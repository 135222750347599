<h3 mat-dialog-title>{{ data.title }}</h3>
<div mat-dialog-content *ngIf="data.message" fxLayoutAlign="start start">
  <p>{{ data.message }}</p>
</div>
<div mat-dialog-actions fxLayout fxLayoutAlign="flex-end">
  <button
    *ngFor="let action of data.actions"
    mat-raised-button
    [disabled]="action.disabled"
    [color]="action.color"
    (click)="action.action()"
    data-test="action-button"
  >
    {{ action.name }}
  </button>
</div>
