import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import {
  APPLICATION_FOR_EMPLOYMENT,
  COMPLIANCE_WITH_DRIVER_LICENSE_REQUIREMENTS,
  DRUG_AND_ALCOHOL_PRE_EMPLOYMENT_STATEMENT,
  EMPLOYMENT_RECORD,
  FAIR_CREDIT_REPORTING_ACT_DISCLOSURE,
  ON_DUTY_HOURS_AND_OTHER_COMPENSATED_WORK
} from 'src/app/shared/models/dqf-declartions';
import { ROUTE_DQF_DOCUMENT_UPLOAD } from 'src/app/shared/routes';
import { SubSink } from 'subsink';
import { DqfSignaturePanelComponent } from '../dqf-signature-panel/dqf-signature-panel.component';
import { ROUTE_DQF } from '../../shared/routes';
import { DriverQualificationService } from '../service/driver-qualification.service';

@Component({
  selector: 'app-dqf-declaration',
  templateUrl: './dqf-declaration.component.html',
  styleUrls: ['./dqf-declaration.component.scss']
})
export class DqfDeclarationComponent implements OnInit, OnDestroy {
  customCollapsedHeight: string = '36px';
  carrierCompanyName: string;
  profilePicture = null;
  panelOpenState = false;
  subSink = new SubSink();
  driverInfo = [];
  declarationInfo = [];
  dqfId: string;
  constructor(
    private location: Location,
    private router: Router,
    private readonly route: ActivatedRoute,
    private driverQualificationService: DriverQualificationService,
    private domSanitizer: DomSanitizer,
    private readonly bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.carrierCompanyName = this.driverQualificationService.dqfData?.carrierCompanyName;
    this.dqfId = this.route.snapshot.paramMap.get('id');

    this.getDeclarations();
    this.getDriverProfilePicture();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  backAction(): void {
    this.location.back();
  }

  onEdit(): void {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_UPLOAD}/${this.driverQualificationService?.dqfData?.id}`]);
  }

  openSignature(): void {
    const bottomSheetRef = this.bottomSheet.open(DqfSignaturePanelComponent, {
      panelClass: 'signature-panel',
      data: {
        dqfId: this.dqfId
      }
    });
    bottomSheetRef.afterDismissed().subscribe(data => {
      if (data) {
      }
    });
  }
  private getDeclarations(): void {
    this.declarationInfo = [
      {
        heading: 'Application For Employment',
        value: this.domSanitizer.bypassSecurityTrustHtml(APPLICATION_FOR_EMPLOYMENT)
      },
      { heading: 'Employment Record', value: this.domSanitizer.bypassSecurityTrustHtml(EMPLOYMENT_RECORD) },
      {
        heading: 'On-Duty Hours & Other Compensated Work',
        value: this.domSanitizer.bypassSecurityTrustHtml(ON_DUTY_HOURS_AND_OTHER_COMPENSATED_WORK)
      },
      {
        heading: 'Compliance with Driver License requirements',
        value: this.domSanitizer.bypassSecurityTrustHtml(COMPLIANCE_WITH_DRIVER_LICENSE_REQUIREMENTS)
      },
      {
        heading: 'Fair Credit Reporting Act disclosure',
        value: this.domSanitizer.bypassSecurityTrustHtml(FAIR_CREDIT_REPORTING_ACT_DISCLOSURE)
      },
      {
        heading: 'Drug and Alcohol pre employment statement',
        value: this.domSanitizer.bypassSecurityTrustHtml(DRUG_AND_ALCOHOL_PRE_EMPLOYMENT_STATEMENT)
      }
    ];
  }

  private getDriverProfilePicture(): void {
    this.profilePicture = this.driverQualificationService.profilePicture;
  }
}
