import { Injectable } from '@angular/core';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import {
  STATUS_ASSIGNED,
  STATUS_BOOKED,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_CONFIRMATION_PENDING,
  STATUS_DELETED,
  STATUS_DELIVERED,
  STATUS_DRAFT,
  STATUS_DRIVING_TO_PICKUP,
  STATUS_INACTIVE,
  STATUS_INVOICE_SENT,
  STATUS_OUT_FOR_DELIVERY,
  STATUS_PAYMENT_OVERDUE,
  STATUS_PAYMENT_RECEIVED,
  STATUS_PICKUP_IN_PROGRESS,
  STATUS_TONU,
  STATUS_UNLOADING,
  STATUS_UNSECURED,
  STOPS,
  STOP_TYPES
} from './constants';
import dayjs from 'dayjs';
import isAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isAfter);
import { Delivery } from 'src/app/loads-shared/shipment-data/models/delivery';

const TIME_FORMAT = 'hh:mm a z';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsUtilsService {
  constructor(private readonly dateTmeConversion: DateTimeService) {}

  getShipmentStatusClass(status: string) {
    if ([STATUS_CONFIRMATION_PENDING].includes(status)) {
      return 'violet-tag';
    } else if (
      [
        STATUS_ASSIGNED,
        STATUS_BOOKED,
        STATUS_DRIVING_TO_PICKUP,
        STATUS_PICKUP_IN_PROGRESS,
        STATUS_OUT_FOR_DELIVERY,
        STATUS_UNLOADING,
        STATUS_INVOICE_SENT
      ].includes(status)
    ) {
      return 'orange-tag';
    } else if ([STATUS_PAYMENT_OVERDUE, STATUS_CANCELLED, STATUS_DELETED].includes(status)) {
      return 'red-tag';
    } else if ([STATUS_DELIVERED, STATUS_COMPLETED, STATUS_PAYMENT_RECEIVED].includes(status)) {
      return 'green-tag';
    } else {
      return 'gray-tag';
    }
  }

  sortShipmentsPickupAsc(shipments: Shipment[], sortOrderAsc: boolean = true): Shipment[] {
    if (!shipments) {
      return;
    }
    let sortedShipments: Shipment[] = [];
    sortedShipments = shipments.sort((shipmentA: Shipment, shipmentB: Shipment) => {
      const pickupDateA = this.dateTmeConversion.getFormattedDate(shipmentA.pickup.pickupDateFrom);
      const pickupDateB = this.dateTmeConversion.getFormattedDate(shipmentB.pickup.pickupDateFrom);
      if (sortOrderAsc) {
        return pickupDateA.getTime() - pickupDateB.getTime();
      } else {
        return pickupDateB.getTime() - pickupDateA.getTime();
      }
    });
    return sortedShipments;
  }

  findUpcomingTrips(shipments: Shipment[]): Shipment[] {
    let foundShipments: Shipment[];

    foundShipments = shipments
      .filter((shipment: Shipment) => {
        if (!shipment || !shipment.status || !shipment.quotes) {
          return;
        }
        const validShipmentStatus =
          shipment.status === STATUS_CONFIRMATION_PENDING || shipment.status === STATUS_BOOKED;
        return validShipmentStatus;
      })
      .map(el => {
        return { ...el, status: el.status === STATUS_BOOKED ? STATUS_ASSIGNED : el.status };
      });
    return foundShipments;
  }

  findOngoingTrips(shipments: Shipment[]): Shipment[] {
    let foundShipment: Shipment[];
    foundShipment = shipments.filter((shipment: Shipment) => {
      if (!shipment || !shipment.status) {
        return;
      }
      const bookedShipment = [
        STATUS_DRIVING_TO_PICKUP,
        STATUS_PICKUP_IN_PROGRESS,
        STATUS_OUT_FOR_DELIVERY,
        STATUS_UNLOADING
      ].includes(shipment.status);
      return bookedShipment;
    });
    return foundShipment;
  }

  getDeadhead(shipment: Shipment): string {
    const deadheadOrigin = shipment?.deadHeadOrigin ? `${shipment?.deadHeadOrigin}-` : '';
    const deadheadDestination = shipment?.deadHeadDestination ? `${shipment?.deadHeadDestination}-Kms` : '';

    return (
      `${deadheadOrigin} ${deadheadDestination}`
        .trim()
        .replace(' ', '/ ')
        .replace(/-/g, ' ') || '-'
    );
  }

  getLoad(shipment: Shipment): string {
    const loadSize = shipment?.loadSize ?? '';
    const weight = shipment?.weight ? `${shipment?.weight}*${shipment.weightUnit ? shipment.weightUnit : ''}` : '';

    return (
      `${loadSize} ${weight}`
        .trim()
        .replace(' ', ' - ')
        .replace('*', ' ') || '-'
    );
  }

  openGoogleMaps(origin: string, destination: string): void {
    const directionsUrl = `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
      origin
    )}&destination=${encodeURIComponent(destination)}`;
    window.open(directionsUrl, '_blank');
  }
  getDeliveryDate(stops: Delivery | Delivery[]): any {
    if (!Array.isArray(stops)) {
      return stops;
    } else {
      let stop = stops[0];
      if (stops?.length === 0) {
        return stop;
      }
      const deliverLoads = stops.filter(stop => stop.stopType === STOP_TYPES.DELIVERY_LOAD);
      const dropTrailers = stops.filter(stop => stop.stopType === STOP_TYPES.DROP_TRAILER);

      if (deliverLoads?.length === 1 && dropTrailers?.length === 0) {
        stop = deliverLoads[0];
      } else if (deliverLoads?.length === 0 && dropTrailers?.length === 1) {
        stop = dropTrailers[0];
      } else if (deliverLoads?.length >= 1 && dropTrailers?.length >= 1) {
        const latestDeliverLoad = deliverLoads.reduce((prev, current) =>
          current?.order > prev?.order ? current : prev
        );
        const latestDropTrailer = dropTrailers.reduce((prev, current) =>
          current?.order > prev?.order ? current : prev
        );
        stop = latestDeliverLoad?.order > latestDropTrailer?.order ? latestDeliverLoad : latestDropTrailer;
      }
      return stop;
    }
  }

  getPickupAddressDateTime(pickups, shipment) {
    let updatedPickupAddress: string;
    let updatedPickupDate: string;
    let updatedPickupTime: string;
    let updatedOrigin: string;
    let updatedStreetAddress: string;
    if (pickups.length > 0) {
      const pickupLoads = pickups.filter(pickup => pickup.stopType === STOPS.PICK_LOAD);
      const targetPickup = pickupLoads.length > 0 ?
        pickupLoads.reduce((minPickup, currentPickup) =>
          minPickup.setOrder < currentPickup.setOrder ? minPickup : currentPickup) :
        pickups.find(pickup => pickup.stopType === STOPS.HOOK_TRAIKER);
  
      updatedPickupAddress = this.formatAddress(targetPickup);
      updatedPickupDate = this.dateTmeConversion.getFormatedConvertedUTCDate(targetPickup?.deliveryDateFrom);
      updatedPickupTime = this.dateTmeConversion.formattedDateTime(targetPickup?.deliveryDateFrom, TIME_FORMAT);
      updatedOrigin = this.getOriginDestination(targetPickup);
      updatedStreetAddress = targetPickup?.streetAddress;
    } else {
      updatedPickupAddress = this.formatAddress(shipment?.pickup);
      updatedPickupDate = this.dateTmeConversion.getFormatedConvertedUTCDate(shipment?.pickup?.pickupDateFrom);
      updatedPickupTime = this.dateTmeConversion.formattedDateTime(shipment?.pickup?.pickupDateFrom, TIME_FORMAT);
      updatedOrigin = this.getOriginDestination(shipment?.pickup);
      updatedStreetAddress = shipment?.pickup?.streetAddress;
    }  
    return {
      updatedPickupAddress,
      updatedPickupDate,
      updatedPickupTime,
      updatedOrigin,
      updatedStreetAddress
    };
  }

  getDeliveryAddressDateTime(dropOffs, shipment) {
    let updatedDeliveryAddress: string;
    let updatedDeliveryDate: string;
    let updatedDeliveryTime: string;
    let updatedDestination: string;
    let updatedStreetAddress: string;

    if (dropOffs.length > 0) {
      const deliverLoads = dropOffs.filter(drop => drop.stopType === STOPS.DELIVER_LOAD);
      const targetDropOff =
        deliverLoads.length > 0
          ? deliverLoads.reduce((maxDropOff, currentDropOff) =>
              maxDropOff.setOrder > currentDropOff.setOrder ? maxDropOff : currentDropOff
            )
          : dropOffs.find(drop => drop.stopType === STOPS.DROP_TRAILER);

      updatedDeliveryAddress = this.formatAddress(targetDropOff);
      updatedDeliveryDate = this.dateTmeConversion.getFormatedConvertedUTCDate(targetDropOff?.deliveryDateFrom);
      updatedDeliveryTime = this.dateTmeConversion.formattedDateTime(targetDropOff?.deliveryDateFrom, TIME_FORMAT);
      updatedDestination = this.getOriginDestination(targetDropOff);
      updatedStreetAddress = targetDropOff?.streetAddress;
    } else if (shipment?.delivery?.length > 0) {
      shipment.delivery.sort((a, b) => a.order - b.order);
      const lastDeliveryStop = shipment.delivery[shipment.delivery.length - 1];
      updatedDeliveryAddress = this.formatAddress(lastDeliveryStop);
      updatedDeliveryDate = this.dateTmeConversion.getFormatedConvertedUTCDate(lastDeliveryStop?.deliveryDateFrom);
      updatedDeliveryTime = this.dateTmeConversion.formattedDateTime(lastDeliveryStop?.deliveryDateFrom, TIME_FORMAT);
      updatedDestination = this.getOriginDestination(lastDeliveryStop);
      updatedStreetAddress = lastDeliveryStop?.streetAddress;
    } else {
      updatedDeliveryAddress = this.formatAddress(shipment.delivery);
      updatedDeliveryDate = this.dateTmeConversion.getFormatedConvertedUTCDate(shipment?.delivery?.deliveryDateFrom);
      updatedDeliveryTime = this.dateTmeConversion.formattedDateTime(shipment?.delivery?.deliveryDateFrom, TIME_FORMAT);
      updatedDestination = this.getOriginDestination(shipment.delivery);
      updatedStreetAddress = shipment?.delivery?.streetAddress;
    }
    return {
      updatedDeliveryAddress,
      updatedDeliveryDate,
      updatedDeliveryTime,
      updatedDestination,
      updatedStreetAddress
    };
  }

  getOriginDestination(stop) {
    const country = stop?.country ? stop.country.substring(0, 2).toUpperCase() : '';
    return `${stop?.city},${stop?.state},${country}`;
  }
  
  formatAddress(stop): string {
    return `${stop?.city},${stop?.state},${stop?.country},${stop?.zipcode}`;
  }
}
