<div>
    <div class="title margin-top-sm">
        <span class="drag-handle"></span>
    </div>
    <ng-container *ngIf="!showCrop">
        <div class="title margin-top-lg">
            {{ data.title | titlecase }}
        </div>
        <div class="options margin-top-md margin-left-sm">
            <div class="margin-bottom-sm">
                <button mat-button (click)="openCamera()">
                    <mat-icon svgIcon="camera"></mat-icon>
                    <span>Camera</span>
                </button>
                <input 
                #cameraInput 
                type="file" 
                name="image" 
                (change)="onProcessPictureFile($event)" 
                [accept]="acceptTypesPicture" 
                capture="environment" 
                style="display: none;" />
            </div>
            <div class="margin-bottom-sm">
                <button  mat-button (click)="openGallery()">
                    <mat-icon svgIcon="image-line"></mat-icon>
                    <span>Photo</span></button>
                <input 
                #pictureSingle 
                type="file" 
                name="image" 
                (change)="onProcessPictureFile($event)" 
                [accept]="acceptTypesPicture" 
                capture="environment"
                data-test="add-photo" 
                style="display: none;"/>
            </div>
            <div class="margin-bottom-sm">
                <button mat-button (click)="openFileExplorer()">
                    <mat-icon svgIcon="icon-file-blue"></mat-icon>
                    <span>File</span>
                </button>
                <input 
                #fileInput 
                type="file" 
                (change)="onUploadFileChange($event)" 
                [accept]="acceptTypesMultiple" 
                data-test="add-file" 
                style="display: none;"/>
            </div>
        </div>
    </ng-container>
    <div *ngIf="showCrop">
        <div class="content" *ngIf="showCrop">
          <div [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
            <image-cropper
              #previewImage
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="false"
              [imageQuality]="100"
              [aspectRatio]="4 / 4"
              [roundCropper]="false"
              [canvasRotation]="canvasRotation"
              [transform]="transform"
              format="png"
              (imageCropped)="onImageCropped($event)"
              (imageLoaded)="onImageLoaded()"
              (cropperReady)="onCropperReady()"
              (loadImageFailed)="onLoadImageFailed()"
            >
            </image-cropper>

            <div *ngIf="!loading" class="save-button-container full-width padding-top-x1">
              <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
                <button
                  class="margin-bottom secondary-button"
                  mat-raised-button
                  (click)="onCancelCrop()"
                  data-test="cancel-button"
                >
                  Cancel
                </button>
                <button mat-raised-button class="rotate-icons" (click)="rotateLeft()">
                  <mat-icon>rotate_left</mat-icon>
                </button>
                <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
                  <mat-icon>rotate_right</mat-icon>
                </button>
                <button
                  class="margin-bottom primary-button finish-crop-button"
                  mat-raised-button
                  (click)="onFinishCrop()"
                  data-test="finish-button"
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
</div>