import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { RegistrationService } from 'src/app/registration/shared/registration.service';

@Component({
  selector: 'app-dqf-driver-residence-history',
  templateUrl: './dqf-driver-residence-history.component.html',
  styleUrls: ['./dqf-driver-residence-history.component.scss']
})
export class DqfDriverResidenceHistoryComponent implements OnInit {
  @Input() version: number;
  data = [];
  constructor(private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.getDriverLicenseInfo();
  }

  private getDriverLicenseInfo(): void {
    this.registrationService.getAllAddress(this.version).subscribe((response) => {
      if (response?.length) {
        const data = response.sort((a, b) => new Date(b.livedFromDate).getTime() - new Date(a.livedFromDate).getTime());
        data.forEach((e) => {
          if (e.isCurrentAddress) {
            const currentAddress = {
              propertyName: 'Current', propertyValue: `${e.completeAddress}`,
              subPropertyValue: `${dayjs(e.livedFromDate).format('MMM YYYY') as string} - Present`
            }
            this.data.push(currentAddress)
          } else {
            const pastAddress = {
              propertyName: 'Past', propertyValue: `${e.completeAddress}`,
              subPropertyValue: `${dayjs(e.livedFromDate).format('MMM YYYY') as string} - ${dayjs(e.livedToDate).format('MMM YYYY') as string}`
            }
            this.data.push(pastAddress)
          }
        })
      }
    });
  }
}
