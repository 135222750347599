<div class="inputs-container">
  <input
    #galleryImageInput
    type="file"
    accept="image/*"
    (change)="onProcessFile($event)"
    multiple
    data-test="select-photo-button"
  />
  <input #cameraImageInput type="file" name="image" accept="image/*" capture="user" (change)="onProcessFile($event)" />
</div>
<div class="menu-overlay" fxLayout="column-reverse" fxLayoutAlign="start">
  <div class="buttons-container">
    <button mat-button class="full-width" (click)="onFileInputClicked(cameraImageInput)">
      <i class="material-icons">camera_alt</i>&nbsp;*Landscape only
    </button>
    <button mat-button class="full-width" (click)="onFileInputClicked(galleryImageInput)" data-test="upload-only">
      <i class="material-icons">landscape</i>&nbsp;Upload Photo
    </button>
    <button mat-button (click)="close()" class="cancel-btn full-width">
      <i class="material-icons">cancel</i>&nbsp;Cancel
    </button>
  </div>
</div>
