import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { catchError, first } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { OtherTruckType, TruckType } from 'src/app/registration/shared/models/truck-type.model';
import { environment } from 'src/environments/environment.dev';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { MilesDriven } from '../shared/models/miles-driven.model';
import { of } from 'rxjs';

const STRAIGHT_TRUCK = 'straightTruck';
const TRACTOR_AND_SEMI_TRAILER = 'tractorAndSemiTrailer';
const TRACTOR_TWO_TRAILERS = 'tractorTwoTrailers';
const OTHERS = 'others';

const ACTION_SAVE_DRIVING_EXPERIENCE = 'Save driving experience';
const ACTION_UPDATE_DRIVING_EXPERIENCE = 'Update driving experience';

@Component({
  selector: 'app-driving-experience',
  templateUrl: './driving-experience.component.html',
  styleUrls: ['./driving-experience.component.scss']
})
export class DrivingExperienceComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  milesDrivenOptions: MilesDriven[];
  dqfId: string;
  panelOpenState = false;
  totalYears = 0;
  totalMonths = 0;
  truckHasValue = false;
  noTruckingExperience = false;
  truckTypes = ['straightTruck', 'tractorAndSemiTrailer', 'tractorTwoTrailers', 'others'];
  showCancelButton = false;
  disableAddNew = false;
  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly registrationService: RegistrationService,
    private readonly timeService: DateTimeService,
    private readonly driverApiService: DriverApiService
  ) {
    this.form = this.createForm();
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit() {
    this.loadDrivingExperience();

    this.registrationService
      .getMilesDriven()
      .pipe(first())
      .subscribe((milesDrivenOptions: MilesDriven[]) => {
        this.milesDrivenOptions = milesDrivenOptions;
      });
  }

  loadDrivingExperience() {
    this.subs.add(
      this.registrationService.loadDriver().subscribe(res => {
        if (res.experience) {
          this.populateForm(res.experience);
        }
      })
    );
  }

  onYearChange() {
    this.totalYears = 0;
    for (let i = 0; i < this.truckTypes.length; i++) {
      this.totalYears += this.form.controls[this.truckTypes[i]].value.years || 0;
    }
  }

  onMonthChange() {
    this.totalMonths = 0;
    for (let i = 0; i < this.truckTypes.length; i++) {
      this.totalMonths += this.form.controls[this.truckTypes[i]].value.months || 0;
    }

    if (this.totalMonths >= 12) {
      let years = Math.floor(this.totalMonths / 12);
      let months = this.totalMonths % 12;
      this.totalYears += years;
      this.totalMonths = months;
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    if (!model.id) {
      this.subs.add(
        this.registrationService
          .saveDrivingExperience(model)
          .pipe(
            catchError((error: any) => {
              this.errorMessage = environment.errorMessage;
              this.loading = false;
              return of(false);
            })
          )
          .subscribe(() => {
            this.errorMessage = undefined;
            this.loading = false;
            this.goBack();
          })
      );
    } else {
      this.subs.add(
        this.registrationService
          .updateDrivingExperience(model)
          .pipe(
            catchError((error: any) => {
              this.errorMessage = environment.errorMessage;
              this.loading = false;
              return of(false);
            })
          )
          .subscribe(() => {
            this.errorMessage = undefined;
            this.loading = false;
            this.goBack();
          })
      );
    }
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  goBack(): void {
    this.location.back();
  }

  noDrivingChecked(event): void {
    if (event.checked) {
      this.disableAddNew = true;
      this.noTruckingExperience = true;
      this.disableEnableForm();
    } else {
      this.disableAddNew = false;
      this.noTruckingExperience = false;
      this.disableEnableForm();
    }
  }

  addDrivingExperienceRecord(): void {
    this.showCancelButton = true;
    this.disableAddNew = true;
  }

  cancelAddingExperience(): void {
    this.disableAddNew = false;
    this.resetAndHide();
  }

  private resetAndHide(): void {
    this.form.get('straightTruck').reset();
    this.form.get('tractorAndSemiTrailer').reset();
    this.form.get('tractorTwoTrailers').reset();
    this.form.get('others').reset();
    this.form.markAsDirty();
    this.totalYears = 0;
    this.totalMonths = 0;
    this.disableForm();
    if (this.form.value.noTruckingExperience) {
      this.form.setErrors(null);
    }
    this.showCancelButton = false;
  }

  private disableEnableForm(): void {
    if (this.noTruckingExperience) {
      this.resetAndHide();
    } else {
      this.form.get('straightTruck').enable();
      this.form.get('tractorAndSemiTrailer').enable();
      this.form.get('tractorTwoTrailers').enable();
      this.form.get('others').enable();
    }
  }

  private prepareSaveModel(): DrivingExperience {
    let model = {};
    if (!this.noTruckingExperience) {
      this.form.controls[STRAIGHT_TRUCK].patchValue({
        type: STRAIGHT_TRUCK,
        displayType: 'Straight Truck',
        monthsOfExperience:
          (this.form.controls[STRAIGHT_TRUCK].get('years').value || 0) * 12 +
          (this.form.controls[STRAIGHT_TRUCK].get('months').value || 0)
      });
      this.form.controls[TRACTOR_AND_SEMI_TRAILER].patchValue({
        type: TRACTOR_AND_SEMI_TRAILER,
        displayType: 'Tractor and Semi-Trailer',
        monthsOfExperience:
          (this.form.controls[TRACTOR_AND_SEMI_TRAILER].get('years').value || 0) * 12 +
          (this.form.controls[TRACTOR_AND_SEMI_TRAILER].get('months').value || 0)
      });
      this.form.controls[TRACTOR_TWO_TRAILERS].patchValue({
        type: TRACTOR_TWO_TRAILERS,
        displayType: 'Tractor-Two Trailers',
        monthsOfExperience:
          (this.form.controls[TRACTOR_TWO_TRAILERS].get('years').value || 0) * 12 +
          (this.form.controls[TRACTOR_TWO_TRAILERS].get('months').value || 0)
      });
      this.form.controls[OTHERS].patchValue({
        type: OTHERS,
        displayType: 'Others',
        monthsOfExperience:
          (this.form.controls[OTHERS].get('years').value || 0) * 12 +
          (this.form.controls[OTHERS].get('months').value || 0)
      });

      model = {
        noTruckingExperience: this.form.value.noTruckingExperience || false,
        truckTypes: this.getTruckTypes(this.form.value),
        id: this.form.value.id || undefined
      };
    } else {
      model = {
        noTruckingExperience: this.form.value.noTruckingExperience || false,
        id: this.form.value.id || undefined
      };
    }
    return model as DrivingExperience;
  }

  private populateForm(model: DrivingExperience): void {
    if (!model.truckTypes.length && !model.noTruckingExperience) {
      this.disableAddNew = false;
      this.noTruckingExperience = false;
    } else if (model.truckTypes.length) {
      this.disableAddNew = true;
      this.noTruckingExperience = false;
      this.showCancelButton = true;
    } else {
      if (model.noTruckingExperience) {
        this.disableAddNew = true;
      } else {
        this.disableAddNew = false;
      }
      this.showCancelButton = false;
      this.noTruckingExperience = true;
      this.form.setErrors({ inValid: false });
      this.form.updateValueAndValidity();
      this.disableForm();
    }

    this.form.patchValue({
      noTruckingExperience: model.noTruckingExperience,
      id: model.id,
      straightTruck: this.setTruckType(model.truckTypes.find(truckType => truckType.type === STRAIGHT_TRUCK)),
      tractorAndSemiTrailer: this.setTruckType(
        model.truckTypes.find(truckType => truckType.type === TRACTOR_AND_SEMI_TRAILER)
      ),
      tractorTwoTrailers: this.setTruckType(
        model.truckTypes.find(truckType => truckType.type === TRACTOR_TWO_TRAILERS)
      ),
      others: this.setTruckType(
        model.truckTypes.find(truckType => truckType.type === OTHERS),
        true
      )
    });
    this.onYearChange();
    this.onMonthChange();
  }

  private setTruckType(truckType: any, isOtherTruck = false): any {
    let value = null;
    if (isOtherTruck) {
      value = new OtherTruckType(truckType);
    } else {
      value = new TruckType(truckType);
    }

    const year = Math.floor(value.monthsOfExperience / 12);
    const month = value.monthsOfExperience % 12;
    this.form.controls[value.type]?.patchValue({
      years: year || '',
      months: month || ''
    });
    return value;
  }

  private createForm(): FormGroup {
    const form = this.fb.group(
      {
        noTruckingExperience: [false],
        id: [],
        straightTruck: this.createTruckFormGroup(),
        tractorAndSemiTrailer: this.createTruckFormGroup(),
        tractorTwoTrailers: this.createTruckFormGroup(),
        others: this.createTruckFormGroup()
      },
      {
        validator: this.checkFormValidity.bind(this)
      }
    );

    return form;
  }

  private createTruckFormGroup(): FormGroup {
    return this.fb.group({
      flat: new FormControl(false),
      tank: new FormControl(false),
      van: new FormControl(false),
      dumpTruck: new FormControl(false),
      reefer: new FormControl(false),
      years: new FormControl(null),
      months: new FormControl(null),
      equipment: new FormControl(null),
      milesDriven: new FormControl(''),
      monthsOfExperience: new FormControl(),
      type: [''],
      displayType: ['']
    });
  }

  private checkFormValidity(group) {
    if (this.noTruckingExperience) {
      return null;
    }

    const controls = group.controls;
    if (
      controls['others'].controls['equipment'].value &&
      (controls['others'].controls['years'].value || controls['others'].controls['months'].value)
    ) {
      return null;
    } else {
      for (let i = 0; i < this.truckTypes.length - 1; i++) {
        if (
          (controls[this.truckTypes[i]].controls['van'].value ||
            controls[this.truckTypes[i]].controls['flat'].value ||
            controls[this.truckTypes[i]].controls['tank'].value ||
            controls[this.truckTypes[i]].controls['dumpTruck'] ||
            controls[this.truckTypes[i]].controls['reefer'].value) &&
          (controls[this.truckTypes[i]].controls['years'].value ||
            controls[this.truckTypes[i]].controls['months'].value)
        ) {
          return null;
        }
      }
    }
    return { inValid: true };
  }

  private getTruckTypes(formModel: FormGroup) {
    let truckTypes = [];

    const straightTruckType = new TruckType(formModel['straightTruck']);
    if (straightTruckType.monthsOfExperience) {
      truckTypes.push(straightTruckType);
    }
    const tractorAndSemiTrailerType = new TruckType(formModel['tractorAndSemiTrailer']);
    if (tractorAndSemiTrailerType.monthsOfExperience) {
      truckTypes.push(tractorAndSemiTrailerType);
    }
    const tractorTwoTrailersType = new TruckType(formModel['tractorTwoTrailers']);
    if (tractorTwoTrailersType.monthsOfExperience) {
      truckTypes.push(tractorTwoTrailersType);
    }
    const otherType = new OtherTruckType(formModel['others']);
    if (otherType.monthsOfExperience) {
      truckTypes.push(otherType);
    }

    return truckTypes;
  }

  private disableForm(): void {
    this.form.get('straightTruck').disable();
    this.form.get('tractorAndSemiTrailer').disable();
    this.form.get('tractorTwoTrailers').disable();
    this.form.get('others').disable();
  }
}
