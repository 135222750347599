import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap, catchError, switchMap, first } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { SendDocumentResponse } from 'src/app/shared/documents-data/models/send-document-response';
import { Response } from 'src/app/shared/services/response';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import * as mime from 'mime';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';

const ERROR_MESSAGE = 'There was an error. Please try again.';
const ERROR_MESSAGE_LOADING_DOCUMENTS = 'Error loading documents. Please try again.';

@Injectable()
export class ShipmentDocumentsService {
  $errorMessage: Observable<string>;
  $documentTypes: Observable<DocumentType[]>;
  $shipmentDocuments: Observable<ShipmentDocument[]>;
  $shipmentDocument: Observable<ShipmentDocument>;

  private errorMessageSubject: Subject<string>;
  private documentTypesSubject: Subject<DocumentType[]>;
  private shipmentDocumentsSubject: Subject<ShipmentDocument[]>;
  private shipmentDocumentSubject: Subject<ShipmentDocument>;

  constructor(
    private readonly documentsDataService: DocumentsDataService,
    private readonly fileDataService: FileDataService,
    private readonly toastService: ToastService
  ) {
    this.errorMessageSubject = new Subject<string>();
    this.$errorMessage = this.errorMessageSubject.asObservable();

    this.documentTypesSubject = new Subject<DocumentType[]>();
    this.$documentTypes = this.documentTypesSubject.asObservable();

    this.shipmentDocumentsSubject = new Subject<ShipmentDocument[]>();
    this.$shipmentDocuments = this.shipmentDocumentsSubject.asObservable();

    this.shipmentDocumentSubject = new Subject<ShipmentDocument>();
    this.$shipmentDocument = this.shipmentDocumentSubject.asObservable();
  }

  // loadShipmentDocumentTypes(): Observable<DocumentType[]> {
  //   return this.documentsDataService.loadShipmentDocumentTypes().pipe(
  //     tap((documentTypes: DocumentType[]) => {
  //       this.documentTypesSubject.next(documentTypes);
  //     }),
  //     // finalize(() => {
  //     //   const documentTypes = this.mockDocumentTypes(); // Mock data for testing
  //     //   this.documentTypesSubject.next(documentTypes);
  //     // }),
  //     catchError((error: string) => {
  //       throw error;
  //     })
  //   );
  // }

  loadShipmentDocuments(shipmentId: string): void {
    this.documentsDataService
      .getShipmentDocuments(shipmentId)
      .pipe(first())
      .subscribe(
        (shipmentDocuments: ShipmentDocument[]) => {
          this.shipmentDocumentsSubject.next(shipmentDocuments);
        },
        (error: any) => {
          this.setErrorMessage(ERROR_MESSAGE_LOADING_DOCUMENTS);
        }
      );
  }

  loadShipmentDocument(shipmentId: string, documentId): void {
    this.documentsDataService
      .getShipmentDocument(shipmentId, documentId)
      .pipe(first())
      .subscribe(
        (shipmentDocument: ShipmentDocument) => {
          this.shipmentDocumentSubject.next(shipmentDocument);
        },
        (error: any) => {
          this.setErrorMessage(ERROR_MESSAGE_LOADING_DOCUMENTS);
        }
      );
  }

  sendDocument(shipmentId: string, documentFile: any, documentType: string, extension: string): Observable<Response> {
    return this.documentsDataService.sendShipmentDocument(shipmentId, documentType, extension).pipe(
      switchMap((sendDocumentResponse: SendDocumentResponse) => {
        const url = sendDocumentResponse.url;
        const newFileName = sendDocumentResponse.file;

        const file = documentFile;
        const renamedFile: File = new File([file], newFileName, { type: file.type });
        const contentType = mime.getType(extension);

        return this.fileDataService.uploadFile(renamedFile, url, contentType);
      }),
      tap(() => {
        this.toastService.showSuccess('Sucessfully uploaded shipment document');
        this.setErrorMessage(undefined);
      }),
      catchError((error: string) => {
        this.setErrorMessage(ERROR_MESSAGE);
        throw error;
      })
    );
  }

  // deleteDocument(shipmentId, documentId: string): Observable<Response> {
  //   this.setErrorMessage(undefined);
  //   return this.documentsDataService.deleteShipmentDocument(shipmentId, documentId).pipe(
  //     tap((response: Response) => {
  //       this.setErrorMessage(undefined);
  //       const message = response && response.message ? response.message : undefined;
  //       this.toastService.showSuccess('Sucessfully deleted document');
  //     }),
  //     catchError((error: string) => {
  //       this.setErrorMessage(ERROR_DELETE_DOCUMENT_MESSAGE);
  //       throw error;
  //     })
  //   );
  // }

  private setErrorMessage(msg: string): void {
    this.errorMessageSubject.next(msg || null);
  }
}
