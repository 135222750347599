<span class="title margin-top-lg">{{ data.title }}</span>
<div mat-dialog-content *ngIf="data.message" fxLayoutAlign="start start">
  <p>{{ data.message }}</p>
</div>
<div class="margin-top-lg margin-bottom-md actions-row">
  <button
    *ngFor="let action of data.actions"
    mat-button
    [disabled]="action.disabled"
    (click)="action.action()"
    [ngClass]="action.color === 'primary' ? 'btn-accept' : 'btn-clear'"
    data-test="action-button"
  >
    {{ action.name }}
  </button>
</div>
