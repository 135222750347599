<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation" fxLayout fxLayoutAlign="center">
    <span>Notifications</span>
  </div>
  <div class="content">
    <div class="card-list">
      <mat-card *ngFor="let notification of notifications" class="card">
        <mat-icon
          *ngIf="notification.status === 'Completed' || notification.status === 'uploaded'"
          class="icon-centered color-success"
          >check_circle_outline</mat-icon
        >
        <mat-icon
          *ngIf="notification.status !== 'Completed' && notification.status !== 'uploaded'"
          class="icon-centered color-warning"
          >error_outline</mat-icon
        >
        <span>{{ notification.type }}</span
        ><br />
        <span class="date">{{ notification.verifiedDate }}</span>
      </mat-card>
    </div>
  </div>
</div>
