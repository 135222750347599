import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { License } from 'src/app/shared/models/license.model';
import { CANADA_PROVINCES, COUNTRY, USA_PROVINCES } from 'src/app/shared/models/provinces';

const DISABLE_TOP_LOADING_BAR = true;
@Component({
  selector: 'app-dqf-driver-license-information',
  templateUrl: './dqf-driver-license-information.component.html',
  styleUrls: ['./dqf-driver-license-information.component.scss']
})
export class DqfDriverLicenseInformationComponent implements OnInit {
  @Input() version: number;
  data = [];
  constructor(private registrationService: RegistrationService) {}

  ngOnInit(): void {
    this.getDriverLicenseInfo();
  }

  private getDriverLicenseInfo(): void {
    this.registrationService.getLicense(DISABLE_TOP_LOADING_BAR, this.version).subscribe((response: License) => {
      if (response) {
        let provinceState = null;
        const country = COUNTRY.filter(data => data.value.toLowerCase() === response.issuingCountry.toLowerCase());
        if (country.length) {
          if (country[0].value.toLowerCase() === 'ca') {
            const value = CANADA_PROVINCES.filter(
              e => e.value.toLowerCase() === response.issuingProvince.toLocaleLowerCase()
            );
            if (value.length) {
              provinceState = value[0].name;
            }
          } else if (country[0].value.toLowerCase() === 'us') {
            const value = USA_PROVINCES.filter(
              e => e.value.toLowerCase() === response.issuingProvince.toLocaleLowerCase()
            );
            if (value.length) {
              provinceState = value[0].name;
            }
          }
        }
        this.bindData(response, country, provinceState);
      }
    });
  }

  private bindData(response: License, country, provinceState: string): void {
    const countryName = country?.[0].name;
    const provinceLabel = countryName?.toLowerCase() === 'canada' ? 'Province' : 'State';

    this.data = [
      { propertyName: 'License Number', propertyValue: `${response.licenseNumber}` },
      { propertyName: 'License Class', propertyValue: `${response.licenseType}` },
      { propertyName: 'Issue Date', propertyValue: `${dayjs(response.issueDate).format('DD MMM YYYY') as string}` },
      {
        propertyName: 'Expiration Date',
        propertyValue: `${dayjs(response.expiryDate).format('DD MMM YYYY') as string}`
      },
      { propertyName: 'Issuing Country', propertyValue: `${countryName ?? ''}` },
      { propertyName: `Issuing ${provinceLabel}`, propertyValue: `${provinceState}` },
      { propertyName: 'Air Brake (Z) Endorsement', propertyValue: `${response.hasAirBreakEndorsment ? 'Yes' : 'No'}` },
      {
        propertyName: 'License, Permit or Privilege Denied',
        propertyValue: `${response.hasDeniedLicense ? 'Yes' : 'No'}`
      },
      {
        propertyName: 'License, Permit or Privilege Suspended or Revoked',
        propertyValue: `${response.hasSuspendedLicense ? 'Yes' : 'No'}`
      }
    ];
  }
}
