import { ROUTE_SIGNUP_SUCCESS } from './../shared/routes';
import { Component, OnInit, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  NgForm,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
  FormControl
} from '@angular/forms';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { SignUpModel } from '../shared/sign-up-model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { Driver } from 'src/app/shared/models/driver.model';
import {
  ROUTE_SIGNIN,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_PRIVACY_STATEMENT,
  ROUTE_SIGNUP_ERROR,
  ROUTE_SIGNUP
} from '../shared/routes';
import { Response } from 'src/app/shared/services/response';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Store } from '@ngxs/store';
import { UpdateDriver } from 'src/app/registration/state/registration.actions';
import { DriverStateModel } from 'src/app/registration/state/registration.state';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { InformationDialogService } from 'src/app/shared/dialog/information-dialog.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { MatDialogRef } from '@angular/material/dialog';
import { InformationDialogComponent } from 'src/app/shared/dialog/information-dialog/information-dialog.component';
import { of } from 'rxjs';

const ACTION_SIGN_IN = 'Sign in';
const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';
const SignInForm = 'SignFormDetails';
const DriverReferralCode = 'ReferralCode';
const MaximumLengthFieldMessage = (value: number) => `Must be less than ${value} characters`;
const MinimumLengthFieldMessage = (value: number) => `Must be atleast ${value} characters`;
const ValidPasswordFormat =
  'Your password must have at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.';
const InvalidFirstName = 'Please enter a valid first name';
const InvalidLastName = 'Please enter a valid last name';
const ValidContactPhoneFormat = 'Must be ###-###-####';
const ContactForHelp = 'Please contact us for help at';
const CANCELLED = 'Cancelled';
const INVITE_CANCELLED_TITLE = 'Invite Cancelled';
const GREETING = 'Hi there!';
const INVITE_CANCELLED_MESSAGE =
  'Looks like the Carrier has cancelled the invitation to join the company’s FleetOperate account. But, you could go ahead and create your own FleetOperate account - The only account you would ever need to find your next Driver job!';
const DISMISS_BUTTON = 'Dismiss';
const SIGNUP_TYPES = {
  INVITE_USER: 'invite-user',
  EXISTING_USER: 'existing-user'
};
const QUERY_PARAMS_STORAGE = {
  INVITE_CODE: 'carrier-invite-code',
  COMPANY_DRIVER_TYPE: 'carrier-invite-company-driver-type',
  SIGNUP_USER_TYPE: 'carrier-invite-user-signup-type'
};
const OWNER_OPERATOR = 'ownerOperator';
const DRIVER_SERVICE_PROVIDER = 'driverServiceProvider';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  hidePassword: boolean;
  loading: boolean;
  errorMessage: string;
  successMessage: string;
  validPasswordFormat: string = ValidPasswordFormat;
  inviteCode: string = undefined;
  carrierCompanyName: string = undefined;
  driverEmail: string = undefined;
  driverFormModel: Driver;
  isCancelled: boolean;
  companyDriverType: string;
  signUpUserType: {
    type: string;
    id: string;
  };

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly driverApi: DriverApiService,
    private readonly elementRef: ElementRef,
    private readonly storageService: StorageService,
    private readonly timeService: DateTimeService,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly store: Store,
    private readonly gtmService: GoogleTagManagerService,
    private readonly registrationService: RegistrationService,
    private readonly informationDialogService: InformationDialogService
  ) {
    this.hidePassword = true;
    this.loading = false;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    this.form = this.createForm();
  }

  ngOnInit() {
    this.inviteCode =
      this.activatedRoute.snapshot.queryParams.code || this.storageService.find(QUERY_PARAMS_STORAGE.INVITE_CODE);
    this.companyDriverType =
      this.activatedRoute.snapshot.queryParams.companyDriverType ||
      this.storageService.find(QUERY_PARAMS_STORAGE.COMPANY_DRIVER_TYPE);
    this.signUpUserType = this.getSignUpUserType() || this.storageService.find(QUERY_PARAMS_STORAGE.SIGNUP_USER_TYPE);

    this.storageService.remove(QUERY_PARAMS_STORAGE.INVITE_CODE);
    this.storageService.remove(QUERY_PARAMS_STORAGE.COMPANY_DRIVER_TYPE);
    this.storageService.remove(QUERY_PARAMS_STORAGE.SIGNUP_USER_TYPE);

    if (this.inviteCode) {
      this.onFetchCarrierCompanyDetails();
    }

    const referralCode = this.activatedRoute.snapshot.queryParams.referralCode;
    if (referralCode) {
      this.storageService.store(DriverReferralCode, referralCode);
    }

    if (this.companyDriverType) {
      this.driverType.setValue(DRIVER_SERVICE_PROVIDER);
    }

    const sign_up = this.storageService.find(SignInForm) as SignUpModel;

    if (sign_up != null) {
      this.form.setValue({
        firstName: sign_up.firstName,
        lastName: sign_up.lastName,
        email: sign_up.email,
        password: sign_up.password,
        mobileNumber: sign_up.phoneNumber,
        driverType: sign_up?.driverType ?? ''
      });
    }

    this.storageService.remove(SignInForm);
  }
  proceedCarrierAsDriver(): void {
    this.createCarrierDriver();
  }

  getSignUpUserType(): any {
    const signUpUserType: any = {};
    let signUpTypeUserId = this.activatedRoute.snapshot.queryParams.inviteUser;

    if (signUpTypeUserId) {
      signUpUserType.id = signUpTypeUserId;
      signUpUserType.type = SIGNUP_TYPES.INVITE_USER;
    } else {
      signUpTypeUserId = this.activatedRoute.snapshot.queryParams.existingUser;

      if (signUpTypeUserId) {
        signUpUserType.id = signUpTypeUserId;
        signUpUserType.type = SIGNUP_TYPES.EXISTING_USER;
      } else {
        return null;
      }
    }

    return signUpUserType;
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#0B184E';
  }

  ngOnDestroy(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
  }
  createCarrierDriver(): void {
    this.loading = true;
    const companyDriverType = this.storageService.find(QUERY_PARAMS_STORAGE.COMPANY_DRIVER_TYPE);
    const model = {
      email: this.driverEmail,
      companyDriverType: companyDriverType
    };

    this.recaptchaV3Service
      .execute('submitSignUp')
      .pipe(
        tap(token => {
          this.driverApi
            .createDriver(model, token, this.inviteCode, this.signUpUserType)
            .pipe(
              catchError(error => {
                this.errorMessage = error?.message ? `${error.message} ${ContactForHelp}` : undefined;
                this.loading = false;
                this.router.navigate([`/${ROUTE_SIGNUP_ERROR}`], { state: { errorMessage: this.errorMessage } });
                return error;
              })
            )
            .subscribe(res => {
              if (res) {
                this.loading = false;
                this.router.navigate([`/${ROUTE_SIGNUP_SUCCESS}`], {
                  queryParams: { invitationType: 'carrier-invite' }
                });
              }
            });
        }),
        catchError((error: any) => {
          this.errorMessage = error?.message ? `${error.message} ${ContactForHelp}` : undefined;
          this.loading = false;
          return of(false);
        }),
        first()
      )
      .subscribe();
  }
  onSubmit(signupForm: NgForm) {
    if (!this.form.valid) {
      this.validateAllFormFields(this.form);

      return;
    }

    this.loading = true;
    this.errorMessage = undefined;
    this.successMessage = undefined;

    const model = this.prepareSaveModel();

    this.recaptchaV3Service
      .execute('submitSignUp')
      .pipe(
        switchMap(token => {
          return this.driverApi.createDriver(
            model,
            token,
            this.storageService.find(QUERY_PARAMS_STORAGE.INVITE_CODE),
            this.storageService.find(QUERY_PARAMS_STORAGE.SIGNUP_USER_TYPE)
          );
        }),
        tap((driver: Driver) => {
          this.customSignupEvent();
          this.carrierCompanyName
            ? this.router.navigate([`/${ROUTE_SIGNUP_SUCCESS}`], {
                queryParams: { companyName: this.carrierCompanyName }
              })
            : this.router.navigate([`/${ROUTE_SIGNUP_SUCCESS}`]);

          this.storageService.remove(QUERY_PARAMS_STORAGE.INVITE_CODE);
          this.storageService.remove(QUERY_PARAMS_STORAGE.COMPANY_DRIVER_TYPE);
          this.storageService.remove(QUERY_PARAMS_STORAGE.SIGNUP_USER_TYPE);

          this.form.reset();
          signupForm.resetForm();
          this.loading = false;
        }),
        catchError((error: any) => {
          this.errorMessage = error?.message ? `${error.message} ${ContactForHelp}` : undefined;
          this.loading = false;

          this.router.navigate([`/${ROUTE_SIGNUP_ERROR}`], { state: { errorMessage: this.errorMessage } });
          return of(false);
        }),
        first()
      )
      .subscribe();
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

  onNavigateToTermsConditions(): void {
    const model = this.prepareSaveModel();
    this.storageService.store(SignInForm, model);
    this.router.navigate([`/${ROUTE_TERMS_CONDITIONS}`]);
  }

  onNavigateToPrivacyStatement(): void {
    const model = this.prepareSaveModel();
    this.storageService.store(SignInForm, model);
    this.router.navigate([`/${ROUTE_PRIVACY_STATEMENT}`]);
  }

  get driverType() {
    return this.form.get('driverType');
  }

  get firstName() {
    return this.form.get('firstName');
  }

  get lastName() {
    return this.form.get('lastName');
  }

  get email() {
    return this.form.get('email');
  }

  get mobileNumber() {
    return this.form.get('mobileNumber');
  }

  get password() {
    return this.form.get('password');
  }

  getFirstNameErrorMessage() {
    const errors = this.firstName.errors;

    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : errors.pattern
      ? InvalidFirstName
      : '';
  }

  getLastNameErrorMessage() {
    const errors = this.lastName.errors;

    return errors.required
      ? RequiredFieldMessage
      : errors.maxlength
      ? MaximumLengthFieldMessage(errors.maxlength.requiredLength)
      : errors.pattern
      ? InvalidLastName
      : '';
  }

  getEmailErrorMessage() {
    const errors = this.email.errors;

    return errors.required ? RequiredFieldMessage : errors.pattern ? InvalidEmailAddress : '';
  }

  getMobileNumberErrorMessage() {
    const errors = this.mobileNumber.errors;

    return errors.required ? RequiredFieldMessage : errors.pattern || errors.minlength ? ValidContactPhoneFormat : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      driverType: [''],
      firstName: [
        '',
        [Validators.required, Validators.maxLength(20), Validators.pattern("^[A-Za-z]+([-'\\s][A-Za-z]*)*$")]
      ],
      lastName: [
        '',
        [Validators.required, Validators.maxLength(20), Validators.pattern("^[A-Za-z]+([-'\\s][A-Za-z]*)*$")]
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)((\\.[A-Za-z]{2,}){0,1})\\s*$')
        ]
      ],
      mobileNumber: ['', [Validators.required, Validators.minLength(12)]],
      password: [
        '',
        [
          Validators.required,
          this.patternValidator(/.{8,}/, { hasLength: true }),
          this.patternValidator(/\d/, { hasNumber: true }),
          this.patternValidator(/[a-z]/, { hasLower: true }),
          this.patternValidator(/[A-Z]/, { hasUpper: true }),
          this.patternValidator(/[!@#\$%\^&\*]/, { hasSpecial: true }),
          this.patternValidator(/^[a-zA-Z0-9!@#\$%\^&\*]*$/, { invalidCharacters: true })
        ]
      ]
    });

    return form;
  }

  private prepareSaveModel(): SignUpModel {
    const formModel = this.form.value;
    const numbersOnlyPhoneNumber = this.form.value.mobileNumber.replace(/-/g, '');
    const titleCaseFirstName = this.convertToTitleCase(formModel.firstName as string).trim();
    const titleCaseLastName = this.convertToTitleCase(formModel.lastName as string).trim();
    const lowerCaseEmail = (formModel.email as string).toLowerCase().trim();
    const referralCode = this.storageService.find(DriverReferralCode);
    const companyDriverType = this.storageService.find(QUERY_PARAMS_STORAGE.COMPANY_DRIVER_TYPE);

    const model = {
      driverType: formModel.driverType ? formModel.driverType : undefined,
      firstName: titleCaseFirstName,
      lastName: titleCaseLastName,
      email: lowerCaseEmail,
      phoneNumber: numbersOnlyPhoneNumber as string,
      password: formModel.password as string,
      referralCode: referralCode ? (referralCode as string) : undefined,
      companyDriverType: companyDriverType
    };
    Object.keys(model).forEach(key => {
      if (model[key] === null || model[key] === undefined) {
        delete model[key];
      }
    });

    return model as SignUpModel;
  }

  private onFetchCarrierCompanyDetails(): void {
    this.loading = true;
    this.form.disable();
    this.recaptchaV3Service
      .execute('login')
      .pipe(
        switchMap(token => {
          return this.registrationService.fetchCompanyData(token, this.inviteCode);
        }),
        tap(val => {
          this.loading = false;
          this.form.enable();
          this.isCancelled = val?.data?.status.toUpperCase() === CANCELLED.toUpperCase() ? true : false;
          if (!this.isCancelled) {
            this.carrierCompanyName = val?.data?.companyName;
            this.driverEmail = val?.data?.email;

            this.storageService.store(QUERY_PARAMS_STORAGE.INVITE_CODE, this.inviteCode);
            this.storageService.store(QUERY_PARAMS_STORAGE.COMPANY_DRIVER_TYPE, this.companyDriverType);
            this.storageService.store(QUERY_PARAMS_STORAGE.SIGNUP_USER_TYPE, this.signUpUserType);

            this.form.patchValue({ email: this.driverEmail });
            const saveModel = { email: val?.data?.email } as DriverStateModel;
            this.store.dispatch([new UpdateDriver(saveModel)]);
            if (this.signUpUserType.type === SIGNUP_TYPES.EXISTING_USER) {
              this.proceedCarrierAsDriver();
            }
          } else if (this.isCancelled) {
            const action = this.getAction(DISMISS_BUTTON);

            const dialogRef = this.informationDialogService.openInformationDialog(
              INVITE_CANCELLED_TITLE,
              GREETING,
              INVITE_CANCELLED_MESSAGE,
              action,
              undefined,
              undefined
            );

            action.action = () => this.closeInviteCancelDialog(dialogRef);
          }
        }),
        catchError((error: any) => {
          this.loading = false;
          this.form.enable();
          console.log(error);
          return error;
        })
      )
      .subscribe();
  }

  private closeInviteCancelDialog(dialogRef: MatDialogRef<InformationDialogComponent>): any {
    this.router.navigate([`/${ROUTE_SIGNUP}`]);
    dialogRef.close();
  }

  private getAction(buttonName): Action {
    return {
      name: buttonName,
      action: (data: any) => {}
    } as Action;
  }

  private convertToTitleCase(str: string): string {
    return str.replace(/\w\S*/g, t => t.charAt(0).toUpperCase() + t.substr(1));
  }

  private customSignupEvent() {
    // push GTM data layer with a custom event
    const gtmTag = {
      event: 'DriverSignup',
      data: 'DriverSignup'
    };
    this.gtmService.pushTag(gtmTag);
  }

  private patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }

      const valid = regex.test(control.value);

      return valid ? null : error;
    };
  }

  /* Function to validate all the forms that will be called on submit */
  private validateAllFormFields(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
