import { Injectable } from '@angular/core';

const MOBILE_NATIVE_APP_URI = 'is_mobile_native=true';

@Injectable({
  providedIn: 'root'
})
export class MobileNativeAppService {
  isNativeApp: boolean;

  constructor() {
    this.isNativeApp = false;
  }

  checkIfNativeApp(): boolean {
    let uriArray = window.location.href.split('?');
    if (uriArray[1] && uriArray[1] === MOBILE_NATIVE_APP_URI) {
      this.isNativeApp = true;
    }

    return this.isNativeApp;
  }
}
