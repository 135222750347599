import { Driver } from './../../shared/services/driver';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, first, map, mergeMap } from 'rxjs/operators';
import { ROUTE_IDENTITY, ROUTE_SIGNUP_ERROR } from 'src/app/authentication/shared/routes';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Token } from 'src/app/shared/models/token.model';
import { Response } from '../../shared/services/response';
import { CodeExchangeService } from 'src/app/shared/code-exchange/code-exchange.service';
import { ErrorApiService } from 'src/app/shared/services/error-api.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-identity-launch',
  templateUrl: './identity-launch.component.html',
  styleUrls: ['./identity-launch.component.scss']
})
export class IdentityLaunchComponent implements OnInit {
  clientToken: string;
  private transactionId: string;
  private codeVerifier: string;
  constructor(
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private readonly codeExchangeService: CodeExchangeService
  ) {}

  ngOnInit() {
    this.codeVerifier = this.codeExchangeService.generateCodeVerifier();
    const codeChallenge = this.codeExchangeService.generateCodeChallenge(this.codeVerifier);
    this.registrationService
      .getBerbixClientToken(codeChallenge)
      .pipe(
        first(),
        catchError((error: any) => {
          const errorMessage = 'Please login again to access your profile.';
          this.router.navigate([`/${ROUTE_SIGNUP_ERROR}`], {
            state: { errorMessage: errorMessage, isBerbixConflict: true }
          });
          return of(false);
        }),
        first()
      )
      .subscribe((token: Token) => {
        this.clientToken = token.clientToken;
        this.transactionId = token.id;
      });
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }
  onNavigateToIdentityStart(): void {
    this.router.navigate([ROUTE_IDENTITY]);
  }

  complete() {
    this.registrationService
      .updateIdentityStatus(this.transactionId, this.codeVerifier)
      .pipe(
        mergeMap((response: Response) => {
          if (response) {
            return this.registrationService.loadDriver();
          }
        }),
        mergeMap((response: Driver) => {
          return this.registrationService.getPrefilledBerbixData(response.id).pipe(map(() => {}));
        })
      )
      .subscribe(() => {
        this.onNavigateToProfile();
      });
  }
}
