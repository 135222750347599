<div fxLayoutAlign="start" fxLayout="column" class="container gray-font-color font-family display-text full-height-vh">
  <div class="header-navigation-back">
    <span *ngIf="!licenseUpload" fxFlex class="title">Drivers License Scan</span>
    <span *ngIf="licenseUpload" fxFlex class="title">Drivers License Upload</span>
  </div>
  <div class="image-upload">
    <input
      style="display: none"
      [accept]="acceptTypes"
      type="file"
      (change)="uploadFrontImg($event)"
      #fileFrontInput
      data-test="front-img"
    />
    <button
      fxFlex="40"
      mat-button
      class="btn-accept"
      [disabled]="frontImg"
      (click)="fileFrontInput.click()"
      data-test="btn-front-img"
    >
      Front Image
    </button>

    <input
      style="display: none"
      [accept]="acceptTypes"
      type="file"
      (change)="uploadBackImg($event)"
      #fileBackInput
      data-test="back-img"
    />
    <button
      fxFlex="40"
      mat-button
      class="btn-accept"
      [disabled]="backImg"
      (click)="fileBackInput.click()"
      data-test="btn-back-img"
    >
      Back Image
    </button>

  </div>

  <div class="capture">
    <button mat-button class="btn-accept" (click)="saveLicense()" data-test="btn-capture">
      Capture
    </button>
  </div>
</div>
