<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div>
    <div class="no-accidents-container-loading">
      <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="add-record-container-loading">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        height: '55px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="content" fxLayout="column">
    <div class="accident-record-loading">
      <div *ngIf="isAccidentRecord">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            height: '65px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div *ngIf="!isAccidentRecord">
        <ngx-skeleton-loader
          appearance="line"
          count="3"
          [theme]="{
            height: '65px'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
