import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-shipment',
  templateUrl: './no-shipment.component.html',
  styleUrls: ['./no-shipment.component.scss']
})
export class NoShipmentComponent implements OnInit {
  @Input() message: string;
  @Input() isShipmentListPage: string;
  @Input() icon: string;
  constructor() {}

  ngOnInit() {}
}
