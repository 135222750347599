<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation" fxLayout fxLayoutAlign="center">
    <span>Account Settings</span>
  </div>
  <div class="content">
    <div class="card-list">
      <mat-card class="card" (click)="onNavigateToResetPassword()">
        <span>Password Reset</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card>
      <!-- <mat-card class="card" (click)="onNavigateToPreferences();">
        <span>Preferences</span>
        <mat-icon class="icon-arrow">chevron_right</mat-icon>
      </mat-card> -->
    </div>
  </div>
</div>
