import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DriverGuardService } from '../core/guards/driver-guard.service';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { MY_VEHICLES } from './shared/routes';
import { VehicleAssociationComponent } from './vehicle-association/vehicle-association.component';
const vehicleRoutes: Routes = [
  {
    path: '',
    component: VehiclesComponent,
    canActivate: [DriverGuardService],
    children: [
      {
        path: '',
        redirectTo: MY_VEHICLES,
        pathMatch: 'full',
        canActivate: [DriverGuardService]
      },
      {
        path: MY_VEHICLES,
        component: VehicleAssociationComponent,
        canActivate: [DriverGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(vehicleRoutes)],
  exports: [RouterModule]
})
export class VehiclesRoutingModule {}
