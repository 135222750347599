<div>
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <ng-container [ngSwitch]="data.type">
    <!-- Filter Shipments - Select -->
    <ng-container *ngSwitchCase="'filter'">
      <div class="title margin-top-lg">
        {{ data.title }}
      </div>
      <div class="options margin-top-md">
        <div *ngFor="let option of data.options" class="margin-bottom-md" (click)="changeChosenValue(option)" data-test="list-of-options"> 
          <span [ngClass]="chosenValue !== option ? 'text-fade' : ''">{{ option }}</span>
          <mat-icon svgIcon="tick-blue" *ngIf="chosenValue === option"></mat-icon>
        </div>
      </div>
      <div class="margin-bottom-md margin-top-md">
        <button mat-button class="btn-accept" (click)="onConfirm()" data-test="confirm-filter-button">Confirm</button>
      </div>
    </ng-container>

    <!-- Documents File -->
    <ng-container *ngSwitchCase="'file'">
      <div class="margin-top-lg" [ngClass]="loading ? 'action-container disabled-text' : 'action-container'">
        <div class="document-actions" (click)="downloadDocument()" data-test="download-button">
          <mat-icon svgIcon="download"></mat-icon>
          <span>Download</span>
        </div>
        <div class="document-actions" *ngIf="data.fileDocument.role === 'Driver'" (click)="deleteDocument()" data-test="delete-button">
          <mat-icon svgIcon="delete-icon"></mat-icon>
          <span>Delete</span>
        </div>
        <div class="document-actions" (click)="fetchDocument()" data-test="view-button">
          <mat-icon svgIcon="eye-line"></mat-icon>
          <span>View</span>
        </div>
      </div>
    </ng-container>

    <!-- Timeline -->
    <ng-container *ngSwitchCase="'timeline'">
      <div [ngClass]="loading ? 'action-container disabled-text' : 'action-container'">
        <div class="document-actions" *ngIf="data.event.comment" (click)="openEditComment()" data-test="edit-comment">
          <mat-icon svgIcon="edit-filled"></mat-icon>
          <span>Edit</span>
        </div>
        <div class="document-actions" *ngIf="data.event.comment" (click)="deleteEvent('comment')" data-test="delete-comment">
          <mat-icon svgIcon="delete-icon"></mat-icon>
          <span>Delete</span>
        </div>
        <div class="document-actions" *ngIf="data.event.fileUrl" (click)="deleteEvent('documentId')" data-test="delete-photo">
          <mat-icon svgIcon="delete-icon"></mat-icon>
          <span>Delete</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
