import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewUploadedDocumentComponent } from './view-uploaded-document/view-uploaded-document.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [UploadDocumentComponent, ViewUploadedDocumentComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MaterialModule,
    ImageCropperModule
  ],
  exports: [UploadDocumentComponent, ViewUploadedDocumentComponent]
})
export class ComponentsModule {}
