import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralSearchService {

  constructor() { }

  searchField(model: Object, search: string): boolean {
    if (!model) {
      return false;
    }

    if (!search) {
      return true;
    }

    return Object.values(model).some((value) => value && String(value).toLowerCase().includes(search.toLowerCase()));
  }
}
