import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { Store } from '@ngxs/store';
import { RegistrationState, DriverStateModel } from 'src/app/registration/state/registration.state';
import { environment } from 'src/environments/environment.dev';
import { tap, onErrorResumeNext } from 'rxjs/operators';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ProfileService } from '../../profile/profile.service';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { base64ToFile } from 'src/app/shared/utils';

const ACTION_SAVE_PROFILE = 'Save profile';

@Component({
  selector: 'app-profile-picture',
  templateUrl: './profile-picture.component.html',
  styleUrls: ['./profile-picture.component.scss']
})
export class ProfilePictureComponent implements OnInit, OnDestroy {
  showMenu = false;
  imageChangedEvent: any;
  profilePicture: any;
  loading = false;
  menuOptionSelected = false;
  errorMessage: string;
  personalInfoCompleted = false;
  driver: any;
  acceptTypes = ['image/png', 'image/jpg', 'image/jpeg'];
  canvasRotation = 0;
  transform: ImageTransform = {};
  @ViewChild('previewImage') previewImage: ImageCropperComponent;
  private subs = new SubSink();
  private profilePicturePath: ProfilePicture = new ProfilePicture();

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly registrationService: RegistrationService,
    private readonly driverApiService: DriverApiService,
    private readonly profileService: ProfileService,
    private readonly timeService: DateTimeService,
    private readonly store: Store
  ) {
    this.loading = false;
    this.errorMessage = undefined;
  }

  ngOnInit(): void {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(
          onErrorResumeNext(this.registrationService.loadProfilePicture()),
          tap(() => {
            this.profilePicturePath = this.store.selectSnapshot(RegistrationState.profilePicture);
          }),
          tap(() => (this.driver = this.store.selectSnapshot(RegistrationState.driver))),
          tap(() => (this.personalInfoCompleted = this.isPersonalInfoCompleted(this.driver)))
        )
        .subscribe(() => {
          if (this.profilePicturePath && this.profilePicturePath.fileUrl) {
            this.profilePicture = this.profilePicturePath.fileUrl;
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onFileInputClicked(fileInput: any) {
    fileInput.click();
  }
  isPersonalInfoCompleted(driver: DriverStateModel): boolean {
    return this.profileService.isPersonalInfoCompleted(driver);
  }
  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }
  goBack(): void {
    this.location.back();
  }
  onShowMenu() {
    this.showMenu = true;
  }

  onHideMenu() {
    this.showMenu = false;
  }

  onProcessFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.profilePicture = file;
      };

      this.loading = true;
      this.menuOptionSelected = true;
      this.onHideMenu();
      this.imageChangedEvent = event;
    }
  }

  onCancelCrop() {
    this.imageChangedEvent = undefined;
    this.profilePicture = undefined;
    this.menuOptionSelected = false;
  }

  onFinishCrop() {
    if (this.profilePicture && this.profilePicture.name) {
      const extension = this.profilePicture.name
        .split('.')
        .pop()
        .toLowerCase();
      this.loading = true;
      this.subs.add(
        this.registrationService
          .saveProfilePicture(this.profilePicture, extension)
          .pipe(
            catchError((error: any) => {
              this.errorMessage = environment.errorMessage;
              this.loading = false;
              this.imageChangedEvent = undefined;
              this.menuOptionSelected = false;

              return of(false);
            })
          )
          .subscribe(() => {
            this.errorMessage = undefined;
            this.loading = false;
            this.imageChangedEvent = undefined;
            this.menuOptionSelected = false;
            this.onNavigateToProfile();
          })
      );
    }
  }

  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      const imageFile = new File([base64ToFile(event.base64)], this.profilePicture.name, {
        type: this.profilePicture.type
      });
      this.profilePicture = imageFile;
    }
  }

  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  onLoadImageFailed() {}
}
