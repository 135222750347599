export class TruckType {
  flat: boolean;
  tank: boolean;
  van: boolean;
  dumpTruck: boolean;
  reefer: boolean;
  milesDriven?: string;
  monthsOfExperience: string;
  type: string;
  displayType: string;

  constructor(data?: TruckType) {
    if (data) {
      this.flat = data?.flat || false;
      this.tank = data?.tank || false;
      this.van = data?.van || false;
      this.dumpTruck = data?.dumpTruck || false;
      this.reefer = data?.reefer || false;
      if (data?.milesDriven) {
        this.milesDriven = data?.milesDriven || '';
      }
      this.monthsOfExperience = data?.monthsOfExperience || '';
      this.type = data?.type || '';
      this.displayType = data?.displayType || '';
    }
  }
}

export class OtherTruckType {
  equipment: string;
  milesDriven: string;
  monthsOfExperience: string;
  type: string;
  displayType: string;

  constructor(data?: OtherTruckType) {
    if (data) {
      this.equipment = data.equipment || '';
      if (data?.milesDriven) {
        this.milesDriven = data?.milesDriven || '';
      }

      this.monthsOfExperience = data?.monthsOfExperience || '';
      this.type = data?.type || '';
      this.displayType = data?.displayType || '';
    }
  }
}
