<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-text">
  <div class="header-navigation-back">
    <span fxFlex class="title">Drivers License Scan</span>
    <button fxFlexAlign="end" mat-icon-button (click)="onOpenActionSheet()" *ngIf="mobile">
      <mat-icon aria-hidden="false" aria-label="Identity actions">more_horiz</mat-icon>
    </button>
  </div>
  <app-status-banner *ngIf="status" [status]="status" class="padding-bottom-xlg"> </app-status-banner>
  <div fxLayout="column" class="txt" *ngIf="!mobile">
    <div class="scan" *ngIf="!mobile">
      <p>Scan one of the QR code below to make the switch</p>
    </div>
    <div class="qr-div margin-bottom-xlg" fxLayout="row" fxLayoutGap="180px" *ngIf="!mobile" alt="Responsive">
      <div fxLayout="column">
        <img [src]="iOSAppQR" class="ios-qrcode-img" alt="Responsive image" />
        <img [src]="appStoreIcon" class="app-store" alt="Responsive image" />
      </div>
      <div>
        <img [src]="qrImg" class="qrcode-img" alt="Responsive image" />
      </div>
    </div>
  </div>
  <div fxLayout="column" class="txt" *ngIf="mobile">
    <div fxLayout="column" fxFlex class="html">
      <div fxFlex="45">
        <img src="assets/images/driver-license-vector.png" class="license-vector" />
      </div>
      <div fxFlex="7" class="header-text">
        {{ title }}
      </div>
      <div fxFlex="43" class="body-text">
        {{ html }}
      </div>
    </div>
  </div>
  <app-actions [actions]="actions"></app-actions>
</div>
