<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground processing-request">
  <mat-error *ngIf="errorMessage" class="error padding-left-md">
    {{ errorMessage }}
  </mat-error>
  <div class="header-navigation-back">
    <mat-icon (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">{{ documentName }}</span>
  </div>
  <div class="processing-container" (swipeleft)="navigateBack()" (swiperight)="onSwipeRight($event)">
    <div class="processing-label">
      Processing your request
    </div>
    <div class="processing-info">
      <h3 class="info-heading">Next Steps</h3>
      <p class="info-content" data-test="driver-info">
        You will be required to scan your ID in order to process your Criminal Record Check report.
      </p>
      <p class="info-content" data-test="info-content">
        You will receive an email at <span class="info-email" data-test="driver-email">{{ driverEmail }}</span> with
        instructions, when the Carrier processes your request.
      </p>
    </div>
  </div>
</div>
