import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { MaterialModule } from '../shared/material/material.module';
import { ReferAFriendRoutingModule } from './refer-a-friend-routing.module';
import { ActionsModule } from '../shared/actions/actions.module';

@NgModule({
  declarations: [ReferFriendComponent],
  imports: [CommonModule, MaterialModule, ReferAFriendRoutingModule, ActionsModule]
})
export class ReferAFriendModule {}

export { ReferFriendComponent } from './refer-friend/refer-friend.component';
