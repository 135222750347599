import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import dayjs, { Dayjs } from 'dayjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const YEAR_MONTH_FORMAT = 'YYYY MMM';
const InvalidEmailAddress = 'Invalid email address';

@Component({
  selector: 'app-requirements-complete-action',
  templateUrl: './requirements-complete-action.component.html',
  styleUrls: ['./requirements-complete-action.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class RequirementsCompleteActionComponent implements OnInit {
  form: FormGroup;
  title: string = '';
  showForm: boolean = false;
  lastDateOfMonth = dayjs()
    .endOf('month')
    .valueOf();
  currentDate = new Date(this.lastDateOfMonth);
  maxToDate = dayjs(this.currentDate).subtract(1, 'month');
  minToDate: any;
  isEmailNeeded = false;
  userAction: string = '';
  @ViewChildren('toDatePicker') toDateList;
  constructor(
    public dialogRef: MatBottomSheetRef<RequirementsCompleteActionComponent>,
    private readonly fb: FormBuilder,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.form = this.createForm();
    if (
      this.data.isEmploymentHistory &&
      !this.data.currentAddress.hasOwnProperty('referenceEmail') &&
      this.data.currentAddress.employmentState === 'employed'
    ) {
      this.isEmailNeeded = true;
    } else {
      this.isEmailNeeded = false;
    }
  }

  ngOnInit(): void {
    this.setTitle();
  }

  get fromDate() {
    return this.form.get('fromDate');
  }
  get toDate() {
    return this.form.get('toDate');
  }
  get referenceEmail() {
    return this.form.get('referenceEmail');
  }

  getToDateErrorMessage() {
    return this.toDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }
  getReferenceEmailErrorMessage() {
    const errors = this.referenceEmail.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : errors.pattern ? InvalidEmailAddress : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      fromDate: ['', [Validators.required]],
      toDate: ['', [Validators.required, this.checkToDateValidation.bind(this)]],
      referenceEmail: ['']
    });
    return form;
  }

  private checkToDateValidation(control: AbstractControl): Promise<any> {
    if (control && control?.parent) {
      let toDate = control?.value;
      const fromDate = control?.parent?.get('fromDate').value;
      if (fromDate && toDate && dayjs(toDate) <= dayjs(fromDate)) {
        return Promise.resolve({
          minDateError: true
        });
      }
    }
    return null;
  }

  chosenMonthHandler(
    normalizedMonth: Dayjs,
    datepicker: MatDatepicker<Dayjs>,
    currentForm: FormGroup,
    element?: string
  ): void {
    const date = dayjs()
      .set('month', normalizedMonth.get('month'))
      .set('year', normalizedMonth.get('year'));

    if (element) {
      const assignDate = dayjs(date).endOf('month');
      currentForm.patchValue({
        toDate: assignDate
      });
    } else {
      const assignDate = dayjs(date).set('date', 1);
      let currentToDate = dayjs(currentForm.get('toDate').value);

      if (
        currentToDate.get('month') === assignDate.get('month') &&
        currentToDate.get('year') === assignDate.get('year')
      ) {
        currentToDate = dayjs(currentToDate).endOf('month');
      } else {
        currentToDate = dayjs(currentToDate).set('date', 1);
      }

      currentForm.patchValue({
        fromDate: assignDate
      });
      currentForm.get('toDate').setValue(currentToDate, { emitEvent: false });
    }

    this.form.updateValueAndValidity();
    datepicker.close();
  }

  onDatePickerClick(datepicker: any) {
    datepicker.open();
  }

  setTitle() {
    if (!this.showForm) {
      if (this.data.isEmploymentHistory) {
        if (this.data.currentAddress?.employmentState == 'unemployed') {
          this.title = `You modified the employment history on <b>${dayjs(
            this.data.currentAddress?.modifiedDate
          ).format(YEAR_MONTH_FORMAT)}</b>. Are you still unemployed?`;
        } else {
          this.title = `You modified the employment history on <b>${dayjs(
            this.data.currentAddress?.modifiedDate
          ).format(YEAR_MONTH_FORMAT)}</b> for the company <b>${
            this.data.currentAddress?.employer
          }</b>. Are you still working at the same place?`;
        }
      } else {
        this.title = `You modified the address on <b>${dayjs(this.data.currentAddress?.modifiedDate).format(
          YEAR_MONTH_FORMAT
        )}</b> for <b>${this.data.currentAddress?.streetAddress}</b>. Are you still living at the same place?`;
      }
    } else {
      if (this.data.isEmploymentHistory) {
        if (this.data.currentAddress?.employmentState == 'unemployed') {
          this.title = `Please set the last unemployed date.`;
        } else {
          if (this.isEmailNeeded) {
            this.title = `Please set the ${
              this.userAction === 'yes' ? 'reference email' : 'last working date and reference email'
            } for the company <b>${this.data.currentAddress?.employer}</b>.`;
          } else {
            this.title = `Please set the last working date for the company <b>${this.data.currentAddress?.employer}</b>.`;
          }
        }
        this.fromDate.setValue(this.data.currentAddress?.fromDate);
        this.minToDate = this.data.currentAddress?.fromDate;
      } else {
        this.title = `Please set the last date you lived here.`;
        this.fromDate.setValue(this.data.currentAddress?.livedFromDate);
        this.minToDate = dayjs(this.data.currentAddress?.livedFromDate).add(1, 'month');
      }
    }
  }

  updateUserAction(val: string) {
    this.userAction = val;
    if (val === 'yes' && this.data.isEmploymentHistory) {
      // in case of employmentHistory
      if (this.isEmailNeeded) {
        this.form
          .get('referenceEmail')
          .setValidators([
            Validators.required,
            Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)((\\.[A-Za-z]{2,}){0,1})\\s*$')
          ]);
        this.form.get('toDate').setValidators([]);
        this.updateShowForm(true);
      } else {
        this.sendDialogDetails({ type: 'yes' });
      }
    } else if (val === 'yes' && !this.data.isEmploymentHistory) {
      // in case of residence
      this.sendDialogDetails({ type: 'yes' });
    } else {
      // When user selected 'no'
      if (this.isEmailNeeded) {
        this.form
          .get('referenceEmail')
          .setValidators([
            Validators.required,
            Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)((\\.[A-Za-z]{2,}){0,1})\\s*$')
          ]);
      }
      this.form.get('toDate').setValidators([Validators.required, this.checkToDateValidation.bind(this)]);
      this.updateShowForm(true);
    }
    this.form.get('referenceEmail').updateValueAndValidity();
    this.form.get('toDate').updateValueAndValidity();
  }

  sendDialogDetails(val: any) {
    this.dialogRef.dismiss(val);
  }
  submitForm(): void {
    let formData: any = {};
    if (this.isEmailNeeded) {
      formData.referenceEmail = this.form.value.referenceEmail;
    }
    if (this.userAction === 'no') {
      formData.toDate = this.form.value.toDate;
    }
    this.sendDialogDetails({ type: this.userAction, data: formData });
  }
  updateShowForm(val: boolean): void {
    this.showForm = val;
    this.setTitle();
  }
}
