<div class="header-navigation-back" *ngIf="isDocumentViewPage">
  <mat-icon (click)="onNavigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
  <span fxFlex class="title">{{ heading }}</span>
</div>

<div fxFlexAlign="center">{{ errorMessage }}</div>
<div fxLayout fxLayoutAlign="space-between center" *ngIf="isDocumentViewPage">
  <div class="margin-left-md">{{ fileType }}</div>
  <div class="margin-right-md download-button">
    <a mat-button [href]="previewImage || safeUrl" target="_blank" download>
      <mat-icon svgIcon="arrow-down-icon" class="icon-color"></mat-icon>Download
    </a>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="space-around center" *ngIf="!dqfDocumentId && !isDocumentViewPage">
  <span class="heading">Carrier upload requested</span>
  <span class="body padding-top-sm center"
    >You will be able to view and download the document once the Carrier uploads.</span
  >
</div>

<img [src]="previewImage" *ngIf="previewImage" class="imagePreview padding-md" />
<iframe [src]="safeUrl" class="safeUrl" *ngIf="safeUrl" frameborder="0" loading="lazy"></iframe>
