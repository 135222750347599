import { Routes } from '@angular/router';
import { EmploymentHistoryComponent } from '../registration/employment-history/employment-history.component';
import { ProfileComponent } from './profile/profile.component';
import { EducationComponent } from '../registration/education/education.component';
import { DrivingExperienceComponent } from '../registration/driving-experience/driving-experience.component';
import { PreferencesComponent } from '../registration/preferences/preferences.component';
import { ProfilePictureComponent } from '../registration/profile-picture/profile-picture.component';
import { PersonalInformationComponent } from '../registration/personal-information/personal-information.component';
import { DriverAbstractComponent } from '../registration/driver-abstract/driver-abstract.component';
import { MedicalExaminationComponent } from '../registration/medical-examination/medical-examination.component';
import { InsuranceComponent } from '../registration/insurance/insurance.component';
import { DriveTestComponent } from '../registration/drive-test/drive-test.component';
import { IdentityCheckComponent } from '../identity-check/identity-check.module';
import { identityCheckRoutes } from '../identity-check/identity-check-routing.module';
import { ResumeComponent } from '../registration/resume/resume.component';
import { DriverGuardService } from '../core/guards/driver-guard.service';
import { ResidenceHistoryComponent } from '../registration/residence-history/residence-history.component';
import { AccidentRecordsComponent } from '../registration/accident-records/accident-records.component';

import { TrafficConvictionsComponent } from '../registration/traffic-convictions/traffic-convictions.component';
import { AdditionalDocumentsComponent } from '../registration/additional-documents/additional-documents.component';
import { AdditionalDocumentViewComponent } from '../registration/additional-document-view/additional-document-view.component';
import { ProfileLicenseInformationComponent } from './profile-license-information/profile-license-information.component';
import { EmploymentHistoryFormEmployedComponent } from '../registration/employment-history/employment-history-form-employed/employment-history-form-employed.component';
import { EmploymentHistoryFormUnemployedComponent } from '../registration/employment-history/employment-history-form-unemployed/employment-history-form-unemployed.component';
import { IncorporationComponent } from '../registration/incorporation/incorporation.component';
import { AdditionalDocumentUploadComponent } from '../registration/additional-document-upload/additional-document-upload.component';
import { ProfileLicenseImageViewComponent } from './profile-license-image-view/profile-license-image-view.component';

export const profileRoutes: Routes = [
  { path: '', component: ProfileComponent, canActivate: [DriverGuardService] },
  { path: 'employmentHistory', component: EmploymentHistoryComponent, canActivate: [DriverGuardService] },
  {
    path: 'employmentHistory',
    canActivate: [DriverGuardService],
    children: [
      { path: '', component: EmploymentHistoryComponent, canActivate: [DriverGuardService] },
      { path: 'employedForm', component: EmploymentHistoryFormEmployedComponent, canActivate: [DriverGuardService] },
      {
        path: 'employedForm/:id',
        component: EmploymentHistoryFormEmployedComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: 'unEmployedForm',
        component: EmploymentHistoryFormUnemployedComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: 'unEmployedForm/:id',
        component: EmploymentHistoryFormUnemployedComponent,
        canActivate: [DriverGuardService]
      }
    ]
  },
  { path: 'education', component: EducationComponent, canActivate: [DriverGuardService] },
  { path: 'drivingExperience', component: DrivingExperienceComponent, canActivate: [DriverGuardService] },
  { path: 'preferences', component: PreferencesComponent, canActivate: [DriverGuardService] },
  { path: 'incorporation', component: IncorporationComponent, canActivate: [DriverGuardService] },
  { path: 'profilePicture', component: ProfilePictureComponent, canActivate: [DriverGuardService] },
  {
    path: 'personalInfo',
    component: PersonalInformationComponent,
    canActivate: [DriverGuardService]
  },
  { path: 'driverAbstract', component: DriverAbstractComponent, canActivate: [DriverGuardService] },
  { path: 'medicalExamination', component: MedicalExaminationComponent, canActivate: [DriverGuardService] },
  { path: 'insurance', component: InsuranceComponent, canActivate: [DriverGuardService] },
  { path: 'driveTest', component: DriveTestComponent, canActivate: [DriverGuardService] },
  { path: 'resume', component: ResumeComponent, canActivate: [DriverGuardService] },
  { path: 'residenceHistory', component: ResidenceHistoryComponent, canActivate: [DriverGuardService] },
  { path: 'license', component: ProfileLicenseInformationComponent, canActivate: [DriverGuardService] },
  { path: 'license/license-view', component: ProfileLicenseImageViewComponent, canActivate: [DriverGuardService] },
  { path: 'identityCheck', component: IdentityCheckComponent, children: [...identityCheckRoutes] },
  { path: 'accidentRecords', component: AccidentRecordsComponent, canActivate: [DriverGuardService] },
  { path: 'trafficConvictions', component: TrafficConvictionsComponent, canActivate: [DriverGuardService] },
  { path: 'documents', component: AdditionalDocumentsComponent, canActivate: [DriverGuardService] },
  { path: 'documents/:type', component: AdditionalDocumentViewComponent, canActivate: [DriverGuardService] },
  { path: 'document-upload/:type', component: AdditionalDocumentUploadComponent, canActivate: [DriverGuardService] }
];

export { ROUTE_PROFILE } from '../shared/routes';
