<div class="header-navigation" fxLayout fxLayoutAlign="left">
  <span>Dashboard</span>
</div>

<div class="dashboard-container" fxLayout="column">
  <div *ngIf="!isProfileContentReady; else profileContent">
    <ngx-skeleton-loader
      appearance="circle"
      [theme]="{
        width: '60px',
        height: '60px'
      }"
    >
    </ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
  </div>
  <ng-template #profileContent>
    <div class="profile-row" fxFlex fxLayout="row">
      <img
        [src]="profilePicturePath ? profilePicturePath : '../../../assets/images/default-profile.png'"
        class="profile-image"
      />
      <div class="driver-name">
        Hello,
        <br />
        {{ driverName }}
      </div>
    </div>
  </ng-template>

  <mat-tab-group
    animationDuration="0ms"
    mat-align-tabs="center"
    mat-stretch-tabs="true"
    class="tab-list"
    (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="currentTab"
    *ngIf="!loading"
  >
    <mat-tab label="Current month" data-test="current-tab">
      <div class="tab-content" fxLayout="column">
        <span class="content-header">Overview</span>
        <div class="sections-container">
          <div class="section section-completed">
            <mat-icon svgIcon="checkbox-circle-line-green"></mat-icon>
            <div fxFlex fxLayout="column">
              <span class="count">{{ dashboardContent?.completed }}</span>
              <span class="status">Completed</span>
            </div>
          </div>
          <div class="section section-upcoming">
            <mat-icon svgIcon="calendar-tick"></mat-icon>
            <div fxFlex fxLayout="column">
              <span class="count">{{ dashboardContent?.upcoming }}</span>
              <span class="status">Upcoming</span>
            </div>
          </div>
          <div class="section section-earned">
            <mat-icon svgIcon="money-dollar-circle-line"></mat-icon>
            <div fxFlex fxLayout="column">
              <span class="count">$ {{ dashboardContent?.earned }}</span>
              <span class="status">Earned</span>
              <span class="goal">Goal: {{ dashboardContent?.target_earned }}</span>
              <div
                class="progress-bar progress-earned"
                [attr.data-progress-earned]="dashboardContent?.earnedPercent"
              ></div>
            </div>
          </div>
          <div class="section section-miles">
            <mat-icon svgIcon="pin-distance"></mat-icon>
            <div fxFlex fxLayout="column">
              <span class="count">{{ dashboardContent?.miles }} miles</span>
              <span class="status">Driven</span>
              <span class="goal">Goal: {{ dashboardContent?.target_miles }}</span>
              <div
                class="progress-bar progress-miles"
                [attr.data-progress-miles]="dashboardContent?.milesPercent"
              ></div>
            </div>
          </div>
        </div>

        <div fxFlex fxLayout="row" fxLayoutAlign="space-between">
          <span class="content-header">Ongoing Shipment</span>
          <div [ngClass]="[getStatusClass(), 'status-tag']">
            {{ shipment?.status | titlecase }}
          </div>
        </div>
        <div fxLayoutGap="10px" fxLayout="column" class="origin-des-container">
          <div fxLayout fxFlex="30" class="origin-des">
            <div class="text-align-left">{{ shipment?.origin || '-' }}</div>
            <div fxFlex="30" fxLayout class="location-icons-container">
              <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
              <div fxFlex="55" class="border-bottom"></div>
              <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
            </div>
            <div class="text-align-right">{{ shipment?.destination || '-' }}</div>
          </div>
          <div fxLayout class="address">
            <div fxFlex="40" class="text-align-left">{{ shipment?.pickup?.streetAddress || '-' }}</div>
            <div fxFlex="40" class="text-align-right">{{ shipment?.delivery?.streetAddress || '-' }}</div>
          </div>
          <div fxLayout class="time">
            <div fxFlex="40" class="text-align-left">{{ shipment?.pickupFormattedDateTime || '-' }}</div>
            <div fxFlex="40" class="text-align-right">{{ shipment?.deliveryFormattedDateAndTime || '-' }}</div>
          </div>
          <div class="margin-top-lg">
            <button mat-button class="btn-accept">
              View more
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Past month" data-test="past-tab"> </mat-tab>
  </mat-tab-group>
</div>
