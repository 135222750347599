import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SINGLE_DOCUMENT_TYPES, STATUS_UNLOADING, DRIVER_NO_ACTION_DOC_TYPES } from '../../shared/constants';
import { SHIPMENTS_ROUTE_MY_SHIPMENTS, SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW } from '../../shared/routes';

const ROUTE_DOCUMENTS = 'documents';
const POD_LABEL_TYPE = 'ProofOfDelivery';

@Component({
  selector: 'app-document-tile-box',
  templateUrl: './document-tile-box.component.html',
  styleUrls: ['./document-tile-box.component.scss']
})
export class DocumentTileBoxComponent implements OnInit {
  @Input() document;
  @Input() shipment: any;
  singleDocumentFiles = [...SINGLE_DOCUMENT_TYPES, ...DRIVER_NO_ACTION_DOC_TYPES];
  isDisabled = false;

  constructor(private readonly route: Router) {}

  ngOnInit(): void {
    if (this.shipment.status !== STATUS_UNLOADING && this.document.name === POD_LABEL_TYPE) {
      this.isDisabled = true;
    }
  }

  navigateToUpload(type: string): void {
    const id = this.document.document ? this.document?.document[0]?.id : '';

    if (this.singleDocumentFiles.includes(type) && id) {
      const path = `${SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW}/${this.shipment.id}/${id}`;
      this.route.navigate([path]);
    } else {
      let path = `/${SHIPMENTS_ROUTE_MY_SHIPMENTS}/${this.shipment.id}/${ROUTE_DOCUMENTS}/${type}`;
      this.route.navigate([path]);
    }
  }
}
