import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ComponentType } from '@angular/cdk/portal';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { Location } from 'src/app/loads-shared/shipment-data/models/location';
import {
  SHIPMENT_STATUSES_PER_STATUS,
  SHIPMENT_STATUSES_UI_MAP,
  SHIPMENT_STATUSES
} from 'src/app/loads-shared/shipment-data/models/shipment-status';
import { STATUS_UNLOADING } from '../shared/constants';

@Component({
  selector: 'app-shipment-change-status-action-sheet',
  templateUrl: './shipment-change-status-action-sheet.component.html',
  styleUrls: ['./shipment-change-status-action-sheet.component.scss']
})
export class ShipmentChangeStatusActionSheetComponent implements OnInit {
  saving: boolean;
  actions: Action[] = [];
  nextSheetActions: Component[];
  shipment: Shipment;
  totalActions: number;
  currentActionStep: number;
  callback: Function;
  isPodUploaded: boolean = false;
  btnPodVisible: boolean = false;
  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<ShipmentChangeStatusActionSheetComponent>,
    private readonly bottomSheet: MatBottomSheet,
    private readonly shipmentsDataService: ShipmentsDataService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.shipment = data?.shipment;
    this.nextSheetActions = data?.nextSheetActions;
    this.totalActions = data?.totalActions ?? this.nextSheetActions?.length ?? 0;
    this.currentActionStep = data?.currentActionStep ?? 1;
    this.callback = data?.callback;
    this.isPodUploaded = data?.isPodUploaded;
    this.btnPodVisible = !this.isPodUploaded && this.shipment?.status === SHIPMENT_STATUSES.UNLOADING;
  }

  ngOnInit(): void {
    const statuses = SHIPMENT_STATUSES_PER_STATUS[this.shipment?.status] as string[];

    if (!statuses || !this.shipment) {
      this.bottomSheetRef.dismiss();
      return;
    }

    Object.keys(SHIPMENT_STATUSES_PER_STATUS).forEach(status => {
      const statusObj = {
        name: SHIPMENT_STATUSES_UI_MAP[status]?.TEXT,
        action: () => this.onStatusSelect(status),
        disabled: !statuses.includes(status),
        type: this.shipment.status === status ? 'current' : null
      } as Action;
      if (this.shipment.status === STATUS_UNLOADING && statusObj.name.toLowerCase() === SHIPMENT_STATUSES.DELIVERED) {
        statusObj.disabled = !this.isPodUploaded;
      }
      this.actions.push(statusObj);
    });
  }

  onStatusSelect(status: string): void {
    this.saving = true;

    navigator.geolocation.getCurrentPosition(
      position => {
        const saveModel = {
          status: status,
          events: [
            {
              status: status,
              location: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
              } as Location
            }
          ]
        } as Shipment;

        this.submitSaveShipment(saveModel);
      },
      () => {
        const saveModel = {
          status: status,
          events: [{ status: status }]
        } as Shipment;

        this.submitSaveShipment(saveModel);
      }
    );
  }

  private submitSaveShipment(saveModel: Shipment): void {
    if (!this.nextSheetActions?.length) {
      this.saveShipmentStatus(saveModel);
    } else {
      const nextActionSheetComponent = this.nextSheetActions.shift() as ComponentType<any>;
      this.bottomSheet.open(nextActionSheetComponent, {
        data: {
          shipment: this.shipment,
          currentData: saveModel,
          totalActions: this.totalActions,
          currentActionStep: this.currentActionStep + 1,
          nextSheetActions: this.nextSheetActions,
          callback: this.callback
        }
      });
    }
  }

  private saveShipmentStatus(saveModel: Shipment): void {
    this.shipmentsDataService.updateShipment(this.shipment.id, saveModel).subscribe(() => {
      this.bottomSheetRef.dismiss(true);
      this.callback?.();
    });
  }
  dismissBottomSheetToUpload(): void {
    this.bottomSheetRef.dismiss({ navigateToDocument: true });
  }
}
