<div fxLayout="column" class="employment-info " *ngIf="!isGapItem">
  <div fxFlex>
    <div fxFlex="60" *ngIf="!model.isCurrentEmployment" class="full-width field-container">
      <span class="field-value">{{ model.fromDate | date: 'MMM yyyy' }} - {{ model?.toDate | date: 'MMM yyyy' }}</span>
    </div>
    <div fxFlex="60" class="full-width field-container" *ngIf="model.isCurrentEmployment">
      <span class="field-value">{{ model.fromDate | date: 'MMM yyyy' }} - Current</span>
    </div>
    <div fxFlex="40" class="action-icons">
      <mat-icon svgIcon="pencil-icon" (click)="onUpdateEmp()" data-test="edit-button"></mat-icon>
      <mat-icon
        svgIcon="delete-outlined"
        class="delete-outlined"
        (click)="onDeleteEmp()"
        data-test="delete-record-button"
        *ngIf="checkDeletePermission()"
      ></mat-icon>
      <mat-icon *ngIf="model?.isCurrentEmployment && model?.fromDate" svgIcon="add-plus-icon" class="add-plus-icon"
        [matMenuTriggerFor]="addmenu" data-test="add-icon"></mat-icon>
      <div>
        <mat-menu #addmenu="matMenu" class="button-menu-container" xPosition="after">
          <button mat-menu-item (click)="onAddNewCurrentEmployment(true, this.model)" class="menu-button">
            Employment Period
          </button>
          <button mat-menu-item (click)="onAddNewCurrentEmployment(false, this.model)" class="menu-button">
            Unemployment Period
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="full-width field-container">
    <span class="employer-value" *ngIf="model?.employmentState == 'employed'">{{ model.employer }}</span>
    <span class="employer-value" *ngIf="model?.employmentState == 'unemployed'">Unemployed</span>
  </div>
</div>

<div fxLayout="column" class="missing-data-container" *ngIf="isGapItem || model.isOverLap">
  <div fxFlex>
    <div fxFlex="60" class="full-width field-container">
      <span class="text-missing-data" [ngClass]="{ 'text-overlap': model.isOverLap }">{{
        model.isOverLap ? 'Overlap Data' : 'Missing Data'
      }}</span>
    </div>
    <div fxFlex="40" class="action-icons" *ngIf="!model.isOverLap">
      <mat-icon svgIcon="add-plus-icon" [matMenuTriggerFor]="menu"></mat-icon>
      <mat-menu #menu="matMenu" class="button-menu-container" xPosition="after">
        <button mat-menu-item (click)="openEmploymentForm()" class="menu-button">Employment Period</button>
        <button mat-menu-item (click)="openUnEmploymentForm()" class="menu-button">Unemployment Period</button>
      </mat-menu>
    </div>
  </div>
</div>
