<div class="header-navigation-back">
  <mat-icon (click)="onNavigateToMyProfile()">arrow_back</mat-icon>
  <span fxFlex class="title">Refer a friend</span>
</div>
<div fxFlex fxLayout="column" class="margin-sides margin-bottom-lg">
  <div fxFlex class="message-container padding-top-md" [innerHTML]="body"></div>
  <p class="padding-bottom-sm referral-link" data-test="referral-link">{{ referralLink }}</p>
</div>
<app-actions
  [actions]="actions"
  [ngClass]="showNavBar ? 'fixed-bottom' : 'native-app-fixed-bottom'"
  data-test="copy-link-button"
></app-actions>
