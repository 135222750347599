import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';

const CARRIER_INVITE_TYPE = 'carrier-invite';
const DEFAULT_TITLE = 'Signup successful';
@Component({
  selector: 'app-sign-up-success',
  templateUrl: './signup-success.component.html',
  styleUrls: ['./signup-success.component.scss']
})
export class SignupSuccessComponent implements OnInit {
  message: string;
  companyName: string = undefined;
  inviteType: string = undefined;
  title: string = DEFAULT_TITLE;
  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
    this.message = `Hey, thanks for signing up to become a FleetOperate driver.
     Complete your profile so that you can hit the road and start earning cash!`;
  }

  ngOnInit() {
    this.companyName = this.activatedRoute.snapshot.queryParams.companyName;
    this.inviteType = this.activatedRoute.snapshot.queryParams.invitationType;
    this.assignTitleAndMessage();
  }
  assignTitleAndMessage(): void {
    if (this.inviteType === CARRIER_INVITE_TYPE) {
      this.title = 'Account Activation successful';
    }
    if (this.companyName) {
      this.title = `${DEFAULT_TITLE} for ${this.companyName}`;
    }
  }
  onSignUpSuccess(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }
}
