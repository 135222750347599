import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Action } from '../models/action.model';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  @Input() actions: Action[];
  constructor(private readonly sanitizer: DomSanitizer) {}

  ngOnChanges(): void {
    if (this.actions) {
      this.actions.forEach((element, index) => {
        element.type = this.determineActionSize(index, this.actions.length);
      });
    }
  }

  ngOnInit() {}

  private determineActionSize(index: number, length: number): string {
    return length > index + 1 || (index % 2 === 1 && length === index + 1) ? 'half-width' : 'full-width';
  }
}
