import { Injectable } from '@angular/core';
import { DocumentsDataModule } from './documents-data.module';
import { DocumentsApiService } from './documents-api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SendDocumentResponse } from './models/send-document-response';
import { SendDocumentRequest } from './models/send-document-request';
import { Response } from '../services/response';
import { HttpOptions } from '../services/http-options';
import { HttpHeaders } from '@angular/common/http';
import { ShipmentDocument } from './models/shipment-document';
import { JobDocument } from 'src/app/jobs-shared/job-data/models/job-documents';

@Injectable({
  providedIn: DocumentsDataModule
})
export class DocumentsDataService {
  shipmentDocumentTypes;
  shipmentDocuments;
  constructor(private readonly documentsAPISerive: DocumentsApiService) {}

  loadDriverDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsAPISerive.getDriverDocumentTypes().pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data.Driver as DocumentType[];
        }

        return undefined;
      })
    );
  }

  sendDriverDocument(driverID: string, documentType: string, extension: string): Observable<SendDocumentResponse> {
    const sendDocumentRequest = { documentType, extension } as SendDocumentRequest;

    return this.documentsAPISerive.sendDriverDocument(driverID, sendDocumentRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }

        return undefined;
      })
    );
  }

  uploadDriverDocument(file: any, url: string): Observable<Response> {
    return this.documentsAPISerive.uploadDriverDocument(file, url);
  }

  loadShipmentDocumentTypes(): Observable<DocumentType[]> {
    return this.documentsAPISerive.getShipmentDocumentTypes().pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data.Shipment as DocumentType[];
        }

        return undefined;
      })
    );
  }

  getShipmentDocuments(shipmentId: string): Observable<ShipmentDocument[]> {
    return this.documentsAPISerive.getShipmentDocuments(shipmentId).pipe(
      map((response: any) => {
        this.shipmentDocuments = response.data;
        return response.data as ShipmentDocument[];
      })
    );
  }
  getShipmentDocumentTypesLookup(): Observable<any[]> {
    return this.documentsAPISerive.getShipmentDocumentTypesLookup().pipe(
      map((response: any) => {
        this.shipmentDocumentTypes = response.data?.Shipment;
        return response.data?.Shipment;
      })
    );
  }

  getShipmentDocument(shipmentId: string, documentId: string, isTracking?: boolean): Observable<ShipmentDocument> {
    return this.documentsAPISerive
      .getShipmentDocument(shipmentId, documentId, isTracking)
      .pipe(map((response: any) => response.data as ShipmentDocument));
  }

  sendShipmentDocument(shipmentID: string, documentType: string, extension: string, shipmentStopId?: string): Observable<SendDocumentResponse> {
    const sendDocumentRequest = { documentType, extension, shipmentStopId } as SendDocumentRequest;

    return this.documentsAPISerive.sendShipmentDocument(shipmentID, sendDocumentRequest ).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }

        return undefined;
      })
    );
  }

  deleteShipmentDocument(shipmentId: string, documentId: string): Observable<Response> {
    return this.documentsAPISerive.deleteShipmentDocument(shipmentId, documentId);
  }

  getJobDocuments(jobId: string): Observable<JobDocument[]> {
    return this.documentsAPISerive.getJobDocuments(jobId).pipe(map((response: any) => response.data as JobDocument[]));
  }

  getJobDocument(shipmentId: string, documentId: string): Observable<JobDocument> {
    return this.documentsAPISerive
      .getJobDocument(shipmentId, documentId)
      .pipe(map((response: any) => response.data as JobDocument));
  }

  sendJobDocument(jobId: string, documentType: string, extension: string): Observable<SendDocumentResponse> {
    const sendDocumentRequest = { documentType, extension } as SendDocumentRequest;

    return this.documentsAPISerive.sendJobDocument(jobId, sendDocumentRequest).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }

        return undefined;
      })
    );
  }

  deleteJobDocument(jobId: string, documentId: string): Observable<Response> {
    return this.documentsAPISerive.deleteJobDocument(jobId, documentId);
  }
}
