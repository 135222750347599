import { Injectable, Inject, forwardRef } from '@angular/core';
import { Router } from '@angular/router';

import { first, finalize, tap, catchError } from 'rxjs/operators';
import { ShipmentDataService } from './shipment-data/shipment-data.service';
import { Shipment } from './shipment-data/models/shipment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ShipmentConfirmationDialogComponent } from './shipment-confirmation-dialog/shipment-confirmation-dialog.component';
import { Observable, Subject } from 'rxjs';
import { ShipmentUtilsService } from './shipment-utils.service';
import { Location } from './shipment-data/models/location';
import { ToastService } from 'src/app/shared/toast/toast.service';
import {
  ROUTE_TOKEN_SHIPMENT_CONTACTS,
  ROUTE_TOKEN_SHIPMENT_DOCUMENTS,
  ROUTE_TOKEN_UPCOMING_LOADS,
  ROUTE_TOKEN_MY_SHIPMENT
} from './route-tokens';
import {
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_COMPLETED,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUS_OUT_FOR_DELIVERY
} from './shipment-data/models/shipment-status';
import { Action } from '../shared/actions/models/action.model';
import { CARRIER, DISPATCHER, DISPATCHER_TYPE, SHIPPER, TRIP_TYPE } from '../shared/constants';
import { ShipmentsFeedbackDialogComponent } from '../shipments/shipments-feedback-dialog/shipments-feedback-dialog.component';

const ERROR_MESSAGE = 'There was an error. Please try again.';
const ERROR_MESSAGE_NO_LOCATION_PERMISSION = 'Unable to access device location.';
const dialogWidth = '400px';
const COMPLETE_DELIVERY_TITLE = 'Complete Delivery?';
const COMPLETE_PICKUP_TITLE = 'Complete Pickup?';
const COMPLETE_DELIVERY_MESSAGE_DISPATCHER =
  'Make sure all the paper work for this shipment is uploaded. Shipment will be marked complete by the dispatcher';
const COMPLETE_DELIVERY_MESSAGE_SHIPPER =
  'Make sure all the paper work for this shipment is uploaded. Shipment will be marked complete by the shipper';
const COMPLETE_DELIVERY_MESSAGE_CARRIER =
  'Make sure all the paper work for this trip is uploaded. Trip will be marked complete by the carrier';
const COMPLETE_PICKUP_MESSAGE = 'Make sure all the paper work for this shipment is uploaded.';
const UNDO_PICKUP_MESSAGE =
  'Confirming this action will put this load back to the Up Coming shipments list and notify the {user}';

@Injectable()
export class ShipmentActionsService {
  $message: Observable<string>;

  private messageSubject: Subject<string>;

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly shipmentUtilsService: ShipmentUtilsService,
    private readonly toastService: ToastService,
    @Inject(forwardRef(() => ROUTE_TOKEN_SHIPMENT_CONTACTS)) public routeShipmentContacts: string,
    @Inject(forwardRef(() => ROUTE_TOKEN_SHIPMENT_DOCUMENTS)) public routeShipmentDocuments: string,
    @Inject(forwardRef(() => ROUTE_TOKEN_UPCOMING_LOADS)) public routeUpcomingLoads: string,
    @Inject(forwardRef(() => ROUTE_TOKEN_MY_SHIPMENT)) public routeMyShipment: string // @Inject(forwardRef(() => ROUTE_TOKEN_ENTRY)) public routeEntry: string
  ) {
    this.messageSubject = new Subject<string>();
    this.$message = this.messageSubject.asObservable();
  }

  createUndoCompletePickupAction(shipment: Shipment): Action {
    const shipmentStatus = shipment && shipment.status ? shipment.status : undefined;

    if (!shipmentStatus) {
      return undefined;
    }

    const isOutForDeliveryShipmentForToday = this.shipmentUtilsService.isOutForDeliveryShipmentForDay(shipment);

    if (isOutForDeliveryShipmentForToday) {
      return {
        name: 'Undo complete pickup',
        action: (data: any) => this.onUndoCompletePickup(shipment),
        disabled: false,
        color: 'primary'
      } as Action;
    }

    return undefined;
  }

  createShipmentContactsAction(shipment: Shipment): Action {
    const validShipment = shipment && shipment.id && shipment.status;

    if (!validShipment) {
      return undefined;
    }
    const title = this.getShipmentType(shipment.shipmentID) === TRIP_TYPE ? 'Trip' : 'Shipment';
    return {
      name: `${title} Contacts`,
      action: (data: any) => this.onShipmentContacts(shipment.id),
      disabled: false,
      color: ''
    } as Action;
  }

  createShipmentDocumentsAction(shipment: Shipment): Action {
    const validShipment = shipment && shipment.id && shipment.status;

    if (!validShipment) {
      return undefined;
    }

    if (
      shipment.status === SHIPMENT_STATUS_OUT_FOR_DELIVERY ||
      shipment.status === SHIPMENT_STATUS_BOOKED ||
      shipment.status === SHIPMENT_STATUS_DELIVERED ||
      shipment.status === SHIPMENT_STATUS_COMPLETED
    ) {
      return {
        name: 'Documents',
        action: (data: any) => this.onShipmentDocuments(shipment.id),
        disabled: false,
        color: ''
      } as Action;
    }
    return undefined;
  }

  createReportLocationAction(shipment: Shipment): Action {
    const shipmentStatus = shipment && shipment.id && shipment.status ? shipment.status : undefined;

    if (!shipmentStatus) {
      return undefined;
    }
    const isOngoingShipment = this.shipmentUtilsService.isOngoingShipment(shipment);

    if (isOngoingShipment) {
      return {
        name: 'Report Location',
        action: (data: any) => this.onReportLocation(shipment.id),
        disabled: false,
        color: ''
      } as Action;
    }
  }

  createCompletePickupAction(shipment: Shipment): Action[] {
    const actions = [] as Action[];
    const shipmentStatus = shipment && shipment.status ? shipment.status : undefined;

    if (!shipmentStatus) {
      return actions;
    }
    const isUpcomingShipmentForToday = this.shipmentUtilsService.isUpcomingShipmentForDay(shipment);

    if (isUpcomingShipmentForToday) {
      actions.push(this.createCompletePickupActionButton(shipment, true));
    }
    return actions;
  }

  createCompleteDeliveryAction(shipment: Shipment): Action[] {
    const actions = [] as Action[];
    const shipmentStatus = shipment && shipment.status ? shipment.status : undefined;

    if (!shipmentStatus) {
      return actions;
    }
    const isOngoingShipment = this.shipmentUtilsService.isOngoingShipment(shipment);

    if (isOngoingShipment) {
      actions.push(this.createCompleteDeliveryActionButton(shipment));
    }
    return actions;
  }

  showShipmentFeedbackDialog(shipment: Shipment): MatDialogRef<ShipmentsFeedbackDialogComponent> {
    const dialogRef = this.dialog.open(ShipmentsFeedbackDialogComponent, {
      panelClass: 'wide-pane',
      data: { shipment },
      disableClose: true
    });

    return dialogRef;
  }

  private createCompletePickupActionButton(shipment: Shipment, enabled: boolean): Action {
    return {
      name: 'COMPLETE PICKUP',
      action: (data: any) => this.onCompletePickup(shipment),
      disabled: !enabled,
      color: 'complete_pickup_delivery_load'
    } as Action;
  }

  private createCompleteDeliveryActionButton(shipment: Shipment) {
    return {
      name: 'COMPLETE DELIVERY',
      action: (data: any) => this.onCompleteDelivery(shipment),
      disabled: false,
      color: 'complete_pickup_delivery_load'
    } as Action;
  }

  private onCompleteDelivery(shipment: Shipment): void {
    this.showCompleteDeliveryShipmentConfirmation(shipment.id, shipment.shipmentID);
  }

  private showCompleteDeliveryShipmentConfirmation(shipmentID: string, shipmentNumber: string): void {
    const actions = this.createAcceptDialogActions();
    const type = this.getShipmentType(shipmentNumber);
    const dialogRef = this.dialog.open(ShipmentConfirmationDialogComponent, {
      width: '400px',
      data: {
        title: COMPLETE_DELIVERY_TITLE,
        message:
          type === TRIP_TYPE
            ? COMPLETE_DELIVERY_MESSAGE_CARRIER
            : type === DISPATCHER_TYPE
            ? COMPLETE_DELIVERY_MESSAGE_DISPATCHER
            : COMPLETE_DELIVERY_MESSAGE_SHIPPER,
        actions
      },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = () => this.completeDeliveryForShipment(dialogRef, confirmAction, cancelAction, shipmentID);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private completeDeliveryForShipment(
    dialogRef: MatDialogRef<ShipmentConfirmationDialogComponent>,
    confirmAction: Action,
    cancelAction: Action,
    shipmentID: string
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;
    this.shipmentDataService
      .updateShipmentAsDelivered(shipmentID)
      .pipe(
        first(),
        tap(() => this.updateMessage(null)),
        catchError((error: string) => {
          this.updateMessage(ERROR_MESSAGE);
          throw error;
        }),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe(() => {
        this.navigateToNextPage();
      });
  }

  private showCompletePickupShipmentConfirmation(shipmentID: string): void {
    const actions = this.createAcceptDialogActions();
    const dialogRef = this.dialog.open(ShipmentConfirmationDialogComponent, {
      width: dialogWidth,
      data: {
        title: COMPLETE_PICKUP_TITLE,
        message: COMPLETE_PICKUP_MESSAGE,
        actions
      },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = () => this.completePickupForShipment(dialogRef, confirmAction, cancelAction, shipmentID);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private onCompletePickup(shipment: Shipment): void {
    this.showCompletePickupShipmentConfirmation(shipment.id);
  }

  private completePickupForShipment(
    dialogRef: MatDialogRef<ShipmentConfirmationDialogComponent>,
    confirmAction: Action,
    cancelAction: Action,
    shipmentID: string
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;
    this.shipmentDataService
      .updateShipmentAsPickedUp(shipmentID)
      .pipe(
        first(),
        tap(() => this.updateMessage(null)),
        catchError((error: string) => {
          this.updateMessage(ERROR_MESSAGE);
          throw error;
        }),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe(() => {
        this.navigateToNextPage();
      });
  }

  private updateMessage(message: string): void {
    this.messageSubject.next(message || null);
  }

  private onUndoCompletePickup(shipment: Shipment): void {
    this.showUndoCompletePickupShipmentConfirmation(shipment.id, shipment.shipmentID);
  }

  private onShipmentContacts(shipmentId: string): void {
    this.navigateToShipmentContacts(shipmentId);
  }

  private onShipmentDocuments(shipmentId: string): void {
    this.navigateToShipmentDocuments(shipmentId);
  }

  private onReportLocation(shipmentId: string): void {
    navigator.geolocation.getCurrentPosition(
      position => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        } as Location;
        this.reportLocationForShipment(shipmentId, location);
      },
      (error: any) => {
        this.updateMessage(ERROR_MESSAGE_NO_LOCATION_PERMISSION);
      }
    );
  }

  private showUndoCompletePickupShipmentConfirmation(shipmentID: string, shipmentNumber: string): void {
    const actions = this.createAcceptDialogActions();
    const type = this.getShipmentType(shipmentNumber);
    const dialogRef = this.dialog.open(ShipmentConfirmationDialogComponent, {
      data: {
        title: 'Undo complete pickup',
        message:
          type === TRIP_TYPE
            ? UNDO_PICKUP_MESSAGE.replace('{user}', CARRIER)
            : type == DISPATCHER_TYPE
            ? UNDO_PICKUP_MESSAGE.replace('{user}', DISPATCHER)
            : UNDO_PICKUP_MESSAGE.replace('{user}', SHIPPER),
        actions
      },
      disableClose: true
    });
    const confirmAction = actions[0];
    const cancelAction = actions[1];
    confirmAction.action = () => this.undoCompletePickupForShipment(dialogRef, confirmAction, cancelAction, shipmentID);
    cancelAction.action = () => this.dialogCancelAction(dialogRef, confirmAction, cancelAction);

    dialogRef.afterClosed().subscribe();
  }

  private dialogCancelAction(dialogRef: MatDialogRef<any>, confirmAction: Action, cancelAction: Action): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;

    dialogRef.close();
  }

  private createAcceptDialogActions(): Action[] {
    const actions: Action[] = [];

    const OkayAction = {
      name: 'Yes',
      action: (data: any) => {},
      disabled: false
      // color: 'confirm'
    } as Action;
    actions.push(OkayAction);

    const NoAction = {
      name: 'No',
      action: (data: any) => {},
      disabled: false
      // color: 'cancel'
    } as Action;
    actions.push(NoAction);

    return actions;
  }

  private undoCompletePickupForShipment(
    dialogRef: MatDialogRef<ShipmentConfirmationDialogComponent>,
    confirmAction: Action,
    cancelAction: Action,
    shipmentID: string
  ): any {
    confirmAction.disabled = true;
    cancelAction.disabled = true;
    this.shipmentDataService
      .updateShipmentAsUndoPickedUp(shipmentID)
      .pipe(
        first(),
        tap(() => this.updateMessage(null)),
        catchError((error: string) => {
          this.updateMessage(ERROR_MESSAGE);
          throw error;
        }),
        finalize(() => {
          dialogRef.close();
          confirmAction.disabled = false;
          cancelAction.disabled = false;
        })
      )
      .subscribe(() => {
        this.navigateToUpcomingLoads();
      });
  }

  private reportLocationForShipment(shipmentID: string, location: Location): any {
    this.shipmentDataService
      .updateShipmentLocation(shipmentID, location)
      .pipe(
        first(),
        tap(() => {
          this.updateMessage(null);
          this.toastService.showSuccess('Sucessfully reported location');
        }),
        catchError((error: string) => {
          this.updateMessage(ERROR_MESSAGE);
          throw error;
        })
      )
      .subscribe();
  }

  private navigateToShipmentContacts(shipmentId: string): void {
    this.router.navigate([this.routeShipmentContacts, shipmentId]);
  }

  private navigateToShipmentDocuments(shipmentId: string): void {
    this.router.navigate([this.routeShipmentDocuments, shipmentId]);
  }

  private navigateToUpcomingLoads(): void {
    this.router.navigateByUrl(this.routeUpcomingLoads);
  }
  private navigateToNextPage(): void {
    this.router.navigateByUrl(this.routeMyShipment);
  }

  private getShipmentType(shipmentNumber): string {
    return shipmentNumber?.substring(0, 3);
  }
}
