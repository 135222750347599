import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DriverGuardService } from '../core/guards/driver-guard.service';
import {
  ShipmentContactsComponent,
  ShipmentDocumentComponent,
  ShipmentDocumentsComponent
} from '../loads-shared/loads-shared.module';
import { MyTripsComponent } from './my-trips/my-trips.component';
import {
  TRIPS_ROUTE_MYTRIPS,
  TRIPS_ROUTE_SHIPMENT_CONTACTS,
  TRIPS_ROUTE_SHIPMENT_CONTACTS_SHORT,
  TRIPS_ROUTE_SHIPMENT_DOCUMENT,
  TRIPS_ROUTE_SHIPMENT_DOCUMENTS,
  TRIPS_ROUTE_SHIPMENT_DOCUMENTS_SHORT,
  TRIPS_ROUTE_SHIPMENT_DOCUMENT_SHORT,
  TRIPS_ROUTE_TRIP_DETAILS,
  TRIPS_ROUTE_TRIP_DETAILS_SHORT
} from './routes';
import { TripDetailsComponent } from './trip-details/trip-details.component';
import { TripsComponent } from './trips/trips.component';
export const loadRoutes: Routes = [
  {
    path: '',
    component: TripsComponent,
    canActivate: [DriverGuardService],
    children: [
      {
        path: '',
        redirectTo: TRIPS_ROUTE_MYTRIPS,
        pathMatch: 'full',
        canActivate: [DriverGuardService]
      },
      {
        path: TRIPS_ROUTE_MYTRIPS,
        component: MyTripsComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${TRIPS_ROUTE_TRIP_DETAILS_SHORT}/:id`,
        component: TripDetailsComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${TRIPS_ROUTE_SHIPMENT_CONTACTS_SHORT}/:id`,
        component: ShipmentContactsComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${TRIPS_ROUTE_SHIPMENT_DOCUMENTS_SHORT}/:id`,
        component: ShipmentDocumentsComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${TRIPS_ROUTE_SHIPMENT_DOCUMENT_SHORT}/:id/:document-id`,
        component: ShipmentDocumentComponent,
        canActivate: [DriverGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(loadRoutes)],
  exports: [RouterModule]
})
export class TripsRoutingModule {}
