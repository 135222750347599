import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingGifComponent } from './loading-gif/loading-gif.component';

@NgModule({
  declarations: [LoadingGifComponent],
  imports: [CommonModule],
  exports: [LoadingGifComponent]
})
export class LoadingGifModule {}
