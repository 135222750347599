<div
  fxLayoutAlign="start"
  *ngIf="incorpForm"
  fxLayout="column"
  class="page gray-font-color font-family bodyBackground incorporation"
>
  <form [formGroup]="incorpForm">
    <div class="header-navigation-back">
      <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
      <span fxFlex class="title">Incorporation / WSIB</span>
      <button
        mat-button
        [disabled]="loading"
        [class.save-disabled]="loading"
        class="header-action-button"
        (click)="onSubmit()"
        data-test="save-button"
      >
        Save
      </button>
    </div>
    <mat-error *ngIf="errorMessage" class="error padding-left-md">
      {{ errorMessage }}
    </mat-error>
    <div class="form-field">
      <div class="description">If you are an Owner Operator, please enter the details below</div>

      <!-- Incorporation -->
      <div class="section-header">
        <p>Incorporation</p>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-placeholder>Incorporation Number</mat-placeholder>
          <input matInput formControlName="incorporationNumber" required data-test="incorporation-number" />
          <mat-error *ngIf="incorporationNumber.touched && incorporationNumber.invalid">
            {{ getIncorporationNumberErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-placeholder>CRA Number</mat-placeholder>
          <input matInput formControlName="craNumber" required data-test="cra-number" />
          <mat-error *ngIf="craNumber.touched && craNumber.invalid">
            {{ getCRANumberErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-placeholder>Incorporation Name</mat-placeholder>
          <input matInput formControlName="incorporationName" required data-test="incorporation-name" />
          <mat-error *ngIf="incorporationName.touched && incorporationName.invalid">
            {{ getIncorporationNameErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="full-width">
          <mat-placeholder>Incorporation Address</mat-placeholder>
          <input matInput formControlName="incorporationAddress" data-test="incorporation-address" required />
          <mat-error *ngIf="incorporationAddress.touched && incorporationAddress.invalid">
            {{ getIncorporationAddressErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>

      <!-- WSIB -->
      <div class="section-header margin-top-md">
        <p>WSIB</p>
      </div>
      <div>
        <div class="field-row">
          <div>
            <span class="field-header">Do you have a WSIB account?</span>
          </div>
          <div>
            <mat-radio-group
              formControlName="haveWorkplaceSafetyAccount"
              #wsibAccount="matRadioGroup"
              fxFlex
              class="button-container"
              (change)="onWSIBorBenefitsChange(haveWorkPlaceSafetyAccountText, wsibAccount.value)"
              [disabled]="loading"
            >
              <mat-radio-button
                class="button-raised"
                [ngClass]="{ active: true === haveWorkplaceSafetyAccount.value }"
                [value]="true"
                data-test="wsib-account-yes"
                >Yes</mat-radio-button
              >
              <mat-radio-button
                class="button-raised"
                [ngClass]="{ active: false === haveWorkplaceSafetyAccount.value }"
                [value]="false"
                data-test="wsib-account-no"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <mat-error *ngIf="haveWorkplaceSafetyAccount.touched && haveWorkplaceSafetyAccount.invalid">
            {{ getHaveWorkplaceSafetyAccountErrorMessage() }}
          </mat-error>

          <!-- Upload WSIB -->
          <div *ngIf="haveWorkplaceSafetyAccount.value" [ngClass]="{ 'invalid-section ': WsibClearance.invalid }">
            <span class="field-header">WSIB clearance *</span>
            <div class="add-file" *ngIf="!WsibClearance.value; else WsibClearanceFile">
              <input
                #WsibClearanceUpload
                type="file"
                [accept]="acceptTypes"
                (change)="onFileChange(WsibClearanceUpload, wsibClearanceText)"
                [hidden]="true"
                data-test="wsib-clearance-file"
              />
              <div class="add-file-content" (click)="WsibClearanceUpload.click()" data-test="btn-wsib-clearance-file">
                <mat-icon svgIcon="upload-file-tree" class="icon-color"></mat-icon>&nbsp;Upload now
              </div>
            </div>
            <ng-template #WsibClearanceFile>
              <div class="added-file">
                <div fxFlex="100" class="added-icon" (click)="viewDocument(wsibClearanceText)">
                  <div class="added-display-icon" fxFlex="10">
                    <mat-icon svgIcon="icon-file-blue" class="added-icon-color"></mat-icon>
                  </div>
                  <div fxFlex="90" class="file-name" data-test="uploaded-file-name">
                    <p>
                      {{
                        !loading
                          ? WsibClearance.value?.name
                            ? WsibClearance.value.name
                            : 'WSIB clearance'
                          : 'Uploading...'
                      }}
                    </p>
                  </div>
                </div>

                <div
                  class="delete-icon"
                  fxFlex="20"
                  (click)="deleteDocuments(wsibClearanceText)"
                  data-test="wsib-clearance-file-delete"
                >
                  <mat-icon svgIcon="delete-icon"></mat-icon>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <!-- Disability Benefits -->
      <div class="section-header margin-top-lg">
        <p>Disability Benefits</p>
      </div>
      <div>
        <div class="field-row">
          <div>
            <span class="field-header">Do you have an alternate Disability benefit?</span>
          </div>
          <div>
            <mat-radio-group
              formControlName="haveDisabilityBenefit"
              #disabilityBenefit="matRadioGroup"
              fxFlex
              class="button-container"
              (change)="onWSIBorBenefitsChange(haveDisabilityBenefitText, disabilityBenefit.value)"
              [disabled]="loading"
            >
              <mat-radio-button
                class="button-raised"
                [ngClass]="{ active: true === haveDisabilityBenefit.value }"
                [value]="true"
                data-test="disability-benefit-yes"
                >Yes</mat-radio-button
              >
              <mat-radio-button
                class="button-raised"
                [ngClass]="{ active: false === haveDisabilityBenefit.value }"
                [value]="false"
                data-test="disability-benefit-no"
                >No</mat-radio-button
              >
            </mat-radio-group>
          </div>
          <mat-error *ngIf="haveDisabilityBenefit.touched && haveDisabilityBenefit.invalid">
            {{ getHaveDisabilityBenefitErrorMessage() }}
          </mat-error>

          <div *ngIf="haveDisabilityBenefit.value">
            <!-- Upload Proof of Insurance -->
            <div class="margin-top-md" [ngClass]="{ 'invalid-section ': DisabilityBenefitProofInsurance.invalid }">
              <span class="field-header">Proof of Insurance *</span>
              <div
                class="add-file"
                *ngIf="!DisabilityBenefitProofInsurance.value; else disabilityBenefitProofInsuranceFile"
              >
                <input
                  #disabilityBenefitProofInsuranceUpload
                  type="file"
                  [accept]="acceptTypes"
                  (change)="onFileChange(disabilityBenefitProofInsuranceUpload, disabilityBenefitProofInsuranceText)"
                  [hidden]="true"
                  data-test="insurance-file"
                />
                <div
                  class="add-file-content"
                  (click)="disabilityBenefitProofInsuranceUpload.click()"
                  data-test="btn-insurance-file"
                >
                  <mat-icon svgIcon="upload-file-tree" class="icon-color"></mat-icon>&nbsp;Upload now
                </div>
              </div>
              <ng-template #disabilityBenefitProofInsuranceFile>
                <div class="added-file">
                  <div
                    fxFlex="100"
                    class="added-icon"
                    [ngClass]="isCreateMode ? 'no-cursor' : ''"
                    (click)="viewDocument(disabilityBenefitProofInsuranceText)"
                  >
                    <div class="added-display-icon" fxFlex="10">
                      <mat-icon svgIcon="icon-file-blue" class="added-icon-color"></mat-icon>
                    </div>
                    <div fxFlex="90" class="file-name" data-test="uploaded-file-name">
                      <p>
                        {{
                          !loading
                            ? DisabilityBenefitProofInsurance.value?.name
                              ? DisabilityBenefitProofInsurance.value.name
                              : 'Proof of Insurance'
                            : 'Uploading...'
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="delete-icon"
                    fxFlex="20"
                    (click)="deleteDocuments(disabilityBenefitProofInsuranceText)"
                    data-test="insurance-file-delete"
                  >
                    <mat-icon svgIcon="delete-icon"></mat-icon>
                  </div>
                </div>
              </ng-template>
            </div>
            <!-- Upload Policy Document -->
            <div class="margin-top-md" [ngClass]="{ 'invalid-section ': DisabilityBenefitPolicyDocument.invalid }">
              <span class="field-header">Policy Document *</span>

              <div
                class="add-file"
                *ngIf="!DisabilityBenefitPolicyDocument.value; else disabilityBenefitPolicyDocumentFile"
              >
                <input
                  #disabilityBenefitPolicyDocumentUpload
                  type="file"
                  [accept]="acceptTypes"
                  (change)="onFileChange(disabilityBenefitPolicyDocumentUpload, disabilityBenefitPolicyDocumentText)"
                  [hidden]="true"
                  data-test="policy-file"
                />
                <div
                  class="add-file-content"
                  (click)="disabilityBenefitPolicyDocumentUpload.click()"
                  data-test="btn-policy-file"
                >
                  <mat-icon svgIcon="upload-file-tree" class="icon-color"></mat-icon>&nbsp;Upload now
                </div>
              </div>
              <ng-template #disabilityBenefitPolicyDocumentFile>
                <div class="added-file">
                  <div fxFlex="100" class="added-icon" (click)="viewDocument(disabilityBenefitPolicyDocumentText)">
                    <div class="added-display-icon" fxFlex="10">
                      <mat-icon svgIcon="icon-file-blue" class="added-icon-color"></mat-icon>
                    </div>
                    <div fxFlex="90" class="file-name" data-test="uploaded-file-name">
                      <p>
                        {{
                          !loading
                            ? DisabilityBenefitPolicyDocument.value?.name
                              ? DisabilityBenefitPolicyDocument.value.name
                              : 'Policy Document'
                            : 'Uploading....'
                        }}
                      </p>
                    </div>
                  </div>

                  <div
                    class="delete-icon"
                    fxFlex="20"
                    (click)="deleteDocuments(disabilityBenefitPolicyDocumentText)"
                    data-test="policy-file-delete"
                  >
                    <mat-icon svgIcon="delete-icon"></mat-icon>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
