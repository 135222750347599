<div>
  <div fxFlex fxLayout="column" class="review">
    <div class="review-heading">
      <div fxFlex="100" fxLayout="row" class="review-header">
        <div fxFlex="20" class="cancel-button">
          <ngx-skeleton-loader appearance="line" [theme]="{ width: '80%', height: '28px' }"></ngx-skeleton-loader>
        </div>
        <div fxFlex="60" class="profile">
          <ngx-skeleton-loader
            appearance="circle"
            [theme]="{
              width: '100px',
              height: '100px'
            }"
          ></ngx-skeleton-loader>
        </div>

        <div fxFlex="20" class="sign-in">
          <ngx-skeleton-loader appearance="line" [theme]="{ width: '80%', height: '28px' }"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="review-info-loading" fxFlex fxLayout="column">
      <ngx-skeleton-loader
        appearance="line"
        count="12"
        [theme]="{
          width: '90%',
          height: '30px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
