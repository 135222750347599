<div class="header-navigation-back">
  <mat-icon (click)="onNavigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
  <span fxFlex class="title">Shipment Document</span>
</div>
<div class="container" *ngIf="!loading">
  <div fxLayout class="margin-bottom-md document-info-row">
    <div *ngIf="!(document.role === 'Driver' && (document.type === 'ACEeManifest' || document.type === 'ACIeManifest'))" fxFlex="40">{{ document?.typeDisplayName || '-' }}</div>
    <div *ngIf="document.role === 'Driver' && (document.type === 'ACEeManifest' || document.type === 'ACIeManifest')" fxFlex="40">Stamped {{ document?.typeDisplayName || '-' }}</div>
    <div fxFlex="40" *ngIf="!isReadOnlyDocument">
      <button class="btn-download" (click)="openActionSheet()" data-test="action-button">
        Actions
      </button>
    </div>
  </div>

  <img [src]="previewImage" *ngIf="previewImage" class="imagePreview padding-md" data-test="preview-image" />
  <iframe
    [src]="safeUrl"
    class="safeUrl"
    *ngIf="safeUrl"
    frameborder="0"
    loading="lazy"
    class="iframe"
    data-test="preview-document"
  ></iframe>
</div>
