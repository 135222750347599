import { Component, Input, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { StorageService } from 'src/app/shared/storage/storage.service';

const KEY_DRIVER = 'driver';
const LESS_THAN_10K = 'Less than 10K';
const MORE_THAN_100K = 'More than 100K';
@Component({
  selector: 'app-dqf-driver-driving-experience',
  templateUrl: './dqf-driver-driving-experience.component.html',
  styleUrls: ['./dqf-driver-driving-experience.component.scss']
})
export class DqfDriverDrivingExperienceComponent implements OnInit {
  @Input() version: number;
  data = [];
  constructor(private storageService: StorageService, private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.getDriverExperience();
  }

  private getDriverExperience(): void {
    if (this.version > 0) {
      this.registrationService.getDrivingExperience(this.version).subscribe((response: any[]) => {
        this.bindData(response);
      })
    } else {
      const driver = this.storageService.find(KEY_DRIVER);
      this.bindData(driver.experience);
    }
  }

  private bindData(experience): void {
    if (experience?.noTruckingExperience) {
      this.data.push({ propertyName: 'No Truck Driving Experience', propertyValue: 'Yes' })
    } else {
      if (experience?.truckTypes?.length) {
        experience?.truckTypes?.forEach(element => {
          const trucks = this.getTruckTypes(element)
          let experience = null;
          let miles = null
          if (element.milesDriven === '10K-50K' || element.milesDriven === '50K-100K') {
            const splittedValue = element.milesDriven.split('-')
            miles = `Between ${splittedValue[0]} - ${splittedValue[1]}`
          }
          else if (element.milesDriven === 'less10K') {
            miles = LESS_THAN_10K
          }
          else if (element.milesDriven === 'greater100K') {
            miles = MORE_THAN_100K
          }

          if (element.monthsOfExperience >= 12) {
            let years = Math.floor(element.monthsOfExperience / 12);
            let months = element.monthsOfExperience % 12;
            experience = months ? `${years} years ${months} months` : `${years} years`;
          } else {
            experience = `${element.monthsOfExperience} months`;
          }
          if (element.type !== 'others') {
            this.data.push({ propertyName: element.displayType, propertyValue: trucks, subPropertyValue: miles ? `${experience} , ${miles}` : `${experience}` })
          } else {
            this.data.push({ propertyName: element.displayType, propertyValue: element.equipment, subPropertyValue: miles ? `${experience} , ${miles}` : `${experience}` })
          }
        });
      }
    }
  }

  private getTruckTypes(element): string {
    let truckTypes = [];
    if (element['dumpTruck']) {
      truckTypes.push('Dump Truck')
    }
    if (element['flat']) { truckTypes.push('Flat') }
    if (element['reefer']) { truckTypes.push('Reefer') }
    if (element['tank']) { truckTypes.push('Tank') }
    if (element['van']) { truckTypes.push('Van') }
    if (truckTypes.length) {
      return truckTypes.join(', ')
    }
    return null;
  }

}
