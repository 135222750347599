import { Injectable } from '@angular/core';
import {
  SHIPMENT_STATUS_UNSECURED,
  SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING,
  SHIPMENT_STATUS_BOOKED,
  SHIPMENT_STATUS_OUT_FOR_DELIVERY,
  SHIPMENT_STATUS_CANCELLED,
  SHIPMENT_STATUS_INACTIVE,
  SHIPMENT_STATUS_DELIVERED,
  SHIPMENT_STATUSES
} from './shipment-data/models/shipment-status';
import { Shipment } from './shipment-data/models/shipment';
import { Status } from '../shared/status-banner/models/status.model';
import { DateTimeService } from '../shared/date-time-convertor/date-time.service';
import { OfferTypeService } from './offer-type.service';
import { QUOTE_STATUS_CONFIRMED } from './shipment-data/models/quote.status';
import { DriverApiService } from '../shared/services/driver-api.service';

@Injectable()
export class ShipmentUtilsService {
  constructor(
    private readonly dateTmeConversion: DateTimeService,
    private readonly driverApiService: DriverApiService,
    private readonly offerTypeService: OfferTypeService
  ) {}

  sortShipmentsPickupAsc(shipments: Shipment[]): void {
    if (!shipments) {
      return;
    }

    shipments.sort((shipmentA: Shipment, shipmentB: Shipment) => {
      const pickupDateA = this.dateTmeConversion.getFormattedDate(shipmentA.pickup.pickupDateFrom);
      const pickupDateB = this.dateTmeConversion.getFormattedDate(shipmentB.pickup.pickupDateFrom);
      return pickupDateB.getTime() - pickupDateA.getTime();
    });
  }

  removeDriverShipments(shipments: Shipment[]): Shipment[] {
    if (!shipments) {
      return;
    }
    const filteredShipments = shipments.filter((shipment: Shipment) => {
      if (!shipment) {
        return;
      } else if (!shipment.quotes) {
        return shipment;
      }
    });
    return filteredShipments;
  }

  findInterested(shipments: Shipment[]): Shipment[] {
    if (!shipments) {
      return;
    }

    let foundShipments: Shipment[];

    foundShipments = shipments.filter((shipment: Shipment) => {
      if (!shipment || !shipment.status || !shipment.pickup.pickupDateFrom) {
        return;
      }

      const validShipmentStatus =
        shipment.status === SHIPMENT_STATUS_UNSECURED ||
        shipment.status === SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING ||
        shipment.status === SHIPMENT_STATUS_INACTIVE;
      return validShipmentStatus;
    });

    return foundShipments;
  }

  findUpcoming(shipments: Shipment[]): Shipment[] {
    let foundShipments: Shipment[];
    const driverId = this.driverApiService.driverID;

    foundShipments = shipments.filter((shipment: Shipment) => {
      if (!shipment || !shipment.status || !shipment.quotes) {
        return;
      }
      const driverQuote = this.offerTypeService.determineDriverQuote(shipment, driverId);
      const validShipmentStatus =
        (shipment.status === SHIPMENT_STATUS_BOOKED && driverQuote.status === QUOTE_STATUS_CONFIRMED) ||
        shipment.status === SHIPMENT_STATUS_CANCELLED;
      return validShipmentStatus;
    });

    return foundShipments;
  }

  findUpcomingTrips(shipments: Shipment[]): Shipment[] {
    let foundShipments: Shipment[];

    foundShipments = shipments.filter((shipment: Shipment) => {
      if (!shipment || !shipment.status || !shipment.quotes) {
        return;
      }
      const validShipmentStatus =
        shipment.status === SHIPMENT_STATUS_BOOKED ||
        shipment.status === SHIPMENT_STATUS_CANCELLED ||
        shipment.status === SHIPMENT_STATUS_UNSECURED ||
        shipment.status === SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING ||
        shipment.status === SHIPMENT_STATUS_INACTIVE;
      return validShipmentStatus;
    });

    return foundShipments;
  }

  findEarliestOngoing(shipments: Shipment[]): Shipment {
    let foundShipment: Shipment;
    foundShipment = shipments.find((shipment: Shipment) => {
      if (!shipment || !shipment.status) {
        return;
      }
      const bookedShipment = shipment.status === SHIPMENT_STATUS_OUT_FOR_DELIVERY;
      return bookedShipment;
    });
    return foundShipment;
  }

  isUpcomingShipmentForDay(shipment: Shipment): boolean {
    if (!shipment || !shipment.status || !shipment.pickup.pickupDateFrom) {
      return false;
    }

    const validShipmentStatus = shipment.status === SHIPMENT_STATUS_BOOKED;
    const pickupDate = this.dateTmeConversion.getFormattedDate(shipment.pickup.pickupDateFrom);
    return validShipmentStatus && this.dateTmeConversion.getCurrentMoment().isBefore(pickupDate, 'hour');
  }

  isOutForDeliveryShipmentForDay(shipment: Shipment): boolean {
    if (!shipment || !shipment.status) {
      return false;
    }

    const validShipmentStatus = shipment.status === SHIPMENT_STATUS_OUT_FOR_DELIVERY;
    return validShipmentStatus;
  }

  isOngoingShipment(shipment: Shipment): boolean {
    if (!shipment || !shipment.status || !shipment.pickup.pickupDateFrom) {
      return false;
    }

    return shipment.status === SHIPMENT_STATUS_OUT_FOR_DELIVERY;
  }

  determineOfferRate(shipment: Shipment): number {
    if (
      shipment &&
      shipment.confirmedOfferRate &&
      (shipment.status === SHIPMENT_STATUS_BOOKED ||
        shipment.status === SHIPMENT_STATUS_CANCELLED ||
        shipment.status === SHIPMENT_STATUS_INACTIVE ||
        shipment.status === SHIPMENT_STATUS_DELIVERED)
    ) {
      return shipment.confirmedOfferRate;
    }
    return shipment.offerRate;
  }

  parseShipmentStatus(status: string): string {
    switch (status) {
      case 'assigned':
        return SHIPMENT_STATUSES.BOOKED;
      default:
        return status;
    }
  }
}
