import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentAction } from '../dqf-document-upload.component';
import {
  CARRIER_DOC_ARRAY,
  DRIVER_DOCS_ARRAY,
  DQF_DOC_STATUS,
  PRE_EMPLOYMENT_REQUIRED,
  DRIVER_DOCS_FOR_NOT_ONTARIO_NOT_US,
  PROVINCE_ONTARIO,
  DRIVER_DOCS_FOR_NOT_ONTARIO_US,
  DRIVER_DOCS_FOR_ONTARIO_US,
  DRIVER_DOCS_FOR_ONTARIO_NOT_US
} from '../../constants';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DriverQualificationService } from '../../service/driver-qualification.service';

const DISPLAY_NAME_DRIVER_DOCS = 'Driver Uploads';
const DISPLAY_NAME_CARRIER_DOCS = 'Carrier Uploads';
@Component({
  selector: 'app-dqf-item-box',
  templateUrl: './dqf-item-box.component.html',
  styleUrls: ['./dqf-item-box.component.scss']
})
export class DqfItemBoxComponent implements OnInit {
  uploadStatus: string;
  isRefused?: boolean;
  isPresent: boolean;
  isRequirementsCompleted: boolean;
  isNotReviewed: boolean;
  isProcessing: boolean;
  totalDocs: number;
  driverType: string;
  @Input() document: DocumentAction;
  @Input() carrierCompanyId: string;
  @Input() carrierAttachedDocuments: any;
  @Output() navigateTo = new EventEmitter();

  constructor(
    private registrationService: RegistrationService,
    private readonly driverQualificationService: DriverQualificationService
  ) {}

  ngOnInit(): void {
    const driver = this.registrationService.getDriver();
    const driverType = driver?.companyDriverType || undefined;
    const companyDriver = driver?.companyAssociations?.find(d => d.id.includes(this.carrierCompanyId));
    this.driverType = companyDriver ? companyDriver.driverType : driverType;
    this.getDriverUploadDocCount(this.document);
    if (this.document.displayName === DISPLAY_NAME_CARRIER_DOCS) {
      this.totalDocs = this.carrierAttachedDocuments;
    }
    this.isRequirementsCompleted = this.document?.isRequirementsCompleted;
    this.checkDocumentStatus();
  }

  getDriverUploadDocCount(document: any) {
    if (this.document.displayName === DISPLAY_NAME_DRIVER_DOCS) {
      let dqfData = this.driverQualificationService.dqfData;
      this.totalDocs = this.driverQualificationService.bindDriverAttachments(
        dqfData.attachedDocuments,
        document.licenseProvince,
        this.driverType
      )?.length;
      // // not Ontario but enter USA
      // if (document.licenseProvince != PROVINCE_ONTARIO && PRE_EMPLOYMENT_REQUIRED.includes(this.driverType)) {
      //   this.totalDocs = DRIVER_DOCS_FOR_NOT_ONTARIO_US.length;
      //   return;
      // }

      // // not  Ontario Not entering USA
      // if (document.licenseProvince != PROVINCE_ONTARIO && !PRE_EMPLOYMENT_REQUIRED.includes(this.driverType)) {
      //   this.totalDocs = DRIVER_DOCS_FOR_NOT_ONTARIO_NOT_US.length;
      //   return;
      // }

      // // ontario And entering USA
      // if (document.licenseProvince === PROVINCE_ONTARIO && PRE_EMPLOYMENT_REQUIRED.includes(this.driverType)) {
      //   this.totalDocs = DRIVER_DOCS_FOR_ONTARIO_US.length;
      //   return;
      // }
      // // ontario Not entering USA
      // if (document.licenseProvince === PROVINCE_ONTARIO && !PRE_EMPLOYMENT_REQUIRED.includes(this.driverType)) {
      //   this.totalDocs = DRIVER_DOCS_FOR_ONTARIO_NOT_US.length;
      //   return;
      // }

      // this.totalDocs = DRIVER_DOCS_ARRAY.length;
    }
  }

  navigateToPage(): void {
    this.navigateTo.emit();
  }

  getDocUploadStatus(): boolean {
    if (this.document?.isRejected) {
      this.uploadStatus = 'rejected';
      return false;
    }

    if (+this.document?.uploaded !== this.totalDocs) {
      this.uploadStatus = 'not-uploaded';
      return false;
    }

    return +this.document?.uploaded === this.totalDocs;
  }

  private checkDocumentStatus(): void {
    if (this.document?.status === DQF_DOC_STATUS.REJECTED) {
      this.isRefused = true;
      return;
    }

    if (this.document?.status === DQF_DOC_STATUS.PROCESSING) {
      this.isProcessing = true;
      return;
    }
    if (this.document?.status === DQF_DOC_STATUS.CARRIER_UPLOAD_REQUESTED) {
      this.isRequirementsCompleted = true;
      this.isPresent = true;
    }
    if (this.isRequirementsCompleted) {
      switch (this.document?.status) {
        case DQF_DOC_STATUS.DRIVER_REVIEWED:
        case DQF_DOC_STATUS.DRIVER_SIGNED:
        case DQF_DOC_STATUS.ACCEPTED:
          this.isPresent = true;
          break;
        case DQF_DOC_STATUS.PENDING:
          this.isNotReviewed = true;
          break;
        default:
          break;
      }
    }
  }
}
