import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  ROUTE_IDENTITY,
  ROUTE_ONBOARDING,
  ROUTE_ACCOUNT_LOCKED,
  ROUTE_PRIVACY_STATEMENT,
  ROUTE_TERMS_CONDITIONS,
  ROUTE_SIGNIN,
  ROUTE_LOGIN_ERROR
} from './authentication/shared/routes';
import { ProfileService } from './profile/profile.service';
import { MobileNativeAppService } from './mobile-native-app.service';
import { RegistrationService } from './registration/shared/registration.service';
import { NotificationStateModel, RegistrationState } from './registration/state/registration.state';
import {
  DriverApiService,
  IDENTITY,
  IDENTITY_INITIATED,
  PROFILE_LOCKED_STATUS
} from './shared/services/driver-api.service';
import { Response } from './shared/services/response';
import { ROUTE_PROFILE, ROUTE_UNSUBSCRIBE_EMAIL } from './shared/routes';
import { Driver } from './shared/models/driver.model';
import { environment } from 'src/environments/environment';
import * as Sentry from '@sentry/angular';
import rg4js from 'raygun4js';
import { of, throwError } from 'rxjs';

const ExcludedUrls = [
  { url: '/confirm-forgot-password' },
  { url: '/signup-completed' },
  { url: '/signup' },
  { url: `/${ROUTE_SIGNIN}` },
  { url: `/${ROUTE_UNSUBSCRIBE_EMAIL}` },
  { url: `/${ROUTE_PRIVACY_STATEMENT}` },
  { url: `/${ROUTE_TERMS_CONDITIONS}` }
];
const STAGE_IMPORTED = 'imported';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  isNativeApp: boolean;
  constructor(
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly driverApiService: DriverApiService,
    private readonly profileService: ProfileService,
    private readonly store: Store,
    private readonly mobileNativeAppService: MobileNativeAppService
  ) {}

  appInitializer() {
    let deepLinkPath = window.location.hash;
    deepLinkPath = deepLinkPath.replace('#', '');
    deepLinkPath = this.removeQueryParamsFromDeepLinkPath(deepLinkPath);
    this.isNativeApp = this.mobileNativeAppService.checkIfNativeApp();

    if (!this.checkExcludedUrls(deepLinkPath)) {
      this.registrationService
        .getDriverId()
        .pipe(
          map((response: Response) => {
            if (response) {
              this.driverApiService.driverId = response.userId;
            }
          }),
          mergeMap(() =>
            this.registrationService.loadDispatcherContact().pipe(
              map(() => {}),
              catchError(error => {
                return of(false);
              })
            )
          ),
          mergeMap(() =>
            this.registrationService.getLicense().pipe(
              map(license => {}),
              catchError(error => {
                return of(false);
              })
            )
          ),
          mergeMap(() =>
            this.registrationService.loadDriver().pipe(
              map((driver: Driver) => {
                this.enableCrashReportingForLoggedUser(driver);
                return this.store.selectSnapshot(RegistrationState.notification);
              })
            )
          ),
          catchError(err => {
            if (err.hasOwnProperty('type')) {
              this.router.navigate([ROUTE_SIGNIN]);
            } else {
              this.router.navigate([ROUTE_LOGIN_ERROR]);
            }
            return throwError(err);
          })
        )
        .subscribe((notificationStateModel: NotificationStateModel) => {
          if (notificationStateModel && notificationStateModel.notifications) {
            const notifications = [...notificationStateModel.notifications];
            const identityStatus = this.registrationService.getDriverIdentityStatus();
            const driverStage = this.registrationService.getDriverStage();
            const license = this.registrationService.getLicenseStore();
            if (identityStatus === PROFILE_LOCKED_STATUS) {
              this.router.navigate([ROUTE_ACCOUNT_LOCKED]);
            } else if (!this.isLicensePresent() || driverStage === STAGE_IMPORTED) {
              this.router.navigate([ROUTE_ONBOARDING]);
            } else {
              this.router.navigate([deepLinkPath]).catch(() => {
                this.router.navigate([ROUTE_PROFILE]);
              });
            }
          }
        });
    }
  }
  private isLicensePresent() {
    const license = this.registrationService.getLicenseStore();
    return license?.backScanImagePresent && license?.frontScanImagePresent;
  }

  private removeQueryParamsFromDeepLinkPath(deepLinkPath: string): string {
    let queryParams = deepLinkPath.split('?')[1];
    if (queryParams) {
      deepLinkPath = deepLinkPath.replace(`?${queryParams}`, '');
    }
    return deepLinkPath;
  }

  private checkExcludedUrls(url: string): boolean {
    let result = false;
    ExcludedUrls.forEach((excludedUrl: any) => {
      if (url.includes(excludedUrl.url)) {
        result = true;
      }
    });
    return result;
  }

  private enableCrashReportingForLoggedUser(driver: Driver): void {
    if (environment?.featureFlags?.enableSentry) {
      Sentry.setUser({
        identifier: driver.id,
        driverId: driver.driverID,
        driverType: driver.driverType,
        firstName: driver.firstName,
        fullName: `${driver.firstName} ${driver.lastName}`.trim()
      });
    }
  }
}
