import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { SendDocumentResponse } from 'src/app/shared/documents-data/models/send-document-response';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as mime from 'mime';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DEFAULT_ERROR_MESSAGE } from '../../shared/constants';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ShipmentsActionSheetComponent } from '../../shipments-action-sheet/shipments-action-sheet.component';

const VIEW_TYPE_DOCUMENT_LIST = 'document-list-view';
const VIEW_TYPE_FILE_UPLOAD = 'file-upload-view';
const DOCUMENT_UPLOAD_SUCCESS_MESSAGE = 'Sucessfully uploaded shipment document';

@Component({
  selector: 'app-shipment-sub-documents',
  templateUrl: './shipment-sub-documents.component.html',
  styleUrls: ['./shipment-sub-documents.component.scss']
})
export class ShipmentSubDocumentsComponent implements OnInit {
  @Input() shipmentId: string;
  @Input() shipmentDocuments: ShipmentDocument[];
  @Output() updateShipmentDocuments = new EventEmitter();
  driverDocuments: ShipmentDocument[] = [];
  dispatcherDocuments: ShipmentDocument[] = [];
  noDocumentsText = 'Your Document List is empty';
  loading: boolean;
  viewType: string;
  file: any;
  acceptTypes = ['application/pdf', 'image/png', 'image/jpg', 'image/jpeg'];
  constructor(
    private readonly toastService: ToastService,
    private readonly documentsDataService: DocumentsDataService,
    private readonly fileDataService: FileDataService,
    private readonly timeService: DateTimeService,
    private readonly bottomSheet: MatBottomSheet
  ) {
    this.viewType = VIEW_TYPE_DOCUMENT_LIST;
  }

  ngOnInit(): void {
    if (this.shipmentDocuments?.length) {
      this.shipmentDocuments.sort((shipmentDocumentA: ShipmentDocument, shipmentDocumentB: ShipmentDocument) => {
        const dateA = this.timeService.getFormattedDate(shipmentDocumentA.createdDate);
        const dateB = this.timeService.getFormattedDate(shipmentDocumentB.createdDate);
        return dateB.getTime() - dateA.getTime();
      });

      this.shipmentDocuments.forEach((shipment: ShipmentDocument) => {
        shipment.createdDate = this.timeService.getConvertedDateTimeFromUTC(shipment.createdDate);
        if (shipment.role === 'Driver') {
          this.driverDocuments.push(shipment);
        } else {
          this.dispatcherDocuments.push(shipment);
        }
      });
    }
  }

  addDocument() {
    this.viewType = VIEW_TYPE_FILE_UPLOAD;
  }

  onUploadCancel() {
    this.file = undefined;
    this.viewType = VIEW_TYPE_DOCUMENT_LIST;
  }

  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.file = file;
    };
  }

  onUploadNewDocument(): void {
    this.loading = true;
    const extension = this.file.name
      .split('.')
      .pop()
      .toLowerCase();

    this.documentsDataService
      .sendShipmentDocument(this.shipmentId, 'ProofOfDelivery', extension)
      .pipe(
        switchMap((sendDocumentResponse: SendDocumentResponse) => {
          const url = sendDocumentResponse.url;
          const newFileName = sendDocumentResponse.file;

          const file = this.file;
          const renamedFile: File = new File([file], newFileName, { type: file.type });
          const contentType = mime.getType(extension);

          return this.fileDataService.uploadFile(renamedFile, url, contentType);
        }),
        tap(() => {
          this.toastService.showSuccess(DOCUMENT_UPLOAD_SUCCESS_MESSAGE);
        }),
        catchError((error: any) => {
          this.loading = false;
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          throw error;
        })
      )
      .subscribe(() => {
        this.viewType = VIEW_TYPE_DOCUMENT_LIST;
        this.loading = false;
        this.file = undefined;
        this.updateShipmentDocuments.emit();
      });
  }

  openActionSheet(doc: ShipmentDocument): void {
    this.bottomSheet.open(ShipmentsActionSheetComponent, {
      data: {
        type: 'file',
        fileDocument: doc,
        shipmentId: this.shipmentId,
        loadShipmentDocuments: () => this.updateShipmentDocuments.emit()
      }
    });
  }
}
