export const SHIPMENT_STATUS_DRAFT = 'draft';
export const SHIPMENT_STATUS_UNSECURED = 'unsecured';
export const SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING = 'confirmation pending';
export const SHIPMENT_STATUS_BOOKED = 'booked';
export const SHIPMENT_STATUS_BILL_OF_LADING_AVAILABLE = 'bill of lading available';
export const SHIPMENT_STATUS_OUT_FOR_DELIVERY = 'out for delivery';
export const SHIPMENT_STATUS_DELIVERED = 'delivered';
export const SHIPMENT_STATUS_COMPLETED = 'completed';
export const SHIPMENT_STATUS_INACTIVE = 'inactive';
export const SHIPMENT_STATUS_CANCELLED = 'cancelled';
export const SHIPMENT_STATUS_DRIVING_TO_PICKUP = 'driving to pickup';

export const SHIPMENT_STATUSES = {
  ASSIGNED: 'assigned',
  DRAFT: 'draft',
  UNSECURED: 'unsecured',
  CONFIRMATION_PENDING: 'confirmation pending',
  BOOKED: 'booked',
  DRIVING_TO_PICKUP: 'driving to pickup',
  PICKUP_IN_PROGRESS: 'pickup in progress',
  OUT_FOR_DELIVERY: 'out for delivery',
  UNLOADING: 'unloading',
  DELIVERED: 'delivered',
  INACTIVE: 'inactive',
  TONU: 'tonu',
  COMPLETED: 'completed',
  INVOICE_SENT: 'invoice sent',
  PAYMENT_OVERDUE: 'payment overdue',
  PAYMENT_RECEIVED: 'payment received',
  CANCELLED: 'cancelled',
  DELETED: 'deleted',
};

// CURRENT_SHIPMENT_STATUS: [AVAILABLE_STATUSES_TO_CHANGE]
export const SHIPMENT_STATUSES_PER_STATUS = {
  [SHIPMENT_STATUSES.CONFIRMATION_PENDING]: [SHIPMENT_STATUSES.ASSIGNED],
  [SHIPMENT_STATUSES.ASSIGNED]: [SHIPMENT_STATUSES.DRIVING_TO_PICKUP],
  [SHIPMENT_STATUSES.DRIVING_TO_PICKUP]: [SHIPMENT_STATUSES.PICKUP_IN_PROGRESS],
  [SHIPMENT_STATUSES.PICKUP_IN_PROGRESS]: [SHIPMENT_STATUSES.OUT_FOR_DELIVERY],
  [SHIPMENT_STATUSES.OUT_FOR_DELIVERY]: [SHIPMENT_STATUSES.UNLOADING],
  [SHIPMENT_STATUSES.UNLOADING]: [SHIPMENT_STATUSES.DELIVERED],
  [SHIPMENT_STATUSES.DELIVERED]: [],
  [SHIPMENT_STATUSES.COMPLETED]: []
};

export const SHIPMENT_STATUSES_UI_MAP = {
  [SHIPMENT_STATUSES.ASSIGNED]: {
    COLOR: 'orange',
    TEXT: 'Assigned'
  },
  [SHIPMENT_STATUSES.DRAFT]: {
    COLOR: 'orange',
    TEXT: 'Assigned'
  },
  [SHIPMENT_STATUSES.BOOKED]: {
    COLOR: 'orange',
    TEXT: 'Assigned'
  },
  [SHIPMENT_STATUSES.UNSECURED]: {
    COLOR: 'gray',
    TEXT: 'Unsecured'
  },
  [SHIPMENT_STATUSES.CONFIRMATION_PENDING]: {
    COLOR: 'purple',
    TEXT: 'Confirmation Pending'
  },
  [SHIPMENT_STATUSES.DRIVING_TO_PICKUP]: {
    COLOR: 'orange',
    TEXT: 'Driving to PickUp'
  },
  [SHIPMENT_STATUSES.PICKUP_IN_PROGRESS]: {
    COLOR: 'orange',
    TEXT: 'PickUp in Progress'
  },
  [SHIPMENT_STATUSES.OUT_FOR_DELIVERY]: {
    COLOR: 'orange',
    TEXT: 'In Transit'
  },
  [SHIPMENT_STATUSES.UNLOADING]: {
    COLOR: 'orange',
    TEXT: 'Unloading'
  },
  [SHIPMENT_STATUSES.DELIVERED]: {
    COLOR: 'green-inverted',
    TEXT: 'Delivered'
  },
  [SHIPMENT_STATUSES.INACTIVE]: {
    COLOR: 'gray',
    TEXT: 'Inactive'
  },
  [SHIPMENT_STATUSES.TONU]: {
    COLOR: 'green-inverted',
    TEXT: 'TONU'
  },
  [SHIPMENT_STATUSES.COMPLETED]: {
    COLOR: 'green-inverted',
    TEXT: 'Completed'
  },
  [SHIPMENT_STATUSES.INVOICE_SENT]: {
    COLOR: 'orange',
    TEXT: 'Invoice Sent'
  },
  [SHIPMENT_STATUSES.PAYMENT_OVERDUE]: {
    COLOR: 'red',
    TEXT: 'Payment Overdue'
  },
  [SHIPMENT_STATUSES.PAYMENT_RECEIVED]: {
    COLOR: 'green',
    TEXT: 'Payment Received'
  },
  [SHIPMENT_STATUSES.CANCELLED]: {
    COLOR: 'red',
    TEXT: 'Cancelled'
  },
  [SHIPMENT_STATUSES.DELETED]: {
    COLOR: 'red',
    TEXT: 'Deleted'
  },
};
