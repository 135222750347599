import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmUnsubscribeEmailComponent } from './feature-unsubscribe-email/confirm-unsubscribe-email/confirm-unsubscribe-email.component';
import { SuccessfulUnsubscribeEmailComponent } from './feature-unsubscribe-email/sucessful-unsubscribe-email/successful-unsubscribe-email.component';
import { FailureUnsubscribeEmailComponent } from './feature-unsubscribe-email/failure-unsubscribe-email/failure-unsubscribe-email.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ConfirmUnsubscribeEmailComponent, SuccessfulUnsubscribeEmailComponent, FailureUnsubscribeEmailComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule
  ]
})
export class UnsubscribeEmailModule { }
