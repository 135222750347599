import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { first, finalize, tap, catchError } from 'rxjs/operators';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { ShipmentDataService } from 'src/app/loads-shared/shipment-data/shipment-data.service';
import { ShipmentDetailsActionSheetComponent } from 'src/app/loads-shared/shipment-details-action-sheet/shipment-details-action-sheet.component';

import {
  SHIPMENT_STATUS_INACTIVE,
  SHIPMENT_STATUS_CANCELLED
} from 'src/app/loads-shared/shipment-data/models/shipment-status';
import { Status } from 'src/app/shared/status-banner/models/status.model';
import {
  OfferTypeService,
  LOAD_STATUS_MULTIPLE_ONGOING_SHIPMENT,
  LOAD_STATUS_INACTIVE_SHIPMENT,
  CANCELLED_SHIPMENT_STATUS,
  MY_LOADS_INACTIVE_SHIPMENT
} from 'src/app/loads-shared/offer-type.service';
import { ShipmentUtilsService } from 'src/app/loads-shared/shipment-utils.service';
import { forkJoin } from 'rxjs';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { AppService } from 'src/app/app.service';
import { Store } from '@ngxs/store';
import { ShipmentActionsService as SharedShipmentActionService } from '../../loads-shared/shipment-actions.service';
import { TRIPS_ROUTE_TRIPS_FULL_PATH } from '../routes';
import { Action } from 'src/app/shared/actions/models/action.model';

const TRIP = 'Trip';
const CONFIRMATION_PENDING = 'confirmation pending';
@Component({
  selector: 'app-trip-details',
  templateUrl: './trip-details.component.html',
  styleUrls: ['./trip-details.component.scss']
})
export class TripDetailsComponent implements OnInit {
  errorMessage: string;
  driverId: string;
  shipment: Shipment;
  actions: Action[];
  quote: any;
  isProfileComplete: boolean;
  shipmentInactive = false;
  status: Status;
  cancelledReason: string;
  cancelledStatus: string;
  loading: boolean;
  showNavBar: boolean = true;
  private shipments: Shipment[];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly bottomSheet: MatBottomSheet,
    private readonly driverApiService: DriverApiService,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly offerTypeService: OfferTypeService,
    private readonly shipmentUtilsService: ShipmentUtilsService,
    private readonly appService: AppService,
    private readonly sharedShipmentActionService: SharedShipmentActionService
  ) {
    this.actions = [];
    this.loading = false;
  }

  ngOnInit(): void {
    this.loading = true;
    const shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.driverId = this.driverApiService.driverID;
    this.loadShipment(shipmentId);
    if (this.appService.isNativeApp) {
      this.showNavBar = false;
    }
  }

  onOpenActionSheet(): void {
    this.bottomSheet.open(ShipmentDetailsActionSheetComponent, {
      data: { shipment: this.shipment, title: TRIP }
    });
  }

  onNavigateBack(): void {
    const { back } = window.history.state;
    this.router.navigateByUrl(back || TRIPS_ROUTE_TRIPS_FULL_PATH);
  }

  private loadShipment(shipmentId: string): void {
    forkJoin([
      this.shipmentDataService.fetchShipment(shipmentId),
      this.shipmentDataService.fetchMyShipments(this.driverApiService.driverID)
    ])
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(([shipment, shipments]) => {
        if (shipment) {
          this.shipment = shipment;
          this.shipments = shipments;
          this.status = this.offerTypeService.getLoadStatus(this.shipment, this.driverId);
          this.status = this.status;

          if (this.shipment.status.toUpperCase() === CONFIRMATION_PENDING.toUpperCase()) {
            this.actions.push(this.createConfirmAction(this.shipment));
          } else if (this.shipment.status === SHIPMENT_STATUS_INACTIVE && this.shipment.quotes) {
            this.status = this.getInactiveTripMessage();
            return;
          } else if (this.shipment.status === SHIPMENT_STATUS_INACTIVE) {
            this.status = this.getPastTripStatusMessage();
            this.shipmentInactive = true;
            return;
          } else if (this.shipment.status === SHIPMENT_STATUS_CANCELLED) {
            this.status = this.getCancelledTripStatusMessage();
            this.cancelledStatus = CANCELLED_SHIPMENT_STATUS;
            this.cancelledReason = this.shipment.cancelled.cancellationReason;
            return;
          }

          if (this.actions.length == 0) {
            this.actions = this.sharedShipmentActionService.createCompletePickupAction(this.shipment);
            if (shipments && this.checkExistingOngoingShipment(this.shipments) && this.actions.length > 0) {
              this.setActionButtonsDisabled(this.actions);
              this.status = this.getMultipleOngoingMessage();
            }
            this.actions =
              this.actions.length == 0
                ? this.sharedShipmentActionService.createCompleteDeliveryAction(this.shipment)
                : this.actions;
          }
        }
      });
  }

  private createConfirmAction(shipment: Shipment): Action {
    return {
      name: 'CONFIRM',
      action: (data: any) => this.onConfirm(shipment),
      disabled: false,
      color: 'confirm_trip'
    } as Action;
  }

  private onConfirm(shipment: Shipment): void {
    const quoteId = shipment.quotes[0]?.id;
    if (quoteId) {
      this.shipmentDataService
        .confirmBid(quoteId, shipment.offerRate)
        .pipe(
          first(),
          tap(
            () => {
              this.navigateToMyTrips();
            },
            catchError((error: string) => {
              throw error;
            })
          )
        )
        .subscribe();
      return;
    }
  }

  private setActionButtonsDisabled(action: Action[]): Action[] {
    action.forEach(element => {
      element.disabled = true;
      element.color = 'disable_action';
    });
    return action;
  }

  private checkExistingOngoingShipment(shipments): boolean {
    return !!this.shipmentUtilsService.findEarliestOngoing(shipments);
  }

  private getMultipleOngoingMessage(): Status {
    return {
      message: LOAD_STATUS_MULTIPLE_ONGOING_SHIPMENT,
      color: 'multiple_ongoing'
    } as Status;
  }

  private getPastTripStatusMessage(): Status {
    return {
      message: LOAD_STATUS_INACTIVE_SHIPMENT,
      svgIcon: 'alert',
      color: 'past_load'
    } as Status;
  }

  private getInactiveTripMessage(): Status {
    return {
      message: MY_LOADS_INACTIVE_SHIPMENT,
      svgIcon: 'alert-circle',
      color: 'inactive_load'
    } as Status;
  }

  private getCancelledTripStatusMessage(): Status {
    return {
      messageType: 'cancelledReason',
      svgIcon: 'alert-circle',
      color: 'cancelled_load'
    } as Status;
  }

  private navigateToMyTrips(): void {
    this.router.navigate([TRIPS_ROUTE_TRIPS_FULL_PATH]);
  }
}
