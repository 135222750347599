import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, first, tap } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ROUTE_DQF, ROUTE_DQF_DECLARE } from 'src/app/shared/routes';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { SubSink } from 'subsink';
import {
  DOCTYPE_PERSONAL_INFO,
  DOCTYPE_LICENSE_TYPE,
  DOCTYPE_RESIDENCE_HISTORY,
  DOCTYPE_DRIVING_EXPERIENCE,
  DOCTYPE_EMP_HISTORY,
  DOCTYPE_ACCIDENT_HISTORY,
  DOCTYPE_TRAFFIC_CONVICTION,
  ALL_DRIVER_DOCS_ARRAY,
  ABSTRACT,
  POLICE_CLEARANCE,
  PRE_EMPLOYMENT_SCREENING,
  ONTARIO_DRIVING_ABSTRACT
} from '../constants';
import { DriverQualificationService } from '../service/driver-qualification.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

const KEY_DRIVER = 'driver';
const DEFAULT_PROFILE_IMAGE = '/assets/images/default-profile-picture.png';
const ABSTRACT_REPORT_TYPE = 'Abstract';
const DOCTYPE_POLICE_CLEARANCE_CERTIFICATE = 'PoliceClearance';
const DOCTYPE_PRE_EMPLOYMENT_SCREENING_REPORT = 'PreEmploymentScreening';
const DOCTYPE_DRIVERS_CLEARING_HOUSE_REPORT = 'ClearingHouseReport';
const UPLOADED_STATUS = 'uploaded';
const DQF_STATUS_ACCEPTED = 'Approved';
const DQF_STATUS_PENDINGAPPROVAL = 'PendingCarrierApproval';
const DQF_STATUS_CARRIERPROCESSINGCOMPLETED = 'CarrierProcessingCompleted';
const DQF_STATUS_APPROVEDPROCESSING = 'ApprovedProcessing';
const DISABLE_TOP_LOADING_BAR = true;

@Component({
  selector: 'app-dqf-review',
  templateUrl: './dqf-review.component.html',
  styleUrls: ['./dqf-review.component.scss']
})
export class DqfReviewComponent implements OnInit, OnDestroy {
  customCollapsedHeight: string = '36px';
  carrierCompanyName: string = null;
  profilePicture = null;
  panelOpenState = false;
  abstractDocumentId: string;
  policeClearanceId: string;
  preEmploymentId: string;
  subSink = new SubSink();
  driverInfo = [];
  hideActionButtons: boolean = false;
  driverDocs = [
    { heading: 'Driver Abstract', value: 9, type: ABSTRACT },
    { heading: 'Police Clearance Certificate', value: 10, type: POLICE_CLEARANCE },
    { heading: 'Pre-Employment Screening', value: 11, type: PRE_EMPLOYMENT_SCREENING },
    { heading: 'Ontario CVOR Abstract', value: 12, type: ONTARIO_DRIVING_ABSTRACT }
  ];

  allDriverInfo = [
    { heading: 'Personal Info', value: 1, type: DOCTYPE_PERSONAL_INFO },
    { heading: 'Contact', value: 2, type: DOCTYPE_PERSONAL_INFO },
    { heading: 'License Info', value: 3, type: DOCTYPE_LICENSE_TYPE },
    { heading: 'Residence History', value: 4, type: DOCTYPE_RESIDENCE_HISTORY },
    { heading: 'Driving Experience', value: 5, type: DOCTYPE_DRIVING_EXPERIENCE },
    { heading: 'Employment History', value: 6, type: DOCTYPE_EMP_HISTORY },
    { heading: 'Accident Records', value: 7, type: DOCTYPE_ACCIDENT_HISTORY },
    { heading: 'Traffic Convictions', value: 8, type: DOCTYPE_TRAFFIC_CONVICTION }
  ];

  profileInfo = this.allDriverInfo;
  isContentReady: boolean = false;
  dqf: any;
  profileInfoVersions: any = {
    'Personal Info': 0,
    Contact: 0,
    'License Info': 0,
    'Residence History': 0,
    'Driving Experience': 0,
    'Employment History': 0,
    'Accident Records': 0,
    'Traffic Convictions': 0
  };
  isVersionsLoaded: boolean = false;
  driverVersionDetails: any;
  dqfId: string;

  constructor(
    private location: Location,
    private registrationService: RegistrationService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private driverQualificationService: DriverQualificationService,
    private storageService: StorageService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.dqfId = this.route.snapshot.paramMap.get('id');
    this.carrierCompanyName = this.driverQualificationService.dqfData?.carrierCompanyName;

    this.subSink.add(this.registrationService.loadDriver(DISABLE_TOP_LOADING_BAR).subscribe());
    this.loadDQFs();
    this.loadData();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  backAction(): void {
    this.location.back();
  }

  navigateToSign(): void {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DECLARE}/${this.dqfId}`]);
  }
  checkDQFStatus(): void {
    if (
      [
        DQF_STATUS_ACCEPTED,
        DQF_STATUS_PENDINGAPPROVAL,
        DQF_STATUS_CARRIERPROCESSINGCOMPLETED,
        DQF_STATUS_APPROVEDPROCESSING
      ].includes(this.dqf.status)
    ) {
      this.hideActionButtons = true;
      this.profileInfoVersions = {
        'Personal Info': this.getVersionFromDQF(DOCTYPE_PERSONAL_INFO),
        Contact: this.getVersionFromDQF(DOCTYPE_PERSONAL_INFO),
        'License Info': this.getVersionFromDQF(DOCTYPE_LICENSE_TYPE),
        'Residence History': this.getVersionFromDQF(DOCTYPE_RESIDENCE_HISTORY),
        'Driving Experience': this.getVersionFromDQF(DOCTYPE_DRIVING_EXPERIENCE),
        'Employment History': this.getVersionFromDQF(DOCTYPE_EMP_HISTORY),
        'Accident Records': this.getVersionFromDQF(DOCTYPE_ACCIDENT_HISTORY),
        'Traffic Convictions': this.getVersionFromDQF(DOCTYPE_TRAFFIC_CONVICTION)
      };
      this.subSink.add(
        this.registrationService
          .loadDriver(DISABLE_TOP_LOADING_BAR, this.profileInfoVersions['Personal Info'])
          .subscribe(data => {
            this.driverVersionDetails = data;
            this.isVersionsLoaded = true;
          })
      );
    } else {
      this.isVersionsLoaded = true;
    }
  }

  loadData(): void {
    this.getDriverProfilePicture();
    this.getDocumentInfo(ABSTRACT_REPORT_TYPE, 'abstractDocumentId');
    this.getDocumentInfo(DOCTYPE_POLICE_CLEARANCE_CERTIFICATE, 'policeClearanceId');
    this.getDocumentInfo(DOCTYPE_PRE_EMPLOYMENT_SCREENING_REPORT, 'preEmploymentId');
    this.getDocumentInfo(ONTARIO_DRIVING_ABSTRACT, 'ontarioCvorAbstractId');
  }

  private getDriverProfilePicture(): void {
    this.isContentReady = false;
    this.subSink.sink = this.registrationService.loadProfileImageThumbnail(DISABLE_TOP_LOADING_BAR).subscribe(
      response => {
        if (response) {
          this.profilePicture = response.fileUrl;
        } else {
          this.profilePicture = DEFAULT_PROFILE_IMAGE;
        }
        this.driverQualificationService.profilePicture = this.profilePicture;
        this.isContentReady = true;
      },
      error => {
        this.isContentReady = true;
        this.profilePicture = DEFAULT_PROFILE_IMAGE;
        this.driverQualificationService.profilePicture = this.profilePicture;
      }
    );
  }

  private getDocumentInfo(reportType: string, documentTypeId: string): void {
    const driver = this.storageService.find(KEY_DRIVER);
    if (driver && driver.notification) {
      const filteredValue = driver.notification.filter(
        notification =>
          notification.type === reportType &&
          notification.status == UPLOADED_STATUS &&
          notification.dqfId === this.dqfId
      );
      if (filteredValue.length) {
        this[documentTypeId] = filteredValue[0].id;
      }
    }
  }

  private loadDQFs() {
    this.driverQualificationService
      .getDqfById(this.dqfId)
      .pipe(
        first(),
        tap(response => {
          if (response) {
            this.dqf = response;
            this.addDocumentsToDisplay(this.dqf);
            this.checkDQFStatus();
          }
        }),
        catchError((error: any) => {
          this.toastService.showError(error.message);
          return error;
        })
      )
      .subscribe();
  }

  addDocumentsToDisplay(documents: any) {
    documents?.attachedDocuments?.forEach(element => {
      if (ALL_DRIVER_DOCS_ARRAY.includes(element.type)) {
        const filteredValue = this.driverDocs.filter(e => e.type === element.type);
        if (filteredValue.length) {
          this.profileInfo.push(filteredValue[0]);
        }
      }
    });
  }

  getVersionFromDQF(type) {
    return this.dqf.attachedDocuments.find(el => el.type === type)?.version ?? 0;
  }
}
