import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action } from 'src/app/loads-shared/shipment-data/models/action';


export interface DialogData {
  title: string;
  message: string;
  actions: Action[];
}

@Component({
  selector: 'app-shipments-confirm-dialog',
  templateUrl: './shipments-confirm-dialog.component.html',
  styleUrls: ['./shipments-confirm-dialog.component.scss']
})
export class ShipmentsConfirmDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit(): void {
  }

}
