<div class="content" fxLayout="column" fxLayoutGap="15px">
  <div fxFlex="10" class="description-banner">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        height: '35px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="progress-bar-container">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        height: '20px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div class="app-employment-view">
    <div>
      <ngx-skeleton-loader
        appearance="line"
        count="1"
        [theme]="{
          height: '65px'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div *ngIf="!isResidenceHistory">
      <ngx-skeleton-loader
        appearance="line"
        count="3"
        [theme]="{
          height: '65px'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div fxLayout class="add-new-container">
    <div fxFlex="100">
      <ngx-skeleton-loader
        appearance="line"
        [theme]="{
          height: '45px',
          width: '98%'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>
