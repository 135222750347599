<div fxLayoutAlign="start" fxLayout="column" class="page full-height gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon data-test="back-arrow-icon" (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Shipment Timeline</span>
  </div>
  <div fxLayout="column" class="full-height margin-bottom-md header" *ngIf="isContentReady">
    <div fxLayout="column" class="section">
      <div fxLayout class="shipment-meta-container">
        <div class="shipment-id-container">
          <div class="shipment-id">{{ shipment.shipmentID || '-' }}</div>
          <br />
          <div class="shipment-type" *ngIf="!isConfirmationPending">{{ this.shipmentType }}</div>
        </div>
        <div fxFlex="40" class="shipment-status-container">
          <div [ngClass]="[getShipmentStatusClass(), 'status-tag']">
            {{ shipment.status === 'out for delivery' ? 'In Transit' : shipment.status | titlecase }}
          </div>
        </div>
      </div>
      <div fxLayoutGap="10px" fxLayout="column" class="origin-des-container">
        <div fxLayout fxFlex="30" class="origin-des">
          <div class="text-align-left">{{ pickupAddress.updatedOrigin || '-' }}</div>
          <div fxFlex="30" fxLayout class="location-icons-container">
            <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
            <div fxFlex="55" class="border-bottom"></div>
            <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
          </div>
          <div class="text-align-right">{{ deliveryAddress.updatedDestination || '-' }}</div> 
        </div>
        <div fxLayout class="address">
          <div fxFlex="40" class="text-align-left">{{ pickupAddress.updatedStreetAddress || '-' }}</div>
          <div fxFlex="40" class="text-align-right">{{ deliveryAddress.updatedStreetAddress || '-' }}</div>
        </div>
        <div fxLayout class="time">
          <div fxFlex="40" class="text-align-left">{{ pickupAddress.updatedPickupDate || '-' }}, {{ pickupAddress.updatedPickupTime || '-' }}</div>
          <div fxFlex="40" class="text-align-right">{{ deliveryAddress.updatedDeliveryDate || '-' }}, {{ deliveryAddress.updatedDeliveryTime || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="detail-panel-body no-scroll">
    <div id="timeline-list" class="margin-top-md margin-left-md shipment-content" data-test="timeline-list">
      <div *ngFor="let status of timelineList" data-test="timeline-section">
        <div fxLayout="column" fxFlexFill class="margin-bottom-sm" (click)="toggleDetails(status)" data-test="status-title-row">
          <div fxFlex="100" fxLayout fxLayoutAlign="space-between center" class="margin-bottom-md">
            <div fxFlex="48">
              <h4 [ngClass]="[getStatusClass(status), 'status-tag', 'status-text']"> {{ editDocumentName(status.status) === 'out for delivery' ? 'In Transit' : editDocumentName(status.status) | titlecase }}</h4>
            </div>
            <div fxFlex="48" class="date-value">
              <p>{{ status.event[0].date | date: "MMM dd YYYY, HH:mm aaaaa'm'" }}</p>
            </div>
            <div fxFlex="4" *ngIf="!status.showDetails">
              <mat-icon
                *ngIf="!(status.event.length === 1 && status.event[0].name) || (status.event.length === 1 && status.event[0].type)"
                class="icon-color" svgIcon="collapse-down-arrow"></mat-icon>
            </div>
            <div fxFlex="4" *ngIf="status.showDetails">
              <mat-icon
                *ngIf="!(status.event.length === 1 && status.event[0].name) || (status.event.length === 1 && status.event[0].type)"
                class="icon-color" svgIcon="collapse-up-arrow"></mat-icon>
            </div>
          </div>
        </div>
        <div *ngIf="status.showDetails">
          <div *ngFor="let event of status.event">
            <div fxLayout="row">
              <div fxFlex="10">
                <div class="outer-circle">
                  <div class="inner-circle"></div>
                </div>
                <div fxFlex="10" class="tracking-line"></div>
              </div>
              <div fxFlex="90">
                <div class="margin-bottom-md">
                  <div fxLayout="column" fxFlexFill class="margin-bottom-md" data-test="timeline-banner">
                    <div fxFlex="100" fxLayout fxLayoutAlign="space-between" class="margin-bottom-md">
                      <div fxFlex="50" class="date-value">
                        <p>{{ event.date | date: "MMM dd YYYY, HH:mm aaaaa'm'" }}</p>
                      </div>
                      <div *ngIf="event.name" fxFlex="25" class="type-value">
                        {{ editDocumentName(event.name) | titlecase }}
                      </div>
                      <div *ngIf="event.type" fxFlex="25" class="type-value">
                        {{ getDisplayName(event.type) | titlecase }}
                      </div>
                      <div *ngIf="event.stopType" fxFlex="25" class="type-value text-align-right ">
                        {{ getStopName(event.stopType) | titlecase }}
                      </div>
                    </div>
                    <div fxFlex="100" fxLayout fxLayoutAlign="flex-start center">
                      <div fxFlex="90" class="comment-value" *ngIf="event.comment">
                        <strong>Comment: </strong> {{ event.comment }}
                      </div>
                      <div [fxFlex]="event.showStatus ? '10' : '50'" class="comment-nostatus">
                        <div class="ellipse-container"
                          *ngIf="event?.comment && event.createdByUserType?.toLowerCase() === 'driver'"
                          (click)="openActionSheet(event)" data-test="ellipses-icon">
                          <mat-icon *ngIf="event?.comment" svgIcon="ellipses"></mat-icon>
                        </div>
                      </div>
                    </div>
                    <div fxFlex="100" fxLayout="column" data-test="timeline-comment">
                      <div fxLayout class="image-container" *ngIf="event.documentId">
                        <div fxFlex="89">
                          <div *ngIf="!event.fileUrl && apiCount">
                            <ngx-skeleton-loader appearance="line" [theme]="{
                                  height: '100px',
                                  width: '90%',
                                  'background-color': '#c9c9c963'
                                }"></ngx-skeleton-loader>
                          </div>
                          <img [src]="event.fileUrl" class="shipment-image" data-test="shipment-image"
                            *ngIf="event.fileUrl" />
                        </div>
                        <div fxFlex="11" fxLayoutAlign="center flex-start">
                          <div class="ellipse-container"
                            *ngIf="event.fileUrl && event.createdByUserType?.toLowerCase() === 'driver'"
                            (click)="openActionSheet(event)" data-test="ellipses-icon">
                            <mat-icon svgIcon="ellipses"></mat-icon>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>