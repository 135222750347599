export const LOAD_WEIGHT_PREFERENCE = [
  {
    displayName: '25K - 35K lbs',
    name: '25K-35K lbs'
  },
  {
    displayName: '30K - 35K lbs',
    name: '30K-35K lbs'
  },
  {
    displayName: '35K - 40K lbs',
    name: '35K-40K lbs'
  },
  {
    displayName: '40K - 50K lbs',
    name: '40K-50K lbs'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const MAX_DEADHEAD_MILES = [
  {
    displayName: '25 miles',
    name: '25 miles'
  },
  {
    displayName: '50 miles',
    name: '50 miles'
  },
  {
    displayName: '100 miles',
    name: '100 miles'
  },
  {
    displayName: '100 + miles',
    name: '100+ miles'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const EARNING_TYPE = [
  {
    displayName: 'Optimal',
    name: 'optimal'
  },
  {
    displayName: 'Relaxed',
    name: 'relaxed'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const TRUCK_TYPE = [
  {
    displayName: 'Sleeper',
    name: 'sleeper'
  },
  {
    displayName: 'Non-Sleeper',
    name: 'nonSleeper'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const TRAILER_TYPE = [
  {
    displayName: 'Dry Van',
    name: 'dryVan'
  },
  {
    displayName: 'Flatbed',
    name: 'flatbed'
  },
  {
    displayName: 'Reefer',
    name: 'reefer'
  },
  {
    displayName: 'Tanker',
    name: 'tanker'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const DAYS_AWAY_FROM_BASE = [
  {
    displayName: '0 days',
    name: '0'
  },
  {
    displayName: '1 days',
    name: '1'
  },
  {
    displayName: '2 days',
    name: '2'
  },
  {
    displayName: '3 days',
    name: '3'
  },
  {
    displayName: '4 days',
    name: '4'
  },
  {
    displayName: '5 days',
    name: '5'
  },
  {
    displayName: '6 days',
    name: '6'
  },
  {
    displayName: '7 days',
    name: '7'
  },
  {
    displayName: '8 days',
    name: '8'
  },
  {
    displayName: '9+ days',
    name: '+9'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const CROSS_BORDER = [
  {
    displayName: 'Yes',
    name: true
  },
  {
    displayName: 'No',
    name: false
  }
];

export const US_ZONES = [
  {
    displayName: 'All Zones',
    name: 'allZones'
  },
  {
    displayName: 'East Coast',
    name: 'eastCoast'
  },
  {
    displayName: 'Central',
    name: 'central'
  },
  {
    displayName: 'West Coast',
    name: 'westCoast'
  },
  {
    displayName: 'None',
    name: ''
  }
];

export const VEHICLE_TYPE = [
  {
    name: 'semiTruck',
    displayName: 'Semi Truck'
  },
  {
    name: 'straightTruck',
    displayName: 'Straight Truck'
  },
  {
    name: 'dumpTruck',
    displayName: 'Dump Truck'
  },
  {
    name: 'dryVanTrailer',
    displayName: 'Dry Van Trailer'
  },
  {
    name: 'flatbedTrailer',
    displayName: 'Flatbed Trailer'
  },
  {
    name: 'reeferTrailer',
    displayName: 'Reefer Trailer'
  },
  {
    name: 'tankerTrailer',
    displayName: 'Tanker Trailer'
  }
];
