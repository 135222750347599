import { Component, OnInit, Inject } from '@angular/core';
import { UnsubscribeEmailDataService } from '../unsubscribe-email-data-access/unsubscribe-email-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL, ROUTE_FAILED_UNSUBSCRIBE_EMAIL } from 'src/app/shared/routes';
import { UnsubscribeEmailApiService } from '../unsubscribe-email-data-access/unsubscribe-email-api.service';
import { Email } from '../../model/email.model';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-confirm-unsubscribe-email',
  templateUrl: './confirm-unsubscribe-email.component.html',
  styleUrls: ['./confirm-unsubscribe-email.component.scss'],
  providers: [UnsubscribeEmailDataService, UnsubscribeEmailApiService]
})
export class ConfirmUnsubscribeEmailComponent implements OnInit {
  id: any;
  signature: any;
  type: any;

  copyrightYear = 2020;
  linkedInURL: string = 'https://linkedin.com/company/fleetoperate';
  facebookURL: string = 'https://www.facebook.com/FleetOperate/';
  twitterURL: string = 'https://twitter.com/fleetoperate';
  contactEmail: string = 'help@fleetoperate.com';

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly unsubscribeEmailDataService: UnsubscribeEmailDataService,
    @Inject('appName') private readonly appName: string
  ) {}

  ngOnInit() {
    this.type = this.activatedRoute.snapshot.queryParams.type
      ? this.activatedRoute.snapshot.queryParams.type
      : this.appName;
    this.id = this.activatedRoute.snapshot.queryParams.id;
    this.signature = this.activatedRoute.snapshot.queryParams.signature;
  }

  onUnsubscibe(): void {
    const model = this.prepareSaveModel();

    this.unsubscribeEmailDataService
      .updateEmailToUnsubscribe(model)
      .pipe(first())
      .subscribe(
        () => {
          this.router.navigate([ROUTE_SUCCESSFUL_UNSUBSCRIBE_EMAIL]);
        },
        (error: any) => {
          this.router.navigate([ROUTE_FAILED_UNSUBSCRIBE_EMAIL]);
        }
      );
  }

  onKeepSubscribed(): void {
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private prepareSaveModel(): Email {
    const model = {
      userType: this.type,
      userId: this.id,
      signature: this.signature
    } as Email;

    return model;
  }
}
