<div class="content" fxLayout="column">
  <div fxFlex="60" fxLayout class="image-container">
    <img src="assets/images/complete-profile.png" class="image" />
  </div>
  <div fxFlex="20" class="text-container">
    Please complete the <span class="bold-text">Required Information</span> sections of your Profile to proceed with DQF
    for <span class="bold-text">{{ this.data?.carrierName | uppercase }}</span>
  </div>
  <div fxFlex="20" class="btn-container">
    <button mat-raised-button color="secondary" class="save-button" (click)="closeDialog()">
      Complete Profile
    </button>
  </div>
</div>
