import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dqf-document-skeleton',
  templateUrl: './dqf-document-skeleton.component.html',
  styleUrls: ['./dqf-document-skeleton.component.scss']
})
export class DqfDocumentSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
