<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
  <div fxFlex fxLayout="column" class="padding-left-md padding-right-md">
    <img fxFlexAlign="center" class="logo padding-top-lg" src="/assets/img/fleetoperate_logo.jpg" width="300px" />
    <div fxLayout="column" class="padding-top-sm center" fxLayoutAlign="center center" fxFlex="70">
      <h2 class="centerBoldedText">{{ title }}</h2>
      <span class="centerText"
        >{{ errorMessage || '' }}<br />
        <a href="mailto:getonboard@fleetoperate.com" *ngIf="!isBerbixConflict">getonboard@fleetoperate.com</a></span
      >
    </div>

    <div fxLayout="column" fxLayoutAlign="center end" class="padding-top-lg" fxFlex>
      <div *ngIf="isBerbixConflict" class="btn-desc full-width">{{ btnDescription }}</div>
      <button
        class="margin-bottom margin-top-xlg full-width"
        mat-raised-button
        color="primary"
        type="submit"
        (click)="onNavigateToSignIn()"
      >
        OK
      </button>
    </div>
  </div>
</div>
