import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { ROUTE_IDENTITY, ROUTE_PROFILE } from 'src/app/authentication/shared/routes';
import { RegistrationService } from '../../registration/shared/registration.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

const STAGE_IMPORTED = 'imported';
const STAGE_IMPORTED_VIEWED = 'importViewed';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent implements OnInit {
  currentSlide: number;
  slides = [];
  constructor(
    private readonly router: Router,
    private domSanitizer: DomSanitizer,
    private readonly registrationService: RegistrationService
  ) {}

  ngOnInit(): void {
    this.currentSlide = 0;
    this.slides = [
      {
        src: 'onboarding-screen-1',
        htmlText: this.domSanitizer.bypassSecurityTrustHtml(
          `<p>No more resume distribution.</p><p>Complete your profile setup once and get hired by multiple trucking companies.</p>`
        )
      },
      {
        src: 'onboarding-screen-2',
        htmlText: this.domSanitizer.bypassSecurityTrustHtml(`<p>Stand out from the crowd and advance your career.</p>`)
      },
      {
        src: 'onboarding-screen-3',
        htmlText: this.domSanitizer.bypassSecurityTrustHtml(`<p>Get faster paying jobs.</p>`)
      },
      {
        src: 'onboarding-screen-4',
        htmlText: this.domSanitizer.bypassSecurityTrustHtml(`<p>Make time for things you want to do.</p>`)
      },
      {
        src: 'onboarding-screen-5',
        htmlText: this.domSanitizer.bypassSecurityTrustHtml(`<p>Refer a friend and earn $.</p>`)
      }
    ];
  }

  onPreviousClick() {
    this.currentSlide = this.currentSlide - 1;
  }

  onNextClick() {
    const next = this.currentSlide + 1;

    if (next === this.slides.length) {
      const stage = this.registrationService.getDriverStage();
      if (stage === STAGE_IMPORTED) {
        this.updateDriverStage(STAGE_IMPORTED_VIEWED);
        this;
      } else {
        this.router.navigate([ROUTE_IDENTITY]);
      }
    } else {
      this.currentSlide = next;
    }
  }
  updateDriverStage(stage: string) {
    const driver = this.registrationService.getDriver();
    if (driver?.stage) {
      driver.stage = stage;
      delete driver.sin;
    }
    this.registrationService
      .updateDriverStage(driver)
      .pipe(
        tap(response => {
          if (response) {
            this.router.navigate([ROUTE_PROFILE]);
          }
        }),
        catchError(error => {
          return of(error);
        })
      )
      .subscribe();
  }

  onSwipeLeft() {
    if (this.currentSlide != 0) {
      this.onPreviousClick();
    }
  }
  onSwipeRight() {
    this.onNextClick();
  }
}
