import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DriverGuardService } from '../core/guards/driver-guard.service';
import { FilterShipmentsComponent } from './filter-shipments/filter-shipments.component';
import {
  SHIPMENTS_ROUTE_DASHBOARD,
  SHIPMENTS_ROUTE_DETAILS,
  SHIPMENTS_ROUTE_FILTER_SHIPMENTS,
  SHIPMENTS_ROUTE_MY_SHIPMENTS,
  SHIPMENTS_ROUTE_MY_STATUS,
  SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW,
  SHIPMENTS_ROUTE_STOPS_DETAILS
} from './shared/routes';
import { ShipmentsListComponent } from './shipments-list/shipments-list.component';
import { ShipmentsComponent } from './shipments/shipments.component';
import { ShipmentDetailComponent } from './shipment-detail/shipment-detail.component';
import { ShipmentDocumentViewComponent } from './shared/shipment-document-view/shipment-document-view.component';
import { ShipmentsDashboardComponent } from './shipments-dashboard/shipments-dashboard.component';
import { ShipmentsDocumentComponent } from './shipments-document/shipments-document.component';
import { ShipmentTimelineComponent } from './shipment-detail/shipment-timeline/shipment-timeline.component';
import { ShipmentSubDetailComponent } from './shipment-detail/shipment-sub-detail/shipment-sub-detail.component';
import { DocumentUploadComponent } from './shipments-document/document-upload/document-upload.component';
import { ShipmentsStopsComponent } from './shipments-stops/shipments-stops.component';
export const shipmentsRoutes: Routes = [
  {
    path: '',
    component: ShipmentsComponent,
    canActivate: [DriverGuardService],
    children: [
      {
        path: '',
        redirectTo: SHIPMENTS_ROUTE_MY_SHIPMENTS,
        pathMatch: 'full',
        canActivate: [DriverGuardService]
      },
      {
        path: SHIPMENTS_ROUTE_MY_SHIPMENTS,
        component: ShipmentsListComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/:id`,
        component: ShipmentDetailComponent
      },
      {
        path: `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/:id/documents`,
        component: ShipmentsDocumentComponent
      },
      {
        path: SHIPMENTS_ROUTE_FILTER_SHIPMENTS,
        component: FilterShipmentsComponent,
        canActivate: [DriverGuardService]
      },

      {
        path: `${SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW}/:id/:document-id`,
        component: ShipmentDocumentViewComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: SHIPMENTS_ROUTE_DASHBOARD,
        component: ShipmentsDashboardComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${SHIPMENTS_ROUTE_MY_STATUS}/:id`,
        component: ShipmentTimelineComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${SHIPMENTS_ROUTE_DETAILS}/:id`,
        component: ShipmentSubDetailComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/:id/documents/:type`,
        component: DocumentUploadComponent,
        canActivate: [DriverGuardService]
      },
      {
        path: `${SHIPMENTS_ROUTE_STOPS_DETAILS}/:id`,
        component: ShipmentsStopsComponent,
        canActivate: [DriverGuardService]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(shipmentsRoutes)],
  exports: [RouterModule]
})
export class ShipmentsRoutingModule {}
