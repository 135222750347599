import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DqfListComponent } from './dqf-list/dqf-list.component';
import { DqfOutletComponent } from './dqf-outlet/dqf-outlet.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DqfDocumentUploadComponent } from './dqf-document-upload/dqf-document-upload.component';
import { DqfApplicationEmployementComponent } from './dqf-application-employement/dqf-application-employement.component';
import { DqfAbstractComponent } from './dqf-abstract/dqf-abstract.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DqfSignaturePanelComponent } from './dqf-signature-panel/dqf-signature-panel.component';
import { DqfItemBoxComponent } from './dqf-document-upload/dqf-item-box/dqf-item-box.component';
import { ComponentsModule } from '../shared/components/components.module';
import { DqfViewDocumentComponent } from './dqf-view-document/dqf-view-document.component';
import { DqfStatusMsgComponent } from './dqf-document-upload/dqf-status-msg/dqf-status-msg.component';
import { DqfReviewComponent } from './dqf-review/dqf-review.component';
import { CompleteProfileSheetComponent } from './complete-profile-sheet/complete-profile-sheet.component';
import { DqfDeclarationComponent } from './dqf-declaration/dqf-declaration.component';
import { DqfDriverPersonalInformationComponent } from './dqf-review/dqf-driver-personal-information/dqf-driver-personal-information.component';
import { DqfDriverContactInformationComponent } from './dqf-review/dqf-driver-contact-information/dqf-driver-contact-information.component';
import { DqfDriverLicenseInformationComponent } from './dqf-review/dqf-driver-license-information/dqf-driver-license-information.component';
import { DqfDriverResidenceHistoryComponent } from './dqf-review/dqf-driver-residence-history/dqf-driver-residence-history.component';
import { DqfDriverTrafficConvictionsComponent } from './dqf-review/dqf-driver-traffic-convictions/dqf-driver-traffic-convictions.component';
import { DqfDriverDrivingExperienceComponent } from './dqf-review/dqf-driver-driving-experience/dqf-driver-driving-experience.component';
import { DqfDriverEmploymentHistoryComponent } from './dqf-review/dqf-driver-employment-history/dqf-driver-employment-history.component';
import { DqfDriverAccidentRecordsComponent } from './dqf-review/dqf-driver-accident-records/dqf-driver-accident-records.component';
import { DqfUploadComponent } from './dqf-upload/dqf-upload.component';
import { DqfPoliceCertificateComponent } from './dqf-police-certificate/dqf-police-certificate.component';
import { DqfPreEmploymentComponent } from './dqf-pre-employment/dqf-pre-employment.component';
import { DqfClearHouseComponent } from './dqf-clear-house/dqf-clear-house.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonDqfListComponent } from './dqf-list/skeleton-dqf-list/skeleton-dqf-list.component';
import { DqfDocumentSkeletonComponent } from './dqf-document-skeleton/dqf-document-skeleton.component';
import { SkeletonDqfReviewComponent } from './dqf-review/skeleton-dqf-review/skeleton-dqf-review.component';
import { DqfSignDeclarationComponent } from './dqf-sign-declaration/dqf-sign-declaration.component';
import { DqfRequestCarrierUploadInfoComponent } from './dqf-request-carrier-upload-info/dqf-request-carrier-upload-info.component';
import { DqfBirthLocationComponent } from './dqf-birth-location/dqf-birth-location.component';
import { DqfCriminalConvictionsComponent } from './dqf-criminal-convictions/dqf-criminal-convictions.component';
import { DqfProcessingBackgroundRequestComponent } from './dqf-processing-background-request/dqf-processing-background-request.component';
import { DqfOntarioCvorAbstractComponent } from './dqf-ontario-cvor-abstract/dqf-ontario-cvor-abstract.component';

@NgModule({
  declarations: [
    DqfListComponent,
    DqfOutletComponent,
    DqfDocumentUploadComponent,
    DqfApplicationEmployementComponent,
    DqfAbstractComponent,
    DqfSignaturePanelComponent,
    DqfItemBoxComponent,
    DqfStatusMsgComponent,
    DqfReviewComponent,
    DqfViewDocumentComponent,
    DqfStatusMsgComponent,
    CompleteProfileSheetComponent,
    DqfViewDocumentComponent,
    DqfStatusMsgComponent,
    DqfDeclarationComponent,
    DqfDriverPersonalInformationComponent,
    DqfDriverContactInformationComponent,
    DqfDriverLicenseInformationComponent,
    DqfDriverAccidentRecordsComponent,
    DqfDriverResidenceHistoryComponent,
    DqfDriverTrafficConvictionsComponent,
    DqfDriverDrivingExperienceComponent,
    DqfDriverEmploymentHistoryComponent,
    DqfUploadComponent,
    DqfPoliceCertificateComponent,
    DqfPreEmploymentComponent,
    DqfClearHouseComponent,
    SkeletonDqfListComponent,
    DqfDocumentSkeletonComponent,
    SkeletonDqfReviewComponent,
    DqfSignDeclarationComponent,
    DqfRequestCarrierUploadInfoComponent,
    DqfBirthLocationComponent,
    DqfRequestCarrierUploadInfoComponent,
    DqfCriminalConvictionsComponent,
    DqfProcessingBackgroundRequestComponent,
    DqfOntarioCvorAbstractComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    MatFormFieldModule,
    MatDividerModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatDividerModule,
    MatBottomSheetModule,
    ComponentsModule,
    NgxSkeletonLoaderModule.forRoot()
  ]
})
export class DriverQualificationFilesModule {}
