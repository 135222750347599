<mat-nav-list class="margin-bottom-md">
  <ng-container *ngFor="let action of actions">
    <a mat-list-item *ngIf="!action.disabled" (click)="action.action(); onOpenLink($event)">
      <span mat-line>{{ action.name }}</span>
    </a>
    <mat-list-item *ngIf="action.disabled" [disableRipple]="true">
      {{ action.name }}
    </mat-list-item>
  </ng-container>
</mat-nav-list>
