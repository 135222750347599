export const TRIPS_ROUTE_MYTRIPS = 'my-trips';
export const TRIPS_ROUTE_TRIPS_ENTRY = 'trips';
export const TRIPS_ROUTE_TRIPS_FULL_PATH = `${TRIPS_ROUTE_TRIPS_ENTRY}/${TRIPS_ROUTE_MYTRIPS}`;

export const TRIPS_ROUTE_TRIP_DETAILS = `${TRIPS_ROUTE_TRIPS_ENTRY}/trip-details`;
export const TRIPS_ROUTE_TRIP_DETAILS_SHORT = `trip-details`;

export const TRIPS_ROUTE_SHIPMENT_CONTACTS = `${TRIPS_ROUTE_TRIPS_ENTRY}/shipment-contacts`;
export const TRIPS_ROUTE_SHIPMENT_CONTACTS_SHORT = 'shipment-contacts';
export const TRIPS_ROUTE_SHIPMENT_DOCUMENTS = `${TRIPS_ROUTE_TRIPS_ENTRY}/shipment-documents`;
export const TRIPS_ROUTE_SHIPMENT_DOCUMENTS_SHORT = 'shipment-documents';
export const TRIPS_ROUTE_SHIPMENT_DOCUMENT = `${TRIPS_ROUTE_SHIPMENT_DOCUMENTS}`;
export const TRIPS_ROUTE_SHIPMENT_DOCUMENT_SHORT = `${TRIPS_ROUTE_SHIPMENT_DOCUMENTS_SHORT}`;
