<div class="header-navigation" fxLayout fxLayoutAlign="left">
  <span>Shipments</span>
</div>
<div *ngIf="!loading" class="list-container">
  <!-- search filter -->
  <div class="search-container" fxLayout *ngIf="upcomingShipment && currentTab === 1">
    <form class="summary-filters" fxFlex="70">
      <mat-form-field>
        <mat-icon matPrefix svgIcon="magnifying-glass" data-test="search"></mat-icon>
        <span matPrefix *ngIf="!currentSearchInput">Search&nbsp;</span>
        <input matInput (input)="onSearchInput(searchInput.value)" #searchInput [value]="currentSearchInput" />
      </mat-form-field>
    </form>
    <button mat-stroked-button class="btn-filter" fxFlex="15" (click)="navigateToFilterShipments()">
      <mat-icon svgIcon="filter" data-test="filter"></mat-icon>
    </button>
    <button mat-stroked-button class="btn-filter" fxFlex="15" (click)="sortByStatus()">
      <mat-icon svgIcon="sort" data-test="sort"></mat-icon>
    </button>
  </div>
  <div class="tag-container" *ngIf="upcomingShipment && currentTab === 1">
    <div *ngFor="let tag of tagsList">
      <span>{{ tag }}</span>
    </div>
  </div>
  <mat-tab-group
    animationDuration="0ms"
    mat-align-tabs="center"
    mat-stretch-tabs="true"
    class="tab-list"
    (selectedTabChange)="tabChanged($event?.index)"
    [selectedIndex]="currentTab"
  >
    <mat-tab label="Ongoing" data-test="ongoing-shipment-tab">
      <ng-container *ngIf="ongoingShipment">
        <div *ngFor="let shipment of ongoingShipment">
          <app-shipments-item
            [shipment]="shipment"
            [isUpcoming]="false"
            (onReload)="loadShipments()"
            (onChangeStatus)="onShipmentChangeStatus($event)"
          ></app-shipments-item>
        </div>
      </ng-container>
      <app-no-shipment
        *ngIf="!ongoingShipment?.length"
        [message]="noOngoingShipment"
        [isShipmentListPage]="true"
        icon="shipments"
      >
      </app-no-shipment>
    </mat-tab>
    <mat-tab label="Upcoming" data-test="upcoming-shipment-tab">
      <ng-container *ngIf="upcomingShipment">
        <div *ngFor="let shipment of upcomingShipment">
          <app-shipments-item
            [shipment]="shipment"
            [isUpcoming]="true"
            [isOngoingFTL]="isOngoingFTL"
            [isOngoingCount]="ongoingShipment.length"
            (onReload)="loadShipments()"
            (onChangeStatus)="onShipmentChangeStatus($event)"
          ></app-shipments-item>
        </div>
      </ng-container>
    </mat-tab>
  </mat-tab-group>

  <app-no-shipment
    *ngIf="currentTab === 1 && !upcomingShipment?.length"
    [message]="this.someTruthy || this.currentSearchInput ? noShipment : noUpcomingShipment"
    [isShipmentListPage]="true"
    icon="shipments"
  ></app-no-shipment>
</div>
