<div class="requirements-container">
  <div *ngIf="showForm; else showDecision">
    <p [innerHtml]="title"></p>
    <div [formGroup]="form" fxLayout="column">
      <mat-form-field *ngIf="userAction === 'no'">
        <input
          id="empTo"
          matInput
          placeholder="To (month, year)"
          [matDatepicker]="toDatePicker"
          [max]="maxToDate"
          [min]="minToDate"
          formControlName="toDate"
          required
          (click)="onDatePickerClick(toDatePicker)"
          onkeydown="return false;"
        />
        <mat-datepicker-toggle matSuffix [for]="toDatePicker"></mat-datepicker-toggle>
        <mat-datepicker
          #toDatePicker
          startView="multi-year"
          touchUi="true"
          (monthSelected)="chosenMonthHandler($event, toDatePicker, form, 'toDateControl')"
        >
        </mat-datepicker>
        <mat-error *ngIf="toDate.touched && toDate.invalid">
          {{ getToDateErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="isEmailNeeded">
        <input
          matInput
          placeholder="Reference Email"
          formControlName="referenceEmail"
          required
          data-test="reference-email-form-field"
        />
        <mat-error *ngIf="referenceEmail.touched && referenceEmail.invalid">
          {{ getReferenceEmailErrorMessage() }}
        </mat-error>
      </mat-form-field>
    </div>

    <div fxLayout fxLayoutAlign="end" class="actions actions-row">
      <button mat-button class="btn-accept" type="button" [disabled]="form.invalid" (click)="submitForm()">
        Confirm
      </button>
      &nbsp;&nbsp;
      <button mat-button class="btn-clear" type="button" (click)="updateShowForm(false)">
        Back
      </button>
    </div>
  </div>
  <ng-template #showDecision>
    <div class="icon-row"><mat-icon svgIcon="alert-circle"></mat-icon></div>
    <p [innerHtml]="title"></p>
    <div fxLayout fxLayoutAlign="end" class="actions actions-row">
      <button mat-button class="btn-accept" type="button" (click)="updateUserAction('yes')">
        Yes</button
      >&nbsp;&nbsp;
      <button mat-button class="btn-clear" (click)="updateUserAction('no')">No</button>
    </div>
  </ng-template>
</div>
