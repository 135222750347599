<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateToProfile()">arrow_back</mat-icon>
    <span fxFlex class="title">Education</span>
    <button
      mat-button
      [disabled]="!form.dirty || (!form.valid && !loading)"
      [class.save-disabled]="!form.dirty || (!form.valid && !loading)"
      class="header-action-button"
      (click)="onSubmit()"
      data-test="save-button"
    >
      Save
    </button>
  </div>
  <div class="content padding-sides-1x">
    <div fxLayout="column" fxLayoutAlign="center stretch" class="component-container padding-top-x2">
      <mat-error *ngIf="errorMessage" class="error">
        {{ errorMessage }}
      </mat-error>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
        <mat-form-field>
          <mat-label>Highest school grade completed</mat-label>
          <mat-select formControlName="highestGradeCompleted" data-test="highest-school-grade-selector">
            <mat-option value="NoFormalEducation">No formal education</mat-option>
            <mat-option value="PrimaryEducation">Primary education</mat-option>
            <mat-option value="SecondaryEducationHighschool">Secondary education / highschool</mat-option>
            <mat-option value="VocationalQualification">Vocational Qualification</mat-option>
            <mat-option value="BachelorsDegree">Bachelor's degree</mat-option>
            <mat-option value="MastersDegree">Master's degree</mat-option>
            <mat-option value="DoctorateOrHigher">Doctorate or higher</mat-option>
          </mat-select>
          <mat-error *ngIf="highestGradeCompleted.touched && highestGradeCompleted.invalid">
            {{ getHighestGradeCompletedErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Last school attended"
            formControlName="lastSchoolAttended"
            data-test="last-school-form-field"
          />
          <mat-error *ngIf="lastSchoolAttended.touched && lastSchoolAttended.invalid">
            {{ getLastSchoolAttendedErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Type of certificate/diploma"
            formControlName="certification"
            data-test="type-of-diploma-form-field"
          />
          <mat-error *ngIf="certification.touched && certification.invalid">
            {{ getCertificationErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <input
            matInput
            placeholder="Special courses or training"
            formControlName="specialCourses"
            data-test="special-courses-form-field"
          />
          <mat-error *ngIf="specialCourses.touched && specialCourses.invalid">
            {{ getSpecialCoursesErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
</div>
