import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/storage/storage.service';

const KEY_DRIVER = 'driver';

@Component({
  selector: 'app-dqf-driver-contact-information',
  templateUrl: './dqf-driver-contact-information.component.html',
  styleUrls: ['./dqf-driver-contact-information.component.scss']
})
export class DqfDriverContactInformationComponent implements OnInit {
  @Input() driverVersionDetails: any;
  data = [];
  constructor(private storageService: StorageService) { }

  ngOnInit(): void {
    this.getDriver();
  }

  private getDriver(): void {
    const driver = this.driverVersionDetails ?? this.storageService.find(KEY_DRIVER)
    if (driver) {
      this.data = [
        { propertyName: 'Address', propertyValue: `${driver.address}` },
        { propertyName: 'Phone', propertyValue: `${driver.phoneNumber}` },
        { propertyName: 'Email', propertyValue: `${driver.email}` }
      ]
    }
  }

}
