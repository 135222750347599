<div fxLayoutAlign="start" fxLayout="column" class="page full-height gray-font-color font-family bodyBackground">
  <div class="header-navigation-back ">
    <mat-icon data-test="back-arrow-icon" (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Shipment Details</span>
    <button mat-button class="header-action-button">
      <mat-icon svgIcon="ellipses" data-test="more" [matMenuTriggerFor]="addmenu"></mat-icon>
    </button>
    <div>
      <mat-menu #addmenu="matMenu" class="button-menu-container" xPosition="after">
        <button mat-menu-item (click)="navigateToDocuments()" class="menu-button">
          Documents
        </button>
        <mat-divider class="divider-class"></mat-divider>
        <button mat-menu-item (click)="navigateToMyStatus()" class="menu-button">
          Status
        </button>
      </mat-menu>
    </div>
  </div>
  <div fxLayout="column" class="full-height margin-bottom-md header" *ngIf="isContentReady">
    <div fxLayout="column" class="section">
      <div fxLayout class="shipment-meta-container">
        <div class="shipment-id-container">
          <div class="shipment-id">{{ shipment.shipmentID || '-' }}</div>
          <br />
          <div class="shipment-type" *ngIf="!isConfirmationPending">{{ this.shipmentType }}</div>
        </div>
        <div fxFlex="40" class="shipment-status-container">
          <div [ngClass]="[getShipmentStatusClass(), 'status-tag']">
            {{ shipment.status === 'out for delivery' ? 'In Transit' : shipment.status | titlecase }}
          </div>
        </div>
      </div>
      <div fxLayoutGap="10px" fxLayout="column" class="origin-des-container">
        <div fxLayout fxFlex="30" class="origin-des">
          <div class="text-align-left">{{ pickupAddress.updatedOrigin || '-' }}</div>
          <div fxFlex="30" fxLayout class="location-icons-container">
            <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
            <div fxFlex="55" class="border-bottom"></div>
            <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
          </div>
          <div class="text-align-right">{{ deliveryAddress.updatedDestination || '-' }}</div>
        </div>
        <div fxLayout class="address">
          <div fxFlex="40" class="text-align-left">{{ pickupAddress.updatedStreetAddress || '-' }}</div> 
          <div fxFlex="40" class="text-align-right">{{ deliveryAddress.updatedStreetAddress || '-' }}</div>
        </div>
        <div fxLayout class="time">
          <div fxFlex="40" class="text-align-left">{{ pickupAddress.updatedPickupDate || '-' }}, {{ pickupAddress.updatedPickupTime || '-' }}</div>
          <div fxFlex="40" class="text-align-right">{{ deliveryAddress.updatedDeliveryDate || '-' }}, {{ deliveryAddress.updatedDeliveryTime || '-' }}</div>
        </div>
      </div>
    </div>
  </div>
  <mat-divider class="margin-bottom-sm"></mat-divider>
  <div class="detail-panel-body no-scroll margin-top-md">
    <div fxLayout="column" class="sub-detail-content" *ngIf="isContentReady">
      <div fxLayout="column" fxLayoutGap="15px" class="shipment-meta-details">
        <div fxLayout class="detail-row">
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="truck" class="text-align-center"></mat-icon>
            </div>
            <div>
              <div class="detail-title">Unit</div>
              <div class="detail-value">
                {{ shipment.unitNumber || '-' }}
              </div>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="trailer" class="grey-icon text-align-center"></mat-icon>
            </div>
            <div>
              <div class="detail-title">Trailer</div>
              <div class="detail-value">{{ shipment.trailerNumber || '-' }}</div>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="trailer" class="text-align-center"></mat-icon>
            </div>
            <div>
              <div class="detail-title">Load Size</div>
              <div class="detail-value">
                {{ loadTypeMap[shipment.loadType] || '-' }}
              </div>
            </div>
          </div>
        </div>
        <div fxLayout class="detail-row">
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="weight-scale" class="text-align-center"></mat-icon>
            </div>
            <div>
              <div class="detail-title">Weight</div>
              <div class="detail-value">
                {{ shipment.weight ? shipment.weight : '-' }}
                <span>{{ shipment.weightUnit ? shipment.weightUnit : '' }}</span>
              </div>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="pin-distance" class="grey-icon text-align-center"></mat-icon>
            </div>
            <div>
              <div class="detail-title">Miles</div>
              <div class="detail-value">{{ shipment.distance ? shipment.distance + 'Miles' : '-' }}</div>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="arrow-up-down-line" class="text-align-center"></mat-icon>
            </div>
            <div>
              <div class="detail-title">DH-O/DH-D</div>
              <div class="detail-value">
                {{ shipment.deadHeadOrigin ? shipment.deadHeadOrigin + ' Miles' : '-' }}/{{
                  shipment.deadHeadDestination ? shipment.deadHeadDestination + ' Miles' : '-'
                }}
              </div>
            </div>
          </div>
        </div>
        <div fxLayout class="detail-row">
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="money-dollar-circle-line" class="grey-icon text-align-center"></mat-icon>
            </div>
            <div fxFlex="70">
              <div class="detail-title">Rate</div>
              <div class="detail-value" *ngIf="shipment.rateVisibility !== false; else showNoRateValue">
                {{ shipment.offerRate ? '$' + (shipment.offerRate | number) : '-' }}
                <span class="currency-txt" *ngIf="shipment.rateCurrency">{{ shipment.rateCurrency | uppercase }}</span>
              </div>
              <ng-template #showNoRateValue>
                -
              </ng-template>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="money-dollar-circle-line" class="grey-icon text-align-center"></mat-icon>
            </div>
            <div fxFlex="70">
              <div class="detail-title">Rate per mile</div>
              <div class="detail-value" *ngIf="shipment.rateVisibility !== false; else showNoRatePerMileValue">
                {{ shipment.offerRate ? '$' + (shipment.offerRate | number) : '-' }}
                <span class="currency-txt" *ngIf="shipment.rateCurrency">{{ shipment.rateCurrency | uppercase }}</span>
              </div>
              <ng-template #showNoRatePerMileValue>
                -
              </ng-template>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="inbox-line" class="text-align-center"></mat-icon>
            </div>
            <div fxFlex="70">
              <div class="detail-title">Commodity</div>
              <div class="detail-value">{{ shipment.commodity || '-' }}</div>
            </div>
          </div>
        </div>
        <div fxLayout class="detail-row">
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="truck-line" class="text-align-center"></mat-icon>
            </div>
            <div fxFlex="70">
              <div class="detail-title">Load Size</div>
              <div class="detail-value">{{ shipment.loadSize || '-' }}</div>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="ruler-line" class="text-align-center"></mat-icon>
            </div>
            <div fxFlex="70">
              <div class="detail-title">Length</div>
              <div class="detail-value">{{ shipment.length || '-' }}</div>
            </div>
          </div>
          <div fxFlex="33" fxLayout>
            <div fxFlex="30">
              <mat-icon svgIcon="temp-cold-line" class="text-align-center"></mat-icon>
            </div>
            <div fxFlex="70">
              <div class="detail-title">Temperature</div>
              <div class="detail-value">{{ shipment?.temperature ? shipment?.temperature + '&#8457;' : 'N/A' }}</div>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
     <div *ngIf="shipment.pickDropType !== 'sequence'">
      <div fxLayout="column" fxLayoutGap="10px" class="pickup-details">
        <div class="header">
          Pickup instructions
        </div>
        <div fxLayout *ngIf="pickupInstructionChips?.length">
          <div *ngFor="let chip of pickupInstructionChips" class="driver-chipset">{{ chip }}</div>
        </div>
        <div class="instruction">
          {{ shipment.pickup.instructions || 'none' }}
        </div>
        <div fxLayout="column" fxLayoutGap="10px" class="contact-info-container">
          <div fxLayout fxLayoutGap="5px">
            <div fxFlex="40">
              <div class="contact-tag">Business Name</div>
              <div class="contact-value">{{ shipment.pickup.businessName || '-' }}</div>
            </div>
            <div fxFlex="35">
              <div class="contact-tag">Contact name</div>
              <div class="contact-value">{{ shipment.pickup.contactName || '-' }}</div>
            </div>
          </div>
          <div fxLayout fxLayoutGap="5px">
            <div fxFlex="40">
              <div class="contact-tag">Contact email</div>
              <div class="contact-value">{{ shipment.pickup.contactEmail || '-' }}</div>
            </div>
            <div fxFlex="35">
              <div class="contact-tag">Contact phone</div>
              <div class="contact-value">{{ shipment.pickup.contactPhone || '-' }}</div>
            </div>
            <div fxFlex="20">
              <button class="contact-icon" [disabled]="!shipment.pickup?.contactPhone">
                <a [href]="'tel:' + '+1' + shipment.pickup?.contactPhone">
                  <mat-icon svgIcon="phonecall"></mat-icon>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="column" fxLayoutGap="10px" class="delivery-details">
        <div class="header">
          Delivery instructions
        </div>
        <div fxLayout *ngIf="deliveryInstructionChips?.length">
          <div *ngFor="let chip of deliveryInstructionChips" class="driver-chipset">{{ chip }}</div>
        </div>
        <div class="instruction">
          {{ shipment.delivery.instructions || 'none' }}
        </div>
        <div fxLayout="column" fxLayoutGap="10px" class="contact-info-container">
          <div fxLayout fxLayoutGap="5px">
            <div fxFlex="40">
              <div class="contact-tag">Business Name</div>
              <div class="contact-value">{{ shipment.delivery.businessName || '-' }}</div>
            </div>
            <div fxFlex="35">
              <div class="contact-tag">Contact name</div>
              <div class="contact-value">{{ shipment.delivery.contactName || '-' }}</div>
            </div>
          </div>
          <div fxLayout fxLayoutGap="5px">
            <div fxFlex="40">
              <div class="contact-tag">Contact email</div>
              <div class="contact-value">{{ shipment.delivery.contactEmail || '-' }}</div>
            </div>
            <div fxFlex="35">
              <div class="contact-tag">Contact phone</div>
              <div class="contact-value">{{ shipment.delivery.contactPhone || '-' }}</div>
            </div>
            <div fxFlex="20">
              <button class="contact-icon" [disabled]="!shipment.delivery?.contactPhone">
                <a [href]="'tel:' + '+1' + shipment.delivery?.contactPhone">
                  <mat-icon svgIcon="phonecall"></mat-icon>
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
     </div>
     <div *ngIf="shipment.pickDropType === 'sequence'">
      <app-shipments-stops [readOnly]="true">
      </app-shipments-stops>
     </div>
    </div>
  </div>
</div>
