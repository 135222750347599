<div data-test="add-comment-box">
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <div class="title margin-top-lg">
    {{ isEditComment ? 'Edit' : 'Add' }}  comment&nbsp;<span *ngIf="totalActions">{{ currentActionStep }}/{{ totalActions + 1 }}</span>
  </div>
  <mat-form-field class="full-width" appearance="outline">
    <textarea
      matInput
      [formControl]="commentFormField"
      maxlength="200"
      placeholder="Type here..."
      data-test="text-box"
    ></textarea>
  </mat-form-field>
  <div class="button-container margin-bottom-md">
    <div class="actions-row">
      <button mat-button class="btn-clear" (click)="onSkipOrCancel()" [disabled]="saving" data-test="skip-button" >
        {{ totalActions ? 'Skip' : 'Cancel' }}
      </button>
      <button
        mat-button
        class="btn-accept"
        (click)="onUpdate(commentFormField.value)" data-test="update-button"

        [disabled]="saving || !commentFormField.valid"
      >
        Update
      </button>
    </div>
  </div>
</div>
