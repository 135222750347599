<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
  <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex class="padding-top-md">
    <div fxLayout fxFlexAlign="center" class="forgot-icon">
      <img src="assets/img/forgot-password-icon.png" />
    </div>
    <div>
      <h2 class="title margin-bottom-sm">Request New <span>Password</span></h2>
    </div>
    <div fxFlexAlign="center" class="messageText">
      <p>{{ message }}</p>
    </div>

    <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error" data-test="form-error-message">
      {{ errorMessage }}
    </mat-error>
    <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column" class="padding-top-x2">
      <div fxLayout="column" fxFlexAlign="center">
        <label class="label">Account Email</label>
        <mat-form-field class="email-field" appearance="outline">
          <input type="email" matInput formControlName="email" data-test="email-form-field" placeholder="Email" />
          <mat-error *ngIf="email.touched && email.invalid">
            {{ getEmailErrorMessage() }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayout fxLayoutAlign="center end" class="padding-top-x4">
        <button
          fxFlex.gt-sm="20%"
          fxFlex.lt-md="100%"
          class="full-width margin-bottom btn-action-primary"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="form.pristine || !form.valid || loading"
          data-test="submit-button"
        >
          Submit
        </button>
      </div>
    </form>
    <span fxLayout="row" fxLayoutAlign="center center" class="account-info margin-top-md">
      <p>Don't have an account?</p>
      <a mat-button [routerLink]="[]" (click)="onNavigateToSignIn()" class="signin padding-top-sm" data-test="backto-signin-link">
        Back
      </a>
    </span>
  </div>
</div>
