import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ToastComponent } from './toast.component';
import { ToastModule } from './toast.module';

@Injectable({
  providedIn: ToastModule
})
export class ToastService {

  constructor(private snackBar: MatSnackBar) { }

  showSuccess(message: string) {
    this.openSnackBar('toast-success', message, 'check_circle');
  }

  showInfo(message: string) {
    this.openSnackBar('toast-info', message , 'info');
  }

  showWarning(message: string) {
    this.openSnackBar('toast-warning', message, 'warning');
  }

  showError(message: string) {
    this.openSnackBar('toast-error', message, 'error');
  }

  private openSnackBar(panelClassType: string, message: string, iconType: string) {
    const config = {
      duration: 5 * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: panelClassType,
      data: { message, iconType }
    } as MatSnackBarConfig;
    this.snackBar.openFromComponent(ToastComponent, config);
  }
}


