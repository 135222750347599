export const DQF_DOC_STATUS = {
  REJECTED: 'Rejected',
  DRIVER_REVIEWED: 'DriverReviewed',
  DRIVER_SIGNED: 'DriverSigned',
  ACCEPTED: 'Accepted',
  PENDING: 'Pending',
  PROCESSING: 'Processing',
  CARRIER_UPLOAD_REQUESTED: 'CarrierUploadRequested'
};
export const DOCTYPE_PERSONAL_INFO = 'PersonalInformation';
export const DOCTYPE_LICENSE_TYPE = 'DriverLicense';
export const DOCTYPE_RESIDENCE_HISTORY = 'ResidenceHistory';
export const DOCTYPE_DRIVING_EXPERIENCE = 'DriverExperience';
export const DOCTYPE_EMP_HISTORY = 'EmploymentHistory';
export const DOCTYPE_ACCIDENT_HISTORY = 'AccidentsHistory';
export const DOCTYPE_TRAFFIC_CONVICTION = 'TrafficConvictionsHistory';
export const DOCTYPE_ON_DUTY_HOURS = 'OnDutyHours';

//driver docs
export const DECLARATION = 'declarationForm';
export const ABSTRACT = 'Abstract';
export const POLICE_CLEARANCE = 'PoliceClearance';
export const PRE_EMPLOYMENT_SCREENING = 'PreEmploymentScreening';
export const EMPLOYMENT_HISTORY_CHECK = 'EmploymentHistoryCheck';
export const ACTION_TYPE_COLLECT = 'collect'
export const ONTARIO_DRIVING_ABSTRACT = 'OntarioCvorAbstract';
export const PROVINCE_ONTARIO = 'on';

export const ALL_DRIVER_DOCS_ARRAY = [
  DECLARATION,
  ABSTRACT,
  POLICE_CLEARANCE,
  PRE_EMPLOYMENT_SCREENING,
  EMPLOYMENT_HISTORY_CHECK,
  ONTARIO_DRIVING_ABSTRACT
]

export const DRIVER_DOCS_ARRAY = [
  DECLARATION,
  ABSTRACT,
  POLICE_CLEARANCE,
  PRE_EMPLOYMENT_SCREENING,
  EMPLOYMENT_HISTORY_CHECK
];

export const DRIVER_DOCS_FOR_NOT_ONTARIO_US = DRIVER_DOCS_ARRAY;

export const DRIVER_DOCS_FOR_NOT_ONTARIO_NOT_US = [
  DECLARATION,
  ABSTRACT,
  POLICE_CLEARANCE,
  EMPLOYMENT_HISTORY_CHECK
];

export const DRIVER_DOCS_FOR_ONTARIO_US = [
  DECLARATION,
  ABSTRACT,
  POLICE_CLEARANCE,
  EMPLOYMENT_HISTORY_CHECK,
  PRE_EMPLOYMENT_SCREENING,
  ONTARIO_DRIVING_ABSTRACT
];

export const DRIVER_DOCS_FOR_ONTARIO_NOT_US = [
  DECLARATION,
  ABSTRACT,
  POLICE_CLEARANCE,
  EMPLOYMENT_HISTORY_CHECK,
  ONTARIO_DRIVING_ABSTRACT
];

export const PRE_EMPLOYMENT_REQUIRED = [
  "ownerOperatorCanadaAndUS",
  "companyDriverCanadaAndUS",
  "ownerOperatorIntrastate",
  "companyDriverIntrastate",
  "ownerOperatorAcrossUS",
  "companyDriverAcrossUS",
  "ownerOperatorUSAndCanada",
  "companyDriverUSAndCanada"
]

export const DRIVER_TYPE_MAP = {
  ownerOperatorProvincial: 'O/O - Provincial',
  ownerOperatorCrossBorder: 'O/O - Cross Border',
  companyDriverProvincial: 'C/D - Provincial',
  companyDriverCrossBorder: 'C/D - Cross Border',
  ownerOperatorAcrossCanada: 'O/O - Across Canada',
  companyDriverAcrossCanada: 'C/D - Across Canada',
  ownerOperatorCanadaAndUS: 'O/O Canada And US',
  companyDriverCanadaAndUS: 'C/D Canada And US',
  ownerOperatorIntrastate: 'O/O Intrastate',
  companyDriverIntrastate: 'C/D Intrastate',
  ownerOperatorAcrossUS: 'O/O Across US',
  companyDriverAcrossUS: 'C/D Across US',
  ownerOperatorUSAndCanada: 'O/O US And Canada',
  companyDriverUSAndCanada: 'C/D US And Canada',
};

//carrier docs
export const DRUG_ALCOHOL_TEST = 'DrugAlcoholTest';
export const ROAD_TEST = 'RoadTest';
export const DRUG_ALOCHOL_CLEANING_HOUSE = 'DrugAndAlcoholClearingHouseReport';
export const DRIVER_TRAINING_TESTS = "DriverTrainingTests"

export const CARRIER_DOC_ARRAY = [DRUG_ALCOHOL_TEST, ROAD_TEST, DRUG_ALOCHOL_CLEANING_HOUSE, DRIVER_TRAINING_TESTS];

//total DQF DOC
export const TOTAL_DQF_DOCS = [
  DOCTYPE_PERSONAL_INFO,
  DOCTYPE_LICENSE_TYPE,
  DOCTYPE_RESIDENCE_HISTORY,
  DOCTYPE_DRIVING_EXPERIENCE,
  DOCTYPE_EMP_HISTORY,
  DOCTYPE_ACCIDENT_HISTORY,
  DOCTYPE_TRAFFIC_CONVICTION,
  DOCTYPE_ON_DUTY_HOURS,
  ...DRIVER_DOCS_ARRAY,
  ...CARRIER_DOC_ARRAY
];

export const DRIVER_DOC_CONTEXT_INITIAL_DQF = 'initialDQF';
