import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-dqf-review',
  templateUrl: './skeleton-dqf-review.component.html',
  styleUrls: ['../dqf-review.component.scss', './skeleton-dqf-review.component.scss']
})
export class SkeletonDqfReviewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
