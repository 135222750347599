import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'fleetoperate-environment-ribbon',
  templateUrl: './environment-ribbon.component.html',
  styleUrls: ['./environment-ribbon.component.scss']
})
export class EnvironmentRibbonComponent implements OnInit {
  @Input() position: string;
  @Input() colour: string;
  @Input() text: string;
  @Input() hoverText: string;
  constructor() {}

  ngOnInit() {}
}
