import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ShipmentsUtilsService } from '../shared/shipments-utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { ToastService } from 'src/app/shared/toast/toast.service';
import {
  DEFAULT_ERROR_MESSAGE,
  STATUS_CONFIRMATION_PENDING,
  STATUS_ASSIGNED,
  STOP_DISPLAY_NAMES,
  STATUS_UNLOADING,
  STATUS_OUT_FOR_DELIVERY,
  STATUS_DELIVERED,
  STOPS
} from '../shared/constants';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { Delivery } from 'src/app/loads-shared/shipment-data/models/delivery';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { title } from 'process';
import { ShipmentsStopsActionSheetComponent } from '../shipments-stops-action-sheet/shipments-stops-action-sheet.component';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { LOADS_ROUTE_SHIPMENT_DOCUMENTS_SHORT } from 'src/app/loads/shared/routes';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_SHIPMENTS } from '../shared/routes';
import { LocationDetails } from 'src/app/loads-shared/shipment-data/models/location';
import { ShipmentActionsService } from 'src/app/loads-shared/shipment-actions.service';
import { InformationDialogService } from 'src/app/shared/dialog/information-dialog.service';
import { Action } from 'src/app/shared/actions/models/action.model';

const SHIPMENT_TYPE_ONGOING = 'Ongoing';
const SHIPMENT_TYPE_UPCOMING = 'Upcoming';
const TIME_FORMAT = 'hh:mm a z';
const ERROR_MESSAGE_NO_LOCATION_PERMISSION = 'Unable to access device location.';
const DISPATCHER_ROLE = "Dispatcher";

interface GeolocationPosition {
  coords: {
    latitude: number;
    longitude: number;
  };
}
const documentDisplayNames = {
  BoL: 'BillOfLading',
  PoD: 'ProofOfDelivery',
  Fuel: 'Fuel',
  ACIeManifest: 'ACIeManifest',
  ACEeManifest: 'ACEeManifest'
};
const documentTypes = {
  hookTrailer: 'BillOfLading',
  pickupLoad: 'BillOfLading',
  deliverLoad: 'ProofOfDelivery',
  dropTrailer: 'ProofOfDelivery',
  fuel: 'Fuel'
};
const BUTTON_DISPLAY_NAMES = new Map([
  ['pickupLoad', 'loading'],
  ['deliverLoad', 'unloading']
]);
@Component({
  selector: 'app-shipments-stops',
  templateUrl: './shipments-stops.component.html',
  styleUrls: ['./shipments-stops.component.scss']
})
export class ShipmentsStopsComponent implements OnInit {
  shipmentId: string;
  isContentReady: boolean = false;
  shipment: Shipment;
  shipmentType: string = '';
  showStopDetailsContainer: boolean = false;
  stops: any[] = [];
  selectedStopIndex: number;
  selectedStopIndices: number[] = [];
  delivery;
  formattedDates;
  acceptTypesPicture = ['image/png', 'image/jpg', 'image/jpeg'];
  acceptTypesMultiple = ['application/pdf'];
  isUploading: boolean = false;
  fileToUpload;
  documentPicture;
  loading: boolean = false;
  showCrop: boolean = false;
  imageChangedEvent;
  allowToUpload: boolean = true;
  shipmentDocuments: ShipmentDocument[];
  uploadedDocumentName: string;
  uploadedDocumentId: string;
  statusComplete = 0;
  disableDocumentUpload = [STOPS.PICK_TRUCK, STOPS.DROP_TRUCK];
  pickupStop;
  activeStop;
  DELIVER_LOAD = 'deliverLoad';
  PICKUP_LOAD = 'pickupLoad';
  STATUS_PENDING = 'pending';
  STATUS_COMPLETE = 'complete';
  shipmentStatus: string;
  highestPickup;
  highestDropOff;
  isDocumentName: string;
  aciDocumentId: string;
  aceDocumentId: string;
  highestDeliverLoad;
  lowestPickupLoad;
  @Input() readOnly: boolean;
  driverAssistedDelivery: any;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly shipmentsUtilsService: ShipmentsUtilsService,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly toastService: ToastService,
    private readonly dateTimeService: DateTimeService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly documentsDataService: DocumentsDataService,
    private readonly router: Router,
    private readonly informationDialogService: InformationDialogService,
    private readonly shipmentActionsService: ShipmentActionsService,
  ) {}

  ngOnInit(): void {
    if (!this.shipmentId) {
      this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
      this.getShipmentDetails();
    }
  }
  navigateBack(): void {
    this.location.back();
  }
  getShipmentStatusClass(): string {
    return this.shipmentsUtilsService.getShipmentStatusClass(this.shipment.status);
  }

  getShipmentDetails(): void {
    this.isContentReady = false;
    this.shipmentsDataService
      .getShipmentDetails(this.shipmentId)
      .pipe(
        first(),
        tap((response: Shipment) => {
          if (response) {
            this.shipment = response;
            this.delivery = response.delivery;
            if (Array.isArray(response.delivery)) {
              this.stops = [...response.delivery];
              this.stops.sort((a, b) => a.order - b.order);
            } else {
              this.stops = [response.delivery];
            }
            if (response.pickup) {
              this.pickupStop = response.pickup;
              this.pickupStop.setOrder = 1;
              this.stops.unshift(this.pickupStop);
            }
            for (let i = 1; i < this.stops.length; i++) {
              this.stops[i].setOrder = i + 1;
            }
            this.statusComplete = this.stops.filter(item => item.status === this.STATUS_COMPLETE)?.length || 0;
            this.getShipmentDocuments();
            this.stops.forEach((stop, index) => {
              const deliveryDateFrom = stop.deliveryDateFrom || stop.pickupDateFrom;
              const deliveryDateTo = stop.deliveryDateTo || stop.pickupDateTo;
              if (deliveryDateFrom) {
                this.formattedDates = this.dateTimeService.getFormatedConvertedUTCDate(deliveryDateFrom);
              } else {
                this.formattedDates = this.dateTimeService.getFormatedConvertedUTCDate(deliveryDateTo);
              }
              const formattedTime = this.dateTimeService.formattedDateTime(deliveryDateFrom, TIME_FORMAT);
              this.stops[index].convertedDate = this.formattedDates;
              this.stops[index].convertedTime = formattedTime;
              this.stops[index].documentUploadName = Object.keys(documentDisplayNames).find(
                key => documentDisplayNames[key] === documentTypes[stop.stopType]
              );
            });
            let allPending = this.stops.every(item => item.status === this.STATUS_PENDING);
            if (allPending) {
              this.activeStop = 0;
            } else {
              [...this.stops].every((item, index) => {
                if (index == this.stops.length - 1) {
                  this.activeStop = index;
                  return false;
                }
                if (item.status == this.STATUS_COMPLETE && this.stops[index + 1]?.status !== this.STATUS_COMPLETE) {
                  this.activeStop = index + 1;
                  return false;
                } else return true;
              });
            }
            const pickupStops = [STOPS.PICK_TRUCK, STOPS.HOOK_TRAIKER, STOPS.PICK_LOAD];
            const pickups = this.stops.filter(el => pickupStops.includes(el.stopType));
            const dropStops = [STOPS.DELIVER_LOAD ,STOPS.DROP_TRUCK, STOPS.DROP_TRAILER];
            const dropOffs = this.stops.filter(el => dropStops.includes(el.stopType));
            const pickupLoads = [STOPS.PICK_LOAD];
            const pickupLoad = this.stops.filter(el => pickupLoads.includes(el.stopType));
            const deliverLoads = [STOPS.DELIVER_LOAD];
            const deliveryLoad = this.stops.filter(el => deliverLoads.includes(el.stopType));
            this.getPickupWithHighestSetOrder(pickups);
            this.getDropsWithHighestSetOrder(dropOffs);
            this.getDeliverLoadWithHighestSetOrder(deliveryLoad);
            this.getLowesPickUpLoadSetOrder(pickupLoad)
            this.getShipmentType();
          }
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          setTimeout(() => {
            this.isContentReady = true;
          }, 1000)
        })
      )
      .subscribe();
  }

  getPickupWithHighestSetOrder(pickups) {
    let highestSetOrder = -1;
    pickups.forEach(pickup => {
      if (pickup.setOrder && pickup.setOrder > highestSetOrder) {
        highestSetOrder = pickup.setOrder;
        this.highestPickup = pickup;
      }
    });
  }

  getDropsWithHighestSetOrder(dropOffs) {
    let highestSetOrder = -1;
    dropOffs.forEach(dropOff => {
      if (dropOff.setOrder && dropOff.setOrder > highestSetOrder) {
        highestSetOrder = dropOff.setOrder;
        this.highestDropOff = dropOff;
      }
    });
  }

  getDeliverLoadWithHighestSetOrder(deliveryLoad) {
    let highestSetOrder = -1;
    deliveryLoad.forEach(delivery => {
      if (delivery.setOrder && delivery.setOrder > highestSetOrder) {
        highestSetOrder = delivery.setOrder;
        this.highestDeliverLoad = delivery;
      }
    });
  }

  getLowesPickUpLoadSetOrder(pickupLoad) {
    let lowestSetOrder = Infinity;
    let lowestPickupLoad = null;
    if(pickupLoad.length > 1){
      pickupLoad.forEach(pickup => {
        if (pickup.setOrder && pickup.setOrder < lowestSetOrder) {
          lowestSetOrder = pickup.setOrder;
          this.lowestPickupLoad = pickup;
        }
      });
    }
  return 
  }

  getStopName(stop): any {
    return STOP_DISPLAY_NAMES.get(stop) || '';
  }

  getButtonName(stop): any {
    return BUTTON_DISPLAY_NAMES.get(stop) || '';
  }

  getDriverAssistedDelivery(stop): any {
    this.driverAssistedDelivery = stop.driverAssistedDelivery;
    if(this.driverAssistedDelivery){
      return 'Yes'
    } else return 'No'
  }
  getShipmentType(): void {
    if (this.shipment.status !== STATUS_CONFIRMATION_PENDING && this.shipment.status !== STATUS_ASSIGNED) {
      this.shipmentType = SHIPMENT_TYPE_ONGOING;
    } else {
      this.shipmentType = SHIPMENT_TYPE_UPCOMING;
    }
  }

  toggleDetailSection(index: number, stopId: string): void {
    const selectedIndex = this.selectedStopIndices.indexOf(index);
    if (selectedIndex !== -1) {
      this.selectedStopIndices.splice(selectedIndex, 1);
    } else {
      this.selectedStopIndices.push(index);
    }
    this.showStopDetailsContainer = this.selectedStopIndices.length > 0;
  }

  openActionSheet(stopId, stopType, documentType): void {
    const dialogRef = this.bottomSheet.open(ShipmentsStopsActionSheetComponent, {
      data: {
        title: title,
        shipmentId: this.shipment.id,
        shipmentStopId: stopId,
        documentType: documentType ? documentType : documentTypes[stopType]
      }
    });
    dialogRef.afterDismissed().subscribe(res => {
      this.getShipmentDetails();
    });
  }

  isButtonDisabled(index: number): boolean {
    if (index > 0 && this.stops[index - 1].status !== this.STATUS_COMPLETE) {
      return true;
    }
    return false;
  }

  disableHookTrailerUpload(stop){
    return !(stop.stopType === STOPS.HOOK_TRAIKER && this.stops.findIndex(stop => stop.stopType === STOPS.PICK_LOAD) !== -1);
  }

  disableDropTrailerUpload(stop){
    return !(stop.stopType === STOPS.DROP_TRAILER && this.stops.findIndex(stop => stop.stopType === STOPS.DELIVER_LOAD) !== -1);
  }

  getShipmentDocuments(): void {
    this.isContentReady = false;
    this.documentsDataService
      .getShipmentDocuments(this.shipmentId)
      .pipe(
        first(),
        tap((response: ShipmentDocument[]) => {
          this.shipmentDocuments = response;
          this.stops.forEach((stop, index) => {
            const documentName = response.find(item => item.shipmentStopId === stop.id);
            if (documentName) {
              this.stops[index].document = documentName;
              this.stops[index].documentDisplayName = Object.keys(documentDisplayNames).find(
                key => documentDisplayNames[key] === documentName.type
              );
            }
          });
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
            this.isContentReady = true;
        })
      )
      .subscribe();
  }


  viewUploadedDocument(documentId: string) {
    this.router.navigate([
      `${SHIPMENTS_ROUTE}/${LOADS_ROUTE_SHIPMENT_DOCUMENTS_SHORT}/${this.shipmentId}/${documentId}`
    ]);
  }

  onShipmentChangeStatus(data: any): void {
    if (data?.status === STATUS_DELIVERED) {
      this.informationDialogService.openInformationDialog(
        'Great job!',
        'In order to complete your shipment process please answer few questions.',
        null,
        { action: () => this.showShipmentFeedbackDialog(data), name: 'Start' } as Action,
        null,
        null,
        'modern'
      );
    }
  }

  private showShipmentFeedbackDialog(shipment: Shipment): void {
    const dialogRef = this.shipmentActionsService.showShipmentFeedbackDialog(shipment);
    dialogRef.afterClosed().subscribe(() => this.showThankYouForFeedbackDialog());
  }

  private showThankYouForFeedbackDialog(): void {
    this.informationDialogService.openInformationDialog(
      'Thank you for your feedback',
      'Your shipment has been successfully completed.',
      null,
      { action: () => this.goToShipmentsPage(), name: 'Close' } as Action,
      null,
      'checkbox-circle-line-green',
      'modern',
      'green'
    );
  }

  goToShipmentsPage(){
    let path = `/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_SHIPMENTS}`;
    this.router.navigate([path]);
  }

  async changeStopStatus(stop: any, STATUS: string) {
    this.isContentReady = false;
    const removeFields = [
      'convertedDate',
      'convertedTime',
      'documentUploadName',
      'documentDisplayName',
      'setOrder',
      'document'
    ];
    let saveModel: any = {};
    let model: any = {};

    if (stop.id === this.pickupStop.id) {
      const pickUp = JSON.parse(JSON.stringify(this.pickupStop));
      removeFields.forEach(field => delete pickUp[field]);
      model['pickup'] = { ...pickUp, status: STATUS };

      if (this.highestPickup.stopType === stop.stopType && STATUS === this.STATUS_COMPLETE) {
        this.shipmentStatus = this.shipment.status = STATUS_OUT_FOR_DELIVERY;
      }
    } else {
      const deliveries = [...this.stops.filter(stop => stop.id !== this.pickupStop.id)];
      deliveries.forEach(delivery => {
        removeFields.forEach(field => delete delivery[field]);
      });
      const index = deliveries.findIndex(item => item.id === stop.id);
      model['delivery'] = [...deliveries];
      if (index > -1) {
        model['delivery'][index].status = STATUS;
      }
      if (this.highestDeliverLoad?.id === stop.id && STATUS === STATUS_UNLOADING) {
        this.shipmentStatus = this.shipment.status = STATUS_UNLOADING;
      } else if (this.highestDropOff?.id === stop.id && STATUS === this.STATUS_COMPLETE) {
        this.shipmentStatus = this.shipment.status = STATUS_DELIVERED;
      } else if (this.lowestPickupLoad && this.lowestPickupLoad.id === stop.id && STATUS === this.STATUS_COMPLETE) {
        this.shipmentStatus = this.shipment.status = STATUS_OUT_FOR_DELIVERY;
      } else if (!this.lowestPickupLoad && this.highestPickup.id === stop.id && STATUS === this.STATUS_COMPLETE) {
        this.shipmentStatus = this.shipment.status = STATUS_OUT_FOR_DELIVERY;
      }
    }
      try {
        saveModel = await this.getLocationWithShipmentEvents(model, stop.id, STATUS, this.shipmentStatus);
      } catch (error) {
        this.toastService.showError(ERROR_MESSAGE_NO_LOCATION_PERMISSION);
      }
    await this.updateShipment(saveModel);
    this.isContentReady = true;
  }

  async getLocationWithShipmentEvents(model: any, stopId: string, stopStatus: string, shipmentStatus?: string) {
    const locationData = await this.getLocationData(stopId, stopStatus, shipmentStatus);
    const saveModel = {
      ...(shipmentStatus ? { status: shipmentStatus } : {}),
      ...(model.hasOwnProperty('pickup') ? { pickup: model['pickup'] } : { delivery: model['delivery'] }),
      events: locationData.events
    };
    return saveModel;
  }
  
  async getLocationData(stopId: string, stopStatus: string, shipmentStatus?: string) {
    try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                position => resolve(position),
                error => reject(error)
            );
        });

        const events = [];
        if (position) {
            if (stopStatus) {
                events.push({
                    stopId: stopId,
                    status: stopStatus,
                    location: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    } as LocationDetails
                });
            }
            if (shipmentStatus) {
                events.push({
                    status: shipmentStatus,
                    location: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    } as LocationDetails
                });
            }
        } else {
            if (stopStatus) {
                events.push({
                    stopId: stopId,
                    status: stopStatus,
                });
            }
            if (shipmentStatus) {
                events.push({
                    status: shipmentStatus,
                });
            }
        }

        return { events };
    } catch (error) {
        const events = [];
        if (stopStatus) {
            events.push({
                stopId: stopId,
                status: stopStatus,
            });
        }
        if (shipmentStatus) {
            events.push({
                status: shipmentStatus,
            });
        }
        return { events };
    }
}

  async updateShipment(saveModel) {
    this.isContentReady = false;
    try {
      const response = await this.shipmentsDataService
        .updateShipment(this.shipmentId, saveModel)
        .pipe(first())
        .toPromise();
      if (response) {
        this.getShipmentDetails();
        this.onShipmentChangeStatus(this.shipment);
      }
    } catch (error) {
      this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
    } finally {
      this.isContentReady = true;
    }
  }

  shouldDisplayButton(stop: any): boolean {
    const isDeliverLoadInProgress = stop.stopType === STOPS.DELIVER_LOAD && stop.status !== this.STATUS_PENDING;
    const isPickupLoadInProgress = stop.stopType === STOPS.PICK_LOAD && stop.status !== this.STATUS_PENDING;
    return isPickupLoadInProgress || isDeliverLoadInProgress;
  }
  showUploadButton(stop: any): boolean {
    const isDocumentMissing = !stop?.document;
    const isUploadAllowed = !this.disableDocumentUpload.includes(stop.stopType);
    return isDocumentMissing && isUploadAllowed;
  }
}
