import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { SignInModel } from './sign-in-model';
import { tap, catchError, mergeMap, map } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Response } from 'src/app/shared/services/response';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { SubSink } from 'subsink';
import { CodeExchangeService } from 'src/app/shared/code-exchange/code-exchange.service';

const PATH_LOGIN = '/auth/login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  apiUrl: string;
  private subs = new SubSink();
  constructor(
    private readonly api: FleetoperateApiService,
    private readonly registrationService: RegistrationService,
    private readonly logger: LoggerService,
    private readonly codeExchange: CodeExchangeService,
    @Inject('environmentData') public environmentData: any
  ) {
    this.apiUrl = environment.apiUrl;
  }

  login(model: SignInModel, recaptchaToken: string): Observable<any> {
    const codeVerifier = this.codeExchange.generateCodeVerifier();
    model.code_challenge = this.codeExchange.generateCodeChallenge(codeVerifier);

    const headerOptions = { 'x-recaptcha-token': recaptchaToken, 'X-Web-Access': 'web' };
    return this.api.post(this.apiUrl + PATH_LOGIN, model, headerOptions).pipe(
      tap((response: Response) => {
        window.location.href = response.data.location + `&codeVerifier=${codeVerifier}`;
      }),
      catchError(err => {
        this.logger.error('Caught authentication error, will logout and rethrow error.', err);
        this.registrationService.clearDriver();
        return throwError(err);
      })
    );
  }
}
