import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InformationDialogComponent } from './information-dialog/information-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { first } from 'rxjs/operators';
import { Action } from '../actions/models/action.model';

@Injectable({
  providedIn: 'root'
})
export class InformationDialogService {
  constructor(public dialog: MatDialog, private readonly dom: DomSanitizer) {}

  openInformationDialog(
    title: string,
    greeting: string,
    infoMessage: string,
    action: Action,
    contactMessage: string,
    iconName: string,
    dialogClass?: string,
    iconBgColor?: string
  ): MatDialogRef<InformationDialogComponent> {
    const dialogRef = this.dialog.open(InformationDialogComponent, {
      data: {
        title: title,
        greeting,
        infoMessage,
        action: action,
        contactMessage,
        iconName,
        dialogClass,
        iconBgColor
      },
      disableClose: true,
      panelClass: dialogClass === 'modern' ? 'wide-pane' : ''
    });
    return dialogRef;
  }
}
