import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dqf-application-employement',
  templateUrl: './dqf-application-employement.component.html',
  styleUrls: ['./dqf-application-employement.component.scss']
})
export class DqfApplicationEmployementComponent implements OnInit {
  file: any;
  constructor(private readonly location: Location) {}

  ngOnInit(): void {}
  backAction() {
    this.location.back();
  }
  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.file = file;
      // this.onSubmit();
    };
  }
}
