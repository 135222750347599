import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { CertificateType } from 'src/app/registration/shared/models/certificate-type.model';
import { Education } from 'src/app/shared/models/education.model';
import { environment } from './../../../environments/environment';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { concatMap } from 'rxjs/operators';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const ACTION_SAVE_EDUCATION = 'Save education';
const INVALID_INPUT_MESSAGE = "Please enter valid input. Special characters allowed are . - '";

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.scss']
})
export class EducationComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  errorMessage: string;
  selectedGrade = 0;

  private subs = new SubSink();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly timeService: DateTimeService,
    private readonly driverApiService: DriverApiService,
    private readonly fb: FormBuilder
  ) {
    this.loading = false;
    this.errorMessage = undefined;
    this.form = this.createForm();
  }

  ngOnInit() {
    this.subs.add(
      this.registrationService.selectEducation().subscribe((education: Education) => {
        if (education.id) {
          this.populateForm(education);
          this.selectedGrade = Number(education.highestGradeCompleted);
        }
      })
    );
    this.loadDriver();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();
    if (model.id) {
      this.subs.add(
        this.registrationService.updateEducation(model).subscribe(
          () => {
            this.errorMessage = undefined;
            this.loading = false;
            this.onNavigateToProfile();
          },
          (error: string) => {
            this.errorMessage = environment.errorMessage;
            this.loading = false;
          }
        )
      );
    } else {
      // TODO: Remove this the removal of id when API ignores it on POST.
      model.id = undefined;
      this.subs.add(
        this.registrationService
          .saveEducation(model)
          .pipe(
            catchError((error: any) => {
              this.errorMessage = environment.errorMessage;
              this.loading = false;
              return of(false);
            })
          )
          .subscribe(() => {
            this.errorMessage = undefined;
            this.loading = false;
            this.onNavigateToProfile();
          })
      );
    }
  }

  get highestGradeCompleted() {
    return this.form.get('highestGradeCompleted');
  }
  get lastSchoolAttended() {
    return this.form.get('lastSchoolAttended');
  }
  get certification() {
    return this.form.get('certification');
  }
  get specialCourses() {
    return this.form.get('specialCourses');
  }

  getHighestGradeCompletedErrorMessage(): string {
    return this.highestGradeCompleted.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getLastSchoolAttendedErrorMessage(): string {
    const errors = this.lastSchoolAttended.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : errors.pattern ? INVALID_INPUT_MESSAGE : '';
  }

  getCertificationErrorMessage(): string {
    const errors = this.certification.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : errors.pattern ? INVALID_INPUT_MESSAGE : '';
  }

  getSpecialCoursesErrorMessage(): string {
    const errors = this.specialCourses.errors;
    return errors.required ? REQUIRED_FIELD_MESSAGE : errors.pattern ? INVALID_INPUT_MESSAGE : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      highestGradeCompleted: ['', Validators.required],
      lastSchoolAttended: ['', [Validators.required, Validators.pattern("^[A-Za-z.]+([-'\\s][.A-Za-z]+)*$")]],
      certification: ['', [Validators.required, Validators.pattern("^[A-Za-z.]+([-'\\s][.A-Za-z]+)*$")]],
      specialCourses: ['', Validators.pattern("^[A-Za-z.]+([-'\\s][.A-Za-z]+)*$")],
      id: ['']
    });

    return form;
  }

  private prepareSaveModel(): Education {
    const formModel = this.form.value;
    const model = {
      highestGradeCompleted: formModel.highestGradeCompleted as string,
      lastSchoolAttended: formModel.lastSchoolAttended as string,
      certification: formModel.certification as string,
      specialCourses: formModel.specialCourses ? (formModel.specialCourses as string) : undefined,
      id: formModel.id as string
    };

    return model as Education;
  }

  private populateForm(model: Education): void {
    this.form.setValue({
      highestGradeCompleted: model.highestGradeCompleted,
      lastSchoolAttended: model.lastSchoolAttended,
      certification: model.certification,
      specialCourses: model.specialCourses ? model.specialCourses : '',
      id: model.id
    });
  }

  private loadDriver(): void {
    this.subs.add(this.registrationService.loadDriver().subscribe());
  }
}
