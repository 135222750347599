<div class="header-navigation-back">
  <mat-icon (click)="onNavigateBack()">arrow_back</mat-icon>
  <span fxFlex class="title">Trip Details</span>
  <button fxFlexAlign="end" *ngIf="!shipmentInactive" mat-icon-button class="margin-xs" (click)="onOpenActionSheet()">
    <mat-icon aria-hidden="false" aria-label="Shipment actions">more_horiz</mat-icon>
  </button>
</div>
<app-status-banner *ngIf="status" [status]="status" class="padding-bottom-xlg">
  <ng-container *ngIf="status.messageType === 'cancelledReason'">
    <p>
      {{ cancelledStatus }} <br />
      <span class="cancelledLoad"><b>Reason: </b>{{ cancelledReason }}</span>
    </p>
  </ng-container>
</app-status-banner>
<div fxFlexAlign="center" class="mat-error margin-sm">
  {{ errorMessage }}
</div>

<app-loading-gif *ngIf="loading"></app-loading-gif>
<app-shipment-item-details
  *ngIf="shipment && !loading"
  [shipment]="shipment"
  [driverId]="driverId"
  class="padding-top-sm"
>
</app-shipment-item-details>
<app-actions [actions]="actions" class="margin-top-lg" [ngClass]="showNavBar ? 'fixed-bottom' : ''"></app-actions>
