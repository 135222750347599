<div fxLayout="column" class="profile-detail-container">
  <div fxFlex="60" fxLayout>
    <div fxFlex="75">
      <ngx-skeleton-loader
        appearance="circle"
        [theme]="{
          width: '60px',
          height: '60px'
        }"
      >
      </ngx-skeleton-loader>
    </div>
    <div fxFlex="25" class="edit-button-container">
      <ngx-skeleton-loader appearance="line"></ngx-skeleton-loader>
    </div>
  </div>
  <div fxFlex="40" fxLayout>
    <div fxFlex="75" fxLayout="column">
      <div fxFlex="100" class="driver-name">
        <ngx-skeleton-loader
          appearance="line"
          [theme]="{
            width: '30%',
            height: '10px'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div fxFlex="100" class="driver-email">
        <ngx-skeleton-loader appearance="line" [theme]="{ width: '70%', height: '10px' }"></ngx-skeleton-loader>
      </div>
    </div>
    <div fxFlex="25" fxLayout class="driver-id-container">
      <div fxFlex="100" class="driver-id">
        <ngx-skeleton-loader appearance="line" [theme]="{ height: '20px' }"></ngx-skeleton-loader>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<div class="profile-completion" fxLayout="row">
  <div fxFlex="70">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        width: '60%',
        height: '10px'
      }"
    ></ngx-skeleton-loader>
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        width: '90%',
        height: '14px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div fxFlex="30" class="completion-circle">
    <ngx-skeleton-loader
      appearance="circle"
      [theme]="{
        width: '55px',
        height: '55px'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
