import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlurElementDirective } from './blur-element.directive';



@NgModule({
  declarations: [BlurElementDirective],
  imports: [
    CommonModule
  ],
  exports: [BlurElementDirective]
})
export class CustomDirectivesModule { }
