<div fxLayoutAlign="start" fxLayout="column" class="dqf-review page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">DQF for {{ carrierCompanyName }}</div>
  </div>
  <div *ngIf="!isContentReady">
    <app-skeleton-dqf-review></app-skeleton-dqf-review>
  </div>
  <div *ngIf="isContentReady && isVersionsLoaded">
    <div fxFlex fxLayout="column" class="review">
      <div class="review-heading">
        <div fxFlex="100" fxLayout="row" class="review-header">
          <div fxFlex="20">
            <button class="font-family" (click)="backAction()" *ngIf="!hideActionButtons" data-test="edit-button">Edit</button>
          </div>
          <div fxFlex="60" class="profile">
            <img [src]="profilePicture" />
          </div>

          <div fxFlex="20" class="sign-in">
            <button class="font-family" (click)="navigateToSign()" *ngIf="!hideActionButtons" data-test="signin-button">Sign</button>
          </div>
        </div>
      </div>
      <div class="review-info" fxFlex fxLayout="column">
        <ng-container *ngFor="let info of profileInfo">
          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header
                [collapsedHeight]="customCollapsedHeight"
                [expandedHeight]="customCollapsedHeight"
                class="expansion-header"
                class="font-family"
              >
                <mat-panel-title>
                  {{ info.heading }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="info-content">
                <app-dqf-driver-personal-information *ngIf="info.value === 1" [driverVersionDetails]="driverVersionDetails"> </app-dqf-driver-personal-information>
                <app-dqf-driver-contact-information *ngIf="info.value === 2" [driverVersionDetails]="driverVersionDetails"> </app-dqf-driver-contact-information>
                <app-dqf-driver-license-information *ngIf="info.value === 3" [version]="profileInfoVersions[info.heading]"> </app-dqf-driver-license-information>
                <app-dqf-driver-residence-history *ngIf="info.value === 4" [version]="profileInfoVersions[info.heading]"> </app-dqf-driver-residence-history>
                <app-dqf-driver-driving-experience *ngIf="info.value === 5" [version]="profileInfoVersions[info.heading]"> </app-dqf-driver-driving-experience>
                <app-dqf-driver-employment-history *ngIf="info.value === 6" [version]="profileInfoVersions[info.heading]"> </app-dqf-driver-employment-history>
                <app-dqf-driver-accident-records *ngIf="info.value === 7" [version]="profileInfoVersions[info.heading]"> </app-dqf-driver-accident-records>
                <app-dqf-driver-traffic-convictions *ngIf="info.value === 8" [version]="profileInfoVersions[info.heading]"> </app-dqf-driver-traffic-convictions>
                <app-view-uploaded-document [dqfDocumentId]="abstractDocumentId" *ngIf="info.value === 9">
                </app-view-uploaded-document>
                <app-view-uploaded-document [dqfDocumentId]="policeClearanceId" *ngIf="info.value === 10">
                </app-view-uploaded-document>
                <app-view-uploaded-document [dqfDocumentId]="preEmploymentId" *ngIf="info.value === 11">
                </app-view-uploaded-document>
                <app-view-uploaded-document [dqfDocumentId]="ontarioCvorAbstractId" *ngIf="info.value === 12">
                </app-view-uploaded-document>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
    </div>
  </div>
</div>
