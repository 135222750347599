import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { MaterialModule } from '../shared/material/material.module';
import { ActionsModule } from '../shared/actions/actions.module';
import { StatusBannerModule } from '../shared/status-banner/status-banner.module';
import { CodeExchangeModule } from '../shared/code-exchange/code-exchange.module';
import { MatIconModule } from '@angular/material/icon';
import { LicenseOutletComponent } from './license-outlet/license-outlet.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CypressLicenseScanComponent } from './cypress-license-scan/cypress-license-scan.component';

@NgModule({
  declarations: [LicenseOutletComponent, CypressLicenseScanComponent],
  imports: [
    CommonModule,
    RouterModule,
    WebcamModule,
    MaterialModule,
    ActionsModule,
    StatusBannerModule,
    MatIconModule,
    CodeExchangeModule,
    ImageCropperModule
  ]
})
export class LicenseScanModule {}
export { LicenseOutletComponent } from './license-outlet/license-outlet.component';
