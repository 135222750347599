import { ROUTE_SIGNIN } from './../shared/routes';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_SIGNUP } from '../shared/routes';

const ROUTE_PROFILE = 'profile';
const DefaultErrorMessage = 'Something went wrong. Please contact us for help at';
@Component({
  selector: 'app-sign-up-error',
  templateUrl: './sign-up-error.component.html',
  styleUrls: ['./sign-up-error.component.scss']
})
export class SignUpErrorComponent implements OnInit {
  errorMessage: string;
  isBerbixConflict: boolean = false;
  title: string = 'Oops...';
  btnDescription: string = '';
  constructor(private readonly router: Router) {
    this.errorMessage = DefaultErrorMessage;
  }

  ngOnInit() {
    if (history.state.errorMessage) this.errorMessage = history.state.errorMessage;
    if (history.state?.isBerbixConflict) this.isBerbixConflict = history.state?.isBerbixConflict;
    if (this.isBerbixConflict) {
      this.title = 'License information has been saved';
      this.errorMessage = `Please click on 'OK' to access your profile.`;
      this.btnDescription = 'We have all the required information to get you going';
    }
  }

  onNavigateToSignIn(): void {
    let route = ROUTE_SIGNIN;
    if (this.isBerbixConflict) {
      route = ROUTE_PROFILE;
    }
    this.router.navigate([`/${route}`]);
  }
}
