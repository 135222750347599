import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Response } from 'src/app/shared/services/response';
import {
  ShipmentApiService,
  FILTER_SHIPMENTS_STATUS_UNSECURED,
  EVENT_TYPE_ACCEPTED,
  EVENT_TYPE_COUNTERED,
  EVENT_TYPE_PASSED,
  EVENT_TYPE_CONFIRMED,
  EVENT_TYPE_CANCELLED,
  FILTER_SHIPMENTS_STATUS_INACTIVE,
  EVENT_TYPE_CLEAR
} from './shipment-api.service';
import { ShipmentDataModule } from './shipment-data.module';
import { Shipment } from './models/shipment';
import { Quote } from './models/quote';
import { Bid } from './models/bid';
import { Location } from './models/location';
import { Lookup } from './models/lookup';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ShipmentFeedback } from './models/shipment-feedback.model';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Injectable({
  providedIn: ShipmentDataModule
})
export class ShipmentDataService {
  getShipment: any;

  constructor(
    private readonly shipmentApiService: ShipmentApiService,
    private readonly registrationService: RegistrationService,
    private readonly toastService: ToastService
  ) {}

  fetchAllShipments(): Observable<Shipment[]> {
    const allLoadsStatus = [FILTER_SHIPMENTS_STATUS_UNSECURED, FILTER_SHIPMENTS_STATUS_INACTIVE];
    return this.shipmentApiService
      .getShipments(allLoadsStatus)
      .pipe(map((response: Response) => response.data as Shipment[]));
  }

  fetchMyShipments(driverId: string): Observable<Shipment[]> {
    const isCarrierDriver = this.registrationService.getDriver()?.companyAssociatedId ? true : false;
    return this.shipmentApiService
      .getShipments(undefined, driverId, isCarrierDriver)
      .pipe(map((response: Response) => response.data as Shipment[]));
  }

  fetchShipment(shipmentId: string): Observable<Shipment> {
    return this.shipmentApiService.getShipment(shipmentId).pipe(map((response: Response) => response.data as Shipment));
  }

  updateShipmentAsPickedUp(shipmentId: string): Observable<Shipment> {
    return this.shipmentApiService
      .updateShipmentAsPickedUp(shipmentId)
      .pipe(map((response: Response) => response.data as Shipment));
  }

  updateShipmentAsUndoPickedUp(shipmentId: string): Observable<Shipment> {
    return this.shipmentApiService
      .updateShipmentAsUndoPickedUp(shipmentId)
      .pipe(map((response: Response) => response.data as Shipment));
  }

  updateShipmentAsDelivered(shipmentId: string): Observable<Shipment> {
    return this.shipmentApiService
      .updateShipmentAsDelivered(shipmentId)
      .pipe(map((response: Response) => response.data as Shipment));
  }

  updateShipmentLocation(shipmentId: string, location: Location): Observable<Shipment> {
    return this.shipmentApiService
      .updateShipmentLocation(shipmentId, location)
      .pipe(map((response: Response) => response.data as Shipment));
  }

  acceptShipment(shipmentID: string, bidOffer: number): Observable<Quote> {
    const quote = {
      shipmentID,
      bids: [
        {
          offer: bidOffer,
          event: EVENT_TYPE_ACCEPTED
        }
      ]
    } as Quote;

    return this.shipmentApiService.createQuote(quote).pipe(map((response: any) => response.data as Quote));
  }

  counterShipment(shipmentID: string, bidOffer: number): Observable<Quote> {
    const quote = {
      shipmentID,
      bids: [
        {
          offer: bidOffer,
          event: EVENT_TYPE_COUNTERED
        }
      ]
    } as Quote;

    return this.shipmentApiService.createQuote(quote).pipe(map((response: any) => response.data as Quote));
  }

  passShipment(shipmentID: string): Observable<Quote> {
    const quote = {
      shipmentID,
      bids: [
        {
          event: EVENT_TYPE_PASSED
        }
      ]
    } as Quote;

    return this.shipmentApiService.createQuote(quote).pipe(map((response: any) => response.data as Quote));
  }

  confirmShipment(shipmentID: string, bidOffer: number): Observable<Quote> {
    const quote = {
      shipmentID,
      bids: [
        {
          offer: bidOffer,
          event: EVENT_TYPE_CONFIRMED
        }
      ]
    } as Quote;

    return this.shipmentApiService.createQuote(quote).pipe(map((response: any) => response.data as Quote));
  }

  cancelShipment(shipmentID: string): Observable<Quote> {
    const quote = {
      shipmentID,
      bids: [
        {
          event: EVENT_TYPE_CANCELLED
        }
      ]
    } as Quote;

    return this.shipmentApiService.createQuote(quote).pipe(map((response: any) => response.data as Quote));
  }

  acceptBid(quoteId: string, bidOffer: number): Observable<Bid> {
    const bid = {
      offer: bidOffer,
      event: EVENT_TYPE_ACCEPTED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  clearBid(quoteId: string): Observable<Bid> {
    const bid = {
      event: EVENT_TYPE_CLEAR
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  counterBid(quoteId: string, bidOffer: number): Observable<Bid> {
    const bid = {
      offer: bidOffer,
      event: EVENT_TYPE_COUNTERED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  passBid(quoteId: string): Observable<Bid> {
    const bid = {
      event: EVENT_TYPE_PASSED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  confirmBid(quoteId: string, bidOffer: number): Observable<Bid> {
    const bid = {
      offer: bidOffer,
      event: EVENT_TYPE_CONFIRMED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  canceledBid(quoteId: string): Observable<Bid> {
    const bid = {
      event: EVENT_TYPE_CANCELLED
    } as Bid;

    return this.shipmentApiService.createBid(quoteId, bid).pipe(map((response: any) => response.data as Bid));
  }

  getProvinceLookups(): Observable<Lookup[]> {
    return this.shipmentApiService
      .getProvinceLookups()
      .pipe(map((response: any) => response.data.firstAdministrativeLevelSupported as Lookup[]));
  }

  getProvinceShipments(stateCode: string): Observable<Shipment[]> {
    return this.shipmentApiService
      .getProvinceShipments(stateCode)
      .pipe(map((response: Response) => response.data as Shipment[]));
  }

  getShipmentsMock(page: number, perPage: number, companyId?: string): Observable<any> {
    return this.shipmentApiService.getShipmentsMock(page, perPage);
  }

  getShipmentByIdMock(id: string) {
    return this.getShipmentByIdMock(id);
  }

  sendFeedback(feedback: ShipmentFeedback): Observable<any> {
    return this.shipmentApiService.sendFeedback(feedback).pipe(
      map((response: any) => response?.data),
      catchError((error) => {
        this.toastService.showError('There was an error submitting your feedback. Please try again.');
        throw error;
      })
    );
  }
}
