<div class="full-width certn-info" data-test="fix-now-banner">
  <div class="content" fxLayout="column">
    <div fxFlex="70" class="requesting-permission">
      <div>
        <img class="badge" src="../../../assets/images/clearnce_badge.png" />
      </div>
      <div>
        <img class="complete-profile" src="../../../assets/images/complete-profile.png" />
      </div>
    </div>
    <div class="document-info" fxFlex="15" data-test="requested-document-name">
      <p>
        We need additional information before we process your
        <span class="document-name" data-test="document-type">{{ documentType }}</span>
      </p>
    </div>
    <div fxFlex="15" class="button-info">
      <button
        mat-raised-button
        type="button"
        class="proceed-button full-width"
        (click)="proceed()"
        data-test="proceed-button"
      >
        Proceed
      </button>
    </div>
  </div>
</div>
