import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { CANADA_PROVINCES, USA_PROVINCES } from 'src/app/shared/models/provinces';
import { PENALTY } from 'src/app/shared/models/traffic-penalty';
import { VIOLATION_TYPES } from 'src/app/shared/models/traffic-violation-types';

@Component({
  selector: 'app-dqf-driver-traffic-convictions',
  templateUrl: './dqf-driver-traffic-convictions.component.html',
  styleUrls: ['./dqf-driver-traffic-convictions.component.scss']
})
export class DqfDriverTrafficConvictionsComponent implements OnInit {
  @Input() version: number;
  data = [];
  state = CANADA_PROVINCES.concat(USA_PROVINCES);
  constructor(private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.getDriverLicenseInfo();
  }

  private getDriverLicenseInfo(): void {
    this.registrationService.getTrafficConvictions(this.version).subscribe((response: any[]) => {
      if (response?.length) {
        const data = response.sort((a, b) => new Date(b.convictionDate).getTime() - new Date(a.convictionDate).getTime());
        if (data.length === 1 && data[0].hasOwnProperty('noConvictions') && data[0].noConvictions) {
          this.data = [
            { propertyName: 'No Convictions', propertyValue: 'Yes' }
          ]
        }
        else {
          this.bindData(data);
        }
      }
    })
  }

  private getViolationTypes(violationType: string): string {
    const filteredValue = VIOLATION_TYPES.filter((e) => e.value == violationType)
    if (filteredValue.length) {
      return filteredValue[0].name
    }
    return null;
  }

  private getPenaltyTypes(penaltyType: string): string {
    const filteredValue = PENALTY.filter((e) => e.value == penaltyType)
    if (filteredValue.length) {
      return filteredValue[0].name
    }
    return null;
  }

  private getState(state: string): string {
    const filteredValue = this.state.filter((e) => e.value.toLowerCase() == state.toLowerCase())
    if (filteredValue.length) {
      return filteredValue[0].name
    }
    return null;
  }

  private bindData(data: any[]): void {
    data.forEach((e) => {
      if (e.hasOwnProperty('convictionDate')) {
        const trafficConvictions =
        {
          propertyName: 'Violation Type ', propertyValue: `${this.getViolationTypes(e.violationType)}`,
          convictionDate: `Date:  ${dayjs(e.convictionDate).format('DD MMM YYYY') as string}`,
          stateOfViolation: `State of Violation Location:  ${this.getState(e.stateOfViolation)}`,
          penalty: `Penalty: ${this.getPenaltyTypes(e.penalty)}`,
          demeritPoints: `Demerit points: ${e.demeritPoints}`
        }
        this.data.push(trafficConvictions)
      }
    })
  }
}
