<div class="main-container" fxLayout="column" fxFlexAlign="center center" [ngClass]="data.dialogClass">
  <div class="icon-bg" [ngClass]="data.iconBgColor" *ngIf="data.dialogClass === 'modern' && data.iconName">
    <mat-icon [svgIcon]="data.iconName"></mat-icon>
  </div>
  <h2 fxFlex class="title" *ngIf="data.title">{{ data.title }}</h2>
  <mat-icon *ngIf="data.dialogClass !== 'modern' && data.iconName" [svgIcon]="data.iconName"></mat-icon>
  <div>
    <p>
      {{ data.greeting }}
      <br *ngIf="data.infoMessage" />
      {{ data.infoMessage }}
      <ng-container *ngIf="data.contactMessage">
        <br />
        <br />
      </ng-container>
      <span [innerHtml]="data.contactMessage"></span>
    </p>
  </div>
  <button (click)="onActionBtn()" class="close-button" aria-label="Close alert" data-close data-test="close-button">
    {{ data.action.name | titlecase }}
  </button>
</div>
