import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-dqf-list',
  templateUrl: './skeleton-dqf-list.component.html',
  styleUrls: ['../dqf-list.component.scss', './skeleton-dqf-list.component.scss']
})
export class SkeletonDqfListComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
