<div fxLayoutAlign="start" fxLayout="column" class="page full-height gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon data-test="back-arrow-icon" (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">{{ shipment?.shipmentID || '' }}</span>
  </div>
  <div fxLayout="column" class="full-height">
    <div class="search-container">
      <form class="summary-filters">
        <mat-form-field>
          <mat-icon matPrefix svgIcon="magnifying-glass" data-test="search"></mat-icon>
          <span matPrefix *ngIf="!searchInput.value">Search&nbsp;</span>
          <input
            matInput
            (input)="onSearchInput(searchInput.value)"
            #searchInput
            [value]="currentSearchInput"
            [disabled]="!isContentReady"
            data-test="search-field"
          />
        </mat-form-field>
      </form>
      <button mat-stroked-button data-test="filter-button" (click)="openRoleFilterSheet()" [disabled]="!isContentReady">
        <mat-icon svgIcon="filter" data-test="filter"></mat-icon>
      </button>
    </div>
    <div
      class="document-tiles-container document-list-container"
      fxFlex="100"
      fxLayout="column"
      fxLayoutGap="20px"
      *ngIf="isContentReady"
    >
      <div fxFlex fxLayout="row wrap" class="document-item-row" *ngIf="filteredShipmentDocumentTypes?.length">
        <div fxFlex="50" *ngFor="let type of filteredShipmentDocumentTypes" class="document-info">
          <app-document-tile-box [shipment]="shipment" [document]="type" class="document-item"></app-document-tile-box>
        </div>
      </div>
      <div fxFlex fxLayout="row wrap" class="no-document" *ngIf="!filteredShipmentDocumentTypes?.length">
        No Documents Found
      </div>
    </div>
  </div>
</div>
