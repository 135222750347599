import { HttpEvent, HttpHandler, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class XsrfInterceptorService {
  constructor(private router: Router, private tokenService: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    const headerName = environment.xsrfHeadername;
    //const lcUrl = req.url.toLowerCase();
    // DO NOT Skip both non-mutating requests and absolute URLs. (Override angular 5 default behaviour)
    const token = this.tokenService.getToken();
    // Be careful not to overwrite an existing header of the same name.
    if (
      token !== null &&
      (req.method === 'GET' || req.method === 'HEAD') &&
      req.url.includes(environment.apiUrl) &&
      !req.headers.has(environment.xsrfCookieName)
    ) {
      req = req.clone({ headers: req.headers.set(headerName, token) });
    }
    return next.handle(req);
  }
}
