import { Component, OnInit, Input } from '@angular/core';
import { Status } from '../models/status.model';

@Component({
  selector: 'app-status-banner',
  templateUrl: './status-banner.component.html',
  styleUrls: ['./status-banner.component.scss']
})
export class StatusBannerComponent implements OnInit {
  @Input() status: Status;
  constructor() {}

  ngOnInit() {}
}
