import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Response } from 'src/app/shared/services/response';
import { FleetoperateApiService } from '../fleetoperate-api.service';
const PATH = '/{user}'

@Injectable({
  providedIn: 'root'
})
export class CommonApiService {
  constructor(
    private readonly api: FleetoperateApiService,
    @Inject('apiUrl') private apiUrl: string,
    private readonly httpClient: HttpClient
  ) { }

  getUserById(userId: string, uri: string): Observable<Response> {
    const path = `${PATH.replace('{user}', uri)}/${userId}`;
    return this.api.get(`${this.apiUrl}${path}?include=company`, {});
  }

  // making an http call to get app config and return promise
  getConfig(): Promise<object> {
    const timeNow = new Date().getTime();
    return fetch(`/assets/app.config.json?time:${timeNow}`, {
      cache: 'no-cache',
      method: "GET",
      headers: {
        "Accept": "application/json",
        "Cache-Control": "no-cache"
      }
    })
  }
}
