// SHIPMENTS STATUS

export const STATUS_DRAFT = 'draft';
export const STATUS_UNSECURED = 'unsecured';
export const STATUS_CONFIRMATION_PENDING = 'confirmation pending';
export const STATUS_ASSIGNED = 'assigned';
export const STATUS_BOOKED = 'booked';
export const STATUS_DRIVING_TO_PICKUP = 'driving to pickup';
export const STATUS_PICKUP_IN_PROGRESS = 'pickup in progress';
export const STATUS_OUT_FOR_DELIVERY = 'out for delivery';
export const STATUS_UNLOADING = 'unloading';
export const STATUS_DELIVERED = 'delivered';
export const STATUS_INACTIVE = 'inactive';
export const STATUS_TONU = 'tonu';
export const STATUS_COMPLETED = 'completed';
export const STATUS_INVOICE_SENT = 'invoice sent';
export const STATUS_PAYMENT_OVERDUE = 'payment overdue';
export const STATUS_PAYMENT_RECEIVED = 'payment received';
export const STATUS_CANCELLED = 'cancelled';
export const STATUS_DELETED = 'deleted';

// DEFAULT ERROR MESSAGE
export const DEFAULT_ERROR_MESSAGE = 'Something Went Wrong.. please try again later';

// LOAD SIZES
export const LOAD_SIZE_FTL = 'FTL';
export const LOAD_SIZE_LTL = 'LTL';

export const DRIVER_UPLOAD_DOCUMENT_TYPES = [
  {
    name: 'PapsLabel',
    displayName: 'PAPS Label'
  },
  {
    name: 'ParsLabel',
    displayName: 'PARS Label'
  },
  {
    displayName: 'Bill of Lading',
    name: 'BillOfLading'
  },
  {
    name: 'Fuel',
    displayName: 'Fuel'
  },
  {
    name: 'Toll',
    displayName: 'Toll'
  },
  {
    name: 'Repair',
    displayName: 'Repair'
  },
  {
    name: 'Food',
    displayName: 'Food'
  },
  {
    name: 'Stay',
    displayName: 'Stay'
  },
  {
    name: 'Other',
    displayName: 'Other'
  },
  {
    name: 'ProofOfDelivery',
    displayName: 'Proof Of Delivery'
  }
];
export const DRIVER_READ_ONLY_DOC_TYPES = ['ACIeManifest', 'ACEeManifest'];
export const DRIVER_NO_ACTION_DOC_TYPES = ['RateConfirmation'];

const PAPS_LABEL_TYPE = 'PapsLabel';
const PARS_LABEL_TYPE = 'ParsLabel';
const POD_LABEL_TYPE = 'ProofOfDelivery';

export const SINGLE_DOCUMENT_TYPES = [PARS_LABEL_TYPE, PAPS_LABEL_TYPE, POD_LABEL_TYPE];
export const STOP_TYPES = {
  PICKUP_TRUCK: 'pickupTruck',
  HOOK_TRAILER: 'hookTrailer',
  PICKUP_LOAD: 'pickupLoad',
  DELIVERY_LOAD: 'deliverLoad',
  DROP_TRAILER: 'dropTrailer',
  DROP_TRUCK: 'dropTruck',
  BORDER_CROSS: 'borderCross',
  FUEL: 'fuel'
};

export const STOP_DISPLAY_NAMES = new Map([
  ['pickTruck', 'Pick Truck'],
  ['hookTrailer', 'Hook Trailer'],
  ['pickupLoad', 'Pickup Load'],
  ['deliverLoad', 'Deliver Load'],
  ['dropTrailer', 'Drop Trailer'],
  ['dropTruck', 'Drop Truck'],
  ['borderCross', 'Border Cross'],
  ['fuel', 'Fuel']
]);

export const STOPS = {
  PICK_TRUCK: 'pickTruck',
  PICK_LOAD: 'pickupLoad',
  HOOK_TRAIKER: 'hookTrailer',
  DELIVER_LOAD: 'deliverLoad',
  DROP_TRAILER: 'dropTrailer',
  DROP_TRUCK: 'dropTruck',
  BORDER_CROSS: 'borderCross',
  FUEL: 'fuel'
}