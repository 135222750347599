<div fxLayout="column" fxLayoutAlign="center stretch" class="loginPageBackground">
  <div fxFlex fxLayout="column" class="padding-left-md padding-right-md">
    <img fxFlexAlign="center" class="logo padding-top-lg" src="/assets/img/fleetoperate_logo.jpg" width="300px" />
    <div fxLayout="column" fxFlex="70" fxFlexAlign="center" class="padding-left-xlg padding-right-xlg message">
      <span class="centerBoldText" [ngClass]="inviteType ? 'font-white' : 'font-gray'"
        ><b>{{ title }}</b></span
      >

      <img fxFlexAlign="center" src="/assets/images/successful-sign-up.png" class="centerImage" />
    </div>
    <div class="padding-left-xlg padding-right-xlg message">
      <span data-test="signup-success" class="bottomText" *ngIf="!inviteType"
        >Please check your email for the activation link.</span
      >
    </div>
    <div fxLayout fxLayoutAlign="center end" class="padding-top-lg padding-bottom-xxlg" fxFlex>
      <button
        fxFlex.gt-sm="20%"
        fxFlex.lt-md="100%"
        class="margin-bottom margin-top-lg"
        mat-raised-button
        color="primary"
        type="submit"
        (click)="onSignUpSuccess()"
      >
        OK
      </button>
    </div>
  </div>
</div>
