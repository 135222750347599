import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompletionMeterComponent } from './completion-meter/completion-meter.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [CompletionMeterComponent],
  imports: [CommonModule, FlexLayoutModule, MatIconModule],
  exports: [CompletionMeterComponent]
})
export class CompletionMeterModule {}
