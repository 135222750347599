import { Component, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { RegistrationService } from '../shared/registration.service';
import { Store } from '@ngxs/store';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { Notification } from 'src/app/shared/models/notification.model';
import { catchError, map, tap, finalize } from 'rxjs/operators';
import { MEDICAL_CERTIFICATE, UPLOAD_STATUS } from 'src/app/shared/services/driver-api.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const VALID_CHARACTER_MESSAGE = 'Please enter valid input. Special characters allowed are , -';
const ERROR_MESSAGE = 'There was an error, please contact us for help';
const SUCCESS_MESSAGE = 'Your medical examination report was successfully uploaded';
const INCORRECT_TYPE_MESSAGE = 'The file must be a pdf, jpeg, or jpg';
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss[Z]';

@Component({
  selector: 'app-medical-examination',
  templateUrl: './medical-examination.component.html',
  styleUrls: ['./medical-examination.component.scss']
})
export class MedicalExaminationComponent implements OnInit {
  html: any;
  supportEmail = 'support@FleetOperate.com';
  driverSupportEmail = 'driversupport@fleetoperate.com';
  private subs = new SubSink();

  loading: boolean;
  file: any;
  errorMessage: string;
  successMessage: string;
  form: FormGroup;
  presentDate: Date = new Date();
  uploaded: boolean;
  createdDate: string;
  attempted: boolean;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private readonly fb: FormBuilder,
    private readonly dateTimeService: DateTimeService
  ) {
    this.loading = true;
    this.errorMessage = undefined;
    this.form = this.createForm();
    this.attempted = false;
  }

  ngOnInit() {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
        .subscribe((notificationStateModel: NotificationStateModel) => {
          if (notificationStateModel && notificationStateModel.notifications) {
            this.medicalExaminationStatus(
              this.profileService.findNotification(
                [...notificationStateModel.notifications],
                MEDICAL_CERTIFICATE,
                UPLOAD_STATUS
              )
            );
          }
        })
    );
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  onSubmit(): void {
    this.loading = true;
    const model = this.prepareSaveModel();

    if (model === undefined) {
      return;
    }

    this.registrationService
      .saveDocument(model, this.file)
      .pipe(
        tap(() => {
          this.setSuccessMessage(SUCCESS_MESSAGE);
          this.setErrorMessage(undefined);
        }),
        catchError((error: string) => {
          this.setErrorMessage(ERROR_MESSAGE);
          this.setSuccessMessage(undefined);
          throw error;
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(() => {
        this.attempted = true;
      });
  }

  setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.file = file;
      this.onSubmit();
    };
  }

  get reportSource() {
    return this.form.get('reportSource');
  }
  get generationDate() {
    return this.form.get('generationDate');
  }
  get validToDate() {
    return this.form.get('validToDate');
  }

  getReportSourceErrorMessage() {
    return this.reportSource.errors.required
      ? REQUIRED_FIELD_MESSAGE
      : this.reportSource.errors.pattern
      ? VALID_CHARACTER_MESSAGE
      : '';
  }

  getGenerationDateErrorMessage() {
    return this.generationDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  getValidToDateErrorMessage() {
    return this.validToDate.errors.required ? REQUIRED_FIELD_MESSAGE : '';
  }

  private medicalExaminationStatus(medicalExamination: Notification): void {
    if (medicalExamination && medicalExamination.createdDate) {
      this.createdDate = this.datePipe.transform(medicalExamination.createdDate, 'dd/MM/yyyy') as string;
      this.uploaded = true;
    } else {
      this.uploaded = false;
    }
    this.loading = false;
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      reportSource: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+([-,\\s][a-zA-Z0-9]+)*\\s*$')]],
      generationDate: ['', Validators.required],
      validToDate: ['', Validators.required]
    });
    return form;
  }

  private prepareSaveModel(): DocumentRequest {
    const extension = this.file.name
      .split('.')
      .pop()
      .toLowerCase();
    const formModel = this.form.value;

    if (['pdf', 'jpeg', 'jpg'].find(element => element == extension) == undefined) {
      this.setErrorMessage(INCORRECT_TYPE_MESSAGE);
      this.setSuccessMessage(undefined);
      return undefined;
    }

    const model = {
      source: formModel.reportSource.trim(),
      extension: extension,
      generationDate: this.dateTimeService.formatDateTime(formModel.generationDate, ISO_8601),
      validToDate: this.dateTimeService.formatDateTime(formModel.validToDate, ISO_8601),
      reportType: 'MedicalCard'
    };

    return model as DocumentRequest;
  }
}
