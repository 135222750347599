import { Component, OnInit } from '@angular/core';
import { RegistrationState, NotificationStateModel } from 'src/app/registration/state/registration.state';
import { map } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Store } from '@ngxs/store';
import { SubSink } from 'subsink';
import { Notification } from 'src/app/shared/models/notification.model';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  public notifications: Notification[];

  private subs = new SubSink();

  constructor(private readonly registrationService: RegistrationService,
              private readonly store: Store) { }

  ngOnInit() {
    this.subs.add(this.registrationService.loadDriver().pipe(
      map(() => this.store.selectSnapshot(RegistrationState.notification))
    ).subscribe((notificationStateModel: NotificationStateModel) => {
      this.notifications = [...notificationStateModel.notifications];
    }));
  }
}
