import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ROUTE_IDENTITY_LAUNCH } from 'src/app/authentication/shared/routes';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { Status } from 'src/app/shared/status-banner/models/status.model';
import { IdentityActionSheetComponent } from '../identity-action-sheet/identity-action-sheet.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DomSanitizer } from '@angular/platform-browser';
import { ROUTE_LICENSE_SCAN, ROUTE_LICENSE_SCAN_UPLOAD } from 'src/app/new-license-scan/license-routes';
import { IdentityLicenseUploadActionSheetComponent } from '../identity-license-upload-action-sheet/identity-license-upload-action-sheet.component';
@Component({
  selector: 'app-identity-start',
  templateUrl: './identity-start.component.html',
  styleUrls: ['./identity-start.component.scss']
})
export class IdentityStartComponent implements OnInit {
  actions: Action[];
  mobile: boolean;
  status: Status;
  title: string;
  html: any;
  footer: string;
  qrImg: any;
  iOSAppQR: any;
  appStoreIcon: any;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router,
    private deviceService: DeviceDetectorService,
    private domSanitizer: DomSanitizer,
    @Inject('environmentData') public environment: any
  ) {
    this.actions = [];
    this.qrImg = this.environment.webQrCode;
    this.iOSAppQR = this.environment.iOSQrCode;
    this.appStoreIcon = this.environment.appStoreIcon;
  }

  ngOnInit() {
    if (this.deviceService.isTablet() || this.deviceService.isMobile()) {
      // checks if the device is a mobile device or tablet
      this.mobile = true;
      this.title = 'Please have your Drivers License handy';
      this.html = 'Your Drivers License is required to complete your Identity Check.';
    } else {
      this.mobile = false;
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p>Please switch to a mobile device and login to your profile to proceed with the identity check process.</p><p>Thank you!</p>`
      );
    }

    if (this.mobile) {
      this.actions.push(this.createInitiateIdentityCheck());
    } else {
      this.actions.push(this.createNavigateToMobile());
      this.status = this.getSwitchToMobileMessage();
    }
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  onOpenActionSheet(): void {
    this.bottomSheet.open(IdentityActionSheetComponent);
  }

  private createInitiateIdentityCheck(): Action {
    let navigation;
    if (this.environment?.featureFlags?.enableLicenseScan) {
      navigation = () => this.bottomSheet.open(IdentityLicenseUploadActionSheetComponent);
    } else {
      navigation = () => this.navigateToBerbixLaunch();
    }
    return {
      name: `Proceed`,
      action: (data: any) => navigation(),
      disabled: false,
      color: 'secondary_blue'
    } as Action;
  }
  private navigateToLicenseScan(): void {
    this.router.navigateByUrl(`${ROUTE_LICENSE_SCAN}`);
  }

  private createNavigateToMobile(): Action { 
    return {
      name: `LOG OUT`,
      action: (data: any) => this.logout(),
      disabled: false,
      color: 'switch_to_mobile'
    } as Action;
  }

  private navigateToBerbixLaunch(): void {
    this.router.navigate([ROUTE_IDENTITY_LAUNCH]);
  }

  private logout(): void {
    this.registrationService.clearDriver();
    this.registrationService.clearRegistration();
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private getSwitchToMobileMessage(): Status {
    return {
      message: 'Switch to a mobile device to proceed',
      svgIcon: 'alert-circle-red',
      color: 'switch_to_mobile_banner'
    } as Status;
  }
}
