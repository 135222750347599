<div *ngIf="!menuOptionSelected" fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()">arrow_back</mat-icon>
    <span class="title">Photo upload</span>
  </div>
  <div fxLayout="column" fxLayoutAlign="start" class="component-container padding-top-x2 padding-sides-1x">
    <div *ngIf="!profilePicture" class="circle-div">
      <mat-icon>person</mat-icon>
    </div>
    <div *ngIf="profilePicture" class="circle-div">
      <img class="profile-picture" [src]="profilePicture" />
    </div>
    <div class="center">
      <button
        (click)="onShowMenu()"
        class="full-width"
        mat-raised-button
        class="full-width"
        data-test="upload-photo-button"
      >
        Upload Photo
      </button>
    </div>
  </div>
</div>
<div class="inputs-container">
  <input
    #galleryImageInput
    type="file"
    [accept]="acceptTypes"
    (change)="onProcessFile($event)"
    data-test="select-photo-button"
  />
  <input #cameraImageInput type="file" name="image" [accept]="acceptTypes" capture="user" (change)="onProcessFile($event)" />
</div>
<div *ngIf="showMenu && !imageChangedEvent" class="menu-overlay" fxLayout="column-reverse" fxLayoutAlign="start">
  <div class="buttons-container">
    <button mat-button class="full-width" (click)="onFileInputClicked(cameraImageInput)">
      <i class="material-icons">camera_alt</i>&nbsp;*Landscape only
    </button>
    <button mat-button class="full-width" (click)="onFileInputClicked(galleryImageInput)" data-test="upload-only">
      <i class="material-icons">landscape</i>&nbsp;Upload Photo
    </button>
    <button mat-button (click)="onHideMenu()" class="cancel-btn full-width">
      <i class="material-icons">cancel</i>&nbsp;Cancel
    </button>
  </div>
</div>
<div *ngIf="menuOptionSelected" fxLayout="column" fxLayoutAlign="start" class="component-container">
  <div class="header-navigation-back">
    <mat-icon (click)="onCancelCrop()">arrow_back</mat-icon>
    <span class="title">Move and scale</span>
  </div>

  <div class="content">
    <div [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
      <image-cropper
        #previewImage
        [imageChangedEvent]="imageChangedEvent"
        [maintainAspectRatio]="true"
        [imageQuality]="100"
        [aspectRatio]="4 / 4"
        [resizeToWidth]="1080"
        [roundCropper]="true"
        [canvasRotation]="canvasRotation"
        [transform]="transform"
        format="png"
        (imageCropped)="onImageCropped($event)"
        (imageLoaded)="onImageLoaded()"
        (cropperReady)="onCropperReady()"
        (loadImageFailed)="onLoadImageFailed()"
      >
      </image-cropper>
    </div>
    <div class="spinner-container" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading" class="save-button-container full-width padding-top-x1">
      <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
        <button mat-raised-button class="rotate-icons" (click)="rotateLeft()"><mat-icon>rotate_left</mat-icon></button>
        <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
          <mat-icon>rotate_right</mat-icon>
        </button>
      </div>
      <button
        class="margin-bottom primary-button finish-crop-button"
        mat-raised-button
        (click)="onFinishCrop()"
        data-test="finish-button"
      >
        Finish
      </button>
    </div>
  </div>
</div>
