import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Action } from 'src/app/loads-shared/action';
import { Driver } from 'src/app/shared/models/driver.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ROUTE_SIGNUP } from 'src/app/authentication/shared/routes';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss']
})
export class ReferFriendComponent implements OnInit {
  mailTo: string;
  body: any;
  actions: Action[];
  referralLink: string;
  showNavBar: boolean = true;
  private driver: Driver;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly toastService: ToastService,
    private domSanitizer: DomSanitizer,
    private appService: AppService
  ) {
    this.actions = [];
  }

  ngOnInit() {
    this.driver = this.registrationService.getDriver();
    if (this.driver) {
      const protocol = window.location.protocol;
      const hostname = window.location.hostname;
      const port = window.location.port ? `:${window.location.port}` : '';
      this.referralLink = `${protocol}//${hostname}${port}/#/${ROUTE_SIGNUP}?referralCode=${this.driver.referralCode}`;
      const driverName = `${this.driver.firstName} ${this.driver.lastName}`;
      this.body = this.domSanitizer.bypassSecurityTrustHtml(
        `<p> Hi ${driverName}, <br /><br />
        Every new driver signup and trip completion
        could potentially earn you up to <b>$100</b> CAD!
    </p>
    <p> Just copy the link below and make sure that your friend uses it to create his FleetOperate account. That’s it!</p>`
      );

      this.actions.push(this.createCopyReferralLink());
    }
    if (this.appService.isNativeApp) {
      this.showNavBar = false;
    }
  }

  onNavigateToMyProfile(): void {
    this.registrationService.navigateToProfile();
  }

  private createCopyReferralLink() {
    return {
      name: 'COPY REFERRAL LINK',
      action: (data: any) => this.onCopy(),
      disabled: false,
      color: 'clear_load'
    } as Action;
  }
  private onCopy() {
    if (this.referralLink) {
      let link = document.createElement('textarea');
      link.value = this.referralLink;
      document.body.appendChild(link);
      link.select();
      document.execCommand('copy');
      document.body.removeChild(link);
      this.toastService.showSuccess('Link successfully copied');
    }
  }
}
