import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-dqf-request-carrier-upload-info',
  templateUrl: './dqf-request-carrier-upload-info.component.html',
  styleUrls: ['./dqf-request-carrier-upload-info.component.scss']
})
export class DqfRequestCarrierUploadInfoComponent implements OnInit {
  documentType: string;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public dialogRef: MatBottomSheetRef<DqfRequestCarrierUploadInfoComponent>
  ) { }

  ngOnInit(): void {
    this.documentType = this.data.documentName;
  }

  proceed(): void {
    this.dialogRef.dismiss('proceed');
  }
}
