import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { DEFAULT_ERROR_MESSAGE } from '../shared/constants';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { of, pipe } from 'rxjs';
import { DRIVER_UPLOAD_DOCUMENT_TYPES } from '../shared/constants';
import { doc } from 'prettier';
import { GeneralSearchService } from 'src/app/shared/services/general-search/general-search.service';
import { SelectActionComponent } from 'src/app/shared/dialog/select-action/select-action.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SHIPMENTS_ROUTE_MY_SHIPMENTS } from '../shared/routes';
interface DocumentTypes {
  name: string;
  displayName: string;
  isPresent: boolean;
  order?: number;
  uploadedBy?: string;
  role?: any;
}
@Component({
  selector: 'app-shipments-document',
  templateUrl: './shipments-document.component.html',
  styleUrls: ['./shipments-document.component.scss']
})
export class ShipmentsDocumentComponent implements OnInit {
  driverId: string;
  shipmentId: string;
  isContentReady: boolean;
  shipment: Shipment;
  currentSearchInput = '';
  shipmentDocuments: ShipmentDocument[];
  shipmentDocumentTypes: DocumentTypes[];
  filteredShipmentDocumentTypes: DocumentTypes[];
  loopupTypes;
  allDocumentTypes: DocumentTypes[];

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly driverApiService: DriverApiService,
    private readonly toastService: ToastService,
    private readonly documentsDataService: DocumentsDataService,
    private readonly generalSearchService: GeneralSearchService,
    private readonly bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.driverId = this.driverApiService.driverID;
    this.shipmentId = this.route.snapshot.paramMap.get('id');
    this.getShipmentDetails();
    this.getShipmentDocuments();
    this.getShipmentDocumentTypes();
  }
  navigateBack(): void {
    const path = `${SHIPMENTS_ROUTE_MY_SHIPMENTS}`;
    this.router.navigate([path]);
  }

  onSearchInput(inputString: string): void {
    if (!inputString) {
      this.filteredShipmentDocumentTypes = this.allDocumentTypes;
    } else {
      this.filteredShipmentDocumentTypes = this.shipmentDocumentTypes.filter((el: any) => {
        let requiredSearchFields: any = {
          name: el.name,
          displayName: el.typeDisplayName
        };

        if (this.generalSearchService.searchField(requiredSearchFields, inputString)) {
          return el;
        }
      });
    }
  }
  openRoleFilterSheet(): void {
    let options: any = [
      {
        displayName: 'All',
        name: 'all'
      },
      {
        displayName: 'Dispatcher',
        name: 'Dispatcher'
      },
      {
        displayName: 'Driver',
        name: 'Driver'
      }
    ];
    this.bottomSheet.open(SelectActionComponent, {
      data: {
        title: 'Filter Documemt By ',
        options: options,
        selectedValue: null,
        onConfirm: (val: string) => {
          if (val) {
            this.filteredShipmentDocumentTypes = this.filterDocumentByRole(val);
          }
        }
      }
    });
  }
  filterDocumentByRole(role: string) {
    if (role === 'all') {
      return this.allDocumentTypes;
    } else {
      return this.allDocumentTypes.filter(document => document.role?.includes(role));
    }
  }

  clubDocuments(): void {
    this.shipmentDocumentTypes = [];
    this.filteredShipmentDocumentTypes = [];
    const dispatcherDocumentTypes = this.shipmentDocuments
      .filter(document => {
        return !DRIVER_UPLOAD_DOCUMENT_TYPES.some(type => document.type == type.name);
      })
      .map(documentType => ({
        name: documentType.type,
        displayName: documentType.typeDisplayName,
        role: documentType.role
      }));

    const driverMustDocumentTypes = [...DRIVER_UPLOAD_DOCUMENT_TYPES, ...dispatcherDocumentTypes];
    driverMustDocumentTypes.forEach(type => {
      const document = this.shipmentDocuments.filter(document => document.type === type.name);
      const isPresent = !!document.length;
      const role = new Set(document?.map(document => document.role));
      const documentType = {
        name: type.name,
        displayName: type.displayName,
        isPresent: isPresent,
        document: document,
        order: isPresent ? 1 : 0,
        role: [...role]
      };
      if (!this.shipmentDocumentTypes.find(el => el.displayName === documentType.displayName)) {
        this.shipmentDocumentTypes.push(documentType);
      }
    });
    this.filteredShipmentDocumentTypes = this.shipmentDocumentTypes;
    this.filteredShipmentDocumentTypes.sort((a, b) => b.order - a.order);
    this.allDocumentTypes = this.filteredShipmentDocumentTypes;
  }
  getShipmentDetails(): void {
    this.isContentReady = false;
    this.shipmentsDataService
      .getShipmentDetails(this.shipmentId)
      .pipe(
        first(),
        tap(response => {
          if (response) {
            this.shipment = response;
          }
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return of(false);
        }),
        finalize(() => {
          this.isContentReady = true;
        })
      )
      .subscribe();
  }
  getShipmentDocuments(): void {
    this.documentsDataService
      .getShipmentDocuments(this.shipmentId)
      .pipe(
        first(),
        tap((response: ShipmentDocument[]) => {
          this.shipmentDocuments = response;
          this.clubDocuments();
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {})
      )
      .subscribe();
  }
  getShipmentDocumentTypes(): void {
    this.documentsDataService
      .getShipmentDocumentTypesLookup()
      .pipe(
        first(),
        pipe(
          tap(response => {
            this.loopupTypes = response;
          }),
          catchError(error => {
            return error;
          })
        )
      )
      .subscribe();
  }
}
