import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { ShipmentDataService } from 'src/app/loads-shared/shipment-data/shipment-data.service';
import { ShipmentUtilsService } from 'src/app/loads-shared/shipment-utils.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { TRIPS_ROUTE_TRIP_DETAILS } from '../routes';

const ACTION_MY_TRIPS_RETRIEVAL = 'Retrieve my Trips';

@Component({
  selector: 'app-my-trips',
  templateUrl: './my-trips.component.html',
  styleUrls: ['./my-trips.component.scss']
})
export class MyTripsComponent implements OnInit {
  noShipment = 'There are no trips at this moment';
  driverId: string;
  ongoingShipment: Shipment;
  upcomingShipment: Shipment[];
  upcomingShipmentCount: number;
  loading: boolean;

  private subscription: Subscription;
  private shipments: Shipment[];

  constructor(
    private readonly router: Router,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly shipmentUtilsService: ShipmentUtilsService,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService
  ) {
    this.subscription = new Subscription();
    this.loading = false;
  }

  ngOnInit(): void {
    this.driverId = this.driverApiService.driverID;
    this.loadTrips(this.driverId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadTrips(driverId: string): void {
    this.loading = true;
    this.shipments = [];
    this.subscription.add(
      this.shipmentDataService
        .fetchMyShipments(driverId)
        .pipe(
          catchError((error: any) => {
            return of(false);
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe((shipments: Shipment[]) => {
          if (shipments && shipments.length > 0) {
            this.shipments = shipments;
            this.shipmentUtilsService.sortShipmentsPickupAsc(this.shipments);
            this.ongoingShipment = this.shipmentUtilsService.findEarliestOngoing(this.shipments);

            this.upcomingShipment = this.shipmentUtilsService.findUpcomingTrips(this.shipments);
            this.upcomingShipmentCount = this.upcomingShipment ? this.upcomingShipment.length : 0;
          } else {
            this.upcomingShipmentCount = 0;
          }
        })
    );
  }
  onNavigateToLoadDetails(shipmentId: string): void {
    const url = this.router.url;
    this.router.navigate([`${TRIPS_ROUTE_TRIP_DETAILS}/${shipmentId}`], { state: { back: url } });
  }
}
