<div fxLayout="column" class="loginPageBackground">
  <div class="header-background" fxLayoutAlign="center center" data-test="account-locked-head">
    <span class="header-text">Account Locked</span>
  </div>
  <div fxLayout="column" class="padding-top-sm center" fxLayoutAlign="center" data-test="account-locked-text">
    <mat-icon class="locked-icon-size" fxFlexAlign="center" svgIcon="locked"></mat-icon>
    <span class="center-text"
      >Your account has been locked for<br />activity that violates our
      <a [routerLink]="" (click)="onNavigateToTermsConditions()">Terms and Conditions</a><br />
      and will be unavailable<br />until further notice.</span
    >
    <hr />
    <span class="center-text"
      >For details and account revival help,<br />please contact us at<br />
      <a href="mailto:driverhelp@fleetoperate.com">driverhelp@fleetoperate.com</a></span
    >
  </div>

  <div fxLayout fxLayoutAlign="center end" class="padding-top-lg" fxFlex>
    <button
      fxFlex.gt-sm="20%"
      fxFlex.lt-md="100%"
      class="margin-bottom margin-top-xlg"
      mat-raised-button
      type="submit"
      (click)="onLogout()"
      data-test="logout-button"
    >
      LOG OUT
    </button>
  </div>
</div>
