import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ShipmentActionsService } from '../shipment-actions.service';
import { Action } from '../action';
@Component({
  selector: 'app-shipment-details-action-sheet',
  templateUrl: './shipment-details-action-sheet.component.html',
  styleUrls: ['./shipment-details-action-sheet.component.scss']
})
export class ShipmentDetailsActionSheetComponent implements OnInit {
  actions: Action[];

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<ShipmentDetailsActionSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly shipmentActionService: ShipmentActionsService
  ) {
    this.actions = [];
  }

  ngOnInit() {
    const shipmentContactsAction = this.shipmentActionService.createShipmentContactsAction(
      this.data.shipment,
    );
    if (shipmentContactsAction) {
      this.actions.push(shipmentContactsAction);
    }

    const reportLocationAction = this.shipmentActionService.createReportLocationAction(this.data.shipment);
    if (reportLocationAction) {
      this.actions.push(reportLocationAction);
    }

    const shipmentDocumentsAction = this.shipmentActionService.createShipmentDocumentsAction(this.data.shipment);
    if (shipmentDocumentsAction) {
      this.actions.push(shipmentDocumentsAction);
    }

    const undoCompletePickupAction = this.shipmentActionService.createUndoCompletePickupAction(this.data.shipment);
    if (undoCompletePickupAction) {
      this.actions.push(undoCompletePickupAction);
    }

    this.addNoActionMessage();
  }

  onOpenLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  private addNoActionMessage(): void {
    if (!this.actions || this.actions.length <= 0) {
      this.actions.push({
        name: 'No actions available',
        action: (date: any) => {},
        disabled: true
      } as Action);
    }
  }
}
