import { Component, Inject, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { catchError, finalize, first } from 'rxjs/operators';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DispatcherContact } from 'src/app/shared/models/dispatcher-contact.model';
import { Driver } from 'src/app/shared/models/driver.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DEFAULT_ERROR_MESSAGE, STATUS_ASSIGNED } from '../shared/constants';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { ShipmentsUtilsService } from '../shared/shipments-utils.service';
import { Location } from '@angular/common';
import dayjs from 'dayjs';
import { of } from 'rxjs';
import { DriverStats } from 'src/app/shared/models/driver-stats.model';

const DISABLE_TOP_LOADING_BAR = true;

@Component({
  selector: 'app-shipments-dashboard',
  templateUrl: './shipments-dashboard.component.html',
  styleUrls: ['./shipments-dashboard.component.scss']
})
export class ShipmentsDashboardComponent implements OnInit {
  profilePicturePath: any;
  driver: Driver;
  driverId: string;
  driverName: string;
  dispatcherConatct: DispatcherContact;
  currentTab: number = 0;
  shipment: any;
  shipments: Shipment[] = [];
  ongoingShipments: Shipment[] = [];
  isProfileContentReady: boolean = false;
  dashboardContent: any = {
    completed: 0,
    upcoming: 0,
    earned: 0,
    target_earned: 0,
    earnedPercent: 0,
    miles: 0,
    target_miles: 0,
    milesPercent: 0
  };
  loading: boolean;
  currentDate = dayjs();
  currentYear: number = this.currentDate.year();
  currentMonth: string = this.currentDate.format('MM');

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly shipmentsUtilsService: ShipmentsUtilsService,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly location: Location,
    private readonly toastService: ToastService,
    @Inject('environmentData') public environment: any
  ) {}

  ngOnInit(): void {
    if (!this.environment?.featureFlags?.showDriverDashboard) {
      this.location.back();
    }

    this.dispatcherConatct = this.registrationService.getDispatcherContact();

    // dashboard only for drivers associated with dispatcher
    if (!this.dispatcherConatct.id) {
      this.location.back();
    }
    this.driver = this.registrationService.getDriver();
    this.driverId = this.driver.id;
    this.driverName = `${this.driver.firstName} ${this.driver.lastName}`;

    this.loadProfileImage();
    this.loadShipments();
    this.loadDriverStats();

    document.documentElement.style.setProperty('--gradient-width-earned', '0%');
    document.documentElement.style.setProperty('--gradient-width-miles', '0%');

    // sample data - will be removed after integration
    this.shipment = {
      status: 'pickup in progress',
      origin: 'Vancouver, BC',
      destination: 'Edmonton, AB',
      pickupFormattedDateTime: 'Sep 1 2022, 8:00am',
      deliveryFormattedDateAndTime: 'Sep 1 2022, 7:00pm',
      pickup: {
        streetAddress: '989 Granville ST'
      },
      delivery: {
        streetAddress: '15703 114 Ave NW'
      }
    };
  }

  private loadProfileImage(): void {
    this.registrationService
      .loadProfileImageThumbnail(DISABLE_TOP_LOADING_BAR)
      .pipe(
        catchError(() => {
          return of(false);
        }),
        finalize(() => {
          this.isProfileContentReady = true;
        })
      )
      .subscribe((res: any) => {
        if (res) {
          this.profilePicturePath = res.fileUrl;
        }
      });
  }

  loadShipments(): void {
    this.loading = true;
    this.shipmentsDataService
      .fetchMyShipments(this.driverId)
      .pipe(
        catchError((error: any) => {
          this.toastService.showError(error?.message || DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((shipments: Shipment[]) => {
        if (shipments?.length) {
          this.shipments = shipments;
          this.ongoingShipments = this.shipmentsUtilsService.findOngoingTrips(this.shipments);
          // For upcoming count - filter shipments with status assigned and pickupdatefrom with current year and month
          this.dashboardContent.upcoming = this.shipmentsUtilsService
            .findUpcomingTrips(this.shipments)
            .filter(el => el.status === STATUS_ASSIGNED)
            .filter(el => {
              const dateObj = dayjs(el.pickup.pickupDateFrom);
              return dateObj.isSame(this.currentDate, 'year') && dateObj.isSame(this.currentDate, 'month');
            }).length;
        }
      });
  }

  loadDriverStats(): void {
    this.loading = true;
    this.registrationService
      .loadDriver(DISABLE_TOP_LOADING_BAR, null, true)
      .pipe(
        first(),
        catchError((error: any) => {
          this.toastService.showError(error?.message || DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe(data => {
        let statsData: DriverStats = data.stats;
        // only if driver have stats data
        if (statsData) {
          this.dashboardContent = {
            ...this.dashboardContent,
            completed: statsData.completedShipments[this.currentYear]?.[this.currentMonth] ?? 0,
            earned: statsData.earned[this.currentYear]?.[this.currentMonth]?.amount ?? 0,
            target_earned: statsData.earned[this.currentYear]?.[this.currentMonth]?.target ?? 0,
            miles: statsData.miles[this.currentYear]?.[this.currentMonth]?.amount ?? 0,
            target_miles: statsData.earned[this.currentYear]?.[this.currentMonth]?.target ?? 0
          };

          if (this.dashboardContent.target_earned > 0) {
            this.dashboardContent.earnedPercent = (
              (this.dashboardContent.earned / this.dashboardContent.target_earned) *
              100
            ).toFixed(2);
            document.documentElement.style.setProperty(
              '--gradient-width-earned',
              `${this.dashboardContent.earnedPercent}%`
            );
          }
          if (this.dashboardContent.target_miles > 0) {
            this.dashboardContent.milesPercent = (
              (this.dashboardContent.miles / this.dashboardContent.target_miles) *
              100
            ).toFixed(2);

            document.documentElement.style.setProperty(
              '--gradient-width-miles',
              `${this.dashboardContent.milesPercent}%`
            );
          }
        }
      });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
  }

  getStatusClass(): string {
    return this.shipmentsUtilsService.getShipmentStatusClass(this.shipment.status);
  }
}
