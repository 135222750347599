import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Quote } from 'src/app/loads-shared/shipment-data/models/quote';
import {
  OfferTypeService,
  OFFER_TYPE_DRIVER_COUNTERED,
  OFFER_TYPE_NAME_DRIVER_COUNTERED,
  OFFER_TYPE_SECONDARY_DRIVER_QUOTE,
  OFFER_TYPE_DRIVER_ACCEPTED_SHIPMENT,
  OFFER_TYPE_NAME_DRIVER_ACCEPTED_SHIPMENT,
  OFFER_TYPE_ACCEPTED_OFFER_SHIPMENT,
  OFFER_TYPE_NAME_ACCEPTED_OFFER_SHIPMENT,
  OFFER_TYPE_SHIPPER_COUNTERED_SHIPMENT,
  OFFER_TYPE_NAME_SHIPPER_COUNTERED_SHIPMENT,
  SHIPMENT_ASSIGNED,
  SHIPMENT_CONFIRM
} from 'src/app/loads-shared/offer-type.service';
import { CurrencyService } from 'src/app/shared/services/currency.service';

const CONFIRMATION_PENDING = 'confirmation pending';
const BOOKED = 'booked';

@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit, OnChanges {
  @Input() quote: Quote;
  @Input() shipmentStatus: string;
  @Input() shipmentOfferRate: number;
  @Input() isTrip: boolean;
  offer: string;
  secondaryOffer: number;
  offerType: string;
  offerTypeName: string;

  constructor(private readonly offerTypeService: OfferTypeService, private readonly currencyService: CurrencyService) {}

  ngOnChanges(): void {
    const offer =
      this.quote && this.quote.primaryOffer
        ? this.quote.primaryOffer
        : this.shipmentOfferRate
        ? this.shipmentOfferRate
        : undefined;
    this.offer = this.currencyService.getLocaleCurrency(offer);
    if (this.isTrip) {
      this.offerTypeName = this.determineTripOfferTypeName();
    } else {
      this.offerTypeName = this.determineOfferTypeName(this.offerType);
      this.offerType = this.offerTypeService.determineOfferType(this.shipmentStatus, this.quote.status);
    }
    this.secondaryOffer = this.determineSecondaryOffer(this.quote, this.offerType);
  }

  ngOnInit() {}

  private determineTripOfferTypeName(): string {
    switch (this.shipmentStatus) {
      case BOOKED:
        return SHIPMENT_ASSIGNED;
      case CONFIRMATION_PENDING:
        return SHIPMENT_CONFIRM;
    }
  }

  private determineOfferTypeName(offerType: string): string {
    switch (offerType) {
      case OFFER_TYPE_DRIVER_COUNTERED:
        return OFFER_TYPE_NAME_DRIVER_COUNTERED;
      case OFFER_TYPE_SECONDARY_DRIVER_QUOTE:
        return undefined;
      case OFFER_TYPE_DRIVER_ACCEPTED_SHIPMENT:
        return OFFER_TYPE_NAME_DRIVER_ACCEPTED_SHIPMENT;
      case OFFER_TYPE_ACCEPTED_OFFER_SHIPMENT:
        return OFFER_TYPE_NAME_ACCEPTED_OFFER_SHIPMENT;
      case OFFER_TYPE_SHIPPER_COUNTERED_SHIPMENT:
        return OFFER_TYPE_NAME_SHIPPER_COUNTERED_SHIPMENT;
    }
  }

  private determineSecondaryOffer(quote: Quote, offerType: string): number {
    const secondaryOffer = quote && quote.secondaryOffer ? quote.secondaryOffer : undefined;
    if (offerType === OFFER_TYPE_SHIPPER_COUNTERED_SHIPMENT) {
      return secondaryOffer;
    }
    return;
  }
}
