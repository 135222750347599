import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentRibbonComponent } from './environment-ribbon/environment-ribbon.component';

@NgModule({
  declarations: [EnvironmentRibbonComponent],
  imports: [CommonModule],
  exports: [EnvironmentRibbonComponent]
})
export class EnvironmentRibbonModule {}
