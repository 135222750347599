<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground profile-license">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">License {{ title }} View</span>
  </div>
  <div *ngIf="!showUpload">
    <div class="button-box padding-top-lg" fxFlex>
      <a mat-button [href]="previewImage || safeUrl" target="_blank" class="button"
        ><mat-icon svgIcon="arrow-down-icon" class="icon-color icon-size"></mat-icon> Download
      </a>
    </div>
    <div>
      <p class="p-font">License {{ title }} Image</p>
    </div>
    <div class="details" *ngIf="generatedDate">
      <p class="date"><span class="text-name">UPLOADED ON</span> {{ this.generatedDate }}</p>
      <div class="replace" (click)="replaceImage()">
        <mat-icon svgIcon="replace"></mat-icon>
        <span>Replace image</span>
      </div>
    </div>
    <img [src]="previewImage" *ngIf="previewImage" class="imagePreview padding-md" />
    <iframe [src]="safeUrl" class="safeUrl" *ngIf="safeUrl" frameborder="0" loading="lazy"></iframe>
  </div>
  <div [hidden]="!showUpload">
    <app-status-banner *ngIf="status" [status]="status" class="padding-bottom-xlg"> </app-status-banner>
    <div class="video-container" *ngIf="mobile && !error">
      <div class="full-width">
        <video
          [class.show]="!isCaptured"
          [class.hide]="isCaptured"
          #video
          id="video"
          [width]="WIDTH"
          [height]="HEIGHT"
          autoplay
          playsinline
        ></video>
        <canvas class="canvasClass hide" #canvas id="canvas" [width]="WIDTH" [height]="HEIGHT"></canvas>
        <div *ngIf="showCrop" [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
          <image-cropper
            #previewImageCropper
            [imageBase64]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [imageQuality]="100"
            cropperMinWidth="420"
            cropperMinHeight="220"
            [roundCropper]="false"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            format="png"
            (imageCropped)="onImageCropped($event)"
            (imageLoaded)="onImageLoaded()"
            (cropperReady)="onCropperReady()"
            (loadImageFailed)="onLoadImageFailed()"
          >
          </image-cropper>
        </div>
      </div>
      <div class="finish-row" *ngIf="!isCaptured">
        <button
          class="margin-bottom btn-retake"
          mat-button
          (click)="onClose()"
          data-test="retake-button"
          [disabled]="isApiLoading"
        >
          Close
        </button>
        <button fxFlex="40" mat-button class="btn-capture" (click)="capture()" [disabled]="isApiLoading">
          Capture
        </button>
      </div>

      <div *ngIf="isCaptured" class="full-width padding-top-x1">
        <div class="finish-row">
          <button
            class="margin-bottom btn-retake"
            mat-button
            (click)="onRetake()"
            data-test="retake-button"
            [disabled]="isApiLoading"
          >
            Retake
          </button>
          <button mat-raised-button class="rotate-icons" (click)="rotateLeft()">
            <mat-icon>rotate_left</mat-icon>
          </button>
          <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
            <mat-icon>rotate_right</mat-icon>
          </button>
          <button
            class="margin-bottom  btn-finish"
            mat-raised-button
            (click)="onFinish()"
            data-test="finish-button"
            [disabled]="isApiLoading"
          >
            Finish
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="error" class="video-error">{{ error }}. Please login again</div>
  </div>
</div>
