import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, ViewChildren } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Employment } from 'src/app/shared/models/employment.model';
import { environment } from 'src/environments/environment.dev';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { Location } from '@angular/common';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { ROUTE_DQF, ROUTE_EMPLOYED_FORM, ROUTE_EMPLOYMENT_HISTORY, ROUTE_UNEMPLOYED_FORM } from 'src/app/shared/routes';
import { Driver } from 'src/app/shared/models/driver.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { RequirementsCompleteActionComponent } from '../requirements-complete-action/requirements-complete-action.component';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { StorageService } from 'src/app/shared/storage/storage.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY'
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-employment-history',
  templateUrl: './employment-history.component.html',
  styleUrls: ['./employment-history.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class EmploymentHistoryComponent implements OnInit, OnDestroy {
  employmentList = [];
  form: FormGroup;
  errorMessage: string;
  progressBarValue: number = 100;
  lastDateOfMonth = dayjs()
    .endOf('month')
    .valueOf();
  currentDate = new Date(this.lastDateOfMonth);
  currentMonthYear = dayjs(this.currentDate).format('MMMM YYYY');
  lastDate = dayjs(this.currentDate).subtract(10, 'year');
  lastMonthYear = dayjs(this.lastDate).format('MMMM YYYY');
  isGreaterThan10years: boolean = false;
  defaultToDate: Date;
  minFromDate;
  maxToDate = dayjs(this.currentDate);
  isContentReady: boolean = false;
  isAllowDeleteCurrentEmployment: boolean = false;
  driver: Driver;
  showAddNewCurrentRecordText: boolean;

  @ViewChild('experienceForm', { read: ViewContainerRef }) experienceTemplateForm: ViewContainerRef;
  @ViewChild('unemploymentForm', { read: ViewContainerRef }) unemploymentTemplateForm: ViewContainerRef;
  @ViewChildren('toDatePicker') toDateList;
  @ViewChildren('fromDatePicker') fromDateList;
  @ViewChildren('unEmpFromDatePicker') unEmpFromDateList;
  @ViewChildren('unEmpToDatePicker') unEmpToDateList;
  private subs = new SubSink();

  constructor(
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly location: Location,
    private readonly bottomSheet: MatBottomSheet,
    private readonly toastService: ToastService,
    private readonly storageService: StorageService
  ) {
    this.errorMessage = undefined;
  }

  ngOnInit(): void {
    this.loadExperience();
  }

  openRequirementsActionSheet() {
    let currentAddress: Employment = this.employmentList.find(emp => emp.isCurrentEmployment == true);
    if (!currentAddress) {
      return;
    }
    const bottomSheetRef = this.bottomSheet.open(RequirementsCompleteActionComponent, {
      panelClass: 'signature-panel',
      data: {
        currentAddress: currentAddress,
        isEmploymentHistory: true
      },
      disableClose: true
    });
    bottomSheetRef.afterDismissed().subscribe(data => {
      if (data.type === 'yes') {
        this.isContentReady = false;
        let addressModel: any = { ...currentAddress };
        if (data.data?.hasOwnProperty('referenceEmail')) {
          addressModel.referenceEmail = data.data?.referenceEmail;
        }
        let id = addressModel.id;
        delete addressModel.id;
        delete addressModel.modifiedDate;
        delete addressModel.creationDate;
        delete addressModel.version;
        this.registrationService.updateEmployment(addressModel, id).subscribe(
          res => {
            this.errorMessage = undefined;
            this.loadExperience();
          },
          (error: any) => {
            this.toastService.showError(error?.message);
            this.isContentReady = true;
          }
        );
      } else {
        // when user selects toDate
        this.isContentReady = false;
        let addressModel: any = { ...currentAddress };
        addressModel.toDate = dayjs(data.data?.toDate).format('YYYY-MM-DD');
        if (data.data?.hasOwnProperty('referenceEmail')) {
          addressModel.referenceEmail = data.data?.referenceEmail;
        }
        addressModel.isCurrentEmployment = false;
        let id = addressModel.id;
        delete addressModel.id;
        delete addressModel.modifiedDate;
        delete addressModel.creationDate;
        delete addressModel.version;
        this.registrationService.updateEmployment(addressModel, id).subscribe(
          res => {
            this.errorMessage = undefined;
            this.loadExperience();
          },
          (error: any) => {
            this.toastService.showError(error?.message);
            this.isContentReady = true;
          }
        );
      }
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  onAddNewEmployment(isMissingIndex: number): void {
    let path = `/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_EMPLOYED_FORM}`;
    if (isMissingIndex) {
      path += `?missingIndex=${isMissingIndex}`;
    }
    this.router.navigateByUrl(path);
  }

  onAddNewCurrentEmployment(isEmploymentForm: boolean): void {
    this.storageService.store('currentEmploymentState', null);
    let path = '';
    if (isEmploymentForm) {
      path = `/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_EMPLOYED_FORM}?newCurrentAddress=true`;
    } else {
      path = `/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_UNEMPLOYED_FORM}?newCurrentAddress=true`;
    }
    this.router.navigateByUrl(path);
  }

  onAddUnemployment(isMissingIndex: number): void {
    let path = `/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_UNEMPLOYED_FORM}`;
    if (isMissingIndex) {
      path += `?missingIndex=${isMissingIndex}`;
    }
    this.router.navigateByUrl(path);
  }

  onDeleteEmploymentPressed($event: Employment): void {
    this.isContentReady = false;
    this.subs.add(
      this.registrationService.deleteEmployment($event).subscribe(
        () => {
          this.errorMessage = undefined;
          const newRecords = this.employmentList.filter(emp => emp.id !== $event.id);
          this.registrationService.updateEmploymentHistoryStore(newRecords);
          this.loadExperience();
        },
        (error: string) => {
          this.errorMessage = environment.errorMessage;
          this.isContentReady = true;
        }
      )
    );
  }

  onUpdateEmploymentPressed($event): void {
    if ($event.employmentState == 'unemployed') {
      this.router.navigateByUrl(`/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_UNEMPLOYED_FORM}/${$event.id}`);
    } else {
      this.router.navigateByUrl(`/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_EMPLOYED_FORM}/${$event.id}`);
    }
  }

  goBack(): void {
    this.location.back();
  }

  showHideAddButton() {
    if (this.isGreaterThan10years) {
      return false;
    }
    return true;
  }

  private loadExperience(): void {
    this.isContentReady = false;
    this.subs.add(
      this.registrationService.getEmploymentHistory().subscribe(res => {
        this.employmentList = [...res];

        this.isAllowDeleteCurrentEmployment = this.allowDeleteCurrentEmployment();
        const currentEmployment = this.employmentList.find(e => e.isCurrentEmployment);
        if (this.employmentList?.length) {
          this.employmentList.sort((a, b) => {
            const fromDate1 = new Date(b.fromDate).getTime();
            const fromDate2 = new Date(a.fromDate).getTime();

            if (fromDate1 !== fromDate2) {
              return fromDate1 - fromDate2;
            } else {
              const toDate1 = b.toDate ? b.toDate : this.currentDate;
              const toDate2 = a.toDate ? a.toDate : this.currentDate;
              return new Date(toDate1).getTime() - new Date(toDate2).getTime();
            }
          });

          this.checkMissingGap();
          // if there is no currentEmployment in employmentlist
          if (this.employmentList.length > 0 && !currentEmployment) {
            this.progressBarValue = 90;
          }
        } else {
          this.isGreaterThan10years = false;
          this.progressBarValue = 100;
          this.defaultToDate = this.currentDate;
        }
        this.registrationService.loadDriver().subscribe(() => {
          this.driver = this.registrationService.getDriver();
          if (this.location.path().includes(ROUTE_DQF)) {
            if (
              !this.driver.employmentRequirementsCompleted &&
              currentEmployment &&
              dayjs(currentEmployment?.modifiedDate)
                .set('date', 1)
                .format('YYYY MM') <
                dayjs(this.currentDate)
                  .set('date', 1)
                  .format('YYYY MM')
            ) {
              this.openRequirementsActionSheet();
              this.progressBarValue = 90;
            } else {
              // after requirements selection stage user refreshed the page
              if (!this.driver.employmentRequirementsCompleted && this.employmentList.length && !currentEmployment) {
                this.showAddNewCurrentRecordText = true;
              }
            }
          }
          this.isContentReady = true;
        });
      })
    );
  }
  private allowDeleteCurrentEmployment(): boolean {
    return this.employmentList?.length >= 1;
  }

  private checkMissingGap(): void {
    let isYearsInOrder = true;
    let employmentData: any[] = [...this.employmentList];
    const currentEmployment = this.employmentList.filter(e => e.isCurrentEmployment);
    let currentDifferenceMonth = 0;
    const currentEmpToDate = dayjs(this.currentDate);
    const currentEmpFromDate = dayjs(currentEmployment[0]?.fromDate);
    currentDifferenceMonth = Math.round(currentEmpToDate.diff(currentEmpFromDate, 'month', true));

    if (this.employmentList.length > 1) {
      let spliceIndex = 0;
      for (let index = 0; index < employmentData.length - 1; index++) {
        if (employmentData[index + 1]) {
          const date2CompareToDate = dayjs(employmentData[index + 1].toDate);
          const date2CompareFromDate = dayjs(employmentData[index].fromDate);
          if (date2CompareToDate.diff(date2CompareFromDate, 'month') === 0) {
            const currToDate = dayjs(employmentData[index + 1].toDate);
            const currFromDate = dayjs(employmentData[index + 1].fromDate);

            currentDifferenceMonth += Math.round(currToDate.diff(currFromDate, 'month', true));
            if (isYearsInOrder) {
              this.updateProgressBar(currentDifferenceMonth, isYearsInOrder);
            }
            if (this.checkEmploymentHasTenYearsInfo(currentDifferenceMonth, isYearsInOrder)) {
              return;
            }
          } else {
            if (!spliceIndex) {
              spliceIndex = index + 1;
            } else {
              spliceIndex += index;
            }
            if (employmentData.length - 1 === index + 1 && employmentData.length - 2 === index) {
              this.employmentList.splice(this.employmentList.length - 1, 0, { fromDate: null } as Employment);
            } else {
              this.employmentList.splice(spliceIndex, 0, { fromDate: null } as Employment);
            }
            if (index === 0) {
              this.updateProgressBar(currentDifferenceMonth, isYearsInOrder);
              isYearsInOrder = this.checkIsYearsInOrder(currentDifferenceMonth);
            } else {
              isYearsInOrder = this.checkIsYearsInOrder(currentDifferenceMonth);
              this.updateProgressBar(currentDifferenceMonth, isYearsInOrder);
            }
          }
        }
      }
    } else {
      this.checkEmploymentHasTenYearsInfo(currentDifferenceMonth, isYearsInOrder);
      this.updateProgressBar(currentDifferenceMonth, isYearsInOrder);
    }
    this.checkAndUpdateOverLap();
  }

  private checkAndUpdateOverLap() {
    this.employmentList = this.employmentList.map((emp, i) => {
      // RecA - Overlap gap - RecB => check if toDate of RecB is greater than fromDate of RecA
      if (
        emp.fromDate === null &&
        dayjs(this.employmentList[i + 1].toDate).format('YYYY MM') >
          dayjs(this.employmentList[i - 1].fromDate).format('YYYY MM')
      ) {
        return { ...emp, isOverLap: true };
      }
      return emp;
    });
  }

  private checkEmploymentHasTenYearsInfo(month: number, isYearsInOrder: boolean): boolean {
    if (month >= 120 && isYearsInOrder) {
      this.isGreaterThan10years = true;
      return true;
    } else {
      this.isGreaterThan10years = false;
      return false;
    }
  }

  private updateProgressBar(currentDifferenceMonth: number, isYearsInOrder: boolean): void {
    if (isYearsInOrder) {
      const percent = (currentDifferenceMonth / 120) * 100;
      this.progressBarValue = percent >= 100 ? 0 : 100 - percent;
    }
  }

  private checkIsYearsInOrder(currentDiffAddressMonth): boolean {
    if (currentDiffAddressMonth < 120) {
      return false;
    } else {
      return true;
    }
  }
}
