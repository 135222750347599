import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular';
import 'hammerjs';

if (environment?.featureFlags?.production) {
  enableProdMode();
}

if (environment?.featureFlags?.enableSentry) {
  const HANDLED_UNKNOWN_ERROR_TYPE = 'Handled unknown error';
  let sessionOnReplay = 0.5;
  let env = 'production';
  if (!environment?.featureFlags?.production) {
    sessionOnReplay = 0.1;
    env = 'test';
  }
  Sentry.init({
    environment: env,
    dsn: environment.sentryDSNKey,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: sessionOnReplay,
    integrations: [new Sentry.Replay()],
    ignoreErrors: ['Non-Error exception captured'],
    beforeSend(event) {
      if (event.message === HANDLED_UNKNOWN_ERROR_TYPE) {
        return null;
      }
      return event;
    },
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
