import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { Response } from 'src/app/shared/services/response';
import { ShipmentDataModule } from './shipment-data.module';
import { Quote } from './models/quote';
import { Bid } from './models/bid';
import { Location } from './models/location';
import { ShipmentFeedback } from './models/shipment-feedback.model';

const PATH_SHIPMENTS = '/shipments';
const PATH_SHIPMENTS_ID = `${PATH_SHIPMENTS}/{id}`;
const PATH_PICKUPED_SHIPMENT = `${PATH_SHIPMENTS_ID}/pickedup`;
const PATH_UNDO_PICKUPED_SHIPMENT = `${PATH_SHIPMENTS_ID}/undopickedup`;
const PATH_DELIVERED_SHIPMENT = `${PATH_SHIPMENTS_ID}/delivered`;
const PATH_SHIPMENT_LOCATION = `${PATH_SHIPMENTS_ID}/location`;
const PATH_QUOTE = `${PATH_SHIPMENTS_ID}/quotes`;
const PATH_BID = `/quotes/{id}/bids`;
const PATH_LOOKUPS = `/lookups`;
const TRIP = 'trip';
const PATH_FEEDBACK = `/feedback`;

export const EVENT_TYPE_ACCEPTED = 'accepted';
export const EVENT_TYPE_COUNTERED = 'countered';
export const EVENT_TYPE_PASSED = 'passed';
export const FILTER_SHIPMENTS_STATUS_UNSECURED = 'unsecured';
export const EVENT_TYPE_CONFIRMED = 'confirmed';
export const EVENT_TYPE_CANCELLED = 'cancelled';
export const FILTER_SHIPMENTS_STATUS_INACTIVE = 'inactive';
export const EVENT_TYPE_CLEAR = 'clear';

@Injectable({
  providedIn: ShipmentDataModule
})
export class ShipmentApiService {
  isCarrierDriver: boolean;
  constructor(
    private readonly api: FleetoperateApiService,
    @Inject('apiUrl') private apiUrl: string,
    private readonly http: HttpClient
  ) {}

  getShipments(status?: Array<string>, actorid?: string, isCarrierDriver?: boolean): Observable<Response> {
    const path = PATH_SHIPMENTS;
    let queryString = `?include=quote`;
    if (status) {
      status.forEach((element: string) => {
        if (element) {
          queryString = queryString + '&status=' + element;
        }
      });
    }

    if (isCarrierDriver) queryString = queryString + '&type=' + TRIP;

    queryString = actorid ? `${queryString}&actorid=${actorid}` : queryString;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  getShipment(shipmentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_ID.replace('{id}', shipmentId);
    const queryString = `?include=quote`;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  updateShipmentAsPickedUp(shipmentId: string): Observable<Response> {
    const path = PATH_PICKUPED_SHIPMENT.replace('{id}', shipmentId);
    return this.api.put(`${this.apiUrl}${path}`, {});
  }

  updateShipmentAsUndoPickedUp(shipmentId: string): Observable<Response> {
    const path = PATH_UNDO_PICKUPED_SHIPMENT.replace('{id}', shipmentId);
    return this.api.put(`${this.apiUrl}${path}`, {});
  }

  updateShipmentAsDelivered(shipmentId: string): Observable<Response> {
    const path = PATH_DELIVERED_SHIPMENT.replace('{id}', shipmentId);
    return this.api.put(`${this.apiUrl}${path}`, {});
  }

  updateShipmentLocation(shipmentId: string, location: Location): Observable<Response> {
    const path = PATH_SHIPMENT_LOCATION.replace('{id}', shipmentId);
    return this.api.put(`${this.apiUrl}${path}`, location);
  }

  createQuote(quote: Quote): Observable<Response> {
    const path = PATH_QUOTE.replace('{id}', quote.shipmentID);
    return this.api.post(`${this.apiUrl}${path}`, quote);
  }

  createBid(quoteId: string, bid: Bid): Observable<Response> {
    const path = PATH_BID.replace('{id}', quoteId);
    return this.api.post(`${this.apiUrl}${path}`, bid);
  }

  getProvinceLookups(): Observable<Response> {
    const path = PATH_SHIPMENTS + PATH_LOOKUPS;
    let queryString = `?include=firstAdministrativeLevelSupported`;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  getProvinceShipments(stateCode: string): Observable<Response> {
    const path = PATH_SHIPMENTS;
    let queryString = `?include=quote&status=unsecured&pickup.state=` + stateCode;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  getShipmentsMock(page: number, perPage: number): Observable<any> {
    return this.http.get('../../../assets/data/shipment-list.json').pipe(
      map((response: Response) => {
        const shipments = response.data;
        const to = perPage;
        const from = page * perPage - perPage;
        const length = shipments.length;
        const list = shipments.splice(from, to);

        return {
          shipments: list,
          count: length
        };
      })
    );
  }

  getShipmentByIdMock(id: string) {
    return this.http.get('../../../assets/data/shipment-list.json').pipe(
      map((response: Response) => {
        const shipments = response.data;
        return shipments.find(shipment => {
          return shipment.shipmentId === id;
        });
      })
    );
  }

  sendFeedback(feedback: ShipmentFeedback): Observable<Response> {
    return this.api.post(`${this.apiUrl}${PATH_FEEDBACK}`, feedback);
  }
}
