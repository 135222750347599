import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MilesDriven } from '../shared/models/miles-driven.model';

@Component({
  selector: 'app-truck-type',
  templateUrl: './truck-type.component.html',
  styleUrls: ['./truck-type.component.scss']
})
export class TruckTypeComponent implements OnInit {
  @Input() group: FormGroup;
  @Input() milesDrivenOptions: MilesDriven[];
  @Input() groupName: string;
  @Output() yearChange = new EventEmitter();
  @Output() monthChange = new EventEmitter();
  maxDate: Date = new Date();
  years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  constructor() { }

  ngOnInit(): void {
  }

  get milesDriven() {
    return this.group.get('milesDriven');
  }

  get checkAnyTruck() {
    const value =
      this.group.get('van').value ||
      this.group.get('tank').value ||
      this.group.get('flat').value ||
      this.group.get('dumpTruck').value ||
      this.group.get('reefer').value ||
      this.group.get('equipment').value;
    if (!value) {
      this.group.get('years').patchValue(null);
      this.group.get('months').patchValue(null);
      this.group.get('milesDriven').patchValue(null);
      this.onYearChange();
      this.onMonthChange();
    }
    return value;
  }

  onTruckChange(value: MatCheckboxChange) {
    if (!value.checked) {
      this.onYearChange();
      this.onMonthChange();
    }
  }

  onYearChange() {
    this.yearChange.emit();
  }

  onMonthChange() {
    this.monthChange.emit();
  }
}
