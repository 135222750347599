import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import {
  DEFAULT_ERROR_MESSAGE,
  SINGLE_DOCUMENT_TYPES,
  DRIVER_READ_ONLY_DOC_TYPES,
  DRIVER_NO_ACTION_DOC_TYPES
} from '../constants';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SelectActionComponent } from 'src/app/shared/dialog/select-action/select-action.component';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_SHIPMENTS, SHIPMENTS_ROUTE_STOPS_DETAILS } from '../routes';
import { ShipmentsStopsActionSheetComponent } from '../../shipments-stops-action-sheet/shipments-stops-action-sheet.component';
 
const DELETED_DOCUMENT = 'Successfully deleted document';
const UPLOAD_NEW_DOCUMENT = 'Upload New Document';
@Component({
  selector: 'app-shipment-document-view',
  templateUrl: './shipment-document-view.component.html',
  styleUrls: ['./shipment-document-view.component.scss']
})
export class ShipmentDocumentViewComponent implements OnInit {
  previewImage: string;
  safeUrl: SafeUrl;
  loading: boolean = false;
  document: ShipmentDocument;
  shipmentId: string;
  fileUrl;
  singleDocumentFiles = [...SINGLE_DOCUMENT_TYPES, ...DRIVER_NO_ACTION_DOC_TYPES];
  isReadOnlyDocument = false;
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer,
    private readonly documentsDataService: DocumentsDataService,
    private readonly toastService: ToastService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
    const documentId = this.activatedRoute.snapshot.paramMap.get('document-id');
    this.loading = true;
    this.documentsDataService
      .getShipmentDocument(this.shipmentId, documentId)
      .pipe(
        first(),
        tap(response => {
          this.document = response;
          this.isReadOnlyDocument = DRIVER_NO_ACTION_DOC_TYPES.includes(this.document.type);
          const url = `${response.fileUrl}`;
          this.fileUrl = url;
          const parentUrl = url
            ?.split('?')[0]
            ?.split('?')[0]
            ?.split('/');

          if (parentUrl && parentUrl[parentUrl.length - 1]?.split('.')[1] === 'pdf') {
            this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.fileUrl + '#toolbar=0');
          } else {
            this.previewImage = response.fileUrl;
          }
          this.loading = false;
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          this.loading = false;
          return error;
        })
      )
      .subscribe();
  }

  onNavigateBack(): void {
    if (this.singleDocumentFiles.includes(this.document?.type)) {
      const path = `${SHIPMENTS_ROUTE_MY_SHIPMENTS}/${this.shipmentId}/documents`;
      this.router.navigate([path]);
    } else {
      this.location.back();
    }
  }
  downloadDocument(): void {
    window.open(this.fileUrl, '_blank');
  }
  deleteDocument(): void {
    if (!this.document.id) {
      return;
    }
    this.loading = true;
    this.documentsDataService
      .deleteShipmentDocument(this.shipmentId, this.document.id)
      .pipe(
        first(),
        tap(response => {
          this.toastService.showSuccess(DELETED_DOCUMENT);
          this.documentsDataService.shipmentDocuments = this.documentsDataService.shipmentDocuments?.filter(
            el => el.id !== this.document.id
          );
          this.location.back();
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe();
  }

  openUplaodActionSheet(stopId, documentType): Promise<void> {
    return new Promise((resolve) => {
      const dialogRef = this.bottomSheet.open(ShipmentsStopsActionSheetComponent, {
        data: {
          title: '',
          shipmentId: this.shipmentId,
          shipmentStopId: stopId,
          documentType: documentType
        }
      });
      dialogRef.afterDismissed().subscribe(data => {
        resolve();
      });
    });
  }

  replaceDocument(shipmentStopId, documentType) {
    this.loading = true;

    const deleteDocumentPromise = new Promise<void>((resolve, reject) => {
      this.documentsDataService
        .deleteShipmentDocument(this.shipmentId, this.document.id)
        .pipe(
          first(),
          tap(response => {
            this.toastService.showSuccess(DELETED_DOCUMENT);
            setTimeout(() => {
              this.toastService.showInfo(UPLOAD_NEW_DOCUMENT);
          }, 2000); 
            resolve();
          }),
          catchError(error => {
            this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
            reject(error);
            return error;
          })
        )
        .subscribe();
    });

    const openActionSheetPromise = this.openUplaodActionSheet(shipmentStopId, documentType);

    Promise.all([deleteDocumentPromise, openActionSheetPromise])
      .then(() => {
        let path = `${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_STOPS_DETAILS}/${this.shipmentId}`;
          this.router.navigate([path]);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  openActionSheet(): void {
    let options: any = [
      {
        displayName: 'Download',
        name: 'download',
        icon: 'download'
      }
    ];
    if (
      ![...DRIVER_READ_ONLY_DOC_TYPES, ...DRIVER_NO_ACTION_DOC_TYPES].includes(this.document.type) &&
      !this.document.shipmentStopId
    ) {
      options.push({
        displayName: 'Delete',
        name: 'delete',
        icon: 'delete-icon'
      });
    }
    if (this.document.shipmentStopId) {
      options.push({
        displayName: 'Replace Document',
        name: 'replace',
        icon: 'replace'
      });
    }
    this.bottomSheet.open(SelectActionComponent, {
      data: {
        title: '',
        options: options,
        selectedValue: null,
        onConfirm: (val: string) => {
          if (val === 'download') {
            this.downloadDocument();
          } else if (val === 'replace') {
            this.replaceDocument(this.document.shipmentStopId, this.document.type);
          } else {
            this.deleteDocument();
          }
        }
      }
    });
  }
}
 
