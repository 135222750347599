<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5"><mat-icon svgIcon="left-arrow"></mat-icon></div>
    <div fxFlex="95" class="header-text">Driver Qualification File</div>
  </div>
  <div class="content">
    <div fxLayout="column" class="dqf-list-container">
      <div fxFlex="10" class="description-banner" *ngIf="isContentReady && dqfs?.length === 0">
        <div class="description">
          <span>No Qualification files to show </span>
        </div>
      </div>
      <app-skeleton-dqf-list *ngIf="!isContentReady"></app-skeleton-dqf-list>
      <div *ngIf="isContentReady" class="dqf-list">
        <div
          *ngFor="let dqf of dqfs"
          class="dqf-item-container"
          fxFlex
          fxLayout
          [ngClass]="{'disabled': isWithdrawn(dqf.status)}"
          (click)="!isWithdrawn(dqf.status) && onDqfItemClick(dqf)"
          [attr.data-id]="dqf.carrierCompanyName"
        >
          <div fxFlex="20">
            <img [src]="dqf.carrierCompanyLogo" [ngClass.gt-sm]="'company-logo'" class="company-image" />
          </div>
          <div fxFlex="50" class="company-description" [ngClass.lt-md]="'margin-left-md'">
            <div class="company-name">{{ dqf.carrierCompanyName }}</div>
            <div class="company-duration">{{ dqf.createdAt | date: 'MMM dd YYYY' }}</div>
          </div>
          <div fxFlex="30" class="status-container" *ngIf="isInComplete(dqf.status)">
            <div class="btn-status status-incomplete" data-test="dqf-incomplete-button">Incomplete</div>
          </div>
          <div fxFlex="30" class="status-container" *ngIf="isPending(dqf.status)">
            <div class="btn-status status-in-progress" data-test="dqf-under-review-button">Under Review</div>
          </div>
          <div fxFlex="30" class="status-container" *ngIf="isRejected(dqf.status)">
            <div class="btn-status status-rejected">Rejected</div>
          </div>
          <div fxFlex="30" class="status-container" *ngIf="isApproved(dqf.status)">
            <div class="status-lock">
              <mat-icon svgIcon="locked-blue" class="lock-icon"></mat-icon>
            </div>
          </div>
          <div class="status-container" *ngIf="isWithdrawn(dqf.status)">
            <div class="btn-status status-rejected">Withdrawn</div>
            <div class="status-lock margin-top-sm margin-right-md">
              <mat-icon svgIcon="locked-blue" class="lock-icon"></mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
