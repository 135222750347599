import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dqf-view-document',
  templateUrl: './dqf-view-document.component.html',
  styleUrls: ['./dqf-view-document.component.scss']
})
export class DqfViewDocumentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
