import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ROUTE_SIGNIN } from './authentication/shared/routes';

class ExcludedUrls {
  url: string;
  code: number;
}

const urlPath: ExcludedUrls[] = [
  { url: 'login' },
  { url: 'confirm-forgot-password' },
  { url: 'forgotpassword' },
  { url: 'resetpassword', code: 4008 }
] as ExcludedUrls[];

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header.
    // catch the error, make specific functions for catching specific errors and you can chain through them with more catch operators
    return next.handle(req).pipe(catchError(x => this.handleAuthError(x))); //here use an arrow function, otherwise you may get "Cannot read property 'navigate' of undefined" on angular 4.4.2/net core 2/webpack 2.70
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    //handle your auth error or rethrow
    if (err.status === 401 && !this.checkExcludedUrls(err)) {
      //navigate /delete cookies or whatever
      this.router.navigateByUrl(ROUTE_SIGNIN);
      // if you've caught / handled the error, you don't want to rethrow it unless you also want downstream consumers to have to handle it as well.
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }

  private checkExcludedUrls(err: HttpErrorResponse): boolean {
    let result = false;
    urlPath.forEach((excludedUrl: ExcludedUrls) => {
      if (err.url.includes(excludedUrl.url)) {
        if (excludedUrl.code && excludedUrl.code === err.error.code) {
          result = false;
        } else {
          result = true;
        }
      }
    });
    return result;
  }
}
