import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  constructor() {}

  isIOSdevice(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
  formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
  }
}
