import { Component, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { FormControl, Validators } from '@angular/forms';
import { ComponentType } from '@angular/cdk/portal';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { catchError } from 'rxjs/operators';
import { Event } from 'src/app/loads-shared/shipment-data/models/event';
import { ShipmentUtilsService } from 'src/app/loads-shared/shipment-utils.service';

@Component({
  selector: 'app-shipment-add-comment-action-sheet',
  templateUrl: './shipment-add-comment-action-sheet.component.html',
  styleUrls: ['./shipment-add-comment-action-sheet.component.scss']
})
export class ShipmentAddCommentActionSheetComponent {
  saving: boolean;
  actions: Action[] = [];
  nextSheetActions: Component[];
  shipment: Shipment;
  totalActions: number;
  currentActionStep: number;
  currentData: any;
  callback: Function;
  shipmentId: string;
  event: Event;
  isEditComment: boolean;
  commentFormField = new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z0-9\,\.\:\;\'\#\&\@\?\\-\s]+$/)]);
  @ViewChild('comment') comment: ElementRef;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<ShipmentAddCommentActionSheetComponent>,
    private readonly bottomSheet: MatBottomSheet,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly shipmentUtilsService: ShipmentUtilsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.shipment = data?.shipment;
    this.nextSheetActions = data?.nextSheetActions;
    this.totalActions = data?.totalActions ?? this.nextSheetActions?.length ?? 0;
    this.currentActionStep = data?.currentActionStep ?? 1;
    this.currentData = data?.currentData;
    this.callback = data?.callback;
    this.shipmentId = data?.shipmentId;
    this.event = data?.event;
    this.isEditComment = data?.isEditComment;
    this.commentFormField.setValue(data?.event?.comment ? data.event.comment : "");
  }

  onUpdate(comment: string): void {
    this.saving = true;

    if (!comment) {
      return;
    }

    let saveModel = this.currentData;
    if(this.isEditComment) {
      saveModel = {
        "events": [
          {
            id: this.event.id,
            comment: comment,
          }
        ]
      }
    } else if (!saveModel?.events?.[0]) {
      saveModel = {
        events: [
          {
            comment: comment,
            status: this.shipmentUtilsService.parseShipmentStatus(this.shipment.status)
          }
        ]
      } as Shipment;
    } else {
      saveModel.events[0].comment = comment;
    }

    this.saveShipmentComment(saveModel);
  }

  onSkipOrCancel(): void {
    if (this.totalActions) {
      this.goToNextStep(this.currentData);
    } else {
      this.bottomSheetRef.dismiss();
    }
  }

  private saveShipmentComment(saveModel: Shipment): void {
    if (!this.nextSheetActions?.length) {
      this.saveShipment(saveModel);
    } else {
      this.goToNextStep(saveModel);
    }
  }

  private saveShipment(saveModel: Shipment): void {
    this.shipmentsDataService
      .updateShipment(!this.isEditComment ? this.shipment.id : this.shipmentId, saveModel)
      .pipe(
        catchError(error => {
          this.saving = false;
          this.changeDetectorRef.detectChanges();
          throw error;
        })
      )
      .subscribe(() => {
        this.bottomSheetRef.dismiss();
        this.callback?.(this.commentFormField.value);
      });
  }

  private goToNextStep(saveModel: Shipment): void {
    const nextActionSheetComponent = this.nextSheetActions.shift() as ComponentType<any>;
    this.bottomSheet.open(nextActionSheetComponent, {
      data: {
        shipment: this.shipment,
        currentData: saveModel,
        totalActions: this.totalActions,
        currentActionStep: this.currentActionStep + 1,
        nextSheetActions: this.nextSheetActions,
        callback: this.callback
      }
    });
  }
}
