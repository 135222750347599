<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground birth-location">
  <mat-error *ngIf="errorMessage" class="error padding-left-md">
    {{ errorMessage }}
  </mat-error>
  <form [formGroup]="birthForm">
    <div class="header-navigation-back">
      <mat-icon (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
      <span fxFlex class="title">Birth Location</span>
    </div>
    <div class="birth-container" data-test="birth-container">
      <div>
        <mat-form-field class="full-width">
          <mat-label>Country Of Birth</mat-label>
          <mat-select
            formControlName="birthCountry"
            (selectionChange)="onCountryChange($event.value)"
            required
            data-test="birth-country"
          >
            <mat-form-field class="dropdown-search full-width" appearance="outline">
              <input
                matInput
                placeholder="Search..."
                (keydown)="$event.stopPropagation()"
                (keyup)="onCertnCountrySearch($event)"
              />
            </mat-form-field>

            <mat-option *ngFor="let country of countries" [value]="country.value" data-test="country-name">
              {{ country.key }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="birthForm.get('birthCountry').touched && birthForm.get('birthCountry').invalid"
            data-test="birth-error"
          >
            {{ requiredMessage }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field class="full-width">
          <mat-label>Birth State/Province</mat-label>
          <mat-select formControlName="birthProvince" required data-test="birth-province">
            <mat-option *ngFor="let province of stateProvinces" [value]="province.value" data-test="province-name">
              {{ province.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              birthForm.get('birthProvince').touched && birthForm.get('birthProvince').invalid && enableProvinceButton
            "
          >
            {{ requiredMessage }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="btn-container">
        <button
          mat-raised-button
          type="button"
          class="next-button full-width"
          (click)="onSubmit()"
          data-test="next-button"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</div>
