import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identity-check',
  templateUrl: './identity-check.component.html',
  styleUrls: ['./identity-check.component.scss'],
  host: {
    class: 'identityCheck'
  }
})
export class IdentityCheckComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
