import { Injectable } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { CustomFormValidatorsModule } from './custom-form-validators.module';
import dayjs from 'dayjs';

@Injectable({
  providedIn: CustomFormValidatorsModule
})
export class DobAgeValidator {
  validate(dateField: AbstractControl): { [s: string]: boolean } {
    let result: any;
    if (!dateField) {
      result = null;
    } else if (dayjs().diff(dateField.value, 'year') < 18) {
      result = { invalidAge: true };
    }

    return result;
  }
}
