import { Component, OnInit } from '@angular/core';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor(private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onNavigateToSignIn(){
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

}
