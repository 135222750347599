import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InformationDialogService } from 'src/app/shared/dialog/information-dialog.service';
import { ROUTE_SIGNIN } from '../authentication.module';
import { Action } from 'src/app/shared/actions/models/action.model';
import { InformationDialogComponent } from 'src/app/shared/dialog/information-dialog/information-dialog.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {
  copyrightYear: number;
  fleetOperateUrl: string = 'https://fleetoperate.com/';
  dialogMessage: string = this.sanitizer.bypassSecurityTrustHtml(
    `<p>Please send us an email at</p>
     <a href=\"mailto:getonboard@fleetoperate.com\">getonboard@fleetoperate.com</a>`
  ) as string;
  driverUrl: string = '';

  constructor(
    private readonly sanitizer: DomSanitizer,
    @Inject('environmentData') public environment: any,
    private readonly informationDialogService: InformationDialogService
  ) {
    this.driverUrl = `${this.environment.driverUrl}${ROUTE_SIGNIN}`;
  }

  ngOnInit(): void {
    this.copyrightYear = new Date().getFullYear();
  }

  onOpenContactUsDialog(): void {
    const action = this.getAction('Close');

    const dialogRef = this.informationDialogService.openInformationDialog(
      'Need Help?',
      null,
      null,
      action,
      this.dialogMessage,
      '',
      'modern'
    );
    action.action = () => this.closeDialog(dialogRef);
  }

  private getAction(buttonName: string): Action {
    return {
      name: buttonName,
      action: (data: any) => {}
    } as Action;
  }

  private closeDialog(dialogRef: MatDialogRef<InformationDialogComponent>): any {
    dialogRef.close();
  }
}
