<div fxLayout fxLayoutAlign="center center">
  <span *ngIf="offerTypeName && !isTrip" class="offer-type-name {{ offerType }} margin-right-xs">{{
    offerTypeName
  }}</span>
  <span *ngIf="isTrip" class="offer-type-name {{ offerTypeName }} margin-right-xs">{{ offerTypeName | titlecase}}</span>
  <span *ngIf="offer" class="offer-label">{{ offer }}</span>
  <span *ngIf="!offer" class="offer-label">N/A</span>
</div>
<span *ngIf="secondaryOffer" fxFlexAlign="end" class="secondary-offer-label secondary-driver-quote"
  >${{ secondaryOffer }}</span
>
