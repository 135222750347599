<div fxLayout="row" fxLayoutAlign="space-between" class="margin-top-md">
  <span class="shipperLabel">{{ companyName | titlecase }}</span>
  <span>{{ shipment.shipmentID }}</span>
</div>
<mat-divider></mat-divider>
<div fxLayout="row" fxLayoutAlign="space-between" class="margin-top-md margin-right-md ">
  <div fxLayout="column">
    <span class="label">Phone</span>
    <span class="value">{{ companyDetails?.phoneNumber || '-' }} </span>
  </div>
  <div fxLayout="column">
    <span class="label">Email</span>
    <span class="value">{{ companyDetails?.email || '-' }}</span>
  </div>
</div>

<span class="shipperLabel margin-top-lg ">Pickup</span>
<mat-divider></mat-divider>

<div fxLayout="row" class="margin-top-md margin-right-md " fxLayoutAlign="space-between">
  <div fxLayout="column">
    <span class="label">Business name</span>
    <span class="value">{{ shipment?.pickup?.businessName | titlecase }}</span>
  </div>
  <div fxLayout="column">
    <span class="label">contact name</span>
    <span class="value">{{ shipment?.pickup?.contactName | titlecase }}</span>
  </div>
</div>
<div fxLayout="column" class="margin-top-md margin-right-md  ">
  <span class="label">Address</span>
  <span class="value">
    <span *ngIf="shipment?.pickup?.streetAddress">{{ shipment?.pickup?.streetAddress | titlecase }}</span>
    <span *ngIf="shipment?.pickup?.city">, {{ shipment?.pickup?.city | titlecase }}</span>
    <span *ngIf="shipment?.pickup?.state">, {{ shipment?.pickup?.state | uppercase }}</span>
    <span *ngIf="shipment?.pickup?.zipcode">, {{ shipment?.pickup?.zipcode | uppercase }}</span>
  </span>
</div>
<div fxLayout="row" class="margin-top-md margin-right-md  " fxLayoutAlign="space-between">
  <div fxLayout="column">
    <span class="label">Phone</span>
    <span class="value">{{ shipment?.pickup?.contactPhone }}</span>
  </div>
  <div fxLayout="column">
    <span class="label">Email</span>
    <span class="value ">{{ shipment?.pickup?.contactEmail }}</span>
  </div>
</div>

<span class="shipperLabel margin-top-lg  ">Delivery</span>
<mat-divider></mat-divider>

<div fxLayout="row" class="margin-top-md margin-right-md  " fxLayoutAlign="space-between">
  <div fxLayout="column">
    <span class="label">Business name</span>
    <span class="value">{{ shipment?.delivery?.businessName | titlecase }}</span>
  </div>
  <div fxLayout="column">
    <span class="label">contact name</span>
    <span class="value ">{{ shipment?.delivery?.contactName | titlecase }}</span>
  </div>
</div>
<div fxLayout="column" class="margin-top-md margin-right-md  ">
  <span class="label">Address</span>
  <span class=" value ">
    <span *ngIf="shipment?.delivery?.streetAddress">{{ shipment?.delivery?.streetAddress | titlecase }}</span>
    <span *ngIf="shipment?.delivery?.city">, {{ shipment?.delivery?.city | titlecase }}</span>
    <span *ngIf="shipment?.delivery?.state">, {{ shipment?.delivery?.state | uppercase }}</span>
    <span *ngIf="shipment?.delivery?.zipcode">, {{ shipment?.delivery?.zipcode | uppercase }}</span>
  </span>
</div>
<div fxLayout="row" class="margin-top-md margin-right-md  " fxLayoutAlign="space-between">
  <div fxLayout="column">
    <span class="label">Phone</span>
    <span class="value">{{ shipment?.delivery?.contactPhone }}</span>
  </div>
  <div fxLayout="column">
    <span class="label">Email</span>
    <span class="value ">{{ shipment?.delivery?.contactEmail }}</span>
  </div>
</div>
