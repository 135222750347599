import { Component, OnInit, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { catchError, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { SignInModel } from '../shared/sign-in-model';
import { ROUTE_SIGNUP } from '../shared/routes';
import { AuthenticationService } from '../shared/authentication.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Response } from 'src/app/shared/services/response';
import { ROUTE_FORGOT_PASSWORD } from 'src/app/forgot-password/shared/routes';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ROUTE_HELP } from 'src/app/help/shared/routes';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { of } from 'rxjs';

const ACTION_SIGN_IN = 'Sign in';
const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';
const MinimumLengthFieldMessage = (value: number) => `Must be at least ${value} characters`;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  hidePassword: boolean;
  loading: boolean;
  errorMessage: string;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly authenticationApi: AuthenticationService,
    private readonly logger: LoggerService,
    private readonly elementRef: ElementRef,
    private readonly timeService: DateTimeService,
    private readonly recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.hidePassword = true;
    this.loading = false;
    this.errorMessage = undefined;

    this.form = this.createForm();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#0B184E';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor = '#fff';
  }

  onSubmit(signinForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.subs.add(
      this.recaptchaV3Service
        .execute('login')
        .pipe(
          switchMap(token => {
            return this.authenticationApi.login(model, token);
          }),
          catchError((error: any) => {
            this.errorMessage = error?.message;
            this.loading = false;

            return of(false);
          })
        )
        .subscribe()
    );
  }

  onNavigateToSignUp(): void {
    this.router.navigate([`/${ROUTE_SIGNUP}`]);
  }

  onNavigateToForgotPassword(): void {
    this.router.navigate([`/${ROUTE_FORGOT_PASSWORD}`]);
  }

  onNavigateToHelp(): void {
    this.router.navigate([`/${ROUTE_HELP}`]);
  }

  get email() {
    return this.form.get('email');
  }
  get password() {
    return this.form.get('password');
  }

  getEmailErrorMessage() {
    const errors = this.email.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? InvalidEmailAddress : '';
  }

  getPasswordErrorMessage() {
    const errors = this.password.errors;
    return errors.required
      ? RequiredFieldMessage
      : errors.minlength
      ? MinimumLengthFieldMessage(errors.minlength.requiredLength)
      : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)((\\.[A-Za-z]{2,}){0,1})\\s*$')
        ]
      ],
      password: ['', [Validators.required, Validators.minLength(8)]]
    });

    return form;
  }

  private prepareSaveModel(): SignInModel {
    const formModel = this.form.value;

    const model = {
      username: (formModel.email as string).trim(),
      password: formModel.password as string
    };

    return model as SignInModel;
  }
}
