import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-emp-residence-history',
  templateUrl: './skeleton-emp-residence-history.component.html',
  styleUrls: ['./skeleton-emp-residence-history.component.scss']
})
export class SkeletonEmpResidenceHistoryComponent implements OnInit {
  @Input() isResidenceHistory: boolean;
  constructor() {}

  ngOnInit(): void {}
}
