import { State, Action, StateContext } from '@ngxs/store';

export interface AppStateModel {
}

@State<AppStateModel>({
    name: 'app',
    defaults: {
    }
})
export class AppState {

}
