import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { FleetoperateApiService } from 'src/app/shared/services/fleetoperate-api.service';
import { Response } from 'src/app/shared/services/response';
import { Email } from '../../model/email.model';

const PATH_UNSUBSCRIBE_EMAIL = '/emails/unsubscribe';

@Injectable()
export class UnsubscribeEmailApiService {
  constructor(private readonly api: FleetoperateApiService, @Inject('apiUrl') private apiUrl: string) {}

  updateEmailToUnsubscribe(model: Email): Observable<Response> {
    const path = PATH_UNSUBSCRIBE_EMAIL;
    return this.api.post(`${this.apiUrl}${path}`, model);
  }
}
