import { Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ROUTE_RESET_PASSWORD } from 'src/app/reset-password/shared/routes';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {

  constructor(private readonly router: Router) { }

  ngOnInit() {
  }

  onNavigateToResetPassword(): void {
    this.router.navigate([`/${ROUTE_RESET_PASSWORD}`]);
  }

  // onNavigateToPreferences() {
  //   this.registrationService.navigateToPreferences(this.activatedRoute);
  // }
}
