import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';

import { OnboardingComponent } from './onboarding/onboarding.component';
import { OnboardingRoutingModule } from './onboarding-routing.module';

@NgModule({
  declarations: [OnboardingComponent],
  imports: [CommonModule, FlexLayoutModule, OnboardingRoutingModule, MatIconModule]
})
export class OnboardingModule {}

export { OnboardingComponent } from './onboarding/onboarding.component';
