<div class="skeleton-container">
  <div class="added-file">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        height: '40px',
        'background-color': '#c9c9c963'
      }"
    ></ngx-skeleton-loader>
  </div>

  <div>
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        height: '40px',
        'background-color': '#c9c9c963'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
