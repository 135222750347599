<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5" data-test="back-arrow-icon">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">PRE EMPLOYMENT REPORT</div>
  </div>
  <div class="content" fxLayout="column" *ngIf="!isContentReady">
    <app-dqf-document-skeleton></app-dqf-document-skeleton>
  </div>
  <div class="content" fxLayout="column" [ngClass]="{ 'hide-content': !isContentReady }">
    <app-upload-document
      [formUploadFiles]="formUploadFiles"
      [documentDqfStatus]="documentStatus"
      (uploadDocument)="uploadDocument()"
      (deleteDocument)="deleteDocument()"
      (requestCarrier)="requestCarrierToUploadDocument()"
    ></app-upload-document>
  </div>
</div>
