import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { LoggerService } from 'src/app/core/services/logger.service';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ContactDetails } from 'src/app/shared/models/contact-details';
import { CARRIER_URI, DISPATCHER_URI, SHIPPER_URI } from 'src/app/shared/constants';
import { CommonDataService } from 'src/app/shared/services/common/common-data.service';
import { Company } from '../shipment-data/models/company';

@Component({
  selector: 'app-shipment-contact-details',
  templateUrl: './shipment-contact-details.component.html',
  styleUrls: ['./shipment-contact-details.component.scss']
})
export class ShipmentContactDetailsComponent implements OnInit, OnChanges {
  @Input() shipment: Shipment;
  companyName: string;
  companyDetails = {} as Company
  constructor(
    private commonDataService: CommonDataService,
    private loggerService: LoggerService
  ) {
    this.shipment = {} as Shipment;
    this.companyName = '';
  }

  ngOnChanges(): void {
    this.shipment = this.shipment || ({} as Shipment);
    const type = this.shipment.shipmentID?.substring(0, 2);
    if (this.shipment?.companyUserId)
      this.getUserDetails(this.shipment?.companyUserId, this.shipment.shipmentID)

  }

  private getUserDetails(companyUserId: string, shipmentId) {
    const type = shipmentId?.substring(0, 3)
    const uri = type === 'FTO' ? CARRIER_URI : type === 'FDO' ? DISPATCHER_URI : SHIPPER_URI;
    this.commonDataService
      .getUserById(companyUserId, uri)
      .pipe(first(),
        map((carrier: ContactDetails) => {
          if (carrier) {
            this.companyDetails.email = carrier.email;
            this.companyDetails.phoneNumber = carrier.phoneNumber;
            this.companyName = carrier.companyName;
          }
        }),
        catchError(e => {
          this.loggerService.error(e);
          return e;
        })
      )
      .subscribe();
  }

  ngOnInit() { }
}
