import { Component, OnInit, OnDestroy, Inject, forwardRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first, catchError } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { SubSink } from 'subsink';
import { ShipmentDocumentsService } from '../shipment-data/shipment-documents.service';
import { ROUTE_TOKEN_SHIPMENT_DETAILS, ROUTE_TOKEN_SHIPMENT_DOCUMENT } from '../route-tokens';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';

const VIEW_TYPE_DOCUMENT_LIST = 'document-list-view';
const VIEW_TYPE_FILE_UPLOAD = 'file-upload-view';
const ACTION_UPLOAD_DOCUMENT = 'Upload document';

@Component({
  selector: 'app-shipment-documents',
  templateUrl: './shipment-documents.component.html',
  styleUrls: ['./shipment-documents.component.scss']
})
export class ShipmentDocumentsComponent implements OnInit, OnDestroy {
  errorMessage: string;
  shipmentId: string;
  viewType: string;
  loading: boolean;
  shipmentDocuments: ShipmentDocument[];
  driverHistory: boolean;

  file: any;
  resetFileSelection$: Observable<boolean>;

  private resetFileSelectionSubject: Subject<boolean>;
  private subSink: SubSink;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly shipmentDocumentsService: ShipmentDocumentsService,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService,
    @Inject(forwardRef(() => ROUTE_TOKEN_SHIPMENT_DETAILS)) public routeShipmentDetails: string,
    @Inject(forwardRef(() => ROUTE_TOKEN_SHIPMENT_DOCUMENT)) public routeShipmentDocument: string
  ) {
    this.subSink = new SubSink();
    this.shipmentDocuments = [];
    this.viewType = VIEW_TYPE_DOCUMENT_LIST;
    this.resetFileSelectionSubject = new Subject<boolean>();
    this.resetFileSelection$ = this.resetFileSelectionSubject.asObservable();
    this.loading = false;
  }

  ngOnInit() {
    this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
    if (this.router.url.includes('driverHistory')) {
      this.driverHistory = true;
    }
    this.subSink.add(
      this.shipmentDocumentsService.$shipmentDocuments.subscribe((shipmentDocuments: ShipmentDocument[]) => {
        if (shipmentDocuments) {
          shipmentDocuments.sort((shipmentDocumentA: ShipmentDocument, shipmentDocumentB: ShipmentDocument) => {
            const dateA = this.timeService.getFormattedDate(shipmentDocumentA.createdDate);
            const dateB = this.timeService.getFormattedDate(shipmentDocumentB.createdDate);
            return dateB.getTime() - dateA.getTime();
          });
          shipmentDocuments.forEach((shipmentDocument: ShipmentDocument) => {
            shipmentDocument.createdDate = this.timeService.getConvertedDateTimeFromUTC(shipmentDocument.createdDate);
          });
          this.shipmentDocuments = shipmentDocuments;
          this.loading = false;
        }
      })
    );

    if (this.shipmentId) {
      this.loadShipmentDocuments(this.shipmentId);
    }
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onAddNewDocument(): void {
    this.viewType = VIEW_TYPE_FILE_UPLOAD;
  }

  onCancelNewDocument(): void {
    this.viewType = VIEW_TYPE_DOCUMENT_LIST;
    this.resetNewDocument();
  }

  onUploadNewDocument(): void {
    this.uploadDocument(this.file, this.file.name, 'ProofOfDelivery');
  }

  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.file = file;
    };
  }

  onNavigateBack(): void {
    this.router.navigate([this.routeShipmentDetails, this.shipmentId]);
  }

  onNavigateToShipmentDocument(documentId: string): void {
    this.router.navigate([this.routeShipmentDocument, this.shipmentId, documentId]);
  }

  private loadShipmentDocuments(shipmentId: string): void {
    this.loading = true;
    this.shipmentDocumentsService.loadShipmentDocuments(shipmentId);
  }

  private resetNewDocument(): void {
    this.resetFileSelectionSubject.next(true);
    this.file = undefined;
  }

  private uploadDocument(file: any, fileName: string, documentType: string): void {
    this.loading = true;
    this.errorMessage = undefined;
    const extension = fileName
      .split('.')
      .pop()
      .toLowerCase();

    this.shipmentDocumentsService
      .sendDocument(this.shipmentId, file, documentType, extension)
      .pipe(
        first(),
        catchError((error: any) => {
          this.loading = false;
          return of(false);
        })
      )
      .subscribe(() => {
        this.resetNewDocument();
        this.viewType = VIEW_TYPE_DOCUMENT_LIST;
        this.loading = false;
        this.loadShipmentDocuments(this.shipmentId);
      });
  }
}
