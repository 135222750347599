import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-privacy-statement',
  templateUrl: './privacy-statement.component.html',
  styleUrls: ['./privacy-statement.component.scss']
})
export class PrivacyStatementComponent implements OnInit {
  private history: string[] = [];

  constructor(private readonly router: Router, private readonly location: Location) {
    if (this.router.events) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects);
        }
      });
    }
  }

  ngOnInit() {}

  onNavigateBack(): void {
    this.location.back();
  }
}
