export const CARRIER_URI = 'carriers';
export const DISPATCHER_URI = 'dispatchers';
export const SHIPPER_URI = 'shippers';
export const TRIP_TYPE = 'FTO';
export const DISPATCHER_TYPE = 'FDO';
export const SHIPPER_TYPE = 'FPO';
export const CARRIER = 'carrier';
export const SHIPPER = 'shipper';
export const DISPATCHER = 'dispatcher';

export const REQUIRED_MESSAGE = 'You must enter a value';
//dqf status
export const CARRIER_UPLOAD_REQUESTED = 'CarrierUploadRequested';
