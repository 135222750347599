import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Action } from 'src/app/shared/actions/models/action.model';

@Component({
  selector: 'app-identity-action-sheet',
  templateUrl: './identity-action-sheet.component.html',
  styleUrls: ['./identity-action-sheet.component.scss']
})
export class IdentityActionSheetComponent implements OnInit {
  actions: Action[];

  constructor(
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly bottomSheetRef: MatBottomSheetRef<IdentityActionSheetComponent>
  ) {
    this.actions = [];
  }

  ngOnInit() {
    const logOutAction = this.createLogoutAction();
    if (logOutAction) {
      this.actions.push(logOutAction);
    }
    this.addNoActionMessage();
  }
  onOpenLink(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }
  private createLogoutAction(): Action {
    return {
      name: 'Logout',
      action: (data: any) => this.logout(),
      disabled: false
    } as Action;
  }

  private logout(): void {
    this.registrationService.clearDriver();
    this.registrationService.clearRegistration();
    this.router.navigate([ROUTE_SIGNIN]);
  }

  private addNoActionMessage(): void {
    if (!this.actions || this.actions.length <= 0) {
      this.actions.push({
        name: 'No actions available',
        action: (date: any) => {},
        disabled: true
      } as Action);
    }
  }
}
