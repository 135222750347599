import {
  RegistrationKYCVerificationDataStateModel,
  DocumentFrontImageModel,
  DocumentBackImageModel,
  DocumentSelfieImageModel,
  DriverStateModel,
  LicenseStateModel
} from './registration.state';
import { Education } from 'src/app/shared/models/education.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { ProfilePicture } from 'src/app/shared/models/profile-picture.model';
import { Notification } from 'src/app/shared/models/notification.model';
import { DispatcherContact } from 'src/app/shared/models/dispatcher-contact.model';
import { WorkPlaceSafety } from 'src/app/shared/models/workplacesafety.model';

export class ClearRegistration {
  static readonly type = '[Registration] ClearRegistration';
  constructor() {}
}

export class SetDriver {
  static readonly type = '[Registration] SetDriver';
  constructor(public payload: DriverStateModel) {}
}

export class UpdateDriver {
  static readonly type = '[Registration] UpdateDriver';
  constructor(public payload: DriverStateModel) {}
}

export class ClearDriver {
  static readonly type = '[Registration] ClearDriver';
  constructor() {}
}
export class SetLicense {
  static readonly type = '[Registration] SetLicense';
  constructor(public payload: LicenseStateModel) {}
}
export class UpdateLicense {
  static readonly type = '[Registration] UpdateLicese';
  constructor(public payload: LicenseStateModel) {}
}
export class ClearLicense {
  static readonly type = '[Registration] ClearLicense';
  constructor() {}
}

export class SaveDrivingExperience {
  static readonly type = '[Registration] SaveDrivingExperience';
  constructor(public payload: DrivingExperience) {}
}

export class SavePreferences {
  static readonly type = '[Registration] SavePreferences';
  constructor(public payload: Preferences) {}
}

export class SetProfilePicture {
  static readonly type = '[Registration] SetProfilePicture';
  constructor(public payload: ProfilePicture) {}
}

export class SetEducation {
  static readonly type = '[Registration] SetEducation';
  constructor(public payload: Education) {}
}

export class SetWorkPlaceSafety {
  static readonly type = '[Registration] SetWorkPlaceSafety';
  constructor(public payload: WorkPlaceSafety) {}
}

export class SetDispatcherContact {
  static readonly type = '[Registration] SetDispatcherContact';
  constructor(public payload: DispatcherContact) {}
}

export class SetPreferences {
  static readonly type = '[Registration] SetPreferences';
  constructor(public payload: Preferences) {}
}

export class SetDrivingExperience {
  static readonly type = '[Registration] SetDrivingExperience';
  constructor(public payload: DrivingExperience) {}
}

export class SetNotification {
  static readonly type = '[Registration] SetNotification';
  constructor(public payload: Notification[]) {}
}
