<div fxFlexAlign="center" fxLayout="column" fxLayoutAlign="center center">
  <img class="logo padding-top-x4" src="/assets/img/fleetoperate_logo.jpg" width="300px" />
  <h2 class="center" *ngIf="!carrierCompanyName || isCancelled; else carrierCompany">Sign Up</h2>
  <ng-template class="center" #carrierCompany
    ><h2>Driver Sign Up for {{ carrierCompanyName }}</h2></ng-template
  >
</div>
<div class="padding-top-x2">
  <mat-error *ngIf="errorMessage" fxFlexAlign="center" class="margin-bottom-sm">{{ errorMessage }}</mat-error>
  <mat-label
    *ngIf="successMessage"
    fxFlexAlign="center"
    class="margin-bottom-sm"
    data-test="signup-successful-message"
    >{{ successMessage }}</mat-label
  >
  <form [formGroup]="form" #signupForm="ngForm" (ngSubmit)="onSubmit(signupForm)">
    <mat-form-field class="full-width">
      <input matInput formControlName="firstName" maxlength="20" data-test="firstname-form-field" />
      <mat-placeholder>First Name</mat-placeholder>
      <mat-error *ngIf="firstName.touched && firstName.invalid">
        {{ getFirstNameErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input matInput formControlName="lastName" maxlength="20" data-test="lastname-form-field" />
      <mat-placeholder>Last Name</mat-placeholder>
      <mat-error *ngIf="lastName.touched && lastName.invalid">
        {{ getLastNameErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input
        type="email"
        matInput
        formControlName="email"
        data-test="email-form-field"
        [readonly]="driverEmail && !isCancelled"
        autocomplete="off"
      />
      <mat-placeholder>Email</mat-placeholder>
      <mat-error *ngIf="email.touched && email.invalid">
        {{ getEmailErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-hint align="start">###-###-####</mat-hint>
      <input
        matInput
        appPhoneMask
        maxlength="12"
        formControlName="mobileNumber"
        data-test="contact-number-form-field"
      />
      <mat-placeholder>Mobile Number</mat-placeholder>
      <mat-error *ngIf="mobileNumber.touched && mobileNumber.invalid">
        {{ getMobileNumberErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="full-width">
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        data-test="password-form-field"
        autocomplete="off"
      />
      <mat-placeholder>Password</mat-placeholder>
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
        hidePassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
    </mat-form-field>
    <div class="password-text" *ngIf="(password.touched || password.dirty) && password.invalid">
      Your password must contain at least:
      <ul>
        <li
          [ngClass]="
            password.hasError('hasLength') || password.errors?.required || loading ? 'text-error' : 'text-good'
          "
        >
          8 characters
        </li>
        <li
          [ngClass]="password.hasError('hasUpper') || password.errors?.required || loading ? 'text-error' : 'text-good'"
        >
          1 uppercase letter
        </li>
        <li
          [ngClass]="password.hasError('hasLower') || password.errors?.required || loading ? 'text-error' : 'text-good'"
        >
          1 lowercase letter
        </li>
        <li
          [ngClass]="
            password.hasError('hasNumber') || password.errors?.required || loading ? 'text-error' : 'text-good'
          "
        >
          1 number
        </li>
        <li
          [ngClass]="
            password.hasError('hasSpecial') || password.errors?.required || loading ? 'text-error' : 'text-good'
          "
        >
          1 special character (! @ # $ % ^ & *)
        </li>
        <li
          *ngIf="password.hasError('invalidCharacters')"
          [ngClass]="password.hasError('invalidCharacters') ? 'text-error' : 'text-good'"
        >
          No special characters are allowed other than ! @ # $ % ^ & *
        </li>
      </ul>
    </div>
    <div class="align-self-center padding-top-md">
      By clicking Sign up, you agree to our
      <a [routerLink]="" (click)="onNavigateToTermsConditions()">Terms and Conditions</a> and
      <a [routerLink]="" (click)="onNavigateToPrivacyStatement()">Privacy Statement</a>.
    </div>
    <div class="full-width padding-top-sm">
      <button
        class="full-width margin-bottom"
        mat-raised-button
        color="primary"
        type="submit"
        data-test="signup-button"
        [disabled]="loading || !form.valid"
      >
        Sign up
      </button>
    </div>
  </form>
  <div class="center padding-top-md">
    Already have an account? <a [routerLink]="" (click)="onNavigateToSignIn()">Sign in</a>
  </div>
  <div class="recaptcha-terms align-self-center padding-top-xs">
    This site is protected by reCAPTCHA and the Google
    <a href="https://policies.google.com/privacy" class="recaptcha-terms-links">Privacy Policy</a> and
    <a href="https://policies.google.com/terms" class="recaptcha-terms-links">Terms of Service</a> apply.
  </div>
</div>
