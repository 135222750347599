<div>
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <ng-container>
    <div class="title margin-top-lg">
      {{ data.title }}
    </div>
    <div class="options margin-top-md">
      <div
        *ngFor="let option of data.options"
        class="margin-bottom-md"
        data-test="upload-options"
        (click)="changeChosenValue(option.name)"
      >
        <span [ngClass]="chosenValue !== option.name ? 'text-fade' : ''" fxLayout="row" fxLayoutAlign=" center"
          ><mat-icon [svgIcon]="option.icon" *ngIf="option.icon"></mat-icon>&nbsp;&nbsp;{{ option.displayName }}</span
        >
        <mat-icon svgIcon="tick-blue" *ngIf="chosenValue === option.name"></mat-icon>
      </div>
    </div>
  </ng-container>
</div>
