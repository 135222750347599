import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-complete-profile-sheet',
  templateUrl: './complete-profile-sheet.component.html',
  styleUrls: ['./complete-profile-sheet.component.scss']
})
export class CompleteProfileSheetComponent implements OnInit {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public dialogRef: MatBottomSheetRef<CompleteProfileSheetComponent>
  ) {}

  ngOnInit(): void {}
  closeDialog() {
    this.dialogRef.dismiss();
  }
}
