<div class="content" mat-dialog-content data-test="feedback-form">
  <div class="step-back" [ngClass]="{ disabled: feedbackStep === 1 }">
    <mat-icon (click)="stepBack()">arrow_back</mat-icon>
  </div>
  <div class="feedback-steps">
    <span [ngClass]="{ current: feedbackStep === 1, passed: feedbackStep > 1 }"></span>
    <hr />
    <span [ngClass]="{ current: feedbackStep === 2, passed: feedbackStep > 2 }"></span>
    <hr />
    <span [ngClass]="{ current: feedbackStep === 3, passed: feedbackStep > 3 }"></span>
    <hr />
    <span [ngClass]="{ current: feedbackStep === 4, passed: feedbackStep > 4 }"></span>
  </div>

  <!-- Feedback Steps -->
  <div *ngIf="feedbackStep === 1">
    <div class="icon-bg grey">
      <mat-icon svgIcon="distance"></mat-icon>
    </div>
    <h2>Does this freight lane make sense to you?</h2>
  </div>

  <div *ngIf="feedbackStep === 2">
    <div class="icon-bg grey">
      <mat-icon svgIcon="weight-load"></mat-icon>
    </div>
    <h2>Was the load weight appropriate for the vehicle?</h2>
  </div>

  <div *ngIf="feedbackStep === 3">
    <div class="icon-bg grey">
      <mat-icon svgIcon="personnel"></mat-icon>
    </div>
    <h2>Were the personnel that you had to deal with helpful?</h2>
  </div>

  <div *ngIf="feedbackStep === 4">
    <div class="icon-bg grey">
      <mat-icon svgIcon="software"></mat-icon>
    </div>
    <h2>Did the software perform up to your expectation?</h2>
  </div>

  <div *ngIf="feedbackStep === 5">
    <h2 class="wide">If there is something else you would like to share, please put a comment below.</h2>
    <mat-form-field class="full-width" appearance="outline">
      <textarea 
        matInput 
        [formControl]="commentFormField" 
        maxlength="120" 
        placeholder="Type here..." 
        data-test="comment-form"
      >
      </textarea>
      <mat-error *ngIf="commentFormField.invalid">
        Only these special characters are allowed: <span>, ; ( ) & @ $ - / . ?</span>
      </mat-error>
    </mat-form-field>
  </div>
  <!-- End of Feedback Steps -->

  <!-- Feedback Actions -->
  <div class="feedback-actions" *ngIf="feedbackStep < 5">
    <button class="approve" (click)="approveStep()" data-test="approve-button">
        <mat-icon svgIcon="thumb-up"></mat-icon>
    </button>
    <button class="disapprove" (click)="disapproveStep()" data-test="disapprove-button">
      <mat-icon svgIcon="thumb-down"></mat-icon>
    </button>
  </div>

  <!-- Feedback Submit -->
  <div class="feedback-submit" *ngIf="feedbackStep === 5">
    <button
      mat-button
      class="btn-accept"
      (click)="submitFeedback(commentFormField.value)"
      [disabled]="!commentFormField.valid || saving"
      data-test="submit-button"
    >
      Submit
    </button>
  </div>
</div>
