import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SignUpCompletedComponent } from './sign-up-completed/sign-up-completed.component';
import { SignUpErrorComponent } from './sign-up-error/sign-up-error.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { PhoneMaskModule } from '../phone-mask/phone-mask.module';
import { MatRadioModule } from '@angular/material/radio';
import { SignupSuccessComponent } from './signup-success/signup-success.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AccountLockedComponent } from './account-locked/account-locked.component';
import { MaterialModule } from '../shared/material/material.module';
import { InformationDialogModule } from '../shared/dialog/information-dialog/information-dialog.module';
import { SinMaskModule } from '../sin-mask/sin-mask.module';
import { LoginErrorComponent } from './login-error/login-error.component';
@NgModule({
  declarations: [
    AuthenticationComponent,
    SignUpComponent,
    SignInComponent,
    SignUpCompletedComponent,
    SignUpErrorComponent,
    TermsConditionsComponent,
    PrivacyStatementComponent,
    SignupSuccessComponent,
    AccountLockedComponent,
    LoginErrorComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatButtonModule,
    PhoneMaskModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    SharedModule,
    AuthenticationRoutingModule,
    MatRadioModule,
    RecaptchaV3Module,
    InformationDialogModule,
    SinMaskModule
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey }],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthenticationModule {}

export { AuthenticationComponent } from './authentication/authentication.component';
export { ROUTE_SIGNIN, ROUTE_SIGNUP } from './shared/routes';
