<div fxLayoutAlign="start" fxLayout="column">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateBack()">arrow_back</mat-icon>
    <span fxFlex class="title">Privacy Statement</span>
  </div>

  <div fxLayout="column" fxLayoutAlign="center stretch">
    <div fxFlex fxLayout="column" class="padding-left-md padding-right-md">
      <span fxFlexAlign="center"></span>
      <p>
        Last Updated: February 10, 2021
      </p>
      <p>
        <strong>
          THIS PRIVACY POLICY SETS OUT THE MANNER IN WHICH FLEETOPERATE INC. MANAGES PERSONAL INFORMATION AND FORMS PART
          OF THE FLEETOPERATE TERMS OF SERVICE. IN ORDER TO USE THE FLEETOPERATE SERVICES, OUR WEBSITE, MOBILE
          APPLICATION AND/OR CONTENT, YOU MUST HAVE READ, UNDERSTOOD AND AGREED TO THE TERMS OF THIS PRIVACY POLICY. IF
          YOU DO NOT AGREE, YOU MAY MAKE NO FURTHER USE OF FLEETOPERATE SERVICES, OUR WEBSITE, MOBILE APPLICATION OR
          CONTENT.
        </strong>
      </p>
      <h1>
        <strong>
          Welcome To FLEETOPERATE!
        </strong>
      </h1>
      <h3>
        The On-demand Driver Marketplace
      </h3>
      <p>
        At FLEETOPERATE Inc., we take your right to privacy very seriously. We have established this Privacy Policy to
        set out the policies we follow in handling personal information that is acquired by us or provided to us in the
        course of operating our business.
      </p>
      <ol>
        <li>
          <p>
            Personal Information
          </p>
          <p>
            This Privacy Policy applies to personal information held by FLEETOPERATE Inc. “Personal Information” is
            defined by applicable law to mean “information about an identifiable individual”.
          </p>
          <p>
            Personal information does not include business contact information of business representatives who subscribe
            to our services or where such information was provided to us for the purposes of facilitating communication
            with that person in their professional capacity.
          </p>
        </li>
        <li>
          <p>
            Collection
          </p>

          At FLEETOPERATE Inc., we only collect personal information required to:
          <ol type="i">
            <li>establish your account,</li>
            <li>to facilitate requested services,</li>
            <li>to process any complaints,</li>
            <li>to carry out our administrative duties,</li>
            <li>to improve your use of our services, website and/or mobile application,</li>
            <li>
              to fulfill the purposes for which you provided the information or that were described when it was
              collected, or any other purpose for which you provide it,
            </li>
            <li>
              to carry out our obligations and enforce our rights arising from any contracts with you, including for
              billing and collection or to comply with legal requirements,
            </li>
            <li>to notify you about changes to our services,</li>
            <li>
              to measure or understand the effectiveness of the advertising we serve to you and others, and to deliver
              relevant advertising to you,
            </li>
            <li>
              or any other purpose, with your consent. This personal information is collected when you submit it to us
              or when you otherwise interact with our services.
            </li>
          </ol>
          As you navigate through and interact with our website, we may use cookies or other automatic data collection
          technologies to collect certain information about your equipment, browsing actions, and patterns, including:
          <ol type="i">
            <li>
              details of your visits to our website, including traffic data, location data and other communication data
              and the resources that you access and use on the website; and
            </li>
            <li>
              information about your computer and internet connection, including your IP address, operating system, and
              browser type.
            </li>
          </ol>
          <p>
            We may also use these technologies to collect information about your online activities over time and across
            third-party websites or other online services (referred to as behavioral tracking). To learn more or to
            opt-out of tailored advertising please visit the Google Advertising opt-out page and the Digital Advertising
            Alliance of Canada Opt-Out Tool for information on how you can opt out of behavioral tracking on this
            website and how we respond to web browser signals and other mechanisms that enable consumers to exercise
            choice about behavioral tracking.
          </p>
          <p>
            The information we collect automatically is statistical information and may include personal information,
            and we may maintain it or associate it with personal information we collect in other ways, that you provide
            to us, or receive from third parties. It helps us to improve our website and to deliver a better and more
            personalized service, including by enabling us to:
          </p>
          <ol type="i">
            <li>estimate our audience size and usage patterns;</li>
            <li>
              store information about your preferences, allowing us to customize our website according to your
              individual interests;
            </li>
            <li>
              speed up your searches;
            </li>
            <li>
              recognize you when you return to our website or app; and
            </li>
            <li>
              show our ads on external sites. We use traffic/analytics data to inform and optimize our site content and
              ad campaigns based on your prior visits to our website.
            </li>
          </ol>
          The technologies we use for this automatic data collection may include:
          <ol type="i">
            <li>
              Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may
              refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you
              select this setting you may be unable to access certain parts of our website. Unless you have adjusted
              your browser setting so that it will refuse cookies, our system will issue cookies when you direct your
              browser to our website.
            </li>
            <li>
              Web Beacons. Pages of our website may contain small electronic files known as web beacons (also referred
              to as clear gifs, pixel tags, and single-pixel gifs) that permit us to, for example, count users who have
              visited those pages or and for other related website statistics (for example, recording the popularity of
              certain website content and verifying system and server integrity).
            </li>
          </ol>
          <p>
            Some content or applications on the website, including advertisements, are served by third-parties,
            including advertisers, ad networks and servers, content providers, and application providers. These third
            parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect
            information about you when you use our website. The information they collect may be associated with your
            personal information or they may collect information, including personal information, about your online
            activities over time and across different websites and other online services. They may use this information
            to provide you with interest-based (behavioral) advertising or other targeted content.
          </p>
          <p>
            We do not control these third parties’ tracking technologies or how they are used. If you have any questions
            about an advertisement or other targeted content, you should contact the responsible provider directly. For
            more information about how you can opt out of receiving targeted advertising from many providers, see the
            “Choices About How We Use and Disclose Your Information” heading of this policy below.
          </p>
          <p>
            In order to submit a review on FLEETOPERATE, you may need to provide us with certain additional information,
            as indicated from time to time on our website or through our mobile application. This information will be
            used for the purposes of facilitating your review and allowing us to confirm, where necessary, your status
            as a participant in the subject Job.
          </p>
        </li>
        <li>
          <p>
            Use and Disclosure
          </p>
          <p>
            Personal information is used by FLEETOPERATE Inc. for the purpose for which it was requested, where required
            or permitted under applicable laws or as provided under this Privacy Policy.
          </p>
          <p>
            Also, in order to allow our users the ability to advertise Jobs and quote on Jobs, we allow other users to
            search for, and view, your profile information. You may also provide personal information, when you post a
            review. IF YOU DO NOT WANT OTHER FLEETOPERATE USERS TO VIEW YOUR PERSONAL INFORMATION, DO NOT ENTER THAT
            PERSONAL INFORMATION INTO YOUR PROFILE OR A REVIEW.
          </p>
        </li>
        <li>
          <p>
            Authorized User
          </p>
          <p>
            Authorized Users acknowledge and agree that their use of the FLEETOPERATE system and services will be
            facilitated through the use of a subscribing account holder who has authorized their use. The account holder
            can view all activity of the Authorized User at will through their account information and may terminate the
            Authorized User’s status as such at any time. Upon termination of status as an Authorized User, the
            Authorized User’s account may be terminated in due course by FLEETOPERATE.
          </p>
        </li>
        <li>
          <p>
            Safeguards
          </p>
          <p>
            FLEETOPERATE Inc. has in place security safeguards to help protect personal information against unauthorized
            access, use, modification and disclosure. We evaluate our security practices on a regular basis to best
            protect those that use our services.
          </p>
        </li>
        <li>
          <p>
            Access
          </p>
          <p>
            Upon request, an individual may request access to his or her personal information maintained by FLEETOPERATE
            Inc. Individuals are certainly able to challenge or confirm the accuracy or completeness of their
            information that we have on file.
          </p>
        </li>
        <li>
          <p>
            Modifications
          </p>
          <p>
            This Privacy Policy may be updated periodically and without prior notice to you, and any changes will be
            effective immediately upon the posting of the revised policy on the FLEETOPERATE website. It is your
            obligation to review the Privacy Policy found on our website to note any modifications.
          </p>
        </li>
        <li>
          <p>
            Links to Third Party websites
          </p>
          <p>
            The FLEETOPERATE website and mobile application may contain links to other websites or applications. We do
            not control or endorse any third-party websites. Your use of such links and any third-party websites or
            services is at your own risk and subject to third party terms of use and conditions.
          </p>
        </li>
        <li>
          <p>
            Choices about how we Use and Disclose your Information
          </p>
          <p>
            We strive to provide you with choices regarding the personal information you provide to us.
          </p>
          <p>
            You can opt-out of several third-party ad servers’ and networks’ cookies simultaneously by using opt-out
            tools created by Google Advertising, the Digital Advertising Alliance of Canada or by the Network
            Advertising Initiative. You may also opt out of our use of Google Analytics by visiting the Google Analytics
            opt-out page. We may have reporting of your aggregate interactions with our ads across the Google Display
            Network or DoubleClick for Advertisers in conjunction with activity on our website.
          </p>
          <p>
            You can also access these websites to learn more about online behavioral advertising and how to stop
            websites from placing cookies on your device. Opting out of a network does not mean you will no longer
            receive online advertising. It does mean that the network from which you opted out will no longer deliver
            ads tailored to your web preferences and usage patterns.
          </p>
          In addition, we have created mechanisms to provide you with the following control over your information:
          <ol type="i">
            <li>
              Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or
              to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of
              our website may not be accessible or may not function properly. For more information about tracking
              technologies, see the “Information We Collect Through Cookies and Other Automatic Data Collection
              Technologies” heading of this policy above.
            </li>
            <li>
              Third-Party Advertising. If you do not want us to share your personal information with unaffiliated or
              non-agent third parties for promotional purposes, you can opt-out by sending us an email stating your
              request to the address noted below.
            </li>
            <li>
              Targeted Advertising. If you do not want us to use information that we collect or that you provide to us
              to deliver advertisements according to our advertisers’ target-audience preferences, you can opt out by
              using the opt-out tools created by the Digital Advertising Alliance of Canada, the Network Advertising
              Initiative or Google Advertising.
            </li>
          </ol>
        </li>
        <li>
          <p>
            Contact Us
          </p>
          <p>
            If you have any questions or concerns about our Privacy Policy, or for any reason need to raise a question,
            concern, or comment about our privacy practices, please do not hesitate to contact our Privacy Officer via
            e-mail:
          </p>
          <p>email: <a href="mailto:info@FleetOperate.com">info@FleetOperate.com</a></p>
          <p>
            telephone: (204) 336-6325
          </p>
        </li>
      </ol>
    </div>
  </div>
</div>
