<div fxFlexAlign="center"
     fxLayout="column">
  <img fxFlexAlign="center"
       class="logo padding-top-x4"
       src="/assets/img/fleetoperate_logo.jpg"
       width="300px">
  <h2 fxFlexAlign="center" class="padding-top-x2" data-test="display-success-title" >Forgot Password Successful</h2>
  <span fxFlexAlign="center">{{message}}</span>
  <span fxFlexAlign="center" class="padding-top-x4">< <a [routerLink]="" (click)="onNavigateToSignIn()">Back to Signin</a></span>
</div>

