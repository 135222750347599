<div *ngIf="showNavBar" class="header-navigation-back">
  <mat-icon (click)="onNavigateBack()">arrow_back</mat-icon>
  <span fxFlex class="title">Shipment Document</span>
</div>
<div fxFlexAlign="center" class="margin-top-md">{{ errorMessage }}</div>

<div fxLayout fxLayoutAlign="space-between center">
  <div class="margin-left-md">{{ fileType }}</div>
  <div class="margin-right-md">
    <a mat-raised-button color="primary" [href]="previewImage" target="_blank" download>Download</a>
  </div>
</div>
<img [src]="previewImage" *ngIf="previewImage" class="imagePreview padding-md" />
