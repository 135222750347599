import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { first, catchError, map } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { RegistrationState, NotificationStateModel } from 'src/app/registration/state/registration.state';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SubSink } from 'subsink';
import { DQF_DOC_STATUS, POLICE_CLEARANCE } from '../constants';
import { DriverQualificationService } from '../service/driver-qualification.service';
import { DRIVER_DOC_CONTEXT_INITIAL_DQF } from '../constants';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DqfRequestCarrierUploadInfoComponent } from '../dqf-request-carrier-upload-info/dqf-request-carrier-upload-info.component';
import { ROUTE_DQF, ROUTE_DQF_BIRTH_LOCATION } from 'src/app/shared/routes';
import { Router } from '@angular/router';
import { CommonDataService } from 'src/app/shared/services/common/common-data.service';
import { of } from 'rxjs';

const REPORT_TYPE = 'PoliceClearance';
const ACTION_UPLOAD_DOCUMENT = 'Upload Document';
const UPLOADED_STATUS = 'uploaded';
const ACTION_DELETE_STATUS = 'Deleted Document';
const UPLOADED_DOCUMENT = 'Successfully uploaded document';
const DELETED_DOCUMENT = 'Successfully deleted document';
const REQUEST_SUCESS = 'Successfully sent the request';
const DOCUMENT_TYPE = 'Police Clearance Certificate.';

const DISABLE_TOP_LOADING_BAR = true;

@Component({
  selector: 'app-dqf-police-certificate',
  templateUrl: './dqf-police-certificate.component.html',
  styleUrls: ['./dqf-police-certificate.component.scss']
})
export class DqfPoliceCertificateComponent implements OnInit {
  form: FormGroup;
  formUploadFiles = { form: null };
  fileInfo: { id: string; fileName: string; generationDate: string };
  subSink = new SubSink();
  isContentReady: boolean = false;
  documentStatus: string;
  documentType = POLICE_CLEARANCE;
  constructor(
    private readonly location: Location,
    private readonly formBuilder: FormBuilder,
    private readonly driverQualificationService: DriverQualificationService,
    private readonly driverApiService: DriverApiService,
    private readonly dateTimeService: DateTimeService,
    private readonly toastService: ToastService,
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly bottomSheet: MatBottomSheet,
    private readonly router: Router,
    private readonly commonDataService: CommonDataService
  ) {}

  ngOnInit(): void {
    this.getDocumentDqfStatus();
    this.loadDriverDocuments();
    this.form = this.createForm();
    this.formUploadFiles = {
      form: this.form
    };
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  backAction(): void {
    this.location.back();
  }
  getDocumentDqfStatus(): void {
    const document = this.driverQualificationService.getDqfDocumentFromAttachedDocuments(REPORT_TYPE);
    if (!document) {
      return;
    }

    this.documentStatus = document.status;
  }
  uploadDocument(): void {
    this.isContentReady = false;
    const model = this.driverQualificationService.prepareUploadDocumentSaveModel(
      this.form.value,
      REPORT_TYPE,
      DRIVER_DOC_CONTEXT_INITIAL_DQF,
      this.driverQualificationService.dqfId
    );
    if (model && this.formUploadFiles.form.valid) {
      this.subSink.sink = this.driverQualificationService
        .uploadDqfDocument(model, this.form.value.file, DISABLE_TOP_LOADING_BAR)
        .pipe(
          first(),
          catchError((error: any) => {
            this.isContentReady = true;
            this.formUploadFiles.form.get('file').patchValue(null);
            this.formUploadFiles = { ...this.formUploadFiles };

            return of(false);
          })
        )
        .subscribe(() => {
          const docModel = {
            attachedDocument: {
              type: REPORT_TYPE,
              status: DQF_DOC_STATUS.DRIVER_REVIEWED
            }
          };
          this.updateDqfDocStatus(docModel, UPLOADED_DOCUMENT);
        });
    }
  }
  requestCarrierToUploadDocument(): void {
    if (this.commonDataService.isCERTNEnableForPoliceClearance) {
      this.bottomSheet.open(DqfRequestCarrierUploadInfoComponent, {
        data: {
          documentName: DOCUMENT_TYPE
        }
      });
      this.bottomSheet._openedBottomSheetRef.afterDismissed().subscribe(value => {
        if (value) {
          this.navigateToBirthLocation();
        }
      });
    } else {
      const docModel = {
        attachedDocument: {
          type: REPORT_TYPE,
          status: DQF_DOC_STATUS.CARRIER_UPLOAD_REQUESTED
        }
      };
      this.updateDqfDocStatus(docModel, REQUEST_SUCESS);
    }
  }

  deleteDocument(): void {
    if (this.formUploadFiles.form?.value?.file?.id) {
      this.isContentReady = false;
      this.subSink.sink = this.driverQualificationService
        .deleteDqfDocument(this.formUploadFiles.form?.value?.file?.id, DISABLE_TOP_LOADING_BAR)
        .subscribe(
          () => {
            this.isContentReady = true;
            this.toastService.showSuccess(DELETED_DOCUMENT);
            this.form.reset();
            this.formUploadFiles.form.get('file').patchValue(null);
          },
          error => {
            this.isContentReady = true;
            return of(false);
          }
        );
    }
  }

  loadDriverDocuments(): void {
    this.isContentReady = false;
    this.subSink.sink = this.registrationService
      .loadDriver(DISABLE_TOP_LOADING_BAR)
      .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
      .subscribe((notificationStateModel: NotificationStateModel) => {
        if (notificationStateModel && notificationStateModel.notifications) {
          const filteredValue = notificationStateModel.notifications.filter(
            notification =>
              notification.type === REPORT_TYPE &&
              notification.status == UPLOADED_STATUS &&
              notification?.dqfId === this.driverQualificationService.dqfId
          );
          this.isContentReady = true;
          if (filteredValue.length) {
            this.driverQualificationService.bindDriverDocument.call(this, filteredValue[0], this.formUploadFiles);
          }
        }
      });
  }

  updateDqfDocStatus(docModel: any, successMessage: string): void {
    this.subSink.sink = this.driverQualificationService
      .updateDqfStatus(this.driverQualificationService.dqfId, docModel)
      .subscribe(() => {
        this.isContentReady = true;
        this.toastService.showSuccess(successMessage);
        this.backAction();
      });
  }

  navigateToBirthLocation(): void {
    this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_BIRTH_LOCATION}`]);
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      generationDate: ['', Validators.required],
      file: ['', Validators.required]
    });
  }
}
