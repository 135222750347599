import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Employment } from 'src/app/shared/models/employment.model';
import dayjs from 'dayjs';
import { ROUTE_DQF, ROUTE_EMPLOYED_FORM, ROUTE_EMPLOYMENT_HISTORY, ROUTE_UNEMPLOYED_FORM } from 'src/app/shared/routes';
import { StorageService } from 'src/app/shared/storage/storage.service';
import { Router } from '@angular/router';

const DELETE_MESSAGE = 'Are you sure you want to delete the employment?';

@Component({
  selector: 'app-employment-view',
  templateUrl: './employment-view.component.html',
  styleUrls: ['./employment-view.component.scss']
})
export class EmploymentViewComponent implements OnInit {
  @Input() model: Employment;
  @Input() index: number;
  @Input() isAllowDeleteCurrentEmployment: boolean;
  currentDate = new Date();
  @Output() updateEmploymentPressed: EventEmitter<Employment> = new EventEmitter<Employment>();
  @Output() deleteEmploymentPressed: EventEmitter<Employment> = new EventEmitter<Employment>();
  @Output() openEmploymentFormPressed = new EventEmitter<number>();
  @Output() openUnEmploymentFormPressed = new EventEmitter<number>();
  isGapItem: boolean = false;
  constructor(private readonly storageService: StorageService, private readonly router: Router) {}

  ngOnInit(): void {
    if (this.model.fromDate) {
      this.model = {
        ...this.model,
        fromDate: dayjs(this.model.fromDate).format('YYYY-MM-DD')
      };
    }
    if (this.model.fromDate == null) {
      this.isGapItem = true;
    }
    if (this.model.toDate) {
      let toDate =
        this.model && this.model.toDate
          ? dayjs(this.model.toDate).format('YYYY-MM-DD')
          : dayjs(this.currentDate).format('YYYY-MM-DD');

      this.model = {
        ...this.model,
        toDate: toDate
      };
    }
  }

  onUpdateEmp(): void {
    this.updateEmploymentPressed.emit(this.model);
  }

  onDeleteEmp(): void {
    if (confirm(DELETE_MESSAGE)) {
      this.deleteEmploymentPressed.emit(this.model);
    }
  }
  openEmploymentForm(): void {
    this.openEmploymentFormPressed.emit(this.index);
  }
  openUnEmploymentForm(): void {
    this.openUnEmploymentFormPressed.emit(this.index);
  }
  checkDeletePermission(): boolean {
    if (this.model.isCurrentEmployment) {
      return false;
    } else {
      return true;
    }
  }
  onAddNewCurrentEmployment(isEmploymentForm: boolean, model?: Employment): void {
    this.storageService.store('currentEmploymentState', model);
    let path = '';
    if (isEmploymentForm) {
      path = `/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_EMPLOYED_FORM}?newCurrentAddress=true`;
    } else {
      path = `/${ROUTE_EMPLOYMENT_HISTORY}/${ROUTE_UNEMPLOYED_FORM}?newCurrentAddress=true`;
    }
    this.router.navigateByUrl(path);
  }
}
