import { NgModule } from '@angular/core';
import { HelpComponent } from './help/help.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { HelpRoutingModule } from './help-routing.module';

@NgModule({
  declarations: [HelpComponent],
  imports: [
    FlexLayoutModule,
    MatButtonModule,
    HelpRoutingModule
  ]
})
export class HelpModule { }