import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { catchError, finalize, first, switchMap, tap } from 'rxjs/operators';
import { pipe } from 'rxjs';
import { SelectActionComponent } from 'src/app/shared/dialog/select-action/select-action.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { title } from 'process';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { base64ToFile } from 'src/app/shared/utils';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import * as mime from 'mime';
import { ToastService } from 'src/app/shared/toast/toast.service';
import {
  DRIVER_UPLOAD_DOCUMENT_TYPES,
  SINGLE_DOCUMENT_TYPES,
  DRIVER_NO_ACTION_DOC_TYPES
} from '../../shared/constants';
import { SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW } from '../../shared/routes';
const DOCUMENT_UPLOAD_SUCCESS_MESSAGE = 'Sucessfully uploaded shipment document';
const DEFAULT_ERROR_MESSAGE = 'Something Went Wrong.. please try again later';
const PAPS_LABEL_TYPE = 'PapsLabel';
const PARS_LABEL_TYPE = 'ParsLabel';
const POD_LABEL_TYPE = 'ProofOfDelivery';

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent implements OnInit {
  documentType: string;
  shipmentDocuments;
  shipmentLoopupTypes;
  shipmentId: string;
  documentTypeDisplayName;
  currentTypeDocuments;
  acceptTypesPicture = ['image/png', 'image/jpg', 'image/jpeg'];
  acceptTypesMultiple = ['application/pdf'];
  isUploading: boolean = false;
  fileToUpload;
  loading: boolean = false;
  showCrop: boolean = false;
  imageChangedEvent;
  documentPicture;
  allowToUpload: boolean = true;
  isContentLoading: boolean = false;
  canvasRotation = 0;
  transform: ImageTransform = {};
  currentEventIndex: number = 0;
  eventsPerPage: number = 3;
  apiCount: number = 0;
  timelineList: Event[] = [];
  singleDocumentFiles = [...SINGLE_DOCUMENT_TYPES, ...DRIVER_NO_ACTION_DOC_TYPES];
  uploadingNewDocument = false;

  @ViewChild('previewImage') previewImage: ImageCropperComponent;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location,
    private readonly documentsDataService: DocumentsDataService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly fileDataService: FileDataService,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.documentType = this.activatedRoute.snapshot.paramMap.get('type');
    this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getShipmentDocumentTypes();
    this.getShipmentDocuments();
  }

  ngDoCheck() {
    let element = document.getElementById('timeline-list');
    const isScrollVisible = element?.scrollHeight > element?.clientHeight;
    // if scroll is not visible but many events are there then update timelinelist
    if (element && !isScrollVisible) {
      this.updateTimelineList();
    }
  }

  updateTimelineList(): void {
    if (this.currentEventIndex <= this.currentTypeDocuments?.length - 1 && this.apiCount === 0) {
      // slicing 3 (eventsPerPage) from eventsList and pushing it to timeline
      const newIndex = this.currentEventIndex + this.eventsPerPage;
      const newEventsList = this.currentTypeDocuments.slice(this.currentEventIndex, newIndex);
      const newEventsListWithDocumentId = newEventsList.filter(el => el?.id);

      this.currentEventIndex = this.currentEventIndex + newEventsList.length;
      this.timelineList.push(...newEventsList);

      // apiCount to keep track of documents api call - so that this function doesn't get triggered whenever user reaches page end
      this.apiCount = newEventsListWithDocumentId.length;

      newEventsListWithDocumentId.forEach((event: any) => {
        if (event.id) {
          this.loading = true;
          this.documentsDataService
            .getShipmentDocument(this.shipmentId, event.id)
            .pipe(
              first(),
              tap(response => {
                let isPdf = false;
                const url = `${response.fileUrl}`;
                const parentUrl = url
                  ?.split('?')[0]
                  ?.split('?')[0]
                  ?.split('/');

                if (parentUrl && parentUrl[parentUrl.length - 1]?.split('.')[1] === 'pdf') {
                  isPdf = true;
                }
                this.timelineList = this.timelineList.map((el: any) => {
                  if (el.id === event.id) {
                    return { ...el, isPdf: isPdf, fileUrl: response.fileUrl };
                  }
                  return el;
                });
                this.loading = false;
              }),
              finalize(() => {
                this.apiCount = this.apiCount - 1;
              }),
              catchError(error => {
                this.loading = false;
                this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
                return error;
              })
            )
            .subscribe();
        }
      });
    }
  }

  navigateBack(): void {
    this.location.back();
  }
  onUploadFileChange($event: any): void {
    this.isUploading = true;
    if (!$event || $event.target.files.length === 0) {
      return;
    }
    const [file] = $event.currentTarget.files;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.fileToUpload = file;
      $event.target.files = null;
      $event.target.value = null;
      this.onUploadNewDocument();
    };
  }
  onProcessPictureFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.documentPicture = file;
      };
      this.loading = true;
      this.showCrop = true;
      this.imageChangedEvent = event;
    }
  }

  openActionSheet(pictureSingle, uploadMultiple): void {
    let options: any = [
      {
        displayName: 'Take picture (Single page)',
        name: 'picture'
      },
      {
        displayName: 'Upload File (Multiple pages)',
        name: 'upload'
      }
    ];
    this.bottomSheet.open(SelectActionComponent, {
      data: {
        title: title,
        options: options,
        selectedValue: null,
        onConfirm: (val: string) => {
          if (val === 'picture') {
            this.onFileInputClicked(pictureSingle);
          } else {
            this.onFileInputClicked(uploadMultiple);
          }
        }
      }
    });
  }
  onFileInputClicked(fileInput: any) {
    fileInput.click();
  }
  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      const imageFile = new File([base64ToFile(event.base64)], this.documentPicture.name, {
        type: this.documentPicture.type
      });
      this.documentPicture = imageFile;
    }
  }
  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  onFinishCrop() {
    this.fileToUpload = this.documentPicture;

    this.onUploadNewDocument();
  }

  onCancelCrop() {
    this.previewImage = null;
    this.documentPicture = null;
    this.showCrop = false;
    this.loading = false;
  }

  onLoadImageFailed() {
    this.loading = false;
  }
  onUploadNewDocument(): void {
    this.loading = true;
    this.uploadingNewDocument = true;
    const extension = this.fileToUpload.name
      .split('.')
      .pop()
      .toLowerCase();
    const documentType = this.documentType ?? POD_LABEL_TYPE;
    this.documentsDataService
      .sendShipmentDocument(this.shipmentId, documentType, extension)
      .pipe(
        switchMap((sendDocumentResponse: any) => {
          const url = sendDocumentResponse.url;
          const newFileName = sendDocumentResponse.file;

          const file = this.fileToUpload;
          const renamedFile: File = new File([file], newFileName, { type: file.type });
          const contentType = mime.getType(extension);

          return this.fileDataService.uploadFile(renamedFile, url, contentType);
        }),
        tap(() => {
          this.toastService.showSuccess(DOCUMENT_UPLOAD_SUCCESS_MESSAGE);
        }),
        catchError((error: any) => {
          this.uploadingNewDocument = false;
          this.loading = false;
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          throw error;
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.fileToUpload = undefined;
        this.documentPicture = undefined;
        this.showCrop = false;
        this.currentEventIndex = 0;
        this.apiCount = 0;
        this.timelineList = [];
        this.currentTypeDocuments = [];
        this.uploadingNewDocument = false;
        this.getNewDocuments(true);
      });
  }
  getShipmentDocuments(): void {
    this.shipmentDocuments = this.documentsDataService.shipmentDocuments;

    if (!this.shipmentDocuments) {
      this.getNewDocuments();
    } else {
      this.currentTypeDocuments = this.shipmentDocuments.filter(document => document.type === this.documentType);
      this.allowToUpload = this.isDocumentAllowToUpload();
      this.updateTimelineList();
    }
  }
  getShipmentDocumentTypes(): void {
    this.shipmentLoopupTypes = this.documentsDataService.shipmentDocumentTypes;
    if (!this.shipmentLoopupTypes) {
      this.getNewShipmentDocumentTypes();
    } else {
      this.documentTypeDisplayName = this.shipmentLoopupTypes.find(
        type => type.name === this.documentType
      )?.displayName;
    }
  }
  private getNewShipmentDocumentTypes(): void {
    this.isContentLoading = true;
    this.documentsDataService
      .getShipmentDocumentTypesLookup()
      .pipe(
        first(),
        pipe(
          tap(response => {
            this.shipmentLoopupTypes = response;
            this.documentTypeDisplayName = this.shipmentLoopupTypes.find(
              type => type.name === this.documentType
            )?.displayName;
          }),
          catchError(error => {
            return error;
          }),
          finalize(() => {
            this.isContentLoading = false;
          })
        )
      )
      .subscribe();
  }
  private getNewDocuments(isNewUpload?: boolean): void {
    this.isContentLoading = true;
    this.documentsDataService
      .getShipmentDocuments(this.shipmentId)
      .pipe(
        first(),
        tap((response: any[]) => {
          const doc: any = response?.filter(document => document.type === this.documentType);
          if (isNewUpload && this.singleDocumentFiles.includes(this.documentType) && doc && doc[0]) {
            const path = `${SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW}/${this.shipmentId}/${doc[0].id}`;
            this.router.navigate([path]);
          } else {
            this.shipmentDocuments = response;
            this.currentTypeDocuments = this.shipmentDocuments?.filter(document => document.type === this.documentType);
            this.allowToUpload = this.isDocumentAllowToUpload();
            this.updateTimelineList();
          }
        }),
        catchError(error => {
          return error;
        }),
        finalize(() => {
          this.isContentLoading = false;
        })
      )
      .subscribe();
  }

  isDocumentAllowToUpload(): boolean {
    let isAllowed = true;
    if (
      !DRIVER_UPLOAD_DOCUMENT_TYPES.filter(el => el.name !== POD_LABEL_TYPE).some(
        type => type.name === this.documentType
      )
    ) {
      isAllowed = false;
    }
    if (this.documentType === PARS_LABEL_TYPE) {
      const parsDocument = this.currentTypeDocuments.find(document => document.type === PARS_LABEL_TYPE);
      isAllowed = !parsDocument;
    }
    if (this.documentType === PAPS_LABEL_TYPE) {
      const papsDocument = this.currentTypeDocuments.find(document => document.type === PAPS_LABEL_TYPE);
      isAllowed = !papsDocument;
    }
    if (this.documentType === POD_LABEL_TYPE) {
      const podDocument = this.currentTypeDocuments.find(document => document.type === POD_LABEL_TYPE);
      isAllowed = !podDocument;
    }
    return isAllowed;
  }
  goToDocumentView(id: string): void {
    if (!id) {
      return;
    }
    const path = `${SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW}/${this.shipmentId}/${id}`;
    this.router.navigate([path]);
  }

  @HostListener('scroll', ['$event'])
  onScroll(ev: any): void {
    if (ev.target.offsetHeight + ev.target.scrollTop >= ev.target.scrollHeight - 10) {
      this.updateTimelineList();
    }
  }
}
