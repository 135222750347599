import { Directive, HostBinding, Input } from '@angular/core';
import { ProfileService } from 'src/app/profile/profile.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';

@Directive({
  selector: '[appBlurElement]'
})
export class BlurElementDirective {
  isProfileComplete: boolean;

  /* Takes elementClass as input */
  @Input('class.blurElement')
  /* Binds the global class BlurElement to the input element */
  @HostBinding('class.blurElement')
  isActive: boolean;

  constructor(
    private readonly profileService: ProfileService,
    private readonly registrationService: RegistrationService
  ) {}

  ngOnInit() {
    this.isProfileComplete = this.profileService.isProfileCompleted();
    const isCarrierDriver = this.registrationService.getDriver()?.companyAssociatedId ? true : false;

    if (!this.isProfileComplete && !isCarrierDriver) {
      this.isActive = true;
    }
  }
}
