import { Component, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { RegistrationService } from '../shared/registration.service';
import { Store } from '@ngxs/store';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { Notification } from 'src/app/shared/models/notification.model';
import { map } from 'rxjs/operators';
import { INSURANCE, UPLOAD_STATUS } from 'src/app/shared/services/driver-api.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.scss']
})
export class InsuranceComponent implements OnInit {
  html: any;
  supportEmail = 'support@FleetOperate.com';
  driverSupportEmail = 'driversupport@fleetoperate.com';
  private subs = new SubSink();

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private domSanitizer: DomSanitizer,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
        .subscribe((notificationStateModel: NotificationStateModel) => {
          if (notificationStateModel && notificationStateModel.notifications) {
            this.insuranceStatus(
              this.profileService.findNotification([...notificationStateModel.notifications], INSURANCE, UPLOAD_STATUS)
            );
          }
        })
    );
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  private insuranceStatus(insurance: Notification): void {
    if (insurance && insurance.createdDate) {
      const createdDate = this.datePipe.transform(insurance.createdDate, 'dd/MM/yyyy') as string;
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p>Your Insurance documents were uploaded to our system on ${createdDate}.</p><p>If you need further assistance, please get in touch with one of our support group at <a href="mailto:${this.supportEmail}">${this.supportEmail}.</a></p>`
      );
    } else {
      this.html = this.domSanitizer.bypassSecurityTrustHtml(
        `<p><b> Applicable to Owner Operators ONLY.</br>Please submit your proof of insurance to </b><a href="mailto:${this.driverSupportEmail}">${this.driverSupportEmail}.</a></p><p>Please allow a minimum of 24 hours for us to process your Insurance documents after you send them to us.</p>`
      );
    }
  }
}
