export const APPLICATION_FOR_EMPLOYMENT = `
<div><p>In compliance with Federal and State equal employment opportunity laws, qualified applicants
are considered for all positions without regard to race, color, religion, sex, national origin, age,
marital status, veteran status, non-job-related disability, or any other protected group status.</p></div>
<div><p>
I authorize you to make such investigations and inquiries of my personal, employment,
financial or medical history and other related matters as may be necessary in arriving at an employment decision.
(Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has been extended.)
I hereby release employers, schools, health care providers and other persons from all liability
in responding to inquiries and releasing information in connection with my application.
</p>
</div>
<div><p>
In the event of employment, I understand that false or misleading information given in my application or interview(s) may result in discharge.
I understand, also, that I am required to abide by all rules and regulations of the Company.
</p>
</div>
<div><p>
I understand that information I provide regarding current and/or previous employers may be used, and those employer(s)
will be contacted, for investigating my safety performance history as required by 49 CFR 391.23(d) and (e).
I understand that I have the right to:</p>
</div>
<div>
<ul>

<li>Review information provided by the previous employer.</li>
<li>Have errors in the information corrected by previous employers and for those previous employers to resend the corrected information to the prospective employer; and</li>
<li> Have a rebuttal statement attached to the alleged erroneous information if the previous employer(s) and I cannot agree on the accuracy of the information.</li>
<ul>
</div>`


export const EMPLOYMENT_RECORD = `<div><p>
I authorize you to make sure investigations and inquiries to my personal, employment,
financial or medical history and other related matters as may be necessary in arriving at an employment decision.
(Generally, inquiries regarding medical history will be made only if and after a conditional offer of employment has been extended.)
I hereby release employers, schools, health care providers and other persons from all liability in responding to inquiries and releasing information in connection with my application.
</p></div>

<div><p>
In the event of employment, I understand that false or misleading information given in my application or interview(s) may result in discharge.
I understand, also, that I am required to abide by all rules and regulations of the Company.
</p></div>

<div><p>
I understand that information I provide regarding current and/or previous employers may be used, and those employer(s)
will be contacted, for investigating my safety performance history as required by 49 CFR 391.23(d) and (e).
I understand that I have the right to:</p>
</div>
<div>
<ul>

<li>Review information provided by the previous employer.</li>
<li>Have errors in the information corrected by previous employers and for those previous employers to resend the corrected information to the prospective employer; and</li>
<li> Have a rebuttal statement attached to the alleged erroneous information if the previous employer(s) and I cannot agree on the accuracy of the information.</li>
<ul>
</div>
`

export const ON_DUTY_HOURS_AND_OTHER_COMPENSATED_WORK = `<div><p>I hereby certify that the information given above is true and I understand that once I become
employed with this company, if I begin working for any additional employer(s) for
compensation that I must inform this company immediately of such employment activity</p></div>`


export const COMPLIANCE_WITH_DRIVER_LICENSE_REQUIREMENTS = `
<div>
<p>
The requirements in Part 383 apply to every driver who operates in
intrastate, interstate, or foreign commerce and operates a vehicle weighing 26,001 pounds or more, can transport more than 15 people or transports hazardous materials that require placarding.
The requirements in Part 391 apply to every driver who operates in interstate commerce and operates a vehicle weighing 10,001 pounds or more, can transport more than 15 people,
or transports hazardous materials that require placarding.
</p>
</div>

<div>
<p>
<strong>DRIVER REQUIREMENTS: </strong> Parts 383 and 391 of the Federal Motor Carrier Safety Regulations contain certain driver licensing requirements that you as a driver must comply with, including the following:
</p>
</div>

<div>
<p>
<strong>POSSESS ONLY ONE LICENSE: </strong> You, as a commercial vehicle driver, may not possess more than one motor vehicle operator's license.
</p>
</div>

<div>
<p>
<strong>NOTIFICATION OF LICENSE SUSPENSION, REVOCATION OR CANCELLATION: </strong> Sections 391.15(b)(2)
and 383.33 of the Federal Motor Carrier Safety Regulations require that you notify your employer the NEXT BUSINESS DAY of any revocation or suspension of your driver's license.
In addition, Section 383.31 requires that any time you are convicted of violating a state or local traffic law (other than parking),
you must report it within 30 days to: 1) your employing motor carrier, and 2)
he state that issued your license (if the violation occurs in a state other than the one which issued your license).
The notification to both the employer and state must be in writing.
</p>
</div>

<div>
<p>
<strong>CDL DOMICILE REQUIREMENT: </strong> Section 383.23(a)(2) requires that your commercial driver's license be issued by your legal state of domicile, where you have your true, fixed, and permanent home and principal residence and to which you have the intention of returning whenever you are absent. If you establish a new domicile in another state, you must apply to transfer your CDL within 30 days.
I certify that I have read and understood the above requirements.
<p>
</div>
`

export const FAIR_CREDIT_REPORTING_ACT_DISCLOSURE = `<div>
<p>
In accordance with the provisions of Section 604(B)(2)(A) of the Fair Credit Reporting Act, public law 91-508,
as amended by the Consumer Credit Reporting Act of 1996 (Title II, Subtitle, D, Chapter I, of Public Law 104-208),
you are being informed that reports verifying your previous employment, previous drug and alcohol test results,
and your driving record may be obtained on you for employment purposes,
These reports are required by sections 382.413,391.23 and 391.25 of Federal Motor Carrier Safety Regulations.
</p>
</div>`

export const DRUG_AND_ALCOHOL_PRE_EMPLOYMENT_STATEMENT = `<div>
<p>
CFR Part 40.25(i) requires the employer to ask any applicant, whether he or she has tested
positive, or refused to test, on any pre-employment drug or alcohol test administered by an
employer to which the employee applied for, but did not obtain, safety-sensitive transportation
work covered by DOT agency drug and alcohol rules during the past two years. If the potential
employee admits that he or she had a positive test or refusal to test, we must not use the
employee to perform safety-sensitive functions, until and unless the potential employee provides
documentation of successful completion of the return-to-duty process.
(See Section 40.25(b)(5) and (e).
</p>
</div>`