<div fxLayout="row wrap">
  <button
    *ngFor="let action of actions"
    mat-raised-button
    class="action-name {{ action.color }}"
    [ngClass]="action.type"
    [disabled]="action.disabled"
    (click)="action.action()"
    data-test="action-button"
  >
    {{ action.name }}
  </button>
</div>
