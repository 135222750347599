import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ShipmentsUtilsService } from '../../shared/shipments-utils.service';
import { ShipmentsActionSheetComponent } from '../../shipments-action-sheet/shipments-action-sheet.component';
import {
  DEFAULT_ERROR_MESSAGE,
  DRIVER_UPLOAD_DOCUMENT_TYPES,
  STATUS_ASSIGNED,
  STATUS_CONFIRMATION_PENDING,
  STOPS,
  STOP_DISPLAY_NAMES
} from '../../shared/constants';
import { first, tap, catchError, finalize } from 'rxjs/operators';
import { Event } from 'src/app/loads-shared/shipment-data/models/event';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ShipmentsDataService } from '../../shared/shipments-data.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

const DELETE_ACTION = 'delete';
const EDIT_ACTION = 'edit';
const SHIPMENT_DETAILS_UPDATED_TEXT = 'Shipment details updated';
const SHIPMENT_TYPE_ONGOING = 'Ongoing';
const SHIPMENT_TYPE_UPCOMING = 'Upcoming';

@Component({
  selector: 'app-shipment-timeline',
  templateUrl: './shipment-timeline.component.html',
  styleUrls: ['./shipment-timeline.component.scss']
})
export class ShipmentTimelineComponent implements OnInit, OnChanges {
  timelineList: Event[] = [];
  currentEventIndex: number = 0;
  eventsPerPage: number = 3;
  apiCount: number = 0;
  @Input() shipmentId: string;
  @Input() eventsList: any;
  shipment: Shipment;
  pickupFormattedDateTime: string | Date;
  deliveryFormattedDateAndTime: string | Date;
  isContentReady: boolean = false;
  shipmentType: string = '';
  excludedStatus = ['Shipment created', 'Shipment approved'];
  delivery;
  stops;
  flattenedTimelineList: any[];
  stopTypes: { stopId: any; stopType: string }[];
  pickupStop;
  deliveryAddress: any;
  pickupAddress: any;

  constructor(
    private readonly bottomSheet: MatBottomSheet,
    private readonly shipmentsUtilsService: ShipmentsUtilsService,
    private readonly documentsDataService: DocumentsDataService,
    private readonly toastService: ToastService,
    private readonly dateTimeService: DateTimeService,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly location: Location
  ) {}

  ngOnInit(): void {
    if (!this.shipmentId) {
      this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
      this.getShipmentDetails();
    }
    this.getShipmentDetails();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eventsList) {
      this.updateTimelineList();
    }
  }

  ngDoCheck() {
    let element = document.getElementById('timeline-list');
    const isScrollVisible = element?.scrollHeight > element?.clientHeight;
    // if scroll is not visible but many events are there then update timelinelist
    if (element && !isScrollVisible) {
      this.updateTimelineList();
    }
  }

  getStatusClass(event: Event): string {
    return this.shipmentsUtilsService.getShipmentStatusClass(event.status);
  }

  getShipmentStatusClass(): string {
    return this.shipmentsUtilsService.getShipmentStatusClass(this.shipment.status);
  }

  openActionSheet(event: Event): void {
    const bottonSheet = this.bottomSheet.open(ShipmentsActionSheetComponent, {
      data: {
        type: 'timeline',
        event: event,
        shipmentId: this.shipmentId,
        updateShipmentEventsActions: (eventObject?: any) => this.updateShipmentEventsActions(eventObject),
        updateShipmentEvents: (eventObject?: any) => this.updateShipmentEvents(eventObject)
      }
    });
  }

  navigateBack(): void {
    this.location.back();
  }

  getShipmentType(): void {
    if (this.shipment.status !== STATUS_CONFIRMATION_PENDING && this.shipment.status !== STATUS_ASSIGNED) {
      this.shipmentType = SHIPMENT_TYPE_ONGOING;
    } else {
      this.shipmentType = SHIPMENT_TYPE_UPCOMING;
    }
  }

  getDisplayName(eventType: string): string {
    const documentType = DRIVER_UPLOAD_DOCUMENT_TYPES.find(i => i.name === eventType);
    return documentType ? documentType.displayName : ' ';
  }

  editDocumentName(eventType: string): string {
    console.log(eventType?.replace(/[0-9]/g, ''))
    return eventType?.replace(/[0-9]/g, '');
  }

  toggleDetails(status: any): void {
    if (this.excludedStatus.includes(status.status)) {
      return;
    }
    status.showDetails = !status.showDetails;
  }

  getShipmentDetails(): void {
    this.isContentReady = false;
    this.shipmentsDataService
      .getShipmentDetails(this.shipmentId)
      .pipe(
        first(),
        tap((response: Shipment) => {
          if (response) {
            this.shipment = response;
            this.getShipmentPickupAndDeliveryDates();
            this.getShipmentType();
            if (Array.isArray(this.shipment.delivery)) {
              this.stops = [...this.shipment.delivery];
              this.stops.sort((a, b) => a.order - b.order);
            } else {
              this.stops = [this.shipment.delivery];
            }
            if (this.shipment.pickup) {
              this.pickupStop = this.shipment.pickup;
              this.pickupStop.setOrder = 1;
              this.stops.unshift(this.pickupStop);
            }
            for (let i = 1; i < this.stops.length; i++) {
              this.stops[i].setOrder = i + 1;
            }
            const pickupStops = [STOPS.HOOK_TRAIKER, STOPS.PICK_LOAD];
            const pickups = this.stops.filter(el => pickupStops.includes(el.stopType));
            const dropStops = [STOPS.DELIVER_LOAD, STOPS.DROP_TRAILER];
            const dropOffs = this.stops.filter(el => dropStops.includes(el.stopType));
            this.pickupAddress = this.shipmentsUtilsService.getPickupAddressDateTime(pickups, this.shipment)
            this.deliveryAddress = this.shipmentsUtilsService.getDeliveryAddressDateTime(dropOffs, this.shipment);
            // filter and sort events
            let eventsList = this.shipment.events
              ?.filter((event: Event) => event.status || event.name !== SHIPMENT_DETAILS_UPDATED_TEXT)
              .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

            const groupedEvents = eventsList.reduce((events, event, i) => {
              const status = event.status || event.name || 'Other';
              if (status !== 'Document uploaded') {
                events[status] = events[status] || [];
                events[status].push(event);
              } else {
                const key = `Document uploaded${i}`;
                events = { ...events, [key]: [event] };
              }
              return events;
            }, {});
            const reversedGroupedEvents = Object.keys(groupedEvents)
              .reverse()
              .reduce((event, key) => {
                event[key] = groupedEvents[key];
                return event;
              }, {});
            const reversedGroupedEventsArray = Object.entries(reversedGroupedEvents).map(([status, event]) => ({
              status,
              event
            }));
            this.eventsList = reversedGroupedEventsArray.reverse();
          }
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.isContentReady = true;
        })
      )
      .subscribe();
  }

  getShipmentPickupAndDeliveryDates(): void {
    this.pickupFormattedDateTime = this.shipment.pickupFormattedDateTime;
    this.deliveryFormattedDateAndTime = this.shipment.deliveryFormattedDateAndTime;
  }
  getStopName(stop): any {
    return STOP_DISPLAY_NAMES.get(stop) || '';
  }
  updateTimelineList(): void {
    if (this.currentEventIndex <= this.eventsList?.length - 1 && this.apiCount === 0) {
      // slicing 3 (eventsPerPage) from eventsList and pushing it to timeline
      const newIndex = this.currentEventIndex + this.eventsPerPage;
      const newEventsList = this.eventsList.slice(this.currentEventIndex, newIndex);
      //const newEventsListWithDocumentId = newEventsList.filter(el => el?.documentId);
      const documentIds: string[] = [];
      // const newEventsListWithDocumentId =
      newEventsList.forEach((item: any) => {
        if (item.event) {
          item.event.forEach((el: any) => {
            if (el.documentId) {
              documentIds.push(el.documentId);
            }
          });
        }
      });

      this.currentEventIndex = newIndex;
      this.timelineList.push(...newEventsList);

      const updatedtimelineList = this.timelineList.map(item => {
        item.event.forEach((evnt) => {
          const searchItem = this.stops.find(item => item.id === evnt.stopId);
          if (searchItem) {
            evnt.stopType = searchItem.stopType;
          }
        });
        return item;
      });

      this.timelineList = updatedtimelineList;

      // apiCount to keep track of documents api call - so that this function doesn't get triggered whenever user reaches page end
      this.apiCount = documentIds.length;

      documentIds.forEach((documentId: string) => {
        if (documentId) {
          this.documentsDataService
            .getShipmentDocument(this.shipmentId, documentId, true)
            .pipe(
              first(),
              tap(response => {
                this.timelineList = this.timelineList.map(item => {
                  if (item.event) {
                    const updatedEvents = item.event.map(event => {
                      if (event.documentId === documentId) {
                        return { ...event, fileUrl: response.fileUrl };
                      }
                      return event;
                    });

                    return { ...item, event: updatedEvents };
                  }

                  return item;
                });
              }),
              finalize(() => {
                this.apiCount = this.apiCount - 1;
              }),
              catchError(error => {
                this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
                return error;
              })
            )
            .subscribe();
        }
      });
    }
  }
  updateShipmentEventsActions(event: any): void {
    switch (event.eventAction.action) {
      case DELETE_ACTION:
        const statusToDelete = event.eventAction.status;
        const eventIdToDelete = event.eventAction.id;

        this.eventsList = this.eventsList.map(status => {
          if (status.status === statusToDelete) {
            status.event = status.event.filter(ev => ev.id !== eventIdToDelete);
          }
          return status;
        });

        this.timelineList = this.timelineList.map(status => {
          if (status.status === statusToDelete) {
            status.event = status.event.filter(ev => ev.id !== eventIdToDelete);
          }
          return status;
        });

        this.currentEventIndex = this.currentEventIndex - 1;
        break;

      case EDIT_ACTION:
        this.eventsList = this.eventsList.map(el => (el.id !== event.id ? el : { ...el, comment: event.comment }));
        this.timelineList = this.timelineList.map(el => (el.id !== event.id ? el : { ...el, comment: event.comment }));
        break;

      default:
        break;
    }
  }

  updateShipmentEvents(ev: any): void {
    switch (ev.action) {
      case DELETE_ACTION:
        this.eventsList = ev?.eventObject
          ? this.getEventList(ev?.eventObject)
          : this.eventsList.filter((el: Event) => el.id !== ev.id);
        this.timelineList = ev?.eventObject
          ? this.getEventList(ev?.eventObject)
          : this.timelineList.filter((el: Event) => el.id !== ev.id);

        this.currentEventIndex = this.currentEventIndex - 1;
        break;
      case EDIT_ACTION:
        const editedStatus = ev.status;
        const editedEventId = ev.id;
        const editedComment = ev.comment;

        this.eventsList = this.eventsList.map(status => {
          if (status.status === editedStatus) {
            status.event = status.event.map(event =>
              event.id === editedEventId ? { ...event, comment: editedComment } : event
            );
          }
          return status;
        });

        this.timelineList = this.timelineList.map(status => {
          if (status.status === editedStatus) {
            status.event = status.event.map(event =>
              event.id === editedEventId ? { ...event, comment: editedComment } : event
            );
          }
          return status;
        });
        break;
    }
  }
  getEventList(eventsList) {
    eventsList = eventsList
      ?.filter((event: Event) => event.status || event.name !== SHIPMENT_DETAILS_UPDATED_TEXT)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    let eventStatus = '';
    let recentEventStatus = eventsList.find(el => el.status);

    return eventsList
      .reverse()
      .map((event: Event) => {
        if (!event.name) {
          if (eventStatus !== event.status || recentEventStatus?.id === event?.id) {
            eventStatus = event.status;
            return { ...event, showStatus: true };
          }
          return event;
        } else {
          return { ...event, showStatus: true };
        }
      })
      .reverse();
  }
}
