import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ShipmentDataService } from '../shipment-data/shipment-data.service';
import { Shipment } from '../shipment-data/models/shipment';
import { AppService } from 'src/app/app.service';
import { ROUTE_TOKEN_SHIPMENT_DETAILS } from '../route-tokens';

const HistoryShipmentContactPath = 'driverHistory/shipment-contacts/';

@Component({
  selector: 'app-shipment-contacts',
  templateUrl: './shipment-contacts.component.html',
  styleUrls: ['./shipment-contacts.component.scss']
})
export class ShipmentContactsComponent implements OnInit {
  errorMessage: string;
  shipment: Shipment;
  showNavBar: boolean;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly shipmentDataService: ShipmentDataService,
    private readonly appService: AppService,
    @Inject(forwardRef(() => ROUTE_TOKEN_SHIPMENT_DETAILS)) public routeShipmentDetails: string
  ) {
    this.showNavBar = true;
  }

  ngOnInit() {
    const path = this.router.url;
    if (this.appService.isNativeApp && path.includes(HistoryShipmentContactPath)) {
      this.showNavBar = false;
    }
    const shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.loadShipment(shipmentId);
  }

  onNavigateBack(): void {
    this.router.navigate([this.routeShipmentDetails, this.shipment.id]);
  }

  private loadShipment(shipmentId: string): void {
    this.shipmentDataService
      .fetchShipment(shipmentId)
      .pipe(first())
      .subscribe((shipment: Shipment) => {
        this.shipment = shipment;
      });
  }
}
