import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action } from '../action';

export interface DialogData {
  title: string;
  message: string;
  actions: Action[];
}

@Component({
  selector: 'app-shipment-confirmation-dialog',
  templateUrl: './shipment-confirmation-dialog.component.html',
  styleUrls: ['./shipment-confirmation-dialog.component.scss']
})

export class ShipmentConfirmationDialogComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<ShipmentConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

   ngOnInit() {
   }
}
