import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ROUTE_SIGNIN, ROUTE_TERMS_CONDITIONS } from '../shared/routes';

@Component({
  selector: 'app-account-locked',
  templateUrl: './account-locked.component.html',
  styleUrls: ['./account-locked.component.scss']
})
export class AccountLockedComponent implements OnInit {
  constructor(private readonly router: Router, private readonly registrationService: RegistrationService) {}

  ngOnInit(): void {}

  onLogout(): void {
    this.registrationService.clearDriver();
    this.registrationService.clearRegistration();
    this.router.navigate([ROUTE_SIGNIN]);
  }

  onNavigateToTermsConditions(): void {
    this.router.navigate([`/${ROUTE_TERMS_CONDITIONS}`]);
  }
}
