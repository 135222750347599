<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateToAccountSettings()">arrow_back</mat-icon>
    <span class="title">Reset Password</span>
  </div>

  <div
    fxFlex="none"
    fxLayout="column"
    fxLayoutAlign="center stretch"
    class="padding-top-xlg padding-right-md padding-left-md"
  >
    <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
      {{ errorMessage }}
    </mat-error>
    <form
      [formGroup]="form"
      #forgotPasswordForm="ngForm"
      class="padding-top-md"
      (ngSubmit)="onSubmit(forgotPasswordForm)"
      fxLayout="column"
    >
      <mat-form-field>
        <mat-hint align="start">at least 8 characters</mat-hint>
        <input
          matInput
          placeholder="Old Password"
          [type]="hideOldPassword ? 'password' : 'text'"
          formControlName="oldPassword"
        />
        <mat-icon matSuffix (click)="hideOldPassword = !hideOldPassword">{{
          hideOldPassword ? 'visibility' : 'visibility_off'
        }}</mat-icon>
        <mat-error *ngIf="oldPassword.touched && oldPassword.invalid">
          {{ getOldPasswordErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-hint align="start">at least 8 characters</mat-hint>
        <input
          matInput
          placeholder="New Password"
          [type]="hideNewPassword ? 'password' : 'text'"
          formControlName="newPassword"
        />
        <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">{{
          hideNewPassword ? 'visibility' : 'visibility_off'
        }}</mat-icon>
        <mat-error *ngIf="newPassword.touched && newPassword.invalid">
          {{ getNewPasswordErrorMessage() }}
        </mat-error>
      </mat-form-field>
      <div fxLayout fxLayoutAlign="center end" class="padding-top-x4">
        <button
          fxFlex.gt-sm="20%"
          fxFlex.lt-md="100%"
          class="full-width margin-bottom"
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="form.pristine || !form.valid || loading"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
