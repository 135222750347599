<div data-test="status-list">
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <div class="title margin-top-lg">
    Choose Status&nbsp;<span *ngIf="totalActions">{{ currentActionStep }}/{{ totalActions + 1 }}</span>
  </div>
  <div class="options margin-top-md center">
    <div
      *ngFor="let status of actions"
      [ngClass]="{ disabled: status.disabled || saving, current: status.type === 'current' }"
      class="margin-bottom-md"
      (click)="status.action()"
      data-test="status-name"
    >
      <span>{{ status.name }}</span>
      <span class="current-status" *ngIf="status.type === 'current'" data-test="current-status-text"
        >Current status</span
      >
    </div>
  </div>
  <div *ngIf="btnPodVisible">
    <button mat-button class="btn-accept" (click)="dismissBottomSheetToUpload()">Upload Proof Of Delivery</button>
  </div>
</div>
