<div fxLayout>
  <div fxLayout="column" class="margin-left-md" fxFlex="4">
    <span class="dot margin-top-md origin"></span>
    <span class="vertical-line"></span>
    <span class="dot"></span>
  </div>
  <div fxLayout="column" fxLayoutAlign="space-between" fxFlex="88">
    <div fxLayoutAlign="space-between">
      <span *ngIf="origin" class="locationLabel " appBlurElement>{{ origin }}</span>
      <app-offer
        [quote]="driverQuote"
        [shipmentStatus]="shipment.status"
        [shipmentOfferRate]="offerRate"
        [isTrip]="isTrip"
      >
      </app-offer>
    </div>
    <div>
      <span class="date-time-label">{{ formattedPickupDateAndTime }}</span>
      <span class="margin-left-sm"><mat-icon svgIcon="time"></mat-icon> </span>
      <span class="date-time-label padding-top-xs">{{ pickupTime }}</span>
    </div>
    <div class="padding-top-lg padding-bottom-lg">
      <span class="offer-type-name " *ngIf="mode">{{ mode }}</span>
      <span class="offer-type-name margin-left-sm" *ngIf="weight">{{ weight }} kg</span>
      <span *ngIf="priority" class="offer-type-name margin-left-sm priority">High Priority</span>
    </div>
    <span *ngIf="destination" class="locationLabel" appBlurElement>{{ destination }}</span>
    <div>
      <span class="date-time-label">{{ formattedDeliveryDateAndTime }} </span>
      <span class="date-time-label margin-left-sm"><mat-icon svgIcon="time"></mat-icon></span>
      <span class="date-time-label padding-top-xs">{{ deliveryTime }}</span>
    </div>
  </div>
</div>
