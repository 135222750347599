import { InjectionToken } from '@angular/core';

export const ROUTE_TOKEN_ENTRY = new InjectionToken<string>('ROUTE_TOKEN_ENTRY');
export const ROUTE_TOKEN_SHIPMENT_DETAILS = new InjectionToken<string>('ROUTE_TOKEN_SHIPMENT_DETAILS');
export const ROUTE_TOKEN_SHIPMENT_CONTACTS = new InjectionToken<string>('ROUTE_TOKEN_SHIPMENT_CONTACTS');
export const ROUTE_TOKEN_SHIPMENT_DOCUMENTS = new InjectionToken<string>('ROUTE_TOKEN_SHIPMENT_DOCUMENTS');
export const ROUTE_TOKEN_SHIPMENT_DOCUMENT = new InjectionToken<string>('ROUTE_TOKEN_SHIPMENT_DOCUMENT');
export const ROUTE_TOKEN_UPCOMING_LOADS = new InjectionToken<string>('ROUTE_TOKEN_UPCOMING_LOADS');
export const ROUTE_TAKEN_MYLOADS = new InjectionToken<string>('ROUTE_TAKEN_MYLOADS');
export const ROUTE_TOKEN_MY_SHIPMENT = new InjectionToken<string>('ROUTE_TOKEN_MY_SHIPMENT');
