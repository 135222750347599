<div
  fxLayout
  fxLayoutAlign="space-between"
  class="padding-right-md padding-left-md margin-bottom-sm"
  *ngIf="companyName"
>
  <div fxLayoutAlign="space-between">
    <span class="shipperLabel" appBlurElement>{{ companyName }}</span>
    <span>
      <mat-icon fxFlex *ngIf="priority" svgIcon="priority"></mat-icon>
    </span>
  </div>
  <app-offer [quote]="driverQuote" [shipmentOfferRate]="offerRate"> </app-offer>
</div>
<mat-divider *ngIf="companyName"></mat-divider>
<div fxLayout fxLayoutAlign="space-between" class="padding-top-md padding-right-md padding-left-md">
  <div fxLayout="column" class="padding-right-md">
    <span class="locationLabel">PICKUP</span>
    <span class="locationValue padding-top-md" appBlurElement
      ><a href="#" *ngIf="isNativeApp">{{ shipment?.origin }}</a>
      <a *ngIf="!isNativeApp">{{ shipment?.origin }}</a></span
    >
    <span class="dateLabel">{{ formattedPickupDateAndTime }}</span>
    <span class="locationLabel padding-top-md">DISTANCE</span>
    <span class="locationValue  padding-top-md">{{ shipment?.distance || '-' }}</span>
    <span class="locationLabel padding-top-md" *ngIf="shipment?.mode">MODE</span>
    <span class="locationValue  padding-top-md">{{ shipment?.mode }}</span>
    <span class="locationLabel padding-top-md">COMMODITY</span>
    <span class="locationValue  padding-top-md">{{ shipment?.commodity || '-' }}</span>
  </div>
  <div fxLayout="column" class="padding-left-md">
    <span class="locationLabel">DELIVERY</span>
    <span class="locationValue  padding-top-md" appBlurElement
      ><a href="#" *ngIf="isNativeApp">{{ shipment?.destination }}</a>
      <a *ngIf="!isNativeApp">{{ shipment?.destination }}</a></span
    >
    <span class="dateLabel">{{ formattedDeliveryDateAndTime }}</span>
    <span class="locationLabel padding-top-md">WEIGHT</span>
    <span class="locationValue  padding-top-md">{{ shipment?.weight }}</span>
  </div>
</div>
<div fxLayout="column" class="padding-top-md padding-right-md padding-left-md">
  <span class="locationLabel padding-top-md">DRIVER ASSISTANCE</span>
  <mat-checkbox class="locationValue padding-top-md" [checked]="shipment?.pickup?.driverAssistedPickup" disabled>
    Pickup</mat-checkbox
  >
  <mat-checkbox
    class="locationValue margin-left-md padding-top-xsm"
    [checked]="shipment?.pickup?.liftgatePickup"
    disabled
  >
    Liftgate Pickup</mat-checkbox
  >
  <mat-checkbox class="locationValue margin-left-md padding-top-xsm" [checked]="shipment?.pickup?.tarpPickup" disabled>
    Tarp Pickup</mat-checkbox
  >
  <mat-checkbox class="locationValue padding-top-sm" [checked]="shipment?.delivery?.driverAssistedDelivery" disabled>
    Delivery</mat-checkbox
  >
</div>
<div fxLayout fxLayoutAlign="space-between" class="padding-left-md">
  <div fxLayout="column" class="padding-right-md">
    <span class="locationLabel padding-top-md">COMMENTS</span>
    <span class="comment">{{ shipment?.comment || 'N/A' }}</span>
  </div>
</div>
