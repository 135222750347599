import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-photo-picker-action-sheet',
  templateUrl: './photo-picker-action-sheet.component.html',
  styleUrls: ['./photo-picker-action-sheet.component.scss']
})
export class PhotoPickerActionSheetComponent {
  files = [];
  @Input() disabled: boolean;
  @Output() onUpload = new EventEmitter<any>();

  constructor(private readonly bottomSheetRef: MatBottomSheetRef<PhotoPickerActionSheetComponent>) {}

  onFileInputClicked(fileInput: any) {
    fileInput.click();
  }

  onProcessFile(event: any) {
    if (event.target.files && event.target.files.length) {
      if (event.target.files.length > 5) {
        alert('You can upload up to 5 images.');
        return;
      } else {
        for (let i = 0; i < event.target.files.length; i++) {
          const file = event.target.files[i];
          this.files.push(file);
        }
      }

      this.close(this.files);
    } else {
      this.close();
    }
  }

  close(files?: any): void {
    this.bottomSheetRef.dismiss(files);
  }
}
