import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { ContactDetails } from '../../models/contact-details';
import { CommonApiService } from './common-api.service';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  isCERTNEnableForPoliceClearance = false;
  isCERTNEnableForAbstract = false;

  constructor(private readonly commonApiService: CommonApiService) { }

  getUserById(userId: string, uri: string): Observable<ContactDetails> {
    return this.commonApiService.getUserById(userId, uri).pipe(map((response: any) => response.data as ContactDetails));
  }

  getConfig(): Promise<object> {
    return this.commonApiService.getConfig()
  }
}
