import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreModule } from '../core.module';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ROUTE_IDENTITY, ROUTE_TERMS_CONDITIONS } from 'src/app/authentication/shared/routes';
import {
  IDENTITY,
  PROFILE_INCOMPLETE_STATUS,
  IDENTITY_INITIATED,
  PROFILE_LOCKED_STATUS
} from 'src/app/shared/services/driver-api.service';
import { ProfileService } from 'src/app/profile/profile.service';
import {
  ROUTE_ACCOUNT_LOCKED,
  ROUTE_DRIVER_HISTORY,
  ROUTE_JOBS,
  ROUTE_LOADS,
  ROUTE_PROFILE_PREFERENCE
} from 'src/app/shared/routes';
import { Location } from '@angular/common';

@Injectable({
  providedIn: CoreModule
})
export class DriverGuardService implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly profileService: ProfileService,
    private readonly location: Location
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const driver = this.registrationService.getDriver();
    const license = this.registrationService.getLicenseStore();

    if (driver && this.isAuthDriver()) {
      const detailsFound = this.profileService.findIdentityNotification(driver.notification, IDENTITY);
      if (!this.isLicensePresent()) {
        this.router.navigate([ROUTE_IDENTITY]);
      }
      return true;
    }
    return false;
  }
  private isLicensePresent() {
    const license = this.registrationService.getLicenseStore();
    return license?.backScanImagePresent && license?.frontScanImagePresent;
  }
  isAuthDriver(): boolean {
    const driverProfileStatus = this.registrationService.getDriverIdentityStatus();
    const isCarrierDriver = this.registrationService.getDriver()?.companyAssociatedId ? true : false;
    const url = this.location.path().replace('/', '');

    if (driverProfileStatus === PROFILE_INCOMPLETE_STATUS && url === ROUTE_DRIVER_HISTORY) {
      return false;
    }
    if (
      driverProfileStatus === PROFILE_LOCKED_STATUS &&
      url !== ROUTE_ACCOUNT_LOCKED &&
      url !== ROUTE_TERMS_CONDITIONS
    ) {
      return false;
    }

    if (
      isCarrierDriver &&
      (url === ROUTE_JOBS || url === ROUTE_LOADS || url === ROUTE_DRIVER_HISTORY || url === ROUTE_PROFILE_PREFERENCE)
    ) {
      return false;
    }

    return true;
  }
}
