import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FleetoperateApiService } from '../shared/services/fleetoperate-api.service';
import { ForgotPasswordModel } from './shared/forgot-password-model';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ChangePasswordModel } from './shared/change-password.model';

const PATH_FORGOT_PASSWORD = '/auth/forgotpassword';
const PATH_CONFIRM_FORGOT_PASSWORD = '/auth/confirmforgotpassword';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  apiUrl: string;

  constructor(private readonly router: Router, private readonly api: FleetoperateApiService) {
    this.apiUrl = environment.apiUrl;
  }

  sendForgotPasswordNotice(model: ForgotPasswordModel): Observable<any> {
    return this.api.post(this.apiUrl + PATH_FORGOT_PASSWORD, model);
  }

  changePassword(model: ChangePasswordModel): Observable<any> {
    return this.api.post(this.apiUrl + PATH_CONFIRM_FORGOT_PASSWORD, model);
  }
}
