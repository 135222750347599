import { Injectable } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  getLocaleCurrency(currency: number): string {
    if (!currency) {
      return;
    }

    let result: string;

    const locale = 'en-US';
    const currencyCode = 'USD';
    const digitsInfo = '1.2-2';
    result = formatCurrency(currency, locale, getCurrencySymbol(currencyCode, 'narrow'), currencyCode, digitsInfo);

    return result;
  }
}
