<div fxFlex class="item-container" fxLayout="column" data-test="documents-panel" [ngClass]="{ disabled: isDisabled }">
  <ng-container>
    <div fxLayout="column" (click)="isDisabled ? null : navigateToUpload(document.name)">
      <div class="status-icon-container" fxFlex="10" *ngIf="document?.isPresent">
        <mat-icon svgIcon="checkbox-circle-line-green" data-test="green-tick-mark"> </mat-icon>
      </div>

      <div class="timer-icon-container" fxFlex="30">
        <mat-icon svgIcon="files" class="icon-color"></mat-icon>
      </div>

      <div class="document-title" data-test="document-name">{{ document?.displayName }}</div>
    </div>
  </ng-container>
</div>
