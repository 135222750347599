import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { ACCIDENT_NATURE } from 'src/app/registration/shared/data-const/accidentNature';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { AccidentRecord } from 'src/app/shared/models/accident-record.model';

@Component({
  selector: 'app-dqf-driver-accident-records',
  templateUrl: './dqf-driver-accident-records.component.html',
  styleUrls: ['./dqf-driver-accident-records.component.scss']
})
export class DqfDriverAccidentRecordsComponent implements OnInit {
  @Input() version: number;
  data = [];
  constructor(private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.getDriverLicenseInfo();
  }

  private getDriverLicenseInfo(): void {
    this.registrationService.getAccidentRecords(this.version).subscribe((response: AccidentRecord[]) => {
      if (response?.length) {
        const data = response.sort((a, b) => new Date(b.accidentDate).getTime() - new Date(a.accidentDate).getTime());
        if (response.length === 1 && data[0].hasOwnProperty('noAccidents') && data[0].noAccidents) {
          this.data = [
            { propertyName: 'No Accidents', propertyValue: 'Yes' }
          ]
        }
        else {
          this.bindData(data);
        }
      }
    })
  }

  private getAccidentNature(natureAccident: string): string {
    const filteredValue = ACCIDENT_NATURE.filter((e) => e.value == natureAccident)
    if (filteredValue.length) {
      return filteredValue[0].name
    }
    return null;
  }

  private bindData(data: AccidentRecord[]): void {
    data.forEach((e) => {
      if (e.hasOwnProperty('accidentDate')) {
        const accidentRecord =
        {
          propertyName: 'Nature of Accident ', propertyValue: `${this.getAccidentNature(e.natureOfAccident)}`,
          date: `Date:  ${dayjs(e.accidentDate).format('DD MMM YYYY') as string}`,
          numOfFatalities: `Number of Fatalities:  ${e.numOfFatalities}`,
          numOfInjuries: `Number of Injuries: ${e.numOfInjuries}`,
          chemicalsSpilt: `Chemical spills: ${e.chemicalsSpilt ? 'Yes' : 'No'}`
        }
        this.data.push(accidentRecord)
      }
    })
  }
}

