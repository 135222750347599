<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-text">
  <div class="header-navigation-back">
    <mat-icon (click)="onNavigateToIdentityStart()">arrow_back</mat-icon>
    <span fxFlex class="title">Drivers License Scan</span>
  </div>

  <div class="font-family" fxLayout="column" class="loading-gif padding-bottom-xlg">
    <div *ngIf="!clientToken">
      <div fxLayoutAlign="center">
        <img src="/assets/icons/FleetOperate load.gif" />
      </div>
      <div fxLayoutAlign="center">Loading..</div>
    </div>

    <div class="berbix-launch" *ngIf="clientToken">
      <lib-berbix clientToken="{{ clientToken }}" (flowCompleted)="complete()"> </lib-berbix>
    </div>
  </div>
</div>
