<div class="noShipment" *ngIf="!isShipmentListPage">
  {{ message }}
</div>

<div class="no-results" *ngIf="isShipmentListPage">
  <div class="icons">
    <mat-icon [svgIcon]="icon"></mat-icon>
    <mat-icon svgIcon="icon-bg-round" class="bg"></mat-icon>
  </div>
  <h2 data-test="message">{{ message }}</h2>
</div>
