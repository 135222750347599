import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { first, tap, catchError, finalize } from 'rxjs/operators';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DEFAULT_ERROR_MESSAGE } from '../shared/constants';
import { SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW } from '../shared/routes';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { ShipmentAddCommentActionSheetComponent } from '../shipment-add-comment-action-sheet/shipment-add-comment-action-sheet.component';

const DELETED_DOCUMENT = 'Successfully deleted document';
const DELETED_COMMENT = 'Successfully deleted the comment';
const DELETE_ACTION = 'delete';
const EDIT_ACTION = 'edit';
const COMMENT_ACTION = 'comment';

@Component({
  selector: 'app-shipments-action-sheet',
  templateUrl: './shipments-action-sheet.component.html',
  styleUrls: ['./shipments-action-sheet.component.scss']
})
export class ShipmentsActionSheetComponent implements OnInit {
  chosenValue: string;
  loading: boolean = false;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly bottomSheetRef: MatBottomSheetRef<ShipmentsActionSheetComponent>,
    private readonly documentsDataService: DocumentsDataService,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly bottomSheet: MatBottomSheet
  ) {
    this.chosenValue = this.data.selectedValue;
  }

  ngOnInit(): void {}

  changeChosenValue(value: string): void {
    this.chosenValue = value;
  }

  onConfirm(): void {
    this.bottomSheetRef.dismiss();
    this.data.onConfirm(this.chosenValue);
  }

  downloadDocument(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.documentsDataService
      .getShipmentDocument(this.data.shipmentId, this.data.fileDocument.id)
      .pipe(
        first(),
        tap(response => {
          window.open(response.fileUrl, '_blank');
          this.loading = false;
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          this.loading = false;
          return error;
        })
      )
      .subscribe();
  }

  deleteDocument(): void {
    const documentId = this.data.fileDocument.id;
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.documentsDataService
      .deleteShipmentDocument(this.data.shipmentId, this.data.fileDocument.id)
      .pipe(
        first(),
        tap((response: any) => {
          this.toastService.showSuccess(DELETED_DOCUMENT);
          this.data?.loadShipmentDocuments();
          this.bottomSheetRef.dismiss();
          this.loading = false;
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          this.loading = false;
          return error;
        })
      )
      .subscribe();
  }

  fetchDocument(): void {
    this.bottomSheetRef.dismiss();
    this.router.navigate([
      `${SHIPMENTS_ROUTE_SHIPMENT_DOCUMENT_VIEW}/${this.data.shipmentId}/${this.data.fileDocument.id}`
    ]);
  }

  deleteEvent(type: string): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    const body = {
      events: [
        {
          id: this.data.event.id,
          [type]: ''
        }
      ]
    } as Shipment;
    this.shipmentsDataService
      .updateShipment(this.data.shipmentId, body)
      .pipe(
        first(),
        tap(response => {
          const newEvent = {
            events: [
              {
                id: this.data.event.id,
                documentId: ''
              }
            ]
          };
          const eventAction = {
            action: DELETE_ACTION,
            id: this.data.event.id,
            status: this.data.event?.status
          };
          this.data?.updateShipmentEventsActions({
            eventAction
          });
          this.toastService.showSuccess(type === COMMENT_ACTION ? DELETED_COMMENT : DELETED_DOCUMENT);
        }),
        finalize(() => {
          this.bottomSheetRef.dismiss();
          this.loading = false;
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        })
      )
      .subscribe();
  }

  openEditComment() {
    this.bottomSheet.open(ShipmentAddCommentActionSheetComponent, {
      data: {
        shipmentId: this.data.shipmentId,
        event: this.data.event,
        isEditComment: true,
        
        callback: (comment: string) => {
          const eventAction = {
            action: EDIT_ACTION,
            id: this.data.event.id,
            comment: comment,
            status: this.data.event?.status
          };
          this.data?.updateShipmentEvents(eventAction);
        },
        nextSheetActions: []
      }
    });
  }
}
