export const LOADS_ROUTE_LOADS_ENTRY = '/loads';
export const LOADS_ROUTE_MYLOADS = `${LOADS_ROUTE_LOADS_ENTRY}/my-loads`;
export const LOADS_ROUTE_MYLOADS_SHORT = 'my-loads';
export const LOADS_ROUTE_SEARCHLOADS = `${LOADS_ROUTE_LOADS_ENTRY}/search-loads`;
export const LOADS_ROUTE_SEARCHLOADS_SHORT = 'search-loads';
export const LOADS_ROUTE_ALLLOADS = `${LOADS_ROUTE_LOADS_ENTRY}/all-loads`;
export const LOADS_ROUTE_ALLLOADS_SHORT = 'all-loads';
export const LOADS_ROUTE_ON_GOING = `${LOADS_ROUTE_LOADS_ENTRY}/on-going`;
export const LOADS_ROUTE_ON_GOING_SHORT = 'on-going';
export const LOADS_ROUTE_UP_COMING = `${LOADS_ROUTE_LOADS_ENTRY}/up-coming`;
export const LOADS_ROUTE_UP_COMING_SHORT = 'up-coming';
export const LOADS_ROUTE_INTERESTS = `${LOADS_ROUTE_LOADS_ENTRY}/interests`;
export const LOADS_ROUTE_INTERESTS_SHORT = 'interests';
export const LOADS_ROUTE_LOAD_DETAILS = `${LOADS_ROUTE_LOADS_ENTRY}/load-details`;
export const LOADS_ROUTE_LOAD_DETAILS_SHORT = 'load-details';
export const LOADS_ROUTE_SHIPMENT_CONTACTS = `${LOADS_ROUTE_LOADS_ENTRY}/shipment-contacts`;
export const LOADS_ROUTE_SHIPMENT_CONTACTS_SHORT = 'shipment-contacts';
export const LOADS_ROUTE_SHIPMENT_DOCUMENTS = `${LOADS_ROUTE_LOADS_ENTRY}/shipment-documents`;
export const LOADS_ROUTE_SHIPMENT_DOCUMENTS_SHORT = 'shipment-documents';
export const LOADS_ROUTE_SHIPMENT_DOCUMENT = `${LOADS_ROUTE_SHIPMENT_DOCUMENTS}`;
export const LOADS_ROUTE_SHIPMENT_DOCUMENT_SHORT = `${LOADS_ROUTE_SHIPMENT_DOCUMENTS_SHORT}`;

