<div class="helpPageBackground" fxLayoutAlign="center stretch">
    <div fxFlexAlign="center" fxLayout="column" fxFlex="none">
      <img class="logo padding-top-xlg" src="/assets/img/fleetoperate_logo.jpg" width="300px" />
    </div>
  </div>
  <div class="contactText" fxLayout="column" fxLayoutAlign="center center">
      <span>Please contact us at</span>
      <br> 
      <a href="mailto:getonboard@fleetoperate.com">getonboard@fleetoperate.com</a>
      <br>
      <span>We will be happy to help you!</span>
  </div>
  <div fxLayout>
    <div class="full-width padding-right-sm padding-bottom-xlg padding-left-sm">
      <button
        class="full-width margin-bottom"
        mat-raised-button
        color="primary"
        type="submit"
        (click)="onNavigateToSignIn()"
      >
        OK
      </button>
    </div>
  </div>

