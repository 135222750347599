<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">DQF for {{ dqfData?.carrierCompanyName }}</div>
  </div>
  <div class="content" fxLayout="column">
    <div class="document-list-container" fxFlex="100" fxLayout="column" fxLayoutGap="20px">
      <div fxFlex fxLayout="row wrap" class="document-item-row">
        <div fxFlex="50" *ngFor="let doc of clubbedDocuments" class="document-info">
          <app-dqf-item-box
            [document]="doc"
            [carrierAttachedDocuments]="carrierAttachedDocuments"
            (navigateTo)="doc.clickHandler(doc)"
            [carrierCompanyId]="dqfData?.carrierCompanyId"
            class="document-item"
          ></app-dqf-item-box>
        </div>
      </div>
      <div class="declaration-container margin-top-xlg" *ngIf="isReview">
        <button
          fxFlex="100"
          mat-raised-button
          class="declaration-button"
          type="button"
          (click)="onReview()"
          data-test="review-button"
        >
          REVIEW
        </button>
      </div>
    </div>
  </div>
</div>
