import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TripsComponent } from './trips/trips.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StatusBannerModule } from '../shared/status-banner/status-banner.module';
import { LoadingGifModule } from '../shared/loading-gif/loading-gif.module';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { LoadsSharedModule } from '../loads-shared/loads-shared.module';
import { TripDetailsComponent } from './trip-details/trip-details.component';
import { TripsRoutingModule } from './trips-routing.module';
import {
  ROUTE_TOKEN_MY_SHIPMENT,
  ROUTE_TOKEN_SHIPMENT_CONTACTS,
  ROUTE_TOKEN_SHIPMENT_DETAILS,
  ROUTE_TOKEN_SHIPMENT_DOCUMENT,
  ROUTE_TOKEN_SHIPMENT_DOCUMENTS,
  ROUTE_TOKEN_UPCOMING_LOADS
} from '../loads-shared/route-tokens';
import {
  TRIPS_ROUTE_MYTRIPS,
  TRIPS_ROUTE_SHIPMENT_CONTACTS,
  TRIPS_ROUTE_SHIPMENT_DOCUMENT,
  TRIPS_ROUTE_SHIPMENT_DOCUMENTS,
  TRIPS_ROUTE_TRIPS_FULL_PATH,
  TRIPS_ROUTE_TRIP_DETAILS
} from './routes';
import { ShipmentActionsService } from '../loads-shared/shipment-actions.service';
import { MyTripsComponent } from './my-trips/my-trips.component';
import { ActionsModule } from '../shared/actions/actions.module';

@NgModule({
  declarations: [TripsComponent, TripDetailsComponent, MyTripsComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    StatusBannerModule,
    LoadingGifModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCardModule,
    MatDividerModule,
    MatListModule,
    LoadsSharedModule,
    TripsRoutingModule,
    ActionsModule
  ],
  providers: [
    ShipmentActionsService,
    { provide: ROUTE_TOKEN_SHIPMENT_DETAILS, useValue: TRIPS_ROUTE_TRIP_DETAILS },
    { provide: ROUTE_TOKEN_SHIPMENT_CONTACTS, useValue: TRIPS_ROUTE_SHIPMENT_CONTACTS },
    { provide: ROUTE_TOKEN_SHIPMENT_DOCUMENTS, useValue: TRIPS_ROUTE_SHIPMENT_DOCUMENTS },
    { provide: ROUTE_TOKEN_SHIPMENT_DOCUMENT, useValue: TRIPS_ROUTE_SHIPMENT_DOCUMENT },
    { provide: ROUTE_TOKEN_UPCOMING_LOADS, useValue: TRIPS_ROUTE_TRIPS_FULL_PATH },
    { provide: ROUTE_TOKEN_MY_SHIPMENT, useValue: TRIPS_ROUTE_TRIPS_FULL_PATH }
  ]
})
export class TripsModule {}
