<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5"><mat-icon svgIcon="left-arrow"></mat-icon></div>
    <div fxFlex="95" class="header-text">Upload Application</div>
  </div>
  <div class="content" fxLayout="column">
    <div fxFlex="10" class="title-container" *ngIf="false">Upload your Application for Employment</div>
    <div fxFlex="40" class="image-container" *ngIf="false">
      <img src="/assets/images/application-employment.png" class="img-note" />
    </div>
    <div fxFlex="50" class="document-list-container" fxLayout="column" *ngIf="true">
      <div fxFlex="22" class="document-list-item">
        <div fxLayout class="document-row">
          <div fxFlex="80" class="file-name">File name</div>
          <div fxFlex="20" class="delete-icon-container"><mat-icon svgIcon="delete-icon"></mat-icon></div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout class="document-row">
          <div fxFlex="70" class="date-uploaded-text">Date Uploaded</div>
          <div fxFlex="30" class="date-text">12th may 2021</div>
        </div>
      </div>
      <div fxFlex="22" class="document-list-item">
        <div fxLayout class="document-row">
          <div fxFlex="80" class="file-name">File name</div>
          <div fxFlex="20" class="delete-icon-container"><mat-icon svgIcon="delete-icon"></mat-icon></div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout class="document-row">
          <div fxFlex="70" class="date-uploaded-text">Date Uploaded</div>
          <div fxFlex="30" class="date-text">12th may 2021</div>
        </div>
      </div>
      <div fxFlex="22" class="document-list-item">
        <div fxLayout class="document-row">
          <div fxFlex="80" class="file-name">File name</div>
          <div fxFlex="20" class="delete-icon-container"><mat-icon svgIcon="delete-icon"></mat-icon></div>
        </div>
        <mat-divider></mat-divider>
        <div fxLayout class="document-row">
          <div fxFlex="70" class="date-uploaded-text">Date Uploaded</div>
          <div fxFlex="30" class="date-text">12th may 2021</div>
        </div>
      </div>
    </div>
    <div fxFlex="40" class="button-container" fxLayout="column">
      <div class="add-file-container" fxFlex="50"><div class="margin-top-md">Add File (PDF/DOC)</div></div>
      <div class="upload-button-container" fxFlex="30">
        <button mat-raised-button class="upload-button" type="button" (click)="fileToUpload.click()">
          Upload
        </button>
        <input
          mat-input
          #fileToUpload
          type="file"
          accept="application/pdf,.doc,.docx,application/msword"
          (change)="onFileChange(fileToUpload)"
          class="hidden-input-field"
        />
      </div>
    </div>
  </div>
</div>
