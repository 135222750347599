import { Component, OnInit } from '@angular/core';
import { SubSink } from 'subsink';
import { RegistrationService } from '../shared/registration.service';
import { Store } from '@ngxs/store';
import { RegistrationState, NotificationStateModel } from '../state/registration.state';
import { Notification } from 'src/app/shared/models/notification.model';
import { catchError, map, tap } from 'rxjs/operators';
import { DRIVER_RESUME, UPLOAD_STATUS } from 'src/app/shared/services/driver-api.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DocumentRequest } from 'src/app/shared/models/document-request.model';

const ERROR_MESSAGE = 'There was an error, please contact us for help';
const SUCCESS_MESSAGE = 'Your resume was successfully uploaded';
const INCORRECT_TYPE_MESSAGE = 'The file must be a pdf';
const SOURCE_TEXT = 'self';
const ISO_8601 = 'YYYY-MM-DDTHH:mm:ss[Z]';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  html: any;
  supportEmail = 'support@FleetOperate.com';
  driverSupportEmail = 'driversupport@fleetoperate.com';
  private subs = new SubSink();

  loading: boolean;
  file: any;
  errorMessage: string;
  successMessage: string;
  uploaded: boolean;
  createdDate: string;
  presentDate: Date = new Date();
  attempted: boolean;

  constructor(
    private readonly registrationService: RegistrationService,
    private readonly store: Store,
    private readonly profileService: ProfileService,
    private domSanitizer: DomSanitizer,
    private readonly datePipe: DatePipe,
    private readonly dateTimeService: DateTimeService
  ) {
    this.loading = true;
    this.errorMessage = undefined;
    this.attempted = false;
  }

  ngOnInit() {
    this.subs.add(
      this.registrationService
        .loadDriver()
        .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
        .subscribe((notificationStateModel: NotificationStateModel) => {
          if (notificationStateModel && notificationStateModel.notifications) {
            this.resumeStatus(
              this.profileService.findNotification(
                [...notificationStateModel.notifications],
                DRIVER_RESUME,
                UPLOAD_STATUS
              )
            );
          }
        })
    );
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  onSubmit(): void {
    const model = this.prepareSaveModel();

    if (model === undefined) {
      return;
    }

    this.registrationService
      .saveDocument(model, this.file)
      .pipe(
        tap(() => {
          this.setSuccessMessage(SUCCESS_MESSAGE);
          this.setErrorMessage(undefined);
        }),
        catchError((error: string) => {
          this.setErrorMessage(ERROR_MESSAGE);
          this.setSuccessMessage(undefined);
          throw error;
        })
      )
      .subscribe(() => {
        this.loading = false;
        this.attempted = true;
      });
  }

  setSuccessMessage(message: string) {
    this.successMessage = message;
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;
  }

  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const reader = new FileReader();

    const [file] = $event.files;
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.file = file;
      this.onSubmit();
    };
  }

  private resumeStatus(resume: Notification): void {
    if (resume && resume.createdDate) {
      this.createdDate = this.datePipe.transform(resume.createdDate, 'dd/MM/yyyy') as string;
      this.uploaded = true;
    } else {
      this.uploaded = false;
    }
    this.loading = false;
  }

  private prepareSaveModel(): DocumentRequest {
    const extension = this.file.name
      .split('.')
      .pop()
      .toLowerCase();

    if (extension.toLowerCase() !== 'pdf') {
      this.setErrorMessage(INCORRECT_TYPE_MESSAGE);
      this.setSuccessMessage(undefined);
      return undefined;
    }

    const driver = this.store.selectSnapshot(RegistrationState.driver);
    const model = {
      extension: extension,
      source: SOURCE_TEXT,
      generationDate: this.dateTimeService.formatDateTime(this.presentDate.toUTCString(), ISO_8601),
      validToDate: this.dateTimeService.formatDateTime(this.presentDate.toUTCString(), ISO_8601),
      reportType: 'Resume'
    };

    return model as DocumentRequest;
  }
}
