<div class="dqf-item-container" fxFlex fxLayout>
  <div fxFlex="20">
    <ngx-skeleton-loader
      appearance="circle"
      [theme]="{
        height: '61px',
        width: '61px'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div fxFlex="50" class="company-description-loading">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        width: '70%'
      }"
    ></ngx-skeleton-loader>
  </div>
  <div fxFlex="30" class="status-container">
    <ngx-skeleton-loader
      appearance="line"
      [theme]="{
        width: '50%'
      }"
    ></ngx-skeleton-loader>
  </div>
</div>
