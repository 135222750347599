export const CANADA_PROVINCES = [
  { name: 'Alberta', value: 'AB' },
  { name: 'British Columbia', value: 'BC' },
  { name: 'Manitoba', value: 'MB' },
  { name: 'New Brunswick', value: 'NB' },
  { name: 'Newfoundland and Labrador', value: 'NL' },
  { name: 'Northwest Territories', value: 'NT' },
  { name: 'Nova Scotia', value: 'NS' },
  { name: 'Nunavut', value: 'NU' },
  { name: 'Ontario', value: 'ON' },
  { name: 'Prince Edward Island', value: 'PE' },
  { name: 'Quebec', value: 'QC' },
  { name: 'Saskatchewan', value: 'SK' },
  { name: 'Yukon Territory', value: 'YT' }
];

export const USA_PROVINCES = [
  { value: 'US-AK', name: 'Alaska' },
  { value: 'US-AL', name: 'Alabama' },
  { value: 'US-AR', name: 'Arkansas' },
  { value: 'US-AS', name: 'American Samoa' },
  { value: 'US-AZ', name: 'Arizona' },
  { value: 'US-CA', name: 'California' },
  { value: 'US-CO', name: 'Colorado' },
  { value: 'US-CT', name: 'Connecticut' },
  { value: 'US-DC', name: 'District of Columbia' },
  { value: 'US-DE', name: 'Delaware' },
  { value: 'US-FL', name: 'Florida' },
  { value: 'US-GA', name: 'Georgia' },
  { value: 'US-GU', name: 'Guam' },
  { value: 'US-HI', name: 'Hawaii' },
  { value: 'US-IA', name: 'Iowa' },
  { value: 'US-ID', name: 'Idaho' },
  { value: 'US-IL', name: 'Illinois' },
  { value: 'US-IN', name: 'Indiana' },
  { value: 'US-KS', name: 'Kansas' },
  { value: 'US-KY', name: 'Kentucky' },
  { value: 'US-LA', name: 'Louisiana' },
  { value: 'US-MA', name: 'Massachusetts' },
  { value: 'US-MD', name: 'Maryland' },
  { value: 'US-ME', name: 'Maine' },
  { value: 'US-MI', name: 'Michigan' },
  { value: 'US-MN', name: 'Minnesota' },
  { value: 'US-MO', name: 'Missouri' },
  { value: 'US-MS', name: 'Mississippi' },
  { value: 'US-MT', name: 'Montana' },
  { value: 'US-NC', name: 'North Carolina' },
  { value: 'US-ND', name: 'North Dakota' },
  { value: 'US-NE', name: 'Nebraska' },
  { value: 'US-NH', name: 'New Hampshire' },
  { value: 'US-NJ', name: 'New Jersey' },
  { value: 'US-NM', name: 'New Mexico' },
  { value: 'US-NV', name: 'Nevada' },
  { value: 'US-NY', name: 'New York' },
  { value: 'US-OH', name: 'Ohio' },
  { value: 'US-OK', name: 'Oklahoma' },
  { value: 'US-OR', name: 'Oregon' },
  { value: 'US-PA', name: 'Pennsylvania' },
  { value: 'US-PR', name: 'Puerto Rico' },
  { value: 'US-RI', name: 'Rhode Island' },
  { value: 'US-SC', name: 'South Carolina' },
  { value: 'US-SD', name: 'South Dakota' },
  { value: 'US-TN', name: 'Tennessee' },
  { value: 'US-TX', name: 'Texas' },
  { value: 'US-UT', name: 'Utah' },
  { value: 'US-VA', name: 'Virginia' },
  { value: 'US-VI', name: 'Virgin Islands' },
  { value: 'US-VT', name: 'Vermont' },
  { value: 'US-WA', name: 'Washington' },
  { value: 'US-WI', name: 'Wisconsin' },
  { value: 'US-WV', name: 'West Virginia' },
  { value: 'US-WY', name: 'Wyoming' }
];

export const COUNTRY = [
  { value: 'ca', name: 'Canada' },
  { value: 'us', name: 'USA' }
];

export const CERTN_COUNTRIES = [
  { key: 'Afghanistan', value: 'AF' },
  { key: 'Aland Islands', value: 'AX' },
  { key: 'Albania', value: 'AL' },
  { key: 'Algeria', value: 'DZ' },
  { key: 'American Samoa', value: 'AS' },
  { key: 'Andorra', value: 'AD' },
  { key: 'Angola', value: 'AO' },
  { key: 'Anguilla', value: 'AI' },
  { key: 'Antarctica', value: 'AQ' },
  { key: 'Antigua and Barbuda', value: 'AG' },
  { key: 'Argentina', value: 'AR' },
  { key: 'Armenia', value: 'AM' },
  { key: 'Aruba', value: 'AW' },
  { key: 'Australia', value: 'AU' },
  { key: 'Austria', value: 'AT' },
  { key: 'Azerbaijan', value: 'AZ' },
  { key: 'Bahamas', value: 'BS' },
  { key: 'Bahrain', value: 'BH' },
  { key: 'Bangladesh', value: 'BD' },
  { key: 'Barbados', value: 'BB' },
  { key: 'Belarus', value: 'BY' },
  { key: 'Belgium', value: 'BE' },
  { key: 'Belize', value: 'BZ' },
  { key: 'Benin', value: 'BJ' },
  { key: 'Bermuda', value: 'BM' },
  { key: 'Bhutan', value: 'BT' },
  { key: 'Bolivia', value: 'BO' },
  { key: 'Bosnia and Herzegovina', value: 'BA' },
  { key: 'Botswana', value: 'BW' },
  { key: 'Bouvet Island', value: 'BV' },
  { key: 'Brazil', value: 'BR' },
  { key: 'British Virgin Islands', value: 'VG' },
  { key: 'British Indian Ocean Territory', value: 'IO' },
  { key: 'Brunei Darussalam', value: 'BN' },
  { key: 'Bulgaria', value: 'BG' },
  { key: 'Burkina Faso', value: 'BF' },
  { key: 'Burundi', value: 'BI' },
  { key: 'Cambodia', value: 'KH' },
  { key: 'Cameroon', value: 'CM' },
  { key: 'Canada', value: 'CA' },
  { key: 'Cape Verde', value: 'CV' },
  { key: 'Cayman Islands', value: 'KY' },
  { key: 'Central African Republic', value: 'CF' },
  { key: 'Chad', value: 'TD' },
  { key: 'Chile', value: 'CL' },
  { key: 'China (except Hong Kong, Macao)', value: 'CN' },
  { key: 'Christmas Island', value: 'CX' },
  { key: 'Cocos Islands', value: 'CC' },
  { key: 'Colombia', value: 'CO' },
  { key: 'Comoros', value: 'KM' },
  { key: 'Congo Brazzaville', value: 'CG' },
  { key: 'Congo Kinshasa', value: 'CD' },
  { key: 'Cook Islands', value: 'CK' },
  { key: 'Costa Rica', value: 'CR' },
  { key: "Côte d'Ivoire (Ivory Coast)", value: 'CI' },
  { key: 'Croatia', value: 'HR' },
  { key: 'Cuba', value: 'CU' },
  { key: 'Curacao', value: 'CW' },
  { key: 'Cyprus', value: 'CY' },
  { key: 'Czech Republic', value: 'CZ' },
  { key: 'Denmark', value: 'DK' },
  { key: 'Djibouti', value: 'DJ' },
  { key: 'Dominica', value: 'DM' },
  { key: 'Dominican Republic', value: 'DO' },
  { key: 'Ecuador', value: 'EC' },
  { key: 'Egypt', value: 'EG' },
  { key: 'El Salvador', value: 'SV' },
  { key: 'Equatorial Guinea', value: 'GQ' },
  { key: 'England (United Kingdom)', value: 'ENG' },
  { key: 'Eritrea', value: 'ER' },
  { key: 'Estonia', value: 'EE' },
  { key: 'Ethiopia', value: 'ET' },
  { key: 'Falkan Islands', value: 'FK' },
  { key: 'Faroe Islands', value: 'FO' },
  { key: 'Fiji', value: 'FJ' },
  { key: 'Finland', value: 'FI' },
  { key: 'France', value: 'FR' },
  { key: 'French Guiana', value: 'GF' },
  { key: 'French Polynesia', value: 'PF' },
  { key: 'French Southern Territories', value: 'TF' },
  { key: 'Gabon', value: 'GA' },
  { key: 'Gambia', value: 'GM' },
  { key: 'Georgia', value: 'GE' },
  { key: 'Germany', value: 'DE' },
  { key: 'Ghana', value: 'GH' },
  { key: 'Gibraltar', value: 'GI' },
  { key: 'Greece', value: 'GR' },
  { key: 'Greenland', value: 'GL' },
  { key: 'Grenada', value: 'GD' },
  { key: 'Guadeloupe', value: 'GP' },
  { key: 'Guam', value: 'GU' },
  { key: 'Guatemala', value: 'GT' },
  { key: 'Guernsey', value: 'GG' },
  { key: 'Guinea', value: 'GN' },
  { key: 'Guinea Bissau', value: 'GW' },
  { key: 'Guyana', value: 'GY' },
  { key: 'Haiti', value: 'HT' },
  { key: 'Heard and Mcdonald Islands', value: 'HM' },
  { key: 'Holy See (Vatican City State)', value: 'VA' },
  { key: 'Honduras', value: 'HN' },
  { key: 'Hong Kong (China)', value: 'HK' },
  { key: 'Hungary', value: 'HU' },
  { key: 'Iceland', value: 'IS' },
  { key: 'India', value: 'IN' },
  { key: 'Indonesia', value: 'ID' },
  { key: 'Islamic Republic of Iran', value: 'IR' },
  { key: 'Iraq', value: 'IQ' },
  { key: 'Ireland', value: 'IE' },
  { key: 'Isle of Man', value: 'IM' },
  { key: 'Israel', value: 'IL' },
  { key: 'Italy', value: 'IT' },
  { key: 'Jamaica', value: 'JM' },
  { key: 'Japan', value: 'JP' },
  { key: 'Jersey', value: 'JE' },
  { key: 'Jordan', value: 'JO' },
  { key: 'Kazakhstan', value: 'KZ' },
  { key: 'Kenya', value: 'KE' },
  { key: 'Kiribati', value: 'KI' },
  { key: 'Kosovo', value: 'XK' },
  { key: 'North Korea', value: 'KP' },
  { key: 'South Korea', value: 'KR' },
  { key: 'Kuwait', value: 'KW' },
  { key: 'Kyrgyzstan', value: 'KG' },
  { key: 'Laos (Lao PDR)', value: 'LA' },
  { key: 'Latvia', value: 'LV' },
  { key: 'Lebanon', value: 'LB' },
  { key: 'Lesotho', value: 'LS' },
  { key: 'Liberia', value: 'LR' },
  { key: 'Libya', value: 'LY' },
  { key: 'Liechtenstein', value: 'LI' },
  { key: 'Lithuania', value: 'LT' },
  { key: 'Luxembourg', value: 'LU' },
  { key: 'Macao (China)', value: 'MO' },
  { key: 'Republic of Macedonia', value: 'MK' },
  { key: 'Madagascar', value: 'MG' },
  { key: 'Malawi', value: 'MW' },
  { key: 'Malaysia', value: 'MY' },
  { key: 'Maldives', value: 'MV' },
  { key: 'Mali', value: 'ML' },
  { key: 'Malta', value: 'MT' },
  { key: 'Marshall Islands', value: 'MH' },
  { key: 'Martinique', value: 'MQ' },
  { key: 'Mauritania', value: 'MR' },
  { key: 'Mauritius', value: 'MU' },
  { key: 'Mayotte', value: 'YT' },
  { key: 'Mexico', value: 'MX' },
  { key: 'Federated States of Micronesia', value: 'FM' },
  { key: 'Moldova', value: 'MD' },
  { key: 'Monaco', value: 'MC' },
  { key: 'Mongolia', value: 'MN' },
  { key: 'Montenegro', value: 'ME' },
  { key: 'Montserrat', value: 'MS' },
  { key: 'Morocco', value: 'MA' },
  { key: 'Mozambique', value: 'MZ' },
  { key: 'Myanmar', value: 'MM' },
  { key: 'Namibia', value: 'NA' },
  { key: 'Nauru', value: 'NR' },
  { key: 'Nepal', value: 'NP' },
  { key: 'Netherlands', value: 'NL' },
  { key: 'Netherlands Antilles', value: 'AN' },
  { key: 'New Caledonia', value: 'NC' },
  { key: 'New Zealand', value: 'NZ' },
  { key: 'Nicaragua', value: 'NI' },
  { key: 'Niger', value: 'NE' },
  { key: 'Nigeria', value: 'NG' },
  { key: 'Niue', value: 'NU' },
  { key: 'Norfolk Island', value: 'NF' },
  { key: 'Northern Ireland (United Kingdom)', value: 'NIR' },
  { key: 'Northern Mariana Islands', value: 'MP' },
  { key: 'Norway', value: 'NO' },
  { key: 'Oman', value: 'OM' },
  { key: 'Pakistan', value: 'PK' },
  { key: 'Palau', value: 'PW' },
  { key: 'Palestinian Territory', value: 'PS' },
  { key: 'Panama', value: 'PA' },
  { key: 'Papua New Guinea', value: 'PG' },
  { key: 'Paraguay', value: 'PY' },
  { key: 'Peru', value: 'PE' },
  { key: 'Philippines', value: 'PH' },
  { key: 'Pitcairn', value: 'PN' },
  { key: 'Poland', value: 'PL' },
  { key: 'Portugal', value: 'PT' },
  { key: 'Puerto Rico', value: 'PR' },
  { key: 'Qatar', value: 'QA' },
  { key: 'Reunion', value: 'RE' },
  { key: 'Romania', value: 'RO' },
  { key: 'Russian Federation', value: 'RU' },
  { key: 'Rwanda', value: 'RW' },
  { key: 'Saint Barthelemy', value: 'BL' },
  { key: 'Saint Helena', value: 'SH' },
  { key: 'Saint Kitts and Nevis', value: 'KN' },
  { key: 'Saint Lucia', value: 'LC' },
  { key: 'Saint Martin', value: 'MF' },
  { key: 'Saint Pierre and Miquelon', value: 'PM' },
  { key: 'Saint Vincent and Grenadines', value: 'VC' },
  { key: 'Samoa', value: 'WS' },
  { key: 'San Marino', value: 'SM' },
  { key: 'Sao Tome and Principe', value: 'ST' },
  { key: 'Saudi Arabia', value: 'SA' },
  { key: 'Scotland (United Kingdom)', value: 'SCT' },
  { key: 'Senegal', value: 'SN' },
  { key: 'Serbia', value: 'RS' },
  { key: 'Seychelles', value: 'SC' },
  { key: 'Sierra Leone', value: 'SL' },
  { key: 'Singapore', value: 'SG' },
  { key: 'Slovakia', value: 'SK' },
  { key: 'Slovenia', value: 'SI' },
  { key: 'Solomon Islands', value: 'SB' },
  { key: 'Somalia', value: 'SO' },
  { key: 'South Africa', value: 'ZA' },
  { key: 'South Georgia and the South Sandwich Islands', value: 'GS' },
  { key: 'South Sudan', value: 'SS' },
  { key: 'Soviet Union (USSR)', value: 'SU' },
  { key: 'Spain', value: 'ES' },
  { key: 'Sri Lanka', value: 'LK' },
  { key: 'Sudan', value: 'SD' },
  { key: 'Suriname', value: 'SR' },
  { key: 'Svalbard and Jan Mayen Islands', value: 'SJ' },
  { key: 'Swaziland', value: 'SZ' },
  { key: 'Sweden', value: 'SE' },
  { key: 'Switzerland', value: 'CH' },
  { key: 'Syrian Arab Republic (Syria)', value: 'SY' },
  { key: 'Taiwan (Republic of China)', value: 'TW' },
  { key: 'Tajikstan', value: 'TJ' },
  { key: 'United Republic of Tanzania', value: 'TZ' },
  { key: 'Thailand', value: 'TH' },
  { key: 'Timor-Leste', value: 'TL' },
  { key: 'Togo', value: 'TG' },
  { key: 'Tokelau', value: 'TK' },
  { key: 'Tonga', value: 'TO' },
  { key: 'Trinidad and Tobago', value: 'TT' },
  { key: 'Tunisia', value: 'TN' },
  { key: 'Turkey', value: 'TR' },
  { key: 'Turkmenistan', value: 'TM' },
  { key: 'Turks and Caicos Islands', value: 'TC' },
  { key: 'Tuvalu', value: 'TV' },
  { key: 'Uganda', value: 'UG' },
  { key: 'Ukraine', value: 'UA' },
  { key: 'United Arab Emirates', value: 'AE' },
  { key: 'United Kingdom (England, Wales, Scotland, Northern Ireland)', value: 'GB' },
  { key: 'USA', value: 'US' },
  { key: 'US', value: 'US' },
  { key: 'United States of America', value: 'US' },
  { key: 'US Minor Outlying Islands', value: 'UM' },
  { key: 'Uruguay', value: 'UY' },
  { key: 'Uzbekistan', value: 'UZ' },
  { key: 'Vanuatu', value: 'VU' },
  { key: 'Venezuela (Bolivarian Republic)', value: 'VE' },
  { key: 'Viet Nam', value: 'VN' },
  { key: 'US Virgin Islands', value: 'VI' },
  { key: 'Wales (United Kingdom)', value: 'WLS' },
  { key: 'Wallis and Futuna Islands', value: 'WF' },
  { key: 'Western Sahara', value: 'EH' },
  { key: 'Yemen', value: 'YE' },
  { key: 'Zambia', value: 'ZM' },
  { key: 'Zimbabwe', value: 'ZW' },
];
