<router-outlet></router-outlet>
<mat-divider></mat-divider>
<nav *ngIf="showNavBar" mat-tab-nav-bar class="fixed-bottom">
  <a
    mat-tab-link
    fxFlex
    *ngFor="let link of links"
    (click)="link.clickHandler()"
    [ngClass]="link.active ? 'active' : ''"
    [attr.data-test]="link.title"
  >
    <label>
      <mat-icon fxFlex [svgIcon]="link.icon" data-test="icon"></mat-icon>
      <span>{{ link.title }}</span>
    </label>
  </a>
</nav>
