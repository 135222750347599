import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/internal/operators/catchError';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { License } from 'src/app/shared/models/license.model';
import { CANADA_PROVINCES, USA_PROVINCES } from 'src/app/shared/models/provinces';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { environment } from 'src/environments/environment';
import { MAT_DAYJS_DATE_ADAPTER_OPTIONS } from '@tabuckner/material-dayjs-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { SubSink } from 'subsink';
import { Location } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { of } from 'rxjs';
import { Router } from '@angular/router';

const ACTION_UPDATE_LICENSE = 'Update License';
const BERBIX_STATUS_ACTIVE = 'active';
const BERBIX_STATUS_FAILED = 'failed';
const BERBIX_STATUS_PROCESSING = 'processing';
const PROCESSSING_STATUS_MESSAGE = `<span>Your license information is being processed, it will take</span
><strong> few minutes </strong><span>to complete the process</span>`;
const FAILED_STATUS_MESSAGE = `<span>We were unable to process the scanned driver license. Please fill your driver license information below</span>`;
const LICENSE_VIEW_PATH = `license/license-view`;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY'
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-profile-license-information',
  templateUrl: './profile-license-information.component.html',
  styleUrls: ['./profile-license-information.component.scss'],
  providers: [
    { provide: MAT_DAYJS_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class ProfileLicenseInformationComponent implements OnInit, OnDestroy {
  licenseForm = new FormGroup({});
  REQUIRED_FIELD_MESSAGE = 'You must enter a value';
  loading: boolean;
  issuingCountry = [
    { code: 'ca', value: 'Canada' },
    { code: 'us', value: 'USA' }
  ];
  issuingProvinces = CANADA_PROVINCES;
  issuingStateProvinces = [];
  licenseClassNonON = ['Class 1', 'Class 2', 'Class 3', 'Class 4', 'Class 5'];

  licenseClassON = ['Class A', 'Class B', 'Class C', 'Class D', 'Class E', 'Class F '];

  usStates = USA_PROVINCES;
  stateProvinces = [];
  provinceLicenseClass = [];
  licenseClass = [];
  isExplain = false;
  isOntario = false;
  errorMessage = null;
  driverLicenseId = null;
  currentStatus: string;
  currentStatusMessage: string;
  maxDate = new Date();
  expiryMinDate: Date = new Date();
  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly registrationService: RegistrationService,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.loading = false;
    this.createForm();
  }

  ngOnInit(): void {
    this.getLicense(false);
    this.licenseForm.get('issuingProvince').valueChanges.subscribe(element => {
      if (element === 'ON') {
        this.licenseForm.get('hasAirBreakEndorsment').setValidators([Validators.required]);
      } else {
        this.licenseForm.get('hasAirBreakEndorsment').setValidators(null);
      }
      this.licenseForm.get('hasAirBreakEndorsment').updateValueAndValidity();
    });

    this.licenseForm.get('hasDeniedLicense').valueChanges.subscribe(element => {
      this.updateControlValidity(element, 'deniedLicenseExplanation');
    });

    this.licenseForm.get('hasSuspendedLicense').valueChanges.subscribe(element => {
      this.updateControlValidity(element, 'suspendedLicenseExplanation');
    });
  }

  createForm(): void {
    this.licenseForm = this.fb.group({
      licenseNumber: ['', Validators.required],
      issueDate: ['', Validators.required],
      expiryDate: ['', Validators.required],
      issuingCountry: ['', Validators.required],
      issuingProvince: ['', Validators.required],
      licenseType: ['', Validators.required],
      hasAirBreakEndorsment: [null, Validators.required],
      hasDeniedLicense: [null, Validators.required],
      deniedLicenseExplanation: [null, Validators.required],
      hasSuspendedLicense: [null, Validators.required],
      suspendedLicenseExplanation: [null, Validators.required]
    });
  }

  issueDateChangeEvent(event: MatDatepickerInputEvent<Date>) {
    this.setMinDateFormExpiry(event.value);
  }

  onCountryChange(countryCode: string): void {
    this.setStateProvinces(countryCode);
    this.licenseForm.get('licenseType').patchValue(null);
  }

  private setStateProvinces(countryCode): void {
    this.stateProvinces = countryCode === 'ca' ? this.issuingProvinces : this.usStates;
  }

  onProvinceChange(province: string): void {
    this.licenseForm.get('licenseType').patchValue(null);
    this.setLicense(province);
  }

  setDeniedLicense(value): void {
    this.licenseForm.get('hasDeniedLicense').patchValue(value);
  }

  setAirBrakeEndorsement(value: boolean): void {
    this.licenseForm.get('hasAirBreakEndorsment').patchValue(value);
  }

  setPermitSuspended(value: boolean): void {
    this.licenseForm.get('hasSuspendedLicense').patchValue(value);
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    if (this.licenseForm.valid) {
      const model = new License(this.licenseForm.value);

      if (!model.issueDate) {
        delete model.issueDate;
      }
      model.issueDate = new Date(new Date(model.issueDate).setHours(0, 0, 0, 0)).toISOString().replace('.000Z', 'Z');
      model.expiryDate = new Date(new Date(model.expiryDate).setHours(0, 0, 0, 0)).toISOString().replace('.000Z', 'Z');
      if (this.driverLicenseId) {
        this.updateLicense(model);
      }
    }
  }
  updateControlValidity(element: any, formControlName: string) {
    if (element) {
      this.licenseForm.get(formControlName).setValidators([Validators.required]);
    } else {
      this.licenseForm.get(formControlName).setValidators(null);
      this.licenseForm.get(formControlName).patchValue(null);
    }
    this.licenseForm.get(formControlName).updateValueAndValidity();
  }

  private updateLicense(model: License): void {
    this.loading = true;
    this.subs.sink = this.registrationService
      .updateLicense(model, this.driverLicenseId)
      .pipe(
        catchError((error: any) => {
          this.errorMessage = environment.errorMessage;
          this.loading = false;
          return of(false);
        })
      )
      .subscribe(() => {
        this.errorMessage = undefined;
        this.getLicense(true);
      });
  }

  private getLicense(fetchLicense): void {
    if (fetchLicense) {
      this.loading = true;
      this.registrationService
        .getLicense()
        .pipe(
          catchError((error: any) => {
            this.loading = false;
            this.goBack();
            return of(false);
          })
        )
        .subscribe((response: License) => {
          if (response) {
            this.loading = false;
            this.goBack();
          }
        });
    } else {
      const license = this.registrationService.getLicenseStore();
      if (license) {
        this.driverLicenseId = license.id;
        if (license?.status) {
          this.currentStatus = license.status;
          if (this.currentStatus == BERBIX_STATUS_PROCESSING) {
            this.currentStatusMessage = PROCESSSING_STATUS_MESSAGE;
          }
          if (this.currentStatus == BERBIX_STATUS_FAILED) {
            this.currentStatusMessage = FAILED_STATUS_MESSAGE;
          }
        }

        this.setStateProvinces(license.issuingCountry);
        this.setLicense(license.issuingProvince);
        this.setMinDateFormExpiry(license.issueDate);

        this.licenseForm.patchValue(license, { emitEvent: true });
        this.updateControlValidity(license.hasDeniedLicense, 'deniedLicenseExplanation');

        this.updateControlValidity(license.hasSuspendedLicense, 'suspendedLicenseExplanation');
        this.cdr.detectChanges();
      }
    }
  }

  openLicenseView(value): void {
    this.router.navigate([`${LICENSE_VIEW_PATH}`], { queryParams: { title: value, id: this.driverLicenseId } });
  }

  private setLicense(province: string): void {
    this.isOntario = false;
    this.provinceLicenseClass = this.licenseClassNonON;
    if (province === 'ON') {
      this.isOntario = true;
      this.provinceLicenseClass = this.licenseClassON;
    }
  }
  setMinDateFormExpiry(issueDate: string | Date): void {
    this.expiryMinDate.setDate(this.expiryMinDate.getDate());
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
