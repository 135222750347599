import { Component, OnInit, Inject, forwardRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubSink } from 'subsink';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { ShipmentDocumentsService } from '../shipment-data/shipment-documents.service';
import { ROUTE_TOKEN_SHIPMENT_DOCUMENTS } from '../route-tokens';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { AppService } from 'src/app/app.service';

const ERROR_MESSAGE = 'There was an error. Please try again.';
const HistoryDocumentPath = 'driverHistory/shipment-documents';

@Component({
  selector: 'app-shipment-document',
  templateUrl: './shipment-document.component.html',
  styleUrls: ['./shipment-document.component.scss']
})
export class ShipmentDocumentComponent implements OnInit {
  errorMessage: string;
  previewImage: any;
  fileType: string;
  showNavBar: boolean;

  private shipmentId: string;
  private documentId: string;
  private shipmentDocument: ShipmentDocument;
  private subSink: SubSink;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly shipmentDocumentsService: ShipmentDocumentsService,
    private readonly dateTimeService: DateTimeService,
    private readonly appService: AppService,
    @Inject(forwardRef(() => ROUTE_TOKEN_SHIPMENT_DOCUMENTS)) public routeShipmentDocuments: string
  ) {
    this.showNavBar = true;
    this.subSink = new SubSink();
  }

  ngOnInit() {
    const path = this.router.url;
    if (this.appService.isNativeApp && path.includes(HistoryDocumentPath)) {
      this.showNavBar = false;
    }

    this.shipmentId = this.activatedRoute.snapshot.paramMap.get('id');
    this.documentId = this.activatedRoute.snapshot.paramMap.get('document-id');
    this.subSink.add(
      this.shipmentDocumentsService.$shipmentDocument.subscribe((shipmentDocument: ShipmentDocument) => {
        shipmentDocument.createdDate = this.dateTimeService.getConvertedDateTimeFromUTC(shipmentDocument.createdDate);
        this.shipmentDocument = shipmentDocument;

        if (!this.shipmentDocument || !this.shipmentDocument.fileUrl || !this.shipmentDocument.type) {
          this.errorMessage = ERROR_MESSAGE;
        } else {
          this.previewImage = this.shipmentDocument.fileUrl;
          this.fileType = this.shipmentDocument.type;
        }
      })
    );

    if (this.shipmentId) {
      this.loadShipmentDocument(this.shipmentId, this.documentId);
    }
  }

  onNavigateBack(): void {
    this.router.navigate([this.routeShipmentDocuments, this.shipmentId]);
  }

  private loadShipmentDocument(shipmentId: string, documentId: string): void {
    this.shipmentDocumentsService.loadShipmentDocument(shipmentId, documentId);
  }
}
