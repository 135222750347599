import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { SubSink } from 'subsink';
import { catchError, finalize, first, tap } from 'rxjs/operators';

import { DriverQualificationService } from '../service/driver-qualification.service';
import { Router } from '@angular/router';
import { ROUTE_DQF, ROUTE_DQF_DOCUMENT_UPLOAD, ROUTE_DQF_REVIEW } from 'src/app/shared/routes';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Driver } from 'src/app/shared/models/driver.model';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { CompleteProfileSheetComponent } from '../../driver-qualification-files/complete-profile-sheet/complete-profile-sheet.component';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { forkJoin, of } from 'rxjs';

const DQF_STATUS_CREATED = 'Created';
const DQF_STATUS_PROCESSING = 'Processing';

const PROFILE_STATUS_INCOMPLETE = 'Incomplete';

const DQF_STATUS_PENDINGAPPROVAL = 'PendingCarrierApproval';
const DQF_STATUS_REJECTED = 'Rejected';
const DQF_STATUS_APPROVED = 'Approved';
const DQF_STATUS_CARRIERPROCESSINGCOMPLETED = 'CarrierProcessingCompleted';
const DQF_STATUS_APPROVEDPROCESSING = 'ApprovedProcessing';
const STATUS_CODE = 200;
const GET_DQFS = 'get all dqfs';
const GET_CARRIER_LOGO = 'get carrier company logo';
const DEAFULT_COMPANY_LOGO = '/assets/images/default-profile-picture.png';
const DISABLE_TOP_LOADING_BAR = true;
const DQF_STATUS_WITHDRAWN = 'Withdrawn';

@Component({
  selector: 'app-dqf-list',
  templateUrl: './dqf-list.component.html',
  styleUrls: ['./dqf-list.component.scss']
})
export class DqfListComponent implements OnInit, OnDestroy {
  dqfs: any[] = [];
  private subs = new SubSink();
  driver: Driver;
  isContentReady: boolean = false;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
    private readonly bottomSheet: MatBottomSheet,
    private readonly driverQualificationService: DriverQualificationService,
    private readonly registrationService: RegistrationService,
    private readonly driverApiService: DriverApiService,
    private readonly timeService: DateTimeService
  ) {}

  ngOnInit(): void {
    this.subs.sink = this.registrationService.loadDriver(DISABLE_TOP_LOADING_BAR).subscribe((response: Driver) => {
      if (response) {
        this.driver = response;
      }
    });
    this.loadDQFs();
  }
  loadDQFs() {
    this.isContentReady = false;
    this.subs.sink = this.driverQualificationService
      .getDqfs()
      .pipe(
        first(),
        tap(response => {
          if (response && response.length > 0) {
            this.dqfs = this.registrationService.getDqfsWithDriverType(response);
            const uniqueCarrierIds = this.getUniqueCarrierCompanyId();
            this.isContentReady = false;
            this.assignCarrierCompanyLogo(uniqueCarrierIds);
          }
        }),
        catchError((error: any) => {
          this.isContentReady = true;
          return of(false);
        })
      )
      .subscribe();
  }
  backAction(): void {
    this.location.back();
  }
  onDqfItemClick(dqf): void {
    let id = dqf.id;
    if (dqf.status == DQF_STATUS_CREATED) {
      if (this.driver.identityStatus == PROFILE_STATUS_INCOMPLETE) {
        let carrierName = dqf.carrierCompanyName;
        const dialogRef = this.bottomSheet.open(CompleteProfileSheetComponent, {
          panelClass: 'signature-panel',
          disableClose: true,
          data: { carrierName: carrierName }
        });
        dialogRef.afterDismissed().subscribe(res => {
          this.registrationService.navigateToProfile();
        });
      } else {
        let dqfStatus = {
          status: DQF_STATUS_PROCESSING
        };
        this.driverQualificationService.updateDqfStatus(id, dqfStatus).subscribe(res => {
          if (res && res.status === STATUS_CODE) {
            this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_UPLOAD}/${id}`]);
          }
        });
      }
    } else {
      if (this.isApproved(dqf.status) || dqf.status === DQF_STATUS_PENDINGAPPROVAL) {
        this.driverQualificationService.dqfData = dqf;
        this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_REVIEW}/${dqf.id}`]);
      } else {
        this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_DOCUMENT_UPLOAD}/${id}`]);
      }
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  isInComplete(status: string): boolean {
    if (status == DQF_STATUS_CREATED || status == DQF_STATUS_PROCESSING) {
      return true;
    } else {
      return false;
    }
  }
  isPending(status): boolean {
    return status && status == DQF_STATUS_PENDINGAPPROVAL ? true : false;
  }
  isRejected(status): boolean {
    return status && status == DQF_STATUS_REJECTED ? true : false;
  }
  isApproved(status): boolean {
    return (
      status &&
      [DQF_STATUS_APPROVED, DQF_STATUS_CARRIERPROCESSINGCOMPLETED, DQF_STATUS_APPROVEDPROCESSING].includes(status)
    );
  }
  isWithdrawn(status): boolean {
    return status && status == DQF_STATUS_WITHDRAWN;
  }
  assignCarrierCompanyLogo(uniqueCarrierId: any[]): void {
    this.isContentReady = false;
    let carrierLogoCalls = [];
    uniqueCarrierId.forEach(unique => {
      carrierLogoCalls.push(this.driverQualificationService.getCarrierCompanyLogo(unique));
    });
    forkJoin(...carrierLogoCalls)
      .pipe(
        first(),
        tap(response => {
          if (response && response.length)
            this.dqfs.forEach(dqf => {
              response.forEach(res => {
                if (dqf.carrierCompanyId === res.id) {
                  if (res?.companyLogo) {
                    dqf.carrierCompanyLogo = res.companyLogo;
                  } else {
                    dqf.carrierCompanyLogo = DEAFULT_COMPANY_LOGO;
                  }
                } else {
                  dqf.carrierCompanyLogo = DEAFULT_COMPANY_LOGO;
                }
              });
            });
        }),
        catchError((error: any) => {
          this.dqfs.forEach(dqf => {
            dqf.carrierCompanyLogo = DEAFULT_COMPANY_LOGO;
          });
          return of(false);
        }),
        finalize(() => {
          this.isContentReady = true;
        })
      )
      .subscribe();
  }
  private getUniqueCarrierCompanyId(): any[] {
    let uniqueCarrierId = [];
    if (this.dqfs.length > 1) {
      for (let i = 0; i < this.dqfs.length; i++) {
        if (this.dqfs[i]?.carrierCompanyId != this.dqfs[i + i]?.carrierCompanyId) {
          uniqueCarrierId.push(this.dqfs[i]?.carrierCompanyId);
        }
      }
    } else {
      uniqueCarrierId.push(this.dqfs[0].carrierCompanyId);
    }
    return uniqueCarrierId;
  }
}
