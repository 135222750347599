import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dqf-outlet',
  templateUrl: './dqf-outlet.component.html',
  styleUrls: ['./dqf-outlet.component.scss']
})
export class DqfOutletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
