import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {

  @Input() reset: Observable<boolean>;
  @Input() disabled: boolean;
  @Output() fileChange: EventEmitter<any>;

  errorMessage: string;
  previewImage: any;
  acceptTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  private subSink: SubSink;

  constructor() {
    this.subSink = new SubSink();
    this.fileChange = new EventEmitter<any>();
    this.disabled = false;
  }

  ngOnInit() {
    this.subSink.add(this.reset.subscribe((reset: boolean) => {
      if (reset) {
        this.errorMessage = undefined;
        this.previewImage = undefined;
      }
    }));
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  onFileChange($event: any): void {
    if (!$event || $event.files.length === 0) {
      return;
    }

    const files = $event.files;
    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.errorMessage = 'Only images are supported.';
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (event) => {
      this.previewImage = reader.result;
      this.fileChange.emit($event);
    };
  }
}
