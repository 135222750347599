<div fxLayoutAlign="start" fxLayout="column" class="page full-height gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon data-test="back-arrow-icon" (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Shipment Details</span>
  </div>
  <div fxLayout="column" class="full-height" *ngIf="isContentReady">
    <div fxLayout="column" class="section">
      <div fxLayout class="shipment-meta-container">
        <div class="shipment-id-container">
          <div class="shipment-id">{{ shipment.shipmentID || '-' }}</div>
          <br />
          <div class="shipment-type" *ngIf="!isConfirmationPending">{{ shipmentType }}</div>
        </div>
        <div fxFlex="40" class="shipment-status-container">
          <div [ngClass]="[getStatusClass(), 'status-tag']">
            {{ shipment.status === 'out for delivery' ? 'In Transit' : shipment.status | titlecase }}
          </div>
        </div>
      </div>
      <div fxLayoutGap="10px" fxLayout="column" class="origin-des-container">
        <div fxLayout fxFlex="30" class="origin-des">
          <div class="text-align-left">{{ shipment.origin || '-' }}</div>
          <div fxFlex="30" fxLayout class="location-icons-container">
            <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
            <div fxFlex="55" class="border-bottom"></div>
            <mat-icon fxFlex="15" svgIcon="location-mark"></mat-icon>
          </div>
          <div class="text-align-right">{{ shipment.destination || '-' }}</div>
        </div>
        <div fxLayout class="address">
          <div fxFlex="40" class="text-align-left">{{ shipment.pickup.streetAddress || '-' }}</div>
          <div fxFlex="40" class="text-align-right">{{ shipment.delivery.streetAddress || '-' }}</div>
        </div>
        <div fxLayout class="time">
          <div fxFlex="40" class="text-align-left">{{ pickupFormattedDateTime || '-' }}</div>
          <div fxFlex="40" class="text-align-right">{{ deliveryFormattedDateAndTime || '-' }}</div>
        </div>
      </div>
      <div fxFlex="10" *ngIf="shipment.status !== 'confirmation pending'">
        <div class="margin-top-md">
          <button
            mat-button
            class="btn-accept"
            (click)="onChangeShipmentStatus()"
            [disabled]="disableChangeStatus()"
            data-test="change-status-button"
          >
            Change Status
          </button>
          <div class="margin-top-md contact-row" *ngIf="shipment.status !== 'delivered'">
            <div (click)="onAddShipmentComment()"><mat-icon svgIcon="comments" data-test="comment"></mat-icon></div>
            <div (click)="onAddShipmentPhoto()"><mat-icon svgIcon="camera" data-test="camera"></mat-icon></div>
            <div (click)="getContact()"><mat-icon svgIcon="phonecall" data-test="phonecall"></mat-icon></div>
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="shipment-content" *ngIf="isContentReady">
      <mat-tab-group
        *ngIf="!isConfirmationPending"
        (selectedTabChange)="tabChanged($event)"
        data-test="content-tabs"
        [selectedIndex]="tabIndex"
      >
        <mat-tab label="DOCUMENTS" data-test="documents-tab">
          <div class="spinner-container" *ngIf="isProcessingDocuments">
            <mat-spinner diameter="30"></mat-spinner>
          </div>
          <div class="detail-panel-body no-scroll" *ngIf="!isProcessingDocuments">
            <app-shipment-sub-documents
              [shipmentId]="shipment.id"
              [shipmentDocuments]="shipmentDocuments"
              (updateShipmentDocuments)="getShipmentDocuments()"
            ></app-shipment-sub-documents>
          </div>
        </mat-tab>
      </mat-tab-group>
      <div *ngIf="isConfirmationPending">
        <app-shipment-sub-detail [shipment]="shipment"></app-shipment-sub-detail>
      </div>
    </div>
    <div class="margin-sm" *ngIf="isConfirmationPending">
      <button mat-button class="btn-accept" (click)="confirmShipment()" data-test="confirm-button">Confirm</button>
    </div>
  </div>
</div>
