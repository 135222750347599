import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ROUTE_FORGOT_PASSWORD, ROUTE_NOTIFICATION_SENT, ROUTE_CONFIRM_FORGOT_PASSWORD } from './shared/routes';
import { NotificationSentComponent } from './notification-sent/notification-sent.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordGuardService } from './shared/guards/forgot-password-guard.service';

const routes: Routes = [
  { path: ROUTE_FORGOT_PASSWORD, component: ForgotPasswordComponent},
  { path: ROUTE_NOTIFICATION_SENT, component: NotificationSentComponent},
  { path: ROUTE_CONFIRM_FORGOT_PASSWORD, component: ChangePasswordComponent/*, canActivate: [ForgotPasswordGuardService]*/}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ForgotPasswordRoutingModule { }
