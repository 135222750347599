import { Component, ElementRef, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { PhotoPickerActionSheetComponent } from 'src/app/shared/components/photo-picker-action-sheet/photo-picker-action-sheet.component';
import { ShipmentDocumentsService } from 'src/app/loads-shared/shipment-data/shipment-documents.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { Observable, of } from 'rxjs';
import { first, catchError } from 'rxjs/operators';

const ACTION_UPLOAD_DOCUMENT = 'Upload document';
const DOCUMENT_TYPE = 'Tracking';

@Component({
  selector: 'app-shipment-add-photo-action-sheet',
  templateUrl: './shipment-add-photo-action-sheet.component.html',
  styleUrls: ['./shipment-add-photo-action-sheet.component.scss']
})
export class ShipmentAddPhotoActionSheetComponent {
  saving: boolean;
  actions: Action[] = [];
  nextSheetActions: Component[];
  shipment: Shipment;
  totalActions: number;
  currentActionStep: number;
  currentData: any;
  callback: Function;
  @ViewChild('comment') comment: ElementRef;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<ShipmentAddPhotoActionSheetComponent>,
    private readonly bottomSheet: MatBottomSheet,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly shipmentDocumentsService: ShipmentDocumentsService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly driverApiService: DriverApiService,
    private readonly dateTimeService: DateTimeService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.shipment = data?.shipment;
    this.nextSheetActions = data?.nextSheetActions;
    this.totalActions = data?.totalActions ?? this.nextSheetActions?.length ?? 0;
    this.currentActionStep = data?.currentActionStep ?? 1;
    this.currentData = data?.currentData;
    this.callback = data?.callback;
  }

  onUploadPhoto(): void {
    const photoSheetRef = this.bottomSheet.open(PhotoPickerActionSheetComponent);
    photoSheetRef.afterDismissed().subscribe((files: any) => {
      if (this.currentData && files) {
        this.saveShipment().subscribe(() => {
          files.forEach(file => {
            this.uploadDocument(file, file.name, DOCUMENT_TYPE);
          });
          this.callback?.(this.currentData?.status);
        });
      } else if (files) {
        files.forEach((file, index) => {
          this.uploadDocument(file, file.name, DOCUMENT_TYPE);
        });
        this.callback?.(this.currentData?.status);
      } else {
        this.bottomSheetRef.dismiss();
      }
    });
  }

  onSkipOrCancel(): void {
    if (this.totalActions) {
      this.saveShipment().subscribe(() => {
        this.bottomSheetRef.dismiss();
        this.callback?.(this.currentData?.status);
      });
    } else {
      this.bottomSheetRef.dismiss();
    }
  }

  private uploadDocument(file: any, fileName: string, documentType: string): void {
    this.saving = true;
    const extension = fileName
      .split('.')
      .pop()
      .toLowerCase();

    this.shipmentDocumentsService
      .sendDocument(this.shipment.id, file, documentType, extension)
      .pipe(
        first(),
        catchError((error: any) => {
          this.callback?.(this.currentData?.status);
          return of(false);
        })
      )
      .subscribe(() => {});
  }

  private saveShipment(): Observable<Shipment> {
    this.saving = true;
    return this.shipmentsDataService.updateShipment(this.shipment.id, this.currentData).pipe(
      catchError(error => {
        this.saving = false;
        this.changeDetectorRef.detectChanges();
        throw error;
      })
    );
  }
}
