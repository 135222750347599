<div class="full-width" data-test="fix-now-banner">
  <div class="content" fxLayout="column">
    <div fxFlex="10" fxLayout class="header-container">
      <div fxFlex="75">
        <div class="status">Rejected</div>
      </div>
      <div fxFlex="10" class="icon-container">
        <mat-icon svgIcon="crossed" class="svg-icon" (click)="closeSheet('close')"></mat-icon>
      </div>
    </div>
    <div fxFlex="15">
      <div class="title" data-test="rejected-document-name">{{ title }}</div>
    </div>
    <div fxFlex="20" class="reason-text">
      {{ reason }}
    </div>
    <div fxFlex="15">
      <button mat-raised-button type="submit" color="primary" class="save-button" (click)="closeSheet('fixNow')" data-test="fix-now-button">
        Fix Now
      </button>
    </div>
  </div>
</div>
