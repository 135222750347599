<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family employment-history">
  <div class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Unemployment Form</span>
  </div>
  <div>
    <mat-error *ngIf="errorMessage" class="error padding-left-md">
      {{ errorMessage }}
    </mat-error>
  </div>
  <app-skeleton-emp-residence-history
    *ngIf="!isContentReady"
    isResidenceHistory="false"
  ></app-skeleton-emp-residence-history>
  <div [hidden]="!isContentReady">
    <div class="content" fxLayout="column" fxLayoutGap="15px">
      <div fxLayout="column" class="component-container">
        <div [formGroup]="unEmpForm" fxLayout="column" class="addUnemployment">
          <div class="form-title">{{ editFormTitle }}</div>
          <div fxFlex fxFlexAlign="end">
            <button mat-button class="btn-save" (click)="onSubmit(unEmpForm)" [disabled]="!unEmpForm.valid || errorMessage">
              Save
            </button>
            <button mat-button class="btn-cancel" (click)="onCancel()">Cancel</button>
            <button mat-raised-button *ngIf="enableLogButton"
            (click)="onLog();"
            color="primary">Log Form</button>
          </div>
      
          <div class="address-fields" fxLayout="column">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="From (month, year)"
                [matDatepicker]="unEmpFromDatePicker"
                formControlName="fromDate"
                [max]="unEmpToDate.value || maxToDate"
                [min]="minFromDate"
                required
                (click)="onDatePickerClick(unEmpFromDatePicker)"
                onkeydown="return false;"
              />
              <mat-datepicker-toggle matSuffix [for]="unEmpFromDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #unEmpFromDatePicker
                startView="multi-year"
                touchUi="true"
                (monthSelected)="chosenMonthHandler($event, unEmpFromDatePicker, unEmpForm)"
              >
              </mat-datepicker>
              <mat-error *ngIf="unEmpFromDate.touched && unEmpFromDate.invalid">
                {{ getunEmpFromDateErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="To (month, year)"
                [matDatepicker]="unEmpToDatePicker"
                formControlName="toDate"
                [max]="maxToDate"
                [min]="unEmpFromDate.value || minFromDate"
                [disabled]="isFirstRecord || isCurrentUnemployment?.value"
                required
                (click)="onDatePickerClick(unEmpToDatePicker)"
                onkeydown="return false;"
              />
              <mat-datepicker-toggle matSuffix [for]="unEmpToDatePicker"></mat-datepicker-toggle>
              <mat-datepicker
                #unEmpToDatePicker
                startView="multi-year"
                touchUi="true"
                (monthSelected)="chosenMonthHandler($event, unEmpToDatePicker, unEmpForm, 'toDateControl')"
              >
              </mat-datepicker>
              <mat-error *ngIf="unEmpToDate.touched && unEmpToDate.invalid">
                {{ getunEmpToDateErrorMessage() }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width">
              <input matInput placeholder="Reason" formControlName="reason" data-test="reason-form-field" />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
