<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family" data-test="upload-document">
  <div class="header-navigation-back" fxLayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5" data-test="back-arrow-icon">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">{{ heading }}</div>
  </div>
  <div class="content" fxLayout="column">
    <div class="status" *ngIf="showStatus" [ngClass]="!currentSelectedData?.id ? 'pending' : 'complete'">
      <div class="info">
        <p>{{ currentStatus }}</p>
      </div>
      <div class="cross-icon" (click)="closeNotification()"><mat-icon svgIcon="crossed"> </mat-icon></div>
    </div>

    <div class="document-list-container" fxFlex="100" fxLayout="column" fxLayoutGap="20px">
      <div class="document-item-row">
        <div fxFlex="50" *ngFor="let doc of documents" class="document-info">
          <app-dqf-item-box
            [document]="doc"
            (navigateTo)="doc.clickHandler(doc)"
            class="document-item"
          ></app-dqf-item-box>
        </div>
      </div>
    </div>
  </div>
</div>
