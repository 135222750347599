<div class="accident-record" data-test="accident-record">
  <div class="info">
    <p class="accident-date">{{ model.accidentDate | date: 'dd MMM yyyy' }}</p>
    <p class="accident-nature">{{ getAccidentNatureValue(model.natureOfAccident) || getAccidentTitle(model) }}</p>
  </div>
  <div class="actions">
    <mat-icon svgIcon="edit-black" (click)="onUpdateAccident()" *ngIf="!model.noAccidents" data-test="edit-accident-record"></mat-icon>
    <mat-icon svgIcon="delete-sweep-outlined" (click)="onDeleteAccident()" data-test="delete-accident-record"></mat-icon>
  </div>
</div>
