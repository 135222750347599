import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { WebcamModule } from 'ngx-webcam';
import { IdentityCheckComponent } from './identity-check/identity-check.component';
import { MaterialModule } from '../shared/material/material.module';
import { ActionsModule } from '../shared/actions/actions.module';
import { StatusBannerModule } from '../shared/status-banner/status-banner.module';
import { LicenseStatusComponent } from './license-status/license-status.component';
import { IdentityStartComponent } from './identity-start/identity-start.component';
import { IdentityLaunchComponent } from './identity-launch/identity-launch.component';
import { BerbixModule } from 'berbix-angular';
import { MatIconModule } from '@angular/material/icon';
import { IdentityActionSheetComponent } from './identity-action-sheet/identity-action-sheet.component';
import { CodeExchangeModule } from '../shared/code-exchange/code-exchange.module';
import { IdentityLicenseUploadActionSheetComponent } from './identity-license-upload-action-sheet/identity-license-upload-action-sheet.component';
import { ImageCropperModule } from 'ngx-image-cropper';

@NgModule({
  declarations: [
    IdentityCheckComponent,
    LicenseStatusComponent,
    IdentityStartComponent,
    IdentityLaunchComponent,
    IdentityActionSheetComponent,
    IdentityLicenseUploadActionSheetComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    WebcamModule,
    MaterialModule,
    ActionsModule,
    StatusBannerModule,
    BerbixModule,
    MatIconModule,
    CodeExchangeModule,
    ImageCropperModule
  ]
})
export class IdentityCheckModule {}

export { IdentityCheckComponent } from './identity-check/identity-check.component';
