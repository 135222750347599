<div fxLayout="column" class="content">
  <div class="brand-title">
    <img src="/assets/img/fleetoperate_logo.jpg" />
  </div>

  <div fxLayout="column" fxFlexAlign="center">
    <div class="title">Looks like you tried to login to the wrong <span>FleetOperate</span> application.</div>
    <div class="margin-top-md message">
      Please <a class="app-link" href="{{ driverUrl }}">click here</a> to login to driver application.
    </div>
  </div>
  <div fxLayout="row" class="footer">
    <div>
      <span class="copy-right">&copy; Copyright {{ copyrightYear }} FleetOperate</span>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="center">
      <div class="contact default-background">
        <a (click)="onOpenContactUsDialog()">Contact</a> |
        <a href="{{ fleetOperateUrl }}" target="_blank">fleetoperate.com</a>
      </div>
    </div>
  </div>
</div>
