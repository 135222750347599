import { Component, Inject, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ShipmentActionsService } from 'src/app/loads-shared/shipment-actions.service';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { SHIPMENT_STATUSES } from 'src/app/loads-shared/shipment-data/models/shipment-status';
import { Action } from 'src/app/shared/actions/models/action.model';
import { InformationDialogService } from 'src/app/shared/dialog/information-dialog.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { GeneralSearchService } from 'src/app/shared/services/general-search/general-search.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DEFAULT_ERROR_MESSAGE, LOAD_SIZE_FTL } from '../shared/constants';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_FILTER_SHIPMENTS } from '../shared/routes';
import { SHIPMENTS_LIST } from '../shared/sample-data';
import { ShipmentsActionsService } from '../shared/shipments-actions.service';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { ShipmentsUtilsService } from '../shared/shipments-utils.service';

const MM_DD_YYYY = 'MM/DD/YYYY';
const TAB_INDEX = {
  ongoing: 0,
  upcoming: 1
};
@Component({
  selector: 'app-shipments-list',
  templateUrl: './shipments-list.component.html',
  styleUrls: ['./shipments-list.component.scss']
})
export class ShipmentsListComponent implements OnInit {
  noUpcomingShipment = 'Your upcoming shipment list is empty';
  noOngoingShipment = 'Your ongoing shipment list is empty';
  noShipment = 'No shipments found';
  ongoingShipment: Shipment[] = [];
  upcomingShipment: Shipment[] = [];
  upcomingShipment_all: Shipment[] = [];
  loading: boolean;
  currentTab: number = 0;
  driverId: string;
  isOngoingFTL: boolean;
  currentSearchInput: string;
  filterFormState: any;
  someTruthy: boolean;
  tagsList: string[];
  sortOrderAsc: boolean = true;

  private subscription: Subscription;
  private shipments: Shipment[];

  constructor(
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly shipmentsUtilsService: ShipmentsUtilsService,
    private readonly shipmentActionsService: ShipmentActionsService,
    private readonly shipmentsActionsService: ShipmentsActionsService,
    private readonly driverApiService: DriverApiService,
    private readonly toastService: ToastService,
    private readonly generalSearchService: GeneralSearchService,
    private readonly informationDialogService: InformationDialogService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    @Inject('environmentData') public environment: any
  ) {
    this.subscription = new Subscription();
    this.loading = false;
  }

  ngOnInit(): void {
    this.driverId = this.driverApiService.driverID;
    this.activatedRoute.queryParams.subscribe(param => {
      if (param?.section) {
        const tabIndex = TAB_INDEX[param.section];
        this.tabChanged(tabIndex);
      }
    });
    this.loadShipments();

    // search & filter
    this.currentSearchInput = '';
    this.filterFormState = window.history.state?.filter || {};
    this.someTruthy = Object.values(this.filterFormState).some(val => val);
    this.setTagsList();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  loadShipments(): void {
    this.loading = true;
    this.shipments = [];
    this.subscription.add(
      this.shipmentsDataService
        .fetchMyShipments(this.driverId)
        .pipe(
          catchError((error: any) => {
            this.toastService.showError(error?.message || DEFAULT_ERROR_MESSAGE);
            return error;
          }),
          finalize(() => {
            this.loading = false;
          })
        )
        .subscribe((shipments: Shipment[]) => {
          if (shipments?.length) {
            this.shipments = shipments;
            this.shipments = this.shipmentsUtilsService.sortShipmentsPickupAsc(this.shipments, this.sortOrderAsc);
            this.ongoingShipment = this.shipmentsUtilsService.findOngoingTrips(this.shipments);
            this.isOngoingFTL = this.ongoingShipment.some((shipment: Shipment) => shipment.loadSize === LOAD_SIZE_FTL);
            this.upcomingShipment_all = this.shipmentsUtilsService.findUpcomingTrips(this.shipments);
            this.filteredUpcomigShipments(this.upcomingShipment_all);
          }
        })
    );
  }

  filteredUpcomigShipments(shipments: Shipment[]) {
    let filteredShipments: Shipment[] = shipments;
    if (this.someTruthy) {
      this.currentTab = 1;
      filteredShipments = shipments.filter(
        el =>
          this.isPickUpInBetweenFilterDates(el) &&
          this.isFilterFieldPresentinShipment(el, 'status', 'status') &&
          this.isFilterFieldPresentinShipment(el, 'loadSize', 'loadSize') &&
          this.isFilterFieldPresentinShipment(el, 'origin', 'pickUpCity') &&
          this.isFilterFieldPresentinShipment(el, 'destination', 'dropOffCity') &&
          this.isFilterFieldPresentinShipment(el, 'unitNumber', 'unitNumber') &&
          this.isFilterFieldPresentinShipment(el, 'loadType', 'loadType') &&
          this.isFilterFieldPresentinShipment(el, 'trailerNumber', 'trailerNumber')
      );
    }
    this.searchShipmentsResults(filteredShipments);
  }

  isFilterFieldPresentinShipment(el: Shipment, shipmentField: string, filterField: string): boolean {
    let formField: string = this.filterFormState[filterField]?.toLowerCase();
    return formField ? el[shipmentField]?.toLowerCase().includes(formField || '') : true;
  }

  isPickUpInBetweenFilterDates(el: Shipment): boolean {
    let pickupDateFrom = dayjs(el.pickup.pickupDateFrom).format(MM_DD_YYYY),
      filterFromDate = this.filterFormState.fromDate,
      filterToDate = this.filterFormState.toDate;
    let isBetween = false;

    if (filterFromDate && filterToDate) {
      isBetween =
        new Date(pickupDateFrom) >= new Date(filterFromDate) && new Date(pickupDateFrom) <= new Date(filterToDate);
    } else if (
      (filterFromDate && new Date(pickupDateFrom) >= new Date(filterFromDate)) ||
      (filterToDate && new Date(pickupDateFrom) <= new Date(filterToDate)) ||
      (!filterFromDate && !filterToDate)
    ) {
      isBetween = true;
    }
    return isBetween;
  }

  setTagsList() {
    let tags: string[] = [];
    let filterKeys = Object.entries(this.filterFormState)
      .filter(([key, value]) => value)
      .map(el => {
        return el[0];
      });
    // from - to date tag
    if (this.filterFormState.fromDate && this.filterFormState.toDate) {
      tags.push(`${this.filterFormState.fromDate} - ${this.filterFormState.toDate}`);
      filterKeys = filterKeys.filter(el => el !== 'fromDate' && el !== 'toDate');
    }
    // pickup - drop tag
    if (this.filterFormState.pickUpCity && this.filterFormState.dropOffCity) {
      tags.push(`${this.filterFormState.pickUpCity} - ${this.filterFormState.dropOffCity}`);
      filterKeys = filterKeys.filter(el => el !== 'pickUpCity' && el !== 'dropOffCity');
    }

    filterKeys.forEach((el: any) => {
      if (
        [
          'pickUpCity',
          'dropOffCity',
          'status',
          'loadSize',
          'fromDate',
          'toDate',
          'unitNumber',
          'loadType',
          'trailerNumber'
        ].includes(el)
      ) {
        tags.push(this.filterFormState[el]);
      }
    });

    this.tagsList = tags;
  }

  tabChanged(tabIndex: number = 0): void {
    this.loadShipments();
    this.currentTab = tabIndex;
  }

  onSearchInput(search: string): void {
    this.currentSearchInput = search?.trim();
    this.filteredUpcomigShipments(this.upcomingShipment_all);
  }

  sortByStatus(): void {
    this.sortOrderAsc = !this.sortOrderAsc;
    this.upcomingShipment = this.shipmentsUtilsService.sortShipmentsPickupAsc(this.upcomingShipment, this.sortOrderAsc);
  }

  navigateToFilterShipments() {
    this.router.navigate([`/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_FILTER_SHIPMENTS}`], {
      state: { filter: window.history.state?.filter }
    });
  }

  onShipmentChangeStatus(data: any): void {
    if (data?.newStatus === SHIPMENT_STATUSES.DELIVERED) {
      this.informationDialogService.openInformationDialog(
        'Great job!',
        'In order to complete your shipment process please answer few questions.',
        null,
        { action: () => this.showShipmentFeedbackDialog(data?.shipment), name: 'Start' } as Action,
        null,
        null,
        'modern'
      );
    } else if (data?.newStatus === SHIPMENT_STATUSES.DRIVING_TO_PICKUP) {
      this.tabChanged(TAB_INDEX.ongoing);
    } else {
      this.loadShipments();
    }
  }

  private showShipmentFeedbackDialog(shipment: Shipment): void {
    const dialogRef = this.shipmentActionsService.showShipmentFeedbackDialog(shipment);
    dialogRef.afterClosed().subscribe(() => this.showThankYouForFeedbackDialog());
  }

  private showThankYouForFeedbackDialog(): void {
    this.informationDialogService.openInformationDialog(
      'Thank you for your feedback',
      'Your shipment has been successfully completed.',
      null,
      { action: () => this.loadShipments(), name: 'Close' } as Action,
      null,
      'checkbox-circle-line-green',
      'modern',
      'green'
    );
  }

  private searchShipmentsResults(filterShipments: Shipment[]): void {
    if (!this.currentSearchInput) {
      this.upcomingShipment = [...filterShipments];
      return;
    }

    this.upcomingShipment = filterShipments.filter(el => {
      let requiredSearchFields: any = {
        shipmentID: el.shipmentID,
        pickupDateFrom: dayjs(el.pickup.pickupDateFrom).format('MMM DD YYYY, hh:mma'),
        pickupDateTo: dayjs(el.pickup.pickupDateTo).format('MMM DD YYYY, hh:mma'),

        origin: el.origin,
        destination: el.destination,
        status: el.status,
        offerRate: el.offerRate,
        distance: el.distance,
        loadSize: el.loadSize,
        weight: el.weight,
        deadHeadOrigin: el.deadHeadOrigin,
        deadHeadDestination: el.deadHeadDestination,
        unitNumber: el.unitNumber,
        loadType: el.loadType,
        trailerNumber: el.trailerNumber
      };
      if (Array.isArray(el.delivery)) {
        const deliveryObj = this.shipmentsUtilsService.getDeliveryDate(el.delivery);
        requiredSearchFields.deliveryDateFrom = dayjs(deliveryObj?.deliveryDateFrom).format('MMM DD YYYY, hh:mma');
        requiredSearchFields.deliveryDateTo = dayjs(deliveryObj?.deliveryDateTo).format('MMM DD YYYY, hh:mma');
      } else {
        requiredSearchFields.deliveryDateFrom = dayjs(el.delivery?.deliveryDateFrom).format('MMM DD YYYY, hh:mma');
        requiredSearchFields.deliveryDateTo = dayjs(el.delivery?.deliveryDateTo).format('MMM DD YYYY, hh:mma');
      }

      if (this.environment?.featureFlags?.enableSearchByIDonShipments) {
        requiredSearchFields.id = el.id;
      }

      if (this.generalSearchService.searchField(requiredSearchFields, this.currentSearchInput)) {
        return el;
      }
    });
  }
}
