<div class="document-list">
  <ng-container [ngSwitch]="viewType">
    <ng-container *ngSwitchCase="'document-list-view'">
      <div *ngIf="!shipmentDocuments?.length; else documentList" class="margin-bottom-lg">
        <app-no-shipment [isShipmentListPage]="true" [message]="noDocumentsText" icon="files"></app-no-shipment>
      </div>
      <ng-template #documentList>
        <div class="files-section" data-test="added-by-you-section">
          <span class="section-title">Added by you</span>
          <div *ngFor="let doc of driverDocuments">
            <ng-container [ngTemplateOutlet]="docFile" [ngTemplateOutletContext]="{ doc: doc }"></ng-container>
          </div>
          <div 
            *ngIf="!driverDocuments?.length" 
            class="no-doc" 
            data-test="no-documents-message"
          >
            <span>No documents found</span>
          </div>
        </div>

        <div class="files-section" data-test="added-by-dispatcher-section">
          <span class="section-title">Added by dispatcher</span>
          <div *ngFor="let doc of dispatcherDocuments">
            <ng-container [ngTemplateOutlet]="docFile" [ngTemplateOutletContext]="{ doc: doc }"></ng-container>
          </div>
          <div *ngIf="!dispatcherDocuments?.length" class="no-doc"><span>No documents found</span></div>
        </div>

        <ng-template #docFile let-doc="doc">
          <div fxLayout class="file" data-test="dispatcher-doc-banner">
            <div fxFlex="90">
              <div fxLayout fxLayoutAlign="center center">
                <mat-icon class="file-icon" svgIcon="document"></mat-icon>
                <span class="margin-left-sm">{{ doc?.createdDate }}</span>
              </div>
              <div [ngClass]="doc.role === 'Driver' ? 'green tag' : 'tag blue'">{{ doc.typeDisplayName }}</div>
            </div>
            <div fxFlex="10">
              <mat-icon class="ellipses-icon" svgIcon="ellipses" (click)="openActionSheet(doc)" data-test="ellipses-icon"></mat-icon>
            </div>
          </div>
        </ng-template>
      </ng-template>

      <div class=" margin-bottom-lg">
        <button mat-button class="btn-accept" (click)="addDocument()" data-test="add-document">Add Document</button>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'file-upload-view'">
      <div>
        <div class="files-section">
          <span class="section-title">Upload File</span>
          <div class="upload-container margin-top-sm">
            <input
              #fileUpload
              type="file"
              [accept]="acceptTypes"
              (change)="onFileChange(fileUpload)"
              [hidden]="true"
              data-test="upload-file"
            />
            <div><mat-icon svgIcon="upload-file-tree" class="upload-icon"></mat-icon></div>
            <div>
              <button mat-button class="btn-accept" (click)="fileUpload.click()" [disabled]="loading" data-test="select-file">
                Select File
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="file" fxLayout class="file">
          <div fxLayout fxLayoutAlign="flex-start center" fxFlex="100">
            <mat-icon class="file-icon" svgIcon="document"></mat-icon>
            <span class="margin-left-sm">{{ file.name }}</span>
          </div>
        </div>
        <div class="margin-top-lg margin-bottom-md actions-row">
          <button mat-button class="btn-clear" (click)="onUploadCancel()" [disabled]="loading" data-test="cancel-button">Cancel</button>
          <button mat-button class="btn-accept" (click)="onUploadNewDocument()" [disabled]="loading || !file" data-test="save-button">
            Save
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
