import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, NgForm, Validators, FormControl, FormGroupDirective } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { ResetPasswordModel } from '../shared/reset-password.model';
import { Response } from 'src/app/shared/services/response';
import { ResetPasswordService } from '../reset-password.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { ROUTE_ACCOUNT_SETTINGS } from 'src/app/shared/routes';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { of } from 'rxjs';

const ACTION_RESET_PASSWORD = 'Reset password';
const RequiredFieldMessage = 'You must enter a value';
const ValidPasswordMessage =
  'Must contain atleast 1 upper case letter, 1 lowercase letter, 1 number, 1 special character and 8 characters';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  hideOldPassword: boolean;
  hideNewPassword: boolean;
  errorMessage: string;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly resetPasswordService: ResetPasswordService,
    private readonly logger: LoggerService,
    private readonly timeService: DateTimeService,
    private toastService: ToastService
  ) {
    this.loading = false;
    this.errorMessage = undefined;
    this.hideOldPassword = true;
    this.hideNewPassword = true;
    this.form = this.createForm();
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit(forgotPasswordForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.subs.add(
      this.resetPasswordService
        .resetPassword(model)
        .pipe(
          catchError((error: any) => {
            this.errorMessage = error.message;
            this.loading = false;

            return of(false);
          })
        )
        .subscribe((result: any) => {
          if (result && !result.error) {
            this.toastService.showSuccess('Your password has been reset successfully');
            this.errorMessage = undefined;
            this.logger.log('Reset form');
            this.form.reset();
            forgotPasswordForm.resetForm();
            this.loading = false;
          }
        })
    );
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

  onNavigateToAccountSettings(): void {
    this.router.navigate([`/${ROUTE_ACCOUNT_SETTINGS}`]);
  }

  get oldPassword() {
    return this.form.get('oldPassword');
  }
  get newPassword() {
    return this.form.get('newPassword');
  }

  getOldPasswordErrorMessage() {
    const errors = this.oldPassword.errors;

    return errors.required ? RequiredFieldMessage : errors.pattern ? ValidPasswordMessage : '';
  }

  getNewPasswordErrorMessage(): string {
    const errors = this.newPassword.errors;

    return errors.required ? RequiredFieldMessage : errors.pattern ? ValidPasswordMessage : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      oldPassword: [
        '',
        [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,}$')]
      ],
      newPassword: [
        '',
        [Validators.required, Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,}$')]
      ]
    });

    return form;
  }

  private prepareSaveModel(): ResetPasswordModel {
    const formModel = this.form.value;

    const model = {
      username: this.resetPasswordService.getDriver().userName,
      oldPassword: formModel.oldPassword as string,
      newPassword: formModel.newPassword as string
    } as ResetPasswordModel;

    return model;
  }
}
