<div class="header-navigation-back">
  <mat-icon (click)="onNavigateToProfile()">arrow_back</mat-icon>
  <span fxFlex class="title">Resume</span>
</div>
<div fxLayout="column" fxLayoutAlign="start stretch" class="content full-height">
  <div *ngIf="uploaded && !loading" class="padding-left-md padding-right-md">
    <p>Your Resume was uploaded to our system on {{ createdDate }}.</p>
    <p>
      If you need further assistance, please get in touch with one of our support group at
      <a href="mailto:${this.supportEmail}">{{ this.supportEmail }}.</a>
    </p>
  </div>
  <div
    fxLayout="column"
    fxLayoutAlign="space-between center"
    *ngIf="!uploaded && !loading"
    class="padding-left-md padding-right-md full-height"
  >
    <p *ngIf="!attempted">Upload your latest resume here</p>
    <p class="help-text">
      email us at <a href="mailto:{{ this.supportEmail }}" class="help-text">{{ this.supportEmail }}.</a> if you have
      questions
    </p>
  </div>
</div>
<div fxLayout="column" *ngIf="!uploaded && !loading" fxLayoutAlign="start stretch" class="botttom-position">
  <div fxLayoutAlign="center">
    <mat-error *ngIf="errorMessage" class="error padding-bottom-sm">
      {{ errorMessage }}
    </mat-error>
    <p *ngIf="successMessage">
      {{ successMessage }}
    </p>
  </div>
  <button
    *ngIf="attempted"
    mat-raised-button
    class="select-file-button"
    type="button"
    [disabled]="!attempted"
    (click)="onNavigateToProfile()"
  >
    Back
  </button>
  <button
    *ngIf="!attempted"
    mat-raised-button
    class="select-file-button"
    type="button"
    [disabled]="successMessage"
    (click)="fileToUpload.click()"
  >
    Upload
  </button>
  <input
    mat-input
    #fileToUpload
    type="file"
    accept="application/pdf"
    [disabled]="loading || successMessage"
    (change)="onFileChange(fileToUpload)"
    class="hidden-input-field"
  />
</div>
