import { Component, OnInit, Input, OnChanges, ViewEncapsulation } from '@angular/core';
import { Shipment } from '../shipment-data/models/shipment';
import { Quote } from '../shipment-data/models/quote';
import { ProfileService } from 'src/app/profile/profile.service';
import { Status } from 'src/app/shared/status-banner/models/status.model';
import { OfferTypeService } from '../offer-type.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ShipmentUtilsService } from '../shipment-utils.service';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-shipment-item-details',
  templateUrl: './shipment-item-details.component.html',
  styleUrls: ['./shipment-item-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentItemDetailsComponent implements OnInit, OnChanges {
  @Input() shipment: Shipment;
  @Input() driverId: string;

  companyName: string;
  driverQuote: Quote;
  formattedPickupDateAndTime: string | Date;
  formattedDeliveryDateAndTime: string | Date;
  status: Status;
  priority: boolean;
  offerRate: number;
  isNativeApp: boolean = false;

  constructor(
    private readonly offerTypeService: OfferTypeService,
    private readonly dateTimeConversion: DateTimeService,
    private readonly shipmentUtilsService: ShipmentUtilsService,
    private readonly appService: AppService
  ) {
    this.shipment = {} as Shipment;
    this.companyName = '';
  }

  ngOnChanges(): void {
    this.shipment = this.shipment || ({} as Shipment);
    this.companyName = this.shipment.companyName || undefined;
    this.driverQuote = this.offerTypeService.determineDriverQuote(this.shipment, this.driverId);
    this.formattedPickupDateAndTime = this.shipment.pickupFormattedDateTime;
    this.formattedDeliveryDateAndTime = this.shipment.deliveryFormattedDateAndTime;
    this.offerRate = this.shipmentUtilsService.determineOfferRate(this.shipment);
  }

  ngOnInit(): void {
    if (this.appService.isNativeApp) {
      this.isNativeApp = true;
    }
    if (this.shipment && this.shipment.priority && this.shipment.priority === 'High') {
      this.priority = true;
    }
  }
}
