import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetEmploymentHistory } from './employment.actions';
import { Directive, Injectable } from '@angular/core';
import { Employment } from 'src/app/shared/models/employment.model';
export interface RegistrationEmploymentHistoryStateModel {
  employmentList: Employment[];
}
@State<RegistrationEmploymentHistoryStateModel>({
  name: 'employment',
  defaults: {
    employmentList: []
  }
})
@Directive({})
export class EmploymentState {
  @Selector() static employmentHistory(state: RegistrationEmploymentHistoryStateModel) {
    return state.employmentList;
  }

  @Action(SetEmploymentHistory)
  setEmploymentHistory(ctx: StateContext<RegistrationEmploymentHistoryStateModel>, { payload }: SetEmploymentHistory) {
    ctx.patchState({
      employmentList: payload
    });
  }
}
