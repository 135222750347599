import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-accident-traffic-records',
  templateUrl: './skeleton-accident-traffic-records.component.html',
  styleUrls: ['./skeleton-accident-traffic-records.component.scss']
})
export class SkeletonAccidentTrafficRecordsComponent implements OnInit {
  @Input() isAccidentRecord: boolean;
  constructor() {}

  ngOnInit(): void {}
}
