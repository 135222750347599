import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { StorageService } from 'src/app/shared/storage/storage.service';

const KEY_DRIVER = 'driver';

@Component({
  selector: 'app-dqf-driver-personal-information',
  templateUrl: './dqf-driver-personal-information.component.html',
  styleUrls: ['./dqf-driver-personal-information.component.scss'],
})
export class DqfDriverPersonalInformationComponent implements OnInit {
  @Input() driverVersionDetails: any;
  data = [];
  constructor(private storageService: StorageService) { }

  ngOnInit(): void {
    this.getDriver();
  }

  private getDriver(): void {
    const driver = this.driverVersionDetails ?? this.storageService.find(KEY_DRIVER)
    if (driver) {
      this.data = [
        { propertyName: 'Name', propertyValue: `${driver.firstName} ${driver.lastName}` },
        { propertyName: 'Date of birth', propertyValue: `${dayjs(driver.birthday).format('DD MMM YYYY') as string}` },
        { propertyName: 'Gender', propertyValue: `${driver.gender}` },
        { propertyName: 'sin / ssn', propertyValue: `${driver.sin}` }]
    }
  }
}
