<div class="logo">
    <img src="assets/images/fleetoperate-logo.png" />
  </div>
  
  <div class="title">Confirm <span class="successful">Unsubscription</span></div>
  
  <p>
   Are you sure you want to unsubscribe?
  </p>
  
  <div fxFlex class="margin-bottom-lg">
    <button mat-raised-button (click)="onKeepSubscribed()">No, keep me subscribed</button> 
    <button mat-raised-button class="confirm-unsubscribe-button" color="warn" (click)="onUnsubscibe()">Yes, unsubscribe me from these emails</button> 
  </div>
  
  <div>
    Follow us<br><br>
    <a href="{{facebookURL}}"><img src="assets/images/facebook.png" class="social-media-imgs" /></a>
    <a href="{{twitterURL}}"><img src="assets/images/twitter.png" class="social-media-imgs" /></a>
    <a href="{{linkedInURL}}"><img src="assets/images/linkedin.png" class="social-media-imgs" /></a>
  </div>
  
  <div class="copyright">
    <hr> <br>
    <span>&copy; {{copyrightYear}} All Rights Reserved FleetOperate</span>
  </div>
  