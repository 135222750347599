import { Injectable } from '@angular/core';
import {
  SHIPMENT_STATUS_UNSECURED,
  SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING,
  SHIPMENT_STATUS_BOOKED
} from './shipment-data/models/shipment-status';
import {
  QUOTE_STATUS_COUNTERED,
  QUOTE_STATUS_ACCEPTED,
  QUOTE_STATUS_OWNER_COUNTERED,
  QUOTE_STATUS_OWNER_ACCEPTED,
  QUOTE_STATUS_OWNER_DECLINED,
  QUOTE_STATUS_DISABLED
} from './shipment-data/models/quote.status';
import { Status } from '../shared/status-banner/models/status.model';
import { Shipment } from './shipment-data/models/shipment';
import { Quote } from './shipment-data/models/quote';
import { CurrencyService } from '../shared/services/currency.service';
import { DISPATCHER_TYPE, SHIPPER_TYPE, TRIP_TYPE } from '../shared/constants';

export const OFFER_TYPE_DRIVER_COUNTERED = 'driver-countered';
export const OFFER_TYPE_NAME_DRIVER_COUNTERED = 'Your offer';
export const LOAD_STATUS_DRIVER_COUNTERED = 'Shipper is yet to confirm this offer';

export const OFFER_TYPE_SECONDARY_DRIVER_QUOTE = 'secondary-driver-quote';

export const OFFER_TYPE_DRIVER_ACCEPTED_SHIPMENT = 'driver-accepted-shipment';
export const OFFER_TYPE_NAME_DRIVER_ACCEPTED_SHIPMENT = 'Pending decision';
export const LOAD_STATUS_DRIVER_ACCEPTED_SHIPMENT = 'Waiting for Shipper to accept this offer';

export const OFFER_TYPE_ACCEPTED_OFFER_SHIPMENT = 'accepted-offer-shipment';
export const OFFER_TYPE_NAME_ACCEPTED_OFFER_SHIPMENT = 'Offer accepted';
export const LOAD_STATUS_ACCEPTED_OFFER_SHIPMENT = 'Shipper has accepted this offer';
export const TRIP_STATUS_ASSIGNED = 'Please confirm the assigned trip';
export const LOAD_STATUS_ASSIGNED = 'Please confirm the assigned load';
export const OFFER_TYPE_SHIPPER_COUNTERED_SHIPMENT = 'shipper-countered-shipment';
export const OFFER_TYPE_NAME_SHIPPER_COUNTERED_SHIPMENT = 'New offer';
export const LOAD_STATUS_SHIPPER_COUNTERED_SHIPMENT = 'Shipper made a new offer of';

export const OFFER_TYPE_DECLINED_OFFER_SHIPMENT = 'declined-offer-shipment';
export const LOAD_STATUS_DECLINED_OFFER_SHIPMENT = 'Shipper has declined the offer';
export const SHIPMENT_ASSIGNED = 'assigned';
export const SHIPMENT_CONFIRM = 'confirm';
export const LOAD_STATUS_MULTIPLE_ONGOING_SHIPMENT =
  'You can only pickup one shipment at a time.\n You currently have an Ongoing shipment.';
export const LOAD_STATUS_INACTIVE_SHIPMENT = 'Sorry, this load is in the past.';
export const SHIPMENT_NO_LOADS = 'There are no loads that originate from your \n base state/province at the moment.';
export const SHIPMENT_PROVINCE_LOADS = 'You are viewing all loads that originate \n from your base state/province.';
export const CANCELLED_SHIPMENT_STATUS = 'Shipper CANCELLED booking';
export const MY_LOADS_INACTIVE_SHIPMENT = 'The Shipment status is INACTIVE';

@Injectable()
export class OfferTypeService {
  constructor(private readonly currencyService: CurrencyService) { }

  determineOfferType(shipmentStatus: string, quoteStatus: string, shipmentNumber?: string): string {
    let offerType;
    if (!(shipmentNumber?.substring(0, 3) === SHIPPER_TYPE)) {
      offerType =
        shipmentStatus?.toUpperCase() === SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING.toUpperCase()
          ? OFFER_TYPE_ACCEPTED_OFFER_SHIPMENT
          : offerType;
    } else {
      offerType =
        shipmentStatus &&
          quoteStatus &&
          shipmentStatus === SHIPMENT_STATUS_UNSECURED &&
          quoteStatus === QUOTE_STATUS_COUNTERED
          ? OFFER_TYPE_DRIVER_COUNTERED
          : offerType;

      offerType =
        shipmentStatus &&
          quoteStatus &&
          shipmentStatus === SHIPMENT_STATUS_UNSECURED &&
          quoteStatus === QUOTE_STATUS_ACCEPTED
          ? OFFER_TYPE_DRIVER_ACCEPTED_SHIPMENT
          : offerType;

      offerType =
        shipmentStatus &&
          quoteStatus &&
          shipmentStatus === SHIPMENT_STATUS_UNSECURED &&
          quoteStatus === QUOTE_STATUS_OWNER_COUNTERED
          ? OFFER_TYPE_SHIPPER_COUNTERED_SHIPMENT
          : offerType;

      offerType =
        shipmentStatus &&
          quoteStatus &&
          shipmentStatus === SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING &&
          quoteStatus === QUOTE_STATUS_OWNER_ACCEPTED
          ? OFFER_TYPE_ACCEPTED_OFFER_SHIPMENT
          : offerType;
      offerType =
        shipmentStatus &&
          quoteStatus &&
          shipmentStatus === SHIPMENT_STATUS_DRIVER_CONFIRMATION_PENDING &&
          quoteStatus === QUOTE_STATUS_DISABLED
          ? OFFER_TYPE_DRIVER_ACCEPTED_SHIPMENT
          : offerType;

      offerType =
        shipmentStatus &&
          quoteStatus &&
          shipmentStatus === SHIPMENT_STATUS_UNSECURED &&
          quoteStatus === QUOTE_STATUS_OWNER_DECLINED
          ? OFFER_TYPE_DECLINED_OFFER_SHIPMENT
          : offerType;
    }
    return offerType;
  }

  determineDriverQuote(shipment: Shipment, driverId: string): Quote {
    let driverQuote = {} as Quote;
    if (driverId && shipment && shipment.quotes && shipment.quotes.length > 0) {
      driverQuote = shipment.quotes.find((quote: Quote) => quote.actorID === driverId) || ({} as Quote);
    }
    return driverQuote;
  }

  getLoadStatus(shipment: Shipment, driverId: string): Status {
    const quote = this.determineDriverQuote(shipment, driverId);
    if (quote) {
      const offerType = this.determineOfferType(shipment.status, quote.status, shipment.shipmentID);
      const offerRate = this.getLatestOfferRate(shipment, quote);
      return offerType ? this.determineLoadStatus(offerType, offerRate, shipment.shipmentID) : undefined;
    }
  }

  private determineLoadStatus(offerType: string, offerRate: string, shipmentNumber: string): Status {
    switch (offerType) {
      case OFFER_TYPE_DRIVER_COUNTERED:
        return this.createYourOfferStatus();
      case OFFER_TYPE_SECONDARY_DRIVER_QUOTE:
        return undefined;
      case OFFER_TYPE_DRIVER_ACCEPTED_SHIPMENT:
        return this.createPendingOfferStatus();
      case OFFER_TYPE_ACCEPTED_OFFER_SHIPMENT:
        return this.createAcceptedOfferStatus(shipmentNumber);
      case OFFER_TYPE_SHIPPER_COUNTERED_SHIPMENT:
        return this.createCounterOfferStatus(offerRate);
      case OFFER_TYPE_DECLINED_OFFER_SHIPMENT:
        return this.createDeclinedOfferStatus();
    }
  }

  private getLatestOfferRate(shipment: Shipment, quote: Quote): string {
    const offer =
      quote && quote.primaryOffer ? quote.primaryOffer : shipment.offerRate ? shipment.offerRate : undefined;
    return this.currencyService.getLocaleCurrency(offer);
  }

  private createYourOfferStatus(): Status {
    return {
      message: LOAD_STATUS_DRIVER_COUNTERED,
      color: 'your_offer',
      svgIcon: 'alert-circle'
    } as Status;
  }
  private createPendingOfferStatus(): Status {
    return {
      message: LOAD_STATUS_DRIVER_ACCEPTED_SHIPMENT,
      color: 'pending_offer'
    } as Status;
  }
  private createAcceptedOfferStatus(shipmentNumber: string): Status {
    const type = shipmentNumber?.substring(0, 3);
    return {
      message: type === TRIP_TYPE ? TRIP_STATUS_ASSIGNED : type === DISPATCHER_TYPE ? LOAD_STATUS_ASSIGNED : LOAD_STATUS_ACCEPTED_OFFER_SHIPMENT,
      color: 'accepted_offer',
      icon: true,
      svgIcon: 'check-circle'
    } as Status;
  }
  private createCounterOfferStatus(offerRate: string): Status {
    return {
      message: `${LOAD_STATUS_SHIPPER_COUNTERED_SHIPMENT} ${offerRate}`,
      color: 'counter_offer'
    } as Status;
  }
  private createDeclinedOfferStatus(): Status {
    return {
      message: LOAD_STATUS_DECLINED_OFFER_SHIPMENT,
      color: 'declined_offer',
      svgIcon: 'alert-circle'
    } as Status;
  }
}
