export const CRIMINAL_OFFENSE =
    [
        "Abandoning child",
        "Abduction",
        "Abduction in contravention of custody order",
        "Abduction of person under fourteen",
        "Abduction of person under sixteen",
        "Absence with or without escort and day parole",
        "Absconding accused deemed present",
        "Absolute jurisdiction",
        "Accused deemed absconded",
        "Access to anything seized",
        "Accused not to cross-examine witness under 18",
        "Accused not entitled to re-opening",
        "Accused absconding during trial",
        "Accused subject to paragraph 672.54(c) disposition order",
        "Accused to be brought before justice",
        "Accessory after the fact",
        "Accused to be present",
        "Accuseds right of reply",
        "Accessories after the fact",
        "Accused calling witnesses",
        "Accused not entitled to re-opening",
        "Accused absconding during inquiry",
        "Accused to be informed of right to apply for order",
        "Accused to be advised of right",
        "Accused to be present",
        "Accused to be present",
        "Accuseds belief as to consent",
        "Accessory after fact to murder",
        "Acceleration of death",
        "Accused to be provided with copies",
        "Accuseds Belief as to Consent",
        "Accessory after the fact",
        "Acknowledging instrument in false name",
        "Acquittal",
        "Acquittal",
        "Acquittal and record of acquittal",
        "Act or omission by accused",
        "Acts intended to alarm Her Majesty or break public peace",
        "Additional conditions",
        "Additional factors",
        "Additional powers",
        "Additional mandatory reviews in custody cases",
        "Additional evidence",
        "Adding names to panel",
        "Additional court orders",
        "Additional jurors",
        "Additional conditions",
        "Adjournment",
        "Adjudication",
        "Adjudication",
        "Adjudication",
        "Adjudication",
        "Adjournment",
        "Adjournment and order",
        "Adjournment on instructions of judge",
        "Adjournment when no jury summoned",
        "Adjournment",
        "Adjournment",
        "Adjournment",
        "Adjournment",
        "Adjournment",
        "Adjournment",
        "Adjournment",
        "Adjournment if accused prejudiced",
        "Adjournment",
        "Adjournment if accused misled",
        "Adjudication at trial",
        "Adjournment of proceedings",
        "Adjournment of proceedings",
        "Admissibility of certified copy",
        "Administration of oath",
        "Admission of report on notice of intent",
        "Admissions not admissible in evidence",
        "Admission of evidence",
        "Admission of evidence",
        "Admitting evidence of witness out of Canada",
        "Admitting evidence of witness who is ill",
        "Admissible evidence at an inquiry",
        "Admissions at trial",
        "Admissibility of certified copy",
        "Administering noxious thing",
        "Admissibility of intercepted communication",
        "Admission of foreign information obtained in confidence",
        "Adverse inference",
        "Advertising and dealing in counterfeit money, etc.",
        "Advocating genocide",
        "Adverse inference",
        "Advertising reward and immunity",
        "Affixing facsimile",
        "Affecting public market",
        "Agent pledging goods, when not theft",
        "Aggravating circumstance value of the fraud",
        "Aggravating circumstance- home invasion",
        "Aggravated sexual assault",
        "Aggravated sexual assault",
        "Aggravated assault of peace officer",
        "Aggravated assault",
        "Aggravating circumstances for sentencing purposes",
        "Aggravated offence in relation to living off the avails of prostitution",
        "Agreement",
        "Agreement",
        "Agreement to limit scope of preliminary inquiry",
        "Agreement to be recorded",
        "Agreement or arrangement sexual offence against child",
        "Alcohol ignition interlock device program",
        "Allegation dismissed or reasonable excuse",
        "Allegation of value not necessary",
        "Allowance of special plea in part",
        "Allowing time",
        "Alternative to oath",
        "Alternate jurors",
        "Alternative to physical presence",
        "Alternative to oath",
        "Alternative to oath",
        "Amendment",
        "Amending indictment",
        "Amendment where variance",
        "Amending defective indictment or count",
        "Amnesty Period",
        "Amounts applied to aid victims",
        "Amount of surcharge",
        "Anal Intercourse",
        "Annual report",
        "Annulling or varying order",
        "Annual Report",
        "Annual Report",
        "Annual Report",
        "Annual Report (Sections 83.28 and 83.29)",
        "Annual report",
        "Answer to inquiries",
        "Any justice may act before and after trial",
        "Application under subsection (1) not prevented",
        "Apprehension of serious mischief",
        "Application by prosecutor",
        "Application to justice",
        "Application of proceeds",
        "Application of proceeds",
        "Application to vary conditions",
        "Application for destruction order",
        "Appointment of Minister of Public Works and Government Services",
        "Application for restraint order",
        "Appeals from orders under subsection 490.2(2)",
        "Appeal from order made under subsection (4)",
        "Application",
        "Application for in rem forfeiture",
        "Appeal",
        "Appeal",
        "Application for termination order",
        "Appeal",
        "Application for termination order",
        "Appeal",
        "Application for exemption order",
        "Appeal against sentence",
        "Appeal barred",
        "Appeal on question of law",
        "Appeal under section 830",
        "Appeals",
        "Application",
        "Appellant to furnish transcript of evidence",
        "Application to fix date for hearing of appeal",
        "Application to appeal court for review",
        "Appeals by Attorney General",
        "Application of certain provisions of section 525",
        "Appeal by defendant, informant or Attorney General",
        "Appeal",
        "Appearances",
        "Application for termination order",
        "Appeal",
        "Application for exemption order",
        "Appeal",
        "Application for termination order",
        "Appeal",
        "Appearances",
        "Appearances",
        "Appearance by organization",
        "Application of Parts XVI, XVIII, XVIII.1, XX and XX.1",
        "Application of Part",
        "Appeal from judgment on return of writ",
        "Appeal in mandamus, etc.",
        "Application of Part XXI",
        "Appearance in person habeas corpus",
        "Application of Part",
        "Application of judicial interim release provisions",
        "Applications for forfeiture of recognizances",
        "Appeal Attorney General",
        "Appeal offender",
        "Application for reduction in period of long-term supervision",
        "Application for finding that an offender is a long-term offender",
        "Application for new sentence or order",
        "Application for remand for assessment later conviction",
        "Appeal",
        "Application by lawful owner",
        "Application for order of return",
        "Application to another judge",
        "Application to judge",
        "Application for variance of order",
        "Appeal",
        "Appeal",
        "Application",
        "Application and notice",
        "Application for exemption",
        "Application of order",
        "Application",
        "Application of section 25",
        "Application",
        "Application",
        "Approval of lieutenant governor in council",
        "Appearance of accused at trial",
        "Application for remand for assessment after imposition of sentence",
        "Application for finding that an offender is a dangerous offender",
        "Application for remand for assessment",
        "Application for restoration of privileges",
        "Appeal",
        "Application for judicial review",
        "Application",
        "Application",
        "Application of section 109 or 110",
        "Appearance of accused at trial",
        "Appeal to Federal Court of Appeal",
        "Appeals from certain orders",
        "Application",
        "Appeal from order under subsection (4)",
        "Application by person claiming interest for relief from forfeiture",
        "Application for forfeiture",
        "Application under section 462.42 to be made in one province",
        "Application of section 462.42",
        "Application of property restitution provisions",
        "Application for review of special warrants and restraint orders",
        "Application to vary conditions",
        "Application for destruction order",
        "Appointment of Minister of Public Works and Government Services",
        "Application for restraint order",
        "Application of certain provisions fines",
        "Application of money paid",
        "Application to other law",
        "Application to vary intermittent sentence",
        "Application of section 109 or 110",
        "Application",
        "Application for leave to appeal",
        "Applying or removing marks without authority",
        "Appeal",
        "Application for order when witness out of Canada",
        "Application where witness is ill",
        "Application by witness to judge",
        "Application",
        "Application",
        "Appellant represented by counsel",
        "Appeal by Attorney General",
        "Appeal against affirmation of verdict of unfit to stand trial",
        "Appeal against affirmation of verdict of not criminally responsible on account of mental disorder",
        "Appeal where acquittal set aside",
        "Appeal from conviction",
        "Appellant represented by counsel",
        "Appeal court may set aside verdict of unfit to stand trial",
        "Appeal from acquittal",
        "Application to appeals on summary conviction proceedings",
        "Application of certain provisions of section 525",
        "Appeal re costs",
        "Appeal against decision not to make s. 745.51(1) order",
        "Appeal against decision not to make section 743.6 order",
        "Appeal against ineligible parole period",
        "Appeal against verdict of unfit to stand trial",
        "Appeals against verdicts based on mental disorder",
        "Appeal against s. 745.51(1) order",
        "Appeal against section 743.6 order",
        "Appeal against absolute term in excess of 10 years",
        "Appeal",
        "Application",
        "Appeal",
        "Application respecting dispositions under appeal",
        "Appellant to provide transcript of evidence",
        "Appeal on the transcript",
        "Appeal to be heard expeditiously",
        "Application and notice",
        "Application of order",
        "Application and publication of rules",
        "Application for federal employment",
        "Application to vary assessment order",
        "Application",
        "Appeal",
        "Appearance by attorney",
        "Application",
        "Application to amend or divide counts",
        "Appeal",
        "Application for review Nunavut",
        "Application of Parts XVI, XVIII, XX and XXIII",
        "Application to Nunavut",
        "Application to Nunavut",
        "Application to Nunavut",
        "Application to Nunavut",
        "Application to Nunavut",
        "Application",
        "Application to Nunavut",
        "Application to Nunavut",
        "Appointment",
        "Appearance for examination",
        "Application to Nunavut",
        "Application of sections respecting sentence",
        "Application of sections 517, 518 and 519",
        "Application of sections 517, 518 and 519",
        "Application of sections 517, 518 and 519",
        "Appeal",
        "Application for production",
        "Application of provisions",
        "Appearance of Attorney General",
        "Appeal",
        "Application by prosecutor",
        "Application for hearing",
        "Application to justice",
        "Application",
        "Appeals to Supreme Court of Canada",
        "Approvals",
        "Application to be accompanied by affidavit",
        "Application to be accompanied by affidavit",
        "Applications to specially appointed judges",
        "Application",
        "Application for authorization",
        "Application by means of telecommunication",
        "Application for authorization",
        "Appeal",
        "Appeal to Court of Appeal",
        "Application of Part XXI",
        "Application",
        "Application",
        "Application of Part XXVII to appeals",
        "Appeal by Attorney General",
        "Appeal by Person",
        "Application for Disposition",
        "Application of ss. 113 to 117",
        "Application for Warrant to Search and Seize",
        "Application of Part XXVII to appeals",
        "Appeal by Attorney General",
        "Appeal by Person or Attorney General",
        "Application for Order",
        "Application of Part XXVII to Appeals",
        "Appeal by Attorney General",
        "Appeal by Person or Attorney General",
        "Application of ss. 113 to 117",
        "Application for Prohibition Order",
        "Application of ss. 113 to 117",
        "Application of ss. 113 to 117",
        "Appearance",
        "Application of Section 707",
        "Appeal of Refusal to Grant Order",
        "Application for order of forfeiture",
        "Application to vary",
        "Application for destruction order",
        "Appointment of Minister of Public Works and Government Services",
        "Appointment of manager",
        "Application to Minister",
        "Appearance of accused at trial",
        "Appeal",
        "Application of criminal law of England",
        "Application to territories",
        "Argument may be oral or in writing",
        "Armed forces",
        "Armed forces",
        "Arrest without warrant by peace officer",
        "Arrest by owner, etc., of property",
        "Arrest without warrant by any person",
        "Arraignment",
        "Arrest",
        "Arrest, search, seizure, etc.",
        "Arrest without warrant for contravention of disposition",
        "Arrest without warrant by peace officer",
        "Arrest of accused without warrant",
        "Arrest Warrant",
        "Arrest without a Warrant",
        "Arrest for breach of peace",
        "Arrest of wrong person",
        "Arson by negligence",
        "Arson for fraudulent purpose",
        "Arson- damage to property",
        "Arson- disregard for human life",
        "Assistance order",
        "Assessment order",
        "Assigning counsel",
        "Assessment report",
        "Assessment report to be filed",
        "Assessment order takes precedence over bail hearing",
        "Assessment order",
        "Assaulting peace officer with weapon or causing bodily harm",
        "Assaulting a peace officer",
        "Assault with a weapon or causing bodily harm",
        "Assault",
        "Assault",
        "Assisting Prisoner of War to Escape",
        "Assisting deserter",
        "Assisting alien enemy to leave Canada, or omitting to prevent treason",
        "Attendance for examination",
        "Attendance and cross-examination",
        "Attorney General",
        "Attorney Generals consent",
        "Attorney Generals consent",
        "Attempts, accessories",
        "Attorney General of Canada",
        "Attorney Generals consent",
        "Attorney Generals consent",
        "Attack on premises, accommodation or transport of United Nations or associated personnel",
        "Attack on premises, residence or transport of internationally protected person",
        "Attorneys General may be parties",
        "Attendance and right to cross-examine",
        "Attempt charged, full offence proved",
        "Attendance for examination",
        "Attendance for examination",
        "Attorney General may direct stay",
        "Attorney General may require trial by jury Nunavut",
        "Attorney General may require trial by jury",
        "Attendance for purposes of Identification of Criminals Act",
        "Attendance and right to cross-examine",
        "Attempt to commit murder",
        "Attendance and Right to Cross-examination",
        "Attorney General of Canada may act",
        "Attorney General of Canada may act",
        "Attendance of Analyst",
        "Attorney General of Canada may act",
        "Attorney General of Canada may act",
        "Attorney General's Opinion",
        "Attorney General's Consent",
        "Attorney General's Consent",
        "Attorney General's Consent",
        "Attempts",
        "Attendance for examination",
        "Audio evidence witness outside Canada",
        "Audio evidence witness in Canada",
        "Audit",
        "Authority of judge",
        "Authority to make copies",
        "Automatic suspension of certain dispositions",
        "Authentication by justice",
        "Authentication of transcript",
        "Authority to enter dwelling without warrant",
        "Authorizations in emergency",
        "Authorization",
        "Authorizations, etc., as evidence",
        "Authorizations, etc., revoked",
        "Authorizations Revoked or Amended",
        "Ban on publication, limitation to access or use of information",
        "Barrel Length",
        "Being unlawfully in dwelling-house",
        "Bench warrant",
        "Bestiality in Presence of or by Child",
        "Bestiality",
        "Betting, pool-selling, book-making, etc.",
        "Bigamy",
        "Blood alcohol level over legal limit death",
        "Blood alcohol level over legal limit bodily harm",
        "Bodily harm",
        "Bona Fide Exception",
        "Books and documents",
        "Breach of recognizance",
        "Breach of long-term supervision",
        "Breach of condition",
        "Breach of order",
        "Breaking and entering with intent, committing offence or breaking out",
        "Breach of Trust by Public Officer",
        "Breaking and Entering to Steal Firearm",
        "Breach of duty",
        "British Columbia",
        "Bringing before justice",
        "Bringing into Canada property obtained by crime",
        "Bringing accused before Review Board",
        "Bribery of Officers",
        "Bribery of Judicial Officers, etc.",
        "Broker reducing stock by selling for his own account",
        "Burden of proving exception, etc.",
        "Burden of proof",
        "Burden of proof",
        "Burden of proof",
        "Calling persons who have stood by",
        "Canadian Forces not affected",
        "Cards to be drawn by clerk of court",
        "Carrying Concealed Weapon",
        "Carrying Weapon While Attending Public Meeting",
        "Careless Use of Firearm, etc.",
        "Causing damage or injury",
        "Causing unnecessary suffering",
        "Causing bodily harm by criminal negligence (street racing)",
        "Causing death by criminal negligence (street racing)",
        "Causing bodily harm with intent air gun or pistol",
        "Causing bodily harm by criminal negligence",
        "Causing death by criminal negligence",
        "Causing disturbance, indecent exhibition, loitering, etc.",
        "Causing Bodily Harm",
        "Causing Death",
        "Certified photograph admissible in evidence",
        "Certificate of non-payment of costs",
        "Certain sections applicable to appeals",
        "Certificate is evidence",
        "Certificate and entry of render",
        "Certificate",
        "Certificate cannot be compelled",
        "Certificate of examiner of counterfeit",
        "Certificate of marriage",
        "Certificate of order",
        "Certain omissions not grounds for objection",
        "Certain provisions applicable to order under this section",
        "Certain actions not to preclude issue of warrant",
        "Certificate admissible in evidence",
        "Certificate of Analyst",
        "Certified Copies",
        "Certain Weapons Deemed not to be Firearms",
        "Certain acts on holidays valid",
        "Certificate as evidence",
        "Change in address",
        "Changes proposed by offender or prosecutor",
        "Change in terms of order",
        "Changes to probation order",
        "Challenge by accused",
        "Chairperson may send accused to court",
        "Chairperson of a Review Board",
        "Challenge for cause",
        "Challenge for cause",
        "Challenge in writing",
        "Challenging the jury panel",
        "Change of venue",
        "Change of venue",
        "Cheating at play",
        "Childrens evidence",
        "Child under twelve",
        "Circumstances in which appellant may be released",
        "Circumstances of aggravation",
        "Circumstances warranting order directing trial in both official languages",
        "Civil remedy not affected",
        "Civil enforcement of fines, forfeiture",
        "Civil remedy not suspended",
        "Clarification",
        "Clarification",
        "Classification of murder",
        "Clerical error",
        "Clipping and uttering clipped coin",
        "Collection of additional bodily substances",
        "Collection of samples",
        "Colour of right",
        "Commission of indictable offence to obtain nuclear material, etc.",
        "Committal",
        "Commencement of proceedings",
        "Committal when writ not satisfied",
        "Commencement of sentence",
        "Committal or new sureties",
        "Compliance with directions",
        "Compulsory conditions of conditional sentence order",
        "Commission of offence for criminal organization",
        "Commission of offence",
        "Compelling appearance of person bound",
        "Compelling appearance of person bound",
        "Coming into force of order",
        "Compulsory conditions of probation order",
        "Compel appearance",
        "Commencement of sentence",
        "Community impact statement",
        "Complainant not compellable",
        "Common nuisance",
        "Compelling the Commission of Bestiality",
        "Compounding Indictable Offence",
        "Commission of Offence for Terrorist Group",
        "Completion of review",
        "Compliance with requirements",
        "Compulsion by threats",
        "Compulsion of spouse",
        "Common law principles continued",
        "Consent to be filed",
        "Consent of Attorney General of Canada",
        "Conditions",
        "Consequences of non-release",
        "Consequences of non-release",
        "Conseuqneces of arrest without warrant",
        "Conditions",
        "Condition for remote appearance",
        "Condition reporting",
        "Condition reporting",
        "Conditions",
        "Conditions firearms",
        "Conditions firearms",
        "Conditions firearms",
        "Conditions in recognizance",
        "Conditions in recognizance",
        "Conditions in recognizance",
        "Condition",
        "Consent of Attorney General required",
        "Conviction or order remediable, when",
        "Conditions",
        "Contents of information",
        "Contents of report",
        "Conditions for issuance of order",
        "Conditions for issuance of order",
        "Conditions of exclusion",
        "Conditions",
        "Consent to be filed",
        "Consent of Attorney General of Canada",
        "Consent of Attorney General of Canada",
        "Consent of Attorney General of Canada",
        "Conditional sentence order resumes",
        "Conditions continue",
        "Considerations",
        "Conspiracy in restraint of trade",
        "Conspiracy to commit offences",
        "Conspiracy",
        "Conditions- pattern of criminal activity",
        "Conditions to be satisfied",
        "Continues in force",
        "Conveying instruments for coining out of mint",
        "Consideration organizations",
        "Conditional and absolute discharge",
        "Confiscation",
        "Content of report",
        "Consent required",
        "Consent required",
        "Consent",
        "Considerations",
        "Contempt",
        "Contents of subpoena",
        "Conditions of order",
        "Conditions",
        "Consent of accused not required for treatment",
        "Consent of hospital required for treatment",
        "Conditions",
        "Continued detention in hospital",
        "Conduct of inquiry",
        "Contents of assessment order",
        "Consent required for proceedings",
        "Continuation of proceedings",
        "Conviction for break and enter with intent",
        "Conviction for dangerous driving where manslaughter charged",
        "Conviction for concealing body of child where murder or infanticide charged",
        "Conviction for infanticide or manslaughter on charge of murder",
        "Consent",
        "Consent required",
        "Consent",
        "Consent",
        "Consent",
        "Contents of designation",
        "Conviction a bar",
        "Contents of notice",
        "Conditions for accepting guilty plea",
        "Conditions respecting expense",
        "Conviction of one or more",
        "Consent to inclusion of other charges",
        "Continuing proceedings Nunavut",
        "Continuation as preliminary inquiry Nunavut",
        "Conference or hearing",
        "Considerations",
        "Continuing proceedings",
        "Confession or admission of accused",
        "Contents of address to accused",
        "Conveyance of prisoner",
        "Conveyance of prisoner",
        "Conditions on production",
        "Consent",
        "Condition prohibiting possession of firearms, etc.",
        "Conditions authorized",
        "Conditional release",
        "Conditions for issuance",
        "Consequences of non-release",
        "Content of summons",
        "Contents of warrant to arrest",
        "Contents of appearance notice, promise to appear and recognizance",
        "Consent",
        "Consent",
        "Consecutive prohibition periods",
        "Condition",
        "Conditional discharge",
        "Consent of Attorney General",
        "Concealing body of child",
        "Contracted murder",
        "Contravention",
        "Conversion into money",
        "Conclusive presumption from slot machine",
        "Content and limitation of authorization",
        "Content and limitation of authorization",
        "Consent to interception",
        "Consent",
        "Consent of Attorney General",
        "Consent no Defence",
        "Consent Required",
        "Contractor Subscribing to Election Fund",
        "Conditions",
        "Consent of Attorney General",
        "Contravention of Storage Regulations, etc.",
        "Conditions- Firearms",
        "Contents of Order",
        "Concealing Person who is Likely to Carry out Terrorist Activity",
        "Concealing Person who Carried out Terrorist Activity",
        "Concealment of identity",
        "Concealment of identity",
        "Condition civilian oversight",
        "Considerations",
        "Contents of application",
        "Contents of application",
        "Consent to death",
        "Consent of Attorney General",
        "Consent of Attorney General of Canada",
        "Copies",
        "Copies of documents returned",
        "Copy of report",
        "Copies",
        "Copies of documents returned or forfeited",
        "Copy of report",
        "Copy of statement",
        "Copy of statement",
        "Copy of information",
        "Copies to interested parties",
        "Copy for Minister of Justice",
        "Copy of order to parties",
        "Copy of statement",
        "Copy of disposition to be sent to court",
        "Copies of reports to accused and prosecutor",
        "Copy of affidavit or warrant",
        "Copy to prosecutor",
        "Copy or facsimile to person",
        "Correcting punishment",
        "Corroboration",
        "Coroners inquisition",
        "Corroboration not required",
        "Corrupting Children",
        "Corrupting Morals",
        "Corruptly Taking Reward for Recovery of Goods",
        "Corroboration",
        "Corroboration",
        "Costs",
        "Costs",
        "Costs",
        "Costs are part of fine",
        "Costs",
        "Costs to successful party in case of libel",
        "Costs of technology",
        "Counsel or agent",
        "Counsel for accused may continue to act",
        "Court of criminal jurisdiction",
        "Counselling offence that is not committed",
        "Court may vary intermittent sentence if subsequent offence",
        "Court-supervised programs",
        "Court to consider restitution order",
        "Counterfeiting mark",
        "Counterfeiting stamp, etc.",
        "Court may vary order",
        "Counterfeit proclamation, etc.",
        "Counsel fees and disbursements",
        "Counsel fees and disbursements",
        "Court may act on own motion",
        "Counsel fees and disbursements",
        "Court may order inquiry to be held",
        "Counsel fees and disbursements",
        "Counsel",
        "Court may direct issue to be tried",
        "Court to send assessment report to Review Board",
        "Count for murder",
        "Court of record",
        "Counsel for accused may continue to act",
        "Counselling or aiding suicide",
        "Court May Vary Order",
        "Credits and other matters",
        "Criteria",
        "Criteria",
        "Criteria",
        "Criminal breach of contract",
        "Criteria for disposition",
        "Criminal interest rate",
        "Criminal breach of trust",
        "Criminal information and bill of indictment",
        "Criminal Harassment",
        "Criminal harassment",
        "Criminal Negligence",
        "Criminal offences to be under law of Canada",
        "Cross-examination and notice",
        "Cross-examination with leave",
        "Culpable homicide",
        "Cumulative punishments",
        "Custodial disposition by court",
        "Custody of records",
        "Damaging documents",
        "Damages",
        "Dangerous operation causing death",
        "Dangerous operation causing bodily harm",
        "Dangerous operation of motor vehicle while street racing",
        "Dangerous operation causing death",
        "Dangerous operation causing bodily harm",
        "Dangerous operation of motor vehicles, vessels and aircraft",
        "Date obligation begins",
        "Date obligation ends",
        "Date order begins",
        "Date for Hearing and Notice",
        "Date for Hearing and Notice",
        "Dealing with data in court",
        "Dealer in second-hand goods",
        "Death",
        "Death during illegal arrest",
        "Death from treatment of injury",
        "Death that might have been prevented",
        "Dead body",
        "Decision re new application",
        "Decision re new application",
        "Decision at hearing",
        "Decisions binding on parties",
        "Decision to hold preliminary inquiry Nunavut",
        "Decision in court records and return of documents",
        "Decision whether to hold joint hearing",
        "Decision binding",
        "Declaration as evidence",
        "Deemed application",
        "Deemed payment",
        "Deemed execution of warrant",
        "Deemed execution of warrant",
        "Deeming",
        "Definition of terrorist activity",
        "Definition of United Nations Personnel",
        "Definition of device",
        "Definition of recognizance",
        "Definition of summons",
        "Definition of undertaking",
        "Definition of warrant",
        "Definition of promise to appear",
        "Definition of officer in charge",
        "Definition of judge",
        "Definition of appearance notice",
        "Definition of accused",
        "Definition of number recorder",
        "Definition of tracking device",
        "Definition of judge",
        "Definition of photograph",
        "Definition of electronic document",
        "Definition of appeal court",
        "Definition of data",
        "Definition of appeal court",
        "Definition of costs",
        "Definition of trial",
        "Definition of summary conviction court",
        "Definition of informant",
        "Definition of information",
        "Definition of order",
        "Definition of proceedings",
        "Definition of prosecutor",
        "Definition of sentence",
        "Definition of clerk of the appeal court",
        "Defect in form",
        "Definition of Attorney General",
        "Default to be endorsed",
        "Definition of schedule",
        "Definition of clerk of the court",
        "Definition of verdict of not criminally responsible on account of mental disorder",
        "Definition of Review Board",
        "Definition of registration centre",
        "Definition of record suspension",
        "Definition of Ontario Act",
        "Definition of pardon",
        "Definition of designated offence",
        "Definition of database",
        "Definition of crime of a sexual nature",
        "Definition of custodian",
        "Definition of document",
        "Definition of judge",
        "Definition of lawyer",
        "Definition of officer",
        "Definition of sexual offence",
        "Definition of young person",
        "Definition of Young Offenders Act",
        "Definition of secondary designated offence",
        "Definition of provincial court judge",
        "Definition of primary designated offence",
        "Definition of forensic DNA analysis",
        "Definition of DNA",
        "Definition of designated offence",
        "Definition of adult",
        "Definition of data",
        "Definition of document",
        "Definition of newspaper",
        "Definition of ship",
        "Definition of serious personal injury offence",
        "Definition of primary designated offence",
        "Definition of long-term supervision",
        "Definition of designated offence",
        "Definition of court",
        "Definition of victim",
        "Definition of appropriate Chief Justice",
        "Definition of supervisor",
        "Definition of optional conditions",
        "Definition of change",
        "Definition of serious offence",
        "Definition of criminal organization",
        "Definition of trade combination",
        "Definition of police officer",
        "Definition of designated substance offence",
        "Definition of order",
        "Definition of proceeds of crime",
        "Definition of judge",
        "Definition of designated offence",
        "Definition of sell",
        "Definition of literature for illicit drug use",
        "Definition of instrument for illicit drug use",
        "Definition of illicit drug use",
        "Defacing current coins",
        "Defence",
        "Definition of consume",
        "Definition of illicit drug",
        "Definition of penalty",
        "Definition of optional conditions",
        "Definition of analyst",
        "Definition of change",
        "Definition of current",
        "Definition of utter",
        "Definition of counterfeit token of value",
        "Definition of counterfeit money",
        "Definition of victim",
        "Definition of public transportation system",
        "Definition of place of public use",
        "Definition of military forces of a state",
        "Definition of infrastructure facility",
        "Definition of explosive or other lethal device",
        "Definition of data",
        "Definition of property",
        "Definition of agent and principal",
        "Definition of distinguishing mark",
        "Definition of identity information",
        "Definition of company",
        "Definition of inside information",
        "Definition of goods",
        "Definition of trading stamps",
        "Definition of election document",
        "Definition of mark",
        "Definition of stamp",
        "Definition of cheque",
        "Definition of cheque",
        "Definition of traffic",
        "Definition of vehicle identification number",
        "Definition of vehicle identification number",
        "Definition of licence",
        "Definition of automobile master key",
        "Definition of court",
        "Definition of fine",
        "Definition of alternative measures",
        "Definition of accused",
        "Definition of court of appeal",
        "Definition of court of appeal",
        "Definition of indictment",
        "Definition of registrar",
        "Definition of sentence",
        "Definition of trial court",
        "Definition of serious personal injury offence",
        "Definition of Minister",
        "Definition of psychosurgery",
        "Definition of electro-convulsive therapy",
        "Definition of disposition information",
        "Definition of victim",
        "Definition of application for federal employment",
        "Definition of protected statement",
        "Definition of interest",
        "Definition of payday loan",
        "Definition of place",
        "Definition of verdict of not criminally responsible on account of mental disorder",
        "Definition of prescribed",
        "Definition of Review Board",
        "Definition of required deposit balance",
        "Definition of overdraft charge",
        "Definition of placement decision",
        "Definition of interest",
        "Definition of official fee",
        "Definition of party",
        "Definition of medical practitioner",
        "Definition of insurance charge",
        "Definition of criminal rate",
        "Definition of hospital",
        "Definition of credit advanced",
        "Definition of dual status offender",
        "Definition of accused",
        "Definition of assessment",
        "Definition of chairperson",
        "Definition of court",
        "Definition of disposition",
        "Definition of function",
        "Definition of intercept",
        "Definition of traffic",
        "Definition of fingerprint examiner",
        "Definition of electro-magnetic, acoustic, mechanical or other device",
        "Definition of computer system",
        "Definition of data",
        "Definition of computer service",
        "Definition of computer program",
        "Definition of computer password",
        "Definition of traffic",
        "Definition of personal authentication information",
        "Definition of lumbering equipment",
        "Definition of lumber",
        "Definition of coastal waters of Canada",
        "Definition of vessel",
        "Definition of telecommunication",
        "Definition of false document",
        "Definition of revenue paper",
        "Definition of exchequer bill paper",
        "Definition of break",
        "Definition of credit card",
        "Definition of document",
        "Definition of exchequer bill",
        "Definition of judge",
        "Definition of hate propaganda",
        "Definition of court",
        "Definition of genocide",
        "Definition of public place",
        "Definition of statements",
        "Definition of identifiable group",
        "Defences",
        "Definition of communicating",
        "Definition of identifiable group",
        "Definition of genocide",
        "Definition of newspaper",
        "Definition",
        "Definition of therapeutic abortion committee",
        "Definition of qualified medical practitioner",
        "Definition of minister of health",
        "Definition of board",
        "Definition for approved hospital",
        "Definition of accredited hospital",
        "Defence",
        "Defence",
        "Definition of guardian",
        "Definition of means",
        "Definition of court",
        "Definition of judge",
        "Defect not to affect validity",
        "Definition of record",
        "Definition of designated justice",
        "Definition of Official",
        "Definition of torture",
        "Definition of weapon",
        "Definition of registrar of motor vehicles",
        "Definition of disqualification",
        "Definition of qualified technician",
        "Definition of qualified medical practitioner",
        "Definition of evaluating officer",
        "Definition of approved screening device",
        "Definition of approved instrument",
        "Definition of approved container",
        "Defences",
        "Definiton of analyst",
        "definition of place",
        "Definition of duty",
        "Definition of guardian",
        "Definition of operate",
        "Definition of vessel",
        "Definition of form of marriage",
        "Definition of aircraft",
        "Definition of abandon or expose",
        "Definition of lottery scheme",
        "Definition of public place",
        "Definition of international cruise ship",
        "Definition of lottery scheme",
        "Definition of fair or exhibition",
        "Definition of three-card monte",
        "Definition of association",
        "Definition of keeper",
        "Definition of place",
        "Definition of prostitute",
        "Definition of public place",
        "Definition of slot machine",
        "Definition of gaming equipment",
        "Definition of game",
        "Definition of disorderly house",
        "Definition of common gaming house",
        "Definition of common betting house",
        "Definition of common-bawdy house",
        "Definition of bet",
        "Definition of Chief Justice",
        "Definition of agent of the state",
        "Definition of sell",
        "Definition of Solicitor",
        "Definition of radio-based telephone communication",
        "Defintion of public switched telephone network",
        "Definition of private communication",
        "Definition of intercept",
        "Definition of offence",
        "Definition of police officer",
        "Definition of electro-magnetic, acoustic, mechanical or other device",
        "Definition",
        "Definition of authorization",
        "Definition of child",
        "Definition of Sexually Explicit Material",
        "Defintion of Voyeuristic Recording",
        "Defence",
        "Defence of Public Good",
        "Definition of Court",
        "Definition of Crime Comic",
        "Definition of Crime Comic",
        "Definition of Judge",
        "Definition of Visual Recording",
        "Definition of Brother and Sister",
        "Defence",
        "Definition of Consent",
        "Definition of Escape",
        "Definition of Evidence",
        "Definition of Guardian",
        "Definition of Public Place",
        "Definition of Theatre",
        "Definition of Young Person",
        "Definition of Municipal Official",
        "Definition of Office",
        "Definition of Official",
        "Definition of Witness",
        "Definition of Judicial Proceeding",
        "Definition of Evidence",
        "Definition of Government",
        "Definition of Public Officer",
        "Definition of Provincial Court Judge",
        "Definition of Release from Imprisonment",
        "Definition of Release from Imprisonment",
        "Definition of Report or Statement",
        "Definitions of Break and Place",
        "Definition of Superior Court",
        "Definition of Transfer",
        "Definition of Restricted Weapon",
        "Definition of Restricted Firearm",
        "Definition of Replica Firearm",
        "Definition of Registration Certificate",
        "Definition of Registrar",
        "Definition of Prohibition Order",
        "Definition of Prohibited Weapon",
        "Definition of Prohibited Firearm",
        "Definition of Prohibited Device",
        "Definition of Prohibited Ammunition",
        "Definition of Import",
        "Definition of Prescribed",
        "Defintion of Licence",
        "Definition of Imitation Firearm",
        "Definition of Handgun",
        "Definition of Firearms Officer",
        "Definition of Cross-bow",
        "Definition of Export",
        "Definition of Commissioner of Firearms",
        "Definition of Chief Firearms Officer",
        "Definition of Cartridge Magazine",
        "Definition of Automatic Firearm",
        "Definition of Authorization",
        "Definition of Antique Firearm",
        "Definition of Ammunition",
        "Definition of Sitting Day of Parliament",
        "Definition of judge",
        "Definition of judge",
        "Definition of terrorist group",
        "Definition of terrorist activity",
        "Definition of Canadian",
        "Definition of civil aircraft",
        "Definition of entity",
        "Definition of fixed platform",
        "Definition of hijacking",
        "Definition of listed entity",
        "Definition of prize fight",
        "Definition of ship",
        "Definition of forcible detainer",
        "Definition of forcible entry",
        "Definition of riot",
        "Definition of unlawful assembly",
        "Definition of member of a force",
        "Definition of seditious intention",
        "Definition of seditious conspiracy",
        "Definition of seditious libel",
        "Definition of seditious words",
        "Definition of certificate of citizenship and certificate of naturalization",
        "Definition of passport",
        "Definition of identity document",
        "Defence of property",
        "Definition of prohibited act",
        "Defence - use or threat of force",
        "Definition of public officer",
        "Definition of senior official",
        "Definition of competent authority",
        "Defence of mental disorder",
        "Definition of counselling",
        "Definition of in service",
        "Definition of flight and in flight",
        "Definition of nuclear material",
        "Definition of flight element",
        "Definition of partner state",
        "Definition of space flight",
        "Definition of space station",
        "Definition of crew member of partner state",
        "Definition of Canadian crew member",
        "Definition of agreement",
        "Definition of convention",
        "Definition of enactment",
        "Definitions",
        "Definition of sexual intercourse",
        "Definition of possession",
        "Definition of victim",
        "Definition of weapon",
        "Definition of wreck",
        "Definition of writing",
        "Definition of valuable security",
        "Definition of valuable mineral",
        "Definition of united nations operation",
        "Definition of unfit to stand trial",
        "Definition of trustee",
        "Definition of testamentary instrument",
        "Definition of terrorist group",
        "Definition of terrorism offence",
        "Definition of territorial division",
        "Definition of superior court of criminal jurisdiction",
        "Definition of representative",
        "Definition of senior officer",
        "Definition of serious offence",
        "Definition of steal",
        "Definition of railway equipment",
        "Definition of public officer",
        "Definition of public stores",
        "Definition of public department",
        "Definition of provincial court judge",
        "Definition of organization",
        "Definition of peace officer",
        "Definition of prison",
        "Definition of property",
        "Definition of prosecutor",
        "Definition of offensive weapon",
        "Definition of offender",
        "Definition of offence related property",
        "Definition of night",
        "Definition of newly born child",
        "Definition of mental disorder",
        "Definition of military",
        "Definition of military law",
        "Definition of motor vehicle",
        "Definition of municipality",
        "Definition of justice system participant",
        "Definition of Justice",
        "Definition of internationally protected person",
        "Definition of highway",
        "Definition of indictment",
        "Definition of Her Majesty's Forces",
        "Definition of Government or public facility",
        "Definition of firearm",
        "Definition of explosive substance",
        "Definition of every one, owner and person",
        "Definition of dwelling house",
        "Definition of document of title to lands",
        "Definition of document of title to goods",
        "Definition of day",
        "Definition of criminal organization offence",
        "Definition of criminal organization",
        "Definition of court of criminal jurisdiction",
        "Definition of complainant",
        "Definition of counsel",
        "Definition of count",
        "Definition of Court of Appeal",
        "Definition of common law partner",
        "Definition of clerk of the court",
        "Definition of bank note",
        "Definition of bodily harm",
        "Definition of Canadian Forces",
        "Definition of cattle",
        "Definition of Attorney General",
        "Definition of associated personnel",
        "Definition of act",
        "Degrees of punishment",
        "Delivery to peace officer",
        "Delegation",
        "Delivery and detention of accused",
        "Delegated authority to vary restrictions on liberty of accused",
        "Delayed enforcement",
        "Deleted parts",
        "Denial",
        "Depositions of such witnesses",
        "Destruction of bodily substances, etc., voluntarily given",
        "Destruction of bodily substances, etc.- warrant",
        "Designation of judge to empanel jury",
        "Designation of province",
        "Destroying documents of title",
        "Destruction of copy",
        "Designation of counsel of record",
        "Destruction of recordings and transcripts",
        "Destruction of Copy",
        "Destroying",
        "Designation of senior officials",
        "Designation of public officers",
        "Descriptive cross-references",
        "Detention on inquiry to determine legality of imprisonment",
        "Detention pending appeal, etc.",
        "Detention without application where consent",
        "Detention of things seized",
        "Detention of person",
        "Detention under s. 515(6)",
        "Determination of objection",
        "Detention and record of property seized",
        "Determination of term",
        "Determination of sentence",
        "Detention to count as service of term",
        "Determination of mental condition of the accused",
        "Details of circumstances",
        "Detention in other cases",
        "Detention of prisoner required as witness",
        "Detention pending bail hearing",
        "Detention in custody for offence listed in section 469",
        "Detention in custody",
        "Direction for payment to municipality",
        "Directions for expediting appeal, new trial, etc.",
        "Directions respecting jury or jurors directory",
        "Directing verdict",
        "Directions to prevent communication",
        "Direct indictments",
        "Directions for expediting proceedings",
        "Directions for expediting trial",
        "Disclosure in proceedings",
        "Disclosure in connection with proceedings",
        "Disclosure",
        "Dismissal for failure to appear or want of prosecution",
        "Disposition of application by appeal court",
        "Disposal of penalties when joint offenders",
        "Discharge of sureties",
        "Disclosure to Correctional Service of Canada",
        "Disposition of appeal",
        "Disposal of things seized",
        "Disposition of application",
        "Dismissal for want of prosecution",
        "Disability to contract",
        "Disclosure of income tax information",
        "Disputed facts",
        "Discretion respecting punishment",
        "Disclosure by peace officer",
        "Disclosure of records",
        "Distinguishing mark on public stores",
        "Disposal of property to defraud creditors",
        "Disguise with intent",
        "Discretionary review",
        "Disposition by Review Board",
        "Discretionary powers respecting suspension of dispositions",
        "Disposition takes precedence over probation orders",
        "Dispositions that may be made",
        "Disclosure for research or statistical purposes",
        "Disposition information to be made available to parties",
        "Disposition made by court",
        "Disposition to be made",
        "Disposal of matter",
        "Disagreement of jury",
        "Discretion not reviewable",
        "Disclosure of jury proceedings",
        "Disagreement of triers",
        "Discharge of juror",
        "Disobeying orders",
        "Disposal",
        "Discretion to postpone execution",
        "Discharge",
        "Discharge from custody",
        "Discretion to postpone execution",
        "Disarming a peace officer",
        "Discretionary order of prohibition",
        "Discharging firearm recklessness",
        "Discharging firearm with intent",
        "Disposal of property seized",
        "Disclosure of information received from interception of radio-based telephone communications",
        "Disclosure of information",
        "Disturbing religious worship or certain meetings",
        "Disposal of Matter",
        "Disobeying a Statute",
        "Disobeying Order of Court",
        "Disposition of Seized Things",
        "Disposal",
        "Discretionary Prohibition Order",
        "Disclosure",
        "Disposition of Property",
        "Divorce proceedings an exception",
        "DNA profile in data bank",
        "DNA profile not in data bank",
        "Documents in writing",
        "Documents to be considered",
        "Drawing document without authority, etc.",
        "Drawing additional cards if necessary",
        "Duelling",
        "Duplicates and facsimiles acceptable",
        "Duration of obligation",
        "Duration extended",
        "Duration extended",
        "Duration extended",
        "Duration of obligation",
        "Duration of order",
        "Duration of order",
        "Duration of order",
        "Duration of order",
        "Duration of order",
        "Duration of order and limit on term of order",
        "Duration",
        "Duration of warrant",
        "Duration of Prohibition",
        "Duration of Revocation or Amendment Orders Under Section 515",
        "Duration of Prohibition Order",
        "Duration of Prohibition Order Subsequent Offences",
        "Duration of Prohibition Order - First Offence",
        "Duty of clerk of court",
        "Duty of justice",
        "Duty to inform",
        "Duty of person in possession or control",
        "Duty of accused when not in custody",
        "Duty of sheriff when date set for trial",
        "Duty of judge",
        "Duty of prosecutor to give notice",
        "Duty to safeguard opening in ice",
        "Duty of persons directing work",
        "Duty of persons undertaking acts",
        "Duty of persons undertaking acts dangerous to life",
        "Duty of persons to provide necessaries",
        "Duty of landlord on notice",
        "Duty of Peace Officer",
        "Duty of care re explosive",
        "Duty of officers if rioters do not disperse",
        "Duty of person arresting",
        "Dwelling-house",
        "Each count separate",
        "Earned remission does not apply",
        "Editing of copies",
        "Effect of certificate",
        "Effect of order",
        "Effect of order dismissing application to quash",
        "Effect of committal",
        "Effect of decision",
        "Effect of subsequent arrest",
        "Effect of endorsement",
        "Effect of free pardon",
        "Effect of registered order",
        "Effect of discharge",
        "Effect of filing order",
        "Effect of filing order",
        "Effect of imprisonment",
        "Effect of judgment",
        "Effect of stay",
        "Effect",
        "Effect of suspension of disposition",
        "Effective date of disposition",
        "Effects of placement decision",
        "Effect of verdict of not criminally responsible on account of mental disorder",
        "Effect of entry in account",
        "Effect of entry in account",
        "Effect of designation",
        "Effect of plea on punishment",
        "Effect of previous charge of infanticide or manslaughter",
        "Effect of previous charge of murder or manslaughter",
        "Effect of endorsement",
        "Effect of condition",
        "Effect when game partly played on premises",
        "Effect of Order",
        "Effect of judicial acts",
        "Electronically transmitted copies",
        "Election if new trial",
        "Election if new trial a jury trial Nunavut",
        "Election if new trial a jury trial",
        "Election deemed to be waived",
        "Election deemed to be waived",
        "Election deemed to have been made",
        "Elections and re-elections in writing",
        "Election before justice in certain cases Nunavut",
        "Election before justice in certain cases",
        "Election of Crown under Contraventions Act",
        "Emergency designation",
        "Empanelling new jury in certain cases",
        "Employees of Museums Handling Firearms Generally",
        "Employees of Museums Handling Functioning Imitation Antique Firearm",
        "Employees of Carriers",
        "Employees of Business with Licence",
        "Employees of Business with Licence",
        "Endorsement",
        "Endorsement on recognizance",
        "Endorsement",
        "Endorsement of search warrant",
        "Endorsement of warrant",
        "Endorsing indictment",
        "Endorsing charge",
        "Endorsement on the information",
        "Endorsement on the information",
        "Endorsing warrant",
        "Endorsement of warrant by justice",
        "Endorsement by offender",
        "Endangering safety of ship or fixed platform",
        "Endangering safety of aircraft or airport",
        "Enforcement by justice",
        "Enforcement",
        "Enforcement of decision",
        "Enforcement of conviction or order by court of appeal",
        "Enforcing restitution order",
        "Enforcement",
        "Enforcement of decision",
        "Engaging in prize fight",
        "Entrance",
        "Entrance",
        "Escape and Being at Large without Excuse",
        "Establishment of list",
        "Evaluation",
        "Evidence of character",
        "Evidence",
        "Evidence concerning victim admissible",
        "Evidence",
        "Evidence of enlistment",
        "Evidence",
        "Evidence of previous conviction",
        "Evidence",
        "Evidence of victim or witness who has a disability",
        "Evidence of victim or witness under 18",
        "Evidence at preliminary inquiry may be read at trial in certain cases",
        "Evidence not excluded",
        "Evidence of medical practitioner",
        "Evidence required",
        "Evidence of character",
        "Evidence of property in timber",
        "Evidence of property in cattle",
        "Evidence in case of polygamy",
        "Evidence of identity of charges",
        "Evidence",
        "Evidence and powers of judge on review",
        "Evidence and powers of judge on review",
        "Evidence of complainants sexual activity",
        "Evidence",
        "Evidence of failure to comply with demand",
        "Evidence of failure to give sample",
        "Evidence",
        "Evidence of peace officer",
        "Evidence in Specific Cases",
        "Evidence",
        "Evidence",
        "Evidence of overt acts",
        "Examination of witnesses",
        "Examination or seizure of certain documents where privilege claimed",
        "Exaggeration",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception to long-term supervision life sentence",
        "Exception if application made after sentencing",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exclusion of public in certain cases",
        "Exception",
        "Exception",
        "Exception",
        "Exception multiple murderers",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exchequer bill paper, public seals, etc.",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exclusion of certain persons from hearing",
        "Exception where disclosure unnecessary or prejudicial",
        "Exception where disclosure dangerous to any person",
        "Exceptions",
        "Exception for compelling circumstances",
        "Exception in fitness cases",
        "Exception",
        "Exceptions",
        "Exceptions",
        "Exception",
        "Exclusion order",
        "Excusing jurors",
        "Excusing of alternate jurors",
        "Exception: foreign trials in absentia",
        "Exception",
        "Exception",
        "Exception",
        "Excavation on land",
        "Exception",
        "Exception",
        "Excision",
        "Exception",
        "Exception re: pari-mutuel betting",
        "Exception",
        "Exception",
        "Exception",
        "Exception criminal organization or terrorism offence",
        "Exception for criminal organizations and terrorist groups",
        "Exception",
        "Exception for criminal organizations and terrorism offences",
        "Exception for criminal organizations and terrorist groups",
        "Exception",
        "Exceptions",
        "Exception for Transitional Purposes",
        "Exception Complainant Aged 14 or 15",
        "Exception Complainant Aged 12 or 13",
        "Exception for Diversion Agreements",
        "Exception",
        "Exception",
        "Exception",
        "Exception Antique Firearms",
        "Exceptions",
        "Exceptions",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Exception",
        "Excessive force",
        "Exception: offences under Controlled Drugs and Substances Act",
        "Exemption order",
        "Exemption order",
        "Execution of search warrant",
        "Execution of warrant against young person",
        "Execution of warrant",
        "Execution in another province",
        "Exemption",
        "Exercising powers of arrest, entry, etc.",
        "Execution of warrant of committal",
        "Execution",
        "Execution in another province",
        "Execution of warrant in other territorial jurisdictions",
        "Execution in another province",
        "Execution of warrant",
        "Execution of process",
        "Execution of warrant anywhere in Canada",
        "Exemption from seizure of communication facilities",
        "Execution",
        "Execution",
        "Execution of authorization",
        "Execution",
        "Execution of warrant",
        "Execution",
        "Exemption lottery scheme on an international cruise ship",
        "Exemption",
        "Exemption for fairs",
        "Execution in another province",
        "Execution of authorizations",
        "Exemptions",
        "Exemptions",
        "Exemption",
        "Exemption for Accused Aged Twelve or Thirteen",
        "Execution of Order",
        "Execution of Warrant",
        "Exemptions",
        "Exigent circumstances",
        "Existing equities maintained",
        "Expiration of special warrants and restraint orders",
        "Expenses",
        "Explosive or other lethal device",
        "Expert testimony",
        "Exploitation",
        "Exposure",
        "Expanded jurisdiction for specified offences",
        "Expressions taken from other acts",
        "Extension of time",
        "Extension of time",
        "Extension of time",
        "Extension of period for giving notice",
        "Extension of time",
        "Extension of period for compliance with order",
        "Extension of time",
        "Extension for serious personal violence offence",
        "Extension on consent",
        "Extension of time for hearing",
        "Extension of time for holding inquiry",
        "Extension",
        "Extortion",
        "Extortion",
        "Extortion by libel",
        "Extension of period for notification",
        "Extension of period for notification",
        "Extension of period for notification",
        "																																",
        "																																",
        "Fabricating Evidence",
        "Factors",
        "Factors in relation to dwelling-house",
        "Factors to be considered",
        "Factors to be considered",
        "Factors to be considered",
        "Factors to be considered",
        "Facilitation",
        "Factors",
        "Factors to be considered",
        "Factors",
        "Factors or consider",
        "Factors to be considered",
        "Factors to be considered",
        "Factors that judge must consider",
        "Factors to be considered",
        "Facilitation",
        "Factors",
        "Facilitating Terrorist Activity",
        "Facilitation",
        "Factors",
        "Failure to comply",
        "Failure to make order",
        "Failure to comply with order or summons",
        "Failure to appear",
        "Failure to comply with order",
        "Failure to comply with probation order",
        "Failure to exercise reasonable care as evidence",
        "Failure to exercise reasonable care as evidence",
        "Failure to comply with subsection (3)",
        "Fair comment on public person or work of art",
        "Fair report of public meeting",
        "Fair reports of parliamentary or judicial proceedings",
        "Failure of accused to attend",
        "Failure of accused to attend",
        "Failure to comply",
        "Failure to comply with order",
        "Failure to appear",
        "Failure to appear",
        "Failure or refusal to provide sample death",
        "Failure or refusal to provide sample bodily harm",
        "Failure or refusal to comply with demand",
        "Failure to stop at scene of accident",
        "Failure to keep watch on person towed",
        "Failure to Comply with Conditions of Undertaking",
        "Failure to Comply with Appearance Notice or Promise to Appear",
        "Failure to Appear or to Comply with Summons",
        "Failure to Comply with Condition of Undertaking or Recognizance",
        "Failure to Attend Court",
        "Failure to Surrender Authorization, etc.",
        "False alarm of fire",
        "Falsely claiming royal warrant",
        "False prospectus, etc.",
        "False return by public officer",
        "Falsifying employment record",
        "False messages",
        "False pretence or false statement",
        "False pretence",
        "False Statements",
        "False communication",
        "False statement in relation to passport",
        "Fear of certain offences",
        "Federal-provincial agreement",
        "Fees and allowances",
        "Fees",
        "Filing of order from another province",
        "Finding of guilt, conviction, order or dismissal",
        "Finding of guilt, conviction or order if charge admitted",
        "Fingerprints",
        "Finding in one court binding",
        "Fine option program not available to offender",
        "Fine instead of forfeiture",
        "Fine option program",
        "Fines on organizations",
        "Firearm, etc., prohibitions",
        "Firearm, etc., prohibitions",
        "First degree murder charged",
        "Fixing day for hearing",
        "Fixing day for hearing",
        "Flight causing bodily harm or death",
        "Flight",
        "For greater certainty",
        "Forfeiture",
        "Forfeiture of weapons and ammunition",
        "Form of appeal",
        "Former evidence",
        "Forms",
        "Form of undertaking or recognizance",
        "Forms",
        "Form",
        "Formalities of information",
        "Formalities respecting warrant and facsimiles",
        "For greater certainty",
        "Form",
        "For greater certainty",
        "Form",
        "Forwarding to clerk where accused to stand trial",
        "Form of recognizance",
        "For greater certainty",
        "For greater certainty",
        "Form and period of order",
        "Forfeiture",
        "Forfeiture- limitation",
        "Forfeiture",
        "Forfeiture",
        "Forging trade-mark",
        "Forfeiture",
        "Forfeiture",
        "Forgery instruments",
        "Form of victims",
        "Forgery complete though document incomplete",
        "Forgery",
        "Form",
        "Form of subpoena in sexual offences",
        "Form of application",
        "Form of subpoena",
        "Form of undertaking or recognizance",
        "Forfeiture",
        "Forfeiture",
        "Form",
        "Forfeiture",
        "Forfeiture",
        "Formal adjournment unnecessary",
        "Form",
        "Form",
        "Form of record in case of amendment",
        "Forcible confinement",
        "Form",
        "Form of indictment",
        "Form of statement",
        "Form and content of application",
        "Form",
        "Formalities of warrant",
        "Form and content of application",
        "Form",
        "For greater certainty",
        "Foreign lotterly included",
        "Forfeiture After Conviction",
        "Forfeiture",
        "Forfeiture and Prohibition Order on Finding",
        "Forfeiture of Seized thing",
        "Forfeiture",
        "For greater certainty",
        "For greater certainty",
        "Forgery of or uttering forged passport",
        "For greater certainty",
        "Fraudulent use of money genuine but valueless",
        "Fraud in relation to valuable minerals",
        "Fraudulently obtaining transportation",
        "Fraud in relation to fares, etc.",
        "Fraudulent receipts under Bank Act",
        "Fraudulent disposal of goods on which money advanced",
        "Fraudulent sale of real property",
        "Fraudulent registration of title",
        "Fraudulent concealment of title documents",
        "Fraudulent manipulation of stock exchange transactions",
        "Fraud",
        "Fraudulently obtaining food, beverage or accommodation",
        "Fraudulent concealment",
        "Fraudulently taking cattle or defacing brand",
        "Frauds on the Government",
        "Fraudulent use of certificate of citizenship",
        "Free or conditional pardon",
        "Freezing of property",
        "Full offence charged, attempt proved",
        "Fundamental principle",
        "Further warrants",
        "Further detention",
        "Further five-year period if no application made",
        "Further disclosure",
        "Further commitment",
        "Further particulars",
        "Further definitions - firearms",
        "																																",
        "																																",
        "Gaming in stocks or merchandise",
        "General penalty",
        "General provisions re appeals",
        "General order for security by recognizance",
        "General rule for period",
        "General authority to manager when negligence",
        "General provisions not restricted",
        "General or special order",
        "Giving person in charge",
        "Giving to purchaser of goods",
        "Giving information to person interested",
        "Giving authorization where telecommunication produces writing",
        "Giving authorization",
        "Government records",
        "Granting of forfeiture order",
        "Grounds",
        "Grounds",
        "Grounds for appeal",
        "Grounds of appeal",
        "Grounds of review",
        "Habeas corpus",
        "Harassing telephone calls",
        "Having clippings, etc.",
        "Having in possession when complete",
        "Hearing",
        "Hearing",
        "Hearing",
        "Hearing",
        "Hearing in private",
        "Hearing may be held",
        "Hearing of appeal",
        "Hearing of application",
        "Hearing of application",
        "Hearing",
        "Hearsay evidence",
        "Hearing may be held",
        "Hearing to be held by a court",
        "Hearing to be informal",
        "Hearing and order",
        "Hearing in camera",
        "Hearing",
        "Hearing in Camera",
        "Hearing",
        "Hearing of witnesses",
        "Hearing",
        "Hearing Before Judge",
        "Hearing of Application",
        "Hearing of Application",
        "Hearing of Application",
        "Hearing of Application",
        "High treason and first degree murder",
        "High treason",
        "Hijacking, sexual assault or kidnapping",
        "Hoax Terrorist Activity",
        "Holder or beneficiary of fire insurance policy",
        "Homicide",
        "Hostage taking",
        "Hostage-taking",
        "Householder Permitting Sexual Activity",
        "How recovered",
        "How recorded",
        "How Person Dealt With",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Identification of person named in the order",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Identity fraud",
        "Identity theft",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Idem",
        "Identity documents",
        "Ignorance of the law",
        "Immediate interception imminent harm",
        "Immoral Theatrical Performance",
        "Immunity",
        "Immunity",
        "Imprisonment in default where not otherwise specified",
        "Imprisonment for term less than two years",
        "Imprisonment for life or more than two years",
        "Imprisonment when no other provision",
        "Imposing of conditional sentence",
        "Imprisonment in default of payment of fine",
        "Imprisonment in default of payment",
        "Imprisonment in default where term not specified",
        "Impeding attempt to save life",
        "Impaired driving causing death",
        "Impaired driving causing bodily harm",
        "Importing or Exporting Knowing it is Unauthorized",
        "Inability of justice to continue",
        "Inappropriate questioning",
        "Inadmissibility of evidence",
        "Increase in surcharge",
        "Incompetency no defence",
        "Included or other offence",
        "Including authorization to enter in warrant of arrest",
        "Incorporated material is not a regulation",
        "Incorporated material",
        "Incest",
        "Inciting to mutiny",
        "Indecent telephone calls",
        "Indictment for treason",
        "Indictment",
        "Indictment Nunavut",
        "Indictment",
        "Indecent acts",
        "Individuals Acting for Police Force, Canadian Forces and Visiting Forces",
        "Ineligibility for parole multiple murders",
        "Ineligibility for parole",
        "Information for treasonable words",
        "Information re number recorder",
        "Information for tracking warrant",
        "Information to be provided to Attorney General",
        "Information submitted by other means of telecommunication",
        "Information submitted by telephone",
        "Information for warrant to take bodily substances for forensic DNA analysis",
        "Information for general warrant",
        "Information for search warrant",
        "Information provided by victim",
        "Information in respect of parole",
        "Inference",
        "Information accepted",
        "Information, copies",
        "Information to be made available to specified persons",
        "Inference from appearance",
        "Information Requirement",
        "Information relevant to presentation of evidence on merits to be part of court record",
        "Information laid otherwise than in person",
        "Information deemed not to have been laid proceedings commenced",
        "Information deemed not to have been laid",
        "Information on oath",
        "Infanticide",
        "Information respecting authorizations",
        "Inference of Sexual Exploitation",
        "Influencing or Negotiating Appointments or Dealing in Offices",
        "Influencing Municiple Officer",
        "Injuring or endandering other animals",
        "Injuring or endangering cattle",
        "Inquiry by court",
        "Inquiry",
        "Inquiry by court",
        "Inquiry by court or Review Board",
        "Inquiry by justice",
        "Inquiries to be made by justice and evidence",
        "Instructing commission of offence for criminal ogranization",
        "Instruments for foring trade-mark",
        "Instruments for copying credit card data or forging or falsifying credit cards",
        "Insufficient grounds",
        "Installation and removal of device",
        "Instructing to Carry out Terrorist Activity",
        "Instructing to Carry out Activity for Terrorist Group",
        "Interpretation",
        "Interim release",
        "Intermittent sentence",
        "Interfering with international boundary marks, etc.",
        "Interfering with boundary lines",
        "Interferng with marine signal, etc.",
        "Interfering with saving a wreck",
        "Interfering with saving of wrecked vessel",
        "Interest",
        "Intimidation of a justice system participant or a journalist",
        "Intimidation",
        "Interprovincial transfers",
        "Interested person may be a party",
        "Interim release",
        "Intervention by Attorney General of Canada",
        "Interim orders",
        "Interpretation",
        "Interim release by judge only",
        "Interim release",
        "Interpretation",
        "Interfering with transportation facilities",
        "Intimidation",
        "Interception of radio-based telephone communications",
        "Interception with consent",
        "Interception to prevent bodily harm",
        "Interception",
        "Interim Preservation Rights",
        "Intimidating Parliament or legislature",
        "Investigative procedures",
        "Invitation to Sexual Touching",
        "Irregularities within section 777",
        "Irregularity or variance not to affect validity",
        "Issuance of certificate",
        "Issue of appearance notice by peace officer",
        "Issuance of warrant where telecommunication produces writing",
        "Issuing warrant",
        "Issuing trading stamps",
        "Issues referred to case management judge",
        "Issue of warrant for arrest of accused",
        "Issue of summons or warrant",
        "Joinder of other offences",
        "Joinder of counts",
        "Judge may act in chambers",
        "Judge may act in chambers",
        "Judge may examine information",
        "Judge may vary recognizance",
        "Judgment debtors of the Crown",
        "Judicial notice",
        "Judicial screening",
        "Judgment not to be stayed on certain grounds",
        "Judge may order production of record to accused",
        "Judge may order production of record for review",
        "Judge to instruct jury re use of evidence",
        "Judge's determination and reasons",
        "Judge may decide to hold hearding",
        "Judge to be satisfied",
        "Judge to be satisfied",
        "Judge to Rule",
        "Judgment may be registered",
        "Judicial review",
        "Jurisdiction",
        "Jurisdiction",
        "Jurisdiction over person",
        "Jurisdiction",
        "Jurisdiction",
        "Jurisdiction",
        "Jurisdiction",
        "Jury",
        "Jurisdiction when appointment to another court",
        "Jurisdiction",
        "Jurisdiction",
        "Juror and other persons to be sworn",
        "Jurisdiction",
        "Jurisdiction Nunavut",
        "Jury and public excluded",
        "Jurisdiction",
        "Jurisdiction",
        "Jury and public excluded",
        "Jurisdiction",
        "Justice not available",
        "Justice system participants",
        "Justification for detention in custody",
        "Justice to hear informant and witnesses",
        "Justice to hear informant and witnesses public prosecutions",
        "Justification for acts or omissions",
        "Keeping cockpit",
        "Keeping in charge",
        "Keeping common-bawdy house",
        "Keeping gaming or betting house",
        "Kidnapping",
        "Kidnapping",
        "Killing unborn child in act of birth",
        "Killing by influence on the mind",
        "Killing child",
        "Kinds of homicide",
        "Language of accused",
        "Language used in proceeding",
        "Landlord, inmate, etc.",
        "Laundering proceeds of crime",
        "Lawful assembly becoming unlawful",
        "Laying of information, etc.",
        "Leaving Canada to Commit Offence that is Terrorist Activity",
        "Leaving Canada to Commit Offence for Terrorist Group",
        "Leaving Canada to Facilitate Terrorist Activity",
        "Leaving Canada to Participate in Activity of Terrorist Group",
        "Legal assistance for accused",
        "Legal assistance for appellant",
        "Less than 15 years of sentence served",
        "Levy under writ",
        "Libel, plea of justification",
        "Licences, permits, etc.",
        "Lifting of Prohibition Order for Sustenance or Employment",
        "Likeness of bank-notes",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation on the use of agents",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation period",
        "Limitation period for appeal",
        "Limitation period for appeal",
        "Limitation on prosecutors application for assessment",
        "Limitation on prosecutors application for assessment of fitness",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation indictable offence",
        "Limited preliminary inquiry",
        "Limitation of further applications",
        "Limitation of further applications",
        "Limitation",
        "Limitation",
        "Limitation",
        "Limitation - Treason",
        "Living on the avails of prostitution of person under eighteen",
        "Long-term supervision",
        "Long-term supervision",
        "Losing or Finding",
        "Lottery Sale Void",
        "Luring a Child",
        "Mailing Obscene Matter",
        "Majority vote",
        "Making, having or dealing in instruments for counterfeiting",
        "Making",
        "Making of probation order",
        "Making false document",
        "Making Sexually Explicit Material Available to Child",
        "Making Automatic Firearm",
        "Making of Order",
        "Management order",
        "Manitoba and Alberta",
        "Manner of giving notice",
        "Manner of giving notice",
        "Manner of giving notice",
        "Manner of appearance",
        "Mandatory order on application",
        "Management order",
        "Manner of giving notice",
        "Manner of appearance",
        "Mandatory review of dispositions",
        "Mandatory pre-trial hearing for jury trials",
        "Mandatory life prohibition",
        "Mandatory order of prohibition death",
        "Mandatory order of prohibition bodily harm",
        "Mandatory order of prohibition street racing",
        "Mandatory order of prohibition",
        "Manslaughter",
        "Manslaughter",
        "Manner in which application to be kept secret",
        "Mandatory Prohibition Order",
        "Manner of giving notice",
        "Marriage contrary to law",
        "Material Benefit",
        "Matters of Defence",
        "Matters to be considered by the court",
        "Matters to be considered on hearing",
        "Matters not material",
        "Maximum length of long-term supervision",
        "Maximum period for detention of witness",
        "Maximum duration of extensions",
        "Maximum number",
        "Meaning of default of payment",
        "Meaning of Attorney General",
        "Meaning of consent",
        "Meaning of Competent Authority",
        "Meaning of Holder",
        "Meaning of Participating or Contributing",
        "Memo of conviction or order",
        "Members of Review Board",
        "Military stores",
        "Minimum that can be accepted",
        "Minimum punishment",
        "Minister shall be a party",
        "Minister and Review Board entitled to access",
        "Minimum absolute prohibition period",
        "Minimum punishment",
        "Minimum punishment",
        "Ministerial authorization",
        "Mischief in relation to cultural property",
        "Mischief relating to religious property",
        "Mischief",
        "Mischief in relation to data",
        "Misleading receipt",
        "Misappropriation of money held under direction",
        "Mistrial rulings binding at new trial",
        "Mistakes not material",
        "Mistake of Age",
        "Mistake of Age",
        "Misconduct of Officers Executing Process",
        "Mistaken identity",
        "Modification of recognizance",
        "Mode of Expression",
        "Mode of trial if two or more accused Nunavut",
        "Mode of trial when two or more accused",
        "Moneys found on offender",
        "Moneys found on offender",
        "Money or other valuable security to be deposited with justice",
        "Moneys in possession of accused may be taken",
        "Monthly report",
        "More than one offence",
        "More than one offence",
        "Motor vehicle theft",
        "Motives are Irrelevant",
        "Motion to vary or set aside",
        "Multiple orders",
        "Multiple sentences",
        "Municiple Corruption",
        "Murder reduced to manslaughter",
        "Murder criminal organization",
        "Murder- terrorist activity",
        "Murder of peace officer, etc.",
        "Murder in commission of offences",
        "Murder",
        "Names of jurors on cards",
        "Names of jurors",
        "Navigation in the internal waters or territorial sea of another state",
        "Neglect to obtain assistance in child-birth",
        "Neglect by peace officer",
        "New trial",
        "New long-term supervision",
        "New offence",
        "New territorial division",
        "Newfoundland",
        "New trial under Part XIX Nunavut",
        "New trial under Part XIX",
        "New evidence required for new hearing",
        "New application",
        "Non-forfeiture of property",
        "Non-appearance of defendant",
        "Non-appearance of prosecutor",
        "Non-appearance of prosecutor",
        "Non-application of subsection (2.2)",
        "Non-communication order",
        "Non-compliance with prevention laws",
        "Non-mitigating factors",
        "Non-application",
        "Non-appearance",
        "Non-resistance",
        "Non-compliance with subsection (2)",
        "Non-appearance",
        "Non-resistance",
        "Non-application informations laid under sections 810 and 810.1",
        "Non culpable homicide",
        "Non-Appearance",
        "Notice",
        "Notice of intention to produce certified photograph",
        "Notice",
        "Notice",
        "Notice",
        "Notice",
        "Notice of intention to produce",
        "Notice on disposition by Review Board",
        "Notice before release",
        "Notification",
        "Notification and transmission of conviction, etc.",
        "Notice before release",
        "Notice of appeal",
        "Notice on disposition by Review Board",
        "Notice to Attorney General",
        "Notice",
        "Notice after covert entry",
        "Notice",
        "Notice of orders of restitution",
        "Notice",
        "Notice",
        "Notice",
        "Notice",
        "Notice to Attorney General",
        "Notice",
        "Notice",
        "Notice",
        "Notice of intention to produce certificate",
        "Notice of appeal",
        "Notice",
        "Notice to accused",
        "Notice to accused",
        "Notice of application for release",
        "Notice",
        "Notice",
        "Notice",
        "Notice of appeal",
        "Notice of appeal to be given to court or Review Board",
        "Notice of discharge",
        "Notice required",
        "Notice to accused and Review Board of increase in restrictions",
        "Notice of hearing",
        "Notice",
        "Notice",
        "Notice of intention to produce certificate",
        "Notice to person who posted the material",
        "Notice for expert testimony",
        "Notice of intention to produce affidavit or solemn declaration",
        "Not guilty, in addition",
        "Notice of re-election",
        "Notice to organization",
        "Notice when no preliminary inquiry or preliminary inquiry completed Nunavut",
        "Notice at preliminary inquiry Nunavut",
        "Notice of re-election under subsection (1) or (3) Nunavut",
        "Notice and transmitting record",
        "Notice by sheriff, when given",
        "Notice of hearing",
        "Notice of intention to tender",
        "Notice to accused",
        "Notice to prosecutor",
        "Notice",
        "Notice to accused",
        "Notice of intention to produce certificate",
        "Notice of conviction to be served on owner",
        "Notice of intention to produce evidence",
        "Notice to Attorney General",
        "Notice to Person who Posted the Material",
        "Notice of Intention to Produce",
        "Notice of Intention to Produce Certificate",
        "Notice",
        "Notice",
        "Notice of the decision to the applicant",
        "Nude",
        "Nudity",
        "Nunavut",
        "Nunavut",
        "Nunavut",
        "Nunavut",
        "Nunavut Court of Justice",
        "Nunavut",
        "Oaths",
        "Oath or affirmation",
        "Oath",
        "Obeying order of peace officer",
        "Obedience to de facto law",
        "Objection to disclosure of information",
        "Objectives offence against peace officer or other justice system participant",
        "Objectives offences against children",
        "Objection that name not on panel",
        "Obligation ends",
        "Obligation to advise police service",
        "Obligation",
        "Obligation to comply",
        "Obligations of court",
        "Obligations of court",
        "Obligations of court",
        "Obliterated vehicle identification number",
        "Obligation to Answer Questions and Produce Things",
        "Obstructing or violence to or arrest of officiating clergyman",
        "Obscene Publication",
        "Obstructing Justice",
        "Obtaining carriage by false billing",
        "Obtaining, etc., by instrument based on forged document",
        "Obtaining execution of valuable security by fraud",
        "Occupant injuring building",
        "Offence",
        "Offence",
        "Offence",
        "Offence",
        "Official languages",
        "Offence",
        "Offence",
        "Offenders serving sentences",
        "Offence",
        "Offence",
        "Offences",
        "Officials with powers of two justices",
        "Offence in Canadian waters",
        "Offence not in a province",
        "Offence outside Canada",
        "Offence in unorganized territory",
        "Offence outstanding in same province",
        "Offence committed entirely in one province",
        "Offences outside of Canada",
        "Offender may establish that property is not proceeds of crime",
        "Offences",
        "Offence",
        "Offence",
        "Offence",
        "Offenders ability to pay",
        "Offender may speak to sentence",
        "Offences by employers",
        "Offences by representatives",
        "Offence",
        "Offences in relation to wreck",
        "Offence",
        "Offence",
        "Offence",
        "Offence",
        "Offences in relation to mines",
        "Offences in relation to registers",
        "Offence",
        "Offence charged, part only proved",
        "Offence",
        "Offence",
        "Offences may be charged in the alternative",
        "Offence",
        "Offence",
        "Offences",
        "Offence",
        "Offences",
        "Offence involving bodily harm or death",
        "Offence involving bodily harm",
        "Offence-related place bodily harm",
        "Offence-related place",
        "Offence",
        "Offence in relation to prostitution",
        "Offence prostitution of person under eighteen",
        "Offence",
        "Offence",
        "Offence",
        "Offence in relation to lotteries and games of chance",
        "Offensive volatile substance",
        "Offence",
        "Offences",
        "Offences Relating to Affidavits",
        "Offences relating to Public or Peace Officer",
        "Offender must be Notified",
        "Offences freezing of property, disclosure or audit",
        "Offensive weapons and explosive substances",
        "Offences related to proclamation",
        "Offences in relation to military forces",
        "Offences in relation to members of RCMP",
        "Offences of negligence organizations",
        "Offence punishable under more than one Act",
        "Offence in relation to trafficking in persons",
        "Offence in relation to sexual offences against children",
        "Offences by Public Service employees",
        "Offence relating to financing of terrorism",
        "Offence involving explosive or other lethal device",
        "Offence against United Nations or associated personnel",
        "Offences involving nuclear material",
        "Offence of hostage taking",
        "Offences against internationally protected person",
        "Offences against fixed platforms or international maritime navigation",
        "Offences in relation to cultural property",
        "Offences committed on aircraft",
        "Offences outside Canada",
        "Omitting announcement before entry",
        "One justice may act before the trial",
        "One application for authorization sufficient",
        "Only one determination of guilt",
        "Onus",
        "Onus",
        "Onus",
        "Onus on the Accused",
        "Operation of computer system and copying equipment",
        "Operation while disqualified",
        "Operation while impaired",
        "Operation of pari-mutuel system",
        "Opening on order of trial judge",
        "Opening on order of judge",
        "Opening for further applications",
        "Opinions",
        "Optional conditions of conditional sentence order",
        "Optional conditions organization",
        "Optional conditions of probation order",
        "Orally",
        "Order re telephone records",
        "Order for restitution or forfeiture of property obtained by crime",
        "Order continues in force",
        "Order in writing",
        "Order",
        "Order declaring interest not affected by forfeiture",
        "Order of restoration of property",
        "Order of forfeiture of property",
        "Order of forfeiture of property on conviction",
        "Order of lieutenant governor in council",
        "Order fixing date",
        "Order of detention or release",
        "Order of dismissal",
        "Order set out",
        "Order may be filed",
        "Order of judge",
        "Order- if previous offence established",
        "Order",
        "Order",
        "Order- if intent established",
        "Order to custodian to deliver",
        "Order denying access to information used to obtain a warrant or production order",
        "Order to offender",
        "Order",
        "Order- persons found not criminally responsible and secondary designated offences",
        "Order- primary designated offences",
        "Order- primary designated offences",
        "Order suspended",
        "Order restricting publication- victims and witnesses",
        "Order restricting publication- sexual offences",
        "Order of restoration",
        "Order for disclosure information",
        "Order declaring interest not subject to forfeiture",
        "Order of restoration of property",
        "Order for forfeiture of property",
        "Order of forfeiture- particular circumstances",
        "Order of forfeiture of property on conviction",
        "Order of restoration of property or revocation or variation of order",
        "Order in writing",
        "Order",
        "Order of prohibition or restitution",
        "Order prohibiting use",
        "Order prohibiting use",
        "Order appointing commissioner",
        "Order where witness arrested under warrant",
        "Order of judge",
        "Order of judge",
        "Order of Supreme Court of Canada",
        "Order to be made",
        "Order of justice pending decision of Review Board",
        "Order",
        "Orders that the court may make",
        "Order restricting publication",
        "Order restricting publication other offences",
        "Order restricting publication sexual offences",
        "Order excluding the public",
        "Order of forfeiture",
        "Order",
        "Order of challenges",
        "Order is authority to remove prisoner",
        "Order",
        "Order for severance",
        "Order to appear at joint hearing",
        "Order in court record and transmission to parties",
        "Order for joint hearing",
        "Order to stand trial at any stage of inquiry with consent",
        "Order to stand trial or discharge",
        "Order that accused appear or be taken before justice where offence committed",
        "Order restricting publication of evidence taken at preliminary inquiry",
        "Order for hearing",
        "Order not reviewable",
        "Order",
        "Order vacating previous order for release or detention",
        "Order not reviewable except under section 680",
        "Order re no communication",
        "Order directing matters not to be published for specified period",
        "Order of detention",
        "Order of release",
        "Order of release",
        "Order re no communication",
        "Order of judge",
        "Order for destruction of documents",
        "Order of Forfeiture",
        "Order of Prohibition",
        "Order",
        "Order",
        "Order",
        "Order for Custody of Thing",
        "Order in Council",
        "Order for Gathering Information",
        "Order",
        "Order refusing forfeiture",
        "Orders by Governor in Council",
        "Organizations",
        "Organ or tissue removal",
        "Organization",
        "Organization",
        "Original version prevails",
        "Original documents to be returned",
        "Other provisions to apply",
        "Other provisions to apply",
        "Other provisions to apply",
        "Other provisions to apply",
        "Other provisions to apply",
        "Other witnesses",
        "Other witnesses",
        "Other witnesses",
        "Other offences",
        "Other sentencing principles",
        "Other offences in relation to trade-marks",
        "Other laws about witnesses to apply",
        "Other powers",
        "Other interprovincial transfers",
        "Other evidence",
        "Other provisions to apply",
        "Other persons becoming available",
        "Other grounds",
        "Other offences",
        "Other information",
        "Other provisions to apply",
        "Other provisions to apply",
        "Other Provisions to Apply",
        "Other Provisions to Apply",
        "Other Forfeiture Provisions Unaffected",
        "Other offences organizations",
        "Overcoming resistance to commission of offence",
        "Ownership",
        "Owner and author may appear",
        "Ownership",
        "Owner and Maker may Appear",
        "Oyster bed",
        "Oysters",
        "Pardon or record suspension",
        "Pardon or record suspension",
        "Part XXI applies re appeals",
        "Part XVI to apply",
        "Parole prohibited",
        "Participation in activities of criminal organization",
        "Parties entitled to adduce evidence and be heard",
        "Parliamentary papers",
        "Part XVI to apply",
        "Particular",
        "Paragraph 207(1)(h) and subsection 207(5) apply",
        "Parent or Guardian Procuring Sexual Activity",
        "Participation in Activity of Terrorist Group",
        "Parties to offence",
        "Passing off",
        "Pattern of criminal activity",
        "Payment of fine not a waiver of appeal",
        "Person bound by military law",
        "Period for and method of service",
        "Persons who may be served",
        "Period for and method of service",
        "Persons who may be served",
        "Perishable things",
        "Persons not in custody",
        "Persons under eighteen",
        "Persons under sixteen",
        "Person deemed absconded",
        "Period of imprisonment",
        "Period for which appearance notice, etc., continues in force",
        "Personation at examination",
        "Personal service",
        "Persons under eighteen",
        "Personal liability",
        "Person who posted the material may appear",
        "Peremptory challenges",
        "Period for which appearance notice, etc., continues in force",
        "Persons who may appear at hearing",
        "Permitted Lotteries",
        "Percentage that may be deducted and retained",
        "Percentage that may be deducted and retained",
        "Person found in or owner permitting use",
        "Persons designated",
        "Person Taking Part",
        "Person Who Posted the Material may Appear",
        "Permitting or Assisting Escape",
        "Perjury",
        "Personating Peace Officer",
        "Person to be Brought Before Judge",
        "Person acting at direction of public officer",
        "Person counselling offence",
        "Photographic evidence",
        "Piracy by law of nations",
        "Piratical acts",
        "Place",
        "Placement decision by Review Board",
        "Planned and deliberate murder",
        "Placing bets on behalf of others",
        "Plea of not guilty",
        "Plea of justification necessary",
        "Plea in writing",
        "Pleading over",
        "Pleas permitted",
        "Pointing a Firearm",
        "Police records",
        "Polygamy",
        "Possession, etc., of nuclear material, radioactive material or device",
        "Possession, etc., of counterfeit money",
        "Possession of incendiary material",
        "Possession of stolen or fraudulently obtained valuable minerals",
        "Possession of property obtained by crime trafficking",
        "Possession of property obtained by crime",
        "Possession of instruments for breaking into coin-operated or currency exchange devices",
        "Possession of break-in instrument",
        "Postponing trial of issue",
        "Possession of device to obtain computer service",
        "Possession of device to obtain telecommunication facility or service",
        "Possession, etc.",
        "Possession Contrary to Order",
        "Possession for Purpose of Weapons Trafficking",
        "Possession of Weapon Obtained by Commission of Offence",
        "Possession of Prohibited or Restricted Firearm with Ammunition",
        "Possession at Unauthorized Place",
        "Possession of Prohibited Weapon, Device or Ammunition Knowing its Possession is Unauthorized",
        "Possession of Firearm Knowing its Possession is Unauthorized",
        "Possession of Weapon for Dangerous Purpose",
        "Possession in association with criminal organization",
        "Possession without lawful excuse",
        "Possession of forged, etc., passport",
        "Power to manage",
        "Powers of appeal court",
        "Power of peace officer",
        "Power to revoke, renew or vary order",
        "Power to revoke, renew or vary order",
        "Power to make rules respecting case management",
        "Power to make rules",
        "Power to make rules",
        "Power of court to delay parole",
        "Power of court to delay parole",
        "Power of court to delay parole",
        "Powers of court",
        "Powers of court",
        "Powers of the Attorney General of a province",
        "Powers of the Attorney General of Canada",
        "Power to manage",
        "Powers of Attorney General of a province",
        "Powers of Attorney General of Canada",
        "Power of court to impose fine",
        "Power for seize",
        "Power to refer",
        "Powers of investigation",
        "Powers of Minister of Justice",
        "Powers of court on appeal against sentence",
        "Power to order suspension",
        "Powers",
        "Powers of court of appeal",
        "Powers of Review Board",
        "Powers of court of appeal",
        "Powers of courts not limited",
        "Powers of Review Boards",
        "Powers of judge",
        "Powers of judge",
        "Powers at stage of presentation of evidence on merits",
        "Power exercised at trial",
        "Powers before evidence on merits presented",
        "Powers of justice",
        "Powers of justice after hearing",
        "Power of justice to name sureties in order",
        "Powers of Attorney General",
        "Power to manage",
        "Power in case of escape from penitentiary",
        "Presumption",
        "Presence of accused at hearing of application",
        "Preserving order in court",
        "Presumption",
        "Previous conviction",
        "Presentation of statement",
        "Presence at baiting as evidence",
        "Presumption when accused a dealer in stores",
        "Presumption from port of shipment",
        "Presumption",
        "Presumption",
        "Pretending to practise witchcraft, etc.",
        "Presumption",
        "Presumption from cheque issued without funds",
        "Presumption",
        "Presentation of victim statement",
        "Presumption of fitness",
        "Presumption of custody in certain circumstances",
        "Presumption against custody Review Board",
        "Presumption against custody",
        "Presumptions",
        "Presumption",
        "Presumption from possession",
        "Presumption from possession",
        "Pretending to solemnize marriage",
        "Presumption valuable minerals",
        "Pre-charge conference",
        "Pre-hearing conference",
        "Presiding judge",
        "Previous charges of first degree murder",
        "Preferring single indictment",
        "Preferring indictment when no preliminary inquiry requested",
        "Preferring indictment Nunavut",
        "Preferring indictment",
        "Preliminary inquiry not requested Nunavut",
        "Preliminary inquiry not requested",
        "Preliminary inquiry if two or more accused",
        "Preliminary inquiry if two or more accused",
        "Previous convictions",
        "Presumption",
        "Presumptions",
        "Presumption",
        "Presumptions",
        "Presumption",
        "Presumption re age",
        "Presumption",
        "Preventing breach of peace",
        "Presumption of innocence",
        "Privilege continues",
        "Principles that are to guide the court",
        "Priority to restitution",
        "Priority for restitution to victims of crime",
        "Private records",
        "Privity to offence",
        "Privy",
        "Prima facie case to be made every two years",
        "Private prosecutor requires consent",
        "Privileged evidence",
        "Printing, publication, etc., of voyeuristic recordings",
        "Prison Breach",
        "Priority for Restitution to Victims of Crime",
        "Principle",
        "Protection of officers",
        "Production of property in court",
        "Procedure",
        "Property outside Canada",
        "Property outside Canada",
        "Property outside Canada",
        "Property related to other offences",
        "Proof of certain facts by certificate",
        "Proof of service",
        "Proof of service",
        "Procedure",
        "Procedure if charge not admitted",
        "Provisions of Part XXV",
        "Proceedings in case of default",
        "Probative force",
        "Procedure",
        "Prohibition",
        "Proof of authorization",
        "Proof of consent",
        "Providing facsimile",
        "Production to peace officer",
        "Production order- financial or commercial information",
        "Probative force of copies",
        "Production order",
        "Production to peace officer",
        "Provisions to apply",
        "Protection of witnesses under 18 and justice system participants",
        "Procedural irregularities",
        "Provisions to apply",
        "Prosecutors duty to advise court",
        "Procedure on breach of condition",
        "Prosecution",
        "Prosecution",
        "Prosecution",
        "Probative force",
        "Property outside Canada",
        "Property outside Canada",
        "Property outside Canada",
        "Proceeds of crime derived from other offences",
        "Procedure",
        "Procedure",
        "Property outside Canada",
        "Proceeds to go to Receiver General for Canada",
        "Proceeds to go to provincial treasurer",
        "Provincial regulations",
        "Proof of certificate of analyst",
        "Procedure",
        "Production of evidence",
        "Procedure for victim impact statement",
        "Provincial regulations",
        "Prohibited conduct",
        "Procedure",
        "Prohibited insider trading",
        "Prohibition order",
        "Providing for presence of accused counsel",
        "Procedure abolished",
        "Proper administration of justice",
        "Proceedings not invalid",
        "Prohibition on publication",
        "Prohibition of disclosure in certain cases",
        "Procedure at dispositio hearing",
        "Proceeding continues where accused is fit",
        "Protected statements not admissible against accused",
        "Proof of effective annual rate",
        "Proof of identity",
        "Proof of previous conviction",
        "Proving publication by order of legislature",
        "Proprietor of newspaper presumed responsible",
        "Procuring feigned marriage",
        "Procuring miscarriage",
        "Proof of age",
        "Proof of ownership and value of property",
        "Proceeding on Sunday, etc., not invalid",
        "Prosecutors right of reply where more than one accused",
        "Procedure on default of appearance",
        "Proof",
        "Prosecutor may prefer indictment",
        "Provisions apply",
        "Proof of conviction, order or acquittal",
        "Proceedings on re-election to be tried by judge without jury Nunavut",
        "Proceedings on re-election to be tried by provincial court judge without jury",
        "Proceedings following re-election Nunavut",
        "Proceedings following re-election Nunavut",
        "Proceedings following re-election",
        "Proceedings on re-election Nunavut",
        "Proceedings on re-election",
        "Provincial court judge may decide to hold preliminary inquiry",
        "Procedure",
        "Procedure if accused elects trial by judge Nunavut",
        "Procedure where accused elects trial by provincial court judge",
        "Procuring attendance",
        "Provincial court judges order",
        "Provisions applicable to proceedings",
        "Provisions applicable to proceedings under this section",
        "Provisions applicable to proceedings under subsection (2)",
        "Provisions re hearing",
        "Production of record to accused",
        "Procedure when witnesses attend",
        "Promise to appear or recognizance deemed to have been confirmed",
        "Procedure when witnesses attend",
        "Process compulsory",
        "Prohibited conduct",
        "Proceedings on making of prohibition order",
        "Proceedings under section 255",
        "Procuring",
        "Proof of Certain Facts by Certificate",
        "Proceedings are a Nullity",
        "Prohibition Order",
        "Proof of Former Trial",
        "Prosecution",
        "Prosecution",
        "Prosecution",
        "Procedure",
        "Procedure",
        "Providing, making available, etc., property or services for terrorist purposes",
        "Providing or collecting property for certain activities",
        "Protection of persons in authority - surgical operations",
        "Protection of persons in authority - correction of child by force",
        "Protection, defences and immunities unaffected",
        "Protections of persons acting under authority",
        "Proceedings by Attorney General of Canada",
        "Proof of service in accordance with provincial laws",
        "Proof of notifications and service of documents",
        "Publication prohibited",
        "Publication",
        "Public office vacated for conviction",
        "Public officers acting in the course of their duties or employment",
        "Publication of application prohibited",
        "Public servant refusing to deliver property",
        "Public incitement of hatred",
        "Publication in good faith for redress of wrong",
        "Publication invited or necessary",
        "Public benefit",
        "Publishing proceedings of courts of justice",
        "Publishing",
        "Publication prohibited",
        "Publication prohibited",
        "Publishing of prior lawful disclosure",
        "Public Mischief",
        "Public Officers",
        "Public Safety",
        "Publication",
        "Public officer to file report",
        "Punishment",
        "Punishment for subsequent offence not affected",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment for forgery",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment for theft of cattle",
        "Punishment for theft",
        "Punishment",
        "Punishment for defamatory libel",
        "Punishment of libel known to be false",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment for infanticide",
        "Punishment for murder",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment - Firearm",
        "Punishment - Other Cases",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment- Firearm",
        "Punishment- Other Cases",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment- Firearm",
        "Punishment- Other Cases",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment for Terrorist Activity",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment",
        "Punishment for unlawful assembly",
        "Punishment of rioter",
        "Punishment of seditious offences",
        "Punishment for high treason",
        "Punishment for treason",
        "Punishment",
        "Purpose of rules",
        "Purpose",
        "Purchase of valuable minerals",
        "Purpose of taking",
        "Purposed of Amnesty Period",
        "Qualification of jurors",
        "Question of Fact",
        "Question of fact",
        "Question of law",
        "Questions reserved for decision in a trial with a jury",
        "Questions reserved for decision",
        "Question of law",
        "Questions of Fact",
        "Question of Law and Question of Fact",
        "Question of Law, Motives",
        "Questions of law",
        "Quorum of Review Board",
        "Reasonable excuse",
        "Reasons for decision",
        "Reasons for decision",
        "Reasons for decision",
        "Reasons",
        "Reasons for decision",
        "Reasons for decision",
        "Reasons for decision",
        "Reasons",
        "Reasons",
        "Reasons to be stated",
        "Reasons",
        "Reasons",
        "Reasons",
        "Reasons",
        "Reasons for committal",
        "Reasons",
        "Reasons for sentence",
        "Reasons",
        "Reasons for disposition and copies to be provided",
        "Reasons for change of venue",
        "Reading and signing depositions",
        "Reasonable terms and conditions",
        "Reasons for decision",
        "Reasons",
        "Reasons",
        "Reasons",
        "Reasons",
        "Reasons",
        "Reason",
        "Reasons",
        "Reasons",
        "Reading proclamation",
        "Recognizance",
        "Recognizance binding",
        "Recommencement where dismissal for want of prosecution",
        "Recommendation by jury multiple murders",
        "Recommendation by jury",
        "Record of proceedings",
        "Record of proceedings",
        "Record to be kept",
        "Records of persons dealt with",
        "Recommendation by Review Board",
        "Record to be kept by court of appeal",
        "Record of proceedings",
        "Record of proceedings",
        "Recommencement of proceedings",
        "Record of conviction or order",
        "Recognizance of witness",
        "Record of reasons",
        "Record not to be used in other proceedings",
        "Record of reasons",
        "Recording",
        "Recommendation",
        "Reduction in term of long-term supervision",
        "Reduction",
        "Reduction of imprisonment on part payment",
        "Reduction of number of jurors to 12",
        "Refusal to enter into recognizance",
        "Refusal to enter into recognizance",
        "Refusal to enter into recognizance",
        "Refusal of application, and appeal",
        "Reference",
        "Refreshment and accommodation",
        "Refusal to plead",
        "Reference to section",
        "Referral when private prosecution",
        "Refusal to Enter into Recognizance",
        "Reference",
        "Registration of order",
        "Regulations",
        "Regulations to secure uniformity",
        "Regulations",
        "Regulations",
        "Registration of order",
        "Regulations",
        "Regulations",
        "Regulations",
        "Regulations",
        "Regulations",
        "Regard to evidence",
        "Regulations",
        "Regulations",
        "Regulations",
        "Regulations",
        "Regulations",
        "Regulations",
        "Release from custody by officer in charge where arrest made with warrant",
        "Release from custody by officer in charge",
        "Release from custody be peace officer",
        "Relevant information",
        "Release or detention pending new trial or new hearing",
        "Release or detention pending hearing of reference",
        "Release pending determination of appeal",
        "Release or delivery of accused subject to paragraph 672.54(b) disposition order",
        "Release of exhibits for testing",
        "Release of accused",
        "Release of accused",
        "Release of accused",
        "Release of accused",
        "Release of accused",
        "Release pending sentence",
        "Release on undertaking with conditions, etc.",
        "Release of person about to commit indictable offence",
        "Release of sample for analysis",
        "Relief from Forfeiture",
        "Reliance on Amnesty Period",
        "Removal after expiry of warrant",
        "Removal of information from database",
        "Removal of information from database",
        "Removal of information from database",
        "Removal of information from database",
        "Removal of disability",
        "Remission by Governor in Council",
        "Removing natural bar without permission",
        "Removal or absence of accused",
        "Removal of prisoner",
        "Remand by justice Nunavut",
        "Remand by justice to provincial court judge in certain cases",
        "Remand",
        "Remand in custody",
        "Remand in custody for return to jurisdiction where offence alleged to have been committed",
        "Removal of child from Canada",
        "Removal after expiry of authorization",
        "Render of accused in court by sureties",
        "Render of accused by sureties",
        "Renewal of authorization",
        "Renewal",
        "Report",
        "Report of peace officer",
        "Report of peace officer",
        "Report",
        "Report by court to Correctional Service",
        "Report of supervisor",
        "Report by probation officer",
        "Report by judge",
        "Report of medical practitioner",
        "Replacement of juror",
        "Reply",
        "Report",
        "Reputation evidence",
        "Report by Attorneys General",
        "Report to be laid before Parliament",
        "Report",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requirements relating to notice",
        "Requiring attendance of supervisor or witness",
        "Requiring attendance of analyst",
        "Request to compel attendance of witnesses",
        "Requirement of consent not affected",
        "Request for preliminary inquiry Nunavut",
        "Request for preliminary inquiry",
        "Requirement to Surrender",
        "Requirements for certain acts",
        "Restraint order",
        "Responsibility of sureties",
        "Restitution of property or report by peace officer",
        "Restitution of property or report by peace officer",
        "Respect of privacy",
        "Restriction on publication",
        "Restitution to persons acting in good faith",
        "Residual disposal of property seized or dealt with pursuant to special warrants or restraint orders",
        "Restraint order",
        "Restitution to victims of offences",
        "Restriction on use",
        "Restitution or forfeiture of property",
        "Residency as a condition of disposition",
        "Restriction on publication",
        "Restriction of publication of reports of preliminary inquiry",
        "Rescue or Permitting Escape",
        "Restrictions",
        "Restriction",
        "Restoration of Authorizations",
        "Respondents",
        "Return of property",
        "Return to lawful owner",
        "Return of copies",
        "Return of proceeds",
        "Return of property",
        "Return of evidence",
        "Return of material",
        "Retention of accused",
        "Retention of record by court",
        "Return",
        "Return of Material",
        "Return to Justice",
        "Return of Seized thing on Production of Authorization",
        "Return to Owner",
        "Return of information",
        "Review for parole",
        "Review by Attorney General",
        "Review of applications",
        "Review of detention",
        "Revocation of suspension order",
        "Review by court of appeal",
        "Review Board of receiving province has jurisdiction over transferee",
        "Review cancels appeal",
        "Review Board to provide notice",
        "Review in case of increase on restrictions on liberty",
        "Review of placement decisions",
        "Review Board shall send accused to court",
        "Review Board to determine fitness",
        "Review Board to make disposition where court does not",
        "Review Boards to be established",
        "Revocation",
        "Review Board may order assessment",
        "Review",
        "Review of order",
        "Review of order",
        "Review of record by judge",
        "Revocation of Order Under s. 117.011",
        "Revocation of Prohibition Order Under s. 111(5)",
        "Review",
        "Review of list",
        "Right of Attorney General of Canada to appeal",
        "Rights of Attorney General of Canada",
        "Right to make full answer and defence",
        "Rights of surety preserved",
        "Right of Attorney General of Canada to appeal",
        "Right of appellant to attend",
        "Right of appellant to attend",
        "Right of Attorney General to appeal",
        "Right of appeal of person convicted",
        "Rights of parties at hearing",
        "Right of accused to be present",
        "Right to counsel",
        "Right of accused",
        "Right to re-elect at preliminary inquiry Nunavut",
        "Right to re-elect before trial Nunavut",
        "Right to re-elect with consent Nunavut",
        "Right to re-elect",
        "Right to re-elect",
        "Right of the accused",
        "Right of Appeal Attorney General",
        "Right of Appeal Third Party",
        "Right to Counsel",
        "Robbery",
        "Robbery",
        "Robbery to Steal Firearm",
        "Role",
        "Royal prerogative",
        "Rules for appeals",
        "Rules",
        "Rules and practice same as in civil cases",
        "Rules of Review Board",
        "Rules",
        "Rules respecting recent complaint abrogated",
        "Rules",
        "Sabotage",
        "Sale of valuable minerals",
        "Sale by servant",
        "Same procedure for determination",
        "Same jury may try another issue by consent",
        "Same judge",
        "Samples of bodily substances",
        "Samples of breath or blood",
        "Saskatchewan",
        "Saving",
        "Saving",
        "Saving",
        "Saving provision",
        "Saving",
        "Saving",
        "Saving provision",
        "Saving",
        "Saving",
        "Saving",
        "Saving",
        "Saving",
        "Saving",
        "Saving powers of court",
        "Saving",
        "Saving",
        "Saving",
        "Saving",
        "Saving",
        "Saving",
        "Saving provision",
        "Saving",
        "Scope of application",
        "Seal not required",
        "Search or seizure to be reasonable",
        "Search for valuable minerals",
        "Seal",
        "Search for timber unlawfully detained",
        "Search without warrant, seizure and arrest",
        "Search and Seizure Without Warrant",
        "Search and Seizure Without Warrant Where Offence Committed",
        "Sebsequence of convictions only",
        "Section not restrictive",
        "Secondary evidence of peace officer",
        "Sections 489.1 and 490 applicable",
        "Sections applicable",
        "Sections to apply",
        "Section 736 does not apply",
        "Secret commissions",
        "Section does not apply",
        "Sections of Criminal Code",
        "Secrecy",
        "Sections directory",
        "Section 579 to apply",
        "Section 715",
        "Second degree murder",
        "Seizure of explosives",
        "Seizure without warrant",
        "Seizure of things not specified",
        "Seizure",
        "Seizure on Failure to Produce Authorization",
        "Seizure and restraint of assets",
        "Seizing control of ship or fixed platform",
        "Self-incrimination",
        "Selling defective stores to Her Majesty",
        "Selling, etc., automobile master key",
        "Selling book containing defamatory libel",
        "Selling newspapers",
        "Selling or Purchasing Office",
        "Self-induced intoxication - Application",
        "Self-induced intoxication - Criminal fault by reason of intoxication",
        "Self-induced intoxication - when defence not available",
        "Sentence served concurrently with supervision",
        "Sentence for long-term offender",
        "Sentence of indeterminate detention",
        "Sentence of indeterminate detention",
        "Sentence for dangerous offender",
        "Sentence of life imprisonment",
        "Sentences deemed to constitute one sentence section 743.1",
        "Sentence served according to regulations",
        "Sentence to penitentiary of person serving sentence elsewhere",
        "Sentences to be served consecutively",
        "Sentence justified by any count",
        "Sentencing proceedings",
        "Sentencing aggravating circumstances",
        "Sentence in absence of appellant",
        "Sentences to be Served Consecutively",
        "Sentences to be Served Consecutively",
        "Sentences to be served consecutively",
        "Separation of jurors",
        "Sequence of convictions only",
        "Sequence of convictions only",
        "Sequence of convictions only",
        "Sequence of convictions only",
        "Sequence of convictions only",
        "Sequence of convictions only",
        "Sequence of convictions only",
        "Service of order",
        "Service on individual",
        "Service of order",
        "Service, duration and contents of notice",
        "Service of order",
        "Service of process on an organization",
        "Service in accordance with provincial laws",
        "Service",
        "Service where respondent cannot be found",
        "Service on other persons",
        "Service of application and subpoena",
        "Service on individual",
        "Service of Term for Escape",
        "Severance of accused and counts",
        "Sexual offences",
        "Sexual assault with a weapon, threats to a third party or causing bodily harm",
        "Sexual Assault",
        "Sexual Exploitation of Person with Disability",
        "Sexual Exploitation",
        "Sexual Interference",
        "Short Title",
        "Signatures",
        "Signing of recognizance by new sureties",
        "Signature",
        "Signature of accused",
        "Single judge acting",
        "Slugs and Tokens",
        "Space station crew members of partner state",
        "Space station Canadian crew members",
        "Specific offences",
        "Special jurisdictions",
        "Specific forfeiture provisions unaffected by this Part",
        "Special rules for hearings",
        "Special search warrant",
        "Specifying grounds of dissent",
        "Special pleas",
        "Specifying sense",
        "Special provisions applicable",
        "Spouse may be charged",
        "Spreading false news",
        "Statements made in certificate",
        "Stay",
        "Status quo pending Review Board hearing",
        "Statements to be made",
        "Stand by",
        "Statement sufficient",
        "Status when exercising power",
        "Statement of accused",
        "Statement of issues and witnesses",
        "Stay of order pending appeal",
        "Stenographer to be sworn",
        "Stopping mail with intent",
        "Stopping of betting",
        "Substitution of surety",
        "Substantial risk",
        "Substantive defect",
        "Subsections 482(4) and (5) to apply",
        "Subsequent applications",
        "Subsequent applications",
        "Subsequent applications",
        "Subsequent applications",
        "Subsequent term less than two years",
        "Submission of evidence",
        "Submissions on facts",
        "Subsequent disclosure",
        "Subpoena effective throughout province",
        "Subpoena effective throughout Canada",
        "Subpoena",
        "Subpoena issued first",
        "Substituting verdict",
        "Subsequent proceedings",
        "Subsequent convictions only",
        "Subsequent Offences",
        "Subsequent offences",
        "Subsequent offences",
        "Subsequent offences",
        "Substitution of alternate jurors",
        "Subsequent procedure",
        "Substance of offence",
        "Subsequent offences",
        "Subsections 507(2) to (8) to apply",
        "Subsection (2) not limiting",
        "Subsequent offences",
        "Subsequent offences",
        "Subsequent offences",
        "Subsequent offences",
        "Subsequent offences",
        "Subsection (3) not Limiting",
        "Subsequence of Convictions Only",
        "Subsequent Extensions",
        "Subsequent Offences",
        "Sufficiency of statement",
        "Sufficiency of count relating to fraud",
        "Sufficiency of count charging perjury, etc.",
        "Sufficiency of count charging libel",
        "Sufficiency of record",
        "Summons",
        "Summons or warrant",
        "Summons or warrant",
        "Summons effective throughout Canada",
        "Summary determination of appeals filed in error",
        "Summary determination of frivolous appeals",
        "Summary conviction appeals",
        "Summary conviction appeals",
        "Summons to occupier",
        "Summing up by accused",
        "Summing up by prosecutor",
        "Summoning other jurors when panel exhausted",
        "Summons or warrant",
        "Summons",
        "Summons or warrant",
        "Summons to be issued except in certain cases",
        "Summons to Occupier",
        "Sunset Provision",
        "Support person- witnesses under 18 or who have a disability",
        "Supervisor may propose changes to optional conditions",
        "Superior court of criminal jurisdiction",
        "Supplying noxious things",
        "Supplemental peremptory challenges",
        "Support for juror with physical disability",
        "Supervision of pari-mutuel system",
        "Surrender, etc.",
        "Surrender, etc.",
        "Surrender, etc.",
        "Surrender, etc.",
        "Sureties or deposit for appearance of witness",
        "Surrender, etc.",
        "Surrender, etc.",
        "Suspension of order pending appeal",
        "Suspension of forfeiture pending appeal",
        "Taking possession, etc., of drift timber",
        "Taking motor vehicle or vessel or found therein without consent",
        "Taking ore for scientific purpose",
        "Taking evidence",
        "Taking evidence",
        "Taking evidence",
        "Taking before justice",
        "Tampering with vehicle identification number",
        "Tampering with Serial Number",
        "Taxing legal fees",
        "Taxation of fees and disbursements",
        "Taxation of fees and disbursements",
        "Taxation of fees and disbursements",
        "Taxation of fees and disbursements",
        "Technological appearance",
        "Telewarrant",
        "Telewarrants",
        "Telewarrant provisions to apply",
        "Telegram, etc., in false name",
        "Telewarrant",
        "Telephones exempt from seizure",
        "Termination order",
        "Termination order",
        "Termination order",
        "Termination order",
        "Territories",
        "Terms and conditions",
        "Terms and conditions",
        "Terms and conditions",
        "Terms of remission",
        "Territories",
        "Terms of examination order",
        "Terms of order imposing fine",
        "Terms and conditions of licence",
        "Terms",
        "Terms and conditions of licence",
        "Terms and conditions of licence",
        "Terms and Conditions",
        "Terms and conditions",
        "Terrorist Activity",
        "Terrorism offence committed outside Canada",
        "Testimony outside court room witnesses under 18 or who have a disability",
        "Testimony as to date of birth",
        "Testimony of a parent",
        "Testing of blood for concentration of a drug",
        "Testing for presence of alcohol",
        "Testing for presence of alcohol or a drug",
        "Theft from mail",
        "Theft, forgery, etc., of credit card",
        "Theft by person holding power of attorney",
        "Theft by person required to account",
        "Theft by or from person having special property or interest",
        "Theft of telecommunication service",
        "Theft by bailee of things under seizure",
        "Theft",
        "Theft and possession",
        "Third Party Rights",
        "Third Party Interests",
        "Third party involvement",
        "Threats",
        "Threats and retaliation against employees",
        "Threat against United Nations or associated personnel",
        "Threat against internationally protected person",
        "Threats causing death or injury",
        "Tied Sale",
        "Time limit for warrant",
        "Time for application one offence",
        "Time for application more than one offence",
        "Time for application one offence",
        "Time of filing",
        "Time for application",
        "Timing of order",
        "Time for making application",
        "Time spent in custody",
        "Time for payment",
        "Time at large excluded from term of imprisonment",
        "Time for making placement decision",
        "Time when theft completed",
        "Time and place for re-election Nunavut",
        "Time and place for re-election",
        "Timing of application or appointment",
        "Time for application to judge",
        "Time within which information to be laid in certain cases",
        "Time limitation in relation to criminal organizations and terrorism offences",
        "Tipping",
        "Torture",
        "Towing of person after dark",
        "Transfer of data",
        "Transfer of deposit",
        "Transmission of deposit",
        "Transmission to clerk of court",
        "Transfer of jurisdiction when youth sentence imposed under Youth Criminal Justice Act",
        "Transfer of jurisdiction when person already sentenced under Youth Criminal Justice Act",
        "Trade union, exception",
        "Transfer of order",
        "Transfer of order",
        "Transfer to penitentiary",
        "Trafficking in identity information",
        "Trader failing to keep accounts",
        "Trafficking in property obtained by crime",
        "Transcript of evidence",
        "Transfer if accused not in custody",
        "Transfer where accused in custody",
        "Transmission of records to court of appeal",
        "Transmittal of transcript to Review Board",
        "Transmittal of transcript to Review Board",
        "Transitional",
        "Transitional",
        "Trafficking of a person under the age of eighteen years",
        "Transmission of record",
        "Transmission of record",
        "Transmission of court record",
        "Transmitting record",
        "Transmission of transcript and documents and effect of order or warrant",
        "Transcription of record taken by sound recording apparatus",
        "Trafficking in persons",
        "Transfer of prisoner",
        "Translation of documents",
        "Traps likely to cause bodily harm",
        "Transporting person to bawdy-house",
        "Transfer Without Authority",
        "Transitional Provision Section 83.3",
        "Transitional Provision Sections 83.28 and 83.29",
        "Treatment disposition",
        "Treatment not a condition",
        "Treated as provincial Board",
        "Trespassing at night",
        "Treason",
        "Treason - Canadian Citizen",
        "Treason - overt act",
        "Trial de novo",
        "Trial by jury compulsory",
        "Trial without jury",
        "Trial of issue by court",
        "Trial of issue by judge and jury",
        "Trial continuous",
        "Trial may continue",
        "Trial of organization",
        "Trial of persons jointly",
        "Trial by judge without a jury",
        "Trial by provincial court judge with consent",
        "Trial continuous",
        "Trial and Punishment",
        "Trying of issues of indictment by jury",
        "Trying ground of challenge",
        "Two or more justices",
        "Two-thirds decision",
        "Unauthorized recording for purpose of sale, etc.",
        "Unauthorized recording of a movie",
        "Unauthorized use of computer",
        "Unauthorized use of credit card data",
        "Unauthorized use or disclosure of results",
        "Unauthorized use of bodily substance",
        "Unauthorized Importing or Exporting",
        "Unauthorized Possession in Motor Vehicle",
        "Unauthorized Possession of Prohibited Weapon or Restricted Weapon",
        "Unauthorized Possession of Firearm",
        "Undertaking or recognizance",
        "Undertaking or recognizance of prosecutor",
        "Undertaking or recognizance of appellant",
        "Undertakings by Attorney General",
        "Undertakings by Attorney General",
        "Undertaking or recognizance to be taken into account",
        "Undertaking or recognizance",
        "Undertaking",
        "Unlawful use of military uniforms or certificates",
        "Unlawful transactions in public stores",
        "Unlawfully causing bodily harm",
        "Unreasonable delay in execution",
        "Unseaworthy vessel and unsafe aircraft",
        "Use or alteration of nuclear material, radioactive material or device",
        "Use of results- warrant",
        "Use of bodily substance- order, authorization",
        "Use of bodily substances- warrant",
        "Used goods sold without disclosure",
        "Use, trafficking or possession of forged document",
        "Use of material by prosecution",
        "Use in evidence of statement by accused",
        "Use or retention",
        "Use of information",
        "Use of proceeds",
        "Use of force to suppress riot",
        "Use of force on board an aircraft",
        "Use of force to prevent commission of offence",
        "Use of force in carrying out sentence",
        "Using mails to defraud",
        "Using Imitation Firearm in Commission of Offence",
        "Using Firearm in Commission of Offence",
        "Using or possessing property for terrorist purposes",
        "Using explosives",
        "Uttering coin",
        "Uttering, etc., counterfeit money",
        "Uttering threats",
        "Vagrancy",
        "Validity not affected",
        "Validity of plea",
        "Validity presumed",
        "Validity of order not affected",
        "Value of postage stamp",
        "Value of valuable security",
        "Variance of conditions",
        "Variance of conditions",
        "Variation of order",
        "Variance not material",
        "Variation of order",
        "Variation of undertaking or recognizance",
        "Variance of Conditions",
        "Variation of Order",
        "Verification",
        "Verdict not a previous conviction",
        "Verdict of not criminally responsible on account of mental disorder",
        "Verdict of unfit to stand trial",
        "Verdicts in cases of defamatory libel",
        "Victim evidence",
        "Victim of criminal harassment",
        "Victim impact statement",
        "Victim surcharge",
        "Victim impact statement",
        "Victim impact statement",
        "Video links",
        "Video surveillance",
        "Video links, etc. witness outside Canada",
        "Video links, etc. witness in Canada",
        "Video links",
        "Video links, etc.",
        "Video links",
        "Video links",
        "Video links",
        "Video Links, etc.",
        "Video recording",
        "Video recording",
        "View",
        "Virtual presence of parties",
        "Virtual presence of witnesses",
        "Voidable transfers",
        "Voidable transfers",
        "Voyeurism",
        "Waiver of notice",
        "Waiver of rights of young person",
        "Want of proof of order in council",
        "Warrant of committal",
        "Warrant to committal",
        "Warrant or arrest suspension of running of conditional sentence order",
        "Warrant of committal",
        "Warrant effective throughout Canada",
        "Warrant where witness bound by recognizance",
        "Warrant when witness does not attend",
        "Warrant for absconding witness",
        "Warrant effective in a province",
        "Warrant effective throughout Canada",
        "Warrant in Form 17",
        "Warrant of committal",
        "Warrant of committal",
        "Warrant of seizure",
        "Warrant of seizure",
        "Warrant of committal",
        "Warrant to enter dwelling-house",
        "Warrant of judge for arrest",
        "Warrant for committal",
        "Warrant for detention",
        "Warrant in default of appearance",
        "Warrants to obtain blood samples",
        "Warrant to Search",
        "Warrant of Seizure",
        "Warrant of Seizure",
        "Weapons Trafficking",
        "What determines identity",
        "What may be ordered",
        "What counts may be included and who may prefer indictment Nunavut",
        "What counts may be included and who may prefer indictment",
        "What is provocation",
        "Where subsection (1) does not apply",
        "Where subsection (1) does not apply",
        "When certain orders not to be made",
        "When management order ceases to have effect",
        "When obligation begins",
        "When obligation ends",
        "When appeal court is Court of Appeal of Nunavut",
        "Where fear of serious personal injury offence",
        "When both parties appear",
        "Where fear of sexual offence",
        "Where injury or damage feared",
        "Where no fine imposed",
        "Where writ granted",
        "Where conviction or order not reviewable",
        "When proof unnecessary",
        "Where accused may be tried and punished",
        "Where continued detention no longer required",
        "When accused ordered to stand trial",
        "Where warrant not necessary",
        "When collection to take place",
        "When collection to take place",
        "When collection to take place",
        "When accused not present",
        "Where accused ordered to stand trial",
        "When disability ceases",
        "Where determination to reduce number of years",
        "Where court unable to act",
        "Where no hearing requested or ordered",
        "Where previously tried outside Canada",
        "Where application made",
        "Where proceedings instituted",
        "When management order ceases to have effect",
        "When counterfeit complete",
        "Where accused may be tried and punished",
        "Where court unable to act",
        "Where person convicted of offence",
        "Where person bound by probation order convicted of offence",
        "When fine imposed",
        "When time begins to run",
        "Where hearing ex parte",
        "When forgery complete",
        "Wherever forged",
        "When alternative measures may be used",
        "Where appeal allowed against verdict of unfit to stand trial",
        "Where application for leave to appeal refused by judge",
        "Where justice to release accused",
        "Where court imposes a sentence",
        "Where prima facie case not made",
        "Where continued detention in custody",
        "When assessment completed",
        "Where court may order assessment",
        "When order takes effect",
        "When truth a defence",
        "Where adjudication is made",
        "Where trial continued",
        "Where no belief in validity of custody order",
        "When court orders presence of accused",
        "Where there are joint trials",
        "Where there are joint trials",
        "Where there are multiple counts",
        "Where more than one sense alleged",
        "When Attorney General does not stay proceedings",
        "When direct indictment preferred",
        "Where subject-matter is a testamentary instrument or exceeds $5,000 in value",
        "Where accused ordered to stand trial",
        "When no request for preliminary inquiry",
        "Where justice to order that accused be released",
        "When direct indictment is preferred charging same offence",
        "Where new information charging same offence",
        "Where consent required",
        "Where belief in consent not a defence",
        "Where no consent obtained",
        "When child becomes human being",
        "When declaration or direction may be made",
        "Where extension to be granted",
        "Where extension to be granted",
        "Where authorization not to be given",
        "Where extension not granted",
        "Where extension to be granted",
        "When Order Takes Effect",
        "When Belief in Consent not a Defence",
        "When no Consent Obtained",
        "Where no Finding or Application",
        "Where Hearing May Proceed Ex Parte",
        "When Order can be Made",
        "Where Hearing May Proceed Ex Parte",
        "When Person to be Taken Before Judge",
        "When management order ceases to have effect",
        "When not protected",
        "When protected",
        "Where one party cannot be convicted",
        "Where previously tried outside Canada",
        "Who may dispose of forfeited property",
        "Who collects samples",
        "Who may issue",
        "Who shall attend",
        "Who shall be the jury",
        "Who May Institute Prosecutions",
        "Wilfully causing event to occur",
        "Wild living creature",
        "Wilful promotion of hatred",
        "Witness not to be a support person",
        "Withdrawal of consent",
        "Witness to appear and remain",
        "Withholding or destroying documents",
        "Witness refusing to be bound",
        "Witness refusing to be examined",
        "Witnesses for accused",
        "Witness Giving Contradictory Evidence",
        "Woman procuring her own miscarriage",
        "Written reasons",
        "Written notice interception in accordance with section 184.4",
        "Written notification to be given",
        "Written notification to be given",
        "Young offenders"
    ]
