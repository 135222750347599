import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';

@Component({
  selector: 'app-notification-sent',
  templateUrl: './notification-sent.component.html',
  styleUrls: ['./notification-sent.component.scss']
})
export class NotificationSentComponent implements OnInit {

  message: string;

  constructor(private readonly router: Router) {
    this.message = `We've e-mailed you instructions for setting your password to the e-mail address you submitted.
     You should be receiving it shortly.`;
  }

  ngOnInit() {
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }
}
