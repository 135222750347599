import { Component, OnInit, OnDestroy, HostBinding, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OverflowMenuComponent } from '../overflow-menu/overflow-menu.component';
import { FrameNavigationService } from '../shared/frame-navigation.service';
import { SubSink } from 'subsink';
import {
  ROUTE_LOADS,
  ROUTE_ROOT,
  ROUTE_PROFILE,
  ROUTE_JOBS,
  ROUTE_TRIPS,
  ROUTE_SHIPMENTS
} from 'src/app/shared/routes';
import { AppService } from 'src/app/app.service';
import { ProfileService } from 'src/app/profile/profile.service';
import { InformationDialogService } from 'src/app/shared/dialog/information-dialog.service';
import { Store } from '@ngxs/store';
import { RegistrationState } from 'src/app/registration/state/registration.state';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { MatDialogRef } from '@angular/material/dialog';
import { InformationDialogComponent } from 'src/app/shared/dialog/information-dialog/information-dialog.component';

const PROFILE_STATUS_ICON = 'profile-status';
const NEED_INFO_TITLE = 'Need Profile Info';
const FOR_REVIEW_TITLE = 'Profile Under Review';
const DISMISS_BUTTON = 'Dismiss';
const DRIVER_TYPE_SERVICE_PROVIDER = 'driverServiceProvider';
class TabNavLink {
  title: string;
  icon: string;
  link: string;
  clickHandler: () => void;
  svgIcon: boolean;
  active: boolean;
  order: number;
}

@Component({
  selector: 'app-frame',
  templateUrl: './frame.component.html',
  styleUrls: ['./frame.component.scss']
})
export class FrameComponent implements OnInit, OnDestroy {
  @HostBinding('class.showNav') showNavBar = true;
  links: TabNavLink[] = [];
  activeRoute: string;
  driver;
  dispatcherConatct;
  private subs: SubSink = new SubSink();

  constructor(
    private readonly router: Router,
    private readonly location: Location,
    private readonly bottomSheet: MatBottomSheet,
    private readonly frameNavigationService: FrameNavigationService,
    private readonly appService: AppService,
    private readonly profileService: ProfileService,
    private readonly informationDialogService: InformationDialogService,
    private readonly store: Store,
    private readonly registrationService: RegistrationService,
    @Inject('environmentData') public environment: any
  ) {}

  ngOnInit() {
    this.driver = this.registrationService.getDriver();
    this.dispatcherConatct = this.registrationService.getDispatcherContact();
    this.determineMenuTabs();
    this.determineActiveLink();
    if (this.appService.isNativeApp) {
      this.showNavBar = false;
    }
    this.subs.add(
      this.router.events.subscribe(val => {
        this.determineActiveLink();
      })
    );

    this.profileReviewDialog();
    this.needInfoDialog();
  }
  determineMenuTabs(): void {
    this.links.push(
      {
        title: 'Profile',
        icon: 'profile-icon',
        link: `/${ROUTE_PROFILE}`,
        clickHandler: () => this.navigateToProfile(),
        svgIcon: true,
        order: 1
      } as TabNavLink,
      {
        title: 'More',
        icon: 'menu-icon',
        link: '',
        clickHandler: () => this.onOpenOverflowMenu(),
        svgIcon: true,
        order: 3
      } as TabNavLink
    );
    if (this.driver?.driverType === DRIVER_TYPE_SERVICE_PROVIDER && this.driver?.companyAssociatedId && this.environment?.featureFlags?.showTrips) {
      this.links.push({
        title: 'Trips',
        icon: 'trips',
        link: `/${ROUTE_TRIPS}`,
        clickHandler: () => this.navigateToTrips(),
        svgIcon: true,
        order: 2
      } as TabNavLink);
    }

    if (this.environment?.featureFlags?.isShipmentEnable && this.dispatcherConatct.id) {
      this.links.push({
        title: 'Shipments',
        icon: 'truck-icon',
        link: `/${ROUTE_SHIPMENTS}`,
        clickHandler: () => this.navigateToShipments(),
        svgIcon: true,
        order: 2
      } as TabNavLink);
    }
    this.links.sort((a, b) => a.order - b.order);

    //For Future reference
    // this.links.push({
    //   title: 'Jobs',
    //   icon: 'jobs-icon',
    //   link: `/${ROUTE_JOBS}`,
    //   clickHandler: () => this.navigateToJobs(),
    //   svgIcon: true
    // } as TabNavLink);
    // this.links.push({
    //   title: 'Loads',
    //   icon: 'truck-icon',
    //   link: `/${ROUTE_LOADS}`,
    //   clickHandler: () => this.navigateToLoads(),
    //   svgIcon: true
    // } as TabNavLink);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onOpenOverflowMenu(): void {
    this.bottomSheet.open(OverflowMenuComponent);
  }

  private needInfoDialog(): void {
    if (this.profileService.isProfileInfoNeeded()) {
      const driver = this.store.selectSnapshot(RegistrationState.driver);
      const greeting = `Hi ${driver.firstName}!`;
      const infoMessage = 'We will need more information to clear your driver account.';
      const contactMessage = 'We will contact you asking the required information.';
      const action = this.getAction(DISMISS_BUTTON);

      const dialogRef = this.informationDialogService.openInformationDialog(
        NEED_INFO_TITLE,
        greeting,
        infoMessage,
        action,
        contactMessage,
        PROFILE_STATUS_ICON
      );
      action.action = () => this.closeDialog(dialogRef);
    }
  }

  private profileReviewDialog(): void {
    if (this.profileService.isProfileForReview()) {
      const driver = this.store.selectSnapshot(RegistrationState.driver);
      const greeting = `Hi ${driver.firstName}!`;
      const infoMessage =
        'Your profile is under review by our admins. Typically, it will take 2 business days to complete the review.';
      const contactMessage = 'We will send you an email when a decision has been made.';
      const action = this.getAction(DISMISS_BUTTON);
      const dialogRef = this.informationDialogService.openInformationDialog(
        FOR_REVIEW_TITLE,
        greeting,
        infoMessage,
        action,
        contactMessage,
        PROFILE_STATUS_ICON
      );
      action.action = () => this.closeDialog(dialogRef);
    }
  }

  private closeDialog(dialogRef: MatDialogRef<InformationDialogComponent>): any {
    dialogRef.close();
  }

  private getAction(buttonName: string): Action {
    return {
      name: buttonName,
      action: (data: any) => {}
    } as Action;
  }

  private navigateToProfile(): void {
    this.frameNavigationService.navigateToProfile();
  }

  private navigateToLoads(): void {
    this.frameNavigationService.navigateToLoads();
  }

  private navigateToTrips(): void {
    this.frameNavigationService.navigateToTrips();
  }

  private navigateToShipments(): void {
    this.frameNavigationService.navigateToShipments();
  }

  private navigateToJobs(): void {
    this.frameNavigationService.navigateToJobs();
  }

  private determineActiveLink(): void {
    this.links.forEach((link: TabNavLink) => (link.active = false));
    if (this.location.path() !== ROUTE_ROOT) {
      this.activeRoute = this.location.path();
      this.links.forEach((link: TabNavLink) => {
        if (link.link !== ROUTE_ROOT) {
          this.setActive(link);
        }
      });
    } else {
      this.activeRoute = ROUTE_ROOT;
      this.links[0].active = true;
    }
  }

  private setActive(link: TabNavLink): void {
    const checked = this.activeRoute.startsWith(link.link);
    link.active = checked;
  }
}
