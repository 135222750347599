<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <span> <mat-icon (click)="goBack()">arrow_back</mat-icon></span>

    <span fxFlex class="title">Preferences</span>
  </div>
  <div class="preference-content">
    <p class="description">Set your preferences to get loads that suit your needs.</p>
    <form [formGroup]="form">
      <mat-tab-group
        animationDuration="0ms"
        mat-align-tabs="center"
        mat-stretch-tabs="true"
        class="tab-list margin-bottom-lg"
        (selectedTabChange)="tabChanged($event)"
        [selectedIndex]="currentTab"
      >
        <!-- LOAD & EARNING -->
        <mat-tab label="LOAD & EARNING">
          <div class="margin-top-lg">
            <mat-label>Load Weight Preference</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="loadWeightPreference"
                placeholder="Load Weight Preference"
                [value]="getFieldDisplayName('loadWeightPreference', 'loadWeightPreferenceOptions')"
                readonly
                (click)="
                  onOpenStatusActionSheet(loadWeightPreferenceOptions, 'Load Weight Preference', 'loadWeightPreference')
                "
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>

          <div>
            <mat-label>Max Deadhead Miles</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="maxDeadheadMiles"
                placeholder="Max Deadhead Miles"
                [value]="getFieldDisplayName('maxDeadheadMiles', 'maxDeadheadMilesOptions')"
                readonly
                (click)="onOpenStatusActionSheet(maxDeadheadMilesOptions, 'Max Deadhead Miles', 'maxDeadheadMiles')"
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>

          <div class="margin-top-sm margin-bottom-sm">
            <mat-label>Favourite Lane</mat-label>
            <div class="sub-title margin-top-xs">Origin</div>
            <mat-form-field appearance="outline">
              <input
                matInput
                #favouriteLaneOriginText
                placeholder="Enter Favourite Lane Origin"
                formControlName="favouriteLaneOrigin"
                [value]="favouriteLaneOrigin"
                data-test="favouriteLaneOrigin-form-field"
                name="favouriteLaneOrigin"
                (input)="onInputChange('origin')"
                (focusout)="focusOut('origin')"
                (focus)="focusin()"
              />
            </mat-form-field>
            <div class="sub-title">Destination</div>
            <mat-form-field appearance="outline">
              <input
                matInput
                #favouriteLaneDestinationText
                placeholder="Enter Favourite Lane Destination"
                formControlName="favouriteLaneDestination"
                [value]="favouriteLaneDestination"
                data-test="favouriteLaneDestination-form-field"
                name="favouriteLaneDestination"
                (input)="onInputChange('destination')"
                (focusout)="focusOut('destination')"
                (focus)="focusin()"
              />
            </mat-form-field>
          </div>

          <div>
            <mat-label>Earning Type</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="earningType"
                placeholder="Earning Type"
                [value]="getFieldDisplayName('earningType', 'earningTypeOptions')"
                readonly
                (click)="onOpenStatusActionSheet(earningTypeOptions, 'Earning Type', 'earningType')"
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>
        </mat-tab>

        <!-- LOCATION & VEHICLE -->
        <mat-tab label="LOCATION & VEHICLE">
          <div class="margin-top-lg">
            <mat-label>Truck Type</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="truckType"
                placeholder="Truck Type"
                [value]="getFieldDisplayName('truckType', 'truckTypeOptions')"
                readonly
                (click)="onOpenStatusActionSheet(truckTypeOptions, 'Truck Types', 'truckType')"
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>

          <div>
            <mat-label>Trailer Type</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="trailerType"
                placeholder="Trailer Type"
                [value]="getFieldDisplayName('trailerType', 'trailerTypeOptions')"
                readonly
                (click)="onOpenStatusActionSheet(trailerTypeOptions, 'Trailer Type', 'trailerType')"
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>

          <div>
            <mat-label>Base</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                #baseLocationText
                placeholder="Enter Base Location"
                formControlName="baseLocation"
                [value]="baseLocation"
                data-test="baseLocation-form-field"
                name="baseLocation"
                (input)="onInputChange('base')"
                (focusout)="focusOut('base')"
                (focus)="focusin()"
              />
            </mat-form-field>
          </div>

          <div>
            <mat-label>Time away from the Base</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="daysAwayFromBaseLocation"
                placeholder="Time away from the Base"
                [value]="getFieldDisplayName('daysAwayFromBaseLocation', 'daysAwayFromBaseLocationOptions')"
                readonly
                (click)="
                  onOpenStatusActionSheet(
                    daysAwayFromBaseLocationOptions,
                    'Time away from the Base',
                    'daysAwayFromBaseLocation'
                  )
                "
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>

          <div>
            <mat-label>Cross - border</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="crossBorder"
                placeholder="Cross - border"
                [value]="getFieldDisplayName('crossBorder', 'crossBorderOptions')"
                readonly
                (click)="onOpenStatusActionSheet(crossBorderOptions, 'Cross Border', 'crossBorder')"
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>

          <div [hidden]="!crossBorder.value">
            <mat-label>US Zone</mat-label>
            <mat-form-field appearance="outline">
              <input
                matInput
                formControlName="usZone"
                placeholder="US Zone"
                [value]="getFieldDisplayName('usZone', 'usZoneOptions')"
                readonly
                (click)="onOpenStatusActionSheet(usZoneOptions, 'US Zone', 'usZone')"
              />
              <span matSuffix><mat-icon class="arrow-down">keyboard_arrow_down</mat-icon></span>
            </mat-form-field>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>
</div>
