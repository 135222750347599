import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DriverQualificationService } from '../../driver-qualification-files/service/driver-qualification.service';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import dayjs from 'dayjs';
import { Router } from '@angular/router';
import { ToastService } from 'src/app/shared/toast/toast.service';

const STATUS_DQF_DRIVER_SIGNATURE_SUBMITTED = 'DriverSignatureSubmitted';
const UPLOADED_DOCUMENT = 'DQF successfully sent for approval';

@Component({
  selector: 'app-dqf-signature-panel',
  templateUrl: './dqf-signature-panel.component.html',
  styleUrls: ['./dqf-signature-panel.component.scss']
})
export class DqfSignaturePanelComponent implements OnInit {
  signatureForm: FormGroup;
  driverSignatureIntent: string;
  isClicked = false;
  dqfId: string;
  constructor(
    private readonly fb: FormBuilder,
    private readonly driverQualificationService: DriverQualificationService,
    public dialogRef: MatBottomSheetRef<DqfSignaturePanelComponent>,
    public router: Router,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.getDriverSignatureIntent();
    this.signatureForm = this.createForm();

    this.dqfId = this.data?.dqfId;
  }

  onSubmit(): void {
    if (!this.dqfId) {
      return;
    }
    if (this.signatureForm.valid && this.driverSignatureIntent) {
      this.isClicked = true;
      let dqfSignatureStatus = {
        status: STATUS_DQF_DRIVER_SIGNATURE_SUBMITTED,
        driverSignature: {
          capturedInitials: this.signatureForm.value.initial,
          driverSignatureIntentId: this.driverSignatureIntent
        }
      };

      this.driverQualificationService.updateDqfStatus(this.dqfId, dqfSignatureStatus).subscribe(
        res => {
          this.toastService.showSuccess(UPLOADED_DOCUMENT);
          this.dialogRef.dismiss();
          this.router.navigate(['']);
        },
        error => {
          this.isClicked = false;
          this.toastService.showWarning(error);
        }
      );
    }
  }

  private createForm(): FormGroup {
    return this.fb.group({
      initial: ['', Validators.required],
      date: [`${dayjs(new Date()).format('DD MMM YYYY') as string}`, Validators.required]
    });
  }

  private getDriverSignatureIntent(): void {
    this.driverQualificationService.getDriverSignatureIntent().subscribe(
      response => {
        if (response && response.data) {
          this.driverSignatureIntent = response.data.id;
        }
      },
      error => {}
    );
  }
}
