<ng-container *ngIf="data?.length">
    <div class="info-content" *ngFor="let info of data">
        <div class="heading">
            <label>{{info.propertyName}}</label>
        </div>
        <div class="value">
            <p class="info">{{info.propertyValue}}</p>
            <p class="info-sub">{{info.subPropertyValue}}</p>
            <div class="line"></div>
        </div>
    </div>
</ng-container>
<div class="no-record" *ngIf="!data?.length">
    <p>No Records</p>
</div>