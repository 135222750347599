import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action } from 'src/app/loads-shared/shipment-data/models/action';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { ShipmentFeedback } from 'src/app/loads-shared/shipment-data/models/shipment-feedback.model';
import { ShipmentDataService } from 'src/app/loads-shared/shipment-data/shipment-data.service';
import { catchError } from 'rxjs/operators';

export interface DialogData {
  shipment: Shipment;
  actions: Action[];
}

const FEEDBACK_TYPE = 'shipmentFeedback';

@Component({
  selector: 'app-shipments-feedback-dialog',
  templateUrl: './shipments-feedback-dialog.component.html',
  styleUrls: ['./shipments-feedback-dialog.component.scss']
})
export class ShipmentsFeedbackDialogComponent implements OnInit {
  shipmentFeedback: ShipmentFeedback;
  feedbackStep = 1;
  saving: boolean;
  commentFormField = new FormControl('', [Validators.pattern(/^[a-zA-Z0-9\,\.\:\;\'\#\&\@\?\s]+$/)]);

  constructor(
    private dialogRef: MatDialogRef<ShipmentsFeedbackDialogComponent>,
    private readonly shipmentDataService: ShipmentDataService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {
    this.shipmentFeedback = {
      shipmentId: this.data.shipment.id,
      type: FEEDBACK_TYPE,
      appropriateFreightLane: false,
      appropriateWeight: false,
      personnelHelpful: false,
      softwarePerformance: false,
      comment: ''
    };
  }

  approveStep(): void {
    this.processStep(true);
  }

  disapproveStep(): void {
    this.processStep(false);
  }

  stepBack(): void {
    if (this.feedbackStep > 1) {
      this.feedbackStep--;
    }
  }

  submitFeedback(comment: string): void {
    this.saving = true;
    this.shipmentFeedback.comment = comment;
    this.shipmentDataService
      .sendFeedback(this.shipmentFeedback)
      .pipe(
        catchError(error => {
          this.saving = false;
          throw error;
        })
      )
      .subscribe(() => this.close());
  }

  private processStep(feedback: boolean): void {
    switch (this.feedbackStep) {
      case 1:
        this.shipmentFeedback.appropriateFreightLane = feedback;
        break;
      case 2:
        this.shipmentFeedback.appropriateWeight = feedback;
        break;
      case 3:
        this.shipmentFeedback.personnelHelpful = feedback;
        break;
      case 4:
        this.shipmentFeedback.softwarePerformance = feedback;
        break;
      default:
        break;
    }

    this.feedbackStep++;
  }

  private close(): void {
    this.dialogRef.close();
  }
}
