import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-select-action',
  templateUrl: './select-action.component.html',
  styleUrls: ['./select-action.component.scss']
})
export class SelectActionComponent implements OnInit {
  chosenValue: string;
  loading: boolean = false;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly bottomSheetRef: MatBottomSheetRef<SelectActionComponent>
  ) {
    this.chosenValue = this.data.selectedValue;
  }

  ngOnInit(): void {}

  changeChosenValue(value: string): void {
    this.chosenValue = value;
    this.bottomSheetRef.dismiss();
    this.data.onConfirm(this.chosenValue);
  }
}
