<div fxLayoutAlign="start" fxLayout="column" class="page font-family">
  <div class="header-navigation-back-profile">
    <span>Profile</span>
  </div>
  <div class="content ">
    <app-profile-loading *ngIf="!(isProfileContentReady && isRequireInfoReady)"></app-profile-loading>
    <div
      class="shipment-notification-container"
      fxLayout="column"
      *ngIf="isProfileContentReady && isRequireInfoReady && latestShipment"
    >
      <div class="description">
        <b>Shipment {{ latestShipment?.shipmentID }}</b> has been assign to you!
      </div>
      <div class="action-row">
        <button mat-button class="btn-action" (click)="navigateToShipmentDetail(latestShipment)">VIEW</button>
        <button mat-button class="btn-action" (click)="closeShipmentNotification()">CLOSE</button>
      </div>
    </div>
    <div fxLayout="column" class="profile-detail-container" *ngIf="isProfileContentReady && isRequireInfoReady">
      <div fxFlex="60" fxLayout>
        <div fxFlex="75">
          <div *ngIf="profilePicturePath">
            <img [src]="profilePicturePath" class="profile-image" />
          </div>
          <div *ngIf="!profilePicturePath">
            <img src="../../../assets/images/default-profile.png" class="profile-image" />
          </div>
        </div>
        <div fxFlex="25" class="edit-button-container">
          <button mat-stroked-button (click)="onNavigateToPersonalInformation()" data-test="edit-profile">
            Edit Profile
          </button>
        </div>
      </div>
      <div fxFlex="40" fxLayout>
        <div fxFlex="75" fxLayout="column">
          <div fxFlex="100" class="driver-name">{{ driverName }}</div>
          <div fxFlex="100" class="driver-email">{{ driverEmail }}</div>
        </div>
        <div fxFlex="25" fxLayout class="driver-id-container">
          <div fxFlex="100" class="driver-id">{{ driverId | uppercase }}</div>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <div class="profile-completion" fxLayout="row" *ngIf="isProfileContentReady && isRequireInfoReady">
      <div fxFlex="70">
        <p *ngIf="showPercentage < 100">
          Your profile is {{ showPercentage }}% complete <br /><span
            >To interact with the Jobs, fill in all the required information</span
          >
        </p>
        <p *ngIf="showPercentage === 100">
          Well done! Your profile is complete
        </p>
      </div>
      <div fxFlex="30">
        <mat-progress-spinner
          [mode]="mode"
          [value]="showPercentage"
          [ngClass]="{
            'mat-spinner-default': showPercentage === 0,
            'mat-spinner-color-initial': showPercentage > 0,
            'mat-spinner-color-complete': showPercentage > 99
          }"
        >
        </mat-progress-spinner>
        <div class="show-percentage">{{ showPercentage }}%</div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="dqfs.length && isProfileContentReady && isRequireInfoReady" class="dqf-list" data-test="dqf-panel">
      <div class="dqf-header">
        <p>Driver Qualification File</p>
        <div (click)="navigateToDriverQualification()" *ngIf="allDqfs.length > 2">
          <span>See all</span>
          <mat-icon svgIcon="greater-than-arrow"></mat-icon>
        </div>
      </div>
      <div
        class="dqf-section"
        *ngFor="let dqf of dqfs"
        [ngClass]="{'disabled': isWithdrawn(dqf.status)}"
        (click)="!isWithdrawn(dqf.status) && onDqfItemClick(dqf)"
        data-test="dqf-section"
        [attr.data-id]="dqf.carrierCompanyName"
      >
        <p class="dqf-company-name">{{ dqf.carrierCompanyName }}</p>
        <div class="dqf-tag">
          <p>{{ dqf.driverType ? dqf.driverType : driverType }}</p>
          <div>
            <div class="status-container" *ngIf="isInComplete(dqf.status)">
              <div class="btn-status status-incomplete" data-test="dqf-incomplete-button">Incomplete</div>
            </div>
            <div class="status-container" *ngIf="isPending(dqf.status)">
              <div class="btn-status status-in-progress" data-test="dqf-under-review-button">Under Review</div>
            </div>
            <div class="status-container" *ngIf="isRejected(dqf.status)">
              <div class="btn-status status-rejected">Rejected</div>
            </div>
            <div class="status-container" *ngIf="isApproved(dqf.status)">
              <div class="status-lock">
                <mat-icon svgIcon="locked-blue" class="lock-icon"></mat-icon>
              </div>
            </div>
            <div class="status-container" *ngIf="isWithdrawn(dqf.status)">
              <div class="btn-status status-rejected">Withdrawn</div>
              <div class="status-lock margin-top-sm margin-right-md">
                <mat-icon svgIcon="locked-blue" class="lock-icon"></mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header class="required-header">
          <mat-panel-title>
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row">
                <span class="panel-header"
                  >Required Information ({{ requiredCompleted }}/{{ totalRequiredProfileSectionCount }} Completed)</span
                >
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngIf="!(isProfileContentReady && isRequireInfoReady)">
          <ngx-skeleton-loader count="6" appearance="line"></ngx-skeleton-loader>
        </ng-container>
        <div *ngIf="isProfileContentReady && isRequireInfoReady">
          <ng-container *ngFor="let info of requiredInformation">
            <div class="card-list" (click)="info.clickHandler()">
              <mat-card fxFlex [ngClass]="!info.isComplete ? 'card-initial' : 'card-complete'">
                <div
                  fxFlex="95"
                  [ngClass]="{ 'padding-top-xs': info.isComplete == false }"
                  data-test="document-category"
                >
                  {{ info.displayName }}
                </div>
                <div fxFlex="5">
                  <mat-icon *ngIf="!info.isComplete" class="icon-centered" svgIcon="yellow-warning-icon"> </mat-icon>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </div>
      </mat-expansion-panel>
    </div>

    <div class="margin-top-md">
      <mat-expansion-panel>
        <mat-expansion-panel-header class="additional-header">
          <mat-panel-title>
            <div fxLayout="column" fxLayoutGap="10px">
              <div fxLayout="row">
                <span class="panel-header"
                  >Additional Information ({{ additionalRequired }}/{{ totalAdditionalInfoCount }} Completed)</span
                >
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let info of additionalInformation">
          <div class="card-list" (click)="info.clickHandler()" [attr.data-test]="'section-' + info.displayName">
            <mat-card fxFlex [ngClass]="!info.isComplete ? 'card-initial' : 'card-complete'">
              <div fxFlex="95" [ngClass]="{ 'padding-top-xs': info.isComplete == false }">{{ info.displayName }}</div>
              <div fxFlex="5">
                <mat-icon *ngIf="!info.isComplete" class="icon-centered" svgIcon="yellow-warning-icon"> </mat-icon>
              </div>
            </mat-card>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </div>
  </div>
</div>
