import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import sha256 from 'crypto-js/sha256';
import { CodeExchangeModule } from './code-exchange.module';

const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijhklmnopqrstuvwxyz_-.~';

@Injectable({
  providedIn: CodeExchangeModule
})
export class CodeExchangeService {
  constructor() {}

  public generateCodeVerifier(): string {
    const lengthOfCode = 128;
    let randomText = '';
    for (let i = 0; i < lengthOfCode; i++) {
      randomText += possibleText.charAt(Math.floor(Math.random() * possibleText.length));
    }
    return randomText;
  }

  public generateCodeChallenge(code_verifier): string {
    return this.base64URL(sha256(code_verifier));
  }

  private base64URL(verifier): string {
    return verifier
      .toString(CryptoJS.enc.Base64)
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }
}
