import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Response } from '../services/response';
import { environment } from './../../../environments/environment';
import { FleetoperateApiService } from './fleetoperate-api.service';
import { Education } from 'src/app/shared/models/education.model';
import { Employment, Unemployment } from 'src/app/shared/models/employment.model';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { DrivingExperience } from 'src/app/shared/models/driving-experience.model';
import { map } from 'rxjs/operators';
import { Driver } from 'src/app/shared/models/driver.model';
import { ProfilePictureRequest } from '../models/profile-picture-request.model';
import { SendDocumentResponse } from '../documents-data/models/send-document-response';
import { DocumentRequest } from '../models/document-request.model';
import { MainTrafficConviction, TrafficConviction } from '../models/traffic-convictions';
import { AccidentRecord } from '../models/accident-record.model';
import { License } from '../models/license.model';
import { WorkPlaceSafety } from '../models/workplacesafety.model';

const PATH_DRIVERS = '/drivers';
const PATH_DISPATCHERS = '/dispatchers';
const PATH_DRIVER_SIGNATURE_INTENT = '/DriverSignatureIntent';
const PATH_CARRIERS = '/carriers';
const PATH_GET_DRIVER = PATH_DRIVERS + '/{id}';
const PATH_EDUCATION_CREATE = PATH_DRIVERS + '/{id}/education';
const PATH_EDUCATION_UPDATE = PATH_DRIVERS + '/{id}/education/{education_id}';

const PATH_INCORPORATION_CREATE = PATH_DRIVERS + '/{id}/workplacesafety';
const PATH_INCORPORATION_UPDATE = PATH_DRIVERS + '/{id}/workplacesafety/{workplacesafety_id}';
const PATH_DOCUMENT_DELETE = PATH_DRIVERS + '/{id}/documents/{documentId}';

const PATH_PREFERENCES_CREATE = PATH_DRIVERS + '/{id}/preference';
const PATH_PREFERENCES_UPDATE = PATH_DRIVERS + '/{id}/preference/{driver_preference_id}';

const PATH_PROFILE_PICTURE = PATH_DRIVERS + '/{id}/picture';
const PATH_DRIVING_EXPERIENCE_CREATE = PATH_DRIVERS + '/{id}/experience';
const PATH_DRIVING_EXPERIENCE_UPDATE = PATH_DRIVERS + '/{id}/experience';
const PATH_DOCUMENTS = PATH_DRIVERS + '/{id}/documents';
const PATH_DOCUMENTS_DOWNLOAD = PATH_DOCUMENTS + '/{documentid}';

//accident records paths
const PATH_ACCIDENT_RECORDS = PATH_DRIVERS + '/{id}/accidents';
const PATH_ACCIDENT_RECORD_UPDATE = PATH_DRIVERS + '/{id}/accidents/{accident_id}';
const PATH_ACCIDENT_RECORD_DELETE = PATH_DRIVERS + '/{id}/accidents/{accident_id}';

//traffic convection paths
const PATH_TRAFFIC_CONVICTIONS = PATH_DRIVERS + '/{id}/convictions';
const PATH_TRAFFIC_CONVICTIONS_PATCH = PATH_TRAFFIC_CONVICTIONS + '/{convictionid}';

//employment history

const PATH_EMPLOYMENT_HISTORY = PATH_DRIVERS + '/{id}/employment';
const PATH_EMPLOYMENT_UPDATE = PATH_DRIVERS + '/{id}/employment/{employment_id}';
// Residence history
const PATH_RESIDENCE_HISTORY = PATH_DRIVERS + '/{id}/addresses';
const PATH_RESIDENCE_HISTORY_PATCH = PATH_RESIDENCE_HISTORY + '/{address_id}';
// license
const PATH_DRIVERS_LICENSE = `${PATH_DRIVERS}/{id}/driverlicense`;

//On duty hours
const PATH_DRIVER_ON_DUTY_HOURS = `${PATH_DRIVERS}/{id}/ondutyhours`;
const PATH_UPDATE_ON_DUTY_HOURS = PATH_DRIVER_ON_DUTY_HOURS + '/{dutyid}';
const PATH_LOOKUPS = `/lookups`;

//DQF paths
const PATH_GET_DQFS_BY_DRIVER_ID = PATH_DRIVERS + '/{id}/documents?type=driverqualification';
const PAHT_GET_DQF_BY_ID = PATH_DRIVERS + '/{driverId}/documents/{docId}';

const PATH_LICENSE = PATH_DRIVERS + '/{id}/verifications';
const PATH_HISTORY = PATH_DRIVERS + '/{id}/history';
const PATH_IDENTITY_VERIFICATION = '/identityverification';
const PATH_IDENTITY_VERIFICATION_UPDATE = '/identityverification/{id}';
const PATH_CARRIER_COMPANY = '/carriercompanies';
const PATH_COMPANY_DRIVERS = `${PATH_CARRIER_COMPANY}/companydrivers`;

// Dispatcher contact PATH
const PATH_DISPATCHER_CONTACT = PATH_DISPATCHERS + '/{dispatcherid}/contacts?id={driverId}';
const DISPATCHER_ID = 'Contact-Check';

export const DRIVER_SESSION = '/sso/session/';

export const DRIVER_ABSTRACT = 'Abstract';
export const MEDICAL_CERTIFICATE = 'MedicalCard';
export const INSURANCE = 'ProofInsurance';
export const DRIVE_TEST = 'RoadTest';
export const DRIVER_RESUME = 'Resume';
export const UPLOAD_STATUS = 'uploaded';
export const PROFILE_COMPLETE_STATUS = 'complete';
export const IDENTITY = 'Identity';
export const IDENTITY_INITIATED = 'initiated';
export const IDENTITY_REVIEW = 'review';
export const IDENTITY_ACCEPT = 'accept';
export const IDENTITY_REJECT = 'reject';
export const PROFILE_INCOMPLETE_STATUS = 'Incomplete';
export const PROFILE_LOCKED_STATUS = 'Lock';
export const PROFILE_FOR_REVIEW_STATUS = 'ForReview';
export const PROFILE_NEED_INFO_STATUS = 'NeedInfo';
export const GET_HELLO_SIGN_URL = `/signatures`;

@Injectable({
  providedIn: 'root'
})
export class DriverApiService {
  apiUrl: string;
  public driverId: string;

  constructor(private readonly api: FleetoperateApiService) {
    this.apiUrl = environment.apiUrl;
  }

  get driverID(): string {
    return this.driverId;
  }

  getDriver(revelFields?: string, isLoadingFalse?: boolean, version?: number, includeStats?: boolean): Observable<any> {
    let headerOptions = {
      Accept: 'application/json'
    };
    if (revelFields) {
      headerOptions['x-reveal-fields'] = revelFields;
    }

    let path = PATH_GET_DRIVER.replace('{id}', this.driverID);
    if (version) {
      path += `?version=${version}`;
    }
    if (includeStats) {
      path += `?includes=stats`;
    }
    return this.api.get(`${this.apiUrl}${path}`, headerOptions, isLoadingFalse);
  }

  getDriverId(): Observable<any> {
    return this.api.get(`${this.apiUrl}${DRIVER_SESSION}`, {});
  }

  getDispatcherContact(): Observable<any> {
    let path = PATH_DISPATCHER_CONTACT.replace('{dispatcherid}', DISPATCHER_ID).replace('{driverId}', this.driverId);
    return this.api.get(`${this.apiUrl}${path}`);
  }

  updateDriver(driver: Driver): Observable<any> {
    let path = PATH_GET_DRIVER.replace('{id}', this.driverID);
    path = path.replace('{driverid}', driver.id);
    return this.api.put(`${this.apiUrl}${path}`, driver);
  }

  createDriver(driver: Driver | any, recaptcha: string, inviteCode?: string, signUpType?: any): Observable<any> {
    const path = PATH_DRIVERS.replace('{id}', this.driverID);

    const headerOption = inviteCode
      ? { 'x-recaptcha-token': recaptcha, 'x-invite-code': inviteCode }
      : { 'x-recaptcha-token': recaptcha };

    if (signUpType) {
      headerOption[`x-${signUpType.type}`] = signUpType.id;
    }

    return this.api.post(`${this.apiUrl}${path}`, driver, headerOption);
  }

  createEducation(education: Education): Observable<any> {
    const path = PATH_EDUCATION_CREATE.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, education);
  }

  updateEducation(education: Education): Observable<any> {
    let path = PATH_EDUCATION_UPDATE.replace('{id}', this.driverID);
    path = path.replace('{education_id}', education.id);
    return this.api.put(`${this.apiUrl}${path}`, education);
  }

  createIncorporation(incorporation: WorkPlaceSafety): Observable<any> {
    const path = PATH_INCORPORATION_CREATE.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, incorporation);
  }

  updateIncorporation(incorporation: WorkPlaceSafety, incorporationId: string): Observable<any> {
    let path = PATH_INCORPORATION_UPDATE.replace('{id}', this.driverID);
    path = path.replace('{workplacesafety_id}', incorporationId);
    return this.api.patch(`${this.apiUrl}${path}`, incorporation);
  }

  createEmployment(employment: Employment | Unemployment): Observable<any> {
    const path = PATH_EMPLOYMENT_HISTORY.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, employment, undefined, true);
  }

  updateEmployment(employment: Employment | Unemployment, id: string): Observable<any> {
    let path = PATH_EMPLOYMENT_UPDATE.replace('{id}', this.driverID);
    path = path.replace('{employment_id}', id);
    return this.api.put(`${this.apiUrl}${path}`, employment, undefined, true);
  }

  deleteEmployment(id: string): Observable<any> {
    const path = PATH_EMPLOYMENT_UPDATE.replace('{id}', this.driverID).replace('{employment_id}', id);

    return this.api.delete(`${this.apiUrl}${path}`, true);
  }

  createPreferences(preferences: Preferences): Observable<any> {
    const path = PATH_PREFERENCES_CREATE.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, preferences);
  }

  getPreferences(): Observable<any> {
    const path = PATH_PREFERENCES_CREATE.replace('{id}', this.driverID);
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  updatePreferences(preferences: Preferences): Observable<any> {
    let path = PATH_PREFERENCES_UPDATE.replace('{id}', this.driverID);
    path = path.replace('{driver_preference_id}', this.driverID);
    return this.api.patch(`${this.apiUrl}${path}`, preferences);
  }

  getProfilePicture(isLoadingFalse?: boolean): Observable<any> {
    const path = PATH_PROFILE_PICTURE.replace('{id}', this.driverID);
    return this.api.get(`${this.apiUrl}${path}`, {}, isLoadingFalse);
  }

  createProfilePicture(profilePictureRequest: ProfilePictureRequest): Observable<Response> {
    const path = PATH_PROFILE_PICTURE.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, profilePictureRequest);
  }

  createDrivingExperience(drivingExperience: DrivingExperience): Observable<any> {
    const path = PATH_DRIVING_EXPERIENCE_CREATE.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, drivingExperience);
  }

  getDrivingExperience(version?: number): Observable<any> {
    let path = PATH_DRIVING_EXPERIENCE_CREATE.replace('{id}', this.driverID);
    if (version) {
      path += `?version=${version}`;
    }
    return this.api.get(`${this.apiUrl}${path}`, {}, true);
  }

  updateDrivingExperience(drivingExperience: DrivingExperience): Observable<any> {
    let path = PATH_DRIVING_EXPERIENCE_UPDATE.replace('{id}', this.driverID);
    return this.api.put(`${this.apiUrl}${path}`, drivingExperience);
  }

  sendDocument(body: DocumentRequest, isLoadingFalse: boolean = false): Observable<SendDocumentResponse> {
    const path = PATH_DOCUMENTS.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, body, {}, isLoadingFalse).pipe(
      map((response: Response) => {
        if (response && response.data) {
          return response.data as SendDocumentResponse;
        }

        return undefined;
      })
    );
  }
  downloadDocument(documentId: string): Observable<any> {
    const path = PATH_DOCUMENTS_DOWNLOAD.replace('{id}', this.driverID).replace('{documentid}', documentId);
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  deleteDocument(documentId: string): Observable<Response> {
    const path = PATH_DOCUMENT_DELETE.replace('{id}', this.driverID).replace('{documentId}', documentId);
    return this.api.delete(`${this.apiUrl}${path}`);
  }
  uploadAbstractDocument(abstract: any): Observable<any> {
    return null;
  }

  getHistory(history: string): Observable<any> {
    const path = PATH_HISTORY.replace('{id}', this.driverID);
    let queryString = `?include=${history}`;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  getBerbixClientToken(codeChallenge: string): Observable<any> {
    let queryString = `/?code_challenge=${codeChallenge}`;
    return this.api.post(`${this.apiUrl}${PATH_IDENTITY_VERIFICATION}${queryString}`, { userId: this.driverID });
  }
  updateIdentityStatus(identityStatus: string, transactionId: string, codeVerifier: string): Observable<any> {
    const path = PATH_IDENTITY_VERIFICATION_UPDATE.replace('{id}', transactionId);
    let queryString = `/?code_verifier=${codeVerifier}`;
    queryString = path + queryString;
    return this.api.patch(`${this.apiUrl}${queryString}`, { status: identityStatus });
  }

  getMilesDrivenOptions(): Observable<Response> {
    const path = PATH_DRIVERS + PATH_LOOKUPS;
    let queryString = `?include=milesDriven`;
    return this.api.get(`${this.apiUrl}${path}${queryString}`, {});
  }

  getPrefilledBerbixData(id: string): Observable<Response> {
    const path = PATH_GET_DRIVER.replace('{id}', id);
    return this.api.patch(`${this.apiUrl}${path}`, { documentIdentityConsent: true });
  }

  getCarrierCompanyDetailsFromInviteCode(queryString: string, headerOptions: Object): Observable<Response> {
    const path = `${this.apiUrl}${PATH_COMPANY_DRIVERS}`;
    return this.api.get(`${path}${queryString}`, headerOptions);
  }

  // DQF functions

  getDqfsByDriverId() {
    const path = PATH_GET_DQFS_BY_DRIVER_ID.replace('{id}', this.driverID);
    return this.api.get(`${this.apiUrl}${path}`, {}, true);
  }
  getDqfById(docId: string) {
    let path = PAHT_GET_DQF_BY_ID.replace('{driverId}', this.driverID);
    path = path.replace('{docId}', docId);
    return this.api.get(`${this.apiUrl}${path}`, {});
  }
  updateDqfStatus(docId: string, dqfStatus: any) {
    let path = PAHT_GET_DQF_BY_ID.replace('{driverId}', this.driverID);
    path = path.replace('{docId}', docId);
    return this.api.patch(`${this.apiUrl}${path}`, dqfStatus);
  }
  getAllResidenceAddress() {}

  createTrafficConvictions(trafficConvictions: TrafficConviction | MainTrafficConviction): Observable<any> {
    const path = PATH_TRAFFIC_CONVICTIONS.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, trafficConvictions, undefined, true);
  }

  getTrafficConvictions(version?: number): Observable<any> {
    let path = PATH_TRAFFIC_CONVICTIONS.replace('{id}', this.driverID);
    if (version) {
      path += `?version=${version}`;
    }
    return this.api.get(`${this.apiUrl}${path}`, {}, true);
  }

  updateTrafficConvictions(
    trafficConviction: TrafficConviction | MainTrafficConviction,
    trafficConvictionId: string
  ): Observable<any> {
    const path = PATH_TRAFFIC_CONVICTIONS_PATCH.replace('{id}', this.driverID).replace(
      '{convictionid}',
      trafficConvictionId
    );

    return this.api.patch(`${this.apiUrl}${path}`, trafficConviction, undefined, true);
  }

  deleteTrafficConvictions(trafficConvictionId: string): Observable<any> {
    const path = PATH_TRAFFIC_CONVICTIONS_PATCH.replace('{id}', this.driverID).replace(
      '{convictionid}',
      trafficConvictionId
    );

    return this.api.delete(`${this.apiUrl}${path}`, true);
  }

  createAccidentRecord(accidentRecord: AccidentRecord): Observable<any> {
    const path = PATH_ACCIDENT_RECORDS.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, accidentRecord, undefined, true);
  }

  updateAccidentRecord(accidentRecord: AccidentRecord, accidentId: string): Observable<any> {
    const path = PATH_ACCIDENT_RECORD_UPDATE.replace('{id}', this.driverID).replace('{accident_id}', accidentId);
    return this.api.patch(`${this.apiUrl}${path}`, accidentRecord, undefined, true);
  }

  deleteAccidentRecord(id: string): Observable<any> {
    const path = PATH_ACCIDENT_RECORD_DELETE.replace('{id}', this.driverID).replace('{accident_id}', id);
    return this.api.delete(`${this.apiUrl}${path}`, true);
  }

  getAccidentRecords(version?: number): Observable<any> {
    let path = `${this.apiUrl}${PATH_ACCIDENT_RECORDS.replace('{id}', this.driverID)}`;
    if (version) {
      path += `?version=${version}`;
    }
    return this.api.get(`${path}`, {}, true);
  }
  // employment history api

  getEmploymentHistory(version?: number): Observable<any> {
    let path = PATH_EMPLOYMENT_HISTORY.replace('{id}', this.driverID);
    if (version) {
      path += `?version=${version}`;
    }
    return this.api.get(`${this.apiUrl}${path}`, {}, true);
  }

  //Residence history

  getAddresses(version?: number): Observable<any> {
    let path = PATH_RESIDENCE_HISTORY.replace('{id}', this.driverID);
    if (version) {
      path += `?version=${version}`;
    }
    return this.api.get(`${this.apiUrl}${path}`, {}, true);
  }
  addAddress(model): Observable<any> {
    const path = PATH_RESIDENCE_HISTORY.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, model, undefined, true);
  }
  updateAddress(model, id): Observable<any> {
    const path = PATH_RESIDENCE_HISTORY_PATCH.replace('{id}', this.driverID).replace('{address_id}', id);
    return this.api.patch(`${this.apiUrl}${path}`, model, undefined, true);
  }
  // License
  getLicense(isLoadingFalse: boolean = false, version?: number, licenseImageType?: string): Observable<any> {
    let path = PATH_DRIVERS_LICENSE.replace('{id}', this.driverID);
    if (version) {
      path += `?version=${version}`;
    }
    if (licenseImageType) {
      path += `?include=${licenseImageType}`;
    }
    return this.api.get(`${this.apiUrl}${path}`, {}, isLoadingFalse);
  }

  updateLicense(model: License, driverLicenseId: string): Observable<any> {
    const path = PATH_DRIVERS_LICENSE.replace('{id}', this.driverID) + `/${driverLicenseId}`;
    return this.api.patch(`${this.apiUrl}${path}`, model);
  }
  uploadLicense(model: any): Observable<any> {
    const path = PATH_DRIVERS_LICENSE.replace('{id}', this.driverID);
    return this.api.post(`${this.apiUrl}${path}`, model);
  }

  //On Duty Hours
  getOnDutyHours(dqfId: string): Observable<any> {
    let path = PATH_DRIVER_ON_DUTY_HOURS.replace('{id}', this.driverID);
    if (dqfId) {
      path += `?dqfId=${dqfId}`;
    }
    return this.api.get(`${this.apiUrl}${path}`, {});
  }
  updateOnDutyHours(model, id): Observable<any> {
    const path = PATH_UPDATE_ON_DUTY_HOURS.replace('{id}', this.driverID).replace('{dutyid}', id);

    return this.api.patch(`${this.apiUrl}${path}`, model);
  }

  getDriverSignatureIntent(): Observable<any> {
    const path = `${PATH_DRIVERS}${PATH_DRIVER_SIGNATURE_INTENT}`;
    return this.api.get(`${this.apiUrl}${path}`);
  }
  getHelloSignUrl(doc: any): Observable<any> {
    const path = `${GET_HELLO_SIGN_URL}`;
    return this.api.post(`${this.apiUrl}${path}`, doc);
  }
  updateDriverStage(driver): Observable<any> {
    const path = PATH_GET_DRIVER.replace('{id}', driver.id);
    return this.api.put(`${this.apiUrl}${path}`, driver);
  }
}
