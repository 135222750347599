<div fxFlex="none" fxLayout="column" fxLayoutAlign="center stretch" class="margin-top-lg padding-top-x4">
  <div fxLayout fxFlexAlign="center" class="forgot-icon">
    <img src="assets/img/forgot-password-icon.png" />
  </div>
  <div>
    <h2 class="title padding-top-x2">New <span>Password</span></h2>
  </div>
  <div fxFlexAlign="center" class="messageText margin-bottom-sm">
    <p>Enter your new password to keep it safe</p>
  </div>
  <mat-error fxFlexAlign="center" *ngIf="errorMessage" class="error">
    {{ errorMessage }}
  </mat-error>
  <mat-label *ngIf="successMessage" fxFlexAlign="center">{{ successMessage }}</mat-label>
  <form [formGroup]="form" #forgotPasswordForm="ngForm" (ngSubmit)="onSubmit(forgotPasswordForm)" fxLayout="column" class="margin-top-sm">
    <label class="label">New Password</label>
    <mat-form-field appearance="outline">
      <mat-hint align="start">at least 8 characters</mat-hint>
      <input
        matInput
        placeholder="New Password"
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
      />
      <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{
        hidePassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
      <mat-error *ngIf="password.touched && password.invalid">
        {{ getPasswordErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <label class="label margin-top-sm">Confirm Password</label>
    <mat-form-field appearance="outline">
      <mat-hint align="start">at least 8 characters</mat-hint>
      <input
        matInput
        placeholder="Re-enter New Password"
        [type]="hideConfirmPassword ? 'password' : 'text'"
        formControlName="confirmPassword"
        [errorStateMatcher]="matcher"
      />
      <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">{{
        hideConfirmPassword ? 'visibility' : 'visibility_off'
      }}</mat-icon>
      <mat-error *ngIf="confirmPasswordHasError()">
        {{ getConfirmPasswordErrorMessage() }}
      </mat-error>
    </mat-form-field>
    <div fxLayout fxLayoutAlign="center end" class="padding-top-x4">
      <button
        fxFlex.gt-sm="20%"
        fxFlex.lt-md="100%"
        class="full-width margin-bottom save-button"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="form.pristine || !form.valid || loading"
      >
        Save
      </button>
    </div>
    <span fxLayout="row" fxLayoutAlign="center center" class="account-info margin-top-md">
      <p>Don't have an account?</p>
      <a mat-button [routerLink]="[]" (click)="onNavigateToSignIn()" class="signin padding-top-sm"> Back </a>
    </span>
  </form>
</div>
