<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family bodyBackground">
  <div fxLayout class="header-navigation-back">
    <mat-icon (click)="goBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">Driving Experience</span>
    <button
      data-test="save-button"
      mat-button
      [disabled]="(!form.dirty || !form.valid) && !loading"
      [class.save-disabled]="(!form.dirty || !form.valid) && !loading"
      class="header-action-button"
      (click)="onSubmit()"
    >
      Save
    </button>
  </div>
  <div class="content">
    <div fxLayout="column" fxLayoutAlign="center stretch" class="component-container padding-top-x2">
      <mat-error *ngIf="errorMessage" class="error">
        {{ errorMessage }}
      </mat-error>
      <div class="exp-info-banner">
        <div class="exp-info">
          <span class="exp-info-text">YEARS OF EXPERIENCE</span>
          <span class="exp-info-value" *ngIf="!(totalYears || totalMonths)">- -</span>
          <span class="exp-info-value" *ngIf="totalYears || totalMonths"
            >{{ totalYears > 0 ? totalYears + ' Years' : '' }}
            {{ totalMonths > 0 ? totalMonths + ' Months' : '' }}</span
          >
        </div>
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" fxLayout="column">
        <div class="main-info">
          <div class="header" fxLayout>
            <div class="no-truck-experience" fxFlex="60">
              <p [ngClass]="disableAddNew && !noTruckingExperience ? 'info-text-disabled' : 'info-text'">
                I have no truck driving experience
              </p>
            </div>
            <div fxFlex="30" class="text-align-right">
              <mat-checkbox
                class="full-width"
                formControlName="noTruckingExperience"
                labelPosition="before"
                (change)="noDrivingChecked($event)"
                [disabled]="disableAddNew && !noTruckingExperience"
              ></mat-checkbox>
            </div>
          </div>

          <div class="add-truck-driving-experience">
            <button
              mat-button
              type="button"
              class="add-truck"
              (click)="addDrivingExperienceRecord()"
              [disabled]="disableAddNew || noTruckingExperience"
              data-test="add-truck-experience"
            >
              <i class="material-icons">add</i>Add Truck Driving Experience
            </button>
            <div *ngIf="!noTruckingExperience && showCancelButton">
              <button mat-button class="btn-cancel" (click)="cancelAddingExperience()" [data-test]="cancel">
                Cancel
              </button>
            </div>
          </div>

          <ng-container *ngIf="showCancelButton">
            <div>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="expansion-header">
                    <mat-panel-title class="truck-panel-title" data-test="truck-type">
                      Straight Truck
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-truck-type
                    [group]="form.get('straightTruck')"
                    [groupName]="'straightTruck'"
                    [milesDrivenOptions]="milesDrivenOptions"
                    (yearChange)="onYearChange()"
                    (monthChange)="onMonthChange()"
                    data-test="straight-truck"
                  >
                  </app-truck-type>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

            <div>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="expansion-header">
                    <mat-panel-title class="truck-panel-title">
                      Tractor and Semi-Trailer
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-truck-type
                    [group]="form.get('tractorAndSemiTrailer')"
                    [groupName]="'tractorAndSemiTrailer'"
                    [milesDrivenOptions]="milesDrivenOptions"
                    (yearChange)="onYearChange()"
                    (monthChange)="onMonthChange()"
                  >
                  </app-truck-type>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

            <div>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="expansion-header">
                    <mat-panel-title class="truck-panel-title">
                      Tractor-Two Trailers
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-truck-type
                    [group]="form.get('tractorTwoTrailers')"
                    [groupName]="'tractorTwoTrailers'"
                    [milesDrivenOptions]="milesDrivenOptions"
                    (yearChange)="onYearChange()"
                    (monthChange)="onMonthChange()"
                  >
                  </app-truck-type>
                </mat-expansion-panel>
              </mat-accordion>
            </div>

            <div>
              <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                  <mat-expansion-panel-header class="expansion-header">
                    <mat-panel-title class="truck-panel-title">
                      Others
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <app-truck-type
                    [group]="form.get('others')"
                    [groupName]="'others'"
                    [milesDrivenOptions]="milesDrivenOptions"
                    (yearChange)="onYearChange()"
                    (monthChange)="onMonthChange()"
                  >
                  </app-truck-type>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
    <br />
  </div>
</div>
