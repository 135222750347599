import { Component, Input, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ROUTE_DQF } from '../driverQualification-routing.module';
import { ROUTE_DQF_UPLOADS } from 'src/app/shared/routes';
import { DriverQualificationService } from '../service/driver-qualification.service';
@Component({
  selector: 'app-dqf-processing-background-request',
  templateUrl: './dqf-processing-background-request.component.html',
  styleUrls: ['./dqf-processing-background-request.component.scss']
})
export class DqfProcessingBackgroundRequestComponent implements OnInit {
  documentName: string
  errorMessage: string
  driverEmail: string;
  constructor(
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private dqfService: DriverQualificationService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.documentName = params.documentType;
      })
    this.driverEmail = this.registrationService.getDriver().email
  }

  navigateBack() {
    this.router.navigate(
      [`${ROUTE_DQF}/${ROUTE_DQF_UPLOADS}`], {
      queryParams: {
        type: 'Driver Uploads'
      }
    }
    );
  }

  onSwipeRight(event: MouseEvent) {
    event.preventDefault();
  }
}
