import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ROUTE_PRIVACY_STATEMENT, ROUTE_SIGNUP } from '../shared/routes';
import { Location } from '@angular/common';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  private history: string[] = [];

  constructor(private readonly router: Router, private readonly location: Location) {
    if (this.router.events) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects);
        }
      });
    }
  }

  ngOnInit() {}
  onNavigateToPrivacyPolicy(): void {
    this.router.navigate([`/${ROUTE_PRIVACY_STATEMENT}`]);
  }

  onNavigateBack(): void {
    this.location.back();
  }
}
