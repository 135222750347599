import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnsubscribeEmailApiService } from './unsubscribe-email-api.service';
import { Email } from '../../model/email.model';

@Injectable()
export class UnsubscribeEmailDataService {

  constructor(private readonly unsubscribeEmailApiService: UnsubscribeEmailApiService) { }

  updateEmailToUnsubscribe(model: Email): Observable <Response> {
    return this.unsubscribeEmailApiService.updateEmailToUnsubscribe(model).pipe(
      map((response: any) => response.data)
    );
  }

}
