import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationDialogComponent } from './information-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '../../material/material.module';

@NgModule({
  declarations: [InformationDialogComponent],
  imports: [CommonModule, MatIconModule, FlexLayoutModule, MaterialModule],
  exports: [InformationDialogComponent]
})
export class InformationDialogModule {}
