import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { catchError, finalize, first, map } from 'rxjs/operators';
import { Location } from '@angular/common';

import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Preferences } from 'src/app/shared/models/preferences.model';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SelectActionComponent } from 'src/app/shared/dialog/select-action/select-action.component';
import {
  CROSS_BORDER,
  DAYS_AWAY_FROM_BASE,
  EARNING_TYPE,
  LOAD_WEIGHT_PREFERENCE,
  MAX_DEADHEAD_MILES,
  TRAILER_TYPE,
  TRUCK_TYPE,
  US_ZONES
} from '../constants';
import { Driver } from 'src/app/shared/models/driver.model';

const TYPE_ORIGIN = 'origin';
const TYPE_DESTINATION = 'destination';
const TYPE_BASE = 'base';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  form: FormGroup;
  driver: Driver;
  currentTab: number = 0;
  countryMap = { Canada: 'CA', USA: 'US' };
  preference: Preferences;
  loadWeightPreferenceOptions = LOAD_WEIGHT_PREFERENCE;
  maxDeadheadMilesOptions = MAX_DEADHEAD_MILES;
  earningTypeOptions = EARNING_TYPE;
  truckTypeOptions = TRUCK_TYPE;
  trailerTypeOptions = TRAILER_TYPE;
  daysAwayFromBaseLocationOptions = DAYS_AWAY_FROM_BASE;
  crossBorderOptions = CROSS_BORDER;
  usZoneOptions = US_ZONES;
  previousFormData: Preferences;
  originValid: boolean = false;
  destinationValid: boolean = false;
  baseValid: boolean = false;
  isAutoCompleteEnabled: boolean = false;

  @ViewChild('favouriteLaneOriginText', { static: false }) favouriteLaneOriginText: any;
  @ViewChild('favouriteLaneDestinationText', { static: false }) favouriteLaneDestinationText: any;
  @ViewChild('baseLocationText', { static: false }) baseLocationText: any;
  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly registrationService: RegistrationService,
    private readonly bottomSheet: MatBottomSheet,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.form = this.createForm();
  }

  ngOnInit() {
    this.driver = this.registrationService.getDriver();
    this.getPreferenceDetails();
  }

  ngAfterViewInit(): void {
    this.showGoogleAutoComplete();
  }

  getPreferenceDetails(): void {
    this.form.disable();
    this.registrationService
      .getPreferences()
      .pipe(
        first(),
        finalize(() => {
          this.form.enable();
        }),
        catchError(err => {
          if (err.status === 404) {
            this.preference = null;
            this.previousFormData = this.createForm().value;
          }
          return err;
        })
      )
      .subscribe(response => {
        this.preference = response.data;
        this.previousFormData = response.data;
        this.populateForm(response.data);
      });
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
  }

  savePreferences() {
    if (!this.preference?.id) {
      // POST
      let formValue = {
        ...this.form.value
      };
      Object.keys(formValue).forEach(key => {
        if (formValue[key] === null || formValue[key] === '') {
          delete formValue[key];
        }
      });
      if (!Object.keys(formValue).length) {
        return;
      }
      this.form.disable();
      this.registrationService
        .savePreferences(formValue)
        .pipe(
          map((res: Preferences) => {
            this.preference = res;
            this.previousFormData = res;
          }),
          finalize(() => {
            this.form.reset();
            this.changeDetectorRef.detectChanges();
            this.populateForm(this.previousFormData);
            this.form.enable();
          }),
          catchError((error: any) => {
            return error;
          })
        )
        .subscribe(() => {});
    } else {
      // PATCH
      let dirtyFields: any = {};
      let formValue = { ...this.form.value };
      for (const key in formValue) {
        if (
          (formValue.hasOwnProperty(key) && !this.previousFormData.hasOwnProperty(key) && formValue[key] !== '') ||
          (formValue.hasOwnProperty(key) &&
            this.previousFormData.hasOwnProperty(key) &&
            formValue[key] !== this.previousFormData[key])
        ) {
          dirtyFields[key] = formValue[key];
        }
      }

      if (!Object.keys(dirtyFields).length) {
        return;
      }
      this.form.disable();
      this.registrationService
        .updatePreferences(dirtyFields)
        .pipe(
          map(() => {
            this.previousFormData = this.form.value;
          }),
          finalize(() => {
            this.form.reset();
            this.changeDetectorRef.detectChanges();
            this.populateForm(this.previousFormData);
            this.form.enable();
          }),
          catchError((error: any) => {
            return error;
          })
        )
        .subscribe(() => {});
    }
  }

  get favouriteLaneOrigin() {
    return this.form.get('favouriteLaneOrigin');
  }

  get favouriteLaneDestination() {
    return this.form.get('favouriteLaneDestination');
  }

  get baseLocation() {
    return this.form.get('baseLocation');
  }

  get crossBorder() {
    return this.form.get('crossBorder');
  }

  getFieldDisplayName(field: string, optionsName: string) {
    let value = this[optionsName]?.find(el => el.name === this.form.get(field)?.value)?.displayName ?? '';
    if (field === 'crossBorder' && value) {
      value === 'Yes' ? 'Yes' : 'No';
    } else if (value === 'None') {
      value = '';
    }
    return value;
  }

  goBack(): void {
    this.location.back();
  }

  private populateForm(model: Preferences): void {
    if (model.favouriteLaneOrigin) {
      this.originValid = true;
    }
    if (model.favouriteLaneDestination) {
      this.destinationValid = true;
    }
    if (model.baseLocation) {
      this.baseValid = true;
    }
    if (model) {
      this.form.setValue({
        loadWeightPreference: model.loadWeightPreference ?? '',
        maxDeadheadMiles: model.maxDeadheadMiles ?? '',
        favouriteLaneOrigin: model.favouriteLaneOrigin ?? '',
        favouriteLaneOriginCity: model.favouriteLaneOriginCity ?? '',
        favouriteLaneOriginState: model.favouriteLaneOriginState ?? '',
        favouriteLaneDestination: model.favouriteLaneDestination ?? '',
        favouriteLaneDestinationCity: model.favouriteLaneDestinationCity ?? '',
        favouriteLaneDestinationState: model.favouriteLaneDestinationState ?? '',
        earningType: model.earningType ?? '',
        truckType: model.truckType ?? '',
        trailerType: model.trailerType ?? '',
        baseLocation: model.baseLocation ?? '',
        baseLocationCity: model.baseLocationCity ?? '',
        baseLocationState: model.baseLocationState ?? '',
        daysAwayFromBaseLocation: model.daysAwayFromBaseLocation ?? '',
        usZone: model.usZone ?? '',
        crossBorder: model.crossBorder ?? ''
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      loadWeightPreference: [''],
      maxDeadheadMiles: [''],
      favouriteLaneOrigin: [''],
      favouriteLaneOriginCity: [''],
      favouriteLaneOriginState: [''],
      favouriteLaneDestination: [''],
      favouriteLaneDestinationCity: [''],
      favouriteLaneDestinationState: [''],
      earningType: [''],
      truckType: [''],
      trailerType: [''],
      baseLocation: [''],
      baseLocationCity: [''],
      baseLocationState: [''],
      daysAwayFromBaseLocation: [''],
      crossBorder: [''],
      usZone: ['']
    });

    return form;
  }

  onOpenStatusActionSheet(options: any, title: string, field: string): void {
    this.openActionSheet(options, title, field);
  }

  openActionSheet(options: string[], title: string, field: string): void {
    this.bottomSheet.open(SelectActionComponent, {
      data: {
        title: title,
        options: options,
        selectedValue: this.form.value[field],
        onConfirm: (val: string) => {
          if (field === 'crossBorder' && val !== '' && !val) {
            this.form.patchValue({
              [field]: val,
              usZone: ''
            });
          } else {
            this.form.patchValue({
              [field]: val
            });
          }
          this.savePreferences();
        }
      }
    });
  }

  private getPlaceAutocomplete(addressText: any, type: string): void {
    const autocomplete = new google.maps.places.Autocomplete(addressText.nativeElement, {
      types: ['(cities)'],
      componentRestrictions: {
        country: [this.countryMap.Canada, this.countryMap.USA]
      }
    });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      autocomplete.setFields(['formatted_address']);
      const place = autocomplete.getPlace();

      // When user uses Enter key to select the address
      if (type === TYPE_ORIGIN) {
        this.originValid = true;
      } else if (type === TYPE_DESTINATION) {
        this.destinationValid = true;
      } else if (type === TYPE_BASE) {
        this.baseValid = true;
      }
      if (place) {
        const formattedAddress = place.formatted_address;
        this.formatAddressAndAlign(formattedAddress, addressText.nativeElement.name, type);
      }
    });

    return addressText;
  }

  formatAddressAndAlign(formattedAddess: string, formControlName: string, type: string): void {
    const address = formattedAddess?.split(',');
    const countrySuffix = this.countryMap[address?.[2]?.trim()];
    const addressTxt = `${address?.[0]},${address?.[1]?.trim().split(' ')[0]}, ${countrySuffix}`;
    if (type === TYPE_ORIGIN) {
      this.form.patchValue({
        favouriteLaneOrigin: addressTxt,
        favouriteLaneOriginCity: address?.[0],
        favouriteLaneOriginState: address?.[1]?.trim().split(' ')[0]
      });
    } else if (type === TYPE_DESTINATION) {
      this.form.patchValue({
        favouriteLaneDestination: addressTxt,
        favouriteLaneDestinationCity: address?.[0],
        favouriteLaneDestinationState: address?.[1]?.trim().split(' ')[0]
      });
    } else if (type === TYPE_BASE) {
      this.form.patchValue({
        baseLocation: addressTxt,
        baseLocationCity: address?.[0],
        baseLocationState: address?.[1]?.trim().split(' ')[0]
      });
    }
    this.updatePreference();
    this.changeDetectorRef.detectChanges();
  }

  showGoogleAutoComplete(): void {
    this.getPlaceAutocomplete(this.favouriteLaneOriginText, TYPE_ORIGIN);
    this.getPlaceAutocomplete(this.favouriteLaneDestinationText, TYPE_DESTINATION);
    this.getPlaceAutocomplete(this.baseLocationText, TYPE_BASE);
    this.isAutoCompleteEnabled = true;
  }

  hideGoogleAutoComplete(): void {
    // hide google address dropdown (pac-container) when form values made empty -
    // because when user focus on input after making it empty it's showing previous dropdown values
    var allContainers = document.getElementsByClassName('pac-container');
    for (let i = 0; i < allContainers.length; i++) {
      (allContainers[i] as HTMLElement).style.visibility = 'hidden';
    }
    this.isAutoCompleteEnabled = false;
  }

  onInputChange(type: string): void {
    // When user selects any address from dropdown (pac-container) make origin/destination/base valid to true
    var pacContainers = document.getElementsByClassName('pac-container');
    for (let i = 0; i < pacContainers.length; i++) {
      pacContainers[i].addEventListener('mousedown', () => {
        // When user clicks the address
        // When user uses Enter key to select the address
        if (type === TYPE_ORIGIN) {
          this.originValid = true;
        } else if (type === TYPE_DESTINATION) {
          this.destinationValid = true;
        } else if (type === TYPE_BASE) {
          this.baseValid = true;
        }
      });
    }

    // When origin/destination/base valid is true and user starts editing the input value - make it false
    if (type === TYPE_ORIGIN && this.originValid) {
      this.originValid = false;
    } else if (type === TYPE_DESTINATION && this.destinationValid) {
      this.destinationValid = false;
    } else if (type === TYPE_BASE && this.baseValid) {
      this.baseValid = false;
    }
  }

  focusin() {
    if (!this.isAutoCompleteEnabled && (!this.originValid || !this.destinationValid || !this.baseValid)) {
      this.showGoogleAutoComplete();
    }
  }

  focusOut(type: string): void {
    // On focusout if origin/destination/base valid is false - remove values
    if (type === TYPE_ORIGIN && !this.originValid) {
      this.form.patchValue({
        favouriteLaneOrigin: '',
        favouriteLaneOriginCity: '',
        favouriteLaneOriginState: ''
      });
      this.hideGoogleAutoComplete();
      this.updatePreference();
    } else if (type === TYPE_DESTINATION && !this.destinationValid) {
      this.form.patchValue({
        favouriteLaneDestination: '',
        favouriteLaneDestinationCity: '',
        favouriteLaneDestinationState: ''
      });
      this.hideGoogleAutoComplete();
      this.updatePreference();
    } else if (type === TYPE_BASE && !this.baseValid) {
      this.form.patchValue({
        baseLocation: '',
        baseLocationCity: '',
        baseLocationState: ''
      });
      this.hideGoogleAutoComplete();
      this.updatePreference();
    }
  }

  updatePreference() {
    if (!this.form.disabled) {
      this.savePreferences();
    }
  }
}
