<div fxLayoutAlign="start" fxLayout="column" class="dqf-review page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">DQF for {{ carrierCompanyName }}</div>
  </div>

  <div>
    <div fxFlex fxLayout="column" class="review">
      <div>
        <div fxFlex="100" fxLayout="row" class="review-header">
          <div fxFlex="20"><button class="font-family" (click)="onEdit()">Edit</button></div>
          <div fxFlex="60" class="profile">
            <img [src]="profilePicture">
          </div>

          <div fxFlex="20" class="sign-in">
            <button class="font-family" (click)="openSignature()" data-test="signin-button">Sign</button>
          </div>
        </div>
      </div>
      <div class="review-info" fxFlex fxLayout="column">
        <ng-container *ngFor="let info of declarationInfo">
          <mat-accordion>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header [collapsedHeight]="customCollapsedHeight"
                [expandedHeight]="customCollapsedHeight" class="expansion-header" class="font-family">
                <mat-panel-title>
                  {{info.heading}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="info-content" [innerHTML]="info.value">
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
      </div>
    </div>
  </div>
</div>