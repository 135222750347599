import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DriverDashboardRoutingModule } from './driver-dashboard-routing.module';
import { DriverDashboardComponent } from './driver-dashboard/driver-dashboard.component';
import { MaterialModule } from '../shared/material/material.module';
import { BerbixModule } from 'berbix-angular';
import { DriverIdCardComponent } from './driver-id-card/driver-id-card.component';
import { ActionsModule } from '../shared/actions/actions.module';

@NgModule({
  declarations: [DriverDashboardComponent, DriverIdCardComponent],
  imports: [CommonModule, MaterialModule, DriverDashboardRoutingModule, BerbixModule, ActionsModule]
})
export class DriverDashboardModule {}

export { DriverIdCardComponent } from './driver-id-card/driver-id-card.component';
