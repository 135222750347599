import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import * as HelloSign from 'hellosign-embedded';
import { DriverQualificationService } from '../service/driver-qualification.service';
import { catchError, first, map, tap } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { Store } from '@ngxs/store';
import { RegistrationState, NotificationStateModel } from 'src/app/registration/state/registration.state';
import { ROUTER_DQF_VIEW } from 'src/app/shared/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { DQF_DOC_STATUS } from '../constants';
import { DqfDocument } from '../models/dqfDocument';
import { ROUTE_DQF, ROUTE_DQF_UPLOADS } from 'src/app/shared/routes';
import dayjs from 'dayjs';
import { DRIVER_DOC_CONTEXT_INITIAL_DQF } from '../constants';

const ACTOR_TYPE_DRIVER = 'driver';
const DOCUMENT_SIGN_DISPLAY_NAME = 'Declaration';
const DOCUMENT_TYPE_SIGN = 'declarationForm';
const UPLOADED_STATUS = 'uploaded';
const PENDING_STATUS = 'Pending';
const SIGNED_STATUS = 'signed';
const REJECTED_STATUS = 'Rejected';
const DELETED_DOCUMENT = 'Successfully deleted document';
const UPLOADED_DOCUMENT = 'Uploading your document...';
const COLLECTED_DOCUMENT = 'Collecting your document...';
const DISABLE_TOP_LOADING_BAR = true;
const EMPLOYMENT_HISTORY_CHECK = 'EmploymentHistoryCheck';
const EMPLOYMENT_HISTORY_CHECK_DISPLAY_NAME = 'Employment History Check';
const EMPLOYMENT_HISTORY_CHECK_DECLARATION_NOT_SIGNED = 'EMPLOYMENT HISTORY CHECK NOT SIGNED';
const EMPLOYMENT_HISTORY_CHECK_DECLARATION_SIGNED = 'EMPLOYMENT HISTORY CHECK FORM SIGNED';
const DEFAULT_DECLARATION_NOT_SIGNED = 'DECLARATION IS NOT SIGNED';
const DEFAULT_DECLARATION_SIGNED = 'DECLARATION FORM SIGNED';

@Component({
  selector: 'app-dqf-sign-declaration',
  templateUrl: './dqf-sign-declaration.component.html',
  styleUrls: ['./dqf-sign-declaration.component.scss']
})
export class DqfSignDeclarationComponent implements OnInit {
  isContentReady: boolean = false;
  documentData: any;
  buttonText: string = 'Sign Declaration';
  generationDate: string = 'dd-mmm-yyyy';
  isDocumentProcessing: boolean = false;
  isRejected: boolean = false;
  isSigning: boolean = true;
  dqfId: string;
  dqfData;
  declarationFormDqfData;
  declarationFormDriverData;
  declarationLabel: string = DEFAULT_DECLARATION_NOT_SIGNED;
  isEmploymentHistoryCheck = false;
  pageTitle = DOCUMENT_SIGN_DISPLAY_NAME;
  constructor(
    private readonly location: Location,
    private router: Router,
    private driverQualificationService: DriverQualificationService,
    private readonly registrationService: RegistrationService,
    private readonly toastService: ToastService,
    @Inject('environmentData') private environment: any,
    private readonly store: Store,
    private route: ActivatedRoute
  ) {
    this.route?.queryParams?.subscribe(params => {
      const declarationType = params['type'];
      if (declarationType && declarationType === EMPLOYMENT_HISTORY_CHECK) {
        this.declarationLabel = EMPLOYMENT_HISTORY_CHECK_DECLARATION_NOT_SIGNED;
        this.pageTitle = EMPLOYMENT_HISTORY_CHECK_DISPLAY_NAME;
        this.isEmploymentHistoryCheck = true;
      }
    });
  }

  ngOnInit(): void {
    this.getDqfData();
    this.getDeclarationFormData();
    this.loadDocuments();
  }
  getDqfData(): void {
    if (!this.driverQualificationService.dqfId || this.dqfId) {
      this.router.navigate([`${ROUTE_DQF}`]);
      return;
    }
    this.dqfId = this.driverQualificationService.dqfId;
    if (!this.driverQualificationService.dqfData) {
      this.driverQualificationService.getDqfById(this.dqfId).subscribe((response: DqfDocument) => {
        if (response) {
          this.dqfData = response;
        }
      });
    }
    this.dqfData = this.driverQualificationService.dqfData;
  }
  getDeclarationFormData(): void {
    if (this.dqfData) {
      let filterType = DOCUMENT_TYPE_SIGN;
      if (this.isEmploymentHistoryCheck) {
        filterType = EMPLOYMENT_HISTORY_CHECK;
      }
      this.declarationFormDqfData = this.dqfData.attachedDocuments.filter(e => e.type === filterType);
      this.determineSignatureStatus();
    }
  }
  backAction(): void {
    this.location.back();
  }
  buttonAction(): void {
    if (this.isSigning) {
      this.openHelloSignDialog();
    } else {
      this.viewDocument();
    }
  }
  viewDocument(): void {
    this.router.navigateByUrl(`${ROUTER_DQF_VIEW}/${this.declarationFormDriverData.id}`);
  }
  openHelloSignDialog(): void {
    this.isContentReady = false;
    const declarationModel = {
      actorType: ACTOR_TYPE_DRIVER,
      documentDisplayName: '',
      documentType: '',
      context: DRIVER_DOC_CONTEXT_INITIAL_DQF,
      dqfId: this.dqfId
    };

    if (this.isEmploymentHistoryCheck) {
      declarationModel.documentDisplayName = EMPLOYMENT_HISTORY_CHECK_DISPLAY_NAME;
      declarationModel.documentType = EMPLOYMENT_HISTORY_CHECK;
    } else {
      declarationModel.documentDisplayName = DOCUMENT_SIGN_DISPLAY_NAME;
      declarationModel.documentType = DOCUMENT_TYPE_SIGN;
    }

    this.driverQualificationService
      .getHelloSignUrl(declarationModel)
      .pipe(
        tap((response: any) => {
          if (response) {
            const documentId = response.documentId;

            const hellosignPath = response.signatureUrl;
            const client = new HelloSign({
              clientId: this.environment.helloSignClientId,
              testMode: !this.environment?.featureFlags?.production
            });
            client.open(hellosignPath, () => {});
            let status = '';
            client.on('finish', () => {
              status = 'finished';
            });
            client.on('cancel', () => {
              status = 'canceled';
            });
            client.on('close', () => {
              if (status === 'finished') {
                this.updateDocumentStatus(documentId, SIGNED_STATUS);
              }
              if (status === 'canceled') {
                this.isContentReady = true;
                this.isSigning = true;
                this.defaultDateAndButtonText();
              }
            });
          }
        }),
        catchError(error => {
          this.isContentReady = true;
          return error;
        })
      )
      .subscribe();
  }
  setDocumentStatusDriverReviewed(): void {
    const docModel = {
      attachedDocument: {
        type: DOCUMENT_TYPE_SIGN,
        status: DQF_DOC_STATUS.DRIVER_REVIEWED
      }
    };

    if (this.isEmploymentHistoryCheck) {
      docModel.attachedDocument.type = EMPLOYMENT_HISTORY_CHECK;
    }

    this.driverQualificationService.updateDqfStatus(this.dqfId, docModel).subscribe(() => {
      this.isContentReady = true;
      this.isSigning = false;
      this.toastService.showSuccess(UPLOADED_DOCUMENT);

      this.router.navigateByUrl(`${ROUTE_DQF}/${ROUTE_DQF_UPLOADS}?type=Driver Uploads`);
    });
  }
  loadDocuments(turnOffSkeletonLoading?: boolean): void {
    if (!turnOffSkeletonLoading) {
      this.isContentReady = false;
    }
    this.registrationService
      .loadDriver(DISABLE_TOP_LOADING_BAR)
      .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
      .subscribe((notificationStateModel: NotificationStateModel) => {
        if (notificationStateModel && notificationStateModel.notifications.length) {
          let filterContent = DOCUMENT_TYPE_SIGN;
          if (this.isEmploymentHistoryCheck) {
            filterContent = EMPLOYMENT_HISTORY_CHECK;
          }

          const filteredValue = notificationStateModel.notifications.filter(
            notification =>
              notification.type === filterContent && notification?.dqfId === this.driverQualificationService.dqfId
          );
          if (!turnOffSkeletonLoading) {
            this.isContentReady = true;
          }
          if (filteredValue.length) {
            this.declarationFormDriverData = filteredValue[0];

            if (this.declarationFormDriverData.status === SIGNED_STATUS) {
              this.isDocumentProcessing = true;
              this.isSigning = false;
            } else if (this.declarationFormDriverData.status === PENDING_STATUS.toLocaleLowerCase()) {
              this.isDocumentProcessing = false;
              this.defaultDateAndButtonText();
            } else {
              this.isDocumentProcessing = false;
              this.isSigning = false;
              this.assignViewDateAndButtonText();
            }
          } else {
            this.isDocumentProcessing = false;
            this.defaultDateAndButtonText();
          }
        } else {
          this.isDocumentProcessing = false;
          this.defaultDateAndButtonText();
        }
        if (!turnOffSkeletonLoading) {
          this.isContentReady = true;
        }
      });
  }
  determineSignatureStatus(): void {
    if (this.declarationFormDqfData.length) {
      if (this.declarationFormDqfData[0].status === REJECTED_STATUS) {
        this.isDocumentProcessing = false;
        this.isRejected = true;
      }
    }
  }
  deleteDocument(): void {
    if (this.declarationFormDriverData) {
      this.isContentReady = false;
      this.driverQualificationService
        .deleteDqfDocument(this.declarationFormDriverData.id, DISABLE_TOP_LOADING_BAR)
        .subscribe(() => {
          this.isContentReady = true;
          this.toastService.showSuccess(DELETED_DOCUMENT);
          this.isRejected = false;
          this.isDocumentProcessing = false;
          this.declarationFormDriverData = undefined;
          this.defaultDateAndButtonText();
        });
    }
  }
  updateDocumentStatus(documentId: string, status: string): void {
    if (documentId && status) {
      this.isContentReady = false;
      const saveModel = {
        status: status
      };
      this.driverQualificationService
        .updateDocument(documentId, saveModel, DISABLE_TOP_LOADING_BAR)
        .pipe(
          first(),
          tap(() => {
            this.toastService.showSuccess(COLLECTED_DOCUMENT);
            this.setDocumentStatusDriverReviewed();
          }),
          catchError(error => {
            this.backAction();
            return error;
          })
        )
        .subscribe();
    }
  }
  private assignViewDateAndButtonText(): void {
    this.buttonText = 'View Declaration';
    this.declarationLabel = DEFAULT_DECLARATION_SIGNED;
    if (this.isEmploymentHistoryCheck) {
      this.buttonText = 'View';
      this.declarationLabel = EMPLOYMENT_HISTORY_CHECK_DECLARATION_SIGNED;
    }

    this.generationDate = dayjs(this.declarationFormDriverData.generationDate).format('DD-MM-YYYY');
  }
  private defaultDateAndButtonText(): void {
    this.buttonText = 'Sign Declaration';
    if (this.isEmploymentHistoryCheck) {
      this.buttonText = 'Sign';
    }
    this.isSigning = true;
    this.generationDate = 'DD-MM-YYYY';
  }
}
