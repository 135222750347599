<form [formGroup]="signatureForm" class="full-width dqf-sign" (ngSubmit)="onSubmit()" data-test="signature-form">
  <div class="content" fxLayout="column">
    <div fxFlex="20" fxLayout class="initial-container">
      <div fxFlex="100" fxLayout class="initial-field-container" data-test="initials">
        <label fxFlex="20" class="form-field-label">Initials</label>
        <mat-form-field fxFlex="100" class="form-field-initial" appearance="standard">
          <input matInput formControlName="initial" aria-autocomplete="none" autocomplete="off" required />
        </mat-form-field>
      </div>
    </div>

    <div fxFlex="20" fxLayout class="initial-container">
      <div fxFlex="100" fxLayout class="initial-field-container">
        <label fxFlex="20" class="form-field-label">Date</label>
        <mat-form-field fxFlex="100" class="form-field-initial" appearance="standard">
          <input matInput formControlName="date" required disabled />
        </mat-form-field>
      </div>
    </div>
    <div class="description" fxFlex="35">
      This certifies that this application was completed by me, and that all entries on it and information in it are
      true and complete to the best of my knowledge.
    </div>
    <div fxFlex="15" class="button-container">
      <button
        fxFlex="100"
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="!signatureForm.valid || signatureForm.pristine || isClicked"
        class="save-button"
        data-test="send-for-approval"
      >
        Send for approval
      </button>
    </div>
  </div>
</form>
