<div fxLayoutAlign="start" fxLayout="column" class="page gray-font-color font-family">
  <div class="header-navigation-back" fxFlayout>
    <div class="back-icon" (click)="backAction()" fxFlex="5" data-test="back-arrow-button">
      <mat-icon svgIcon="left-arrow"></mat-icon>
    </div>
    <div fxFlex="95" class="header-text">{{ pageTitle }}</div>
  </div>
  <div class="content" fxLayout="column" *ngIf="!isContentReady">
    <app-dqf-document-skeleton></app-dqf-document-skeleton>
  </div>
  <div *ngIf="isContentReady && isDocumentProcessing" class="container-loading-gif">
    <div fxLayoutAlign="center">
      <img src="/assets/icons/FleetOperate load.gif" />
    </div>
    <div fxLayoutAlign="center" class="loading-text">
      Getting ready with your {{ pageTitle }} form.<br />Please come back after few minutes...
    </div>
  </div>
  <div *ngIf="isContentReady && isRejected">
    <div class="rejection-banner">
      <div class="description">Declaration rejected. Please Delete to restart</div>
    </div>
    <div class="btn-delete-container">
      <button mat-stroked-button class="btn-delete" (click)="deleteDocument()">
        <mat-icon svgIcon="delete-icon" class="delete-icon" data-test="delete-declaration-icon"></mat-icon>Delete
        Declaration
      </button>
    </div>
  </div>
  <div
    class="content"
    fxLayout="column"
    [ngClass]="{ 'display-none': !isContentReady || isDocumentProcessing }"
    *ngIf="!isRejected"
  >
    <div fxFlex="15" fxLayout="column" class="margin-top-md">
      <div fxFlex="15" class="margin-bottom-xs">
        <mat-label class="date-label">{{ declarationLabel }}</mat-label>
      </div>
      <div fxFlex="85">
        <mat-form-field class="full-width">
          <input matInput type="text" disabled="true" [value]="generationDate" />
          <span matSuffix><mat-icon svgIcon="calendar-line"></mat-icon></span>
        </mat-form-field>
      </div>
    </div>
    <div fxFlex="15">
      <button
        mat-button
        class="btn-sign-decl"
        (click)="buttonAction()"
        [disabled]="!isContentReady"
        data-test="sign-in-declaration"
      >
        <mat-icon svgIcon="ball-pen" class="icon-ball-pen"></mat-icon>{{ buttonText }}
      </button>
    </div>
  </div>
</div>
