import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ROUTE_DQF, ROUTE_DQF_CRIMINAL_CONVICTION } from 'src/app/shared/routes';
import { CANADA_PROVINCES, CERTN_COUNTRIES, USA_PROVINCES } from 'src/app/shared/models/provinces';
import { DriverQualificationService } from '../service/driver-qualification.service';

const REQUIRED_FIELD_MESSAGE = 'You must enter a value';
const INVALID_CERNT_US_PROVINCES = ['US-AS', 'US-GU', 'US-PR', 'US-VI'];
@Component({
  selector: 'app-dqf-birth-location',
  templateUrl: './dqf-birth-location.component.html',
  styleUrls: ['./dqf-birth-location.component.scss']
})
export class DqfBirthLocationComponent implements OnInit {
  errorMessage: string;
  birthForm: FormGroup;
  countries = CERTN_COUNTRIES;
  stateProvinces: { value: string; name: string }[];
  requiredMessage = REQUIRED_FIELD_MESSAGE;
  enableProvinceButton = true;
  invalidCertnUSProvinces = INVALID_CERNT_US_PROVINCES;

  constructor(
    private readonly fb: FormBuilder,
    private readonly location: Location,
    private readonly dqfService: DriverQualificationService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.birthForm = this.fb.group({
      birthCountry: new FormControl('', Validators.required),
      birthProvince: new FormControl('', Validators.required)
    });
  }

  onCountryChange(value) {
    switch (value) {
      case 'US': {
        this.stateProvinces = USA_PROVINCES.filter(el => !this.invalidCertnUSProvinces.includes(el.value));
        this.enableProvince();
        break;
      }
      case 'CA': {
        this.stateProvinces = CANADA_PROVINCES;
        this.enableProvince();
        break;
      }
      default: {
        this.stateProvinces = [];
        this.enableProvinceButton = false;
        this.birthForm.get('birthProvince').disable();
        this.birthForm.get('birthProvince').patchValue(null);
      }
    }
  }

  onCertnCountrySearch(event: any): void {
    const searchText = event?.target?.value?.trim();

    if (searchText) {
      this.countries = CERTN_COUNTRIES.filter(country => country.key.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      this.countries = CERTN_COUNTRIES;
    }
  }

  enableProvince(): void {
    this.enableProvinceButton = true;
    this.birthForm.get('birthProvince').enable();
  }

  onSubmit(): void {
    if (this.birthForm.valid) {
      this.dqfService.driverBirthLocationData = {
        reportData: {
          birthLocation: {
            birthCountry: this.birthForm.value?.birthCountry,
            birthState: this.birthForm.value?.birthCountry === 'US' ? 
              this.birthForm.value?.birthProvince?.slice(3) : 
              this.birthForm.value?.birthProvince
          },
          convictions: []
        }
      };
      this.router.navigate([`${ROUTE_DQF}/${ROUTE_DQF_CRIMINAL_CONVICTION}`]);
    } else {
      this.birthForm.markAllAsTouched();
      return;
    }
  }

  navigateBack(): void {
    this.location.back();
  }
}
