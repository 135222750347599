import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccountSettingsRoutingModule } from './account-settings-routing.module';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { MaterialModule } from '../shared/material/material.module';

@NgModule({
  declarations: [AccountSettingsComponent],
  imports: [
    CommonModule,
    MaterialModule,
    AccountSettingsRoutingModule
  ]
})
export class AccountSettingsModule { }

export { AccountSettingsComponent } from './account-settings/account-settings.component';
