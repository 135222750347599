import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { StorageService } from 'src/app/shared/storage/storage.service';

const KEY_DRIVER = 'driver';

@Component({
  selector: 'app-dqf-driver-employment-history',
  templateUrl: './dqf-driver-employment-history.component.html',
  styleUrls: ['./dqf-driver-employment-history.component.scss']
})
export class DqfDriverEmploymentHistoryComponent implements OnInit {
  @Input() version: number;
  data = [];
  constructor(private storageService: StorageService, private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.getEmploymentInfo();
  }

  private getEmploymentInfo(): void {
    const driver = this.storageService.find(KEY_DRIVER);
    if(this.version > 0) {
      this.registrationService.getEmploymentHistory(this.version).subscribe((response: any[]) => {
        this.bindData(response);
       })
    } else {
      const driver = this.storageService.find(KEY_DRIVER);
      this.bindData(driver.employment);
    }
  }

  private bindData(employment): void {
    if (employment?.length) {
      const currentEmployment = employment.find((item) => item.isCurrentEmployment);
      const otherEmployments = employment.filter((item) => !item.isCurrentEmployment);
      otherEmployments.sort((a, b) => {
        const dateA = new Date(a.fromDate).getTime();
        const dateB = new Date(b.fromDate).getTime();
        return dateB - dateA;
      });
      const data = currentEmployment ? [currentEmployment, ...otherEmployments] : otherEmployments;
      data.forEach((e) => {
        if (e.employer) {
          const employed = {
            propertyName: 'Employer', propertyValue: `${e.employer}`,
            subInfoValue: `${e.position}`,
            subPropertyValue: `${dayjs(e.fromDate).format('MMM YYYY') as string
              } - ${dayjs(e.toDate).format('MMM YYYY') as string} `
          }
          this.data.push(employed)
        } else {
          const pastAddress = {
            propertyName: 'Unemployed',
            propertyValue: `${dayjs(e.fromDate).format('MMM YYYY') as string} - ${dayjs(e.toDate).format('MMM YYYY') as string} `
          }
          this.data.push(pastAddress)
        }
      })
    }
  }
}

