import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ShipmentDocumentsComponent } from './shipment-documents/shipment-documents.component';
import { ShipmentContactsComponent } from './shipment-contacts/shipment-contacts.component';
import { ShipmentDocumentComponent } from './shipment-document/shipment-document.component';
import { ShipmentContactDetailsComponent } from './shipment-contact-details/shipment-contact-details.component';
import { FileUploadModule } from '../shared/file-upload/file-upload.module';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ShipmentItemComponent } from './shipment-item/shipment-item.component';
import { ShipmentItemDetailsComponent } from './shipment-item-details/shipment-item-details.component';
import { ShipmentConfirmationDialogComponent } from './shipment-confirmation-dialog/shipment-confirmation-dialog.component';
import { ShipmentConfirmationInputDialogComponent } from './shipment-confirmation-input-dialog/shipment-confirmation-input-dialog.component';
import { OfferComponent } from './offer/offer.component';
import { ShipmentDetailsActionSheetComponent } from './shipment-details-action-sheet/shipment-details-action-sheet.component';
import { ShipmentMessageComponent } from './shipment-message/shipment-message.component';
import { ShipmentActionsService } from './shipment-actions.service';
import { ShipmentDataModule } from './shipment-data/shipment-data.module';
import { ShipmentDocumentsService } from './shipment-data/shipment-documents.service';
import { ShipmentUtilsService } from './shipment-utils.service';
import { OfferTypeService } from './offer-type.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { StatusBannerModule } from '../shared/status-banner/status-banner.module';
import { DateTimeConvertorModule } from '../shared/date-time-convertor/date-time-convertor.module';
import { CustomDirectivesModule } from '../shared/custom-directives/custom-directives.module';
import { NoShipmentComponent } from './no-shipment/no-shipment.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [
    ShipmentContactsComponent,
    ShipmentContactDetailsComponent,
    ShipmentDocumentsComponent,
    ShipmentDocumentComponent,
    ShipmentItemComponent,
    ShipmentItemDetailsComponent,
    ShipmentConfirmationDialogComponent,
    ShipmentConfirmationInputDialogComponent,
    OfferComponent,
    ShipmentDetailsActionSheetComponent,
    ShipmentMessageComponent,
    NoShipmentComponent
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatButtonModule,
    MatBottomSheetModule,
    ShipmentDataModule,
    FileUploadModule,
    StatusBannerModule,
    DateTimeConvertorModule,
    CustomDirectivesModule,
    MatCheckboxModule
  ],
  providers: [ShipmentActionsService, ShipmentDocumentsService, ShipmentUtilsService, OfferTypeService],
  exports: [
    ShipmentContactsComponent,
    ShipmentDocumentsComponent,
    ShipmentDocumentComponent,
    ShipmentItemComponent,
    ShipmentItemDetailsComponent,
    NoShipmentComponent
  ]
})
export class LoadsSharedModule {}

export { ShipmentDocumentsComponent } from './shipment-documents/shipment-documents.component';
export { ShipmentDocumentComponent } from './shipment-document/shipment-document.component';
export { ShipmentContactsComponent } from './shipment-contacts/shipment-contacts.component';
export { ShipmentItemComponent } from './shipment-item/shipment-item.component';
