import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { switchMap, tap, catchError, finalize, takeUntil, first } from 'rxjs/operators';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { DEFAULT_ERROR_MESSAGE } from '../shared/constants';
import * as mime from 'mime';
import { FileDataService } from 'src/app/shared/file-data/file-data.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { Observable, Subject, interval, of, timer } from 'rxjs';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';

const DOCUMENT_UPLOAD_SUCCESS_MESSAGE = 'Sucessfully uploaded shipment document';
const MESSAGE_ERROR_LOADING_SHIPMENT_DOCUMENTS = 'Error loading shipment documents. Please try again.';
const DOCUMENT_UPLADING_MESSAGE = 'Uploading the document'

@Component({
  selector: 'app-shipments-stops-action-sheet',
  templateUrl: './shipments-stops-action-sheet.component.html',
  styleUrls: ['./shipments-stops-action-sheet.component.scss']
})
export class ShipmentsStopsActionSheetComponent implements OnInit {
  acceptTypesPicture = ['image/png', 'image/jpg', 'image/jpeg'];
  acceptTypesMultiple = ['application/pdf'];
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('pictureSingle') pictureSingle: ElementRef<HTMLInputElement>;
  @ViewChild('cameraInput') cameraInput: ElementRef<HTMLInputElement>;
  private messageSubject: Subject<string>;
  private shipmentDocumentsSubject: Subject<ShipmentDocument[]>;
  $shipmentDocuments: Observable<ShipmentDocument[]>;
  isUploading: boolean = false;
  fileToUpload;
  loading: boolean = false;
  showCrop: boolean = false;
  imageChangedEvent;
  documentPicture;
  uploadingNewDocument = false;
  shipmentId;
  canvasRotation = 0;
  transform: ImageTransform = {};
  @ViewChild('previewImage') previewImage: ImageCropperComponent;
  shipmentStopId;
  documentType;
  url;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private readonly bottomSheetRef: MatBottomSheetRef<ShipmentsStopsActionSheetComponent>,
    private readonly documentsDataService: DocumentsDataService,
    private readonly fileDataService: FileDataService,
    private readonly toastService: ToastService
  ) {
    this.shipmentDocumentsSubject = new Subject<ShipmentDocument[]>();
    this.$shipmentDocuments = this.shipmentDocumentsSubject.asObservable();
  }

  ngOnInit(): void {
    this.shipmentId = this.data.shipmentId;
    this.shipmentStopId = this.data.shipmentStopId;
    this.documentType = this.data.documentType;
  }

  openFileExplorer(): void {
    this.fileInput.nativeElement.click();
  }
  openGallery(): void {
    this.pictureSingle.nativeElement.click();
  }
  openCamera(): void {
    this.cameraInput.nativeElement.click();
  }
  onUploadFileChange($event: any): void {
    this.isUploading = true;
    if (!$event || $event.target.files.length === 0) {
      return;
    }
    const [file] = $event.currentTarget.files;

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      this.fileToUpload = file;
      $event.target.files = null;
      $event.target.value = null;
      this.onUploadNewDocument();
    };
  }

  onProcessPictureFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.documentPicture = file;
      };
      this.loading = true;
      this.showCrop = true;
      this.imageChangedEvent = event;
    }
  }

  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      const imageFile = new File([base64ToFile(event.base64)], this.documentPicture.name, {
        type: this.documentPicture.type
      });
      this.documentPicture = imageFile;
    }
  }
  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  onFinishCrop() {
    this.fileToUpload = this.documentPicture;

    this.onUploadNewDocument();
  }

  onCancelCrop() {
    this.previewImage = null;
    this.documentPicture = null;
    this.showCrop = false;
    this.loading = false;
  }

  onLoadImageFailed() {
    this.loading = false;
  }

  loadShipmentDocumentsPolling(
    shipmentId: string,
    url: string,
    pollingTime: number,
    pollingInterval: number
  ): Observable<any> {
    return new Observable(observer => {
      const documentId = this.getDocumentId(url);
      const timer$ = timer(pollingTime);
      const pollingData = interval(pollingInterval)
        .pipe(
          switchMap(() => this.documentsDataService.getShipmentDocuments(shipmentId)),
          takeUntil(timer$)
        )
        .subscribe(
          (shipmentDocuments: ShipmentDocument[]) => {
            this.shipmentDocumentsSubject.next(shipmentDocuments);
            const uploadedDocument = shipmentDocuments.find(
              (shipmentDocument: ShipmentDocument) =>
                documentId && shipmentDocument.id === documentId && shipmentDocument.status === 'uploaded'
            );
            if (uploadedDocument) {
              pollingData.unsubscribe();
              observer.next();
              observer.complete();
            }
          },
          (error: any) => {
            this.updateMessage(MESSAGE_ERROR_LOADING_SHIPMENT_DOCUMENTS);
            observer.error(error);
          }
        );
      return () => {
        pollingData.unsubscribe();
      };
    });
  }

  private getDocumentId(documentUrl: string): string {
    return documentUrl.split('/')[6].substring(9);
  }

  private updateMessage(message: string): void {
    this.messageSubject.next(message || null);
  }

  onUploadNewDocument(): void {
    this.loading = true;
    this.uploadingNewDocument = true;
    const extension = this.fileToUpload.name
      .split('.')
      .pop()
      .toLowerCase();
    this.documentsDataService
      .sendShipmentDocument(this.shipmentId, this.documentType, extension, this.shipmentStopId)
      .pipe(
        first(),
        switchMap((sendDocumentResponse: any) => {
          this.url = sendDocumentResponse.url;
          const newFileName = sendDocumentResponse.file;
          const file = this.fileToUpload;
          const renamedFile: File = new File([file], newFileName, { type: file.type });
          const contentType = mime.getType(extension);
          return this.fileDataService.uploadFile(renamedFile, this.url, contentType);
        }),
        switchMap((): any => {
          this.toastService.showInfo(DOCUMENT_UPLADING_MESSAGE);
          if (this.url) {
            return this.loadShipmentDocumentsPolling(this.shipmentId, this.url, 20000, 2000);
          } else {
            return of(null);
          }
        }),
        tap(() => {
          this.toastService.showSuccess(DOCUMENT_UPLOAD_SUCCESS_MESSAGE);
        }),
        catchError((error: any) => {
          this.uploadingNewDocument = false;
          this.loading = false;
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          throw error;
        }),
        finalize(() => {
          this.loading = false;
          this.fileToUpload = undefined;
          this.documentPicture = undefined;
          this.showCrop = false;
          this.uploadingNewDocument = false;
        })
      )
      .subscribe(() => {
        this.dismissBottomSheetToUpload();
      });
  }
  dismissBottomSheetToUpload(): void {
    this.bottomSheetRef.dismiss({ navigateToStops: true });
  }
}
