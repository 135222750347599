import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Action } from '../../actions/models/action.model';

export interface DialogData {
  title: string;
  greeting: string;
  infoMessage: string;
  action: Action;
  contactMessage: string;
  iconName: string;
  dialogClass?: string;
  iconBgColor?: string;
}

@Component({
  selector: 'app-information-dialog',
  templateUrl: './information-dialog.component.html',
  styleUrls: ['./information-dialog.component.scss']
})
export class InformationDialogComponent {
  constructor(
    public readonly dialogRef: MatDialogRef<InformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogData
  ) {}

  onActionBtn(): void {
    this.data?.action?.action?.();
    this.dialogRef.close();
  }
}
