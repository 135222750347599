import { Component, HostListener, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ShipmentDocument } from 'src/app/shared/documents-data/models/shipment-document';
import { DocumentsDataService } from 'src/app/shared/documents-data/documents-data.service';
import { CommonDataService } from 'src/app/shared/services/common/common-data.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ShipmentsUtilsService } from '../shared/shipments-utils.service';
import {
  STATUS_CONFIRMATION_PENDING,
  DEFAULT_ERROR_MESSAGE,
  LOAD_SIZE_FTL,
  STATUS_ASSIGNED,
  STATUS_UNLOADING
} from '../shared/constants';
import { ShipmentsActionsService } from '../shared/shipments-actions.service';
import { ShipmentsDataService } from '../shared/shipments-data.service';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';
import { Event } from 'src/app/loads-shared/shipment-data/models/event';
import { ContactDetails } from 'src/app/shared/models/contact-details';
import { InformationDialogService } from 'src/app/shared/dialog/information-dialog.service';
import { ShipmentActionsService } from 'src/app/loads-shared/shipment-actions.service';
import { Action } from 'src/app/shared/actions/models/action.model';
import { SHIPMENT_STATUSES } from 'src/app/loads-shared/shipment-data/models/shipment-status';
import { Router } from '@angular/router';
import { SHIPMENTS_ROUTE, SHIPMENTS_ROUTE_MY_SHIPMENTS } from '../shared/routes';

const SHIPMENT_DETAILS_UPDATED_TEXT = 'Shipment details updated';
const SHIPMENT_TYPE_ONGOING = 'Ongoing';
const SHIPMENT_TYPE_UPCOMING = 'Upcoming';
const DISPATCHERS = 'dispatchers';
const DOC_TYPE_PROOF_OF_DELIVERY = 'ProofOfDelivery';
const SECTION_INDEX = {
  'my-status': 0,
  documents: 1,
  details: 2
};
@Component({
  selector: 'app-shipment-detail',
  templateUrl: './shipment-detail.component.html',
  styleUrls: ['./shipment-detail.component.scss']
})
export class ShipmentDetailComponent implements OnInit {
  shipmentId: string;
  isContentReady: boolean = false;
  shipment: Shipment;
  pickupFormattedDateTime: string | Date;
  deliveryFormattedDateAndTime: string | Date;
  shipmentType: string = '';
  isConfirmationPending: boolean = false;
  shipmentDocuments: ShipmentDocument[];
  isProcessingDocuments: boolean = false;
  eventsList: Event[] = [];
  shipments: Shipment[] = [];
  ongoingShipments: Shipment[] = [];
  isOngoingFTL: boolean = false;
  loading: boolean = false;
  driverId: string;
  isPodUploaded: boolean = false;
  tabIndex = 0;

  constructor(
    private readonly location: Location,
    private readonly route: ActivatedRoute,
    private readonly shipmentsDataService: ShipmentsDataService,
    private readonly dateTimeService: DateTimeService,
    private readonly shipmentUtilsService: ShipmentsUtilsService,
    private readonly driverApiService: DriverApiService,
    private readonly shipmentsActionService: ShipmentsActionsService,
    private readonly commonService: CommonService,
    private readonly documentsDataService: DocumentsDataService,
    private readonly toastService: ToastService,
    private readonly commonDataService: CommonDataService,
    private readonly informationDialogService: InformationDialogService,
    private readonly shipmentActionsService: ShipmentActionsService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.driverId = this.driverApiService.driverID;
    this.shipmentId = this.route.snapshot.paramMap.get('id');
    if (Array.isArray(this.shipmentsDataService.shipments)) {
      this.shipments = this.shipmentsDataService?.shipments;
      this.getShipmentDetails();
    } else {
      this.loadShipments();
    }
    this.checkQueryParams();
  }
  checkQueryParams(): void {
    this.route.queryParams.subscribe(params => {
      if (params?.section) {
        this.tabIndex = SECTION_INDEX[params.section];
      }
    });
  }

  loadShipments(): void {
    this.shipmentsDataService
      .fetchMyShipments(this.driverId)
      .pipe(
        catchError((error: any) => {
          this.toastService.showError(error?.message || DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((shipments: Shipment[]) => {
        if (shipments?.length) {
          this.shipments = shipments;
          this.getShipmentDetails();
        }
      });
  }

  checkIsOnGoingFTL(): void {
    this.isOngoingFTL = this.ongoingShipments.some((shipment: Shipment) => shipment.loadSize === LOAD_SIZE_FTL);
  }

  disableChangeStatus(): boolean {
    let isUpcoming = this.shipmentType === SHIPMENT_TYPE_UPCOMING;
    let disableBtn =
      isUpcoming && (this.isOngoingFTL || (!this.isOngoingFTL && this.shipment.loadSize === LOAD_SIZE_FTL));

    return this.shipment.status === SHIPMENT_STATUSES.DELIVERED
      ? true
      : !isUpcoming || !this.ongoingShipments.length
      ? false
      : disableBtn;
  }

  navigateBack(): void {
    this.location.back();
  }

  getShipmentDetails(): void {
    this.isContentReady = false;
    this.shipmentsDataService
      .getShipmentDetails(this.shipmentId)
      .pipe(
        first(),
        tap((response: Shipment) => {
          if (response) {
            this.shipment = response;
            if (this.shipment?.pickup?.contactPhone) {
              this.shipment.pickup.contactPhone = this.commonService.formatPhoneNumber(
                this.shipment?.pickup?.contactPhone
              );
            }
            if (!Array.isArray(this.shipment?.delivery) && this.shipment?.delivery?.contactPhone) {
              this.shipment.delivery.contactPhone = this.commonService.formatPhoneNumber(
                this.shipment?.delivery?.contactPhone
              );
            }
            this.pickupFormattedDateTime = this.shipment.pickupFormattedDateTime;
            this.deliveryFormattedDateAndTime = this.shipment.deliveryFormattedDateAndTime;
            this.isConfirmationPending = this.shipment?.status === STATUS_CONFIRMATION_PENDING;
            if (!this.isConfirmationPending) {
              this.getShipmentDocuments();
            }

            this.ongoingShipments = this.shipmentUtilsService.findOngoingTrips(this.shipments);
            this.checkIsOnGoingFTL();
            this.getShipmentType();

            // filter and sort events
            let eventsList = this.shipment.events
              ?.filter((event: Event) => event.status || event.name !== SHIPMENT_DETAILS_UPDATED_TEXT)
              .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
            let eventStatus = '';
            // find first event with status and make showStatus to True
            let recentEventStatus = eventsList.find(el => el.status);
            // Only when there is status change - need to show status and for events with name
            this.eventsList = eventsList
              .reverse()
              .map((event: Event) => {
                if (!event.name) {
                  if (eventStatus !== event.status || recentEventStatus?.id === event?.id) {
                    eventStatus = event.status;
                    return { ...event, showStatus: true };
                  }
                  return event;
                } else {
                  return { ...event, showStatus: true };
                }
              })
              .reverse();
          }
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.isContentReady = true;
        })
      )
      .subscribe();
  }

  getShipmentDocuments(): void {
    this.isProcessingDocuments = true;
    this.documentsDataService
      .getShipmentDocuments(this.shipmentId)
      .pipe(
        first(),
        tap((response: ShipmentDocument[]) => {
          this.shipmentDocuments = response;
          this.isPodUploaded = this.checkPODUploaded();
        }),
        catchError(error => {
          this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
          return error;
        }),
        finalize(() => {
          this.isProcessingDocuments = false;
        })
      )
      .subscribe();
  }

  getStatusClass(): string {
    return this.shipmentUtilsService.getShipmentStatusClass(this.shipment.status);
  }

  getShipmentType(): void {
    if (this.shipment.status !== STATUS_CONFIRMATION_PENDING && this.shipment.status !== STATUS_ASSIGNED) {
      this.shipmentType = SHIPMENT_TYPE_ONGOING;
    } else {
      this.shipmentType = SHIPMENT_TYPE_UPCOMING;
    }
  }

  confirmShipment(): void {
    const driverId = this.driverApiService.driverID;
    this.shipmentsActionService.confirmShipment(this.shipment, driverId, () => this.getShipmentDetails());
  }

  onChangeShipmentStatus(): void {
    const bottonSheet = this.shipmentsActionService.openChangeShipmentStatusBottomSheet(
      this.shipment,
      true,
      (newStatus: string) => {
        if (newStatus === SHIPMENT_STATUSES.DELIVERED) {
          this.informationDialogService.openInformationDialog(
            'Great job!',
            'In order to complete your shipment process please answer few questions.',
            null,
            { action: () => this.showShipmentFeedbackDialog(this.shipment), name: 'Start' } as Action,
            null,
            null,
            'modern'
          );
        } else {
          setTimeout(() => {
            this.getShipmentDetails();
          }, 3000);
        }
        // Added timer because Once document uploaded, it takes couple of sec to add that event into shipment details
      },
      this.isPodUploaded
    );
    bottonSheet.afterDismissed().subscribe(data => {
      if (data?.navigateToDocument) {
        this.tabIndex = 1;
      }
    });
  }
  private showShipmentFeedbackDialog(shipment: Shipment): void {
    const dialogRef = this.shipmentActionsService.showShipmentFeedbackDialog(shipment);
    dialogRef.afterClosed().subscribe(() => this.showThankYouForFeedbackDialog());
  }

  private showThankYouForFeedbackDialog(): void {
    this.informationDialogService.openInformationDialog(
      'Thank you for your feedback',
      'Your shipment has been successfully completed.',
      null,
      { action: () => this.navigateToShipmentsList(), name: 'Close' } as Action,
      null,
      'checkbox-circle-line-green',
      'modern',
      'green'
    );
  }
  navigateToShipmentsList() {
    this.router.navigate([`/${SHIPMENTS_ROUTE}/${SHIPMENTS_ROUTE_MY_SHIPMENTS}`]);
  }
  onAddShipmentComment(): void {
    if (this.loading) {
      return;
    }
    this.shipmentsActionService.openAddShipmentCommentBottomSheet(this.shipment, false, () => {
      this.getShipmentDetails();
    });
  }

  onAddShipmentPhoto(): void {
    if (this.loading) {
      return;
    }
    this.shipmentsActionService.openAddShipmentPhotoBottomSheet(this.shipment, () => {
      // Added timer because Once document uploaded, it takes couple of sec to add that event into shipment details
      setTimeout(() => {
        this.getShipmentDetails();
      }, 3000);
    });
  }

  getContact(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.commonDataService
      .getUserById(this.shipment.companyUserId, DISPATCHERS)
      .pipe(
        first(),
        map((response: ContactDetails) => {
          if (response) {
            window.open(`tel:${response.phoneNumber}`, '_self');
          }
        }),
        finalize(() => {
          this.loading = false;
        }),
        catchError(error => {
          this.toastService.showError(error?.message || DEFAULT_ERROR_MESSAGE);
          return error;
        })
      )
      .subscribe();
  }
  tabChanged(event): void {
    this.tabIndex = event.index;
    if (event.index === 1) {
      this.getShipmentDocuments();
    }
  }
  checkPODUploaded(): boolean {
    const events = this.shipment?.events;
    const unloadingEvent = events.find(event => event.status === STATUS_UNLOADING);
    if (unloadingEvent) {
      const podDocument = this.shipmentDocuments.find(doc => doc.type === DOC_TYPE_PROOF_OF_DELIVERY);
      if (!podDocument) {
        return false;
      }
      if (podDocument && podDocument?.createdDate > unloadingEvent.date) {
        return true;
      }
    } else {
      return false;
    }
  }
}
