import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { SubSink } from 'subsink';
import { DriverQualificationService } from '../service/driver-qualification.service';
import { first } from 'rxjs/internal/operators/first';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ErrorModel } from 'src/app/shared/models/error.model';
import { DriverApiService } from 'src/app/shared/services/driver-api.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { map } from 'rxjs/internal/operators/map';
import { Store } from '@ngxs/store';
import { NotificationStateModel, RegistrationState } from 'src/app/registration/state/registration.state';
import { DQF_DOC_STATUS, ONTARIO_DRIVING_ABSTRACT } from '../constants';
import { DRIVER_DOC_CONTEXT_INITIAL_DQF } from '../constants';
import { of } from 'rxjs';

const REPORT_TYPE = ONTARIO_DRIVING_ABSTRACT;
const ACTION_UPLOAD_DOCUMENT = 'Upload Document';
const UPLOADED_STATUS = 'uploaded';
const ACTION_DELETE_STATUS = 'Deleted Document';
const UPLOADED_DOCUMENT = 'Successfully uploaded document';
const DELETED_DOCUMENT = 'Successfully deleted document';
const REQUEST_SUCCESS = 'Successfully sent the request';
const DISABLE_TOP_LOADING_BAR = true;

@Component({
  selector: 'app-dqf-ontario-cvor-abstract',
  templateUrl: './dqf-ontario-cvor-abstract.component.html',
  styleUrls: ['./dqf-ontario-cvor-abstract.component.scss']
})
export class DqfOntarioCvorAbstractComponent implements OnInit, OnDestroy {
  form: FormGroup;
  formUploadFiles = { form: null };
  fileInfo: { id: string; fileName: string; generationDate: string };
  subSink = new SubSink();
  isContentReady: boolean = false;
  documentStatus: string;
  documentType = REPORT_TYPE;

  constructor(
    private readonly location: Location,
    private readonly formBuilder: FormBuilder,
    private readonly driverQualificationService: DriverQualificationService,
    private readonly driverApiService: DriverApiService,
    private readonly dateTimeService: DateTimeService,
    private readonly toastService: ToastService,
    private readonly registrationService: RegistrationService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.getDocumentDqfStatus();
    this.loadDriverDocuments();
    this.form = this.createForm();
    this.formUploadFiles = {
      form: this.form
    };
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  backAction(): void {
    this.location.back();
  }
  getDocumentDqfStatus(): void {
    const document = this.driverQualificationService.getDqfDocumentFromAttachedDocuments(REPORT_TYPE);
    if (!document) {
      return;
    }

    this.documentStatus = document.status;
  }
  uploadDocument(): void {
    this.isContentReady = false;
    const model = this.driverQualificationService.prepareUploadDocumentSaveModel(
      this.form.value,
      REPORT_TYPE,
      DRIVER_DOC_CONTEXT_INITIAL_DQF,
      this.driverQualificationService.dqfId
    );
    if (model && this.formUploadFiles.form.valid) {
      this.subSink.sink = this.driverQualificationService
        .uploadDqfDocument(model, this.form.value.file, DISABLE_TOP_LOADING_BAR)
        .pipe(
          first(),
          catchError((error: any) => {
            this.formUploadFiles.form.get('file').patchValue(null);
            this.formUploadFiles = { ...this.formUploadFiles };
            this.isContentReady = true;
            return of(false);
          })
        )
        .subscribe(() => {
          const docModel = {
            attachedDocument: {
              type: REPORT_TYPE,
              status: DQF_DOC_STATUS.DRIVER_REVIEWED
            }
          };
          this.updateDqfDocStatus(docModel, UPLOADED_DOCUMENT);
        });
    }
  }

  requestCarrierToUploadDocument(): void {
    const docModel = {
      attachedDocument: {
        type: REPORT_TYPE,
        status: DQF_DOC_STATUS.CARRIER_UPLOAD_REQUESTED
      }
    };
    this.updateDqfDocStatus(docModel, REQUEST_SUCCESS);
  }

  deleteDocument(): void {
    this.isContentReady = false;
    if (this.formUploadFiles.form?.value?.file?.id)
      this.subSink.sink = this.driverQualificationService
        .deleteDqfDocument(this.formUploadFiles.form?.value?.file?.id, DISABLE_TOP_LOADING_BAR)
        .subscribe(
          () => {
            this.toastService.showSuccess(DELETED_DOCUMENT);
            this.form.reset();
            this.formUploadFiles.form.get('file').patchValue(null);
            this.isContentReady = true;
          },
          error => {
            this.isContentReady = true;

            return of(false);
          }
        );
  }

  loadDriverDocuments(): void {
    this.isContentReady = false;
    this.subSink.sink = this.registrationService
      .loadDriver(DISABLE_TOP_LOADING_BAR)
      .pipe(map(() => this.store.selectSnapshot(RegistrationState.notification)))
      .subscribe((notificationStateModel: NotificationStateModel) => {
        if (notificationStateModel && notificationStateModel.notifications) {
          this.isContentReady = true;
          const filteredValue = notificationStateModel.notifications.filter(
            notification =>
              notification.type === REPORT_TYPE &&
              notification.status == UPLOADED_STATUS &&
              notification?.dqfId === this.driverQualificationService.dqfId
          );

          if (filteredValue.length) {
            this.driverQualificationService.bindDriverDocument.call(this, filteredValue[0], this.formUploadFiles);
          }
        }
      });
  }

  updateDqfDocStatus(docModel: any, successMessage: string): void {
    this.subSink.sink = this.driverQualificationService
      .updateDqfStatus(this.driverQualificationService.dqfId, docModel)
      .subscribe(() => {
        this.isContentReady = true;
        this.toastService.showSuccess(successMessage);
        this.backAction();
      });
  }

  private createForm(): FormGroup {
    return this.formBuilder.group({
      generationDate: ['', Validators.required],
      file: ['', Validators.required]
    });
  }
}
