import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccidentRecord } from 'src/app/shared/models/accident-record.model';

const DELETE_MESSAGE = 'Are you sure you want to delete this accident record?';
import { ACCIDENT_NATURE } from '../shared/data-const/accidentNature';

@Component({
  selector: 'app-accident-view',
  templateUrl: './accident-view.component.html',
  styleUrls: ['./accident-view.component.scss']
})
export class AccidentRecordViewComponent implements OnInit {
  accidentNatures = ACCIDENT_NATURE;
  @Input() model: AccidentRecord;
  @Output() updateAccidentPressed: EventEmitter<AccidentRecord> = new EventEmitter<AccidentRecord>();
  @Output() deleteAccidentPressed: EventEmitter<AccidentRecord> = new EventEmitter<AccidentRecord>();

  constructor() {}

  ngOnInit() {}

  onUpdateAccident() {
    this.updateAccidentPressed.emit(this.model);
  }

  onDeleteAccident() {
    if (confirm(DELETE_MESSAGE)) {
      this.deleteAccidentPressed.emit(this.model);
    }
  }

  getAccidentTitle(model) {
    if (model.noAccidents) {
      return 'No accidents in the last 3 years';
    }

    return '-';
  }
  getAccidentNatureValue(value) {
    let name = '';
    this.accidentNatures.forEach(accidentNature => {
      if (accidentNature.value == value) {
        name = accidentNature.name;
      }
    });
    return name;
  }
}
