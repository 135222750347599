<div class="header-navigation" fxLayout fxLayoutAlign="center">
  <span>Trips</span>
</div>
<div *ngIf="!loading">
  <div class="margin-top-md margin-left-sm padding-bottom-sm title-header">Ongoing</div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="ongoingShipment">
    <app-shipment-item
      (click)="onNavigateToLoadDetails(ongoingShipment.id)"
      [shipment]="ongoingShipment"
      [driverId]="driverId"
    >
    </app-shipment-item>
    <mat-divider class="list-item-seperator"></mat-divider>
  </ng-container>
  <app-no-shipment *ngIf="!ongoingShipment" [message]="noShipment"> </app-no-shipment>

  <div class="margin-top-md margin-left-sm padding-bottom-sm title-header">
    Up coming <span>({{ upcomingShipmentCount }})</span>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="upcomingShipment">
    <div *ngFor="let shipment of upcomingShipment">
      <app-shipment-item
        (click)="onNavigateToLoadDetails(shipment.id)"
        [shipment]="shipment"
        [driverId]="driverId"
        [isTrip]="true"
      >
      </app-shipment-item>
      <mat-divider class="list-item-seperator"></mat-divider>
    </div>
  </ng-container>

  <app-no-shipment *ngIf="!upcomingShipment" [message]="noShipment"> </app-no-shipment>
</div>
