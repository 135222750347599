<div class="logo">
  <img src="assets/images/fleetoperate-logo.png" />
</div>

<div class="title">Unsubscription <span class="failure">Unsuccessful </span></div>

<p fxFlex>
  There was a problem and we were not able to remove you from your mailing list. <br><br> Please contact us at <a href="mailto:{{contactEmail}}">{{contactEmail}}</a>
</p>

<div>
  Follow us<br><br>
  <a href="{{facebookURL}}"><img src="assets/images/facebook.png" class="social-media-imgs" /></a>
  <a href="{{twitterURL}}"><img src="assets/images/twitter.png" class="social-media-imgs" /></a>
  <a href="{{linkedInURL}}"><img src="assets/images/linkedin.png" class="social-media-imgs" /></a>
</div>

<div class="copyright">
  <hr> <br>
  <span>&copy; {{copyrightYear}} All Rights Reserved FleetOperate</span>
</div>
