import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/internal/operators/map';
import { DriverQualificationService } from 'src/app/driver-qualification-files/service/driver-qualification.service';
import { ViewDocument } from '../../documents-data/models/view-document.model';
import { DOCUMENT_TYPES } from '../../models/dqf-document-types';
import { Response } from '../../services/response';
const DISABLE_TOP_LOADING_BAR = true;

@Component({
  selector: 'app-view-uploaded-document',
  templateUrl: './view-uploaded-document.component.html',
  styleUrls: ['./view-uploaded-document.component.scss']
})
export class ViewUploadedDocumentComponent implements OnInit {
  @Input() dqfDocumentId: string;
  @Input() isDocumentViewPage: boolean;
  reportTypes = DOCUMENT_TYPES;
  previewImage: string;
  errorMessage: string;
  fileType: string;
  safeUrl: SafeUrl;
  heading = null;
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly driverQualificationService: DriverQualificationService,
    private location: Location,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    const documentId = this.activatedRoute.snapshot.paramMap.get('document-id') || this.dqfDocumentId;
    if (documentId) {
      this.driverQualificationService
        .getDqfDocument(documentId, DISABLE_TOP_LOADING_BAR)
        .pipe(map((response: Response) => response.data as ViewDocument))
        .subscribe((response: ViewDocument) => {
          if (response) {
            const filteredValue = this.reportTypes.filter(e => e.reportType === response.type);

            if (filteredValue.length) {
              this.heading = filteredValue[0].displayName;
            }
            const url = `${response.fileUrl}`;
            const parentUrl = url
              ?.split('?')[0]
              ?.split('?')[0]
              ?.split('/');

            if (parentUrl && parentUrl[parentUrl.length - 1]?.split('.')[1] === 'pdf') {
              this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.fileUrl + '#toolbar=0');
            } else {
              this.previewImage = response.fileUrl;
            }
          }
        });
    }
  }

  onNavigateBack(): void {
    this.location.back();
  }
}
