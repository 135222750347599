export class License {
  id?: string;
  licenseType?: string;
  expiryDate?: string;
  issueDate?: string;
  licenseNumber?: string;
  issuingCountry?: string;
  issuingProvince?: string;
  hasAirBreakEndorsment?: boolean;
  hasDeniedLicense?: boolean;
  deniedLicenseExplanation?: string;
  hasSuspendedLicense?: boolean;
  suspendedLicenseExplanation?: string;
  status?: string;
  generationDate?: string;
  frontScanImageExtension?: string;
  backScanImageExtension?: string;
  frontScanImageUrl?: string;
  frontScanImageFile?: string;
  backScanImageUrl?: string;
  backScanImageFile?: string;

  constructor(data: License) {
    this.licenseType = data?.licenseType || null;
    this.issueDate = data?.issueDate || null;
    this.expiryDate = data?.expiryDate || null;
    this.licenseNumber = data?.licenseNumber || null;
    this.issuingCountry = data?.issuingCountry || null;
    this.issuingProvince = data?.issuingProvince || null;
    this.hasAirBreakEndorsment = data?.hasAirBreakEndorsment || false;
    this.hasDeniedLicense = data?.hasDeniedLicense || false;
    this.deniedLicenseExplanation = data?.deniedLicenseExplanation || '';
    this.hasSuspendedLicense = data?.hasSuspendedLicense || false;
    this.suspendedLicenseExplanation = data?.suspendedLicenseExplanation || '';
  }
}
