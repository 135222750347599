import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fleetoperate-successful-unsubscribe-email',
  templateUrl: './successful-unsubscribe-email.component.html',
  styleUrls: ['./successful-unsubscribe-email.component.scss']
})
export class SuccessfulUnsubscribeEmailComponent implements OnInit {

  copyrightYear = 2020;
  linkedInURL: string = "https://linkedin.com/company/fleetoperate";
  facebookURL: string = "https://www.facebook.com/FleetOperate/";
  twitterURL: string = "https://twitter.com/fleetoperate";
  contactEmail: string = "help@fleetoperate.com";
  constructor() { }

  ngOnInit() {
  }

}
