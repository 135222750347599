import { Injectable, Inject } from '@angular/core';
import { DocumentsDataModule } from './documents-data.module';
import { HttpHeaders } from '@angular/common/http';
import { Response } from '../services/response';
import { FleetoperateApiService } from '../services/fleetoperate-api.service';
import { SendDocumentRequest } from './models/send-document-request';
import { Observable } from 'rxjs/internal/Observable';

const PATH_DRIVERS = '/drivers';
const PATH_DOCUMENTS_TYPES = '/documents';
const PATH_DOCUMENTS_TYPES_DRIVER = PATH_DOCUMENTS_TYPES + '/driver';
const PATH_DRIVERS_DOCUMENT = '/drivers/{id}/documents';
const PATH_DOCUMENTS_TYPES_SHIPMENT = PATH_DOCUMENTS_TYPES + '/shipment';
const PATH_SHIPMENTS_DOCUMENTS = '/shipments/{id}/documents';
const PATH_SHIPMENTS_DOCUMENT = '/shipments/{id}/documents/{documentId}';
const PATH_JOBS_DOCUMENTS = '/jobs/{id}/documents';
const PATH_JOBS_DOCUMENT = '/jobs/{id}/documents/{documentId}';
const PATH_DOCUMENTS = PATH_DRIVERS + '/{id}/documents';
const PATH_DOCUMENTS_DOCUMENT = PATH_DOCUMENTS + '/{documentId}';

const PATH_CARRIER_COMPANY = '/carriercompanies';
const PATH_CARRIER_COMPANY_LOGO = PATH_CARRIER_COMPANY + '/{carrierCompanyId}?include=companyLogo';
const PATH_SHIPMENT_DOCUMENT_TYPE_LOOKUP = '/documenttypes?type=shipment';
export const CONTENT_TYPE_JPEG = 'image/jpeg';

@Injectable({
  providedIn: DocumentsDataModule
})
export class DocumentsApiService {
  constructor(private readonly api: FleetoperateApiService, @Inject('apiUrl') private apiUrl: string) {}

  getDriverDocumentTypes(): Observable<Response> {
    const path = PATH_DOCUMENTS_TYPES_DRIVER;
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  sendDriverDocument(driverID: string, sendDocumentRequest: SendDocumentRequest): Observable<Response> {
    const path = PATH_DRIVERS_DOCUMENT.replace('{id}', driverID);
    return this.api.post(`${this.apiUrl}${path}`, sendDocumentRequest);
  }

  uploadDriverDocument(file: object, url: string): Observable<Response> {
    const headers = {
      'Content-Type': 'application/pdf'
    };

    const httpOptions = {
      headers: new HttpHeaders(headers)
    };

    return this.api.put(`${url}`, file);
  }

  getShipmentDocumentTypes(): Observable<Response> {
    const path = PATH_DOCUMENTS_TYPES_SHIPMENT;
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  sendShipmentDocument(shipmentID: string, sendDocumentRequest: SendDocumentRequest): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENTS.replace('{id}', shipmentID);
    return this.api.post(`${this.apiUrl}${path}`, sendDocumentRequest);
  }

  getShipmentDocuments(shipmentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENTS.replace('{id}', shipmentId);
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  getShipmentDocument(shipmentId: string, documentId: string, isTracking?: boolean): Observable<Response> {
    let path = PATH_SHIPMENTS_DOCUMENT.replace('{id}', shipmentId).replace('{documentId}', documentId);
    if (isTracking) {
      path = path + '?type=Tracking';
    }
    return this.api.get(`${this.apiUrl}${path}`, {});
  }
  getShipmentDocumentTypesLookup(): Observable<Response> {
    return this.api.get(`${this.apiUrl}${PATH_SHIPMENT_DOCUMENT_TYPE_LOOKUP}`, {});
  }
  deleteShipmentDocument(shipmentId: string, documentId: string): Observable<Response> {
    const path = PATH_SHIPMENTS_DOCUMENT.replace('{id}', shipmentId).replace('{documentId}', documentId);
    return this.api.delete(`${this.apiUrl}${path}`);
  }

  sendJobDocument(jobId: string, sendDocumentRequest: SendDocumentRequest): Observable<Response> {
    const path = PATH_JOBS_DOCUMENTS.replace('{id}', jobId);
    return this.api.post(`${this.apiUrl}${path}`, sendDocumentRequest);
  }

  getJobDocuments(jobId: string): Observable<Response> {
    const path = PATH_JOBS_DOCUMENTS.replace('{id}', jobId);
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  getJobDocument(shipmentId: string, documentId: string): Observable<Response> {
    const path = PATH_JOBS_DOCUMENT.replace('{id}', shipmentId).replace('{documentId}', documentId);
    return this.api.get(`${this.apiUrl}${path}`, {});
  }

  deleteJobDocument(shipmentId: string, documentId: string): Observable<Response> {
    const path = PATH_JOBS_DOCUMENT.replace('{id}', shipmentId).replace('{documentId}', documentId);
    return this.api.delete(`${this.apiUrl}${path}`);
  }

  getDqfDocument(driverId: string, documentId: string, isLoadingFalse: boolean = false): Observable<Response> {
    const path = PATH_DOCUMENTS_DOCUMENT.replace('{id}', driverId).replace('{documentId}', documentId);
    return this.api.get(`${this.apiUrl}${path}`, {}, isLoadingFalse);
  }

  deleteDqfDocument(driverId: string, documentId: string, isLoadingFalse: boolean = false): Observable<Response> {
    const path = PATH_DOCUMENTS_DOCUMENT.replace('{id}', driverId).replace('{documentId}', documentId);
    return this.api.delete(`${this.apiUrl}${path}`, isLoadingFalse);
  }
  updateDocument(
    driverId: string,
    documentId: string,
    saveModel: any,
    isLoadingFalse: boolean = false
  ): Observable<Response> {
    const path = PATH_DOCUMENTS_DOCUMENT.replace('{id}', driverId).replace('{documentId}', documentId);
    return this.api.patch(`${this.apiUrl}${path}`, saveModel, undefined, isLoadingFalse);
  }
  getCarrierCompanyLogo(carrierCompanyId: string): Observable<Response> {
    const path = PATH_CARRIER_COMPANY_LOGO.replace('{carrierCompanyId}', carrierCompanyId);
    return this.api.get(`${this.apiUrl}${path}`, {}, true);
  }
}
