import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { first, tap, catchError, finalize } from 'rxjs/operators';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { LicenseDataService } from '../license-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cypress-license-scan',
  templateUrl: './cypress-license-scan.component.html',
  styleUrls: ['./cypress-license-scan.component.scss']
})
export class CypressLicenseScanComponent implements OnInit {
  frontImg: any;
  backImg: any;
  acceptTypes;
  isApiLoading = false;
  licenseUpload;
  constructor(
    private readonly licenseDataService: LicenseDataService,
    private readonly registrationService: RegistrationService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.licenseUpload = this.router.url.includes('upload-license');
    if(this.licenseUpload){
      this.acceptTypes ='application/pdf';
    } else {
       this.acceptTypes = 'image/png';
    }
  }

  uploadFrontImg(event: any) {
    const file = event.target.files[0];
    if( this.licenseUpload ){
      const imageFile = new File([file], 'front-license', { type: 'pdf' }); 
      this.frontImg = imageFile;
    } else {
      const imageFile = new File([file], 'front-license', { type: 'png' });
      this.frontImg = imageFile;
    }
  }

  uploadBackImg(event: any) {
    const file = event.target.files[0];
    if( this.licenseUpload ){
      const imageFile = new File([file], 'back-license', { type: 'pdf' }); 
      this.backImg = imageFile;
    } else {
      const imageFile = new File([file], 'back-license', { type: 'png' });
      this.backImg = imageFile;
    }
  }

  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  saveLicense(): void {
    if (this.frontImg && this.backImg) {
      this.isApiLoading = true;
      const frontLicense = this.frontImg;
      const backLicense = this.backImg;

      const saveModel = {
        frontScanImageExtension: frontLicense.type,
        backScanImageExtension: backLicense.type
      };
      this.licenseDataService
        .uploadLicense(saveModel)
        .pipe(
          first(),
          tap(response => {
            if (response) {
              const licenseResponse = response;

              this.licenseDataService
                .sendImageFiles(frontLicense, backLicense, response)
                .pipe(
                  first(),
                  tap(response => {
                    if (licenseResponse?.frontScanImageUrl) {
                      licenseResponse.backScanImagePresent = true;
                    }
                    if (licenseResponse?.backScanImageUrl) {
                      licenseResponse.frontScanImagePresent = true;
                    }
                    this.registrationService.updateLicenseStore(licenseResponse);
                    this.onNavigateToProfile();
                  }),
                  catchError(error => {
                    return of(false);
                  }),
                  finalize(() => {
                    this.isApiLoading = false;
                  })
                )
                .subscribe();
            }
          }),
          catchError(error => {
            return of(false);
          })
        )
        .subscribe();
    }
  }
}
