<div class="header-navigation-back">
  <mat-icon (click)="onNavigateBack()">arrow_back</mat-icon>
  <span fxFlex class="title">Shipment Documents</span>
</div>
<div *ngIf="errorMessage" fxFlexAlign="center" class="margin-top-md">{{ errorMessage }}</div>
<ng-container [ngSwitch]="viewType">
  <ng-container *ngSwitchCase="'document-list-view'">
    <div
      *ngIf="!loading && (!shipmentDocuments || shipmentDocuments.length === 0)"
      fxFlexAlign="start"
      class="no-documents margin-top-sm padding-left-sm"
    >
      No documents uploaded
    </div>
    <div *ngIf="!driverHistory" fxLayout fxLayoutAlign="center">
      <button
        mat-raised-button
        class="full-width upload-documents margin-top-sm margin-left-sm margin-right-sm"
        [disabled]="loading || shipmentDocuments.length >= 3"
        (click)="onAddNewDocument()"
      >
        +UPLOAD DOCUMENTS
      </button>
    </div>
    <mat-list
      *ngIf="!loading && shipmentDocuments && shipmentDocuments.length > 0"
      class="margin-left-sm margin-right-sm"
    >
      <mat-divider></mat-divider>
      <mat-list-item
        *ngFor="let shipmentDocument of shipmentDocuments"
        (click)="onNavigateToShipmentDocument(shipmentDocument.id)"
      >
        <p mat-line>{{ shipmentDocument.type }}</p>
        <p mat-line>{{ shipmentDocument.createdDate }}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </ng-container>
  <ng-container *ngSwitchCase="'file-upload-view'">
    <div fxLayout fxLayoutAlign="center" class="margin-top-sm margin-bottom-md">
      <button mat-raised-button [disabled]="loading" (click)="onCancelNewDocument()">
        Cancel
      </button>
      <button
        mat-raised-button
        color="primary"
        class="margin-left-md"
        [disabled]="!file || loading"
        (click)="onUploadNewDocument()"
      >
        Upload
      </button>
    </div>
    <div class="margin-bottom-sm margin-left-md">Proof of Delivery</div>
    <app-file-upload
      [reset]="resetFileSelection$"
      [disabled]="loading"
      (fileChange)="onFileChange($event)"
    ></app-file-upload>
  </ng-container>
</ng-container>
