import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { MaterialModule } from './shared/material/material.module';
import { AppState } from './shared/state/app.state';
import { AuthenticationModule } from './authentication/authentication.module';
import { FrameModule } from './frame/frame.module';
import { environment } from 'src/environments/environment';
import { RegistrationModule } from 'src/app/registration/registration.module';
import { DriverDashboardModule } from './driver-dashboard/driver-dashboard.module';
import { NotificationsModule } from './notifications/notifications.module';
import { AccountSettingsModule } from './account-settings/account-settings.module';
import { ForgotPasswordModule } from './forgot-password/forgot-password.module';
import { ResetPasswordModule } from './reset-password/reset-password.module';
import { ProfileModule } from './profile/profile.module';
import { DocumentsDataModule } from './shared/documents-data/documents-data.module';
import { MatIconRegistry } from '@angular/material/icon';
import { FileDataModule } from './shared/file-data/file-data.module';
import { IdentityCheckModule } from './identity-check/identity-check.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { UnsubscribeEmailModule } from './unsubscribe-email/unsubscribe-email.module';
import { EnvironmentRibbonModule } from './shared/environment-ribbon/environment-ribbon.module';
import { ReferAFriendModule } from './refer-a-friend/refer-a-friend.module';
import { HelpModule } from './help/help.module';
import { AppService } from './app.service';
import { HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './auth-interceptor.service';
import { HttpClientModule } from '@angular/common/http';
import { XsrfInterceptorService } from './xsrf-interceptor.service';
import { TripsModule } from './trips/trips.module';
import { ShipmentsModule } from './shipments/shipments.module';
import { VehicleModule } from './vehicles/vehicles.module';
import { DriverQualificationFilesModule } from './driver-qualification-files/driverQualificationFiles.module';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { LicenseScanModule } from './new-license-scan/license-scan.module';
import * as Sentry from '@sentry/angular';
import * as Hammer from 'hammerjs';

export const interceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptorService, multi: true }
];

const sentryErrorHandler = environment?.featureFlags?.enableSentry
  ? [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler()
      }
    ]
  : [];

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL }
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([AppState], { developmentMode: !environment?.featureFlags?.production }),
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManagerId
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    CoreModule,
    SharedModule,
    MaterialModule,
    FrameModule,
    RegistrationModule,
    DriverQualificationFilesModule,
    DriverDashboardModule,
    NotificationsModule,
    AccountSettingsModule,
    ReferAFriendModule,
    AuthenticationModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    ProfileModule,
    DocumentsDataModule,
    FileDataModule,
    UnsubscribeEmailModule,
    IdentityCheckModule,
    OnboardingModule,
    EnvironmentRibbonModule,
    HelpModule,
    AppRoutingModule,
    GoogleTagManagerModule,
    HttpClientModule,
    TripsModule,
    ShipmentsModule,
    VehicleModule,
    HammerModule,
    HttpClientXsrfModule.withOptions({
      cookieName: environment.xsrfCookieName,
      headerName: environment.xsrfHeadername
    }),
    LicenseScanModule
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment?featureFlags?.production })
  ],
  providers: [
    {
      provide: 'apiUrl',
      useValue: environment.apiUrl
    },
    {
      provide: 'appName',
      useValue: 'driver'
    },
    {
      provide: 'environmentData',
      useValue: environment
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initAppService,
      deps: [AppService],
      multi: true
    },
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    sentryErrorHandler,
    interceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('loads', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/loads.svg'));
    iconRegistry.addSvgIcon('alert', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert.svg'));
    iconRegistry.addSvgIcon('alert-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert-circle.svg'));
    iconRegistry.addSvgIcon(
      'alert-circle-red',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alert-circle-red.svg')
    );
    iconRegistry.addSvgIcon('check-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/check-circle.svg'));
    iconRegistry.addSvgIcon('priority', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/priority.svg'));
    iconRegistry.addSvgIcon('time', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/time.svg'));
    iconRegistry.addSvgIcon(
      'onboarding-screen-1',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/onboarding-screen-one.svg')
    );
    iconRegistry.addSvgIcon(
      'onboarding-screen-2',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/onboarding-screen-two.svg')
    );
    iconRegistry.addSvgIcon(
      'onboarding-screen-3',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/onboarding-screen-three.svg')
    );
    iconRegistry.addSvgIcon(
      'onboarding-screen-4',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/onboarding-screen-four.svg')
    );
    iconRegistry.addSvgIcon(
      'onboarding-screen-5',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/onboarding-screen-five.svg')
    );

    iconRegistry.addSvgIcon('locked', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/locked.svg'));
    iconRegistry.addSvgIcon(
      'profile-status',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/profile-status.svg')
    );
    iconRegistry.addSvgIcon('trips', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trips.svg'));
    iconRegistry.addSvgIcon('right-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/right-arrow.svg'));
    iconRegistry.addSvgIcon('left-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/left-arrow.svg'));
    iconRegistry.addSvgIcon('locked-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/lock.svg'));
    iconRegistry.addSvgIcon('timer', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/timer.svg'));
    iconRegistry.addSvgIcon('white-timer', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/white-timer.svg'));
    iconRegistry.addSvgIcon('tick-checked', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/tick-checked.svg'));
    iconRegistry.addSvgIcon('delete-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete-icon.svg'));
    iconRegistry.addSvgIcon(
      'icon-file-black',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-file-black.svg')
    );
    iconRegistry.addSvgIcon(
      'icon-file-blue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-file-blue.svg')
    );
    iconRegistry.addSvgIcon(
      'upload-file-tree',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/upload-file-tree.svg')
    );
    iconRegistry.addSvgIcon(
      'add-circle-outline',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-circle-outline.svg')
    );
    iconRegistry.addSvgIcon('pencil-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pencil-icon.svg'));
    iconRegistry.addSvgIcon('edit-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-icon.svg'));
    iconRegistry.addSvgIcon(
      'delete-outlined',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete-outlined.svg')
    );
    iconRegistry.addSvgIcon('pencil-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pencil-icon.svg'));
    iconRegistry.addSvgIcon('edit-black', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-black.svg'));
    iconRegistry.addSvgIcon(
      'delete-sweep-outlined',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete-sweep-outlined.svg')
    );
    iconRegistry.addSvgIcon(
      'yellow-warning-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/yellow-warning-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'dashboard-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/dashboard-icon.svg')
    );
    iconRegistry.addSvgIcon('profile-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/profile-icon.svg'));
    iconRegistry.addSvgIcon('jobs-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/jobs-icon.svg'));
    iconRegistry.addSvgIcon('truck-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-icon.svg'));
    iconRegistry.addSvgIcon('gear-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/gear-icon.svg'));
    iconRegistry.addSvgIcon('menu-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu-icon.svg'));
    iconRegistry.addSvgIcon(
      'add-plus-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add-plus-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'accident-record-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/accident-record-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'driving-exp-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/driving-exp-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'emp-history-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/emp-history-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'license-info-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/license-info-icon.svg')
    );
    iconRegistry.addSvgIcon('on-duty-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/on-duty-icon.svg'));
    iconRegistry.addSvgIcon(
      'profile-info-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/profile-info-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'residence-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/residence-icon.svg')
    );
    iconRegistry.addSvgIcon(
      'white-license-info-icon',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/white-license-info-icon.svg')
    );
    iconRegistry.addSvgIcon('eye-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-line.svg'));
    iconRegistry.addSvgIcon('eye-off-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/eye-off-line.svg'));
    iconRegistry.addSvgIcon('arrow-down-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-down.svg'));
    iconRegistry.addSvgIcon(
      'calendar-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar-line.svg')
    );
    iconRegistry.addSvgIcon('crossed', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/crossed.svg'));
    iconRegistry.addSvgIcon(
      'checkbox-circle-line-green',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/checkbox-circle-line-green.svg')
    );
    iconRegistry.addSvgIcon(
      'checkbox-circle-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/checkbox-circle-line.svg')
    );
    iconRegistry.addSvgIcon(
      'error-warning-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/error-warning-line.svg')
    );
    iconRegistry.addSvgIcon('folder-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/folder-icon.svg'));
    iconRegistry.addSvgIcon('car-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/car-icon.svg'));
    iconRegistry.addSvgIcon('search-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/search-icon.svg'));
    iconRegistry.addSvgIcon('zoom-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoom-icon.svg'));
    iconRegistry.addSvgIcon(
      'criminal-face',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/criminal-face.svg')
    );
    iconRegistry.addSvgIcon('flask-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/flask-icon.svg'));
    iconRegistry.addSvgIcon('medical-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/medical-icon.svg'));
    iconRegistry.addSvgIcon('steer-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/steer-icon.svg'));
    iconRegistry.addSvgIcon('ball-pen', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ball-pen.svg'));
    iconRegistry.addSvgIcon('health-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/health-icon.svg'));
    iconRegistry.addSvgIcon(
      'magnifying-glass',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/magnifying-glass.svg')
    );
    iconRegistry.addSvgIcon('filter', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter.svg'));
    iconRegistry.addSvgIcon('sort', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/sort.svg'));
    iconRegistry.addSvgIcon('shipments', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/shipments.svg'));
    iconRegistry.addSvgIcon(
      'icon-bg-round',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bg-round.svg')
    );
    iconRegistry.addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/location.svg'));
    iconRegistry.addSvgIcon('weight', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/weight.svg'));
    iconRegistry.addSvgIcon('trips-grey', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trips-grey.svg'));
    iconRegistry.addSvgIcon('ellipses', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ellipses.svg'));
    iconRegistry.addSvgIcon('camera', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/camera.svg'));
    iconRegistry.addSvgIcon('phonecall', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/phonecall.svg'));
    iconRegistry.addSvgIcon('comments', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/comments.svg'));
    iconRegistry.addSvgIcon(
      'location-mark',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/location-mark.svg')
    );
    iconRegistry.addSvgIcon('weight-scale', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/weight-scale.svg'));
    iconRegistry.addSvgIcon('pin-distance', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pin-distance.svg'));
    iconRegistry.addSvgIcon(
      'arrow-up-down-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow-up-down-line.svg')
    );
    iconRegistry.addSvgIcon(
      'money-dollar-circle-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/money-dollar-circle-line.svg')
    );
    iconRegistry.addSvgIcon('inbox-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/inbox-line.svg'));
    iconRegistry.addSvgIcon('truck-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-line.svg'));
    iconRegistry.addSvgIcon('ruler-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ruler-line.svg'));
    iconRegistry.addSvgIcon(
      'temp-cold-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/temp-cold-line.svg')
    );
    iconRegistry.addSvgIcon('tick-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/tick-blue.svg'));
    iconRegistry.addSvgIcon('files', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/files.svg'));
    iconRegistry.addSvgIcon('xls', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/xls.svg'));
    iconRegistry.addSvgIcon('download', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/download.svg'));
    iconRegistry.addSvgIcon('document', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/document.svg'));
    iconRegistry.addSvgIcon('edit-filled', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit-filled.svg'));
    iconRegistry.addSvgIcon('distance', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/distance-icon.svg'));
    iconRegistry.addSvgIcon(
      'weight-load',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/weight-load-icon.svg')
    );
    iconRegistry.addSvgIcon('personnel', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/personnel-icon.svg'));
    iconRegistry.addSvgIcon('software', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/software-icon.svg'));
    iconRegistry.addSvgIcon('thumb-up', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumb-up-icon.svg'));
    iconRegistry.addSvgIcon('thumb-down', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/thumb-down-icon.svg'));
    iconRegistry.addSvgIcon('history-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/history-line.svg'));
    iconRegistry.addSvgIcon(
      'greater-than-arrow',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/greater-than-arrow.svg')
    );
    iconRegistry.addSvgIcon(
      'calendar-tick',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar-tick.svg')
    );
    iconRegistry.addSvgIcon('bill-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bill-line.svg'));
    iconRegistry.addSvgIcon('bill-fill', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bill-fill.svg'));
    iconRegistry.addSvgIcon(
      'passport-line',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/passport-line.svg')
    );
    iconRegistry.addSvgIcon(
      'passport-fill',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/passport-fill.svg')
    );
    iconRegistry.addSvgIcon('pr-visa-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pr-visa-line.svg'));
    iconRegistry.addSvgIcon('pr-visa-fill', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pr-visa-fill.svg'));
    iconRegistry.addSvgIcon('truck', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck.svg'));
    iconRegistry.addSvgIcon('trailer', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/trailer.svg'));
    iconRegistry.addSvgIcon('replace', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/replace.svg'));
    iconRegistry.addSvgIcon('collapse-down-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/collapse-down-arrow.svg'));
    iconRegistry.addSvgIcon('collapse-up-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/collapse-up-arrow.svg'));
    iconRegistry.addSvgIcon('image-line', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/image-line.svg'));
    iconRegistry.addSvgIcon('image-upload', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/image-upload.svg'));
  }
}
export function initAppService(appService: AppService) {
  return () => appService.appInitializer();
}
