<div class="onboarding-container" fxLayout="column">
  <div fxFlex="75" class="slide" fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngFor="let slide of slides; let i = index">
      <div
        [hidden]="!(i === currentSlide)"
        fxLayout="column"
        fxLayoutAlign="center center"
        (swipeleft)="onSwipeLeft()"
        (swiperight)="onSwipeRight()"
      >
        <mat-icon [svgIcon]="slide.src" class="slide-image" [inline]="true"></mat-icon>
        <div *ngIf="slide.src" class="slide-text" [innerHTML]="slide.htmlText"></div>
      </div>
    </ng-container>
  </div>

  <div fxFlex="10" class="navigation-buttons" fxLayout="row" fxLayoutAlign="space-around center">
    <button class="navigation-button" (click)="onPreviousClick()" [disabled]="currentSlide === 0">
      Back
    </button>
    <div fxLayout="row" fxLayoutAlign="center center">
      <ng-container *ngFor="let slide of slides; let i = index">
        <span class="dot" [ngClass]="currentSlide === i ? 'filled-in-dot' : null"></span>
      </ng-container>
    </div>
    <button class="navigation-button" (click)="onNextClick()">
      <div *ngIf="currentSlide + 1 !== slides.length">
        Next
      </div>
      <div *ngIf="currentSlide + 1 === slides.length">
        Done
      </div>
    </button>
  </div>
</div>
