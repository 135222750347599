import { Component, OnInit, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

const DOC_TYPE_DECLARATION = 'declarationForm';
@Component({
  selector: 'app-dqf-status-msg',
  templateUrl: './dqf-status-msg.component.html',
  styleUrls: ['./dqf-status-msg.component.scss']
})
export class DqfStatusMsgComponent implements OnInit {
  title: string;
  reason: string;
  type: string;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    public dialogRef: MatBottomSheetRef<DqfStatusMsgComponent>
  ) {}

  ngOnInit(): void {
    this.title = this.data.displayName;
    this.reason = this.data.reason;
    this.type = this.data.type;
  }
  closeSheet(event: string): void {
    if (this.type === DOC_TYPE_DECLARATION) {
      event += 'SignDeclaration';
    }
    this.dialogRef.dismiss(event);
  }
}
