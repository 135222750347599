import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform, base64ToFile } from 'ngx-image-cropper';
import { catchError, finalize, first, tap } from 'rxjs/operators';
import { ROUTE_LICENSE_SCAN, ROUTE_LICENSE_UPLOAD } from 'src/app/new-license-scan/license-routes';
import { of } from 'rxjs';
import { LicenseDataService } from 'src/app/new-license-scan/license-data.service';
import { RegistrationService } from 'src/app/registration/shared/registration.service';
import { DomSanitizer } from '@angular/platform-browser';

const FRONT_LICENSE_DEFAULT_NAME = 'front-license';
const BACK_LICENSE_DEFAULT_NAME = 'back-lincense';

@Component({
  selector: 'app-identity-license-upload-action-sheet',
  templateUrl: './identity-license-upload-action-sheet.component.html',
  styleUrls: ['./identity-license-upload-action-sheet.component.scss']
})
export class IdentityLicenseUploadActionSheetComponent implements OnInit {
  documentPicture;
  loading: boolean = false;
  showCrop: boolean = false;
  imageChangedEvent;
  acceptTypesPicture = ['image/png', 'image/jpg', 'image/jpeg'];
  canvasRotation = 0;
  transform: ImageTransform = {};
  fileToUpload;
  @ViewChild('previewImage') previewImage: ImageCropperComponent;
  uploadingNewDocument = false;
  documentType;
  url;
  isApiLoading = false;
  isCaptured: boolean;
  frontImageUrl: any;
  backImageUrl: any;
  isFrontSave: boolean;
  finalImageFiles: any[] = [];
  frontImage: any;
  backImage: any;
  license: any;
  imageDetails;

  constructor(
    private readonly bottomSheetRef: MatBottomSheetRef<IdentityLicenseUploadActionSheetComponent>,
    private readonly router: Router,
    private readonly licenseDataService: LicenseDataService,
    private readonly registrationService: RegistrationService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {}

  openCamera() {
    this.router.navigateByUrl(`${ROUTE_LICENSE_SCAN}`).then(() => {
      this.bottomSheetRef.dismiss();
    });
  }

  openGallery(galleryImageInput: any): void {
    galleryImageInput.click();
  }

  onProcessPictureFile(event: any) {
    if (event.target.files && event.target.files.length) {
      const reader = new FileReader();
      const [file] = event.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.documentPicture = file;
      };
      this.loading = true;
      this.showCrop = true;
      this.imageChangedEvent = event;
    }
  }

  onImageCropped(event: ImageCroppedEvent) {
    if (event && event.base64) {
      let name = FRONT_LICENSE_DEFAULT_NAME;
      if (this.isFrontSave) {
        name = BACK_LICENSE_DEFAULT_NAME;
      }
      const imageFile = new File([base64ToFile(event.base64)], name, { type: 'png' });
      let reader = new FileReader();
      let url;
      reader.readAsDataURL(imageFile);
      reader.onload = event => {
        url = event.target.result;
        if (!this.isFrontSave) {
          this.frontImage = imageFile;
          this.frontImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
        } else {
          this.backImage = imageFile;
          this.backImageUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
        }
      };
    }
  }

  openFileExplorer(){
    this.router.navigate([ROUTE_LICENSE_UPLOAD]);
    this.dismissBottomSheetToUpload();
  }

  onImageLoaded() {
    this.loading = false;
  }

  onCropperReady() {
    this.loading = false;
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

  onFinishCrop() {
    if (!this.isFrontSave) {
      this.finalImageFiles.push(this.frontImage);
      this.isFrontSave = true;
      this.frontImage = undefined;
    } else {
      this.finalImageFiles.push(this.backImage);
    }
    this.isCaptured = false;
    this.showCrop = false;
    this.loading = false;
  }

  resetLicense(): void {
    this.isFrontSave = false;
    this.finalImageFiles = [];
    this.frontImage = undefined;
    this.frontImageUrl = undefined;
    this.backImage = undefined;
    this.backImageUrl = undefined;
    this.isCaptured = false;
    this.showCrop = false;
    this.loading = false;
  }

  onCancelCrop() {
    this.showCrop = false;
    this.isCaptured = false;
    this.loading = false;
    if (!this.isFrontSave) {
      this.frontImage = undefined;
      this.frontImageUrl = undefined;
    }
    if (this.isFrontSave) {
      this.backImage = undefined;
      this.backImageUrl = undefined;
    }
  }

  onLoadImageFailed() {
    this.loading = false;
  }

  private isLicensePresent(): boolean {
    return this.license && this.license?.id ? true : false;
  }

  saveLicense(): void {
    if (this.finalImageFiles?.length > 1) {
      this.isApiLoading = true;
      const frontLicense = this.finalImageFiles.find(file => file.name === FRONT_LICENSE_DEFAULT_NAME);
      const backLicense = this.finalImageFiles.find(file => file.name === BACK_LICENSE_DEFAULT_NAME);

      const saveModel = {
        frontScanImageExtension: frontLicense.type,
        backScanImageExtension: backLicense.type
      };
      const licensePresent = this.isLicensePresent();

      if (licensePresent) {
        this.licenseDataService
          .updateLicense(saveModel, this.license.id)
          .pipe(
            first(),
            tap(
              response => {
                this.uploadLicenseImage(response, frontLicense, backLicense);
              },
              catchError(error => {
                return of(false);
              })
            )
          )
          .subscribe();
      }
      this.licenseDataService
        .uploadLicense(saveModel)
        .pipe(
          first(),
          tap(response => {
            this.uploadLicenseImage(response, frontLicense, backLicense);
          }),
          catchError(error => {
            return of(false);
          })
        )
        .subscribe();
    }
  }
  uploadLicenseImage(response, frontLicense, backLicense) {
    if (response) {
      const licenseResponse = response;

      this.licenseDataService
        .sendImageFiles(frontLicense, backLicense, response)
        .pipe(
          first(),
          tap(response => {
            if (licenseResponse?.frontScanImageUrl) {
              licenseResponse.backScanImagePresent = true;
            }
            if (licenseResponse?.backScanImageUrl) {
              licenseResponse.frontScanImagePresent = true;
            }
            this.registrationService.updateLicenseStore(licenseResponse);
            this.onNavigateToProfile();
            this.dismissBottomSheetToUpload();
          }),
          catchError(error => {
            return of(false);
          }),
          finalize(() => {
            this.isApiLoading = false;
          })
        )
        .subscribe();
    }
  }
  onNavigateToProfile(): void {
    this.registrationService.navigateToProfile();
  }

  dismissBottomSheetToUpload(): void {
    this.bottomSheetRef.dismiss();
  }
}
