<div class="header-navigation-back" fxFlayout>
  <div class="back-icon" (click)="backAction()" fxFlex="5" data-test="back-arrow-icon">
    <mat-icon svgIcon="left-arrow"></mat-icon>
  </div>
  <div fxFlex="95" class="header-text">Associated With</div>
</div>
<div class="list-container">
  <mat-tab-group
    animationDuration="0ms"
    mat-align-tabs="center"
    mat-stretch-tabs="true"
    class="tab-list"
    (selectedTabChange)="tabChanged($event)"
    [selectedIndex]="currentTab"
  >
    <mat-tab label="Current" class="tab" data-test="current-vehicle-tab">
      <ng-container>
        <div class="vehicle-container" fxFlex fxLayout="column">
          <div fxFlex="45" class="company-name">
            Long name of the company, long name of the company
          </div>
          <div fxLayout class="details-container margin-top-xs">
            <div fxFlex="50" class="vin-container">
              <span><mat-icon svgIcon="truck-line" class="truck-icon"></mat-icon></span>VIN: 4Y1SL65848Z411439
            </div>
            <div fxFlex="48" class="truck-type-container">
              Dry Van: 2020 GMC KDL
            </div>
          </div>
        </div>
      </ng-container>
    </mat-tab>
    <mat-tab label="Past" data-test="past-vehicle-tab"> </mat-tab>
  </mat-tab-group>
</div>
