import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SinMaskDirective } from './sin-mask.directive';

@NgModule({
  declarations: [SinMaskDirective],
  imports: [CommonModule],
  exports: [SinMaskDirective]
})
export class SinMaskModule {}
