import { Component, Inject, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss']
})
export class TripsComponent implements OnInit {
  constructor(private readonly location: Location, @Inject('environmentData') public environment: any) {}

  ngOnInit(): void {
    if (!this.environment?.featureFlags?.showTrips) {
      this.location.back();
    }
  }

  ngOnDestroy(): void {}
}
