<div data-test="add-photo-box">
  <div class="title margin-top-sm">
    <span class="drag-handle"></span>
  </div>
  <div class="title margin-top-lg">
    Add Photo&nbsp;<span *ngIf="totalActions">{{ currentActionStep }}/{{ totalActions + 1 }}</span>
  </div>
  <div class="margin-top-lg margin-bottom-md actions-row">
    <button mat-button class="btn-clear" (click)="onSkipOrCancel()" [disabled]="saving" data-test="skip-button">
      {{ totalActions ? 'Skip' : 'Cancel' }}
    </button>
    <button mat-button class="btn-accept" (click)="onUploadPhoto()" [disabled]="saving" data-test="upload-button">Upload</button>
  </div>
</div>
