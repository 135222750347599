import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Shipment } from '../shipment-data/models/shipment';
import { Quote } from '../shipment-data/models/quote';
import { ProfileService } from 'src/app/profile/profile.service';
import { OfferTypeService } from '../offer-type.service';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ShipmentUtilsService } from '../shipment-utils.service';
import { ShipmentsUtilsService } from 'src/app/shipments/shared/shipments-utils.service';

const PRIORITY = 'High';

@Component({
  selector: 'app-shipment-item',
  templateUrl: './shipment-item.component.html',
  styleUrls: ['./shipment-item.component.scss']
})
export class ShipmentItemComponent implements OnInit, OnChanges {
  @Input() shipment: Shipment;
  @Input() driverId: string;
  @Input() isTrip: boolean;

  companyName: string;
  driverQuote: Quote;
  origin: string;
  destination: string;
  formattedPickupDateAndTime: string;
  formattedDeliveryDateAndTime: string;
  pickupTime: string;
  deliveryTime: string;
  priority: boolean;
  offerRate: number;
  mode: string;
  weight: number;

  constructor(
    private readonly offerTypeService: OfferTypeService,
    private readonly dateTimeConversion: DateTimeService,
    private readonly shipmentUtilsService: ShipmentUtilsService,
    private readonly shipmentsUtilsService: ShipmentsUtilsService
  ) {
    this.shipment = {} as Shipment;
    this.companyName = '';
    this.origin = '';
    this.destination = '';
    this.formattedDeliveryDateAndTime = '';
    this.formattedPickupDateAndTime = '';
    this.pickupTime = '';
    this.deliveryTime = '';
    this.mode = '';
  }

  ngOnChanges(): void {
    this.shipment = this.shipment || ({} as Shipment);
    this.companyName = this.shipment.companyName || 'Unknown';
    this.origin = `${this.shipment.pickup.city}, ${this.shipment.pickup.state}`;
    this.mode = this.shipment.mode;
    this.weight = this.shipment.weight;
    this.driverQuote = this.offerTypeService.determineDriverQuote(this.shipment, this.driverId);

    this.formattedPickupDateAndTime = this.dateTimeConversion.getFormatedConvertedUTCDate(
      this.shipment.pickup.pickupDateFrom
    );
    this.pickupTime = this.dateTimeConversion.getFormatedConvertedUTCTime(
      this.shipment.pickup.pickupDateFrom,
      this.shipment.pickup.pickupDateTo
    );
    this.getDeliveryDetails();

    this.offerRate = this.shipmentUtilsService.determineOfferRate(this.shipment);
  }

  ngOnInit() {
    if (this.shipment && this.shipment.priority && this.shipment.priority === PRIORITY) {
      this.priority = true;
    }
  }
  getDeliveryDetails(): void {
    const deliveryObject = this.shipmentsUtilsService.getDeliveryDate(this.shipment?.delivery);
    this.destination = `${deliveryObject?.city}, ${deliveryObject?.state}`;

    this.formattedDeliveryDateAndTime = this.dateTimeConversion.getFormatedConvertedUTCDate(
      deliveryObject?.deliveryDateFrom
    );
    this.deliveryTime = this.dateTimeConversion.getFormatedConvertedUTCTime(
      deliveryObject?.deliveryDateFrom,
      deliveryObject?.deliveryDateTo
    );
  }
}
