import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router, ActivatedRoute } from '@angular/router';
import { LoggerService } from 'src/app/core/services/logger.service';
import { ForgotPasswordModel } from '../shared/forgot-password-model';
import { Response } from 'src/app/shared/services/response';
import { ROUTE_SIGNIN } from 'src/app/authentication/shared/routes';
import { ForgotPasswordService } from '../forgot-password.service';
import { ROUTE_NOTIFICATION_SENT } from '../shared/routes';
import { INVALID_TOKEN } from '../shared/guards/messages';

const RequiredFieldMessage = 'You must enter a value';
const InvalidEmailAddress = 'Invalid email address';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  form: FormGroup;
  loading: boolean;
  message: string;
  errorMessage: string;

  private subs = new SubSink();

  constructor(
    private readonly fb: FormBuilder,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly forgotPasswordService: ForgotPasswordService,
    private readonly logger: LoggerService,
  ) {
    this.loading = false;
    this.message = `Enter your email address to receive a reset link.`;

    this.errorMessage = undefined;
    if (INVALID_TOKEN === this.activatedRoute.snapshot.queryParams.message) {
      this.errorMessage = 'There was an error with changing your password. Please try again or contact us for help.';
    }

    this.form = this.createForm();
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onSubmit(forgotPasswordForm: NgForm) {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    const model = this.prepareSaveModel();

    this.subs.add(
      this.forgotPasswordService.sendForgotPasswordNotice(model).subscribe(
        (result: ForgotPasswordModel) => {
          this.errorMessage = undefined;
          this.form.reset();
          forgotPasswordForm.resetForm();
          this.loading = false;
          this.navigateToNotificationSent();
        },
        (error: Response) => {
          this.errorMessage = error.message;
          this.loading = false;
        }
      )
    );
  }

  onNavigateToSignIn(): void {
    this.router.navigate([`/${ROUTE_SIGNIN}`]);
  }

  get email() {
    return this.form.get('email');
  }

  getEmailErrorMessage() {
    const errors = this.email.errors;
    return errors.required ? RequiredFieldMessage : errors.pattern ? InvalidEmailAddress : '';
  }

  private createForm(): FormGroup {
    const form = this.fb.group({
      email: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]]
    });

    return form;
  }

  private prepareSaveModel(): ForgotPasswordModel {
    const formModel = this.form.value;

    const model = {
      username: formModel.email as string
    } as ForgotPasswordModel;

    return model;
  }

  navigateToNotificationSent(): void {
    this.router.navigate([`/${ROUTE_NOTIFICATION_SENT}`]);
  }
}
