<div fxLayoutAlign="start" fxLayout="column" class="page full-height gray-font-color font-family bodyBackground">
  <div class="header-navigation-back">
    <mat-icon data-test="back-arrow-icon" (click)="navigateBack()" data-test="back-arrow-icon">arrow_back</mat-icon>
    <span fxFlex class="title">{{ documentTypeDisplayName || '' }}</span>
  </div>
  <div class="content" (scroll)="onScroll($event)" id="timeline-list">
    <div *ngIf="uploadingNewDocument">
      <app-loading-gif></app-loading-gif>
    </div>
    <div *ngIf="!uploadingNewDocument">
      <div
        fxLayout="column"
        *ngIf="currentTypeDocuments?.length && !showCrop && !isContentLoading"
        class="container document-list-container"
      >
        <div
          fxLayout="column"
          class="document-container"
          (click)="goToDocumentView(document.id)"
          data-test="document-list"
          *ngFor="let document of timelineList"
        >
          <div class="document-info">
            <div *ngIf="!document.fileUrl && apiCount" class="full-width">
              <ngx-skeleton-loader
                appearance="line"
                [theme]="{
                  height: '100px',
                  width: '100%',
                  'background-color': '#c9c9c963'
                }"
              ></ngx-skeleton-loader>
            </div>
            <div fxLayout="column" class="pdf-row margin-top-md" >
              <div fxLayout="row" fxLayoutAlign=" center" *ngIf="document.fileUrl" class="margin-top-sm">
                <img fxFlex="25" src="assets/images/Logo.png" class="logo" />
                <span fxFlex="75" class="pdf-text margin-left-md"> {{ document.isPdf ? 'PDF Document' : 'Image' }}</span>
              </div>
            </div>
          </div>
          <div fxLayout="row">
            <div fxLayoutGap="10px"
              *ngIf="document.role === 'Driver' && document?.shipmentStopId && (document.type === 'ACIeManifest' || document.type === 'ACEeManifest')">
              <span class="stamped-document">Stamped
                {{ document.typeDisplayName }}</span>
              <span class="date">{{ (document.createdDate | date: 'MMM d, y, h:mm a') || '' }}</span>
            </div>
            <div fxLayoutGap="10px"
              *ngIf="document.role === 'Dispatcher' && (document.type === 'ACIeManifest' || document.type === 'ACEeManifest')">
              <span class="stamped-document dispatcher-upload">
                {{ document.typeDisplayName }}</span>
              <span class="date">{{ (document.createdDate | date: 'MMM d, y, h:mm a') || '' }}</span>
            </div>
            <div
              *ngIf="(!(document.role === 'Driver' && document?.shipmentStopId && (document.type === 'ACIeManifest' || document.type === 'ACEeManifest')) && 
                        !(document.role === 'Dispatcher' && (document.type === 'ACIeManifest' || document.type === 'ACEeManifest')))">
              <span class="date outer-date">{{ (document.createdDate | date: 'MMM d, y, h:mm a') || '' }}</span>
            </div>
          </div>
        </div>
      </div>
      <div fxLayout="column" *ngIf="!currentTypeDocuments?.length && !showCrop && !isContentLoading" class="container">
        <div
          fxLayout="column"
          fxFlex="90"
          class="empty-document-container"
          *ngIf="!showCrop && !currentTypeDocuments?.length"
        >
          <div fxFlex="40">
            <img src="assets/images/file-layer.png" />
          </div>
          <div fxFlex="5" class="empty-text">
            {{ (documentTypeDisplayName | titlecase) || 'Document' }} Not Uploaded
          </div>
        </div>
      </div>
      <div *ngIf="showCrop">
        <div class="content" *ngIf="showCrop">
          <div [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
            <image-cropper
              #previewImage
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="false"
              [imageQuality]="100"
              [aspectRatio]="4 / 4"
              [roundCropper]="false"
              [canvasRotation]="canvasRotation"
              [transform]="transform"
              format="png"
              (imageCropped)="onImageCropped($event)"
              (imageLoaded)="onImageLoaded()"
              (cropperReady)="onCropperReady()"
              (loadImageFailed)="onLoadImageFailed()"
            >
            </image-cropper>

            <div *ngIf="!loading" class="save-button-container full-width padding-top-x1">
              <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
                <button
                  class="margin-bottom secondary-button"
                  mat-raised-button
                  (click)="onCancelCrop()"
                  data-test="cancel-button"
                >
                  Cancel
                </button>
                <button mat-raised-button class="rotate-icons" (click)="rotateLeft()">
                  <mat-icon>rotate_left</mat-icon>
                </button>
                <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
                  <mat-icon>rotate_right</mat-icon>
                </button>
                <button
                  class="margin-bottom primary-button finish-crop-button"
                  mat-raised-button
                  (click)="onFinishCrop()"
                  data-test="finish-button"
                >
                  Finish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div fxFlex="10" class="text-align-center add-row" *ngIf="allowToUpload">
    <input
      #uploadMultiple
      type="file"
      [accept]="acceptTypesMultiple"
      (change)="onUploadFileChange($event)"
      data-test="add-file"
    />
    <input
      #pictureSingle
      type="file"
      name="image"
      [accept]="acceptTypesPicture"
      capture="environment"
      (change)="onProcessPictureFile($event)"
      data-test="add-file"
    />
    <button
      mat-button
      class="btn-new-document"
      (click)="openActionSheet(pictureSingle, uploadMultiple)"
      *ngIf="!isContentLoading && !loading && !apiCount"
      data-test="add-button"
    >
      <mat-icon svgIcon="icon-file-blue"></mat-icon> + Add {{ (documentTypeDisplayName | titlecase) || '' }}
    </button>
  </div>
</div>
