import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FleetoperateApiService } from '../services/fleetoperate-api.service';
import { HttpOptions } from '../services/http-options';
import { Observable } from 'rxjs';
import { Response } from '../services/response';
import { FileDataModule } from './file-data.module';

@Injectable({
  providedIn: FileDataModule
})
export class FileApiService {
  constructor(private readonly api: FleetoperateApiService) {}

  uploadFile(
    file: object,
    url: string,
    httpOptions?: HttpOptions,
    isLoadingFalse: boolean = false
  ): Observable<Response> {
    const defaultContentType = {
      'Content-Type': 'application/json'
    };

    if (!httpOptions) {
      httpOptions = {
        headers: new HttpHeaders(defaultContentType),
        withCredentials: true
      };
    } else if (httpOptions && !httpOptions.headers) {
      httpOptions.headers = new HttpHeaders(defaultContentType);
    } else if (httpOptions && httpOptions.headers && !httpOptions.headers.get('Content-Type')) {
      httpOptions.headers = httpOptions.headers.set('Content-Type', 'application/json');
    }

    return this.api.put(`${url}`, file, httpOptions, isLoadingFalse);
  }
}
