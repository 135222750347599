<div class="truck-container" [formGroup]="group">
  <div fxLayout="row wrap" class="truck-type" *ngIf="groupName !== 'others'">
    <div fxFlex="30" class="margin-left-sm margin-right-sm outline">
      <mat-checkbox
        formControlName="van"
        class="full-width"
        labelPosition="after"
        (change)="onTruckChange($event)"
        data-test="van-truck-type"
        >Van
      </mat-checkbox>
    </div>
    <div fxFlex="30" class="margin-left-sm margin-right-sm outline">
      <mat-checkbox formControlName="tank" class="full-width" labelPosition="after" (change)="onTruckChange($event)">
        Tank</mat-checkbox
      >
    </div>
    <div fxFlex="30" class="margin-left-sm margin-right-sm outline">
      <mat-checkbox formControlName="flat" labelPosition="after" (change)="onTruckChange($event)">Flat</mat-checkbox>
    </div>
    <div fxFlex="30" class="margin-left-sm margin-right-sm margin-top-sm outline" *ngIf="groupName === 'straightTruck'">
      <mat-checkbox formControlName="dumpTruck" labelPosition="after" (change)="onTruckChange($event)"
        >Dump Truck
      </mat-checkbox>
    </div>
    <div fxFlex="30" class="margin-left-sm margin-right-sm margin-top-sm outline">
      <mat-checkbox formControlName="reefer" labelPosition="after" (change)="onTruckChange($event)"
        >Reefer
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="row" *ngIf="groupName === 'others'">
    <mat-form-field class="full-width">
      <input matInput type="text" placeholder="EQUIPMENT" maxlength="100" formControlName="equipment" />
    </mat-form-field>
  </div>

  <div class="truck-experience" *ngIf="checkAnyTruck">
    <div class="label">
      <label>EXPERIENCE *</label>
    </div>
    <div fxLayout="row" fxLayoutGap="10px">
      <div fxFlex="48">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>
            Years
          </mat-label>
          <mat-select formControlName="years" (selectionChange)="onYearChange()" data-test="experience-years">
            <mat-option *ngFor="let year of years" [value]="year" data-test="years">
              {{ year }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="48">
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>
            Months
          </mat-label>
          <mat-select formControlName="months" (selectionChange)="onMonthChange()" data-test="experience-months">
            <mat-option *ngFor="let month of months" [value]="month" data-test="months">
              {{ month }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="miles-info" fxLayout="column">
    <div>
      <label>MILES DRIVEN</label>
    </div>
    <div>
      <mat-form-field class="full-width" data-test="miles-driven">
        <mat-select formControlName="milesDriven" data-test="select-miles-driven">
          <mat-option *ngFor="let option of milesDrivenOptions" [value]="option.name" data-test="miles">
            {{ option.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
