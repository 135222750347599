<div fxLayoutAlign="start" fxLayout="column" class="gray-font-color font-family display-text full-height-vh">
  <div class="header-navigation-back">
    <span fxFlex class="title">Drivers License Scan</span>
    <button fxFlexAlign="end" mat-icon-button (click)="onOpenActionSheet()" *ngIf="mobile">
      <mat-icon aria-hidden="false" aria-label="Identity actions">more_horiz</mat-icon>
    </button>
  </div>
  <app-status-banner *ngIf="status" [status]="status" class="padding-bottom-xlg"> </app-status-banner>
  <div fxLayout="column" class="txt" *ngIf="!mobile">
    <div class="scan" *ngIf="!mobile">
      <p>Scan one of the QR code below to make the switch</p>
    </div>
    <div class="qr-div margin-bottom-xlg" fxLayout="row" fxLayoutGap="180px" *ngIf="!mobile" alt="Responsive">
      <div fxLayout="column">
        <img [src]="iOSAppQR" class="ios-qrcode-img" alt="Responsive image" />
        <img [src]="appStoreIcon" class="app-store" alt="Responsive image" />
      </div>
      <div>
        <img [src]="qrImg" class="qrcode-img" alt="Responsive image" />
      </div>
    </div>
  </div>
  <div fxLayout="column" class="txt full-height-vh capture-container" *ngIf="mobile && !error">
    <div fxFlex="30" class="video-container" *ngIf="!error && finalImageFiles?.length != 2">
      <video
        [class.show]="!isCaptured"
        [class.hide]="isCaptured"
        #video
        id="video"
        [width]="WIDTH"
        [height]="HEIGHT"
        autoplay
        playsinline
      ></video>
      <canvas class="canvasClass hidden" #canvas id="canvas" [width]="WIDTH" [height]="HEIGHT"></canvas>

      <div fxLayout="column" class="" fxLayoutGap.lt-md="5px">
        <div *ngIf="showCrop" [ngClass]="{ 'image-cropper-container': !loading, 'no-height': loading }">
          <image-cropper
            #previewImage
            [imageBase64]="imageChangedEvent"
            [maintainAspectRatio]="false"
            [imageQuality]="100"
            cropperMinWidth="420"
            cropperMinHeight="220"
            [roundCropper]="false"
            [canvasRotation]="canvasRotation"
            [transform]="transform"
            format="png"
            (imageCropped)="onImageCropped($event)"
            (imageLoaded)="onImageLoaded()"
            (cropperReady)="onCropperReady()"
            (loadImageFailed)="onLoadImageFailed()"
          >
          </image-cropper>
        </div>
        <div *ngIf="!loading && showCrop" class="save-button-container full-width padding-top-x1">
          <div fxLayout fxLayoutAlign="space-evenly center" class="margin-bottom-xlg">
            <button
              class="margin-bottom secondary-button"
              mat-raised-button
              (click)="onCancelCrop()"
              data-test="finish-button"
              [disabled]="isApiLoading"
            >
              Retake
            </button>
            <button mat-raised-button class="rotate-icons" (click)="rotateLeft()">
              <mat-icon>rotate_left</mat-icon>
            </button>
            <button mat-raised-button class="rotate-icons" (click)="rotateRight()">
              <mat-icon>rotate_right</mat-icon>
            </button>
            <button
              class="margin-bottom  finish-crop-button"
              mat-raised-button
              (click)="onFinishCrop()"
              data-test="finish-button"
              [disabled]="isApiLoading"
            >
              Finish
            </button>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="80" class="text-align-center" fxLayout="column" fxLayoutGap="4px" *ngIf="finalImageFiles?.length == 2">
      <div fxFlex="45">
        <img [src]="frontImageUrl" class="license-image" />
      </div>
      <div fxFlex="45">
        <img [src]="backImageUrl" class="license-image" />
      </div>
    </div>
    <div fxFlex="20" class="image-action-row" fxLayout *ngIf="finalImageFiles?.length != 2 && !showCrop">
      <div fxFlex="20">
        <img [src]="frontImageUrl || 'assets/images/license-view-front.png'" class="preview-image" />
      </div>
      <div fxFlex="20">
        <img [src]="backImageUrl || 'assets/images/license-view-back.png'" class="preview-image" />
      </div>
      <button
        fxFlex="40"
        mat-button
        class="btn-proceed"
        *ngIf="!isCaptured && finalImageFiles?.length != 2"
        (click)="capture()"
        [disabled]="isApiLoading"
      >
        Capture
      </button>
    </div>
    <div fxFlex="20" class="image-action-row" fxLayout *ngIf="finalImageFiles?.length == 2">
      <button fxFlex="40" mat-button class="btn-reset" (click)="resetLicense()" [disabled]="isApiLoading">
        Reset
      </button>
      <button fxFlex="40" mat-button class="btn-save" (click)="saveLicense()" [disabled]="isApiLoading">
        Save
      </button>
    </div>
  </div>
  <div *ngIf="error" class="video-error">{{ error }}. Please login again</div>
</div>
