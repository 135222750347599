export const ACCIDENT_NATURE = [
  { name: 'Front end', value: 'frontEnd' },
  { name: 'Rear end', value: 'rearEnd' },
  { name: 'Upset', value: 'upset' },
  { name: 'Jackknife', value: 'jackKnife' },
  { name: 'T-Bone', value: 'tbone' },
  { name: 'Sideswipe', value: 'sideswipe' },
  { name: 'Wide Turn', value: 'wideTurn' },
  { name: 'Other', value: 'other' }
];
