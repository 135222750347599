import { Injectable } from '@angular/core';
import { EVENT_TYPE_CONFIRMED, ShipmentsApiService } from './shipments-api.service';
import { Response } from 'src/app/shared/services/response';
import { Shipment } from 'src/app/loads-shared/shipment-data/models/shipment';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { Bid } from 'src/app/loads-shared/shipment-data/models/bid';
import { DEFAULT_ERROR_MESSAGE, STATUS_ASSIGNED, STATUS_BOOKED, STOP_TYPES } from './constants';
import { Observable } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { DateTimeService } from 'src/app/shared/date-time-convertor/date-time.service';
import { ShipmentsUtilsService } from './shipments-utils.service';
@Injectable({
  providedIn: 'root'
})
export class ShipmentsDataService {
  loading$: boolean;
  shipments: Shipment[];
  shipment: Shipment;
  constructor(
    private readonly shipmentsApiService: ShipmentsApiService,
    private readonly shipmentUtilService: ShipmentsUtilsService,
    private readonly toastService: ToastService,
    private readonly dateTimeService: DateTimeService
  ) {}

  fetchMyShipments(actorId: string): Observable<Shipment[]> {
    return this.shipmentsApiService.getMyShipments(actorId).pipe(
      map((response: Response) => {
        if (!response?.data) {
          return null;
        }
        response.data?.forEach(shipment => {
          shipment = this.getFormattedDates(shipment);
        });

        this.shipments = response.data;
        return response.data as Shipment[];
      })
    );
  }

  getShipmentDetails(shipmentId: string): Observable<Shipment> {
    return this.shipmentsApiService.getShipmentDetails(shipmentId).pipe(
      map((response: Response) => {
        let shipmentData = response ? (response?.data as Shipment) : undefined;
        if (shipmentData?.status === STATUS_BOOKED) {
          shipmentData.status = STATUS_ASSIGNED;
        }
        this.shipment = this.getFormattedDates(shipmentData);
        return shipmentData;
      }),
      catchError(error => {
        throw error;
      })
    );
  }
  getFormattedDates(shipment: Shipment): Shipment {
    shipment.pickupFormattedDateTime = this.dateTimeService.getConvertedFromToDateTime(
      shipment.pickup.pickupDateFrom,
      shipment.pickup.pickupDateTo
    );
    const deliveryObj = this.shipmentUtilService.getDeliveryDate(shipment?.delivery);
    shipment.deliveryFormattedDateAndTime = this.dateTimeService.getConvertedFromToDateTime(
      deliveryObj?.deliveryDateFrom,
      deliveryObj?.deliveryDateTo
    );
    return shipment;
  }

  confirmBid(quoteId: string): Observable<Bid> {
    const bid = {
      event: EVENT_TYPE_CONFIRMED
    } as Bid;

    return this.shipmentsApiService
      .createBid(quoteId, bid)
      .pipe(map((response: any) => (response && response.data ? (response.data as Bid) : undefined)));
  }

  updateShipment(shipmentId: string, body: Shipment): Observable<Shipment> {
    this.loading$ = true;

    return this.shipmentsApiService.updateShipment(shipmentId, body).pipe(
      map((response: any) => response?.data as Shipment),
      catchError((error: any) => {
        this.toastService.showError(error?.message ?? DEFAULT_ERROR_MESSAGE);
        throw error;
      }),
      finalize(() => (this.loading$ = false))
    );
  }
}
